/**
 * Reducer for registered user
 */

import { REGISTER_USER, RESET_REGISTER_USER } from "../actions/types";

const INTIAL_STATE = false;

const fn = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case REGISTER_USER:
      if (action.payload.success) {
        return true;
      } else {
        return false;
      }

    case RESET_REGISTER_USER:
      return false;

    default:
      return state;
  }
};

export default fn;