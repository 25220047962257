// Password input component
import React from "react";
import TextField from "@mui/material/TextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CheckIcon from "@mui/icons-material/Check";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";

class PasswordField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  render() {
    const {
      label,
      input,
      classes,
      isCheckOff,
      meta: { touched, invalid, error },
      ...custom
    } = this.props;
    return (
      <TextField
        label={label}
        {...input}
        {...custom}
        error={touched && invalid}
        helperText={touched && error}
        type={this.state.showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {!invalid && !error && !isCheckOff && (
                <CheckIcon className={classes.greenColor} />
              )}
              <IconButton
                aria-label="toggle password visibility"
                sx={{ marginLeft: "10px" }}
                onClick={() =>
                  this.setState({ showPassword: !this.state.showPassword })
                }
                edge="end"
              >
                {this.state.showPassword ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
}

PasswordField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
  classes: PropTypes.object,
  variant: PropTypes.string,
  label: PropTypes.string,
  isCheckOff: PropTypes.bool,
};

PasswordField.defaultProps = {
  input: {
    onChange: () => {},
    value: null,
  },
  meta: {
    touched: false,
    invalid: false,
    error: undefined,
  },
  classes: {},
  variant: "standard",
  label: "",
  isCheckOff: false,
};

export default PasswordField;
