// ----------------------------------------------------------------------

export function cssBaseline(theme) {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          boxSizing: "border-box",
          "&::-webkit-scrollbar": {
            width: "10px",
            height: "2px",
          },
          "&::-webkit-scrollbar-track": {
            background: theme.palette.background.paper,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
            width: "6px",
            borderRadius: "5px",
          },
          "&.slick-list .slick-track": {
            marginLeft: "0 !important",
          },
        },
      },
    },
  };
}
