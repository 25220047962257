/**
 * My Profile -> My Data page component
 */
import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";

import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";

import { getUserDetails, updateUser } from "../../actions";
import UserUpdateForm from "./UserUpdateForm";
import withRouter from "../../helpers/withRouter";

const styles = (theme) => ({
  root: {
    "@media (hover:hover)": {
      backgroundColor: "var(--color-main)",
    },
    width: "100%",
    "& .MuiStepper-root": {
      padding: "4em 0 0 0",
    },
    "& .MuiStepLabel-label": {
      color: "var(--grey-4)",
      fontSize: "0.7rem",
    },
    "& .MuiStepLabel-active": {
      color: "var(--color-contrast)",
    },
  },
  button: {
    margin: theme.spacing(1),
    color: "var(--btn-text)",
    "&:hover": {
      backgroundColor: "var(--color-contrast)",
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    zIndex: 1,
    color: "var(--grey-4)",
    width: 10,
    height: 10,
    display: "flex",
    borderRadius: "20%",
    justifyContent: "center",
    alignItems: "center",
  },
  stepperActive: {
    zIndex: 1,
    color: "var(--color-contrast)",
    width: 10,
    height: 10,
    display: "flex",
    borderRadius: "20%",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage:
      "linear-gradient(45deg, var(--blue-light) 30%, var(--blue-light-2) 90%)",
  },
  stepperCompleted: {
    backgroundImage:
      "linear-gradient(45deg, var(--blue-light) 30%, var(--blue-light-2) 90%)",
    color: "var(--color-contrast)",
  },
  hidden: {
    display: "none",
  },
});

class UserUpdate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      previousStep: 0,
      stepCompleted: true,
    };

    this.child = React.createRef();
  }

  componentDidMount() {
    const { auth, locale, navigate } = this.props;
    auth.isSignedIn
      ? this.props.getUserDetails(auth.userName, this.constructor.name)
      : navigate(`/${locale}/sign-in`);
  }

  componentDidUpdate() {
    const { auth, locale, navigate } = this.props;
    if (!auth.isSignedIn) navigate(`/${locale}/sign-in`);
  }

  /**
   * Update user information
   */
  onSubmit = (formValues) => {
    this.props.updateUser(formValues, this.constructor.name);
  };

  submitForm = () => {
    this.child.submitForm();
  };

  render() {
    log(this.constructor.name, "render");
    const { initialValues, classes } = this.props;

    return (
      <div className="text-center">
        <UserUpdateForm
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          content="update"
          step={this.state.activeStep}
          lang={this.props.locale}
          onRef={(ref) => (this.child = ref)}
        />
        {/* <Button
          type="submit"
          variant="contained"
          size="small"
          color="primary"
          className={classes.button}
          onClick={this.submitForm.bind(this)}
        >
          <Translate value="labels.btn_save" />
        </Button> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.user.auth,
  initialValues: state.user.profile,
  translate: state.i18n.translations[state.i18n.locale],
  locale: state.i18n.locale,
});

export default withRouter(connect(mapStateToProps, { getUserDetails, updateUser })(
  withStyles(styles)(UserUpdate)
));
