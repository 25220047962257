/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconNC = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g>
					<path d="M1158.4,1682.9l141,70.5c0.4,0.2,0.9,0.4,1.2,0.7l89.1-154.2c32-55.4,19.8-125.7-29-167l-167.7-142.4
	c-4.9-4.2-9.1-9.3-12.3-15c-2,0.5-4.1,0.7-6.1,0.7c-2.7,0-5.3-0.5-7.9-1.2l-230.4-76.9c-2.8,49.8,17.9,98.1,56,130.4l215.1,182.5
	c19.4,16.7,24.3,44.8,11.5,67L1158.4,1682.9z"/>
					<path d="M1434.7,1183.9l126-94.6l-16.2-13.8c-6.9-5.9-12.8-12.8-17.4-20.6l-70-121.6c-18.2-31.4-45.2-56.8-77.6-73.2L1278,809.6
	c-63.6-31.6-140.7-7.6-175.1,54.5l-146.9,266.5c-3.4,6.2-6.3,12.7-8.8,19.4c0.2,0,0.7,0.2,1,0.2l231.6,77.1c1.3-3.1,2.9-5.9,4.9-8.6
	l141.7-193.5l74.7,128.9c5.9,10.6,13.8,20,23.1,27.8l6.9,5.6C1432,1186.1,1433.3,1184.9,1434.7,1183.9z"/>
					<path d="M1619.9,1238.2c25-35.4,18.2-84.2-15.5-111.5l-5.9-5.2c-1.1,1.5-2.4,2.8-3.9,3.9l-125.8,94.3l36.3,30.9
	c16.6,14.1,38.3,20.8,60,18.4C1586.9,1266.7,1606.7,1255.5,1619.9,1238.2z"/>
					<path d="M1399.1,1224.9l-6.1-5.2c-1.2-1.2-2.7-2.2-3.9-3.4l-126.5,68.8l40.3,34.1l126.5-68.8L1399.1,1224.9z" />
					<path d="M1664.8,1122.5l85-46.2c7.7-4.2,12.6-12.2,12.8-21c0.2-8.8-4.3-17-11.8-21.6c-7.5-4.6-16.9-4.8-24.6-0.6l-95,51.6l5.6,5.2
	C1647.9,1099.2,1657.3,1110.2,1664.8,1122.5z"/>
					<path d="M1020.4,1416.7l-295.3,160.5l-21-42.3c-6.1-12.1-20.8-17.1-33-11c-12.1,6.1-17.1,20.8-11,33l21.9,43.8l-85.5,46.4
	c-9.9,5.4-14.9,16.8-12.1,27.7c2.8,10.9,12.7,18.5,23.9,18.5c4.1,0,8.2-1,11.8-3l83.9-45.6l29.7,59.5c6.1,12.1,20.8,17.1,33,11
	c12.1-6.1,17.1-20.8,11-33l-30.5-61l313.6-170.4L1020.4,1416.7z"/>
					<path d="M1860.8,1717.8c-13.6,0-24.6,11-24.6,24.6c0,54.2-44,98.2-98.2,98.2h-490c4.5-4.5,8.4-9.7,11.5-15.2l16.4-28.7l-141-70.5
	c-0.5-0.2-0.7-0.5-1.2-0.7l-9.6,16.7c-18.6,31.3-15,71.1,9.1,98.5H755.7c-13.6,0-24.6,11-24.6,24.6c0,13.6,11,24.6,24.6,24.6H1738
	c81.3-0.1,147.3-66,147.4-147.4C1885.4,1728.8,1874.4,1717.8,1860.8,1717.8z"/>
					<circle cx="1387.1" cy="695.4" r="124.5" />
					<path d="M1041.3,225.7c20.5,68.5-18.4,140.7-86.9,161.2c-68.5,20.5-140.7-18.4-161.2-86.9s18.4-140.7,86.9-161.2
	C948.6,118.3,1020.8,157.2,1041.3,225.7z M1013.1,579.6l-581.5,598.3c10.3-52.7,27.1-120.9,54.2-197.8c21-10.6,38.3-28.8,47.3-52.6
	c15.5-40.9,68.6-162.2,127.7-231c77.3-89.9,128.6-135.4,129-135.7c40.5-35.4,44.5-97,9.1-137.4c-35.4-40.5-97-44.5-137.4-9.1
	c-1.3,1.1-17.9,15.8-45.9,44L229.8,647.6c-33.7,16.6-47.6,57.3-31.1,91c14.9,30.3,49.2,44.6,80.5,35.2c3.6-1.1,7.1-2.4,10.5-4.1
	l133-65.3c-43.3,79-70.2,149.8-71.9,154.4c-10.3,27.2-7.4,56.2,5.3,80c-67.4,193.1-75.9,336.1-76.3,343.5
	c-0.8,14.9,3.3,28.9,10.9,40.6L124,1494.3c-13.7,14.1-13.3,36.5,0.7,50.2l7.8,7.6c14.1,13.7,36.5,13.3,50.2-0.7l203.8-209.7
	c7.1-4.9,13.2-11.2,17.9-18.5l617.9-635.7c27.3-28.1,26.7-73.1-1.4-100.4L1013.1,579.6z"/>
				</g>
			</svg>
		</div>
	);
};

export default IconNC;
