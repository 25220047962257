// MUI Modal
export function modal(theme) {
  return {
    MuiModal: {
      styleOverrides: {
        root: {
        //   background: theme.palette.background.paper,
        },
      },
    },
  };
}
