/**
 * ResponsibleGame - Finnish version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import ListItem from "@mui/material/ListItem";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
    "& .MuiListItem-root": {
      padding: "0.2em",
    },
  },
});

class ResponsibleGame extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          {host.gaming && host.bet
            ? [
                <div id="legalcontent" className={classes.root} key={0}>
                  <h1>VASTUULLINEN PELAAMINEN</h1>
                  Nubet.com tukee vastuullista pelaamista ja urheiluvedonlyöntiä. Nubet.com kasino- ja urheiluvedonlyönti on suunnattu asiakkaille, jotka pitävät uhkapelaamista ja vedonlyöntiä hauskanpitona ja viihteenä
                  ja voivat arvioida vedonlyöntikäyttäytymistään vastuullisesti. Jos voitontoive johtaa itsehillinnän menettämiseen, sinun on ehkä haettava apua. Varmista, että peli pysyy pelinä. Noudata seuraavia
                  periaatteita, kun vierailet verkkosivustollamme.<br></br>
                  <br></br>
                  <ListItem>Aseta ensin talletuksesi maksimikokonaispanos</ListItem>
                  <ListItem>Älä talleta enemmän rahaa pelitilillesi, kuten määritit ennen aloittamista</ListItem>
                  <ListItem>Päätä etukäteen, millä tuotolla haluat poistua verkkosivustolta</ListItem>
                  <ListItem>Aseta kiinteä summa etukäteen siitä, kuinka suuria tappiosi voivat olla</ListItem>
                  <ListItem>Yritä aina olla keskittynyt ja levännyt, kun pelaat</ListItem>
                  <ListItem>Pidä usein taukoja pelatessasi, jolloin teet jotain muuta</ListItem>
                  <ListItem>Älä koskaan pelaa tai lyö vetoa huumeiden, vahvojen lääkkeiden vaikutuksen alaisena tai liiallisen alkoholinkäytön jälkeen</ListItem>
                  <ListItem>Älä ota luottoa pelataksesi tai lyödäksesi vetoa</ListItem>
                  <ListItem>Rajaa panoksesi, jotta tappio ei vaikuta jokapäiväiseen elämääsi</ListItem>
                  <ListItem>Älä jätä huomiotta tai laiminlyö sosiaalista ympäristöäsi.</ListItem>
                  <br></br>
                  Tarjoamme kaikille käyttäjille mahdollisuuden asettaa omat pelirajansa tai estää oman pelitilinsä tietyksi ajaksi. Muista, että kun asetat tai lasket pelirajan, se tulee voimaan välittömästi. Nubet.com
                  ei näin ollen ota panoksia, jotka ylittävät nämä rajat. Voit estää itsesi pelaamasta myös ottamalla yhteyttä osoitteeseen support@nubet.com. Sinulla on mahdollisuus estää tilisi rajoitetuksi ajaksi tai
                  pysyvästi. On monia riippumattomia organisaatioita, jotka tarjoavat apua. Tutustu seuraaviin saadaksesi lisätietoa:<br></br>
                  <br></br>
                  <h4>Ammattimainen apu:</h4>
                  Jos sinulla on peliongelma tai jos olet ongelmapelaajan ystävä tai perheenjäsen, voit saada ilmaista apua ja neuvontaa peliriippuvuuskeskuksesta:<br></br>
                  <br></br>
                  puh: 15410<br></br>
                  emai: info@15410.ee<br></br>
                  www: www.15410.ee<br></br>
                  <br></br>
                  <b>Nubet.com kieltää alaikäisiä käyttämästä palveluitamme</b>
                  <br></br>
                  Emme ota panoksia asiakkailta, jotka eivät ole täysi-ikäisiä oman maansa lakien mukaan. Alaikäisen rekisteröinti rikkoo käyttöehtojamme, ja siitä voidaan ilmoittaa asianmukaisille viranomaisille.
                  Nubet.com pyytää henkilökohtaista henkilöllisyystodistusta asiakkaiden laillisen iän vahvistamiseksi. Nubet.com on laillisesti velvollinen peruuttamaan kaikki alaikäisten asettamat vedot ja siirtämään
                  mahdolliset voitot sääntelyviranomaisille tarpeen mukaan.<br></br>
                  <br></br>
                </div>,
              ]
            : null}
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(ResponsibleGame));
