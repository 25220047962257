// @main view
// #react
import { useEffect } from "react";
// @redux
import { useDispatch, useSelector } from "react-redux";
// @actions
import { getCasinoSlidersGamesAsync } from "../../../redux-store/casino-reducer";
// components
import GamesCarouselSection from "../components/GamesCarouselSection";
import CasinoProviders from "../components/CasinoProviders";
// translate
import { I18n } from "react-redux-i18n";
// category icons
import CategoryNewIcon from "../components/icons/CategoryNewIcon";
import CategoryPopularIcon from "../components/icons/CategoryPopularIcon";
import CategoryLiveIcon from "../components/icons/CategoryLiveIcon";
import CategoryBonusBuyIcon from "../components/icons/CategoryBonusBuyIcon";
import CategoryClassicsIcon from "../components/icons/CategoryClassicsIcon";
import CategoryHotGamesIcon from "../components/icons/CategoryHotGamesIcon";
import CategoryJackpotsIcon from "../components/icons/CategoryJackpotsIcon";
import CategoryMegawaysIcon from "../components/icons/CategoryMegawaysIcon";
import CategoryWinALotIcon from "../components/icons/CategoryWinALotIcon";
import CategoryWinOftenIcon from "../components/icons/CategoryWinOftenIcon";
import CategoryDropsWinsIcon from "../components/icons/CategoryDropsWinsIcon";
import CategoryTournament from "../components/icons/CategoryTournament";

const CasinoMainView = () => {
  const dispatch = useDispatch();
  // selectors
  const newCasinoGames = useSelector((state) => state.casino.slider.newGames);
  const popularCasinoGames = useSelector(
    (state) => state.casino.slider.popularGames
  );
  const liveCasinoGames = useSelector((state) => state.casino.slider.liveGames);
  const dropAndWinGames = useSelector(
    (state) => state.casino.slider.dropAndWinGames
  );
  const winOftenGames = useSelector(
    (state) => state.casino.slider.winOftenGames
  );
  const winAlotGames = useSelector((state) => state.casino.slider.winAlotGames);
  const megawayGames = useSelector((state) => state.casino.slider.megawayGames);
  const jackpotGames = useSelector((state) => state.casino.slider.jackpotGames);
  const hotGames = useSelector((state) => state.casino.slider.hotGames);
  const classicGames = useSelector((state) => state.casino.slider.classicGames);
  const bonusBuyGames = useSelector(
    (state) => state.casino.slider.bonusBuyGames
  );
  const tournamentGames = useSelector(
    (state) => state.casino.slider.tournamentGames
  );
  // select locale
  const lang = useSelector((state) => state.i18n.locale);
  // load carousel games
  const getCasinoGames = () => {
    if (!liveCasinoGames.length) {
      dispatch(getCasinoSlidersGamesAsync("livecasino"));
    }
    if (!newCasinoGames.length) {
      dispatch(getCasinoSlidersGamesAsync("new"));
    }
    if (!popularCasinoGames.length) {
      dispatch(getCasinoSlidersGamesAsync("popular"));
    }
    if (!dropAndWinGames.length) {
      dispatch(getCasinoSlidersGamesAsync("dropsandwins"));
    }
    if (!winOftenGames.length) {
      dispatch(getCasinoSlidersGamesAsync("winoften"));
    }
    if (!winAlotGames.length) {
      dispatch(getCasinoSlidersGamesAsync("winalot"));
    }
    if (!megawayGames.length) {
      dispatch(getCasinoSlidersGamesAsync("Megaways"));
    }
    if (!jackpotGames.length) {
      dispatch(getCasinoSlidersGamesAsync("jackpots"));
    }
    if (!hotGames.length) {
      dispatch(getCasinoSlidersGamesAsync("hotgames"));
    }
    if (!classicGames.length) {
      dispatch(getCasinoSlidersGamesAsync("classics"));
    }
    if (!bonusBuyGames.length) {
      dispatch(getCasinoSlidersGamesAsync("bonusbuy"));
    }
    if (!bonusBuyGames.length) {
      dispatch(getCasinoSlidersGamesAsync("tournament"));
    }
  };

  useEffect(getCasinoGames, []);

  return (
    <div className="casino-slider-container">
      {Boolean(newCasinoGames?.length) && (
        <GamesCarouselSection
          title={I18n.t("slots.new")}
          games={newCasinoGames}
          url={`/${lang}/casino/featured-games?filter=new`}
          icon={<CategoryNewIcon />}
        />
      )}
      <CasinoProviders />
      {Boolean(popularCasinoGames?.length) && (
        <GamesCarouselSection
          title={I18n.t("slots.popular")}
          games={popularCasinoGames}
          url={`/${lang}/casino/featured-games?filter=popular`}
          icon={<CategoryPopularIcon />}
        />
      )}
      {Boolean(liveCasinoGames?.length) && (
        <GamesCarouselSection
          title={I18n.t("slots.livecasino")}
          games={liveCasinoGames}
          url={`/${lang}/casino/featured-games?filter=livecasino`}
          icon={<CategoryLiveIcon />}
        />
      )}
      {Boolean(dropAndWinGames?.length) && (
        <GamesCarouselSection
          title={I18n.t("slots.dropsandwins")}
          games={dropAndWinGames}
          url={`/${lang}/casino/featured-games?filter=dropsandwins`}
          icon={<CategoryDropsWinsIcon />}
        />
      )}
      {Boolean(winOftenGames?.length) && (
        <GamesCarouselSection
          title={I18n.t("slots.winoften")}
          games={winOftenGames}
          url={`/${lang}/casino/featured-games?filter=winoften`}
          icon={<CategoryWinOftenIcon />}
        />
      )}
      {Boolean(winAlotGames?.length) && (
        <GamesCarouselSection
          title={I18n.t("slots.winalot")}
          games={winAlotGames}
          url={`/${lang}/casino/featured-games?filter=winalot`}
          icon={<CategoryWinALotIcon />}
        />
      )}
      {Boolean(megawayGames?.length) && (
        <GamesCarouselSection
          title={I18n.t("slots.megaways")}
          games={megawayGames}
          url={`/${lang}/casino/featured-games?filter=Megaways`}
          icon={<CategoryMegawaysIcon />}
        />
      )}
      {Boolean(jackpotGames?.length) && (
        <GamesCarouselSection
          title={I18n.t("slots.jackpots")}
          games={jackpotGames}
          url={`/${lang}/casino/featured-games?filter=jackpots`}
          icon={<CategoryJackpotsIcon />}
        />
      )}
      {Boolean(hotGames?.length) && (
        <GamesCarouselSection
          title={I18n.t("slots.hotgames")}
          games={hotGames}
          url={`/${lang}/casino/featured-games?filter=hotgames`}
          icon={<CategoryHotGamesIcon />}
        />
      )}
      {Boolean(classicGames?.length) && (
        <GamesCarouselSection
          title={I18n.t("slots.classics")}
          games={classicGames}
          url={`/${lang}/casino/featured-games?filter=classics`}
          icon={<CategoryClassicsIcon />}
        />
      )}
      {Boolean(bonusBuyGames?.length) && (
        <GamesCarouselSection
          title={I18n.t("slots.bonusbuy")}
          games={bonusBuyGames}
          url={`/${lang}/casino/featured-games?filter=bonusbuy`}
          icon={<CategoryBonusBuyIcon />}
        />
      )}
      {Boolean(tournamentGames?.length) && (
        <GamesCarouselSection
          title={I18n.t("slots.tournament")}
          games={tournamentGames}
          url={`/${lang}/casino/featured-games?filter=tournament`}
          icon={<CategoryTournament />}
        />
      )}
    </div>
  );
};

export default CasinoMainView;
