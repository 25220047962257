import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";

const CustomTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => {
  return (
    <TextField
      {...input}
      {...custom}
      label={label}
      helperText={touched && invalid && error}
      error={touched && invalid && error}
    />
  );
};

CustomTextField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
  classes: PropTypes.object,
  variant: PropTypes.string,
  label: PropTypes.string,
};

CustomTextField.defaultProps = {
  input: {
    onChange: () => {},
    value: null,
  },
  meta: {
    touched: false,
    invalid: false,
    error: undefined,
  },
  classes: {},
  variant: "standard",
  label: "",
};

export default CustomTextField;
