import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const CategoryBonusBuyIcon = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 200 200"
      {...props}
    >
      <path d="M85.1,167.7c6.3,5.7,14.6,9.3,24.6,11l25.8,4.9l10.2-35.1l-22.1-6.5L94.7,96.9c-7.6-13.1-22.5-2.5-17.2,7.7
	C82,113.2,89,122,92.9,130.8c0.7,2.9-4.6,4.4-6.2,1.5l-26.1,11.5L85.1,167.7L85.1,167.7z M80,18.5l13.2,52.4l4.4,17.4
	c-2.2-2.2-4.9-3.7-7.8-4.6l-1.2-4.9c0,1.5-0.3,3-0.6,4.5c-2.8-0.4-5.7,0-8.7,1.2c-1.5,0.5-2.7,1.3-3.9,2.2c-1.3-1.5-2.5-3.3-3.5-5.4
	c-10.2,12.7-18.3,7.1-20,0c-1.8-7.2,2.7-15.9,17.7-9.6c-0.2-3.5,0.4-6.6,1.6-9.1c-3.2-1.3-6.7-1.6-10.3-0.7
	C52,64.1,46.6,73.1,48.9,82c2.2,8.9,11.2,14.3,20,12c0.4-0.2,0.8-0.2,1.2-0.3c-1,2.4-1.5,5-1.2,7.7C59,103,49.3,98.2,44.5,89.9
	l7.2,28.8c6-1.5,12.2,2.2,13.6,8.2l14.7-3.7c1.2,1.9,2.4,3.8,3.5,5.8l-33.6,8.4L36.7,85.1L23.6,32.7l56.7-14.3L80,18.5z M41,77.1
	c0.3-10.3,7.5-19.6,18-22.2c10.5-2.6,21.1,2.2,26.4,11.1l-7.2-28.9c-6,1.5-12.2-2.2-13.6-8.2l-22.5,5.7c1.5,6-2.2,12.1-8.2,13.7
	l7.2,28.8H41z M129.5,17.6l-1.6,54l-1.6,53.9l-11.5-0.3l-3.4-13.4c2.2-2.6,5.4-4.2,9-4.1l0.9-29.7c-2.3,7-7.7,12.6-14.6,15.1
	l-1.8-7.1c1.1-0.5,2.1-1,3.1-1.6c-1.8-2.2-3-5.2-3.5-8.6c-0.6,0.4-1.2,0.8-1.9,1.2l-3.7-14.8c1.8,0.8,3.7,2.1,5.9,3.8
	c0.8-3.4,2.2-6.3,4-8.3c-2.7-2.2-6-3.4-9.6-3.5c-0.8,0-1.5,0-2.2,0l-1.8-7c1.4-0.2,2.8-0.3,4.2-0.2c10.9,0.3,19.8,7.9,22.4,17.8
	l0.9-29.7c-6.2-0.2-11.1-5.3-11-11.6l-22.6-0.7l-1.6-6.4C87.5,16.4,129.5,17.6,129.5,17.6z M176.7,36.1l-16.5,51.4l-16.5,51.4
	l-17.5-5.6l7.6,0.2l0.4-12.4c2.6-1.5,5.8-1.9,8.9-0.9l9-28.3c-3.9,5.9-10.4,9.7-17.4,10.4l0.2-7.3c0.9-0.2,1.9-0.4,2.7-0.7
	c-1.1-2.5-1.4-5.8-1-9.2c-0.5,0.2-0.9,0.3-1.4,0.5l0.4-14.5c1.3,1.2,2.6,2.7,4,4.6c1.7-3,3.9-5.4,6.2-6.9c-2-2.7-4.9-4.9-8.3-6
	c-0.5-0.2-1-0.3-1.5-0.4l0.2-7.3c1.2,0.2,2.3,0.5,3.5,0.8c10.3,3.3,16.8,13,16.5,23.4l9.1-28.3c-5.9-1.9-9.2-8.3-7.3-14.1l-21.1-6.8
	l0.2-6.6L176.7,36.1L176.7,36.1z"/>
    </SvgIcon>
  );
};

export default CategoryBonusBuyIcon;
