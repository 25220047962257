/**
 * Component for game box detail avatar svg
 */

import { log } from '../../../../config/log';
import React from 'react';
import { connect } from 'react-redux';

import './GameBoxDetailedAvatar.css';

class GameBoxDetailedAvatarBX extends React.PureComponent {

  renderShirt() {
    const { pattern, color1, color2 } = this.props;

    switch (pattern) {

      case "1":
        return (
          <div>
            <div className="competitor-avatar-bg-boxing"></div>
            <svg className="competitor-avatar-img" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="3em" height="3em"
              viewBox="0 0 520.7 565.9" style={{ enableBackground: "0 0 520.7 565.9" }} xmlSpace="preserve">
              <g>
                <g>
                  <g>
                    <path fill={color1} d="M495.3,289.7c-10.2-85.6-24.4-169.6-37-218.2c-12.7,6.6-42.6,13.8-83.1,19c-32.3,4.1-71.2,6.8-113.5,6.8
                      c-0.9,0-1.8,0-2.7,0c-38.8-0.1-74.9-2.5-105.5-6c-42.2-4.9-74-12-87.7-18.6c-12.4,49.2-26.5,130.9-36.8,214.3
                      c-11.3,91.4-18,184.9-13,240.1c0,0,44.3,9.7,97,11c38.1,0.9,80.7-2.6,113.9-17.5c11.6-74.1,23.2-148.2,34.9-222.3l0,0
                      c0.1,0.7,0.2,1.4,0.3,2c0.2,1.1,0.4,2.3,0.5,3.4c11.3,72.3,22.7,144.5,34,216.8c35.7,16,82.1,18.8,122.2,17.2
                      c48.9-2,88.6-10.8,88.6-10.8C512.5,473.1,506.2,380.5,495.3,289.7z"/>
                    <path fill={color2} d="M29.6,529.6C22.2,302,75.7,69.5,76.2,67.2l14.5,3.4C90.1,72.9,37.1,306.4,44.4,532L29.6,529.6z" />
                    <path fill={color2} d="M497.4,529.1l-14.9,2.3c7.3-225.6-45.7-458.6-46.2-460.9l14.5-3.4C451.2,69.5,504.7,301.6,497.4,529.1z" />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        );

      default:
        return (
          <div>no pattern</div>
        );
    }


  }

  render() {
    log(this.constructor.name, 'render');
    return <div>{this.renderShirt()}</div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pattern: ownProps.pattern,
    color1: ownProps.color1,
    color2: ownProps.color2
  };
};

export default connect(mapStateToProps)(GameBoxDetailedAvatarBX);
