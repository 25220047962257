import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import logger from "redux-logger";
import rootReducer from "./root-reducer";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import alertMiddleware from "./alert-reducer/_middleware";

const extraMiddlewares = [thunk, alertMiddleware];
const isLoggerManullyEnabled = localStorage.getItem("log-react-redux");

if (process.env.NODE_ENV === "development" || isLoggerManullyEnabled) {
  extraMiddlewares.push(logger);
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    });
    // Remove immutable state invariant middleware in production
    if (process.env.NODE_ENV === "production") {
      // We can remove the default immutable state check if we want
      return middlewares.filter((middleware) => middleware.name !== "immutableStateInvariantMiddleware");
    }

    return middlewares.concat(extraMiddlewares);
  },
});

export const persistor = persistStore(store);

export default { store, persistor };
