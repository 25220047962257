/**
 * OddsFormat component
 ** Show odd in many format
 ** Show lock icon if price is under 1 or odd is not active.
 */

import { log } from '../config/log';
import React from 'react';
import { connect } from 'react-redux';
import Lock from '@mui/icons-material/Lock';


class OddsFormat extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {}
  }

  /**
   * Calculate fraction and return correct odd price info
   */

  calcFractional() {
    let price = this.props.price;
    const { isActive } = this.props;

    if (price <= 1.00) return <Lock style={{ color: "var(--grey-5)", fontSize: "1.2em" }} />;
    if (Number(price) === 1) return <Lock style={{ color: "var(--grey-5)", width: "0.5em" }} />

    let part1 = parseInt(Math.floor(price).toFixed());
    let part2 = parseFloat((price - part1).toFixed(2));

    part1 = parseInt((part1 - 1) * 100);
    part2 = parseInt(part2 * 100);


    let numerator = !isNaN(part2) ? part1 + part2 : part1;

    let denumerator = 100;
    let divisor = greatestCommonDivisor(numerator, denumerator);

    let fraction = numerator / divisor + '/' + denumerator / divisor;



    function greatestCommonDivisor(a, b) {
      if (!b) {
        return a;
      }
      return greatestCommonDivisor(b, a % b);
    }
    if (Number(isActive) === 0) return <span style={{ color: "var(--grey-5)" }} >{fraction}</span>

    return fraction;
  }

  /**
   * Calculate price in American way and return correct odd price info
   */

  calcAmerican() {
    let price = this.props.price;
    const { isActive } = this.props;

    if (price <= 1.00) return <Lock style={{ color: "var(--grey-5)", fontSize: "1.2em" }} />;
    if (Number(price) === 1) return <Lock style={{ color: "var(--grey-5)", width: "0.5em" }} />;
    if (price >= 2) {
      price = '+' + ((price - 1) * 100).toFixed();
    } else {
      price = '-' + Math.ceil((100 / (price - 1)));
    }
    if (Number(isActive) === 0) return <span style={{ color: "var(--grey-5)" }} >{price}</span>




    return price;
  }

  /**
   * Calculate price in selected way and return correct odd price info
   */

  render() {
    log(this.constructor.name, 'render');
    const { oddsFormat, price, isActive } = this.props;
    switch (oddsFormat) {
      default:
      case 'decimal':
        let result;
        if ((Number(price) <= 1) && price !== null) {
          result = (<Lock style={{ color: "var(--grey-5)", fontSize: "1.2em" }} />);
        } else {
          if (Number(price) === 0) {
            result = null;
          } else {
            if (Number(isActive) === 0) {
              result = <span style={{ color: "var(--grey-5)" }} >{Number(price).toFixed(2)}</span>
            } else {
              result = Number(price).toFixed(2);
            }
          }
        }
        return result;

      case 'fractional':
        return this.calcFractional();

      case 'american':
        return this.calcAmerican()
    }

  }

}

const mapStateToProps = (state, ownProps) => (
  {
    oddsFormat: state.settings.oddsFormat,
    price: ownProps.price
  }
);


export default connect(mapStateToProps)(OddsFormat);