import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const CategoryNewIcon = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 200 200"
      {...props}
    >
      <polygon points="126.6 75.9 100 42.4 73.4 75.9 126.6 75.9" />
      <polygon points="39.3 39.1 15.3 75.9 53.8 75.9 39.3 39.1" />
      <polygon points="70.1 87 100 162.9 129.9 87 70.1 87" />
      <polygon points="16.8 87 89.6 166.5 58.2 87 16.8 87" />
      <polygon points="93 33.5 49 33.5 63.6 70.5 93 33.5" />
      <polygon points="151 33.5 107 33.5 136.4 70.5 151 33.5" />
      <polygon points="110.4 166.5 183.2 87 141.8 87 110.4 166.5" />
      <polygon points="184.7 75.9 160.7 39.1 146.2 75.9 184.7 75.9" />
    </SvgIcon>
  );
};

export default CategoryNewIcon;
