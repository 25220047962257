// Racing main page
import { useEffect } from "react";
// @MUI
import { Box } from "@mui/material";
// @Redux utils
import { useDispatch, useSelector } from "react-redux";
import { getRacingLeaguesAsync } from "../../../redux-store/sports-reducer";
// external components
import FeaturedOutrightsBanner from "../../banners/featured-outright";
import EmptyScreen from "../@components/EmptyScreen";
import { Translate } from "react-redux-i18n";
import PageLoader from "../../PageLoader";
import SportsLayout from "../@components/SportsLayout";
import RacingLeague from "../@components/RacingLeague";

const RacingPage = () => {
  // redux action dispatcher
  const dispatch = useDispatch();

  // selectors
  const isPageLoading = useSelector((state) => state.sports.racing.isRacingLoading);
  const lang = useSelector((state) => state.i18n.locale);
  const racingLeagues = useSelector((state) => state.sports.racing.list);
  const layout = useSelector((state) => state.settings.layout);

  // functions
  useEffect(() => {
    dispatch(getRacingLeaguesAsync(lang));
  }, []);

  return (
    <SportsLayout>
      <Box>
        <Box>{layout === "mouse" && <FeaturedOutrightsBanner />}</Box>
        {isPageLoading && !racingLeagues.length ? (
          <PageLoader />
        ) : !racingLeagues.length && !isPageLoading ? (
          <EmptyScreen displayBackground message={<Translate value="labels.racing_empty_screen_message" />} />
        ) : (
          <Box>
            {racingLeagues.map((league) => (
              <RacingLeague key={league.leagueId} {...league} />
            ))}
          </Box>
        )}
      </Box>
    </SportsLayout>
  );
};

export default RacingPage;
