/**
 * Password Reset component
 */

import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import withStyles from "@mui/styles/withStyles";
import { Translate } from "react-redux-i18n";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import PasswordField from "../Inputs/PasswordInput";
import withRouter from "../../helpers/withRouter";
// import Alert from '../Alert';

import { resetPassword } from "../../actions";

const styles = (theme) => ({
  root: {
    "& .MuiContainer-root": {
      padding: theme.spacing(2),
      width: "100%",
    },
    "& .MuiFormControl-root": {
      margin: "1em 0",
      width: "100%",
    },
    "& .MuiButton-root": {
      margin: "2em 1em 2em 0",
    },
    backgroundColor: "var(--color-main)",
  },
  greenColor: {
    color: "var(--color-contrast)",
  },
});

class PasswordResetForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAlert: false,
    };
  }

  onSubmit = (formValues) => {
    this.props
      .resetPassword(
        {
          ...formValues,
          password_current: this.props.location?.search?.split("=")[1],
        },
        this.constructor.name,
        "PasswordResetForm"
      )
      .then(() => {});
  };

  render() {
    log(this.constructor.name, "render");
    const { translate, classes } = this.props;
    return (
      <div className={classes.root}>
        <form
          onSubmit={this.props.handleSubmit(this.onSubmit)}
          autoComplete="off"
        >
          <div>
            <Container maxWidth="md">
              <Typography
                variant="h6"
                component="h4"
                color="primary"
                style={{ margin: "0 0 1em 0" }}
              >
                <Translate value="labels.reset_pass" />
              </Typography>
              <Typography
                variant="body2"
                paragraph
                style={{ whiteSpace: "pre-line" }}
              >
                <Translate value="labels.reset_pass_text" />
              </Typography>
              <Field
                name="password_new"
                type="password"
                component={PasswordField}
                label={translate.labels.new_pass}
                classes={classes}
              />
              <Field
                name="password_new_confirm"
                type="password"
                component={PasswordField}
                label={translate.labels.confirm_pass}
                classes={classes}
              />
              <Button
                type="submit"
                variant="contained"
                size="small"
                color="primary"
                style={{ color: "var(--btn-text)" }}
              >
                <Translate value="labels.change_pass" />
              </Button>
            </Container>
          </div>
        </form>
      </div>
    );
  }
}

const validate = (values, props) => {
  const errors = {};

  const requiredFields = ["password_new", "password_new_confirm"];

  //maks: some fields need to sent to server uppercase
  // const toUpper = [
  //   'address1',
  //   'address2',
  //   'zip',
  //   'city'
  // ];

  // toUpper?.forEach(field => {
  //   values[field] = values[field].toUpper;
  // })

  requiredFields?.forEach((field) => {
    if (!values[field]) {
      errors[field] = props.translate.labels.field_required;
    }
  });

  // console.log(values.password_new);
  if (values.password_new && values.password_new?.length < 8) {
    errors.password_new = props.translate.labels.invalid_pass;
  }

  if (
    values.password_new_confirm &&
    values.password_new_confirm !== values.password_new
  ) {
    errors.password_new = props.translate.labels.invalid_pass_confirm;
  }

  return errors;
};

const mapStateToProps = (state) => ({
  auth: state.user.auth,
  settings: state.settings,
  // initialValues: state.user,
  translate: state.i18n.translations[state.i18n.locale],
});

export default withRouter(
  connect(mapStateToProps, { resetPassword })(
    reduxForm({
      form: "PasswordResetForm",
      validate,
      enableReinitialize: true,
    })(withStyles(styles)(PasswordResetForm))
  )
);
