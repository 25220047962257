// Player props catalog  Single odds list
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid2, styled, Typography } from "@mui/material";
import Iconify from "../../iconify";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { toggleMyTicketsPanel } from "../../../redux-store/settings-reducer";
import { saveBet } from "../../../actions";
import { checkSelectedOdd } from "../../../helpers/odds-factory";

const StyledContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-betweens",
  "&:hover": {
    background: "var(--bg-marketline-hover)",
  },
}));

const CatalogSingleOdds = ({ marketKey, odds, game, autoOpenFirst }) => {
  // accardion actions
  const [expanded, setExpanded] = useState(false);
  const handleExpand = () => {
    setExpanded(!expanded);
  };
  // redux dispatcher
  const dispatch = useDispatch();
  // get market viewer labels
  const marketViewerLabels = useSelector((state) => state.labels?.market?.viewer);
  // get hc disabled list
  const hcDisabledMarkets = useSelector((state) => state.settings.playerProps.hcDisabledList);
  // get slip items
  const selectedSlipTemplate = useSelector((state) => state.selected.slipTemplate);
  const bets = useSelector((state) => state.slips[selectedSlipTemplate].settings.bets);
  // format market viewer labels
  const formatMarketViewerLabels = (marketLabel) => {
    marketLabel = marketLabel?.replace("{%player}", "")?.trim();
    marketLabel = marketLabel?.replace(/^./, marketLabel[0].toUpperCase());
    return marketLabel;
  };

  /**
   * Select odd for bet
   */

  function addBet(odd, index) {
    let payload = {
      gameId: game.gameid,
      isLive: game.isLive,
    };

    const marketId = odd.m;
    const selectionIds = odd.s.split(",");
    const prices = odd.p.split(",");
    const hc = odd.hc;
    const shc = odd?.shc;
    const isActives = odd.iA.split(",");

    if (selectionIds.length < 3 && selectionIds.length > 1) {
      selectionIds.unshift(null);
      prices.unshift(null);
      isActives.unshift(null);
    } else if (selectionIds.length === 1) {
      selectionIds.push(null);
      prices.push(null);
      isActives.push(null);
      selectionIds.push(null);
      prices.push(null);
      isActives.push(null);
    }

    if (Number(prices[index]) < 1) return false;
    if (Number(prices[index]) === 1) return false;
    if (Number(isActives[index]) === 0) return false;

    payload.marketId = Number(marketId);
    payload.selectionId = Number(selectionIds[index]);
    payload.price = Number(prices[index]);
    payload.hc = hc;
    payload.sn = odd?.sn;
    payload.isPlayerProps = true;
    payload.isSinglePlayerProps = true;
    payload.isActive = Number(isActives[index]);
    payload.game = { ...game, isOutright: true };
    if (shc) {
      payload.shc = shc;
    }
    dispatch(toggleMyTicketsPanel(false));
    dispatch(saveBet(payload, selectedSlipTemplate, "CatalogSingleOdds"));
  }

  return (
    <Accordion expanded={expanded} onChange={handleExpand}>
      <AccordionSummary expandIcon={<Iconify icon="ic:outline-expand-more" />} aria-controls="panel1-content" id="panel1-header">
        <Typography>{marketViewerLabels && marketViewerLabels[marketKey] && formatMarketViewerLabels(marketViewerLabels[marketKey])}</Typography>
      </AccordionSummary>
      {expanded && (
        <AccordionDetails>
          {Object.keys(odds).map((marketKey, indx) => {
            const marketOdds = odds[marketKey];
            return (
              <StyledContainer key={indx} my={1}>
                <Grid2 container rowSpacing={2}>
                  <Grid2 item xs={3} md={2}>
                    <Box display="flex" alignItems="center" height="100%">
                      <Typography pr={1}>{marketOdds && marketOdds[0] ? marketOdds[0]["sn"] : null}</Typography>
                    </Box>
                  </Grid2>
                  <Grid2 item xs={9} md={10} container justifyContent="flex-end">
                    {marketOdds?.map((odd, indx) => {
                      const hc = odd.hc ? odd.hc?.split(":") : [""];
                      const isSelected = checkSelectedOdd(Object.values(bets), game, odd, 0);
                      return (
                        <Grid2 item key={indx} xs={4} sm={2} md={1.2}>
                          <div
                            onClick={() => addBet(odd, 0)}
                            className={`${isSelected ? "selected-odds" : ""}  content-center vertical-center cursor-pointer font-xl font-bold-2 odds-btn-outright border-odds-right border-odds-left `}
                            style={{
                              margin: "0.2em 0.2em",
                              padding: "10px",
                              color: odd.iA != "1" ? "var(--grey-5)" : "",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className="flex-end spacer-right font-s"
                                style={{
                                  fontSize: "0.7em",
                                  fontWeight: 400,
                                  color: "var(--grey)",
                                }}
                              >
                                {hc && !hcDisabledMarkets.includes(odd?.m) ? hc[hc.length - 1] + "+" : ""}
                              </div>
                              <div>{parseFloat(odd.p).toFixed(2)}</div>
                            </div>
                          </div>
                        </Grid2>
                      );
                    })}
                  </Grid2>
                </Grid2>
              </StyledContainer>
            );
          })}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default CatalogSingleOdds;
