/**
 * PrivacyPolicy - Estonian version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import ListItem from "@mui/material/ListItem";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
    "& .MuiListItem-root": {
      padding: "0.2em",
    },
  },
});

class PrivacyPolicy extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>NUBET.COM-i PRIVAATSUSPÕHIMÕTTED</h1>
            Eestikeelne versioon 1.1, viimati uuendatud 27. jaanuaril 2023.<br></br>
            <br></br>
            Vana Lauri OÜ juhatus kinnitas privaatsuspõhimõtted 17. jaanuaril 2023 kooskõlas Eesti hasartmänguseadusega.<br></br>
            <br></br>
            <h3 id="General">1. ÜLDINE</h3>
            1. Veebisaiti nubet.com (edaspidi: meie, me ja/või ettevõte) haldab Eestis registreeritud ettevõte Vana Lauri OÜ, registrikood 16427120, registreeritud aadress Roseni 10–135, 10111 Tallinn, Eesti. Ettevõte on
            võtnud endale põhimõtete kohaldamisel kohustuse järgida EL-i isikuandmete kaitse üldmäärust (edaspidi: üldmäärus) ning privaatsust ja andmekaitset käsitlevaid riiklikke õigusakte. Ettevõte tegutseb Eesti
            Maksu- ja Tolliameti tegevusloa alusel ning nende järelevalve all. Vana Lauri OÜ-l on hasartmänguseadusega kooskõlas kommertslike veebihasartmängude pakkumiseks tegevusload HKT000066 (välja antud 10.07.2022)
            ja HKT000067 (välja antud 10.07.2022).<br></br>
            <br></br>
            2. Siinsetes privaatsus- ja küpsiste põhimõtetes (edaspidi: põhimõtted) kirjeldatakse, kuidas ja miks me teie isikuandmeid kogume ja töötleme.<br></br>
            <br></br>
            3. Isikuandmed on mis tahes teave, mis on seotud tuvastatud või tuvastatava füüsilise isikuga, nagu eesnimi, perekonnanimi, sünnikuupäev, elukoha aadress, IP-aadress, e-posti aadress, telefoninumber, isikut
            tõendavad dokumendid, elektroonilise isikutunnistuse (e-ID) andmed, finantsdokumendid, kommunaalarved, pangakonto väljavõtted, hoolsuskohustuse täitmiseks vajalikud andmed jne (edaspidi: isikuandmed või teie
            andmed/teave). Töötlemine hõlmab muu hulgas selliste andmete kogumist, kasutamist, säilitamist ja avalikustamist.<br></br>
            <br></br>
            4. Teie isikuandmeid töötleme meie (sealhulgas meie esindajad ja/või töötajad) ja (vajaduse korral) meie kontserni kuuluvad ettevõtted ja/või meie partnerid ja alltöövõtjad kooskõlas siinsete põhimõtetega.
            <br></br>
            <br></br>
            5. Pange tähele, et isikuandmeid kogudes, töödeldes ja säilitades tegutseme meie ja tegutsevad meie sidusettevõtjad vastutava töötlejana.<br></br>
            <br></br>
            6. Kui jätkate meie teenuste kasutamist pärast siinsete põhimõtete avaldamist, nõustute põhimõtetega ja oma isikuandmete kasutamisega kooskõlas siinsete tingimustega. Põhimõtted kehtivad veebisaidi,
            veebisaidi mobiilse või seadmespetsiifilise versiooni ning kõigi meie rakenduste ja kontode kohta.<br></br>
            <br></br>
            7. Jätame endale õiguse muuta põhimõtteid, sealhulgas eesmärke, milleks teie isikuandmeid töödeldakse. Kõik muudatused avaldatakse meie veebilehel. Seetõttu soovitame teil korrapäraselt kontrollida, kas
            põhimõtteid on uuendatud. Veebisaidi kasutamist jätkates nõustute järgima põhimõtteid ja nende viimaseid muudatusi. Kui te ei nõustu põhimõtete muudatustega, siis ei ole teil õigust meie teenuseid kasutada
            ega neile juurde pääseda.<br></br>
            <br></br>
            <h3 id="Collection">2. ANDMETE KOGUMINE, TÖÖTLEMINE JA KASUTAMINE</h3>
            Pange tähele, et võime koguda ja töödelda teie kohta järgmisi isikuandmeid. Teeme seda aga ainult juhul, kui kehtib vähemalt üks järgmistest üldmääruses sätestatud õiguslikest alustest:<br></br>
            <br></br>
            <ListItem>olete andnud nõusoleku isikuandmete töötlemiseks ühel või mitmel konkreetsel eesmärgil (artikli 6 lõike 1 punkt a);</ListItem>
            <ListItem>töötlemine on vajalik selleks, et täita ettevõtte ja teie vahel sõlmitud lepingut või teha teie taotlusel enne lepingu sõlmimist vajalikke toiminguid (artikli 6 lõike 1 punkt b);</ListItem>
            <ListItem>töötlemine on vajalik meie juriidilise kohustuse täitmiseks (artikli 6 lõike 1 punkt c);</ListItem>
            <ListItem>töötlemine on vajalik teie või mõne muu füüsilise isiku oluliste huvide kaitsmiseks (artikli 6 lõike 1 punkt d);</ListItem>
            <ListItem>töötlemine on vajalik avalikes huvides oleva ülesande täitmiseks või avaliku võimu teostamiseks (artikli 6 lõike 1 punkt e);</ListItem>
            <ListItem>
              töötlemine on vajalik meie või kolmanda isiku õigustatud huvi korral, välja arvatud juhul, kui sellise huvi kaaluvad üles teie huvid või põhiõigused ja -vabadused, mille nimel tuleb kaitsta isikuandmeid
              (artikli 6 lõike 1 punkt f).
            </ListItem>
            1. Registreerimine/sisselogimine<br></br>
            Konto ja meie teenuste kasutajaks registreerimisel (või registreerimisvormi täitmisel) kasutatakse esitatud teavet kasutaja tuvastamiseks ja tema konto haldamiseks. Selline teave võib hõlmata järgmisi
            andmeid: teie nimi, perekonnanimi, sünnikuupäev, maksuandmed, elektroonilise isikutunnistuse (e-ID) andmed, e-posti aadress, elukoha aadress, telefon, IP-aadress, seade ja muud kohustuslikud andmed, mida
            võidakse teilt nõuda registreerimise ja/või teenuse kasutamise jätkamise eesmärgil. Nimetatud andmeid töödeldakse selleks, et ettevõte saaks täita kasutajate ees lepingulisi kohustusi ja rakendada oma
            õigustatud huvisid.<br></br>
            <br></br>
            2. Tugi/kontaktivorm<br></br>
            Jälgime ja/või salvestame teie vestlusi meiega ja säilitame salvestisi nii kaua, kui see on vajalik teie päringute käsitlemiseks või nõutud seadustes ja/või ettevõtte õigustatud huvides.<br></br>
            <br></br>
            3. Maksete töötlemine<br></br>
            Kogume kõiki esitatud konto, krediitkaardi või muid maksetehingutega seotud andmeid, mida võidakse edastada makseteenuse osutajale, et täita kasutaja taotletud makset. Kohaldada võidakse makseteenuse
            osutajate lisatingimusi, millega saate tutvuda tehingu tegemisel.<br></br>
            <br></br>
            4. Isikut tõendavad dokumendid<br></br>
            Kogume ja säilitame üleslaaditud dokumente, et tuvastada kliente, töödelda teie päringuid ja/või täita kehtivaid seadusi ja eeskirju. Neid dokumente kasutatakse ka pettuste ennetamiseks ja avastamiseks,
            rahapesu ja terrorismi rahastamise tõkestamiseks ning vastutustundliku hasartmängimise tagamiseks.<br></br>
            <br></br>
            5. Uudiskirjad<br></br>
            Kui annate turundusmaterjalide saamise eesmärgil ettevõttele oma kontaktandmed, töötleme teie andmeid selleks, et saata teile uudiseid, mis võivad teile huvi pakkuda. Kui soovite nimetatud uudiskirja
            saamisest loobuda, saate seda teha oma kontol isiklike seadete kaudu või kasutada tellimusest loobumise funktsiooni mis tahes turundusmaterjali juures. Ettevõte töötleb nimetatud kontaktandmeid seaduslikult
            teie nõusoleku alusel.<br></br>
            <br></br>
            6. Mängija käitumine<br></br>
            Võime töödelda andmeid ka rahapesu ja/või terrorismi rahastamise tõkestamiseks, pettuse ennetamiseks, vastutustundliku hasartmängimise tagamiseks, teenuse kvaliteedi parandamiseks ja kliendile soovituste
            andmiseks, kui mängija käitumine ja andmed lubavad meil teha sellise järelduse. Töötleme selliseid andmeid juriidilise kohustuse täitmiseks ning õigustatud huvi ja nõusoleku alusel.<br></br>
            <br></br>
            <h3 id="Duration">3. ANDMETE SÄILITAMISE PERIOOD JA ANDMETE KUSTUTAMINE</h3>
            Ettevõte kustutab teie isikuandmed põhimõtteliselt siis, kui eesmärk, milleks neid säilitati, on täidetud. Eespool nimetatud andmed tuleb esitada veebilehe kasutamiseks ja rakendamiseks ning tingimuste
            täitmiseks. Siiski on võimalik, et andmeid tuleb säilitada mõnel muul eesmärgil kooskõlas Euroopa või riigisiseste seaduste, määruste või muude eeskirjadega, mida ettevõte peab järgima. Sellised andmed
            kustutatakse alles siis, kui eespool nimetatud õigusaktides sätestatud säilitamisperioodid on lõppenud. Ainus erand on see, kui salvestatud andmeid on vaja lepingu täitmiseks või sõlmimiseks. Näiteks on Eesti
            rahapesu ja terrorismi rahastamise tõkestamise seaduses sätestatud, et tegevusloa omanik on kohustatud säilitama dokumente ja andmeid kliendi suhtes rakendatavate hoolsusmeetmete täitmise, ärisuhte käigus
            tehtud tehingute ja üksikute tehingute kohta, mille suhtes kohaldatakse hoolsusmeetmeid. Ettevõte peab säilitama kliendi suhtes rakendatavate hoolsusmeetmetega seotud dokumente ja andmeid viis aastat,
            kusjuures teatavaid andmeid maksueeskirjadega kooskõlas kuni kümme aastat.<br></br>
            <br></br>
            <h3 id="Transmission">4. ANDMETE EDASTAMINE</h3>
            1. Kolmandate isikute teenused<br></br>
            Kui soovite kasutada teenust, mida pakume osaliselt või täielikult kolmandast isikust teenuseosutaja kaudu, võime jagada isikuandmeid kolmandate isikutega. Kolmandast isikust teenuseosutajad on sõlminud
            meiega lepingu. Oleme võtnud ettevaatusabinõusid tagamaks, et kolmandast isikust teenuseosutajad peavad järgima samu privaatsusnõudeid nagu meie, ning edastame andmeid Euroopa Majanduspiirkonnast väljas
            asuvatesse riikidesse ainult siis, kui andmete vastuvõtja järgib Euroopas kehtivate andmekaitsenõuetega samaväärseid nõudeid.<br></br>
            <br></br>
            2. Isikusamasuse tuvastamine<br></br>
            Jätame endale õiguse teha teie isikusamasuse kinnitamiseks igal ajal turvakontrolle. Turvakontroll võib hõlmata muu hulgas teie vanuse, registreerimisandmete, finantstehingute, krediidiinfo ja/või muude
            teiega seotud andmete kinnitamist kolmandate isikute andmebaaside põhjal. Et võimaldada turvakontrolli tegemist, nõustute esitama meile kogu lisateabe ja dokumendid, mida võime nõuda. See on vajalik eelkõige
            selleks, et kontrollida meie üldtingimuste ja/või kehtivate õigusaktide võimalikku rikkumist. Nõustudes meie üldtingimuste ja privaatsuspõhimõtetega, annate meile ja meie töötajatele, esindajatele ja
            tarnijatele loa kasutada oma isikuandmeid. Võime saada teie andmeid kolmandatelt isikutelt, nagu krediidiasutused ja pettuste ennetamisega tegelevad asutused, et täita rahapesu- ja pettusevastaseid nõudeid
            ning rakendada klientide suhtes hoolsusmeetmeid.<br></br>
            <br></br>
            3. Reguleerivad ja täitevasutused<br></br>
            Et ettevõte saaks täita oma juriidilisi kohustusi, võidakse meilt nõuda, et edastaksime teie kontoga seotud registreerimis-, isikut tõendavate dokumentide, finantstehingute, mängutehingute ja muud üldised
            andmed Euroopa Liidu / Euroopa Majanduspiirkonna reguleerivatele ja täitevasutustele, näiteks Eesti rahapesu andmebüroole. Võime avaldada teavet ka finantsteabeasutustele oma juriidilise kohustuse põhjal, mis
            tuleneb Euroopa Parlamendi ja nõukogu direktiivist 2015/849, mida võidakse aeg-ajalt muuta. Peale selle peab ettevõte edastama teavet riiklikule enesevälistusregistrile (Maksu- ja Tolliamet) ja sealt teavet
            pärima, eelkõige mängijate enesevälistusandmeid.<br></br>
            <br></br>
            4. Mängu- ja andmeplatvormide pakkujad<br></br>
            Et ettevõte saaks oma teenuseid osutada, teeb see koostööd mänguplatvormi ja andmeplatvormi pakkujaga.<br></br>
            <br></br>
            5. Tulemuste kokkuleppimine<br></br>
            Me toetame ausat sporti, mistõttu võidakse spordi või mängu aususe kontrollimise või reeglite jõustamise ja/või kuritegevuse ennetamise ja avastamise eesmärgil avaldada teie isikuandmeid mis tahes
            reguleerivale või spordiorganisatsioonile, kellega kontsern sõlmib andmete jagamiseks aeg-ajalt lepinguid ja kui kontsern leiab, et sel on alust kahtlustada, et te võite olla seotud selliste reeglite või
            seaduse rikkumisega, omada teavet reeglite või seaduse rikkumisest või kujutada muul viisil ohtu asjaomase spordi või mängu aususele. Need asutused võivad seejärel kasutada teie isikuandmeid rikkumiste
            uurimiseks ja menetlemiseks. Teeme otsuse nendelt saadud teabe põhjal.<br></br>
            <br></br>
            6. Klienditugi<br></br>
            Et ettevõte saaks teile tugiteenuseid osutada, vahendab kliendituge kolmandast isikust teenuseosutaja. Selle tööriist võimaldab meil teiega suhelda nii e-posti teel kui ka reaalajas.<br></br>
            <br></br>
            7. Turundus<br></br>
            Et ettevõte saaks teile saata huvipakkuvat teavet, kasutame kolmandatest isikutest teenuseosutajaid, kes aitavad meil turundusteenuseid pakkuda. See kehtib ainult juhul, kui olete tellinud ettevõtte
            uudiskirja, nagu on sätestatud punktis 2.5.<br></br>
            <br></br>
            <h3 id="External">5. VÄLINE SISU JA KÜPSISED</h3>
            1. Väline sisu ja JavaScript<br></br>
            Pange tähele, et kuigi meie teenused võivad sisaldada linke veebisaitidele ja rakendustele (näiteks Maksu- ja Tolliameti veebisait või hasartmängusõltuvuse organisatsiooni veebisaidid), mida haldavad
            kolmandad isikud, ei vastuta me (ja/või mõni muu meie kontserni kuuluv ettevõte) selliste veebisaitide või rakenduste privaatsustavade või sisu eest. Peale selle kasutame oma veebisaitidel ka väliste
            teenuseosutajate JavaScripti-põhist aktiivset sisu. Kui kasutate meie veebilehte, võivad need välised teenuseosutajad saada teie külastusega seotud isikuandmeid.<br></br>
            Seda saate ära hoida, kui paigaldate brauserisse JavaScripti blokeerija, näiteks lisandmooduli NoScript (www.noscript.net), või keelate JavaScripti. Pange tähele, et kui te seda teete, ei pruugi teil olla
            võimalik kasutada külastatavate veebisaitide kõiki funktsioone. Pange ka tähele, et me ei saa võtta vastutust välise sisu eest.<br></br>
            <br></br>
            2. Lisandmoodulid<br></br>
            Võime oma veebisaitide või teenuste toimimise tagamiseks kasutada kolmandate isikute lisandmooduleid ja aktiivseid skripte (sh JavaScript). Jätame endale õiguse kasutada igal ajal teisi lisandmooduleid ja
            aktiivseid skripte.<br></br>
            <br></br>
            a. Google-Analytics<br></br>
            Sellel veebisaidil kasutatakse Google Analyticsit, veebianalüüsiteenust, mida pakub Google Inc., Amphitheatre Parkway 1600, Mountain View, CA 94043, USA (edaspidi: Google). Google Analytics kasutab
            mitmesuguseid meetodeid, sealhulgas küpsiseid ehk teie arvutisse paigutatud tekstifaile, mis aitavad veebisaidil analüüsida, kuidas saiti kasutatakse. Teave, mida Google Analytics kogub veebilehe kasutamise
            kohta, edastatakse Google’ile ja see salvestatakse serveritesse, mis võivad asuda väljaspool Euroopa Liidu liikmesriike ja väljaspool Euroopa Majanduspiirkonna lepinguriike. Kui veebisaidi Google Analyticsi
            jälgimiskoodis on aktiveeritud IP-aadressi anonüümimine, anonüümib Google enne edastamist teie IP-aadressi. Veebisaidil kasutatakse Google Analyticsi jälgimiskoodi, millele on lisatud parameeter
            „gat.anonymizelp();“, et IP-aadresse saaks koguda anonüümselt (nn IP maskimine). Google kasutab asjaomast teavet veebisaidi käitaja nimel, et hinnata veebisaidi kasutamist, koostada aruandeid veebisaidil
            tehtavate tegevuse kohta ning osutada veebisaidi käitajale muid tegevuste tegemise ja interneti kasutamisega seotud teenuseid. Google Analytics ei seosta teie IP-aadressi muude Google’i säilitatavate
            andmetega. Võite keelduda Google’i küpsiste kasutamisest, muutes asjakohaseid brauseri seadeid. Pange siiski tähele, et kui te seda teete, ei pruugi teil olla võimalik kasutada veebisaidi kõiki funktsioone.
            Et takistada Google’il veebisaidi kasutamisega seotud andmete (sealhulgas teie IP-aadressi) kogumist ja kasutamist, laadige alla ja installige brauserisse siin saadaval olev lisandmoodul.<br></br>
            <br></br>
            3. Küpsised<br></br>
            Veebilehe kasutamise ajal võib teie brauser peale küpsiste laadida automaatselt alla ka kasutatava saidi koopia. See fail salvestatakse teie arvuti kõvakettale. Küpsised aitavad meil parandada oma veebisaite.
            Küpsiste keelamiseks muutke oma veebibrauseri seadeid. Pange tähele, et kui otsustate küpsised keelata, ei pruugi teil olla võimalik pääseda juurde meie veebisaitide teatavatele osadele. Et meie e-posti
            funktsioonid töötaksid korralikult, võime kasutada ka küpsiseid ja veebimajakaid. Meie veebisaiti/teenuseid kasutades nõustute nende küpsiste kasutamisega.<br></br>
            Saame mõne küpsise kaudu anonüümset teavet selle kohta, kui palju külastajaid meie veebisaite kasutab, mis kuupäeval ja kellaajal nad seda teevad, kui kaua külastus kestab ja milliseid lehti nad vaatavad.
            Kasutame küpsiseid ka selleks, et teha kindlaks, milliseid brausereid meie külastajad kasutavad ja milliselt aadressilt nad meie veebisaitidele sisenesid (näiteks kui nad suunati veebisaidile pärast meie
            reklaamribal klõpsamist). Võime jagada seda teavet kolmandate isikutega, et parandada oma veebisaitide sisu ja toimivust, mõista paremini oma kliente ja turge ning parandada oma tooteid ja teenuseid.<br></br>
            Teised küpsised võimaldavad meil aga koostada statistikat oma külastajate ja meie veebisaitide kasutamise kohta (nt IP-aadress, teie operatsioonisüsteem, veebileht, millelt faili vaadati, faili nimi,
            kättesaamise kuupäev ja kellaaeg, edastatud andmete maht, veebilogid jne).<br></br>
            <br></br>
            a. Kasutame järgmist tüüpi küpsiseid.<br></br>
            <br></br>
            <ListItem>
              Seansipõhine küpsis – küpsis, mis kustutatakse, kui kasutaja brauseri sulgeb. Seansiküpsis salvestatakse ajutisse mällu ja seda ei säilitata pärast brauseri sulgemist. Seansiküpsised ei kogu teavet kasutaja
              arvuti kohta. Seansipõhised küpsised aitavad teil meie veebisaidil kiiremini liikuda, ja kui olete registreeritud klient, siis edastada teile asjakohasemat teavet.
            </ListItem>
            <ListItem>
              Püsiküpsis – küpsis, mida säilitatakse kasutaja kõvakettal kuni selle kehtivusaja lõpuni (püsiküpsistele määratakse alati kehtivusaeg) või kuni kasutaja küpsise kustutab. Püsiküpsiseid kasutatakse selleks,
              et koguda kasutaja tuvastamist võimaldavat teavet, näiteks tema internetikasutuse harjumuste või konkreetse veebisaidiga seotud eelistuste kohta (näiteks keel).
            </ListItem>
            <ListItem>
              Analüütiline küpsis – neid küpsiseid kasutatakse saidi kohta teabe kogumiseks. Analüütilised küpsised võimaldavad meil külastajaid tuvastada ja neid kokku lugeda ning näha, kuidas külastajad saidil ringi
              liiguvad. Need küpsised koguvad anonüümseid andmeid kasutajate tegevuse kohta ja kuidas nad veebisaidile sattusid.
            </ListItem>
            <br></br>
            b. Küpsised võimaldavad meil:<br></br>
            <br></br>
            <ListItem>parandada veebisaidi/teenuste toimivust ning muuta sisu kasutajatele huvitavamaks ja asjakohasemaks, näiteks tutvustada teile asjakohasemaid kampaaniapakkumisi;</ListItem>
            <ListItem>eristada teid meie veebisaitide teistest kasutajatest, et tagada hea kasutuskogemus;</ListItem>
            <ListItem>tuvastada teie seadeid ja eelistusi, kui olete sisu või funktsioone kohandanud;</ListItem>
            <ListItem>kasutada teie sisselogimisandmeid, et tuvastada teid meie veebisaidi/teenuste registreeritud kasutajana;</ListItem>
            <ListItem>koguda statistilisi andmeid meie veebisaidi/teenuste kasutamise kohta.</ListItem>
            <br></br>
            c. Kolmandate isikute küpsised<br></br>
            <br></br>
            Need on küpsised, mille on paigaldanud teie seadmesse kolmandast isikust teenuseosutajad. Selliste küpsiste paigaldamist reguleerivad asjaomaste kolmandate isikute küpsisepõhimõtted. Teie seadmesse
            salvestavad küpsiseid järgmised kolmandad isikud.<br></br>
            <br></br>
            <b>Jälgimisfail</b>
            <br></br>
            Netrefer<br></br>
            <b>
              Privaatsuspõhimõtete link<br></br>
              Õiguslik alus
            </b>
            <br></br>
            Õigustatud huvi: kolmandatest isikutest turunduspartnerite tuvastamine.<br></br>
            <br></br>
            <b>Jälgimisfail</b>
            <br></br>
            Google Analytics<br></br>
            <b>
              Privaatsuspõhimõtete link<br></br>
              Õiguslik alus
            </b>
            <br></br>
            Õigustatud huvi: teenuste võimaldamine, optimeerimine ja parandamine.<br></br>
            <br></br>
            <b>Jälgimisfail</b>
            <br></br>
            Betradar<br></br>
            <b>
              Privaatsuspõhimõtete link<br></br>
              Õiguslik alus
            </b>
            <br></br>
            Õigustatud huvi: teenuste osutamise võimaldamine, optimeerimine ja osutamine.<br></br>
            <br></br>
            d. Küpsiste haldamine<br></br>
            <br></br>
            Enamik brausereid võtab küpsised automaatselt vastu. Siiski saate brauseri seadistada nii, et teie arvutisse ei salvestata üldse küpsiseid või enne uue küpsise loomist küsitakse teilt luba. Et küpsiseid
            desaktiveerida, järgige konkreetse käitaja juhiseid. Kui küpsised täielikult keelate, ei pruugi teil olla võimalik kasutada ettevõtte veebisaidi kõiki funktsioone. Lisateavet küpsiste, sealhulgas selle kohta,
            kuidas vaadata, millised küpsised on teie seadmesse paigutatud ning kuidas neid hallata ja kustutada, leiate veebisaidilt www.allaboutcookies.org.<br></br>
            <br></br>
            <h3 id="Security">6. ANDMETURVE</h3>
            We employ security measures to protect your information from access by unauthorized persons and to prevent unlawful processing, accidental loss, destruction and damage. We use state of the art technological
            security solutions, including encryption when transmitting sensitive information across the internet. This means that all personal and financial information sent to and from the Website are encrypted in
            accordance with good industry practice.<br></br>
            In order to protect both ourselves and our customers from identity theft we may verify the information you have provided with our banking institutions over secure lines. This will be carried out in accordance
            with our data protection obligations.<br></br>
            Where we have given you (or where you have chosen) a password which enables you to access certain parts of our websites, you are responsible for keeping the password, as well as any means to manage the
            password such as a registered email address, secure and confidential at all times.<br></br>
            As the security of some communications via the internet is not completely secure, we cannot guarantee the security of any information that you disclose using your internet connection. You accept the inherent
            security implications of using the internet and the Company will accept no liability for any direct, consequential, incidental, indirect, or punitive losses or damages arising out of such an occurrence.
            <br></br>
            While we are following best practice, including but not limited to implementing administrative, technical and physical measures to safeguard your Personal Data against loss, theft and misuse you should also
            take personal steps to protect the security of your data both physically and electronically by following common best practice procedures such as:<br></br>
            <br></br>
            <ListItem>Running Anti-Virus software and keeping it up to date;</ListItem>
            <ListItem>Applying operating system, web browser and other security updates;</ListItem>
            <ListItem>Ensuring your PC or other device is not left unattended whilst logged into our Websites; and</ListItem>
            <ListItem>Using strong passwords for all Services and logins.</ListItem>
            <br></br>
            When users submit sensitive information via the Website, their information is protected both online and offline. When our registration form asks users to enter sensitive information (such as a credit card
            number), that information is encrypted and is protected using Secure Socket Layer– (SSL) technology. While on a secure page, such as our deposit page, the lock icon at the bottom, or in the url, of your web
            browser becomes locked. The lock icon is unlocked, or open, when users are simply 'surfing' and are not inputting sensitive information. All payments made on our Site and Mobile Offer are secured, in
            compliance with the relevant EU rules and regulations.<br></br>
            While we use SSL encryption to protect sensitive information online, we also do everything in our power to protect user information offline. All of our users' information, not just the sensitive information
            mentioned above, is restricted in our offices. Only authorized employees/contractors are granted access to personally identifiable information. We perform checks on all of our potential employees/contractors
            to ensure that none of them has a criminal record. Our employees/contractors are regularly made aware of security best practices within the industry and security audits are carried out on a regular basis.
            Finally, the servers that store personally identifiable information are in a secure environment.<br></br>
            <br></br>
            Rakendame turvameetmeid, et kaitsta teie andmeid volitamata isikute juurdepääsu eest, samuti ebaseadusliku töötlemise, juhusliku kadumise, hävinemise ja kahjustada saamise eest. Internetis tundlikku teavet
            edastades kasutame uusimaid tehnoloogilisi turvalahendusi, sealhulgas krüptimist. See tähendab, et kõik veebisaidile ja veebisaidilt saadetavad isiku- ja finantsandmed on krüptitud valdkonna heade tavade
            järgi.<br></br>
            Et kaitsta nii ennast kui ka oma kliente identiteedivarguse eest, võime kontrollida turvaliste ühenduste kaudu teie esitatud teavet meie pangandusasutustes. Seda tehakse kooskõlas meie
            andmekaitsekohustustega.<br></br>
            Kui oleme teile andnud (või kui olete ise valinud) salasõna, mis võimaldab teil pääseda juurde meie veebisaitide teatavatele osadele, vastutate selle eest, et salasõna ja kõik salasõna haldamise vahendid,
            nagu registreeritud e-posti aadress, oleksid alati turvalised ja konfidentsiaalsed.<br></br>
            Kuna interneti kaudu toimuv teabevahetus ei ole alati täiesti turvaline, ei saa me tagada, et teave, mida avaldate oma internetiühenduse kaudu, on kaitstud. Nõustute interneti kasutamisega kaasnevate
            turvalisusprobleemidega ning sellega, et ettevõte ei võta vastutust mis tahes otseste, kaasnevate, juhuslike, kaudsete või karistuslike kahjude eest, mis tulenevad interneti kasutamisest.<br></br>
            Kuigi me järgime parimaid tavasid, rakendades muu hulgas halduslikke, tehnilisi ja füüsilisi meetmeid, et kaitsta teie isikuandmeid kadumise, varguse ja väärkasutuse eest, peaksite ka ise võtma meetmeid, et
            kaitsta oma andmeid nii füüsiliselt kui ka elektrooniliselt. Selleks järgige alltoodud parimaid tavasid:<br></br>
            <br></br>
            <ListItem>kasutage viirusetõrjetarkvara ja uuendage seda pidevalt;</ListItem>
            <ListItem>rakendage operatsioonisüsteemi, veebibrauseri ja muid turvauuendusi;</ListItem>
            <ListItem>ärge jätke oma arvutit või muud seadet järelevalveta, kui olete meie veebisaitidele sisse logitud;</ListItem>
            <ListItem>kasutage kõigis teenustes tugevaid salasõnu.</ListItem>
            <br></br>
            Kui kasutajad esitavad veebisaidi kaudu tundlikku teavet, kaitstakse nende andmeid nii veebis kui ka sellest väljaspool. Kui meie registreerimisvormis palutakse kasutajatel sisestada tundlikku teavet (näiteks
            krediitkaardi number), krüptitakse ja kaitstakse seda Secure Socket Layeri (SSL) tehnoloogia abil. Kui olete turvalisel lehel, näiteks meie sissemaksete lehel, kuvatakse teie veebibrauseri allservas või URL-i
            välja alguses lukustatud tabalukk. Tabalukk on avatud, kui kasutajad käivad veebisaidil lihtsalt ringi ega sisesta tundlikku teavet. Kõik meie saidil ja mobiilirakenduses tehtavad maksed on turvalised
            kooskõlas asjakohaste EL-i eeskirjade ja määrustega.<br></br>
            Kuigi me kasutame veebis tundliku teabe kaitsmiseks SSL-krüptimistehnoloogiat, teeme ka kõik endast oleneva, et kaitsta kasutajate andmeid väljaspool veebi. Juurdepääs kõigile meie kasutajate andmetele,
            sealhulgas tundlikule teabele, on piiratud. Isiku tuvastamist võimaldavatele andmetele pääsevad juurde ainult volitatud töötajad/töövõtjad. Kontrollime kõiki võimalikke töötajaid/töövõtjaid, tagamaks, et
            keegi neist ei ole kriminaalkorras karistatud. Meie töötajaid/töövõtjaid teavitatakse korrapäraselt valdkonna parimatest turvatavadest ja korrapäraselt tehakse ka turvaauditeid. Peale selle asuvad
            isikuandmeid sisaldavad serverid turvalises keskkonnas.<br></br>
            <br></br>
            <h3 id="Rights">7. MILLISED ON TEIE ÕIGUSED?</h3>
            1. Õigus saada teavet selle kohta, kas ja kuidas teie andmeid töödeldakse (üldmääruse artikkel 15).<br></br>
            <br></br>
            2. Õigus parandada ebatäpseid andmeid (üldmääruse artikkel 16).<br></br>
            <br></br>
            3. Õigus kustutada oma andmed („õigus olla unustatud“), kui üldmääruse artikli 17 tingimused on täidetud.<br></br>
            <br></br>
            4. Õigus piirata oma isikuandmete töötlemist kooskõlas üldmääruse artikliga 18.<br></br>
            <br></br>
            5. Õigus saada teada isikuandmete parandamisest, kustutamisest või isikuandmete töötlemise piiramisest (üldmääruse artikkel 19).<br></br>
            <br></br>
            6. Õigus andmeid üle kanda (üldmääruse artikkel 20).<br></br>
            <br></br>
            7. Õigus esitada vastuväiteid kooskõlas üldmääruse artikliga 21.<br></br>
            <br></br>
            8. Õigus võtta igal ajal tagasi antud nõusolek, mis kehtib edasise töötlemise kohta (üldmääruse artikkel 7).<br></br>
            <br></br>
            9. Õigus keelata otsused, mis põhinevad üksnes automatiseeritud töötlusel, sealhulgas profiilianalüüsil (üldmääruse artikkel 22).<br></br>
            <br></br>
            10. Õigus esitada järelevalveasutusele kaebus (üldmääruse artikkel 77). Kui arvate, et teie andmete töötlemine rikub üldmäärust või teie andmekaitseõigusi rikutakse muul viisil, võite igal ajal pöörduda meie
            kui vastutava töötleja, meie andmekaitseametniku või järelevalveasutuse poole (kontaktandmed leiate punktist 8 „KAEBUSED JA KONTAKTANDMED ANDMEKAITSEGA SEOTUD KÜSIMUSTE KORRAL“).<br></br>
            <br></br>
            <h3 id="Contact">8. KAEBUSED JA KONTAKTANDMED ANDMEKAITSEGA SEOTUD KÜSIMUSTE KORRAL</h3>
            1. Kui soovite esitada kaebuse, mis on seotud teie isikuandmete kasutamise, töötlemise või mis tahes muu andmetöötluse või privaatsusega seotud küsimusega, mille puhul te ei ole meie, meie teenuse või meie
            veebisaidi/-saitidega rahul, võtke ühendust meie andmekaitseametnikuga või esitage kaebus Andmekaitse Inspektsioonile, nagu on kirjeldatud allpool. Andmekaitse Inspektsiooni sõnul peavad asjaosalised esmalt
            üritama probleemi lahendada omavahel. Seega soovitame teil enne Andmekaitse Inspektsiooni veebisaidil kaebuse esitamist võtta kõigepealt ühendust meie andmekaitseametnikuga, et saaksime asja hoolikalt uurida.
            <br></br>
            <br></br>
            2. Meie vastutava andmekaitseametnikuga saate ühendust võtta e-posti aadressil dpo@vanalauri.com.<br></br>
            <br></br>
            3. Andmekaitse Inspektsiooniga saate ühendust võtta alltoodud kontaktandmete kaudu või esitada kaebuse otse, järgides Andmekaitse Inspektsiooni veebilehel https://www.aki.ee esitatud juhiseid.<br></br>
            <br></br>
            <b>Andmekaitse Inspektsioon</b>
            <br></br>
            <br></br>
            Telefon: +372 627 4135<br></br>
            E-posti aadress: info@aki.ee<br></br>
            Postiaadress: Tatari 39, 10134 Tallinn<br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(PrivacyPolicy));
