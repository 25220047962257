/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconSJ = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g>
					<path d="M1791.2,318.2c7,87.9-58.6,164.9-146.6,171.9c-87.9,7-164.9-58.6-171.9-146.6s58.6-164.9,146.6-171.9
	C1707.3,164.6,1784.2,230.2,1791.2,318.2z M1843.5,630.6l-980.4,647.6c26.1-60.9,64-138.7,116.6-224.5c28.1-7.3,53.7-24.8,70.7-51.1
	c29.3-45.4,124.8-177.9,213.9-245.5c116.6-88.4,190.2-130,190.8-130.3c58-32.3,78.9-105.4,46.6-163.4
	c-32.3-58-105.4-78.8-163.4-46.6c-1.8,1-25.7,14.4-66.8,41.1L757.1,586.3c-45,11.2-72.3,56.8-61.1,101.7
	c10.1,40.4,47.8,66.5,88,63.3c4.6-0.4,9.2-1.1,13.8-2.3L975,704.9C902.4,789,851.6,867.4,848.3,872.5
	c-19.4,30.2-23.5,65.8-14.4,97.8c-131.4,215.5-178.7,385.8-181.1,394.6c-4.8,17.8-3.5,35.7,2.6,51.7l-501.5,320.8
	c-20.1,13.4-25.6,40.6-12.2,60.8l7.5,11.2c13.4,20.1,40.6,25.6,60.8,12.2l556.3-357.3c9.8-4.1,18.8-10.1,26.4-17.6l1034-683.3
	c40.3-26.8,51.2-81.2,24.3-121.5L1843.5,630.6z"/>
				</g>
			</svg>
		</div>
	);
};

export default IconSJ;
