/**
 * Reducer for labels
 */

import _ from "lodash";
import {
  GET_SPORTS_LABELS,
  GET_MARKET_LABELS,
  GET_MARKET_SPECIFIERS,
  GET_SELECTION_LABELS,
  GET_SELECTION_VIEWER_LABELS,
  GET_SELECTION_PRINT_LABELS,
  GET_HC_LABELS,
  GET_USER_BONUSES,
  GET_CASHOUT_AVAILABILITY,
} from "../actions/types";

const fn = (state = {}, action) => {
  let newState = {};
  let stateCopy = {};
  switch (action.type) {
    case GET_SPORTS_LABELS:
      stateCopy = _.cloneDeep(state);
      newState = { ...stateCopy.sports, default: action.payload };
      return { ...stateCopy, sports: newState };

    case GET_MARKET_LABELS:
      stateCopy = _.cloneDeep(state);
      newState = { ...stateCopy.market, viewer: action.payload };
      return { ...stateCopy, market: newState };

    case GET_MARKET_SPECIFIERS:
      stateCopy = _.cloneDeep(state);
      newState = { ...stateCopy.market, specifiers: action.payload };
      return { ...stateCopy, market: newState };

    case GET_SELECTION_LABELS:
      stateCopy = _.cloneDeep(state);
      newState = { ...stateCopy.selection, default: action.payload };
      return { ...stateCopy, selection: newState };

    case GET_SELECTION_VIEWER_LABELS:
      stateCopy = _.cloneDeep(state);
      newState = { ...stateCopy.selection, viewer: action.payload };
      return { ...stateCopy, selection: newState };

    case GET_SELECTION_PRINT_LABELS:
      stateCopy = _.cloneDeep(state);
      newState = { ...stateCopy.selection, print: action.payload };
      return { ...stateCopy, selection: newState };

    case GET_HC_LABELS:
      stateCopy = _.cloneDeep(state);
      newState = { ...stateCopy.hc, default: action.payload };
      return { ...stateCopy, hc: newState };

    case GET_USER_BONUSES:
      stateCopy = _.cloneDeep(state);
      return { ...stateCopy, userBonuses: action.payload };

    case GET_CASHOUT_AVAILABILITY:
      stateCopy = _.cloneDeep(state);
      return { ...stateCopy, cashoutAvailability: action.payload };

    default:
      return state;
  }
};

export default fn;
