// Sports tabs
import { styled, Tab, Tabs } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import sportsIcons from "./sportsIcons";
import { useSelector } from "react-redux";

const MuiTab = styled(Tab)(({ theme }) => ({
  minHeight: "3em",
  padding: "0.3em 0.4em",
  minWidth: 0,
  fontSize: "1.3em",
  "@media (hover:hover)": {
    fontSize: "1.2em",
    padding: "0.5em 1.2em 0.5em 1em",
  },
}));

const SportsTabs = ({ initialCode, sportCodes = [] }) => {
  // router hooks
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  // Selectors
  const selectedSport = searchParams.get("sport-code") || initialCode;
  const sportLabels = useSelector((state) => state.labels.sports);

  // functions
  const handleTabChange = (event, newValue) => {
    searchParams.set("sport-code", newValue);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  return (
    <Tabs
      value={selectedSport}
      onChange={handleTabChange}
      // TabIndicatorProps={{
      //   style: {
      //     display: "none",
      //   },
      // }}
    >
      {sportCodes.map((code) => {
        return <MuiTab key={code} value={code} icon={sportsIcons[code]} iconPosition="top" />;
      })}
    </Tabs>
  );
};

export default SportsTabs;
