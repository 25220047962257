/**
 * Payment modal component ( showing after clicking deposit button in Payment page )
 */
import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";

const styles = (theme) => ({
  paper: {
    position: "absolute",
    width: "100vW",
    height: "100vH",
    top: "56px",
    left: "0",
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
});

class PaymentsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
    };
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({ open: true });
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  /**
   * Dynamically inject a form and submit it with values
   */
  renderForm = () => {
    const { url, params } = this.props;

    // do action
    let submitUrl = atob(url);
    let submitParams = JSON.parse(atob(params));

    if (submitUrl && submitParams) {
      let form = document.createElement("form");
      form.setAttribute("method", "post");
      form.setAttribute("action", submitUrl);
      form.setAttribute("id", "betworxPurchaseForm");
      document.getElementsByTagName("body")[0].appendChild(form);

      for (let key in submitParams) {
        let input = document.createElement("input");
        input.setAttribute("type", "hidden");
        input.setAttribute("name", key);
        input.setAttribute("value", submitParams[key]);
        document.getElementById("betworxPurchaseForm").appendChild(input);
      }

      document.getElementById("betworxPurchaseForm").submit();
    }
  };

  renderBody = () => {
    const { classes, url } = this.props;

    return (
      <Fade in={this.state.open}>
        <div className={classes.paper} id="purchaseFormWrapper">
          {/* {this.renderForm()} */}
          <iframe
            id="modalframe"
            src="../PaymentsGateway.html"
            url={url}
            style={{ width: "100%", height: "100%" }}
            title="modalframe"
          ></iframe>
        </div>
      </Fade>
    );
  };

  render() {
    log(this.constructor.name, "render");
    return (
      <div>
        {/* <button type="button" onClick={handleOpen}>
    Open Modal
    </button> */}
        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          {this.renderBody()}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    url: ownProps.url,
    params: ownProps.params,
    open: ownProps.open,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(PaymentsModal));
