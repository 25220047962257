import { Box } from "@mui/material";
import preloader from "../media/img/preloader.svg";

const PageLoader = () => {
  return (
    <Box minHeight="80vh" display="flex" justifyContent="center" alignItems="center">
      {/* Loading... */}
      <img src={preloader} alt="Loading..." style={{ width: "100px", height: "100px" }} />
    </Box>
  );
};

export default PageLoader;
