import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Box, Grid2, IconButton, Stack, styled } from "@mui/material";
import Iconify from "../../iconify";
import { useDispatch, useSelector } from "react-redux";
import { getWalletBalanceDetails } from "../../../actions";
import { getCustomerFreeSpinsAsync, getCasinoGameAsync } from "../../../redux-store/casino-reducer";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import LikeButton from "../components/LikeButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledGameContainer = styled("div")(({ isFullActive, layout, theme, headerHeight }) => ({
  overflow: "hidden",
  background: theme.palette.background.default,
  height: `100vh`,
  "@media (hover:none) and (orientation:landscape)": {
    height: `100vh`,
  },
  ...(isFullActive &&
    layout === "mouse" && {
      width: "100dvw",
      height: "100vh",
      "@media (hover:none) and (orientation:landscape)": {
        height: "100vh",
      },
    }),
  ...(layout !== "mouse" && {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 100000,
    outline: "hidden",
    width: "100dvw",
    height: `${100 - (headerHeight * 100) / window.innerHeight}dvh`,
  }),
}));

const CasinoGameView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const handle = useFullScreenHandle();

  const auth = useSelector((state) => state.user.auth);
  const layout = useSelector((state) => state.settings.layout);
  const lang = useSelector((state) => state.i18n.locale);
  const selectedGame = useSelector((state) => state.casino.selectedGame.data);

  const dialogType = searchParams.get("dialog");
  const gameId = searchParams.get("game-id");
  const bonusType = searchParams.get("bonus");

  const handleClose = () => {
    searchParams.delete("dialog");
    searchParams.delete("game-id");
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  React.useEffect(() => {
    if (auth.isSignedIn) {
      dispatch(getCasinoGameAsync({ gameId, type: "REAL", layout }));
    }
  }, [gameId]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getWalletBalanceDetails("CasinoGameView"));
      if (bonusType === "free-spins") {
        dispatch(getCustomerFreeSpinsAsync());
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const gameURL = selectedGame?.url;

  if (!auth.isSignedIn) return <Navigate to={`/${lang}/sign-in?redirect_to=casino/dashboard?dialog=play-game&game-id=${gameId}`} />;

  return (
    <Dialog fullScreen open={dialogType === "play-game"} onClose={handleClose} TransitionComponent={Transition}>
      <Box>
        <Grid2 container>
          <Grid2 item size={11.5}>
            <FullScreen handle={handle}>
              <StyledGameContainer isFullActive={handle.active} layout={layout} headerHeight={0}>
                {gameURL && <Box component="iframe" id="modalframe" src={gameURL} width="100%" height="100%" border={0} title="modalframe" />}
                <Box display={{ xs: "block", md: "none" }} position="absolute" top={15} left={10}>
                  <IconButton onClick={handleClose}>
                    <Iconify width={25} icon="ic:round-close" />
                  </IconButton>
                </Box>
              </StyledGameContainer>
            </FullScreen>
          </Grid2>
          <Grid2 item size={{ xs: 12, md: 0.5 }}>
            <Stack display={{ xs: "none", md: "flex" }} alignItems="center" width="100%" py={3} spacing={3}>
              <IconButton onClick={handleClose}>
                <Iconify icon="ic:round-close" />
              </IconButton>
              <IconButton onClick={handle.enter}>
                <Iconify icon="octicon:screen-full-24" />
              </IconButton>
              <LikeButton gameId={Number(gameId)} />
            </Stack>
          </Grid2>
        </Grid2>
      </Box>
    </Dialog>
  );
};

export default CasinoGameView;
