// Casino games by tab filter
// @react
import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
// @redux
import { useDispatch, useSelector } from "react-redux";
// actions
import { getCasinoGamesByFeatureAsync, resetCasinoFeatureGames } from "../../../redux-store/casino-reducer";
// i18n
import { I18n } from "react-redux-i18n";
// @mui
import { Box, Grid2, Typography } from "@mui/material";
// @components
import CasinoGameCard from "../components/CasinoGameCard";

const CasinoFilterView = () => {
  const observerTarget = useRef(null);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  // get route params
  const filterValue = searchParams.get("filter");

  // selectors
  const gamesList = useSelector((state) => state.casino.gamesByFeature.list);
  const currentPage = useSelector((state) => state.casino.gamesByFeature.page);
  const pageSize = useSelector((state) => state.casino.gamesByFeature.pageSize);
  const pageCount = useSelector((state) => state.casino.gamesByFeature.pageCount);
  const groupId = useSelector((state) => state.casino.gamesByFeature.groupId);

  // games loader
  const getCasinoGames = () => {
    if (!filterValue || currentPage > pageCount) return;
    let params = {};
    params["groupName"] = filterValue;
    params["page"] = currentPage;
    params["size"] = pageSize;
    dispatch(getCasinoGamesByFeatureAsync(params));
  };

  useEffect(() => {
    dispatch(resetCasinoFeatureGames(filterValue));
  }, [filterValue]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          getCasinoGames();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, filterValue, currentPage, groupId]);

  return (
    <Box>
      <Typography variant="h2" m={2}>
        {I18n.t(`slots.${filterValue}`)}
      </Typography>
      <Grid2 container>
        {gamesList.map((gameData, indx) => (
          <Grid2 item size={{ xs: 6, sm: 4, md: 3 }} key={indx}>
            <Box p={1}>
              <CasinoGameCard game={gameData} />
            </Box>
          </Grid2>
        ))}
        <Box ref={observerTarget} />
      </Grid2>
    </Box>
  );
};

export default CasinoFilterView;
