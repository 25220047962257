export const getPlayerPropsOdds = (marketSetConfig, game) => {
  let currentMarketSetValue =
    marketSetConfig[game.sport_code]["sidebets"]["player"];
  const marketSetValue = currentMarketSetValue?.includes(",")
    ? currentMarketSetValue?.split(",")
    : currentMarketSetValue;
  const selectedOdds = game.odds?.filter(function (odd) {
    return marketSetValue?.includes(odd.m);
  });

  const singlePlayerOdds = selectedOdds.filter(
    (sOdd) => sOdd.s.split(",").length === 1
  );
  const multiPlayerOdds = selectedOdds.filter(
    (sOdd) => sOdd.s.split(",").length > 1
  );

  const singlePlayersGroupedByMarket = singlePlayerOdds.reduce(
    (result, currentObject) => {
      const marketId = currentObject?.m;
      if (!result[marketId]) {
        result[marketId] = [];
      }
      result[marketId].push(currentObject);
      return result;
    },
    {}
  );

  const sortedSingles = {};

  Object.keys(singlePlayersGroupedByMarket)?.forEach((marketKey) => {
    const groupedBySelections = singlePlayersGroupedByMarket[marketKey]?.reduce(
      (result, currentObject) => {
        const selectionId = currentObject?.s;
        if (!result[selectionId]) {
          result[selectionId] = [];
        }
        result[selectionId].push(currentObject);
        return result;
      },
      {}
    );
    sortedSingles[marketKey] = groupedBySelections;
  });

  const groupedByMarket = multiPlayerOdds.reduce((result, currentObject) => {
    const marketId = currentObject?.m;
    if (!result[marketId]) {
      result[marketId] = [];
    }
    result[marketId].push(currentObject);
    return result;
  }, {});

  return {
    odds: sortedSingles,
    multiOdds: groupedByMarket,
  };
};
// Check if odd is selected
export const checkSelectedOdd = (bets, game, odd, index) => {
  // console.log(odd);

  let payload = {
    gameId: game.gameid,
    // isLive: game.isLive
  };

  const marketId = odd.m;
  const selectionIds = odd.s.split(",");
  const prices = odd.p.split(",");
  const hc = odd.hc;
  const shc = odd.shc;
  const isActives = odd.iA.split(",");

  if (selectionIds.length < 3 && selectionIds.length > 1) {
    selectionIds.unshift(null);
    prices.unshift(null);
    isActives.unshift(null);
  } else {
    selectionIds.push(null);
    prices.push(null);
    isActives.push(null);
    selectionIds.push(null);
    prices.push(null);
    isActives.push(null);
  }

  if (Number(prices[index]) < 1) return false;
  payload.marketId = Number(marketId);
  payload.selectionId = Number(selectionIds[index]);
  payload.price = Number(prices[index]);
  payload.hc = hc;
  if (shc) {
    payload.shc = shc;
  }

  let flag = false;

  if (!bets) return flag;

  for (let i = 0; i < bets.length; i++) {
    let bet = bets[i];
    if (
      bet.gameId === payload.gameId &&
      bet.marketId === payload.marketId &&
      bet.selectionId === payload.selectionId &&
      bet.hc === payload.hc &&
      bet.shc === payload.shc
    ) {
      flag = true;
      break;
    }
  }

  return flag;
};

// get odds selection labels

export const getSelectionLabel = (selectionPrintLabels, id) => {
  if (selectionPrintLabels && selectionPrintLabels[id]) {
    if (selectionPrintLabels[id].indexOf("{$competitor1}") > -1) {
      return selectionPrintLabels[id].replace("{$competitor1}", "1");
    } else if (selectionPrintLabels[id].indexOf("{$competitor2}") > -1) {
      return selectionPrintLabels[id].replace("{$competitor2}", "2");
    } else {
      return selectionPrintLabels[id];
    }
  }
  return "";
};

// format odds

export const formatPlayerPropsOdds = (odds, shouldSortOdds) => {
  let formattedOdds = [];

  formattedOdds = odds?.map((odd, key) => {
    const generatedOdds = [];
    odd?.s?.split(",").forEach((_, indx) => {
      generatedOdds.push({
        hc: odd?.hc,
        iA: odd?.iA?.split(",")[indx],
        p: odd?.p?.split(",")[indx],
        s: odd?.s?.split(",")[indx],
        shc: odd?.shc,
        sn: odd?.sn?.split(",")[indx],
        m: odd?.m,
        iF: odd?.iF,
        isPlayerProps: true,
      });
    });
    return generatedOdds;
  });
  if (shouldSortOdds) {
    formattedOdds = formattedOdds.sort((a, b) => {
      if (a.sn < b.sn) {
        return -1;
      }
      if (a.sn > b.sn) {
        return 1;
      }
      return 0;
    });
  }
  return formattedOdds;
};
