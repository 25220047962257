import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const CategoryWinOftenIcon = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 200 200"
      {...props}
    >
      <path d="M50.5,119.1v-15c0-27.1,22.2-49.4,49.5-49.4s49.5,22.3,49.5,49.4v15c0,8.4,6.8,15.4,15.4,15.4s14,6.2,14,14
	s-6.2,14.1-14,14.1H35.1c-7.6,0-14-6.3-14-14.1s6.3-14,14-14S50.5,127.5,50.5,119.1L50.5,119.1z M67.6,185.9
	c-9.5,0-17.1-7.6-17.1-17.1h34.1C84.6,178.3,77,185.9,67.6,185.9L67.6,185.9z M100,14.1c9.2,0,16.7,7.5,16.7,16.7
	s-7.5,16.6-16.7,16.6s-16.6-7.5-16.6-16.6S90.8,14.1,100,14.1L100,14.1z"/>    </SvgIcon>
  );
};

export default CategoryWinOftenIcon;
