import React from "react";
import { Box, Stack, Tab, Typography } from "@mui/material";
import CountryAvatar from "./CountryAvatar";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Translate } from "react-redux-i18n";
import RacingRegularLayout from "../racing/regular-layout";
import RacingResults from "../racing/results";
import TrottingSystemLayout from "../racing/system-layout";

const RacingLeague = ({ leagueId, label, labelCategory, countryCode, games, results }) => {
  const [tabValue, setTabValue] = React.useState("regular-layout");

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box>
      <Stack direction="row" alignItems="center" gap={1} pl={1}>
        <CountryAvatar countryCode={countryCode} />
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="subtitle1" component="p">
            {labelCategory}
          </Typography>
          <Typography variant="body2" color="text.secondary" component="p" noWrap>
            {label}
          </Typography>
        </Stack>
      </Stack>
      <TabContext value={tabValue}>
        <Box
          pl={1}
          sx={{
            "& .MuiTab-root": {
              fontWeight: "400",
            },
            "& .Mui-selected": {
              color: "primary.menuTopActive",
              fontWeight: "400",
            },
          }}
        >
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label={<Translate value="marketSets.outrights" />} value="regular-layout" />
            <Tab label={<Translate value="slip.system" />} value="system-layout" />
            <Tab label={<Translate value="menu.results" />} value="results" />
          </TabList>
        </Box>
        <TabPanel value="regular-layout">
          <RacingRegularLayout leagueId={leagueId} gamesList={games || []} />
        </TabPanel>
        <TabPanel value="system-layout">
          <TrottingSystemLayout leagueId={leagueId} gamesList={games || []} />
        </TabPanel>
        <TabPanel value="results">
          <RacingResults leagueId={leagueId} results={results || {}} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default RacingLeague;
