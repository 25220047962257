/**
 * BonusTerms - Estonian version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import { ListItem } from "@mui/material";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class BonusTerms extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>NUBET.COM-i SPORDIENNUSTUSE BOONUSE TINGIMUSED</h1>
            Eestikeelne versioon 1.1, viimati uuendatud 27. jaanuaril 2023.<br></br>
            <br></br>
            <h3 id="General">1. ÜLDTEAVE</h3>
            1.1. Siinsed spordiennustuse tingimused kehtivad Nubeti spordikihlvedude kohta. Spordikihlvedude boonust ei saa kasutada muude toodete, näiteks Nubeti kasiino toodete jaoks. Kui teil on aktiivne spordikihlveo
            boonus, saate Nubeti kasiinos või mujal mängida ainult siis, kui kannate kontole lisaraha.<br></br>
            <br></br>
            1.2. Siinsetes spordiennustuse boonuse tingimustes nimetatud boonust pakub Nubet veebisaidil nubet.com, mida haldab Eestis registreeritud ettevõte Vana Lauri OÜ, registrikood 16427120, registrijärgne aadress
            Roseni 10-135, 10111 Tallinn, Estonia (edaspidi: <b>Nubet</b> või <b>ettevõte</b>). Vana Lauri OÜ tegutseb Eesti Maksu- ja Tolliameti järelevalve all ja järgmiste tegevuslubade alusel: HKT000066 (välja antud
            10.07.2022) ja HKT000067 (välja antud 10.07.2022).<br></br>
            <br></br>
            1.3. Nubet võib teatavate tingimuste täitmise korral anda teile mis tahes tüüpi spordiennustuse boonuse krediiti või muud tüüpi boonuseid, nagu tasuta panuseid (edaspidi: <b>boonusraha</b>, <b>boonus</b> või{" "}
            <b>spordiennustuse boonus</b>). Mängija peab ise otsustama, kas ta soovib boonust kasutada, ja soovi korral võib ta boonusest loobuda, jättes boonuspakkumise kasutamata. Iga antud spordiennustuse boonuse
            puhul kehtivad boonuse kasutamisega seotud panustamistingimused (edaspidi: <b>panustamine</b>), millest teavitatakse mängijat varem veebisaidi ja e-posti teel. Kõik antud spordiennustuse boonused kantakse
            mängukontole automaatselt boonusrahana, mida reguleerivad spordiennustuse boonuse tingimustes määratletud reeglid.<br></br>
            <br></br>
            <h3 id="Tc">2. SPORDIENNUSTUSE BOONUSE ÜLDTINGIMUSED</h3>
            2.1. Siinsed spordiennustuse boonuse üldtingimused kehtivad Nubeti antud mis tahes tüüpi spordiennustuse boonuste kohta. Mõne boonuse suhtes kohaldatakse eraldi tingimusi, mis võivad erineda spordiennustuse
            boonuse üldtingimustest ja mis on sätestatud spordiennustuse boonuse tingimuste asjakohastes jaotistes, näiteks spordiennustuse tervitusboonuse või spordiennustuse sissemakseboonuse jaotises. Vastuolude
            korral kohaldatakse konkreetse boonusetüübi tingimusi. Pange tähele, et muu hulgas võivad erineda boonusetüüpide minimaalse panuse nõuded ja minimaalsed sissemaksesummad.<br></br>
            <br></br>
            2.2. Nubet soovib pakkuda spordiennustuse boonust kõigile oma mängijatele. Sellest hoolimata on ettevõttel õigus oma ainuäranägemise järgi keelata üksikutel mängijatel boonust kasutamast. Ettevõte võib oma
            ainuäranägemisel tühistada mis tahes boonuse ja sellega seotud võidud, kui ettevõte avastab, et mängija ei järgi boonusereegleid. Ettevõte võib igal ajal ilma ette teatamata muuta boonuse tingimusi või
            lõpetada selle pakkumise.<br></br>
            <br></br>
            2.3. Nubeti spordiennustuse boonus kehtib ja seda tuleb kasutada teatava kampaaniaperioodi jooksul. Kui seda ei kasutata, siis boonus aegub ja seda ei saa hiljem rakendada.<br></br>
            <br></br>
            2.4. Nubeti spordiennustuse boonus on saadaval ainult üks kord kliendi, mängijakonto, majapidamise, sissemakse tegemiseks kasutatud ülekandemeetodi ja/või konto omaniku, ühise arvuti (ülikool, töökoht,
            internetikohvik jne) ja/või IP-aadressi kohta.<br></br>
            <br></br>
            2.5. Nubeti spordiennustuse boonust ei tohi kombineerida teiste boonustega, kui sõnaselgelt ei ole teisiti märgitud, ega kasutada koos muude kampaaniatega.<br></br>
            <br></br>
            2.6. Nubeti spordiennustuse boonuse kampaaniapakkumise, mis saadetakse e-kirjaga, võib lunastada ainult boonuse e-kirja algne saaja. Boonuse saanud ja seda kasutav e-posti aadress peab ühtima mängijakonto
            loomisel kasutatud e-posti aadressiga. Nubet jätab endale õiguse uurida, kas boonuskood saadeti isiklikult spordiennustuse boonuse pakkumist kasutanud mängukonto omanikule mängukontol kasutatud e-posti
            aadressile. Kui seda reeglit rikutakse, võidakse boonus ja sellega seotud võidud konfiskeerida.<br></br>
            <br></br>
            2.7. Nubeti spordiennustuse boonus, mis on seotud sissemakse tegemisega, kehtib ainult Nubeti pakutavate maksemeetodite kasutamise korral ega ole spordiennustuse boonuse pakkumisest maksemeetodina välistatud.
            Kontoomanik ja maksemeetodi omanik peavad olema samad. Mängukonto krediidijääki, mis koosneb ainult mängija tehtud sissemaksetest (mitte boonustest) ja mis seega ei ole seotud boonuse tingimustega,
            nimetatakse pärisrahajäägiks (edaspidi: <b>pärisrahajääk</b>).<br></br>
            <br></br>
            2.8. Nubeti spordiennustuse boonus kajastub teie mängukonto jäägis. Kui spordiennustuse boonus on panustatud, ei saa kontojääki välja võtta ega konverteerida seda pärisrahajäägiks enne, kui panustamine on
            lõpetatud, nagu on sätestatud boonuse tingimustes. Kui spordiennustuse boonus antakse sissemakse eest, kehtib see ka sissemaksesumma kohta, kuna spordiennustuse boonuse tingimused kehtivad mõlema kohta.
            <br></br>
            <br></br>
            2.9. Kui sissemakse on suurem kui spordiennustuse boonuse pakkumise maksimaalne summa, kehtivad spordiennustuse boonuse tingimused kogu sissemaksesumma kohta. Näide: kui saate kuni 100-eurose 100%
            spordiennustuse boonuse ja kannate kontole 150 eurot, kohaldatakse spordiennustuse boonuse panustamise nõudeid 150 euro suhtes. Selles näites on spordiennustuse boonuse summa 100 eurot.<br></br>
            <br></br>
            2.10. Panustamisnõuet täidavad ainult arveldatud panused. Kui panus makstakse tagasi, sest sündmus peatatakse, tühistatakse, kuulutatakse kehtetuks või ilmneb mõni muu põhjus, miks mängu panust ei saa
            tavapäraselt arveldada, ei täida panusesumma panustamisnõuet. Sama kehtib panuste kohta, millest on raha väljavõetud.<br></br>
            <br></br>
            2.11. Kui samale sündmusele on tehtud kaks või enam panust eri tulemuste suhtes, täidab panustamisnõuet ainult esimene panus.<br></br>
            <br></br>
            2.12. Kui mängija on panustanud aktiivse boonusega, ei saa lunastatud boonust ilma boonust kaotamata enam tühistada.<br></br>
            <br></br>
            2.13. Panustamisnõuet aitab täita ainult sündmusele pandud esimene panus. Panustamisnõude täitmisel ei võeta arvesse topelt- ega mitmikpanuseid. Süsteempanuseid saab panustamisnõude täitmisel arvesse võtta
            ainult osaliselt.<br></br>
            <br></br>
            2.14. Kui ei ole sätestatud teisiti, tuleb saadud kasiino spordiennustuse boonus enne selle väljamaksmist või väljamaksmise üritamist panustada vähemalt kuus (6) korda. Boonusrahaga teenitud võite ei maksta
            välja enne, kui boonuse panustamise tingimused on täidetud. Kui püüate boonust välja võtta enne boonuse panustamise nõuete täitmist, kaotate kõik boonusega saadud võidud ja boonuse enda.<br></br>
            <br></br>
            2.15. Boonuse panustamise nõuded tuleb täita kindlaksmääratud aja jooksul pärast boonuse saamist. Kui boonuse tingimusi ei täideta õigel ajal, jätab Nubet endale õiguse tühistada boonuse summa ja kõik sellest
            tulenenud võidud.<br></br>
            <br></br>
            2.16. Nubet jälgib, kas boonuseid kasutatakse ebahariliku mängumustri osana.<br></br>
            <br></br>
            2.17. Spordikihlvedude boonust väärkasutades rikutakse boonuse tingimusi. Nubet eeldab, et boonuse tingimusi on rikutud järgmistel juhtudel:<br></br>
            <br></br>
            <ListItem>sama isik kasutab boonust mitu korda või boonust kasutatakse mitu korda sama IP-aadressi all või seadmes;</ListItem>
            <ListItem>kahtlustatakse, et boonuspakkumist kasutab organisatsioon;</ListItem>
            <ListItem>tehakse panuseid (nn kindlaid panuseid), mis tagavad tulemustest olenemata spordiennustuse boonuse võidu üksikisikule või koostööd tegevale mängijaterühmale.</ListItem>
            <br></br>
            2.18. Kui Nubetil tekib kahtlus, et spordiennustuse boonuse tingimusi on rikutud või klient on teinud panuseid, mis tagavad spordiennustuse sissemakseboonuse, tasuta panuste või mis tahes muu
            reklaamipakkumise tõttu võidu, olenemata panuse tulemusest, jätab ettevõte endale õiguse nõuda spordiennustuse boonus tagasi, parandada või tühistada tehtud panuseid, keelduda taotletud väljamaksetest ja
            sulgeda mängija konto.<br></br>
            <br></br>
            2.19. Boonust ei kanta kontole, kui te ei tohi Nubeti pakkumises osaleda või pakkumine on peatatud.<br></br>
            <br></br>
            2.20. Spordikihlvedude boonus kehtib kuni panustamise lõpuni või boonuse tühistamiseni.<br></br>
            <br></br>
            2.21. Nubet jätab endale õiguse boonust ja kampaania kestust igal ajal muuta, peatada või tühistada.<br></br>
            <br></br>
            2.22. Kohaldatakse Nubeti tingimusi.<br></br>
            <br></br>
            <h3 id="Welcome">3. SPORDIENNUSTUSE TERVITUSBOONUSE TINGIMUSED</h3>
            3.1. Spordiennustuse tervitusboonuse pakkumist ei saa kasutada koos muude pakkumiste ega teise spordiennustuse tervitusboonuse pakkumisega.<br></br>
            <br></br>
            3.2. Nubeti spordiennustuse tervitusboonus antakse ainult kampaaniaperioodi jooksul ja esimese või teise sissemakse eest, mis tehakse uuele mängukontole.<br></br>
            <br></br>
            3.3. Minimaalne sissemakse on 20 eurot.<br></br>
            <br></br>
            3.4. Mängijal on sissemakse ja spordiennustuse tervitusboonuse pakkumise kasutamiseks aega 30 päeva alates registreerimisest.<br></br>
            <br></br>
            3.5. Kohe, kui klient on teinud sissemakse, kantakse Nubeti spordiennustuse tervitusboonus kliendi kontole summas, mis ühtib määratletud maksimaalse limiidi piires 100% sissemaksesummaga.<br></br>
            <br></br>
            3.6. Spordiennustuse tervitusboonuse summat tuleb enne väljamakse taotlemist panustada Nubeti spordikihlvedudes vähemalt kuus (6) korda. Arvutamise näide: kannate kontole 20 eurot ja saate meilt 20 eurot
            boonuskrediiti. Väljamakse taotlemiseks tuleb seega spordikihlvedudes panustada vähemalt 120 eurot.<br></br>
            <br></br>
            3.7. Kui pakkumises ei ole märgitud teisiti, peab iga nimekirjas oleva panuse koefitsient olema vähemalt 1.5, et panused läheksid panustamisnõude täitmisel arvesse. Panustamisnõuet ei täida panused, mille
            koefitsient on väiksem määratud minimaalsest panustamiskoefitsiendist.<br></br>
            <br></br>
            3.8. Spordiennustuse tervitusboonuse tingimustes sätestatud panustamisnõue tuleb täita 90 päeva jooksul (spordiennustuse tervitusboonuse periood) alates esimesest sissemaksest. Spordiennustuse tervitusboonuse
            perioodi lõppedes tühistatakse spordiennustuse tervitusboonus ja kõik sellega seotud võidud automaatselt ning neid ei tagastata.<br></br>
            <br></br>
            3.9. Spordiennustuse tervitusboonuse summa, k.a sellega seotud sissemakse ja võidud, kuvatakse Nubeti kontol kättesaamatu krediidijäägina kuni määratud panustamisnõuete täitmiseni. Kui väljamakset taotletakse
            enne, kui spordiennustuse tervitusboonuse panustamisnõue on täidetud, muutuvad spordiennustuse tervitusboonus ja kõik sellega seotud võidud kehtetuks ning need konfiskeeritakse ilma ette teatamata.<br></br>
            <br></br>
            3.10. Aktiivse boonusega on maksimaalne lubatud panus 100 eurot, kui ei ole märgitud teisiti.<br></br>
            <br></br>
            3.11. Panustamisnõuet aitab täita ainult sündmusele pandud esimene panus. Panustamisnõude täitmisel ei võeta arvesse topelt- ega mitmikpanuseid. Süsteempanuseid saab panustamisnõude täitmisel arvesse võtta
            ainult osaliselt.<br></br>
            <br></br>
            3.12. Spordiennustuse tervitusboonuse panustamisnõudeid saate täita ainult siis, kui panustate Nubeti spordikihlvedudes kooskõlas siinsete nubet.com-i spordiennustuse boonuse tingimustega ja teete panuseid
            koefitsientidega, mis ületavad minimaalse koefitsiendi nõuet.<br></br>
            <br></br>
            <h3 id="Deposit">4. SPORDIENNUSTUSE SISSEMAKSEBOONUSE TINGIMUSED</h3>
            4.1. Mängija saab valida spordiennustuse sissemakseboonuse sissemakse tegemise ajal vabatahtlikult. Järgige kindlasti spordiennustuse sissemakseboonuse koodi kasutamise juhiseid. Sissemakse tegemisel
            kuvatakse saadaval spordiennustuse sissemakseboonuse valikud.<br></br>
            <br></br>
            4.2. Boonuse kasutamiseks tuleb kontole teha vähemalt 20-eurone sissemakse.<br></br>
            <br></br>
            4.3. Spordikihlvedude sissemakseboonus kehtib ainult kampaaniaperioodi jooksul ja seda ei saa kasutada hiljem.<br></br>
            <br></br>
            4.4. E-posti, kontoteate või muu teate kaudu saadetud Nubeti spordiennustuse sissemakseboonuse pakkumist võib lunastada ainult sissemakseboonuse pakkumise algne saaja. Nubet jätab endale õiguse uurida, kas
            sissemakseboonust kasutanud mängijakonto omanik sai boonuskoodi isiklikult, ning tühistada ja konfiskeerida selle reegli rikkumise korral boonus ja sellega seotud võidud.<br></br>
            <br></br>
            4.5. Teid teavitatakse spordiennustuse sissemakseboonuse tingimustest (näiteks kampaaniaperiood, spordiennustuse sissemakseboonuse protsent boonuse summast, mis ühtib sissemaksesummaga, ja maksimaalne
            boonusesumma eurodes) isikliku e-kirja, kontoteate, SMS-i või tõuketeate kaudu.<br></br>
            <br></br>
            4.6. Spordikihlvedude sissemakseboonuse summat tuleb enne väljamakse taotlemist panustada Nubeti spordikihlvedudes vähemalt kuus (6) korda. Arvutamise näide: kannate kontole 20 eurot ja saate meilt 20 eurot
            boonuskrediiti. Väljamakse taotlemiseks tuleb seega spordikihlvedudes panustada vähemalt 120 eurot.<br></br>
            <br></br>
            4.7. Kui pakkumises ei ole märgitud teisiti, peab iga nimekirjas oleva panuse koefitsient olema vähemalt 1.5, et panused läheksid panustamisnõude täitmisel arvesse. Panustamisnõuet ei pruugi täita panused,
            mille koefitsient on väiksem määratud minimaalsest panustamiskoefitsiendist.<br></br>
            <br></br>
            4.8. Spordiennustuse sissemakseboonuse tingimustes sätestatud panustamisnõue tuleb täita 30 päeva jooksul (spordiennustuse sissemakseboonuse periood) alates esimesest sissemaksest. Kui spordiennustuse
            sissemakseboonuse periood on lõppenud, muutuvad spordiennustuse sissemakseboonus ja kõik sellega seotud võidud automaatselt kehtetuks ning neid ei tagastata.<br></br>
            <br></br>
            4.9. Aktiivse boonusega on maksimaalne lubatud panus 100 eurot, kui ei ole märgitud teisiti. Panustamisnõuet aitab täita ainult sündmusele pandud esimene panus. Panustamisnõude täitmisel ei võeta arvesse
            topelt- ega mitmikpanuseid.<br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(BonusTerms));
