// player props catalog game box fo soccer
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Iconify from "../../iconify";
import PropTypes from "prop-types";
import GameBoxColumn from "../@components/GameBoxColumn";
import { Box } from "@mui/material";
import CatalogMarketsBox from "./CatalogMarketsBox";

const CatalogGameBox = ({ game }) => {
  const [expanded, setExpanded] = useState(false);
  // handle accardion
  const handleAccordion = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion disableGutters expanded={expanded} onChange={handleAccordion}>
      <Box pb={0.3} width="100%">
        <AccordionSummary
          expandIcon={<Iconify icon="ic:outline-expand-more" />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
            padding: 0,
          }}
        >
          <Box pl={0}>
            <GameBoxColumn
              game={[game]}
              content="selectedGamesTicket"
              isInViewport={true}
            />
          </Box>
        </AccordionSummary>
      </Box>
      <AccordionDetails>
        {expanded && <CatalogMarketsBox game={game} />}
      </AccordionDetails>
    </Accordion>
  );
};

CatalogGameBox.propTypes = {
  game: PropTypes.object.isRequired,
};

export default CatalogGameBox;
