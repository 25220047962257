/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconBM = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g>
					<path d="M1863.4,1061.3c-10.1-21.7-31.9-31.9-53.6-24.6c-8.7,1.4-84.1,0-156.5-182.6c-4.3-11.6-17.4-21.7-29-26.1h-1.4
	c-1.4,0-208.7-50.7-208.7-200v-1.4c-1.4-20.3-15.9-37.7-34.8-40.6c-1.4,0-1.4,0-2.9,0c-1.4,0-2.9,0-2.9,0h-1.4
	c-101.5,0-163.8-97.1-192.8-184.1c-7.2-21.7-10.1-44.9-33.3-55.1c-47.8-20.3-97.1-40.6-136.2-73.9c-18.8-15.9-36.2-36.2-43.5-62.3
	c-7.2-29,8.7-59.4-27.5-75.4c-21.7-10.1-47.8,1.4-58,23.2L372,1239.6l-158,158c-107.3,107.3-107.3,282.6,0,389.9
	c52.2,52.2,121.7,81.2,194.2,81.2s142-29,194.2-81.2l158-158l1081.2-508.7C1860.5,1109.1,1872.1,1083,1863.4,1061.3z M1515.6,883
	L1515.6,883l-633.4,434.8c-4.3,2.9-33.3-15.9-37.7-18.8c-15.9-11.6-33.3-26.1-39.1-46.4l536.3-536.3
	C1375,803.3,1453.3,854,1515.6,883z M1279.3,655.5L1279.3,655.5l-534.8,534.8c-34.8-10.1-55.1-52.2-63.8-75.4l434.8-633.4
	C1143.1,543.9,1193.8,622.1,1279.3,655.5z M738.7,1316.4L615.5,1412l-29-29l94.2-124.6c11.6,7.2,26.1,14.5,40.6,17.4
	C725.7,1290.3,731.5,1303.3,738.7,1316.4z M909.8,294.6c27.5,34.8,73.9,73.9,149.3,110.2l-449.3,655.1
	c-21.7-10.1-31.9-34.8-36.2-49.3L909.8,294.6z M528.6,1106.2c17.4,18.8,39.1,34.8,68.1,42c5.8,14.5,13,30.4,21.7,44.9l-95.7,126.1
	l-65.2-65.2L528.6,1106.2z M537.3,1723.6c-34.8,34.8-82.6,55.1-131.9,55.1c-49.3,0-97.1-18.8-131.9-55.1
	c-34.8-34.8-55.1-82.6-55.1-131.9s18.8-97.1,55.1-131.9L405.4,1328l265.2,265.2L537.3,1723.6z M741.6,1539.6l-63.8-63.8l126.1-97.1
	c14.5,8.7,30.4,15.9,44.9,21.7c7.2,29,23.2,50.7,42,68.1L741.6,1539.6z M986.6,1423.6c-14.5-4.3-39.1-15.9-49.3-36.2l655.1-449.3
	c36.2,75.4,75.4,121.7,110.2,149.3L986.6,1423.6z"/>
				</g>
			</svg>
		</div>
	);
};

export default IconBM;
