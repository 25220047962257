import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const CategoryWinOftenIcon = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 200 200"
      {...props}
    >
<path d="M120.1,134L120.1,134c42.7-0.2,57.9-22.8,57.9-42.2c0-10.9-7.7-20-18-22.2c0.9-2.5,1.7-4.8,2.2-6.6
	c2.3-7.8,0.8-15.9-4.1-22.5c-5-6.7-12.6-10.5-20.9-10.5H62.8c-8.3,0-15.9,3.8-20.9,10.5C37,47,35.5,55.2,37.8,63
	c0.5,1.7,1.3,4.1,2.2,6.6c-10.3,2.2-18,11.3-18,22.2c0,19.4,15.2,42,57.9,42.2c0.4,2,0.6,4,0.6,6v20c0,11.9-10,12.9-13,13H61
	c-3.6,0-6.5,2.9-6.5,6.5c0,3.6,2.9,6.5,6.5,6.5h78c3.6,0,6.5-2.9,6.5-6.5c0-3.6-2.9-6.5-6.5-6.5h-6.4c-3.1-0.1-13.1-1.1-13.1-13v-20
	C119.5,137.9,119.7,135.9,120.1,134z M130.7,115.5c11.3-9.6,19.1-22.4,24.2-33.5c0.1,0,0.2,0.1,0.3,0.1c5.4,0,9.8,4.4,9.8,9.8
	c0,13.2-10.5,27.3-39.2,29.1C127.2,118.9,128.8,117.1,130.7,115.5z M35,91.7c0-5.4,4.4-9.8,9.8-9.8c0.1,0,0.2-0.1,0.3-0.1
	C50.2,93,58,105.9,69.3,115.5c1.9,1.6,3.5,3.4,5,5.3C45.5,119,35,105,35,91.7z M116.1,88.1c0.8,2.5-0.6,5.2-3.1,6
	c-1.5,0.5-3,0.2-4.3-0.7L100,87l-8.7,6.4c-1.7,1.2-3.9,1.2-5.6,0l0,0c-1.7-1.2-2.4-3.4-1.8-5.3l3.4-10.4l-8.9-6.5
	c-2.1-1.6-2.6-4.5-1-6.7c0.9-1.2,2.3-2,3.8-2h10.9l3.3-10.4c0.8-2.5,3.6-3.9,6.1-3c1.4,0.5,2.6,1.6,3,3l3.3,10.4h10.9
	c2.6,0,4.8,2.1,4.8,4.8c0,1.5-0.7,3-2,3.9l-8.9,6.5L116.1,88.1z"/>  </SvgIcon>
  );
};

export default CategoryWinOftenIcon;
