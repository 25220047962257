/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconHB = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g><path d="M1656,1081.2L1656,1081.2l-479.3-479.3c-7.3-7.3-15.1-13.8-23.3-19.9c51.6-38.7,85.3-100,85.3-169.3
	c0-116.8-95-211.8-211.8-211.8c-116.8,0-211.8,95-211.8,211.8c0,47.8,16.3,91.6,43.1,127.1H740.7L673,472.1
	c35.3-37.9,57.4-88.3,57.4-144.1c0-116.8-95-211.8-211.8-211.8c-116.8,0-211.8,95-211.8,211.8c0,29.5,6.1,57.6,17.1,83.1
	c-5.1,38.8,6.7,78,34.7,106l239.6,239.6c24,24,55.9,37.2,89.8,37.2h127.1v381.2c0,21.7,3.5,43.1,10.4,64.1L398,1666.8
	c-49.6,49.6-49.6,130.2,0,179.7c49.6,49.6,130.2,49.6,179.7,0l459.8-459.8c6.4-0.3,12.3-1.9,18.5-2.8l103.1,103.1l-179.7,179.7
	c-49.6,49.6-49.6,130.2,0,179.7s130.2,49.6,179.7,0l269.6-269.6c49.5-49.6,49.5-130.2,0-179.7l-192.9-192.9
	c1.4-9.7,2.9-19.2,2.9-29.2v-151.9l237.5,237.5c49.6,49.6,130.2,49.6,179.7,0C1705.5,1211.3,1705.6,1130.7,1656,1081.2z
	 M1026.9,285.7c70.1,0,127.1,57,127.1,127.1c0,70.1-57,127.1-127.1,127.1c-70.1,0-127.1-57-127.1-127.1
	C899.9,342.7,956.9,285.7,1026.9,285.7z M1368.8,1457.2c16.5,16.5,16.5,43.4,0,59.9l-269.6,269.6c-16,16-43.9,16-59.9,0
	c-16.5-16.5-16.5-43.4,0-59.9l209.7-209.7c8-8,12.4-18.7,12.4-29.9s-4.4-22-12.4-29.9l-105.8-105.8c23.8-15.8,44.5-35.8,60.2-59.6
	L1368.8,1457.2z M1596.1,1201c-16.6,16.5-43.5,16.5-59.9,0l-309.8-309.8c-12.1-12.2-30.3-15.8-46.2-9.2c-15.8,6.6-26.1,22-26.1,39.1
	v254.1c0,70.1-57,127.1-124.6,127.2c-1.5-0.2-6-0.7-7.5-0.7c-12-0.7-23.2,4.1-31.5,12.4l-472.7,472.6c-16,16-43.9,16-59.9,0
	c-16.5-16.5-16.5-43.4,0-59.9l447.4-447.4c12.8-12.8,16.1-32.4,8-48.6c-8.9-18.1-13.4-36.8-13.4-55.5V751.6
	c0-23.4-19-42.4-42.4-42.4H688.1c-11.3,0-21.9-4.4-29.9-12.4l-159-159c6.5,0.6,12.8,2,19.5,2c30,0,58.5-6.5,84.5-17.8l90.1,90.1
	c7.9,8,18.7,12.4,29.9,12.4h303.7c33.9,0,65.9,13.3,89.9,37.2l479.2,479.2C1612.6,1157.5,1612.6,1184.4,1596.1,1201z"/>
				</g>
			</svg>
		</div>
	);
};

export default IconHB;
