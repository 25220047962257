/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconFL = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g><path d="M1375.3,216.4C942.5,9.2,423.7,192,216.4,624.7C9.2,1057.5,192,1576.3,624.7,1783.6c432.8,207.2,951.6,24.4,1158.8-408.3
			C1990.8,942.5,1808,423.7,1375.3,216.4z M1162.3,261.3c25.2-43.7,93.3-51.6,152.1-17.7c58.8,33.9,86,96.8,60.8,140.5
			c-25.2,43.7-93.3,51.6-152.1,17.7C1164.3,367.9,1137.1,305,1162.3,261.3z M384.2,1375.2c-43.7,25.2-106.6-2-140.5-60.7
			c-33.9-58.8-26-126.9,17.7-152.1c43.7-25.2,106.6,2,140.5,60.7C435.8,1281.9,427.9,1350,384.2,1375.2z M401.9,776.9
			c-33.9,58.8-96.8,85.9-140.5,60.7c-43.7-25.2-51.6-93.3-17.7-152.1c33.9-58.8,96.8-86,140.5-60.7
			C427.9,650,435.8,718.1,401.9,776.9z M554.8,915.5c47.3-47.2,123.9-47.2,171.1,0c47.2,47.3,47.2,123.9,0,171.1
			c-47.2,47.3-123.8,47.3-171.1,0C507.5,1039.4,507.5,962.8,554.8,915.5z M837.6,1738.7c-25.2,43.7-93.3,51.6-152.1,17.7
			c-58.8-33.9-86-96.8-60.7-140.5c25.2-43.7,93.3-51.6,152.1-17.7C835.7,1632.1,862.9,1695,837.6,1738.7z M776.9,401.9
			c-58.8,33.9-126.9,26-152.1-17.7c-25.2-43.7,2-106.6,60.7-140.5c58.8-33.9,126.9-26,152.1,17.7C862.9,305,835.7,367.9,776.9,401.9
			z M1085.5,1446.3c-47.2,47.2-123.8,47.2-171.1,0c-47.2-47.3-47.2-123.9,0-171.1c47.3-47.3,123.9-47.3,171.1,0
			C1132.8,1322.4,1132.8,1399,1085.5,1446.3z M1085.5,727c-47.2,47.2-123.8,47.2-171.1,0c-47.2-47.2-47.2-123.8,0-171.1
			c47.3-47.2,123.9-47.2,171.1,0C1132.8,603.1,1132.8,679.7,1085.5,727z M1314.4,1756.4c-58.8,33.9-126.9,26-152.1-17.7
			c-25.2-43.7,2-106.6,60.7-140.5c58.8-33.9,126.9-26,152.1,17.7C1400.4,1659.5,1373.2,1722.4,1314.4,1756.4z M1445.2,1086.6
			c-47.3,47.3-123.9,47.3-171.1,0c-47.3-47.2-47.3-123.8,0-171.1c47.2-47.2,123.8-47.2,171.1,0
			C1492.5,962.8,1492.5,1039.4,1445.2,1086.6z M1756.4,1314.4c-33.9,58.8-96.8,86-140.5,60.7c-43.7-25.2-51.6-93.3-17.7-152.1
			c33.9-58.8,96.8-86,140.5-60.7C1782.4,1187.6,1790.3,1255.7,1756.4,1314.4z M1738.7,837.6c-43.7,25.2-106.6-2-140.5-60.7
			c-33.9-58.8-26-126.9,17.7-152.1c43.7-25.2,106.6,2,140.5,60.7S1782.4,812.4,1738.7,837.6z"></path></g>
			</svg>
		</div>
	);
};

export default IconFL;
