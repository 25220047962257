/**
 * Help - Estonian version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import ListItem from "@mui/material/ListItem";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class Help extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>ABI</h1>
            <h3>MINU MÄNGUKONTO</h3>
            Nubet.com on suunatud klientidele, kes peavad hasartmänge ja spordiennustusi lõbusaks ja meelelahutuseks ning oskavad oma panustamiskäitumist vastutustundlikult hinnata. Brauseri abil pääsete oma kontole
            juurde kõigis oma seadmetes. Kõik, mida vajame, on teie kasutajanimi ja parool. Pärast sisselogimist klõpsake paremas ülanurgas lihtsalt nuppu "Minu konto". Jaotises "Minu konto" saate teha sissemakseid,
            väljamakseid, kontrollida oma tehinguid, samuti muuta oma limiite ja muuta oma isikuandmeid.<br></br>
            <br></br>
            <h4>1. Sissemakse</h4>
            Riigi raha kontole kandmiseks saab kasutada järgmisi võimalusi:<br></br>
            <br></br>
            <ListItem>Trustly</ListItem>
            <br></br>
            <h4>2. Väljamakse</h4>
            Teie taganemistaotlust töödeldakse 24 tunni jooksul. Väljamaksemeetodina saab kasutada ainult neid võimalusi, millegasissemakse tehakse.<br></br>
            <br></br>
            <ListItem>Trustly</ListItem>
            <br></br>
            <h4>3. Väljavõte</h4>
            Selles valdkonnas näete oma kihlvedude ajalugu ja saate filtreerida kindla ajaperioodi kohta.<br></br>
            <br></br>
            <h4> 4. Tehingud</h4>
            Selles jaotises näete ülevaadet kogu oma arvelduskontoga seotud tegevusest. Samuti saate sirvida sisse- ja väljamaksete aegu.<br></br>
            <br></br>
            <h4> 5. Minu profiil</h4>
            Selles jaotises saate muuta oma isikuandmeid. Siin saate muuta oma aadressi või telefoninumbrit. Nime, sünniaega ja kodakondsust ei saa muuta. Pange tähele, et mõned muudatused nõuavad isikut tõendavat
            dokumenti. Kui vajate nendes jaotistes muudatusi, võtke ühendust meie tugitiimiga: support@nubet.com.<br></br>
            <br></br>
            <h4>6. Dokumendid</h4>
            Vahekaardil "Dokumendid" saate üles laadida dokumendid, mida Nubet vajab teie konto täielikuks kinnitamiseks. Meil on seadusest tulenev kohustus kontrollida iga registreeritud kliendi isikut. Kui tehingud
            jõuavad 2000 euro piirini, oleme seadusega kohustatud teostama kliendi isikusamasuse kontrolli. Väljamakseid ei töödelda, kui Nubet ei ole esitanud ja kontrollinud kliendi kohta vajalikke dokumente.<br></br>
            <br></br>
            <h3>SEADID</h3>
            <h4>1. Muuda salasõna</h4>
            Saate oma salasõna igal ajal muuta. Järgige lihtsalt juhiseid, klõpsates nuppu "Muuda salasõna". Kui olete oma salasõna unustanud, klõpsake nuppu "Unustasite salasõna?" ja järgige juhiseid. Kui olete oma
            kasutajanime unustanud, klõpsake nuppu "Unustasite oma kasutajanime?" ja teie kasutajanimi saadetakse e-posti aadressile, kus registreerusite.<br></br>
            <br></br>
            <h4>2. Piirid</h4>
            Kontrollige oma hasartmänge. Vastutustundliku mängimise võimaldamiseks pakume mitmeid võimalusi.<br></br>
            <br></br>
            <h4>3. Panuslimiit</h4>
            Siin saate määrata maksimaalse summa, mida soovite teatud aja jooksul mängida.<br></br>
            <br></br>
            <h4>4. Kaotuslimiit</h4>
            Siin saate määrata maksimaalse summa, mille võite teatud aja jooksul kaotada.<br></br>
            <br></br>
            <h4>5. Automaatne väljalogimine</h4>
            See funktsioon võimaldab teil reguleerida aega, mil süsteem teid automaatselt välja logib. 1, 2, 5, 12 või 24 tunni pärast.<br></br>
            <br></br>
            <h4>6. Sissemakselimiit</h4>
            Sellel väljal saate määrata sissemakse limiidid teatud perioodiks. Pakume päeva-, nädala- või kuuhoiuselimiite.<br></br>
            <br></br>
            <h4>7. Blokeeri konto</h4>
            Saate oma konto teatud ajaks blokeerida. Teie konto avatakse automaatselt pärast perioodi lõppu. Kui kinnitate piiramatu blokeerimise, blokeeritakse teiekonto kõigi muude tegevuste jaoks. Siis on hiljem
            võimatu sisse logida. Kui teie mängijakontole on jäänud raha, tagastatakse see summa makseviisile, mida kasutasite selle salvestamiseks.<br></br>
            <br></br>
            <h4>8. Muudatused kehtivates piirväärtustes</h4>
            Kõik teie seatud piirangud aktiveeritakse kohe. Kui soovite olemasoleva limiidi eemaldada või seda vähendada, jõustub see 7 päeva pärast.<br></br>
            <br></br>
            <h4>9. Märguanded</h4>
            Sellel väljal saate juhtida oma suhtluseelistusi, nagu e-posti teatised, täiendavad SMS-teatised, individuaalsed andmed või pakkumiste ja uudiskirjade saamine.<br></br>
            <br></br>
            <h3>PANUSKUPONG</h3>
            <h4>Panuste tüübid</h4>
            <h4>1. Üksik (Single)</h4>
            Üksikpanuses panustate ühele tulemusele ühes matšis.<br></br>
            <br></br>
            <h4>2. Mitmik (Combo)</h4>
            Kombineeritud panus koosneb mitmest individuaalsest panusest, mis kõik peavad olema õiged, et kombineeritud panus võita. Kombineeritud panusega saate panustada kahele, kolmele, neljale või enamale panusele
            korraga.<br></br>
            <br></br>
            <h4>3. Süsteem</h4>
            Süsteempanus on koondada teatud arv n mitmik panuseid ühte panusekviitungisse. Võite valida mitu süsteemi, näiteks: 2/3, 5/7 ja nii edasi.<br></br>
            <br></br>
            <h4>4. Pank (Banker)</h4>
            Panka saab kasutada ainult süsteemipanuste tegemiseks. Panga valik on igas kombinatsioonis. Selleks, et pank võidaks, tuleb võita kõik kupongile tehtud panused. Märgite panuse pangaks, klõpsates panuselehel
            oleval B-märgil. Süsteemipanuste arv arvutatakse ainult nende vastete kohta, mida pole pangaks valitud.<br></br>
            <br></br>
            <h4>5. Maksimaalnepanus</h4>
            Klõpsates panuse kõrval "Max", kuvab süsteem maksimaalse võimaliku panuse matšidele, mille olete panuselehele lisanud. See arv võib varieeruda sõltuvalt spordialast, seeriast, panuse tüübist või muudest
            süsteemi seadetest.<br></br>
            <br></br>
            <h4>6. Sa võid</h4>
            See näitab panuselehe suurimat võitu.<br></br>
            <br></br>
            <h4>7. Maksimivõitja</h4>
            Maksimaalne võidusumma iga panuselehe kohta on 10 0 000,00 €. Maksimaalne kogusumma, mille võid oma panustamiskontolt võita, on 2 00 000 € nädalas.<br></br>
            <br></br>
            <h4>8. Minimaalnepanus</h4>
            Minimaalne panus igalt panusekviitungilt on 1 €. Süsteemi- ja kombineeritud panuste puhul on minimaalne panus iga kombinatsiooni eest 0,25 €.<br></br>
            <br></br>
            <h4>9. Panuste arv</h4>
            Maksimaalne panusekviitungiga matšide arv on 30.<br></br>
            <br></br>
            <h4>10. Nõustuge koefitsientide muudatustega</h4>
            Kui lisate oma panuselehele sündmused, võib juhtuda, et koefitsiendid muutuvad. See juhtub enamasti otseülekannetel. Ekraanile ilmub teade, et koefitsiendid on muutunud ja peate muudatusega nõustuma. Sõnumi
            vältimiseks klõpsake "Nõustu koefitsientide muudatustega".<br></br>
            <br></br>
            <h3>TULEMUSTE KINNITAMINE JA VÕITUDE VÄLJAMAKSMINE</h3>
            Panusekviitungite arveldamine ja võitude väljamaksmine toimub pärast seda, kui kõigi panusekviitungil olevate tehingute ametlikud tulemused on kontrollitud ja süsteemi lisatud.<br></br>
            <br></br>
            <br></br>
            <h3>PILDIÕIGUSED</h3>
            Nubet hangib pilte ja graafikat, mida kasutatakse veebilehel www.nubet.com, erinevatest allikatest, sealhulgas:<br></br>
            <br></br>
            <ListItem>Shutterstockist litsentsilepingu alusel, mis võimaldab meil kasutada pilte ja graafikat meie veebisaidil ärilistel eesmärkidel.</ListItem>
            <ListItem>Freepik litsentsilepingu alusel, mis lubab meil kasutada pilte ja graafikat meie veebisaidil ärilistel eesmärkidel. </ListItem>
            <ListItem>Meie enda fotograafid ning nende loodud pildid ja graafika kuuluvad Nubetile ja neid võib kasutada ainult ärilistel eesmärkidel meie veebisaidil. </ListItem>
            <br></br>
            <h4>Autoriõigus</h4>
            Nubet austab teiste isikute autoriõigusi. Kui arvate, et teie tööd on meie veebisaidil kasutatud ilma teie loata, võtke meiega ühendust.<br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(Help));
