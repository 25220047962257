import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const CategoryLiveIcon = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 200 200"
      {...props}
    >
      <path d="M135.4,107.8l-14.5-3.6c6-5.3,10.8-12.6,14-20.7,4.8-.9,9.3-4.7,10-13.4.7-8.4-2.1-11.9-5.6-13.3,0-2.7-.4-5.2-.6-7.6-1.6-26.7-15-38.4-36.7-36.1-2.8.3-5.6.5-8.4,0-11.3-1.9-23.1,6.8-28.9,15.9-7.4,11.7-4.6,27.5-4.6,28-3.3,1.5-5.7,5.1-5,13.1.7,8.5,4.9,12.3,9.5,13.3,3.3,8.1,8.2,15.5,14.3,20.8l-14.3,3.6c-19.6,4.9-33.3,22.4-33.3,42.6v24.1c0,6.8,5.7,12.6,12.5,12.6h112.4c6.8,0,12.5-5.7,12.5-12.5v-24.1c0-20.2-13.7-37.7-33.2-42.6ZM55.1,179.5h-11.3c-2.6,0-4.9-2.3-4.9-4.9v-24.1c0-12.4,6.3-23.5,16.1-30.1v59.1ZM67.7,61.2c0-1.1.2-2.2.5-3.3.9-2.9,2-5.3,3.1-7.3,2-3.4,5.1-6.1,8.9-7.3.1,0,.2,0,.3-.1,1.3-.5,2.8,0,3.5,1.2.8,1.2.6,2.7-.3,3.7-.2.2-.4.4-.5.6-.6.6-.7,1.5-.3,2.2s1.2,1.1,2,1c7.9-1.3,21.8-4.8,32.2-13.8,2.8-2.4,6.9-2.1,9.4.5,2.3,2.6,3.9,5.7,4.4,9.1.6,3.8.9,8.3.9,13.4,0,20-14.3,44.1-32.1,44.1-17.7,0-32-24.1-32-44.1ZM102.8,179.5h-5.5l-26.3-65.3,16.4-4.1c3.9,1.9,8,2.9,12.3,2.9s8.7-1.1,12.7-3l16.7,4.2-26.3,65.2ZM161.1,174.5c0,2.6-2.2,4.9-4.8,4.9h-11v-59c9.7,6.6,15.9,17.7,15.9,29.9v24.1h0Z" />
      <circle cx="99.9" cy="143" r="4.4" />
      <circle cx="99.9" cy="158.6" r="4.4" />
      <path d="M112.9,116.5l-7.9,2.3c-.1,0-.2,0-.4,0-1.3-1.1-2.9-1.9-4.7-1.9s-3.5.7-4.7,1.9c-.1,0-.2,0-.4,0l-7.8-2.3c-2.3-.7-4.6,1.1-4.6,3.5v8.9c0,2.4,2.2,4.2,4.6,3.5l8.2-2.4c1.3,1.1,2.9,1.8,4.7,1.9,1.8,0,3.5-.8,4.8-1.9l8.2,2.4c2.3.7,4.6-1.1,4.6-3.5v-8.9c0-2.4-2.3-4.2-4.6-3.5Z" />
    </SvgIcon>
  );
};

export default CategoryLiveIcon;
