import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const CategoryBonusIcon = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 200 200"
      {...props}
    >
      <polygon points="25.9,121.1 15,151.7 66.5,151.7 55.6,121.1 " />
      <polygon points="172.1,121.1 142.4,121.1 131.5,151.7 183,151.7 " />
      <polygon points="84.1,121.1 73.3,151.7 124.7,151.7 113.9,121.1 " />
      <polygon points="113.9,46.3 84.1,46.3 73.3,76.9 124.7,76.9 " />
      <polygon points="84.7,83.7 55,83.7 44.1,114.3 95.6,114.3 " />
      <polygon points="102.4,114.3 153.9,114.3 143,83.7 113.3,83.7 " />
    </SvgIcon>
  );
};

export default CategoryBonusIcon;
