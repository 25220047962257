import { Autoplay, Pagination, Navigation } from "swiper/modules";

const casinoBannerSliderSettings = {
  slidesPerView: 1,
  spaceBetween: 0,
  loop: true,
  freeMode: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  modules: [Autoplay, Pagination, Navigation],
};

export default casinoBannerSliderSettings;
