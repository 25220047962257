/**
 * Component for game box detail avatar svg
 */

import { log } from '../../../../config/log';
import React from 'react';
import { connect } from 'react-redux';

import './GameBoxDetailedAvatar.css';

class GameBoxDetailedAvatarSN extends React.PureComponent {

  renderShirt() {
    const { pattern, color1, color2 } = this.props;

    switch (pattern) {

      case "1":
        return (
          <div>
            <div className="competitor-avatar-bg-snooker"></div>
            <svg className="competitor-avatar-img" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="3em" height="3em"
              viewBox="0 0 520.7 565.9" style={{ enableBackground: "0 0 520.7 565.9" }} xmlSpace="preserve">
              <path fill={color1} d="M498,353.4C482.9,181,449.9,99.9,443.5,90.9C436,80.3,430.4,77.5,429.1,77c0-0.1,0-0.1,0-0.1L317.8,32.3
                c0,0-18.8,14.4-55.8,13.2C225.1,46.7,206,32.3,206,32.3L94.7,76.9c0,0-6.1,1.8-14.7,14c-6.4,9.1-39.5,90.1-54.6,262.5
                c0,0-13.6,177.8-13.6,177.8c28.9,17.7,59.2,6.6,59.2,6.6L101,336.5l11.8-80.5c0.9,15,1.4,29.1,1.4,41.3
                c0,74.4-19.4,238.3-19.4,238.3c79.4,19.6,167.2,19.9,167.2,19.9s87.8-0.3,167.2-19.9c0,0-19.4-163.9-19.4-238.3
                c0-11.8,0.5-25.3,1.3-39.8l11.6,79l29.9,201.3c0,0,30.3,11.1,59.2-6.6C511.6,531.1,498,353.4,498,353.4z"/>
            </svg>
          </div>
        );

      default:
        return (
          <div>no pattern</div>
        );
    }


  }

  render() {
    log(this.constructor.name, 'render');
    return <div>{this.renderShirt()}</div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pattern: ownProps.pattern,
    color1: ownProps.color1,
    color2: ownProps.color2
  };
};

export default connect(mapStateToProps)(GameBoxDetailedAvatarSN);
