/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconAT = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g><path d="M1175.5,431.4c-97.1,0-175.9-78.8-175.9-175.9c0-98.2,78.8-176.9,175.9-176.9c97.1,0,175.9,78.8,175.9,176.9
		C1351.3,352.6,1272.6,431.4,1175.5,431.4z"/>
					<path d="M418,1921.4c-19.4,0-37.8-4.3-53.9-16.2c-38.8-22.7-58.3-71.2-48.6-119.8c6.5-35.6,56.1-84.2,139.2-169.4
		c78.8-78.8,175.9-179.1,196.4-239.5c63.7-194.2,171.6-537.3,215.8-722.9l-76.6,7.6c-6.5,55-16.2,111.1-24.8,164
		c-2.2,20.5-7.6,39.9-9.7,60.4c-11.9,76.6-41,118.7-87.4,127.3c-44.2,9.7-90.6-20.5-102.5-42.1c-22.7-36.7-11.9-119.8,6.5-214.7
		c3.2-16.2,5.4-30.2,7.6-39.9c4.3-30.2,8.6-58.3,11.9-78.8c20.5-155.4,34.5-159.7,206.1-178c30.2-2.2,62.6-7.6,104.7-11.9
		c20.5-2.2,36.7-4.3,52.9-7.6c64.7-10.8,115.4-18.3,194.2,17.3c74.4,34.5,88.5,69.1,106.8,119.8c5.4,14,11.9,30.2,18.3,46.4
		c21.6,48.6,39.9,94.9,58.3,139.2l9.7,23.7l224.4-104.7c2.2,0,4.3-2.2,7.6-2.2c34.5-5.4,66.9,4.3,87.4,28.1
		c21.6,22.7,30.2,55,21.6,91.7c-11.9,53.9-98.2,98.2-193.1,142.4c-14,6.5-24.8,10.8-34.5,16.2c-9.7,4.3-20.5,10.8-32.4,18.3
		c-28.1,16.2-60.4,34.5-83.1,44.2c-100.3,34.5-139.2-44.2-167.2-116.5l-36.7,132.7c48.6,38.8,89.6,73.4,127.3,103.6
		c127.3,102.5,185.6,151.1,182.3,209.3c-2.2,43.2-41,80.9-111.1,127.3c-10.8,8.6-36.7,24.8-66.9,46.4
		c-125.2,85.2-240.6,164-266.5,175.9c-48.6,20.5-94.9,14-127.3-18.3c-32.4-32.4-39.9-83.1-18.3-127.3
		c12.9-28.1,66.9-64.7,161.8-127.3c48.6-33.4,99.3-65.8,131.6-90.6l-201.8-162.9c0,3.2-2.2,3.2-2.2,7.6c0,2.2-4.3,14-8.6,30.2v2.2
		l-70.1,222.3c-32.4,109-64.7,141.3-103.6,175.9c-37.8,35.6-68,72.3-102.5,114.4c-48.6,57.2-97.1,117.6-164,166.2
		C468.7,1912.8,442.8,1921.4,418,1921.4z"/></g>
			</svg>
		</div>
	);
};

export default IconAT;
