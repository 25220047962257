import { Box, IconButton, Stack } from "@mui/material";
import { Swiper } from "swiper/react";
import Iconify from "../iconify";
import { useRef } from "react";

const SwiperSlider = ({ children, nextButton, prevButton, ...rest }) => {
  const swiperRef = useRef(null);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
      {prevButton && (
        <Box display={{ xs: "none", md: "block" }}>
          <IconButton onClick={() => swiperRef.current?.slidePrev()}>
            <Iconify icon="jam:chevron-left" />
          </IconButton>
        </Box>
      )}
      <Swiper
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        {...rest}
      >
        {children}
      </Swiper>
      {nextButton && (
        <Box display={{ xs: "none", md: "block" }}>
          <IconButton onClick={() => swiperRef.current?.slideNext()}>
            <Iconify icon="jam:chevron-right" />
          </IconButton>
        </Box>
      )}
    </Stack>
  );
};

export default SwiperSlider;
