/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconSP = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g><path d="M1755,592c0-102-39.7-197.9-111.8-270c-72.1-72.1-168-111.8-270-111.8c-87.5,0-170.5,29.3-237.8,83.1
	c-17.1-9.4-39-6.9-53.6,7.6c-14.5,14.5-17,36.5-7.6,53.7c-54.1,68.2-83.6,153.3-82.8,240.9l-555.3,646.6c-23.8,27.8-30.6,65-21,98.3
	l-98.9,98.9c-46.7,46.7-55.6,117-26.9,172.8l-60.5,60.5c-17.6,17.6-17.6,46.2,0,63.8c8.8,8.8,20.3,13.2,31.9,13.2
	c11.5,0,23.1-4.4,31.9-13.2l60.4-60.4c20.8,10.8,43.9,16.5,68,16.5c39.6,0,76.8-15.4,104.8-43.4l98.8-98.8
	c9.7,2.8,19.7,4.2,29.8,4.2c24.4,0,48.8-8.3,68.5-25.2l646.6-555.3c1.1,0,2.2,0,3.3,0c86.4,0,170.2-29.4,237.6-82.8
	c6.8,3.7,14.2,5.6,21.7,5.6c11.5,0,23.1-4.4,31.9-13.2c14.5-14.5,17-36.4,7.7-53.5C1725.8,762.5,1755,679.5,1755,592z M462.2,1585.1
	c-11,11-25.5,17-41.1,17c-15.5,0-30.1-6-41.1-17l0,0c-22.6-22.6-22.6-59.5,0-82.1l89.7-89.7l41.1,41.1l41.1,41.1L462.2,1585.1z
	 M664.4,1460.7c-5.9,5.1-14.9,4.8-20.4-0.8l-138.6-138.6c-5.5-5.5-5.9-14.5-0.8-20.4L1039.4,678l247.8,247.8L664.4,1460.7z
	 M1372.6,883.6L1372.6,883.6l-291-291c-0.1-62.1,19.6-122.5,55.9-172.3l407.4,407.4C1495.1,864,1434.7,883.7,1372.6,883.6z
	 M1608.7,764.1l-407.6-407.6c49.6-36.4,109.3-56.1,172.1-56.1c77.9,0,151.1,30.3,206.2,85.4c55.1,55.1,85.4,128.3,85.4,206.2
	C1664.9,654.8,1645.2,714.5,1608.7,764.1z"></path>
				</g>
			</svg>
		</div>
	);
};

export default IconSP;
