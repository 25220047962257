import { log } from "../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import { Translate } from "react-redux-i18n";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TrustlyIcon from "../media/img/trustly_register.png";
import Box from "@mui/material/Box";
import withRouter from "../helpers/withRouter";

const styles = (theme) => ({
  root: {
    minHeight: "20em",
  },
  text: {
    color: "var(--red)",
    textAlign: "center",
    paddingTop: "3em",
    fontSize: "var(--xlarge)",
  },
});

class TrustlyCases extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      type: "unknown",
      status: "unknown",
    };
  }

  goTop = () => {};

  handleSearchParams = () => {
    const { location } = this.props;
    if (location && location?.search) {
      const queryParams = new URLSearchParams(location.search);
      const type = queryParams.get("type");
      const status = queryParams.get("status");
      const value = queryParams.get("value");
      if (type) {
        this.setState({ type: type });
      }
      if (status && !value) {
        this.setState({ status: status });
      }
      if (!status && value) {
        this.setState({ status: value });
      }
    }
  };

  componentDidMount() {
    const { trustlyPayload, navigate } = this.props;
    this.handleSearchParams();

    if (!trustlyPayload?.token) {
      navigate("/");
    }
  }

  renderDepositFailed = () => {
    const { classes } = this.props;
    return (
      <Typography component={"span"}>
        <div className={classes.text}>
          <Translate value="trustly.deposit_failed" />
        </div>
      </Typography>
    );
  };

  renderDepositCanceled = () => {
    const { classes } = this.props;
    return (
      <Typography component={"span"}>
        <div className={classes.text}>
          <Translate value="trustly.deposit_canceled" />
        </div>
      </Typography>
    );
  };

  renderDepositRejected = () => {
    const { classes } = this.props;
    return (
      <Typography component={"span"}>
        <div className={classes.text}>
          <Translate value="trustly.deposit_rejected" />
        </div>
      </Typography>
    );
  };

  renderWithdrawalRejected = () => {
    const { classes } = this.props;
    return (
      <Typography component={"span"}>
        <div className={classes.text}>
          <Translate value="trustly.withdraw_rejected" />
        </div>
      </Typography>
    );
  };
  renderWithdrawalCanceled = () => {
    const { classes } = this.props;
    return (
      <Typography component={"span"}>
        <div className={classes.text}>
          <Translate value="trustly.withdraw_canceled" />
        </div>
      </Typography>
    );
  };
  renderWithdrawalFailed = () => {
    const { classes } = this.props;
    return (
      <Typography component={"span"}>
        <div className={classes.text}>
          <Translate value="trustly.withdraw_failed" />
        </div>
      </Typography>
    );
  };

  renderDepositNotInitialized = () => {
    const { classes } = this.props;
    return <Typography component={"span"}>{/* <div className={classes.text}><Translate value="trustly.deposit_not_initialized" /></div> */}</Typography>;
  };

  renderNotCompletedDeposit = () => {
    const { classes } = this.props;
    return (
      <Typography component={"span"}>
        <div className={classes.text}>
          <Translate value="trustly.deposit_not_completed" />
        </div>
      </Typography>
    );
  };

  renderDepositPending = () => {
    const { locale, navigate } = this.props;
    navigate(`/${locale}/trustly/deposit/waiting`);
    return null;
  };

  render() {
    log(this.constructor.name, "render");
    const { trustlyPayload } = this.props;
    return (
      <Container style={{ backgroundColor: "var(--color-main)", minHeight: "100%", textAlign: "center" }} maxWidth={false}>
        {this.state.type === "deposit" && this.state.status === "pending" ? this.renderDepositPending() : null}
        {this.state.type === "deposit" && this.state.status === "failed" ? this.renderDepositFailed() : null}
        {this.state.type === "deposit" && this.state.status === "rejected" ? this.renderDepositRejected() : null}
        {this.state.type === "deposit" && this.state.status === "cancelled" ? this.renderDepositCanceled() : null}
        {this.state.type === "deposit" && this.state.status === "voided" ? this.renderDepositCanceled() : null}
        {this.state.type === "deposit" && this.state.status === "notInitialized" ? this.renderDepositNotInitialized() : null}
        {this.state.type === "deposit" && this.state.status === "init" ? this.renderNotCompletedDeposit() : null}
        {this.state.type === "withdrawal" && this.state.status === "failed" ? this.renderWithdrawalFailed() : null}
        {this.state.type === "withdrawal" && this.state.status === "rejected" ? this.renderWithdrawalRejected() : null}
        {this.state.type === "withdrawal" && this.state.status === "cancelled" ? this.renderWithdrawalCanceled() : null}
        <img style={{ color: "var(--btn-text)", marginTop: "2.5em", height: "6em" }} src={TrustlyIcon} alt="trustly" />
        <br></br>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          {this.state.status === "init" ? (
            <Button variant="contained" color="primary" style={{ color: "var(--btn-text)", marginTop: "3em" }} onClick={() => (window.location.href = trustlyPayload?.redirectUrl)}>
              <Translate value="trustly.complete_deposit" />
            </Button>
          ) : null}
          <Link to={`/`}>
            <Button variant="contained" color="primary" style={{ color: "var(--btn-text)", marginTop: "3em" }}>
              <Translate value="trustly.deposit_back_btn" />
            </Button>
          </Link>
        </Box>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
    trustlyPayload: state.user.trustlyPayload,
    locale: state.i18n.locale,
  };
};

export default withRouter(connect(mapStateToProps, {})(withStyles(styles)(TrustlyCases)));
