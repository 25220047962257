import IconFL from "./@icons/IconFL";
import IconBD from "./@icons/IconBD";
import IconTR from "./@icons/IconTR";
import IconTT from "./@icons/IconTT";
import IconSN from "./@icons/IconSN";
import IconDA from "./@icons/IconDA";
import IconIH from "./@icons/IconIH";
import IconTE from "./@icons/IconTE";
import IconCR from "./@icons/IconCR";
import IconFB from "./@icons/IconFB";
import IconBA from "./@icons/IconBA";
import IconBX from "./@icons/IconBX";
import IconVB from "./@icons/IconVB";
import IconRG from "./@icons/IconRG";
import IconAF from "./@icons/IconAF";
import IconAR from "./@icons/IconAR";
import IconHB from "./@icons/IconHB";
import IconMM from "./@icons/IconMM";
import IconBB from "./@icons/IconBB";
import IconBM from "./@icons/IconBM";
import IconCS from "./@icons/IconCS";
import IconCU from "./@icons/IconCU";
import IconDT from "./@icons/IconDT";
import IconHR from "./@icons/IconHR";
import IconLL from "./@icons/IconLL";
import IconMO from "./@icons/IconMO";
import IconSQ from "./@icons/IconSQ";
import IconPS from "./@icons/IconPS";
import IconWP from "./@icons/IconWP";
import IconGO from "./@icons/IconGO";
import IconF1 from "./@icons/IconF1";
import IconAS from "./@icons/IconAS";
import IconBI from "./@icons/IconBI";
import IconSJ from "./@icons/IconSJ";
import IconCC from "./@icons/IconCC";
import IconCH from "./@icons/IconCH";
import IconCY from "./@icons/IconCY";
import IconFS from "./@icons/IconFS";
import IconRA from "./@icons/IconRA";
import IconNC from "./@icons/IconNC";
import IconGH from "./@icons/IconGH";
import IconGF from "./@icons/IconGF";
import IconSP from "./@icons/IconSP";
import IconChecked from "./@icons/IconChecked";
import IconAT from "./@icons/IconAT";
import IconWR from "./@icons/IconWR";
import IconOL from "./@icons/IconOL";

const sportsIcons = {
  // AY: <IconOL />,
  // SB: <IconOL />,
  // CA: <IconOL />,
  // SW: <IconOL />,
  // WL: <IconOL />,
  // SL: <IconOL />,
  // JU: <IconOL />,
  // TK: <IconOL />,
  // DV: <IconOL />,
  // SF: <IconOL />,
  // CL: <IconOL />,
  // TA: <IconOL />,
  // RO: <IconOL />,
  // BR: <IconOL />,
  // FE: <IconOL />,
  // GY: <IconOL />,
  // SH: <IconOL />,
  // OL: <IconOL />,
  // AT: <IconOL />,
  // WG: <IconOL />,
  AT: <IconAT />,
  WR: <IconWR />,
  FH: <IconBD />,
  AF: <IconAF />,
  // AR: <IconAR />,
  BA: <IconBA />,
  BB: <IconBB />,
  BD: <IconBD />,
  BM: <IconBM />,
  BV: <IconVB />,
  BX: <IconBX />,
  CR: <IconCR />,
  CS: <IconCS />,
  CU: <IconCU />,
  DA: <IconDA />,
  DT: <IconDT />,
  FB: <IconFB />,
  FL: <IconFL />,
  FS: <IconFS />,
  HB: <IconHB />,
  HR: <IconHR />,
  IH: <IconIH />,
  LL: <IconLL />,
  MM: <IconMM />,
  MO: <IconMO />,
  PS: <IconPS />,
  RG: <IconRG />,
  SN: <IconSN />,
  SQ: <IconSQ />,
  TE: <IconTE />,
  TR: <IconTR />,
  TT: <IconTT />,
  VB: <IconVB />,
  WP: <IconWP />,
  GO: <IconGO />,
  F1: <IconF1 />,
  AS: <IconAS />,
  BI: <IconBI />,
  SJ: <IconSJ />,
  CC: <IconCC />,
  CH: <IconCH />,
  CY: <IconCY />,
  FS: <IconFS />,
  RA: <IconRA />,
  NC: <IconNC />,
  GH: <IconGH />,
  GF: <IconGF />,
  SP: <IconSP />,
  ES: <IconCS />,
  B3: <IconBA />,
  BV: <IconVB />,
};

export default sportsIcons;
