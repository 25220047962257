/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconRG = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g><path d="M833.6,1351.2l517.6-517.6c51-51,51-133.9,0-184.8c-51-51-133.9-51-184.8,0c-10.2,10.2-10.2,26.8,0,37
		c10.2,10.2,26.8,10.2,37,0c30.6-30.6,80.3-30.6,110.9,0c30.6,30.6,30.6,80.3,0,110.9l-92.4,92.4l-37-37l92.4-92.4
		c10.2-10.2,10.2-26.8,0-37c-10.2-10.2-26.8-10.2-37,0l-92.4,92.4l-37-37l18.5-18.5c10.2-10.2,10.2-26.8,0-37
		c-10.2-10.2-26.8-10.2-37,0l-443.6,443.6c-51,51-51,133.9,0,184.8C699.8,1402.2,782.7,1402.2,833.6,1351.2L833.6,1351.2z
		 M926.1,1184.8l-37-37L1000,1037l37,37L926.1,1184.8z M1147.9,889.1l37,37L1073.9,1037l-37-37L1147.9,889.1z M1073.9,815.2l37,37
		L1000,963l-37-37L1073.9,815.2z M926.1,963l37,37l-110.9,110.9l-37-37L926.1,963z M685.8,1203.3l92.4-92.4l37,37l-92.4,92.4
		c-10.2,10.2-10.2,26.8,0,37c10.2,10.2,26.8,10.2,37,0l92.4-92.4l37,37l-92.4,92.4c-30.6,30.6-80.3,30.6-110.9,0
		C655.2,1283.7,655.2,1233.9,685.8,1203.3L685.8,1203.3z"/>
					<path d="M1319.9,168c80.3,0,154.1,11.7,219.3,34.7c63.9,22.6,117.8,55.5,160.2,97.9c112.4,112.4,156.3,304,120.4,525.7
		c-18.2,112.8-55.8,225.3-111.8,334.3c-59.7,116.3-137.4,222.7-231.1,316.4c-93.7,93.7-200.1,171.4-316.4,231.1
		c-109,55.9-221.4,93.5-334.3,111.8c-49.9,8.1-99.1,12.2-146.2,12.2c-80.3,0-154.1-11.7-219.3-34.7
		c-63.9-22.6-117.8-55.5-160.2-97.9c-112.4-112.4-156.3-304-120.4-525.7c18.2-112.8,55.8-225.3,111.8-334.3
		C351.6,723.2,429.4,616.7,523,523.1c93.7-93.7,200.1-171.4,316.4-231.1c109-55.9,221.4-93.5,334.3-111.8
		C1223.6,172.1,1272.8,168,1319.9,168 M1319.9,116.3c-49.6,0-101.3,4.2-154.5,12.8c-118.2,19.1-235.8,58.4-349.6,116.8
		C694.7,308,583.9,389,486.4,486.4C389,583.9,308,694.7,245.9,815.8c-58.4,113.8-97.7,231.4-116.8,349.6
		C90.6,1403.8,139.7,1611.7,264,1736c96.5,96.5,243.6,147.8,416.1,147.8c49.6,0,101.3-4.2,154.5-12.8
		c118.2-19.1,235.8-58.4,349.6-116.8c121.1-62.1,231.9-143.1,329.4-240.5c97.5-97.5,178.4-208.3,240.5-329.4
		c58.4-113.8,97.7-231.4,116.8-349.6c38.5-238.3-10.6-446.3-134.9-570.6v0C1639.5,167.5,1492.4,116.3,1319.9,116.3L1319.9,116.3z"/>
				</g>
			</svg>
		</div>
	);
};

export default IconRG;
