/**
 * i18n configuration - locales
 */
// import Cookies from 'js-cookie'

export const supportedLocales = {
  en: 'English',
  // de: 'Deutsch',
  et: 'Eesti',
  fi: 'Suomi',
};