import { Box, Stack, Typography } from "@mui/material";
import { I18n } from "react-redux-i18n";
import CasinoGameCard from "./CasinoGameCard";
import { useNavigate } from "react-router-dom";
import Iconify from "../../iconify";
import SwiperSlider from "../../swiper";
import { SwiperSlide } from "swiper/react";
import casinoCategorySliderSettings from "../../swiper/settings/casino-category-slider";

const GamesCarouselSection = ({ title, games = [], url, icon }) => {
  const navigate = useNavigate();

  const sliderBoxStyles = {
    width: "95%",
    padding: "0.5em 0 0.5em ",
  };

  return (
    // <Box sx={{ width: "95%", padding: "1em 0" }}>
    <Box sx={sliderBoxStyles}>
      <Stack mb={2} direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={1}>
          {icon}
          <Typography
            variant="h3"
            align="left"
            sx={{
              color: "var(--grey)",
              fontSize: "1.4em",
            }}
          >
            {title}
          </Typography>
        </Stack>
        <a>
          <Stack onClick={() => navigate(url)} direction="row" alignItems="center" sx={{ cursor: "pointer" }}>
            <Typography variant="h6">{I18n.t(`slots.seeall`)}</Typography>
            <Iconify icon="material-symbols-light:double-arrow" />
          </Stack>
        </a>
      </Stack>
      <SwiperSlider {...casinoCategorySliderSettings}>
        {games.map((game) => (
          <SwiperSlide key={game.gameId}>
            <Box px={1}>
              <CasinoGameCard game={game} />
            </Box>
          </SwiperSlide>
        ))}
      </SwiperSlider>
    </Box>
  );
};

export default GamesCarouselSection;
