/**
 * BonusTermsSlots - Estonian version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import { ListItem } from "@mui/material";
// import ListItem from '@mui/material/ListItem';

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class BonusTermsSlots extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component={"span"}>
          <div id="legalcontent" className={classes.root}>
            <h1>NUBET.COM-i KASIINOBOONUSE TINGIMUSED</h1>
            Eestikeelne versioon 1.1, viimati uuendatud 27. jaanuaril 2023.<br></br>
            <br></br>
            <h3 id="General">1. ÜLDTEAVE</h3>
            1.1. Siinsed kasiinoboonuse tingimused kehtivad Nubeti kasiino kohta. Kasiinoboonust ei saa kasutada muude toodete, näiteks spordiennustuse või reaalaajas panustamise jaoks. Kui teil on aktiivne
            kasiinoboonus, saate sõlmida Nubeti spordikihlvedusid ainult siis, kui kannate kontole lisaraha.<br></br>
            <br></br>
            1.2. Siinsetes kasiinoboonuse tingimustes nimetatud kasiinoboonust pakub Nubet veebisaidil nubet.com, mida haldab Eestis registreeritud ettevõte Vana Lauri OÜ, registrikood 16427120, registrijärgne aadress
            Roseni 10–135, 10111 Tallinn, Eesti (edaspidi: <b>Nubet</b> või <b>ettevõte</b>). Vana Lauri OÜ tegutseb Eesti Maksu- ja Tolliameti järelevalve all ja järgmiste tegevuslubade alusel: HKT000066 (välja antud
            10.07.2022) ja HKT000067 (välja antud 10.07.2022).<br></br>
            <br></br>
            1.3. Nubet võib teatavate tingimuste täitmise korral anda teile mis tahes tüüpi kasiinoboonuskrediiti või muud tüüpi boonuseid, nagu tasuta keerutusi (edaspidi: <b>boonusraha</b>, <b>boonus</b> või{" "}
            <b>kasiinoboonus</b>). Mängija peab ise otsustama, kas ta soovib boonust kasutada, ja soovi korral võib ta boonusest loobuda, jättes boonuspakkumise kasutamata. Iga antud kasiinoboonuse puhul kehtivad boonuse
            kasutamisega seotud panustamistingimused (edaspidi: <b>panustamine</b>), millest teavitatakse mängijat varem veebisaidi ja e-posti teel. Kõik antud kasiinoboonused kantakse mängukontole automaatselt
            boonusrahana, mida reguleerivad kasiinoboonuse tingimustes määratletud reeglid.<br></br>
            <br></br>
            <h3 id="Generalbonus">2. KASIINOBOONUSE ÜLDTINGIMUSED</h3>
            2.1. Siinsed kasiinoboonuse üldtingimused kehtivad Nubeti antud mis tahes tüüpi kasiinoboonuste kohta. Mõne boonuse suhtes kohaldatakse eraldi tingimusi, mis võivad erineda kasiinoboonuse üldtingimustest ja
            mis on sätestatud kasiinoboonuse tingimuste asjakohastes jaotistes, näiteks kasiino tervitusboonuse või sissemakseboonuse jaotises. Vastuolude korral kohaldatakse konkreetse boonusetüübi tingimusi. Pange
            tähele, et muu hulgas võivad erineda boonusetüüpide minimaalse panuse nõuded ja minimaalsed sissemaksesummad.<br></br>
            <br></br>
            2.2. Nubet soovib pakkuda kasiinoboonust kõigile oma mängijatele. Sellest hoolimata on ettevõttel õigus oma ainuäranägemise järgi keelata üksikutel mängijatel boonust kasutamast. Ettevõte võib oma
            ainuäranägemisel tühistada mis tahes boonuse ja sellega seotud võidud, kui ettevõte avastab, et mängija ei järgi boonusereegleid. Ettevõte võib igal ajal ilma ette teatamata muuta boonuse tingimusi või
            lõpetada selle pakkumise.<br></br>
            <br></br>
            2.3. Nubeti kasiinoboonus kehtib ja seda tuleb kasutada teatava kampaaniaperioodi jooksul. Kui seda ei kasutata, siis boonus aegub ja seda ei saa hiljem rakendada.<br></br>
            <br></br>
            2.4. Nubeti kasiinoboonus on saadaval ainult üks kord kliendi, mängijakonto, majapidamise, sissemakse tegemiseks kasutatud ülekandemeetodi ja/või konto omaniku, ühise arvuti (ülikool, töökoht, internetikohvik
            jne) ja/või IP-aadressi kohta.2.5. Nubeti kasiinoboonust ei tohi kombineerida teiste boonustega, kui sõnaselgelt ei ole teisiti märgitud, ega kasutada koos muude kampaaniatega.<br></br>
            <br></br>
            2.6. Nubeti kasiinoboonuse kampaaniapakkumise, mis saadetakse e-kirjaga, võib lunastada ainult boonuse e-kirja algne saaja. Boonuse saanud ja seda kasutav e-posti aadress peab ühtima mängijakonto loomisel
            kasutatud e-posti aadressiga. Nubet jätab endale õiguse uurida, kas boonuskood saadeti isiklikult kasiinoboonuse pakkumist kasutanud mängukonto omanikule mängukontol kasutatud e-posti aadressile. Kui seda
            reeglit rikutakse, võidakse boonus ja sellega seotud võidud konfiskeerida.<br></br>
            <br></br>
            2.7. Nubeti kasiinoboonus, mis on seotud sissemakse tegemisega, kehtib ainult Nubeti pakutavate maksemeetodite kasutamise korral ega ole kasiinoboonuse pakkumisest maksemeetodina välistatud. Kontoomanik ja
            maksemeetodi omanik peavad olema samad. Mängukonto krediidijääki, mis koosneb ainult mängija tehtud sissemaksetest (mitte boonustest) ja mis seega ei ole seotud boonuse tingimustega, nimetatakse
            pärisrahajäägiks (edaspidi: pärisrahajääk).<br></br>
            <br></br>
            2.8. Nubeti kasiinoboonus kajastub teie mängukonto jäägis. Kui kasiinoboonus on panustatud, ei saa kontojääki välja võtta ega konverteerida seda pärisrahajäägiks enne, kui panustamine on lõpetatud, nagu on
            sätestatud boonuse tingimustes. Kui kasiinoboonus antakse sissemakse eest, kehtib see ka sissemaksesumma kohta, kuna kasiinoboonuse tingimused kehtivad mõlema puhul.<br></br>
            <br></br>
            2.9. Kui sissemakse on suurem kui kasiinoboonuse pakkumises määratud maksimaalne summa eurodes, kehtivad kasiinoboonuse tingimused kogu sissemaksesumma kohta. Näide: kui saate kuni 100-eurose 100%
            kasiinoboonuse ja kannate kontole 150 eurot, kohaldatakse kasiinoboonuse panustamise nõudeid 150 euro suhtes. Selles näites on kasiinoboonuse summa 100 eurot.<br></br>
            <br></br>
            2.10. Kui ei ole sätestatud teisiti, tuleb saadud kasiinoboonus enne selle väljamaksmist või väljamaksmise üritamist panustada vähemalt 40 korda. Boonusrahaga teenitud võite ei maksta välja enne, kui boonuse
            panustamise tingimused on täidetud. Kui püüate boonust välja võtta enne boonuse panustamise nõuete täitmist, kaotate kõik boonusega saadud võidud ja boonuse enda.<br></br>
            <br></br>
            2.11. Kui ei ole sätestatud teisiti, tuleb boonuse panustamise nõuded täita 90 päeva jooksul pärast boonuse saamist. Kui boonuse tingimusi ei täideta õigel ajal, jätab Nubet endale õiguse tühistada boonuse
            summa ja kõik sellest tulenenud võidud.<br></br>
            <br></br>
            2.12. Kui kasutajale antakse tasuta keerutusi, on nende minimaalne väärtus 0,10 eurot keerutuse kohta, kui ei ole märgitud teisiti. Keerutus, mida ei kasutata määratud perioodi jooksul, kaotab kehtivuse. Kõik
            tasuta keerutustest saadud võidud loetakse kasiinoboonusega seotud krediidiks ja need peavad olema kooskõlas panustamisnõuetega.<br></br>
            <br></br>
            2.13. Nubet jälgib, kas boonuseid kasutatakse ebahariliku mängumustri osana.<br></br>
            <br></br>
            2.14. Kasiinoboonust väärkasutades rikutakse boonuse tingimusi. Nubet eeldab, et boonuse tingimusi on rikutud järgmistel juhtudel:<br></br>
            <br></br>
            <ListItem>sama isik kasutab boonust mitu korda või boonust kasutatakse mitu korda sama IP-aadressi all või seadmes;</ListItem>
            <ListItem>kahtlustatakse, et boonuspakkumist kasutab organisatsioon.</ListItem>
            <br></br>
            2.15. Kui Nubet kahtlustab ebaharilikku mängimist või sõltuvuskäitumist, jätab ettevõte endale õiguse konfiskeerida kõik boonusega saadud võidud, mida ei ole panustatud, välja arvatud kontole kantud
            pärisraha. Sellises olukorras tagastatakse pärisraha.<br></br>
            <br></br>
            2.16. Boonust ei kanta kontole, kui te ei tohi Nubeti pakkumises osaleda või pakkumine on peatatud.<br></br>
            <br></br>
            2.17. Kasiinoboonus kehtib kuni panustamise lõpuni või boonuse tühistamiseni.<br></br>
            <br></br>
            2.18. Nubet jätab endale õiguse boonust ja kampaania kestust igal ajal muuta, peatada või tühistada.<br></br>
            <br></br>
            2.19. Panustamisnõudeid ei täida panused, mis tehakse järgmistes mängudes: Absolootly Mad Mega Moolah, Agent Royale, Age of Conquest, Astro Legends Lyra & Erion, Blazing Bull 2, Bakers Treat, Book Of 99,
            Castle Builder II, Cauldron, Double Dragons, Cazino Cosmos, Dwarf Mine, EggOmatic, Eye Of The Kraken, Gemix, Goblins and Gemstones, Magic of Sahara, Mad Monsters, Reel Rush II, Dynamite Riches, 11 Champions,
            Victoria Wild, Ducks Till Dawn, The Wish Master, Viking Runecraft, White Rabbit, Sea Hunter, Mystery Joker 6000, Extra Chilli, Wild Swarm, Dark Vortex, Jingle Spin, Zombie Hoard, Mahjong 88, Le Kaffee Bar,
            Narcos, Village People Macho Moves, Solar Queen, Razor Shark, Tiki Mania, Jewel Scarabs, Crystal Cavern, Blazing Bull, Solar Temple, Wilderland, Bronco Spirit, Hellcatraz, Football glory, All Star Knockout
            Ultra Gamble, Marching Legions, Banana Drop, Maui Millions, Lil Devil, Bombuster, Jungle Gorilla, 5 Families, Rage of the Seas, Lucky Fridays, Solar King, Emerald King, Hoard of Poseidon, Golden Tsar,
            Leprechauns Magic Megaways, Augustus, Jingle Bells Power Reels, Sylvan Spirits, Golden Stallion, Frost Queen Jackpots, Rome The Golden Age, Rome The Golden Age, Pirates Plenty MegaWays, Pearls Of India, The
            Hot Offer, Tower Quest, Rage To Riches, Robin Hood Shifting Riches, Scudamores Super Stakes, Scrooge, Serengeti Kings, Spina Colada, Sweet Alchemy, Tuts Twister, Vikings Go Berzerk, Wolf Hunters.<br></br>
            <br></br>
            2.20. Kohaldatakse Nubeti tingimusi.<br></br>
            <br></br>
            <h3 id="Welcome">3. KASIINO TERVITUSBOONUSE TINGIMUSED</h3>
            3.1. Kasiino tervitusboonuse pakkumist ei saa kasutada koos muude pakkumiste ega teise kasiino tervitusboonuse pakkumisega.<br></br>
            <br></br>
            3.2. Nubeti kasiino tervitusboonus antakse ainult kampaaniaperioodi jooksul ja esimese või teise vähemalt 20-eurose sissemakse eest, mis tehakse uuele mängukontole.<br></br>
            <br></br>
            3.3. Mängijal on sissemakse ja kasiino tervitusboonuse pakkumise kasutamiseks aega 30 päeva alates registreerimisest.<br></br>
            <br></br>
            3.4. Kohe, kui klient on teinud sissemakse, kantakse Nubeti kasiino tervitusboonus kliendi kontole summas, mis ühtib määratletud maksimaalse limiidi piires 100% sissemaksesummaga.<br></br>
            <br></br>
            3.5. Kasiino tervitusboonuse summat tuleb enne väljamakse taotlemist panustada Nubeti kasiinos vähemalt 40 korda. Arvutamise näide: kannate kontole 20 eurot ja saate meilt 20 eurot boonuskrediiti. Väljamakse
            taotlemiseks tuleb kasiinoboonuse panustamisnõude täitmiseks seega panustada vähemalt 800 eurot.<br></br>
            <br></br>
            3.6. Kasiino tervitusboonuse tingimustes sätestatud panustamisnõue tuleb täita 90 päeva jooksul (kasiino tervitusboonuse periood) alates esimesest sissemaksest. Kasiino tervitusboonuse perioodi lõppedes
            tühistatakse kasiino tervitusboonus ja kõik sellega seotud võidud automaatselt ning neid ei tagastata.<br></br>
            <br></br>
            3.7. Kasiino tervitusboonuse summa, k.a sellega seotud sissemakse ja võidud, kuvatakse Nubeti kontol kättesaamatu krediidijäägina kuni määratud panustamisnõuete täitmiseni. Kui väljamakset taotletakse enne,
            kui kasiino tervitusboonuse panustamisnõue on täidetud, muutuvad kasiino tervitusboonus ja kõik sellega seotud võidud kehtetuks ning need konfiskeeritakse ilma ette teatamata.<br></br>
            <br></br>
            3.8. Kasiino tervitusboonuse panustamisnõudeid saate täita ainult siis, kui mängite Nubeti kasiino mänge, mis ei ole panustamise arvestusest eraldi välja jäetud. Mängud, mis on panustamise arvestusest välja
            jäetud, on täpsustatud tingimustes.<br></br>
            <br></br>
            <h3 id="Deposit">4. KASIINO SISSEMAKSEBOONUSE TINGIMUSED</h3>
            4.1. Mängija saab valida kasiino sissemakseboonuse sissemakse tegemise ajal vabatahtlikult. Järgige kindlasti kasiino sissemakseboonuse koodi kasutamise juhiseid. Sissemakse tegemisel kuvatakse saadaval
            kasiino sissemakseboonuse valikud.<br></br>
            <br></br>
            4.2. Boonuse kasutamiseks tuleb kontole teha vähemalt 20-eurone sissemakse.<br></br>
            <br></br>
            4.3. Kasiino sissemakseboonus kehtib ainult kampaaniaperioodi jooksul ja seda ei saa lunastada hiljem.<br></br>
            <br></br>
            4.4. E-posti, kontoteate või muu teate kaudu saadetud Nubeti kasiino sissemakseboonuse pakkumist võib lunastada ainult sissemakseboonuse pakkumise algne saaja. Nubet jätab endale õiguse uurida, kas kasiino
            sissemakseboonust kasutanud mängijakonto omanik sai boonuskoodi isiklikult, ning tühistada ja konfiskeerida selle reegli rikkumise korral boonus ja sellega seotud võidud.<br></br>
            <br></br>
            4.5. Teid teavitatakse kasiino sissemakseboonuse tingimustest (näiteks kampaaniaperiood, kasiino sissemakseboonuse protsent boonuse summast, mis ühtib sissemaksesummaga ja maksimaalne boonusesumma eurodes)
            isikliku e-kirja, kontoteate, SMS-i või tõuketeate kaudu.<br></br>
            <br></br>
            4.6. Kasiino sissemakseboonuse summat tuleb enne väljamakse taotlemist panustada Nubeti kasiinos vähemalt 40 korda. Arvutamise näide: kannate kontole 20 eurot ja saate meilt 20 eurot boonuskrediiti.
            Väljamakse taotlemiseks tuleb kasiinoboonuse panustamisnõude täitmiseks seega panustada vähemalt 800 eurot.<br></br>
            <br></br>
            4.7. Kasiino sissemakseboonuse tingimustes sätestatud panustamisnõue tuleb täita 30 päeva jooksul (kasiino sissemakseboonuse periood) alates esimesest sissemaksest. Kui kasiino sissemakseboonuse periood on
            lõppenud, muutuvad kasiino sissemakseboonus ja kõik sellega seotud võidud automaatselt kehtetuks ning neid ei tagastata.<br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(BonusTermsSlots));
