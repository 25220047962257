/**
 * Reducer for casino filter ( hot, new, video_slot ), leagues ( selected leagues), slipTemplate and casion games
 */

import { calcGame, updateScore, updateOdd, updateOutrightOdd, checkGameSession, checkOutrightGameSession } from "../helpers/gameFunctions";

import {
  SAVE_CASINO_FILTER,
  SELECT_LEAGUES,
  UPDATE_SELECTED_LEAGUE,
  SAVE_LEAGUE_RESULTS,
  SET_LEAGUE_MENU_VALUE,
  DELETE_SELECTED_LEAGUES,
  SAVE_GAMES,
  SAVE_OUTRIGHT_GAMES,
  SAVE_GAME_ALL_ODDS,
  SAVE_ODDS_HISTORY,
  SELECT_SLIP_TEMPLATE,
  UPDATE_ODDS_IN_LEAGUE,
  UPDATE_OUTRIGHT_LEAGUE_ODDS,
  UPDATE_SCORES_IN_LEAGUE,
  SELECT_CASINO_GAME,
  DELETE_CASINO_GAME,
  REMOVE_ENDED_LEAGUE_GAMES,
  REMOVE_ENDED_OUTRIGHT_GAMES,
  CASINO_GAME_LOADING,
  SELECT_SEARCHED_LEAGUES,
} from "../actions/types";

const INITIAL_STATE = {
  leagues: {},
  slipTemplate: 0,
  casino: {},
  isCasinoLoading: false,
};

const fn = (state = INITIAL_STATE, action) => {
  let result = {};
  let stateCopy = {};
  let leagueId, gameId, marketId;

  switch (action.type) {
    case SAVE_CASINO_FILTER:
      return { ...state, casinoFilter: action.payload };
    case SELECT_SEARCHED_LEAGUES:
      return { ...state, leagues: action.payload };
    case SELECT_LEAGUES:
      stateCopy = { ...state };
      let newLeagues = action.payload;
      let selectedLeagues = {};
      selectedLeagues = { ...stateCopy.leagues, ...newLeagues };
      return { ...stateCopy, leagues: selectedLeagues };

    case UPDATE_SELECTED_LEAGUE:
      stateCopy = { ...state };
      let object = action.payload;

      // format games
      for (let leagueId in object) {
        let games = object[leagueId] && object[leagueId].games ? object[leagueId].games : {};

        for (let game in games) {
          if (!object[leagueId] || object[leagueId].games) {
            object[leagueId]["games"] = {};
            object[leagueId]["isOpen"] = true;
            object[leagueId]["menuValue"] = "";
          } else {
            object[leagueId]["isOpen"] = true;
          }

          object[leagueId]["games"][games[game]["gameid"]] = calcGame(games[game]);
        }
      }

      let currentLeagues = stateCopy.leagues;
      let updatedCurrentLeagues = { ...currentLeagues, ...object };

      return { ...stateCopy, leagues: updatedCurrentLeagues };

    case SAVE_GAMES:
      stateCopy = { ...state };
      let newLeagues1 = action.leagues;
      let selectedLeagues1 = {};

      selectedLeagues1 = { ...stateCopy.leagues, ...newLeagues1 };
      let newState1 = { ...stateCopy, leagues: selectedLeagues1 };

      let result1 = { ...newState1.leagues };
      let games = action.payload.games;
      // if (!games||Object.keys(games)?.length===0) {
      //     return { ...state, leagues: result1 };
      // }
      // console.log("working0001");
      let outrightLeagueGames = action.payload.outrightGames;
      let hasLeagueOutrightGames = action.payload.hasOutright;
      let openLeague = action.payload.openLeague;
      if (hasLeagueOutrightGames) {
        for (let game in outrightLeagueGames) {
          leagueId = outrightLeagueGames[game]["leagueID"];
          if (!result1[leagueId].outrightGames) {
            result1[leagueId]["outrightGames"] = {};
            result1[leagueId]["isOpen"] = openLeague;
            result1[leagueId]["menuValue"] = "";
            result1[leagueId]["hasOutright"] = hasLeagueOutrightGames;
          } else {
            result1[leagueId]["isOpen"] = openLeague;
            result1[leagueId]["menuValue"] = "";
            result1[leagueId]["games"] = {};
          }
          const returnedGame = checkOutrightGameSession(outrightLeagueGames[game], action.leagues.leagueFilter);
          //    console.log(returnedGame);
          if (returnedGame) {
            result1[leagueId]["outrightGames"][game] = calcGame(returnedGame);
          }
        }
      }
      for (let game in games) {
        leagueId = games[game]["leagueID"];
        if (!result1[leagueId]?.games) {
          result1[leagueId]["games"] = {};
          result1[leagueId]["isOpen"] = openLeague;
          result1[leagueId]["menuValue"] = "";
          result1[leagueId]["hasOutright"] = hasLeagueOutrightGames;
          // result1[leagueId]['outrightGames'] = outrightLeagueGames;
        } else {
          result1[leagueId]["isOpen"] = openLeague;
          result1[leagueId]["menuValue"] = "";
        }
        const returnedGame = checkGameSession(games[game], action.leagues.leagueFilter);
        if (returnedGame) {
          result1[leagueId]["games"][games[game]["gameid"]] = calcGame(returnedGame);
        }
      }
      return { ...state, leagues: result1 };
    case SAVE_OUTRIGHT_GAMES:
      stateCopy = { ...state };
      let newLeagues2 = action.leagues;
      let selectedLeagues2 = {};

      selectedLeagues2 = { ...stateCopy.leagues, ...newLeagues2 };
      let newState2 = { ...stateCopy, leagues: selectedLeagues2 };

      let result2 = { ...newState2.leagues };
      let allGames = action.payload.games;
      let isOpenLeague = action.payload.openLeague;
      for (let game in allGames) {
        leagueId = allGames[game]["leagueID"];
        if (!result2[leagueId].games) {
          result2[leagueId]["games"] = {};
          result2[leagueId]["isOpen"] = isOpenLeague;
          result2[leagueId]["menuValue"] = "";
        } else {
          result2[leagueId]["isOpen"] = isOpenLeague;
        }
        const returnedGame = checkOutrightGameSession(allGames[game], action.leagues?.leagueFilter);
        if (returnedGame) {
          result2[leagueId]["games"][allGames[game]["gameid"]] = calcGame(returnedGame);
        }
      }
      return { ...state, leagues: result2 };
    case REMOVE_ENDED_LEAGUE_GAMES:
      stateCopy = { ...state };
      let endedGames = action.payload;

      endedGames?.forEach((gm) => {
        Object.values(stateCopy.leagues)?.forEach((league) => {
          const hasGame = league && league?.games && league?.games[gm?.gId];
          if (hasGame) {
            delete stateCopy["leagues"][league.leagueId]["games"][gm?.gId];
          }
        });
      });

      return stateCopy;

    case REMOVE_ENDED_OUTRIGHT_GAMES:
      stateCopy = { ...state };
      let endedOutrightGames = action.payload;

      endedOutrightGames?.forEach((gm) => {
        Object.values(stateCopy.leagues)?.forEach((league) => {
          const hasGame = league && league?.outrightGames && league?.outrightGames[gm?.gId];
          if (hasGame) {
            delete stateCopy["leagues"][league.leagueId]["outrightGames"][gm?.gId];
          }
        });
      });

      return stateCopy;

    case SAVE_GAME_ALL_ODDS:
      stateCopy = { ...state };
      leagueId = action.payload.leagueId;
      gameId = action.payload.gameId;
      if (stateCopy.leagues[leagueId] && stateCopy.leagues[leagueId]["games"][gameId]) {
        // if (!stateCopy.leagues[leagueId]['games'][gameId].odds) {
        stateCopy.leagues[leagueId]["games"][gameId].odds = action.payload.markets;
        // }

        // if (!stateCopy.leagues[leagueId]['games'][gameId].oddsHistory[marketId]) {
        //     stateCopy.leagues[leagueId]['games'][gameId].oddsHistory[marketId] = {};
        // }

        // stateCopy.leagues[leagueId]['games'][gameId].oddsHistory[marketId] = action.payload.data;
      }
      return stateCopy;

    case DELETE_SELECTED_LEAGUES:
      stateCopy = { ...state };

      if (action.leagueId) {
        // if (!action.gamesOnly) {
        //     delete stateCopy.leagues[action.leagueId]['games'];
        // } else {
        //     delete stateCopy.leagues[action.leagueId];
        // }
        // delete stateCopy.leagues[action.leagueId]['games'];
        if (stateCopy.leagues[action.leagueId]["isOpen"]) {
          stateCopy.leagues[action.leagueId]["isOpen"] = false;
          stateCopy.leagues[action.leagueId]["menuValue"] = "";
        } else {
          stateCopy.leagues[action.leagueId]["isOpen"] = true;
        }
      } else {
        if (stateCopy.leagues) stateCopy.leagues = {};
      }

      return stateCopy;

    case SAVE_LEAGUE_RESULTS:
      let gameDays = { ...action.payload.gameDays };

      Object.values(gameDays)?.forEach((gameDay) => {
        gameDay?.forEach((game, key) => {
          gameDay[key] = calcGame(game);
        });
      });

      result = { ...state };
      leagueId = action.payload.leagueId;
      if (result.leagues[leagueId]) result.leagues[leagueId].results = gameDays;

      return result;

    case SET_LEAGUE_MENU_VALUE:
      stateCopy = { ...state };
      // console.log('lala:' + action.leagueId);
      leagueId = action.leagueId;
      if (action.sportCode) {
        // console.log('okok');
        for (let league in stateCopy.leagues) {
          if (!isNaN(league)) stateCopy.leagues[league].menuValue = action.menuValue;
        }
      } else if (stateCopy.leagues[leagueId]) {
        stateCopy.leagues[leagueId].menuValue = action.menuValue;
      }

      let specificLeagues = Object.values(stateCopy.leagues);

      if (specificLeagues?.length) {
        specificLeagues?.forEach((league) => {
          if (league.games) {
            Object.values(league.games)?.forEach((game) => {
              game.odds?.forEach((odd) => {
                odd["priceChanges"] = [];
              });
            });
          }
        });
      }

      return stateCopy;

    case SAVE_ODDS_HISTORY:
      stateCopy = { ...state };
      leagueId = action.payload.leagueId;
      gameId = action.payload.gameId;
      marketId = action.payload.marketId;
      // hc = action.payload.hc;

      if (stateCopy.leagues[leagueId] && stateCopy.leagues[leagueId]["games"][gameId]) {
        if (!stateCopy.leagues[leagueId]["games"][gameId].oddsHistory) {
          stateCopy.leagues[leagueId]["games"][gameId].oddsHistory = {};
        }

        if (!stateCopy.leagues[leagueId]["games"][gameId].oddsHistory[marketId]) {
          stateCopy.leagues[leagueId]["games"][gameId].oddsHistory[marketId] = {};
        }

        stateCopy.leagues[leagueId]["games"][gameId].oddsHistory[marketId] = action.payload.data;
      }
      return stateCopy;

    case SELECT_SLIP_TEMPLATE:
      result = { ...state, slipTemplate: action.payload };
      return result;

    case UPDATE_SCORES_IN_LEAGUE:
      let newGameState = { ...state };
      let leagues = Object.values(newGameState.leagues);

      let newScores = action.payload;
      if (leagues?.length && newScores?.length) {
        for (let key in newGameState.leagues) {
          let league = newGameState.leagues[key];
          if (league.games) {
            for (let gameId in league.games) {
              let game = league.games[gameId];
              game = updateScore(game, newScores);
            }
          }
        }
      }

      for (let leagueId in newGameState.leagues) {
        let league = newGameState.leagues[leagueId];
        if (league.games) {
          let games = league.games ? league.games : {};
          if (Object.values(games)?.length > 0) {
            for (let key in games) {
              let game = games[key];
              if (game.state === "FT" && new Date().getTime() > game.endTime) {
                delete games[key];
              }
            }
          }
        }
      }

      // please check if league contains games
      // if not remove it from newGameState.leagues list
      // add to logic below
      for (let keys in newGameState.leagues) {
        let league = newGameState.leagues[keys];
        let games = league.games ? Object.keys(league.games) : [];
        if (games?.length === 0) {
          delete newGameState.leagues[keys];
        }
      }

      return { ...state, leagues: { ...newGameState.leagues } };

    case UPDATE_ODDS_IN_LEAGUE:
      let newState = { ...state };
      let leagues1 = Object.values(newState.leagues);
      let newOdds = action.payload;

      if (leagues1?.length && newOdds?.length) {
        leagues1?.forEach((league) => {
          if (league.games) {
            Object.values(league.games)?.forEach((game) => {
              game.odds?.forEach((odd) => {
                updateOdd(game, game.gameid, odd, newOdds);
              });
              if (game.isLive == 1 && game.gameid === 23032145) {
                // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> UPDATE INFO FROM REDUCER START <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");
                const startTimeLocal = localStorage.getItem("updateTimeStart");
                const startTime = new Date(startTimeLocal);
                const currentTime = new Date();
                const mills = currentTime - startTime;
                // console.log("Time taken in milli seconds", mills);
                // console.log("game >>>", game);
                // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> UPDATE INFO FROM REDUCE END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");
              }
            });
          }
        });
      }

      return newState;

    case UPDATE_OUTRIGHT_LEAGUE_ODDS:
      let newOutrightState = { ...state };
      let outrightLeagues1 = Object.values(newOutrightState.leagues);
      let newOutrightOdds = [...action.payload];
      // console.log(newOutrightOdds?.find(id=>id.s===485771)?.p, ">>>>>>>>> initial log");

      if (outrightLeagues1?.length && newOutrightOdds?.length) {
        outrightLeagues1?.forEach((league) => {
          if (league?.outrightGames) {
            Object.values(league.outrightGames)?.forEach((game) => {
              game.odds?.forEach((odd) => {
                updateOutrightOdd(game, game.gameid, odd, newOutrightOdds);
              });
            });
          }
        });
      }
      return newOutrightState;

    case SELECT_CASINO_GAME:
      stateCopy = { ...state };
      gameId = action.gameId;
      let game = action.payload;
      let newGame = { [gameId]: game };

      return { ...stateCopy, casino: newGame };

    case CASINO_GAME_LOADING:
      stateCopy = { ...state };

      return { ...stateCopy, isCasinoLoading: action.payload };

    case DELETE_CASINO_GAME:
      stateCopy = { ...state };

      if (action.id) {
        delete stateCopy.casino[action.id];
      } else {
        stateCopy.casino = {};
      }

      return stateCopy;

    default:
      return state;
  }
};

export default fn;
