/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconWR = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<path d="M49.1,1136.4c7.2,18.4,21.9,33.7,40.6,41.2c75.9,30.8,158.6,46.5,240.7,46.5c14.4,0,28.6-0.3,43.1-1.3
						c96.3-6.7,191.5-35.6,275.2-83.4c232.4-134,518.9-108.6,722.9,61l-250.4,462.9c-10.7,22.7-1.3,49.7,21.4,60.7
						c6.4,3.2,13.1,4.6,19.8,4.6c16.9,0,33.2-9.4,40.9-25.7l700.3-1399c0.8-1.6,1.6-3.5,2.1-5.1c28.9-7.5,50.3-34,50.3-65.3
						c0-37.4-30.5-67.7-67.7-67.7c-37.4,0-67.7,30.5-67.7,67.7c0,8.8,1.6,17.1,4.6,24.6c-1.6,1.9-3,4-4,6.4l-19.3,40.1
						c-105.6-83.2-231.3-134.8-366.7-150c-155.4-17.7-308.9,14.2-444.2,92C883,306.5,760,330.8,644.7,315c-40.6-5.6-83.4-16.8-135.1-35.3
						c-35.3-13.4-75.2,2.1-91.5,36.1L50.9,1078.6C42.4,1096.3,41.6,1117.4,49.1,1136.4z M685.6,410l-98.2,205.7
						c-66.8,2.4-134.2-6.7-199.5-27.5l104.9-218c51.9,17.9,96.5,29.1,139.9,35C650.1,407.6,667.7,409.2,685.6,410z M1241.7,250.3
						l-94.4,198.2c-97.9,8-195,36.9-284.3,88.3l80-168c31.6-11.8,62.6-25.9,92.5-42.5l0.3-0.3c0-0.3,0.3-0.3,0.3-0.3
						C1101.2,288.3,1170.8,263.2,1241.7,250.3z M1653,615.1c-65.5-57.2-139.9-100.3-218.8-128.7l104.6-219.1
						c80.2,24.6,156.2,65.3,223.1,121.2L1653,615.1z M1190.3,998.4l114.5-239.9c78.6,28.4,152.7,71.2,217.7,128.4l-110.2,229.2
						C1345.4,1063.9,1270.5,1024.1,1190.3,998.4z M1026.1,714.9l124.6-261.3c93.4-7.2,187.7,5.1,276.3,36.1l-124.9,262.1
						C1213.6,720.8,1119.7,708.2,1026.1,714.9z M616.1,1053.2l115.3-241.5c1.9-1.1,3.5-1.9,5.3-3c88-50.5,183.8-79.4,280.3-87.7
						L900.1,966.3C801.2,975.1,704.9,1004.6,616.1,1053.2z M466.3,882.3l124.9-262.1c5.3-0.3,11-0.5,16.3-0.8
						c84.8-5.6,168.5-30,243.1-70.1L727.4,807.4C645.8,852.9,556.4,878,466.3,882.3z M141.4,1100l115.5-239.9
						c58.6,18.4,120.1,28.1,181.6,28.1c6.4,0,12.8,0,19.2-0.3l-116.9,245.5C273.7,1134.8,206.3,1123.6,141.4,1100z"/>
			</svg>
		</div>
	);
};

export default IconWR;
