import { alpha, Box, Divider, Stack, styled, Tooltip, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { saveBet } from "../../../../actions";
import { toggleMyTicketsPanel } from "../../../../redux-store/settings-reducer";

const StyledRaceBox = styled(Box)(({ theme, isNr, isSelected }) => ({
  padding: "5px",
  border: "1px solid",
  borderColor: theme.palette.primary.main,
  width: "40px",
  height: "35px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  borderRadius: "4px",
  transition: "0.3s ease-in-out",
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
  }),
  ...(isNr && {
    borderColor: alpha(theme.palette.grey[500], 0.5),
  }),
  ...(!isNr &&
    !isSelected && {
      "&:hover": {
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
      },
    }),
}));

const TrottingSystemRow = ({ game }) => {
  // redux action dispatcher
  const dispatch = useDispatch();
  // select redux values
  const selectedSlipTemplate = useSelector((state) => state.selected.slipTemplate);
  const slips = useSelector((state) => state.slips);
  const betsObject = slips[selectedSlipTemplate].settings.bets;

  const bets = betsObject ? Object.values(betsObject) : [];
  // check if bet is selected
  function checkSelectedOdd(odd, index) {
    let payload = {
      gameId: game.gameid,
    };
    payload.marketId = odd.m;
    payload.selectionId = odd.s;
    payload.price = odd.p;
    payload.hc = odd.hc;
    let flag = false;

    if (!bets) return flag;

    for (let i = 0; i < bets?.length; i++) {
      let bet = bets[i];
      if (bet.gameId === payload.gameId && bet.marketId === payload.marketId && bet.selectionId === payload.selectionId && bet.hc === payload.hc) {
        flag = true;
        break;
      }
    }

    return flag;
  }
  // add bets
  function addBet(odd, index) {
    let payload = {
      gameId: game.gameid,
      isLive: game.isLive,
    };

    const marketId = odd.m;
    const selectionId = odd.s;
    const price = odd.p;
    const hc = odd.hc;
    const isActive = odd.iA;

    payload.marketId = marketId;
    payload.selectionId = selectionId;
    payload.price = price;
    payload.hc = hc;
    payload.hn = odd.hn;
    payload.sn = odd.sn;
    payload.nr = odd?.nr;
    payload.isActive = isActive;
    payload.game = game;
    payload.isOutright = true;
    dispatch(toggleMyTicketsPanel(false));
    dispatch(saveBet(payload, selectedSlipTemplate, "TrottingSystemRow"));
    return Object.keys(payload)?.length === 0 ? false : true;
  }

  let odds = game.odds ? [...game.odds] : [];
  odds = odds.sort((a, b) => a.ord - b.ord);

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box minWidth={100}>
          <Typography variant="body2">{game.game_title}</Typography>
        </Box>
        <Stack direction="row" alignItems="center" ml={2} gap={1} flexWrap="wrap">
          {odds.map((odd, indx) => (
            <Tooltip followCursor title={`${odd.sn} - ${odd.dn}`} key={odd.s}>
              <StyledRaceBox isSelected={checkSelectedOdd(odd, indx)} isNr={Boolean(odd.nr)} key={odd.s} onClick={!odd.nr ? () => addBet(odd, indx) : () => {}}>
                {odd.ord}
              </StyledRaceBox>
            </Tooltip>
          ))}
        </Stack>
      </Box>
      <Box py={1} display={{ xs: "block", md: "none" }}>
        <Divider />
      </Box>
    </Box>
  );
};

TrottingSystemRow.propTypes = {
  game: PropTypes.object,
};

TrottingSystemRow.defaultProps = {
  game: { odds: [] },
};

export default TrottingSystemRow;
