import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const CategoryHotGamesIcon = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 200 200"
      {...props}
    >
      <path d="M154.2,108.7c-5.8-10-8.8-17.4-9.6-29.9c-0.6-9.3,2.7-20,2.7-20c-4.1,3.2-4.2,2.9-6.9,5.5c-14.2,13.7-17.9,27.5-18.3,29.3
	c0,0.1,0,0.2,0,0.2v-0.2c0,0,11.1-50.8-19.7-74.5c-2.6-2-8.5-5.9-8.5-5.9s15.3,44-12.1,70.2c-5.4-25.8-33.9-26.2-33.9-26.2
	s2.2,9,2.5,11.1c4.6,28.1-9.5,37-12.4,63.5c0.3-3,0.2-1.8,0,0l0,0l0,0l0,0c-2.2,20.8,6.2,37,19.2,47.9c0.6-7.4,3.2-14.6,6.8-20.7
	c4-6.9,6.1-12.1,6.7-20.6c0.4-6.4-1.9-13.8-1.9-13.8c2.9,2.2,2.9,2,4.8,3.8c8,7.7,11.2,15.5,12.2,18.7c0,0,0,0,0.1,0
	c-1.4-7.2-5.2-35.2,14-50c1.8-1.4,5.9-4.1,5.9-4.1s-10.9,27.9,13.1,50.5c-0.5-9.3,2.5-17.8,8.1-24.4c3.1-3.6,8.6-5.3,8.6-5.3
	s-1.5,6.2-1.8,7.7c-3.2,19.4,10.7,34.5,7.7,52.9c-0.6,3.4-1.4,6.8-2.5,10h1.3c7.1-4.4,13.6-10.2,18.1-18
	C168.6,148.5,164,125.4,154.2,108.7L154.2,108.7z"/>
    </SvgIcon>
  );
};

export default CategoryHotGamesIcon;
