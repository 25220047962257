// Tickets paging component
import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useSelector, useDispatch } from "react-redux";
import { getTicketGamesAsync, getTicketsAsync } from "../../redux-store/tickets-reducer";
import PropTypes from "prop-types";

const TicketsPagination = ({ startDate, endDate }) => {
  const [page, setPage] = React.useState(1);
  const dispatch = useDispatch();

  const count = useSelector((state) => state.tickets.count);
  const ticketsLimit = useSelector((state) => state.tickets.limit);
  const ticketListStatus = useSelector((state) => state.settings.ticketListStatus);
  const lang = useSelector((state) => state.i18n.locale);

  const pagesCount = Math.ceil(count / ticketsLimit);

  const handleChange = (event, value) => {
    const start = (value - 1) * ticketsLimit;
    setPage(value);
    const getTicketsPayload = {
      startDate: startDate,
      endDate: endDate,
      status: ticketListStatus,
      start: start,
      limit: ticketsLimit,
      lang: lang,
      callbacks: { getTicketGames: (props) => dispatch(getTicketGamesAsync(props)), getTicket: () => {} },
    };
    dispatch(getTicketsAsync(getTicketsPayload));
  };

  React.useEffect(() => {
    setPage(1);
  }, [ticketListStatus]);

  return (
    <Stack
      alignItems="center"
      sx={{
        display: pagesCount && pagesCount > 1 ? "flex" : "none",
      }}
      py={3}
    >
      <Pagination page={page} onChange={handleChange} count={pagesCount} variant="outlined" color="primary" />
    </Stack>
  );
};

TicketsPagination.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default TicketsPagination;
