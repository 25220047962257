import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const CategoryPopularIcon = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 200 200"
      {...props}
    >
<path d="M53.5,126.2v-3.9c-2-2.5-3.9-5.2-5.6-8H30.6v-38H4.1l13.7,24.9l-13.7,25H53.5z"/>
	<polygon points="64.1,148.6 59.5,140.2 55,148.6 45.6,150.4 52.2,157.4 50.9,166.9 59.5,162.8 68.2,166.9 66.9,157.4 73.5,150.4 	
		"/>
	<path d="M100,134.9c20.6,0,38.4-12.2,46.4-29.9h13.7V63h-13.7c-8.1-17.6-25.7-29.9-46.4-29.9S61.5,45.4,53.5,63H39.8v42h13.7
		C61.6,122.7,79.3,134.9,100,134.9z M87.4,66.7L100,43.5l12.6,23.2l25.9,4.9l-18.1,19.1l3.4,26.1L100,105.5l-23.8,11.3l3.3-26.1
		L61.4,71.6L87.4,66.7z"/>
	<path d="M104.5,148.6c-1.5-2.7-3-5.6-4.5-8.4c-1.5,2.8-3.1,5.6-4.6,8.4c-3.2,0.6-6.2,1.2-9.4,1.8c2.2,2.3,4.4,4.6,6.6,7
		c-0.4,3.2-0.9,6.3-1.2,9.5c2.8-1.4,5.7-2.7,8.6-4.1c2.8,1.4,5.7,2.7,8.6,4.1c-0.4-3.2-0.9-6.3-1.2-9.5c2.1-2.4,4.4-4.7,6.5-7
		C110.8,149.8,107.7,149.2,104.5,148.6z"/>
	<polygon points="145,148.6 140.4,140.2 135.8,148.6 126.4,150.4 133,157.4 131.8,166.9 140.4,162.8 149,166.9 147.8,157.4 
		154.4,150.4 	"/>
	<path d="M195.9,76.3h-26.5v38h-17.3c-1.6,2.8-3.6,5.5-5.6,8v3.9h49.4l-13.7-25L195.9,76.3z"/>    </SvgIcon>
  );
};

export default CategoryPopularIcon;
