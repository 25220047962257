/**
 * SlipListBox component - currently not being used
 */
import "./@css/SlipListBox.css";
import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Localize, Translate } from "react-redux-i18n";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import {
  selectSlipTemplate,
  deleteSlipTemplate,
  copySlipTemplate,
} from "../../../actions";
import { formatCommasAndDots } from "../../../helpers/utilities";

const styles = (theme) => ({
  button: {
    "& .MuiButton-root": {
      width: "95%",
      padding: "0.2em 0.2em",
      fontSize: "1.1em",
      textTransform: "none",
      minWidth: "1em",
    },
  },
});

class SlipListBox extends React.PureComponent {
  openSlipDetails = () => {
    const { thisSlipTemplate } = this.props;
    this.props.selectSlipTemplate(thisSlipTemplate, this.constructor.name);
    this.props.parentCallBack(thisSlipTemplate);
  };

  deleteSlipTemplate = () => {
    const { slipTemplates, thisSlipTemplate, selectedSlipTemplate } =
      this.props;
    this.props.deleteSlipTemplate(thisSlipTemplate, this.constructor.name);
    if (thisSlipTemplate === selectedSlipTemplate)
      this.props.selectSlipTemplate(
        Object.keys(slipTemplates)?.length - 2,
        this.constructor.name
      );
  };

  copySlipTemplate = () => {
    const { slipTemplates, thisSlipTemplate } = this.props;
    this.props.copySlipTemplate(thisSlipTemplate, this.constructor.name);
    this.props.selectSlipTemplate(
      Object.keys(slipTemplates)?.length,
      this.constructor.name
    );
  };

 
  renderSlipTemplate() {
    const { slipTemplates, thisSlipTemplate, selectedSlipTemplate, classes } =
      this.props;

    if (!slipTemplates[thisSlipTemplate]) return;

    let slipTemplateData = slipTemplates[thisSlipTemplate];
    let betTypeLabel = "";

    switch (slipTemplateData.betType) {
      case "single":
      case "combi":
        betTypeLabel = <Translate value={`slip.${slipTemplateData.betType}`} />;
        break;

      case "system":
        let totalBanks = slipTemplateData.settings.cntBanks;
        let totalBets = Object.keys(slipTemplateData.settings.bets)?.length;
        let betsCount = [],
          combiTypes = [];

        if (totalBanks > 0) {
          betTypeLabel += totalBanks + "B + ";
          betsCount = totalBets - totalBanks;
          let arrCombiTypesAfterBanks = [];
          slipTemplateData.settings.combiTypes?.forEach((item, index) => {
            let key = Number(item) - totalBanks;
            if (key > 0) arrCombiTypesAfterBanks.push(key);
          });
          combiTypes = arrCombiTypesAfterBanks.join();
        }

        betTypeLabel +=
          combiTypes +
          " aus " +
          betsCount +
          " = " +
          slipTemplateData.settings.availableCombiCounts?.length +
          " Wetten"; //ticket.combiCount
        betTypeLabel = slipTemplateData.settings.hasMulti
          ? "Combi (" +
            slipTemplateData.settings.availableCombiCounts?.length +
            " Wege)"
          : betTypeLabel;

        break;

      default:
        break;
    }

    return (
      <div className="bg-light">
        <div className="slips-list-title">
          <div className="color-contrast text-wrap vertical-center font-m spacer-left">
            {betTypeLabel}
          </div>
          <div className="color-contrast text-wrap vertical-center font-m">
            <Translate value={`slip.stake`} />
          </div>
          <div className="color-contrast text-wrap font-bold vertical-center font-m flex-end spacer-right">
            {formatCommasAndDots(slipTemplateData.stake, this.props.locale)}
          </div>
          <div></div>
          <div className="text-wrap vertical-center spacer-left font-m">
            <Translate value={`slip.slip`} />{" "}
            {slipTemplates?.length > 1
              ? slipTemplateData.slipTemplate + 1
              : null}
          </div>
          <div className="text-wrap vertical-center font-m">
            <Translate value="labels.ticket_win_chance" />
          </div>
          <div className="text-wrap vertical-center font-xs flex-end">
            min.
            {slipTemplateData.settings.winMin ? (
              <Localize
                value={slipTemplateData.settings.winMin}
                options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
              />
            ) : null}
            /max.
            {slipTemplateData.settings.winMax ? (
              <Localize
                value={slipTemplateData.settings.winMax}
                options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
              />
            ) : null}
          </div>
        </div>
        {/* </Link> */}
        <div className="slips-list-option-btn text-center">
          <div className={classes.button}>
            <Button
              className=""
              variant="outlined"
              onClick={(e) => this.deleteSlipTemplate()}
              disabled={Object.keys(slipTemplates)?.length === 1 ? true : false}
              style={{
                borderColor: "var(--red)",
                color: "var(--red)",
              }}
            >
              <Translate value={`slip.delete`} />
            </Button>
          </div>
          <div className={classes.button}>
            <Button
              // className="contrast-button"
              variant="outlined"
              color="primary"
              onClick={(e) => this.copySlipTemplate()}
            >
              <Translate value={`slip.copy`} />
            </Button>
          </div>
          <div className={classes.button}>
            <Button
              className=""
              variant="outlined"
              onClick={(e) => this.openSlipDetails()}
              style={{
                borderColor:
                  thisSlipTemplate === selectedSlipTemplate
                    ? "var(--green)"
                    : "var(--color-contrast)",
                color:
                  thisSlipTemplate === selectedSlipTemplate
                    ? "var(--green)"
                    : "var(--color-contrast)",
              }}
            >
              {thisSlipTemplate === selectedSlipTemplate ? (
                <Translate value={`slip.active`} />
              ) : (
                <Translate value={`slip.select`} />
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    log(this.constructor.name, "render");
    return <div>{this.renderSlipTemplate()}</div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    slipTemplates: state.slips,
    selectedSlipTemplate: state.selected.slipTemplate,
    thisSlipTemplate: ownProps.slipTemplate,
  };
};

export default connect(mapStateToProps, {
  selectSlipTemplate,
  deleteSlipTemplate,
  copySlipTemplate,
})(withStyles(styles)(SlipListBox));
