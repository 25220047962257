import { Paper, Stack, styled, Typography } from "@mui/material";
import { format } from "date-fns";
import createJersey from "../../@components/@utils/create-jersey";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.results,
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  cursor: "pointer",
  userSelect: "none",
  height: "100%",
}));

const BetbuilderGameCard = ({ game }) => {
  const navigate = useNavigate();

  // selectros
  const lang = useSelector((state) => state.i18n.locale);

  const handleRoute = () => {
    navigate(`/${lang}/betbuilder/${game.gameid}?league=${game.leagueID}&sport=${game.sport_code}`);
  };

  return (
    <StyledPaper onClick={handleRoute}>
      <Stack>
        <Typography variant="body2" align="center" color="text.secondary">
          {format(new Date(game.startTime), "dd.MM. HH:mm")}
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between" >
          
          <Stack
            gap={1}
            width="40%"
            alignItems="top"
            justifyContent="center"
          >
            {createJersey(
              game,
              game?.team1_color,
              game?.team1_horizontal_stripes,
              game?.team1_number,
              game?.team1_sleeve,
              game?.team1_split,
              game?.team1_squares,
              game?.team1_stripes,
              game?.team1_sleeve_detail,
              game?.sport_code,
              true
            )}
            <Typography variant="teamnamebb" color="primary.team" sx={{ textAlign: "center", width: "100%" }}>{game.team1}</Typography>
          </Stack>

          <Typography
            variant="h3"
            color="primary.featured2"
            width="20%"
            textAlign="center"
          >
            VS
          </Typography>
          <Stack
            gap={1}
            width="40%"
            alignItems="top"
            justifyContent="center"
          >
            {createJersey(
              game,
              game?.team2_color,
              game?.team2_horizontal_stripes,
              game?.team2_number,
              game?.team2_sleeve,
              game?.team2_split,
              game?.team2_squares,
              game?.team2_stripes,
              game?.team2_sleeve_detail,
              game?.sport_code,
              false
            )}
            <Typography variant="teamnamebb" color="primary.team" sx={{ textAlign: "center", width: "100%" }}>{game.team2}</Typography>
          </Stack>
        </Stack>


      </Stack>
    </StyledPaper>
  );
};

export default BetbuilderGameCard;
