/**
 * Show Limit form based on limit type ( Max bet, Max promo bet )
 */

import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { withStyles } from "@mui/styles";
import { Translate } from "react-redux-i18n";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import { saveLimits } from "../../actions";

const styles = (theme) => ({
  root: {
    "& .MuiContainer-root": {
      padding: theme.spacing(2),
      width: "100%",
    },
    "& .MuiFormControl-root": {
      margin: "1em 0",
      width: "100%",
    },
    "& .MuiButton-root": {
      margin: "1em 1em 0 0",
    },
    "& .MuiTypography-body1": {
      fontSize: "1em",
    },
    "& .MuiInputBase-input": {
      fontSize: "1em",
    },
    "& .MuiFormHelperText-root": {
      fontSize: "1em",
    },
    "& .MuiFormLabel-root": {
      fontSize: "1em",
    },
  },
  radioButton: {
    "& svg": {
      width: '1em"',
      height: "1em",
    },
  },
  maxAmount: {
    color: "var(--color-max-bet-limit)",
    margin: "1em 0",
    fontSize: "1.3em",
    fontWeight: "bold",
  },
});

class LimitsBetsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  renderNumericField = ({
    input,
    label,
    min,
    amount,
    max,
    meta: { touched, invalid, error },
    ...custom
  }) => {
    return (
      <TextField
        label={label}
        placeholder={label}
        InputProps={{
          min: min,
          max: max,
        }}
        error={touched && invalid}
        helperText={!!touched && !!invalid && error}
        variant="outlined"
        type="number"
        {...input}
        {...custom}
      />
    );
  };

  onSubmit = (formValues) => {
    const { type } = this.props;
    let data = {};
    switch (type) {
      case "max_bet":
        data = {
          ...formValues,
          limitType: "maxCashBet",
        };
        this.props.saveLimits(data, this.constructor.name);
        break;
      case "max_promo_bet":
        data = {
          ...formValues,
          limitType: "maxPromoBet",
        };
        this.props.saveLimits(data, this.constructor.name);
        break;
      default:
        break;
    }
  };

  render() {
    log(this.constructor.name, "render");
    const { translate, type, classes, limits, amount, extraData, host } =
      this.props;
    if (!limits) return <div>Loading...</div>;
    return (
      <div>
        <Grid
          container
          spacing={2}
          className={classes.root}
          style={{ padding: "0 0 4em 0" }}
        >
          <Grid item xs={2} md={2}></Grid>
          <Grid item xs={8} md={8}>
            <b>
              <h3>
                <Translate value={`labels.limits_${type}_text_1`} />
              </h3>
            </b>
          </Grid>
          <Grid item xs={2} md={2}></Grid>
          <Grid item xs={2} md={2}></Grid>
          <Grid item xs={8} md={8}>
            <Translate value={`labels.limits_${type}_text_2`} />
          </Grid>
          <Grid item xs={2} md={2}></Grid>
          <Grid item xs={2} md={2}></Grid>
          <Grid item xs={8} md={8}>
            <Grid
              container
              spacing={2}
              style={{
                padding: "1em",
                border: "1px solid var(--color-contrast)",
                borderRadius: "0.5em",
                margin: "1em 0 0",
              }}
            >
              <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
                <Translate value={`labels.limits_${type}_text_3`} />
                <br></br>
                <div className={classes.maxAmount}>
                  {extraData[5].amount}{" "}
                  {host.brand === "nubet_se" ? "SEK" : "EUR"}
                </div>
              </Grid>
              <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
                <Translate value={`labels.limits_${type}_text_4`} />
                <br></br>
                <div className={classes.maxAmount}>
                  {extraData[6].amount}{" "}
                  {host.brand === "nubet_se" ? "SEK" : "EUR"}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} md={2}></Grid>
        </Grid>
      </div>
    );
  }
}

const validate = (values, props) => {
  const errors = {};

  const requiredFields = [];

  requiredFields?.forEach((field) => {
    if (!values[field]) {
      errors[field] = props.translate.labels.field_required;
    }
  });

  return errors;
};

const mapStateToProps = (state, ownProps) => ({
  auth: state.user.auth,
  type: ownProps.type,
  limits: state.user.limits ? state.user.limits : null,
  layout: state.settings.layout,
  translate: state.i18n.translations[state.i18n.locale],
  initialValues: ownProps.initialValues,
  amount: ownProps.amount,
  extraData: state.user.limits.extraData,
  host: state.settings.host,
});

export default connect(mapStateToProps, { saveLimits })(
  reduxForm({
    form: "LimitsBetsForm",
    validate,
    enableReinitialize: true,
  })(withStyles(styles)(LimitsBetsForm))
);
