// MUI Drawer
export function drawer(theme) {
  return {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: theme.palette.background.drawer,
        },
      },
    },
  };
}
