/**
 * Sign in / Log in page
 */
import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Translate } from "react-redux-i18n";
import { withStyles } from "@mui/styles";
import { signIn, getWalletBalanceDetails, registerUserWithTrustly } from "../../actions";
import { getTicketsAsync, getTicketGamesAsync } from "../../redux-store/tickets-reducer";
import { generateDatabetTokenAsync } from "../../redux-store/databet-reducer";
import { getCustomerFreeSpinsAsync } from "../../redux-store/casino-reducer";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Field, reduxForm } from "redux-form";

import PasswordField from "../Inputs/PasswordInput";
import CustomTextField from "../Inputs/TextField";
import withRouter from "../../helpers/withRouter";

const styles = (theme) => ({
  root: {
    padding: "3em",
    "& .MuiContainer-root": {
      width: "100%",
      height: "100vH",
      "@media (hover:none)": {
        padding: "0",
      },
    },
    "& .MuiButton-root": {
      margin: "1.5em 1em 0 0",
      fontSize: "1em",

      "@media (hover:none)": {
        width: "100%",
        fontSize: "1.5em",
      },
      "@media screen and (max-width: 680px)": {
        width: "100%",
        fontSize: "1.5em",
      },
    },
    "& .MuiFormControl-root": {
      margin: "1em 1em 0 0",
      width: "100%",
      "@media (hover:hover)": {
        width: "48%",
      },
      "@media screen and (max-width: 680px)": {
        width: "100%",
      },
    },
    "& .MuiFormLabel-root": {
      fontSize: "1.3em",
    },
    "& .MuiInput-root": {
      fontSize: "1.3em",
    },
    backgroundColor: "var(--color-main)",
    "& .MuiIconButton-root": {
      fontSize: "2em",
    },
    "& .MuiGrid-spacing-xs-2": {
      margin: "0",
    },
  },
  title: {
    fontWeight: "600",
    textTransform: "uppercase",
    fontSize: "1.3em",
  },
  gameInfo: {
    display: "flex",
    alignItems: "center",
    padding: "1em 0",
    cursor: "pointer",
    fontSize: "1em",
  },
  iconVisible: {
    "& .MuiSvgIcon-root": {
      fontSize: "0.8em",
    },
  },
  button: {
    "& .MuiButton-iconSizeSmall > *:first-child": {
      fontSize: "1.6em",
    },
  },
  buttonBanklogin: {
    color: "var(--btn-text)",
    backgroundColor: "var(--banklogin)",
    padding: "1.5em 2em",
    "&:hover": {
      backgroundColor: "var(--banklogin-hover)",
    },
  },
  formText: {
    margin: "0 0 1em 0",
    // color: 'var(--color-input-amount)',
    fontSize: "1.5em",
  },
  featText: {
    textTransform: "uppercase",
    fontStyle: "italic",
    fontWeight: 500,
    color: "var(--color-contrast)",
  },
  ammountButton: {
    color: "var(--btn-text)",
    fontSize: "1.5em !important",
    margin: "0 1em !important",
    "@media (hover:none)": {
      width: "22% !important",
      fontSize: "1.5em",
    },
    "@media screen and (max-width: 680px)": {
      width: "18% !important",
      fontSize: "1.5em",
    },
  },
  ammountField: {
    "& .MuiOutlinedInput-root": {
      // width: '15em',
    },
    "& .MuiInput-input": {
      color: "var(--color-input-amount)",
      fontSize: "1.5em",
      textAlign: "center",
    },
  },
});

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    let dateFrom = new Date();
    dateFrom.setDate(dateFrom.getDate() - 365);

    this.state = {
      dateFrom: dateFrom,
      dateTo: Date.now(),
      showPassword: false,
      amount: "0",
      remainTime: "",
    };

    this.timer = null;
  }

  UNSAFE_componentWillMount() {
    /**
     * Get balance and tickets data when user is signed in successfully
     */
    const { auth, navigate } = this.props;

    if (auth.isSignedIn) {
      navigate(`/${this.props.locale}`);
      this.props.getWalletBalanceDetails(this.constructor.name);
      const payload = {
        startDate: this.formatDateToApi(this.state.dateFrom),
        endDate: this.formatDateToApi(this.state.dateTo),
        status: "pending",
        start: 0,
        limit: this.props.ticketsLimit,
        lang: this.props.locale,
        callbacks: { getTicketGames: (props) => this.props.getTicketGamesAsync(props), getTicket: () => {} },
      };
      this.props.getTicketsAsync(payload);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.auth !== this.props.auth) {
      if (newProps.auth.isSignedIn) {
        const search = this.props.location.search;
        if (search) {
          // return to previous page
          const previousPage = search.split("?redirect_to=")[1];
          this.props.navigate(`/${this.props.locale}/${previousPage}`);
        } else {
          this.props.navigate(`/${this.props.locale}`);
        }
        this.props.getWalletBalanceDetails(this.constructor.name);
        const payload = {
          startDate: this.formatDateToApi(this.state.dateFrom),
          endDate: this.formatDateToApi(this.state.dateTo),
          status: "pending",
          start: 0,
          limit: this.props.ticketsLimit,
          lang: this.props.locale,
          callbacks: { getTicketGames: (props) => this.props.getTicketGamesAsync(props), getTicket: () => {} },
        };
        this.props.getTicketsAsync(payload);
      } else {
        // start calculating Time remaining
        if (newProps.auth.rgCooloffExpires) {
          this.calculateRemainTime();
        }
      }
    }
  }

  signInCallback = async () => {
    await this.props.generateDatabetTokenAsync({ isAuthenticated: false, lang: this.props.locale });
    await this.props.getCustomerFreeSpinsAsync();
  };

  handleSignIn = (values) => {
    /**
     * Trigger sign in request
     */
    if (values.email && values.userPass) {
      this.props.signIn(values.email, values.userPass, this.constructor.name, false, this.signInCallback);
    }
  };

  formatDateToApi(date) {
    /**
     * Format the date to YYYY-MM-DD string
     */

    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(date);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);

    // return (`${da}.${mo}.${ye}`);
    return `${ye}-${mo}-${da}`;
  }

  calculateRemainTime = () => {
    /**
     * Calculate remained cool down time
     */
    let self = this;
    clearInterval(self.timer);
    self.timer = setInterval(() => {
      const { auth } = self.props;
      const { rgCooloffExpires } = auth;
      const currentTime = new Date().getTime();
      const timeDifference = rgCooloffExpires * 1000 - currentTime;

      if (timeDifference === 0) {
        clearInterval(self.timer);
      } else {
        // get total seconds between the times
        let delta = Math.abs(timeDifference) / 1000;

        // calculate (and subtract) whole days
        let days = Math.floor(delta / 86400);
        delta -= days * 86400;

        // calculate (and subtract) whole hours
        let hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;

        // calculate (and subtract) whole minutes
        let minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;

        // what's left is seconds
        let seconds = parseInt(delta % 60, 10); // in theory the modulus is not required

        let remainTime = "";

        if (days) {
          if (days === 1) {
            remainTime += `1 day`;
          } else {
            remainTime += `${days} days`;
          }
        }

        remainTime += " ";

        if (hours) {
          if (hours === 1) {
            remainTime += `1 hour`;
          } else {
            remainTime += `${hours} hours`;
          }
        }

        remainTime += " ";

        if (minutes) {
          if (minutes === 1) {
            remainTime += `1 minute`;
          } else {
            remainTime += `${minutes} minutes`;
          }
        }

        remainTime += " ";

        if (seconds) {
          if (seconds === 1) {
            remainTime += `1 second`;
          } else {
            remainTime += `${seconds} seconds`;
          }
        }

        self.setState({ remainTime });
      }
    }, 1000);
  };

  componentDidUpdate() {
    const { trustlyDepositStatus, locale, navigate } = this.props;
    if (!window.location.href.includes("/register/trustly") && !window.location.href.includes("/trustly/deposit/waiting") && !window.location.href.includes("/interfaces/message?type=deposit")) {
      if (trustlyDepositStatus?.payload && trustlyDepositStatus?.payload?.status === "ok") {
        navigate(`/${locale}/register/trustly`);
      } else if (trustlyDepositStatus?.payload && trustlyDepositStatus?.payload?.status === "pending") {
        navigate(`/${locale}/trustly/deposit/waiting`);
      }
    }
  }

  render() {
    log(this.constructor.name, "render");
    const { auth, classes, host, translate, locale } = this.props;
    const { remainTime } = this.state;

    if (!this.props.auth.isSignedIn) {
      // start calculating Time remaining
      if (this.props.auth.rgCooloffExpires) {
        this.calculateRemainTime();
      }
    }

    return (
      <div key="signed-out">
        {auth.isBlockedUser ? (
          <div className={classes.root}>
            <div>
              <Container maxWidth="md">
                <Typography variant="h6" component="h4" color="primary" style={{ margin: "0 0 1.5em 0" }}>
                  <Translate value="labels.rg_account_blocked" />
                </Typography>
                <Typography
                  variant="h7"
                  component="h4"
                  style={{
                    margin: "0 0 1.5em 0",
                    color: "var(--grey)",
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                >
                  <Translate value="labels.rg_temp_block" />
                </Typography>

                <Typography
                  variant="h7"
                  component="h4"
                  style={{
                    margin: "0 0 1.5em 0",
                    color: "var(--grey)",
                    textAlign: "center",
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                >
                  <Translate value="labels.rg_block_cooloff" dangerousHTML time={remainTime} />
                </Typography>
              </Container>
            </div>
          </div>
        ) : (
          <form className={classes.root} onSubmit={this.props.handleSubmit(this.handleSignIn)} noValidate autoComplete="off">
            <div>
              <Container maxWidth="md">
                {/* <Typography className={classes.title} color="primary" style={{ margin: "0 0 1em 0" }}>
                    <Translate value="labels.account_login" />
                  </Typography> */}
                {host.gaming && !host.bet
                  ? [
                      <div className={classes.gameInfo}>
                        <Translate value="slots.not_logged_in" />
                      </div>,
                    ]
                  : null}
                <Field name="email" id="trustly_email" component={CustomTextField} classes={classes} label={translate.labels.email} type="email" variant="standard" />
                <Field name="userPass" id="trustly_password" maxLength={8} component={PasswordField} isCheckOff classes={classes} label={translate.labels.password} variant="standard" />
                {auth.emailConfirmed === false ? (
                  <div style={{ fontSize: "14px", marginTop: "12px" }}>
                    <Translate value="labels.email_not_confirmed" dangerousHTML link={`/${locale}/resend?email=${auth.email}`} />
                  </div>
                ) : null}

                <br></br>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{
                    color: "var(--btn-text)",
                    // background: "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%",
                  }}
                  id="login_button"
                  type="submit"
                >
                  <Translate value="labels.account_login" />
                </Button>
                <Link to={`/${locale}/forgot-password`}>
                  <Button variant="outlined" color="primary" size="small" className={classes.button} startIcon={<VpnKeyIcon />}>
                    <Translate value="labels.forget_pass" />
                  </Button>
                </Link>
                <Link to={`/${locale}/register-switch`}>
                  <Button variant="outlined" color="primary" size="small" className={classes.button} startIcon={<PersonAddIcon />}>
                    <Translate value="labels.register" />
                  </Button>
                </Link>
              </Container>
            </div>
          </form>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let leagueFilter = state.leagues._leagueFilter;
  let sportCode = state.leagues._sportCode;
  let leagues;
  let favoriteLeagues = state.leagues.favorites;
  switch (leagueFilter) {
    case "live":
      leagues = state.selected.leagues;
      break;

    case "search":
      leagues = state.leagues.all;

      break;

    case "favorites":
      for (let sportCode in favoriteLeagues) {
        for (let leagueId in favoriteLeagues[sportCode]) {
          leagues = {
            ...leagues,
            [leagueId]: favoriteLeagues[sportCode][leagueId],
          };
        }
      }
      break;

    default:
      leagues = state.leagues[leagueFilter] && state.leagues[leagueFilter][sportCode] ? state.leagues[leagueFilter][sportCode] : null;
      break;
  }
  return {
    auth: state.user.auth,
    locale: state.i18n.locale,
    host: state.settings.host,
    leagues: leagues,
    leagueFilter: leagueFilter,
    sportCode: sportCode,
    translate: state.i18n.translations[state.i18n.locale],
    trustlyDepositStatus: state.user.trustlyDepositStatus,
    ticketsLimit: state.tickets.limit,
  };
};

const validate = (values, props) => {
  const errors = {};
  const emailValidationCheck = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const requiredFields = ["email", "userPass"];

  requiredFields?.forEach((field) => {
    if (!values[field]) {
      errors[field] = props.translate.labels.field_required;
    }
  });
  const checkEmail = (field, error) => {
    if (values[field] && !emailValidationCheck.test(values[field])) {
      errors[field] = error;
    }
  };
  checkEmail("email", props.translate.labels.invalid_email);
  return errors;
};

export default withRouter(
  connect(mapStateToProps, {
    signIn,
    getWalletBalanceDetails,
    getTicketGamesAsync,
    registerUserWithTrustly,
    getTicketsAsync,
    generateDatabetTokenAsync,
    getCustomerFreeSpinsAsync,
  })(
    reduxForm({
      form: "Login",
      validate,
      // enableReinitialize: true,
    })(withStyles(styles)(SignIn))
  )
);
