import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getCasinoGame,
  getCasinoGamesByFeature,
  getCasinoGamesByProvider,
  getCasinoGamesGroupNames,
  getCasinoSlidersGames,
  getCustomerFreeSpins,
  getUserFavGamesIds,
  getUserFavoriteCasinoGames,
  getVendors,
  saveFavoriteCasinoGames,
  searchCasinoGames,
} from "./_requests";

const initialState = {
  allGames: [],
  games: [],
  gameGroups: [],
  searchedGames: [],
  favGames: {
    ids: [],
    games: [],
  },
  bonuses: {},
  slider: {
    newGames: [],
    popularGames: [],
    liveGames: [],
    dropAndWinGames: [],
    winOftenGames: [],
    winAlotGames: [],
    megawayGames: [],
    jackpotGames: [],
    hotGames: [],
    classicGames: [],
    bonusBuyGames: [],
    tournamentGames: [],
  },
  gamesByFeature: {
    list: [],
    isLoading: false,
    page: 1,
    pageCount: 10,
    pageSize: 20,
    groupId: null,
  },
  gamesByProvider: {
    list: [],
    isLoading: false,
    page: 1,
    pageCount: 10,
    pageSize: 20,
  },
  providers: [],
  isGamesLoading: false,
  isFreeSpinsLoading: false,
  freeSpinsLoaded: false,
  selectedGame: {
    data: {},
    isLoading: false,
  },
};

// thunk requests
export const getCustomerFreeSpinsAsync = createAsyncThunk("thunk/getCustomerFreeSpins", getCustomerFreeSpins);
export const getCasinoSlidersGamesAsync = createAsyncThunk("thunk/getCasinoSlidersGames", getCasinoSlidersGames);
export const getVendorsAsync = createAsyncThunk("thunk/getVendors", getVendors);
export const getCasinoGamesByFeatureAsync = createAsyncThunk("thunk/getCasinoGamesByFeature", getCasinoGamesByFeature);
export const getCasinoGamesGroupNamesAsync = createAsyncThunk("thunk/getCasinoGamesGroupNames", getCasinoGamesGroupNames);
export const searchCasinoGamesAsync = createAsyncThunk("thunk/searchCasinoGames", searchCasinoGames);
export const getCasinoGamesByProviderAsync = createAsyncThunk("thunk/getCasinoGamesByProvider", getCasinoGamesByProvider);
export const getUserFavGamesIdsAsync = createAsyncThunk("thunk/getUserFavGamesIds", getUserFavGamesIds);
export const getUserFavoriteCasinoGamesAsync = createAsyncThunk("thunk/getUserFavoriteCasinoGames", getUserFavoriteCasinoGames);
export const getCasinoGameAsync = createAsyncThunk("thunk/getCasinoGame", getCasinoGame);
export const saveFavoriteCasinoGamesAsync = createAsyncThunk("thunk/saveFavoriteCasinoGames", saveFavoriteCasinoGames);

const casinoSlice = createSlice({
  name: "slice/settings",
  initialState,
  reducers: {
    resetCasinoFeatureGames: (state, { payload }) => {
      state.gamesByFeature.list = [];
      state.gamesByFeature.page = 1;
      state.gamesByFeature.pageCount = 10;
      state.gamesByFeature.pageSize = 20;
      if (payload) {
        state.gamesByFeature.groupId = payload;
      }
    },
    resetCasinoProviderGames: (state) => {
      state.gamesByProvider.list = [];
      state.gamesByProvider.page = 1;
      state.gamesByProvider.pageCount = 10;
      state.gamesByProvider.pageSize = 20;
    },
    removeSelectedCasinoGame: (state) => {
      state.selectedGame.data = {};
    },
  },
  extraReducers: (builder) => {
    //   GET CUSTOMER FREE SPINS
    builder
      .addCase(getCustomerFreeSpinsAsync.pending, (state) => {
        state.isFreeSpinsLoading = true;
      })
      .addCase(getCustomerFreeSpinsAsync.rejected, (state) => {
        state.isFreeSpinsLoading = false;
      })
      .addCase(getCustomerFreeSpinsAsync.fulfilled, (state, { payload }) => {
        let freeSpins = payload;
        let transactions = freeSpins?.transactions;
        const freeSpinGames = [];

        transactions?.forEach((transaction) => {
          transaction?.games?.forEach((game) => {
            freeSpinGames.push(game);
          });
        });
        state.isFreeSpinsLoading = false;
        state.bonuses = freeSpins;
        state.games = freeSpinGames;
        state.allGames = freeSpinGames;
      })
      // GET SLIDER GAMES
      .addCase(getCasinoSlidersGamesAsync.fulfilled, (state, { payload }) => {
        if (payload?.groupType === "new") {
          state.slider.newGames = payload.games;
        }
        if (payload?.groupType === "livecasino") {
          state.slider.liveGames = payload.games;
        }
        if (payload?.groupType === "popular") {
          state.slider.popularGames = payload.games;
        }
        if (payload?.groupType === "dropsandwins") {
          state.slider.dropAndWinGames = payload.games;
        }
        if (payload?.groupType === "winoften") {
          state.slider.winOftenGames = payload.games;
        }
        if (payload?.groupType === "winalot") {
          state.slider.winAlotGames = payload.games;
        }
        if (payload?.groupType === "Megaways") {
          state.slider.megawayGames = payload.games;
        }
        if (payload?.groupType === "jackpots") {
          state.slider.jackpotGames = payload.games;
        }
        if (payload?.groupType === "classics") {
          state.slider.classicGames = payload.games;
        }
        if (payload?.groupType === "bonusbuy") {
          state.slider.bonusBuyGames = payload.games;
        }
        if (payload?.groupType === "hotgames") {
          state.slider.hotGames = payload.games;
        }
        if (payload?.groupType === "tournament") {
          state.slider.tournamentGames = payload.games;
        }
      })
      // GET VENDORS
      .addCase(getVendorsAsync.fulfilled, (state, { payload }) => {
        const allProviders = payload && typeof payload === "object" ? payload[0] : [];
        const orderList = [3, 116, 83];
        let mainProviders = [];
        const subProviders = [];
        allProviders.forEach((provider) => {
          const scheme = {
            supplier_id: provider.provider_id,
            supplier_name: provider.provider_name,
            order: 100,
          };
          if (orderList.includes(scheme.supplier_id)) {
            scheme.order = orderList.findIndex((itm) => itm === scheme.supplier_id);
          }
          mainProviders.push(scheme);
          provider.suppliers.forEach((supplier) => {
            subProviders.push({ ...supplier, order: 100 });
          });
        });

        mainProviders = mainProviders.concat(subProviders);
        mainProviders = mainProviders.sort((a, b) => a.order - b.order);
        state.providers = mainProviders;
      })
      //
      .addCase(getCasinoGamesByFeatureAsync.fulfilled, (state, { payload }) => {
        const currentList = state.gamesByFeature.list;
        state.gamesByFeature.list = payload.data?.games ? [...currentList, ...payload.data.games] : currentList;
        state.gamesByFeature.page = payload.data?.pagination?.page ? payload.data.pagination.page + 1 : 1;
        state.gamesByFeature.pageCount = payload.data?.pagination?.pageCount ? payload.data.pagination.pageCount : 10;
        state.gamesByFeature.pageSize = payload.data?.pagination?.size ? payload.data.pagination.size : 20;
        state.gamesByFeature.groupId = payload.groupId;
      })
      // GET CASINO GROUP NAMES
      .addCase(getCasinoGamesGroupNamesAsync.fulfilled, (state, { payload }) => {
        state.gameGroups = payload;
      })
      .addCase(searchCasinoGamesAsync.fulfilled, (state, { payload }) => {
        const loadedGames = typeof payload === "object" ? payload : {};
        let gamesList = loadedGames["All"] || [];
        gamesList = gamesList.reduce((accumulator, current) => {
          if (!accumulator.find((item) => item.gameId === current.gameId || item.translation === current.translation)) {
            accumulator.push(current);
          }
          return accumulator;
        }, []);
        state.searchedGames = gamesList;
      })
      .addCase(getCasinoGamesByProviderAsync.fulfilled, (state, { payload }) => {
        const currentList = state.gamesByProvider.list;
        state.gamesByProvider.list = payload?.games ? [...currentList, ...payload.games] : currentList;
        state.gamesByProvider.page = payload?.pagination?.page ? payload.pagination.page + 1 : 1;
        state.gamesByProvider.pageCount = payload?.pagination?.pageCount ? payload.pagination.pageCount : 10;
        state.gamesByProvider.pageSize = payload?.pagination?.size ? payload.pagination.size : 20;
      })
      .addCase(getUserFavGamesIdsAsync.fulfilled, (state, { payload }) => {
        let favIdsPayload = typeof payload === "object" ? payload : [];
        favIdsPayload = favIdsPayload.map((favObject) => favObject.gameId);
        state.favGames.ids = favIdsPayload;
      })
      .addCase(getUserFavoriteCasinoGamesAsync.fulfilled, (state, { payload }) => {
        let favGames = typeof payload === "object" ? Object.values(payload) : [];
        favGames = favGames.reduce((accumulator, current) => {
          if (!accumulator.find((item) => item.gameId === current.gameId || item.translation === current.translation)) {
            accumulator.push(current);
          }
          return accumulator;
        }, []);
        state.favGames.games = favGames;
      })
      // GET CASINO GAME
      .addCase(getCasinoGameAsync.pending, (state) => {
        state.selectedGame.isLoading = true;
      })
      .addCase(getCasinoGameAsync.rejected, (state) => {
        state.selectedGame.isLoading = false;
      })
      .addCase(getCasinoGameAsync.fulfilled, (state, { payload }) => {
        const selectedGame = typeof payload === "object" ? payload : {};
        state.selectedGame.isLoading = false;
        state.selectedGame.data = selectedGame;
      });
  },
});

export const { resetCasinoFeatureGames, resetCasinoProviderGames, removeSelectedCasinoGame } = casinoSlice.actions;

export default casinoSlice.reducer;
