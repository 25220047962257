import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const CategoryWinALotIcon = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 200 200"
      {...props}
    >
      <path d="M123.5,36.8l26.2-10.3l7.8,22.5l-26.6,9l-7.4-21.1L123.5,36.8z M76.5,36.8L50.3,26.5L42.5,49l26.6,9l7.4-21.1V36.8z
	 M46.1,56.5c-4.7,9.9-18.8,32.2-18.8,57c0.4,43.9,32.6,60,72.7,60s72.3-16.1,72.7-60c0-24.8-14.1-47.1-18.8-57l-20.8,6.8
	c12.8,32,29.9,80-33,82.5c-62.9-2.5-45.8-50.4-33-82.5l-20.8-6.8L46.1,56.5z"/>
    </SvgIcon>
  );
};

export default CategoryWinALotIcon;
