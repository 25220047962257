/**
 * Show Limit form based on limit type ( Stake, Loss, Deposit )
 */

import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { withStyles } from "@mui/styles";
import { Translate } from "react-redux-i18n";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import LimitsConfirmationPopUp from "./Limits-dialogs/LossLimitConfirmationModal";
import format from "date-fns/format";
import InputAdornment from "@mui/material/InputAdornment";

import { saveLimits, updateLimitsStatus, getLimits } from "../../actions";

import StakePad from "../StakePad";
import ConfirmRemoveLimit from "./Limits-dialogs/ConfirmRemoveLimit";
import ConfirmAbortLimit from "./Limits-dialogs/ConfirmAbortLimit";
import { differenceInHours } from "date-fns";

const styles = (theme) => ({
  root: {
    "& .MuiContainer-root": {
      padding: 2,
      width: "100%",
    },
    "& .MuiFormControl-root": {
      margin: "1em 0",
      width: "100%",
    },
    "& .MuiButton-root": {
      margin: "1em 1em 0 0",
      // background:
      //   "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
    },
    "& .MuiTypography-body1": {
      fontSize: "1em",
    },
    "& .MuiInputBase-input": {
      fontSize: "1em",
    },
    "& .MuiFormHelperText-root": {
      fontSize: "1em",
    },
    "& .MuiFormLabel-root": {
      fontSize: "1em",
    },
  },
  radioButton: {
    "& svg": {
      width: "1em",
      height: "1em",
    },
  },
});

class LimitsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: this.props.type,
      period: this.props.initialValues.period,
      value: this.props.initialValues.value,
      stakePadOpen: false,
      weeklyAmount: this.props.initialValues.weeklyAmount,
      monthlyAmount: this.props.initialValues.monthlyAmount,
      dailyAmount: this.props.initialValues.dailyAmount,
      autoRenew: this.props.autoRenew,
      isLimitConfirmModalOpen: false,
    };
  }

  setStackValue = () => {
    const { layout } = this.props;
    if (layout !== "mouse") {
      this.openStakePad();
    }
  };

  changeStackAmount = (e) => {
    const { layout } = this.props;
    if (layout === "mouse") {
      const re = /^[+-]?\d*(?:[.,]\d*)?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({ amount: e.target.value });
      }
    }
  };

  renderNumericField = ({
    input,
    label,
    min,
    max,
    meta: { touched, invalid, error },
    ...custom
  }) => {
    return (
      <TextField
        label={label}
        placeholder={label}
        InputProps={{
          min: min,
          max: max,
        }}
        error={touched && invalid}
        helperText={!!touched && !!invalid && error}
        variant="standard"
        type="number"
        {...input}
        {...custom}
      />
    );
  };

  renderSelectField = ({
    input,
    label,
    meta: { touched, error },
    children,
    ...custom
  }) => (
    <FormControl error={touched && error}>
      <InputLabel htmlFor="color-native-simple">{label}</InputLabel>
      <Select
        native
        {...input}
        {...custom}
        inputProps={{
          name: input.name,
          id: "color-native-simple",
        }}
      >
        {children}
      </Select>
      {this.renderFromHelper({ touched, error })}
    </FormControl>
  );

  renderFromHelper = ({ touched, error }) => {
    if (!(touched && error)) {
      return;
    } else {
      return <FormHelperText>{touched && error}</FormHelperText>;
    }
  };

  handlePeriod = (event) => {
    const { limits } = this.props;
    let stakeLimit = limits?.settings["stake"] ? limits.settings["stake"] : {};
    let period =
      event.target.value === "1"
        ? "daily"
        : event.target.value === "7"
        ? "weekly"
        : event.target.value === "30"
        ? "monthly"
        : "";
    this.setState({
      period: event.target.value,
      value:
        stakeLimit[period] &&
        stakeLimit[period].active &&
        stakeLimit[period].active.limitValue
          ? stakeLimit[period].active.limitValue
          : "",
    });
  };

  handleValue = (event) => {
    this.setState({
      value: event.target.value,
    });
  };

  handleWeeklyAmount = (event) => {
    this.setState({
      weeklyAmount: event.target.value,
    });
  };

  handleDailyAmount = (event) => {
    this.setState({
      dailyAmount: event.target.value,
    });
  };

  handleMonthlyAmount = (event) => {
    this.setState({
      monthlyAmount: event.target.value,
    });
  };

  handleRenew = (event) => {
    this.setState({
      autoRenew: event.target.checked,
    });
  };

  getUsedAmountPercentage = (total, used) => {
    const totalAmouunt = parseInt(total);
    const usedAmount = parseInt(used);
    const amountToShow = (usedAmount * 100) / totalAmouunt;
    return amountToShow && amountToShow > 0 ? amountToShow?.toFixed(1) : 0;
  };

  getMinAmount(period) {
    const { extraData, type } = this.props;
    let min = 0;

    if (period === "daily") {
      switch (type) {
        case "loss":
          min = extraData?.find(
            (item) => item?.dailyLoss || item?.dailyLoss === false
          )?.minAmount;
          break;
        case "deposit":
          min = extraData?.find(
            (item) => item?.dailyDeposit || item?.dailyDeposit === false
          )?.minAmount;
          break;
        case "stake":
          min = extraData?.find(
            (item) => item?.dailyWager || item?.dailyWager === false
          )?.minAmount;
          break;
        default:
          break;
      }
    } else if (period === "monthly") {
      switch (type) {
        case "loss":
          min = extraData?.find(
            (item) => item?.monthlyLoss || item?.monthlyLoss === false
          )?.minAmount;
          break;
        case "deposit":
          min = extraData?.find(
            (item) => item?.monthlyDeposit || item?.monthlyDeposit === false
          )?.minAmount;
          break;
        case "stake":
          min = extraData?.find(
            (item) => item?.monthlyWager || item?.monthlyWager === false
          )?.minAmount;
          break;
        default:
          break;
      }
    } else if (period === "weekly") {
      switch (type) {
        case "loss":
          min = extraData?.find(
            (item) => item?.weeklyLoss || item?.weeklyLoss === false
          )?.minAmount;
          break;
        case "deposit":
          min = extraData?.find(
            (item) => item?.weekDeposit || item?.weekDeposit === false
          )?.minAmount;
          break;
        case "stake":
          min = extraData?.find(
            (item) => item?.weeklyWager || item?.weeklyWager === false
          )?.minAmount;
          break;
        default:
          break;
      }
    }
    return min;
  }

  getMaxAmount(period) {
    const { extraData, type } = this.props;
    let max = 0;

    if (period === "daily") {
      switch (type) {
        case "stake":
          max = extraData?.find(
            (item) => item?.dailyWager || item?.dailyWager === false
          )?.maxAmount;
          break;
        case "loss":
          max = extraData?.find(
            (item) => item?.dailyLoss || item?.dailyLoss === false
          )?.maxAmount;
          break;
        case "deposit":
          max = extraData?.find(
            (item) => item?.dailyDeposit || item?.dailyDeposit === false
          )?.maxAmount;
          break;
        default:
          break;
      }
    } else if (period === "monthly") {
      switch (type) {
        case "stake":
          max = extraData?.find(
            (item) => item?.monthlyWager || item?.monthlyWager === false
          )?.maxAmount;
          break;
        case "loss":
          max = extraData?.find(
            (item) => item?.monthlyLoss || item?.monthlyLoss === false
          )?.maxAmount;
          break;
        case "deposit":
          max = extraData?.find(
            (item) => item?.monthlyDeposit || item?.monthlyDeposit === false
          )?.maxAmount;
          break;
        default:
          break;
      }
    } else if (period === "weekly") {
      switch (type) {
        case "stake":
          max = extraData?.find(
            (item) => item?.weeklyWager || item?.weeklyWager === false
          )?.maxAmount;
          break;
        case "loss":
          max = extraData?.find(
            (item) => item?.weeklyLoss || item?.weeklyLoss === false
          )?.maxAmount;
          break;
        case "deposit":
          max = extraData?.find(
            (item) => item?.weeklyDeposit || item?.weeklyDeposit === false
          )?.maxAmount;
          break;
        default:
          break;
      }
    }
    return max;
  }

  getHelperText = (period) => {
    let text = <Translate value={`labels.renewaltime_1`} />;
    let data;
    const { extraData, type, translate } = this.props;
    if (period === "daily") {
      switch (type) {
        case "stake":
          data = extraData?.find(
            (item) => item?.dailyWager || item?.dailyWager === false
          );
          if (data?.autoRenew) {
            text = `${translate.labels.renewaltime_2} ${format(
              data.endTime,
              "yyyy-MM-dd HH:mm"
            )}`;
          }
          break;
        case "loss":
          data = extraData?.find(
            (item) => item?.dailyLoss || item?.dailyLoss === false
          );
          if (data?.autoRenew) {
            text = `${translate.labels.renewaltime_2}  ${format(
              data.endTime,
              "yyyy-MM-dd HH:mm"
            )}`;
          }
          break;
        case "deposit":
          data = extraData?.find(
            (item) => item?.dailyDeposit || item?.dailyDeposit === false
          );
          if (data?.autoRenew) {
            text = `${translate.labels.renewaltime_2} ${format(
              data.endTime,
              "yyyy-MM-dd HH:mm"
            )}`;
          }
          break;
        default:
          break;
      }
    } else if (period === "monthly") {
      switch (type) {
        case "stake":
          data = extraData?.find(
            (item) => item?.monthlyWager || item?.monthlyWager === false
          );
          if (data?.autoRenew) {
            text = `${translate.labels.renewaltime_2} ${format(
              data.endTime,
              "yyyy-MM-dd HH:mm"
            )}`;
          }
          break;
        case "loss":
          data = extraData?.find(
            (item) => item?.monthlyLoss || item?.monthlyLoss === false
          );
          if (data?.autoRenew) {
            text = `${translate.labels.renewaltime_2} ${format(
              data.endTime,
              "yyyy-MM-dd HH:mm"
            )}`;
          }
          break;
        case "deposit":
          data = extraData?.find(
            (item) => item?.monthlyDeposit || item?.monthlyDeposit === false
          );
          if (data?.autoRenew) {
            text = `${translate.labels.renewaltime_2} ${format(
              data.endTime,
              "yyyy-MM-dd HH:mm"
            )}`;
          }
          break;
        default:
          break;
      }
    } else if (period === "weekly") {
      switch (type) {
        case "stake":
          data = extraData?.find(
            (item) => item?.weeklyWager || item?.weeklyWager === false
          );
          if (data?.autoRenew) {
            text = `${translate.labels.renewaltime_2} ${format(
              data.endTime,
              "yyyy-MM-dd HH:mm"
            )}`;
          }
          break;
        case "loss":
          data = extraData?.find(
            (item) => item?.weeklyLoss || item?.weeklyLoss === false
          );
          if (data?.autoRenew) {
            text = `${translate.labels.renewaltime_2} ${format(
              data?.endTime,
              "yyyy-MM-dd HH:mm"
            )}`;
          }
          break;
        case "deposit":
          data = extraData?.find(
            (item) => item?.weeklyDeposit || item?.weeklyDeposit === false
          );
          if (data?.autoRenew) {
            text = `${translate.labels.renewaltime_2} ${format(
              data.endTime,
              "yyyy-MM-dd HH:mm"
            )}`;
          }
          break;
        default:
          break;
      }
    }
    return text;
  };

  getTimeDifference = (effectDate, amount) => {
    const { translate } = this.props;
    let message = translate.labels.confirm_limit_period;
    let endMessage = translate.labels.confirm_limit_period_ended;
    const currentDate = new Date();
    const timeDiff = differenceInHours(new Date(effectDate), currentDate);

    message = message?.replace("{{!time}}", timeDiff);
    message = message?.replace("{{!amount}}", amount);
    endMessage = endMessage?.replace("{{!amount}}", amount);

    if (timeDiff > 0) {
      return message;
    }
    return endMessage;
  };

  getRemoveableStatus = (startDate) => {
    const currentDate = new Date();
    const timeDiff = differenceInHours(new Date(startDate), currentDate);

    return timeDiff < 48;
  };

  getTimeEquality = (effectDate) => {
    const currentDate = new Date();
    const effectNewDate = new Date(effectDate);
    return currentDate < effectNewDate;
  };

  confirmLimits = (type, period) => {
    let payload = {
      operation: "confirmLimit",
      limitType: type,
      isConfirmed: true,
      limitPeriod: period,
    };
    const callback = () => this.props.getLimits(this.constructor.name);
    this.props.updateLimitsStatus(payload, callback, this.constructor.name);
  };

  removeLimits = (type, period) => {
    let payload = {
      operation: "removeLimit",
      limitType: type,
      limitPeriod: period,
      isConfirmed: true,
    };
    const callback = () => this.props.getLimits(this.constructor.name);
    this.props.updateLimitsStatus(payload, callback, this.constructor.name);
  };

  abortNewLimit = (type, period) => {
    let payload = {
      operation: "confirmLimit",
      limitType: type,
      limitPeriod: period,
      isConfirmed: false,
    };
    const callback = () => this.props.getLimits(this.constructor.name);
    this.props.updateLimitsStatus(payload, callback, this.constructor.name);
  };

  getNewLimitsText = (type, period) => {
    const { extraData, translate } = this.props;
    //Wager limits
    const dailyWager = extraData?.find((item) => item.dailyWager);
    const weeklyWager = extraData?.find((item) => item.weeklyWager);
    const monthlyWager = extraData?.find((item) => item.monthlyWager);
    //Loss limits
    const dailyLoss = extraData?.find((item) => item.dailyLoss);
    const weeklyLoss = extraData?.find((item) => item.weeklyLoss);
    const monthlyLoss = extraData?.find((item) => item.monthlyLoss);
    //Deposit limits
    const dailyDeposit = extraData?.find((item) => item.dailyDeposit);
    const weeklyDeposit = extraData?.find((item) => item.weeklyDeposit);
    const monthlyDeposit = extraData?.find((item) => item.monthlyDeposit);

    let textMessage = "";
    switch (type) {
      //Loss limits renew
      case "loss":
        if (period === "daily" && dailyLoss?.newAmount) {
          textMessage = this.getTimeDifference(
            dailyLoss?.amountEffectiveTime,
            dailyLoss?.newAmount
          );
        }
        if (period === "weekly" && weeklyLoss?.newAmount) {
          textMessage = this.getTimeDifference(
            weeklyLoss?.amountEffectiveTime,
            weeklyLoss?.newAmount
          );
        }
        if (period === "monthly" && monthlyLoss?.newAmount) {
          textMessage = this.getTimeDifference(
            monthlyLoss?.amountEffectiveTime,
            monthlyLoss?.newAmount
          );
        }
        return textMessage;
      //Wager limits renew
      case "stake":
        if (period === "daily" && dailyWager?.newAmount) {
          textMessage = this.getTimeDifference(
            dailyWager?.amountEffectiveTime,
            dailyWager?.newAmount
          );
        }
        if (period === "weekly" && weeklyWager?.newAmount) {
          textMessage = this.getTimeDifference(
            weeklyWager?.amountEffectiveTime,
            weeklyWager?.newAmount
          );
        }
        if (period === "monthly" && monthlyWager?.newAmount) {
          textMessage = this.getTimeDifference(
            monthlyWager?.amountEffectiveTime,
            monthlyWager?.newAmount
          );
        }
        return textMessage;
      case "deposit":
        if (period === "daily" && dailyDeposit?.newAmount) {
          textMessage = this.getTimeDifference(
            dailyDeposit?.amountEffectiveTime,
            dailyDeposit?.newAmount
          );
        }
        if (period === "weekly" && weeklyDeposit?.newAmount) {
          textMessage = this.getTimeDifference(
            weeklyDeposit?.amountEffectiveTime,
            weeklyDeposit?.newAmount
          );
        }
        if (period === "monthly" && monthlyDeposit?.newAmount) {
          textMessage = this.getTimeDifference(
            monthlyDeposit?.amountEffectiveTime,
            monthlyDeposit?.newAmount
          );
        }
        return textMessage;
      default:
        return "";
    }
  };

  openStakePad = (type) => {
    this.setState({ stakePadOpen: true }, () => {
      this.openDrawer();
    });
  };

  setAmount = (type, value) => {
    /**
     * Set correct amount from inputs
     */
    let amount = String(this.state.amount);

    switch (value) {
      case "decimal":
        if (amount.indexOf(".") === -1) {
          amount = amount + ".";
        }
        break;

      case "delete":
        amount = amount.slice(0, -1);
        amount = amount ? amount : 0;
        break;

      case "set":
        if (amount.toString().indexOf(".") === -1) {
          amount = amount + ".00";
        } else {
          amount = parseFloat(amount);
        }

        this.setState({ stakePadOpen: false });
        break;

      default:
        if ([10, 25, 50].includes(value)) {
          amount = value;
        } else {
          if (amount === 0) {
            amount = value;
          } else {
            amount = amount + value;
          }
        }
        break;
    }

    this.setState({ amount });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLimitConfirmModalOpen: true });
  };

  handleLimitConfirm = () => {
    /**
     * Save limits with type, period and value
     */
    this.setState({ isLimitConfirmModalOpen: false });
    const { type } = this.props;
    let data = {};
    if (this.state.dailyAmount) {
      data = {
        limitType: type,
        limitValue: this.state.dailyAmount,
        limitPeriod: 1,
        autoRenew: true,
      };
      this.props.saveLimits(data, this.constructor.name);
    }
    if (this.state.monthlyAmount) {
      data = {
        limitType: type,
        limitValue: this.state.monthlyAmount,
        limitPeriod: 30,
        autoRenew: true,
      };
      this.props.saveLimits(data, this.constructor.name);
    }
    if (this.state.weeklyAmount) {
      (data = {
        limitType: type,
        limitValue: this.state.weeklyAmount,
        limitPeriod: 7,
        autoRenew: true,
      }),
        this.props.saveLimits(data, this.constructor.name);
    }
  };

  handleLimitsModal = (always) => {
    if (always) {
      this.setState({ isLimitConfirmModalOpen: true });
    } else {
      this.setState({
        isLimitConfirmModalOpen: !this.state.isLimitConfirmModalOpen,
      });
    }
  };

  render() {
    log(this.constructor.name, "render");
    const { limits, classes, translate, type, extraData } = this.props;
    //Loss limits
    const dailyLoss = extraData?.find((item) => item.dailyLoss);
    const weeklyLoss = extraData?.find((item) => item.weeklyLoss);
    const monthlyLoss = extraData?.find((item) => item.monthlyLoss);
    //Wager limits
    const dailyWager = extraData?.find((item) => item.dailyWager);
    const weeklyWager = extraData?.find((item) => item.weeklyWager);
    const monthlyWager = extraData?.find((item) => item.monthlyWager);
    //Deposit limits
    const dailyDeposit = extraData?.find((item) => item.dailyDeposit);
    const weeklyDeposit = extraData?.find((item) => item.weeklyDeposit);
    const monthlyDeposit = extraData?.find((item) => item.monthlyDeposit);

    if (!limits) return <div>Loading...</div>;
    let currentLimit = limits.settings[type];
    return (
      <div>
        {/* DEPOSIT AND LOSS > Same forms */}

        <form
          onSubmit={this.onSubmit}
          className={`${type === "stake" ? "hidden" : null}`}
        >
          <Grid container spacing={2} className={classes.root}>
            <Grid item xs={2} md={2}></Grid>
            <Grid item xs={8} md={8}>
              <b>
                <h3>
                  <Translate value={`labels.limits_${type}_text_1`} />
                </h3>
              </b>
            </Grid>
            <Grid item xs={2} md={2}></Grid>
            <Grid item xs={2} md={2}></Grid>
            <Grid item xs={8} md={8}>
              <Translate value={`labels.limits_${type}_text_2`} />
            </Grid>
            <Grid item xs={2} md={2}></Grid>
            <Grid item xs={2} md={2}></Grid>
            <Grid item xs={8} md={8}>
              <Translate value={`labels.limits_${type}_text_3`} />
            </Grid>
            <Grid item xs={2} md={2}></Grid>
            {/* <Grid item xs={2} md={2}>
            </Grid>
            <Grid item xs={8} md={8}>
              <Translate value={`labels.limits_${type}_text_4`} />
            </Grid>
            <Grid item xs={2} md={2}>
            </Grid> */}
            <Grid item xs={2} md={2}></Grid>
            <Grid item xs={8} md={8}>
              <Translate value={`labels.limits_${type}_common_vl`} />
            </Grid>
            <Grid item xs={2} md={2}></Grid>
            <Grid item xs={2} md={2}></Grid>
            <Grid item xs={8} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
                  <Box position="relative" display="inline-flex">
                    <CircularProgress
                      variant="determinate"
                      value={
                        currentLimit["daily"] &&
                        currentLimit["daily"].active &&
                        currentLimit["daily"].active.limitValue &&
                        currentLimit["daily"].active.usedAmount
                          ? this.getUsedAmountPercentage(
                              currentLimit["daily"].active.limitValue,
                              currentLimit["daily"].active.usedAmount
                            )
                          : 0
                      }
                      size={70}
                      thickness={8}
                      style={{ margin: "1em 0" }}
                    />
                    <Box
                      top={0}
                      left={0}
                      bottom={0}
                      right={0}
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        variant="caption"
                        component="div"
                        color="textSecondary"
                      >
                        {currentLimit["daily"] &&
                        currentLimit["daily"].active &&
                        currentLimit["daily"].active.limitValue &&
                        currentLimit["daily"].active.usedAmount
                          ? this.getUsedAmountPercentage(
                              currentLimit["daily"].active.limitValue,
                              currentLimit["daily"].active.usedAmount
                            )
                          : 0}{" "}
                        %
                      </Typography>
                    </Box>
                  </Box>
                  <TextField
                    name="dailyAmount"
                    label={translate.labels.limits_time_day}
                    placeholder={translate.labels.limits_time_day}
                    helperText={this.getHelperText("daily")}
                    id="outlined-basic"
                    variant="standard"
                    value={this.state.dailyAmount}
                    onChange={this.handleDailyAmount}
                    type="number"
                    inputProps={{
                      min: this.getMinAmount("daily"),
                      max:
                        this.state.weeklyAmount && this.state.weeklyAmount > 10
                          ? this.state.weeklyAmount
                          : this.state.monthlyAmount > 10
                          ? this.state.monthlyAmount
                          : this.getMaxAmount("daily"),
                    }}
                    disabled={
                      (dailyLoss?.newAmount &&
                        dailyLoss?.newAmount > 0 &&
                        type === "loss") ||
                      (dailyDeposit?.newAmount &&
                        dailyDeposit?.newAmount > 0 &&
                        type === "deposit")
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {
                            (dailyLoss?.newAmount &&
                              dailyLoss?.newAmount > 0 &&
                              type === "loss") ||
                            (dailyDeposit?.newAmount &&
                              dailyDeposit?.newAmount > 0 &&
                              type === "deposit") ? (
                              <ConfirmAbortLimit
                                onConfirm={() => this.abortNewLimit(type, 1)}
                              />
                            ) : null
                            // (
                            // currentLimit["daily"] &&
                            // currentLimit["daily"].active &&
                            // currentLimit["daily"].active.limitValue && (
                            //   <ConfirmRemoveLimit
                            //       onConfirm={() => this.removeLimits(type, 1)}
                            //       isDisabled={this.getRemoveableStatus(type==="loss"?dailyLoss?.startTime:dailyDeposit?.startTime)}
                            //   />
                            // )
                            // )
                          }
                        </InputAdornment>
                      ),
                    }}
                  />
                  {(type === "loss" &&
                    dailyLoss?.newAmount &&
                    dailyLoss?.newAmount > 0) ||
                  (type === "deposit" &&
                    dailyDeposit?.newAmount &&
                    dailyDeposit?.newAmount > 0) ? (
                    <Button
                      onClick={() => this.confirmLimits(type, 1)}
                      fullWidth
                      size="large"
                      variant="contained"
                      disabled={this.getTimeEquality(
                        type === "loss"
                          ? dailyLoss?.amountEffectiveTime
                          : dailyDeposit?.amountEffectiveTime
                      )}
                      color="primary"
                    >
                      <Typography
                        textTransform="capitalize"
                        variant="string"
                        fontWeight={600}
                      >
                        {this.getNewLimitsText(type, "daily")}
                      </Typography>
                    </Button>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
                  <Box position="relative" display="inline-flex">
                    <CircularProgress
                      variant="determinate"
                      value={
                        currentLimit["weekly"] &&
                        currentLimit["weekly"].active &&
                        currentLimit["weekly"].active.limitValue &&
                        currentLimit["weekly"].active.usedAmount
                          ? this.getUsedAmountPercentage(
                              currentLimit["weekly"].active.limitValue,
                              currentLimit["weekly"].active.usedAmount
                            )
                          : 0
                      }
                      size={70}
                      thickness={8}
                      style={{ margin: "1em 0" }}
                    />
                    <Box
                      top={0}
                      left={0}
                      bottom={0}
                      right={0}
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        variant="caption"
                        component="div"
                        color="textSecondary"
                      >
                        {currentLimit["weekly"] &&
                        currentLimit["weekly"].active &&
                        currentLimit["weekly"].active.limitValue &&
                        currentLimit["weekly"].active.usedAmount
                          ? this.getUsedAmountPercentage(
                              currentLimit["weekly"].active.limitValue,
                              currentLimit["weekly"].active.usedAmount
                            )
                          : 0}{" "}
                        %
                      </Typography>
                    </Box>
                  </Box>
                  <TextField
                    name="weeklyAmount"
                    label={translate.labels.limits_time_week}
                    placeholder={translate.labels.limits_time_week}
                    helperText={this.getHelperText("weekly")}
                    id="outlined-basic"
                    variant="standard"
                    value={this.state.weeklyAmount}
                    onChange={this.handleWeeklyAmount}
                    type="number"
                    disabled={
                      (weeklyLoss?.newAmount &&
                        weeklyLoss?.newAmount > 0 &&
                        type === "loss") ||
                      (weeklyDeposit?.newAmount &&
                        weeklyDeposit?.newAmount > 0 &&
                        type === "loss")
                    }
                    inputProps={{
                      min: this.state.dailyAmount
                        ? this.state.dailyAmount
                        : this.getMinAmount("weekly"),
                      max: this.state.monthlyAmount
                        ? this.state.monthlyAmount
                        : this.getMaxAmount("weekly"),
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {
                            (weeklyLoss?.newAmount &&
                              weeklyLoss?.newAmount > 0 &&
                              type === "loss") ||
                            (weeklyDeposit?.newAmount &&
                              weeklyDeposit?.newAmount > 0 &&
                              type === "deposit") ? (
                              <ConfirmAbortLimit
                                onConfirm={() => this.abortNewLimit(type, 7)}
                              />
                            ) : null
                            //   (
                            //   currentLimit["weekly"] &&
                            //   currentLimit["weekly"].active &&
                            //   currentLimit["weekly"].active.limitValue && (
                            //       <ConfirmRemoveLimit
                            //       isDisabled={this.getRemoveableStatus(type==="loss"?weeklyLoss?.startTime:weeklyDeposit?.startTime)}
                            //       onConfirm={() => this.removeLimits(type, 7)}
                            //     />
                            //   )
                            // )
                          }
                        </InputAdornment>
                      ),
                    }}
                  />
                  {(type === "loss" &&
                    weeklyLoss?.newAmount &&
                    weeklyLoss?.newAmount > 0) ||
                  (type === "deposit" &&
                    weeklyDeposit?.newAmount &&
                    weeklyDeposit?.newAmount > 0) ? (
                    <Button
                      onClick={() => this.confirmLimits(type, 7)}
                      fullWidth
                      size="large"
                      variant="contained"
                      disabled={this.getTimeEquality(
                        type === "loss"
                          ? weeklyLoss?.amountEffectiveTime
                          : weeklyDeposit?.amountEffectiveTime
                      )}
                      color="primary"
                    >
                      <Typography
                        textTransform="capitalize"
                        variant="string"
                        fontWeight={600}
                      >
                        {this.getNewLimitsText(type, "weekly")}
                      </Typography>
                    </Button>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
                  <Box position="relative" display="inline-flex">
                    <CircularProgress
                      variant="determinate"
                      value={
                        currentLimit["monthly"] &&
                        currentLimit["monthly"].active &&
                        currentLimit["monthly"].active.limitValue &&
                        currentLimit["monthly"].active.usedAmount
                          ? this.getUsedAmountPercentage(
                              currentLimit["monthly"].active.limitValue,
                              currentLimit["monthly"].active.usedAmount
                            )
                          : 0
                      }
                      size={70}
                      thickness={8}
                      style={{ margin: "1em 0" }}
                    />
                    <Box
                      top={0}
                      left={0}
                      bottom={0}
                      right={0}
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        variant="caption"
                        component="div"
                        color="textSecondary"
                      >
                        {currentLimit["monthly"] &&
                        currentLimit["monthly"].active &&
                        currentLimit["monthly"].active.limitValue &&
                        currentLimit["monthly"].active.usedAmount
                          ? this.getUsedAmountPercentage(
                              currentLimit["monthly"].active.limitValue,
                              currentLimit["monthly"].active.usedAmount
                            )
                          : 0}{" "}
                        %
                      </Typography>
                    </Box>
                  </Box>
                  <TextField
                    name="monthlyAmount"
                    label={translate.labels.limits_time_month}
                    placeholder={translate.labels.limits_time_month}
                    helperText={this.getHelperText("monthly")}
                    id="outlined-basic"
                    variant="standard"
                    value={this.state.monthlyAmount}
                    onChange={this.handleMonthlyAmount}
                    type="number"
                    inputProps={{
                      min: this.state.weeklyAmount
                        ? this.state.weeklyAmount
                        : this.getMinAmount("monthly"),
                      max: this.getMaxAmount("monthly"),
                    }}
                    disabled={
                      (monthlyLoss?.newAmount &&
                        monthlyLoss?.newAmount > 0 &&
                        type === "loss") ||
                      (monthlyDeposit?.newAmount &&
                        monthlyDeposit?.newAmount > 0 &&
                        type === "deposit")
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {
                            (monthlyLoss?.newAmount &&
                              monthlyLoss?.newAmount > 0 &&
                              type === "loss") ||
                            (monthlyDeposit?.newAmount &&
                              monthlyDeposit?.newAmount > 0 &&
                              type === "deposit") ? (
                              <ConfirmAbortLimit
                                onConfirm={() => this.abortNewLimit(type, 30)}
                              />
                            ) : null
                            //   (
                            //   currentLimit["monthly"] &&
                            //   currentLimit["monthly"].active &&
                            //   currentLimit["monthly"].active.limitValue && (
                            //       <ConfirmRemoveLimit
                            //       isDisabled={this.getRemoveableStatus(type==="loss"?monthlyLoss?.startTime:monthlyDeposit?.startTime)}
                            //       onConfirm={() => this.removeLimits(type, 30)}
                            //     />
                            //   )
                            // )
                          }
                        </InputAdornment>
                      ),
                    }}
                  />
                  {(type === "loss" &&
                    monthlyLoss?.newAmount &&
                    monthlyLoss?.newAmount > 0) ||
                  (type === "deposit" &&
                    monthlyDeposit?.newAmount &&
                    monthlyDeposit?.newAmount > 0) ? (
                    <Button
                      onClick={() => this.confirmLimits(type, 30)}
                      fullWidth
                      size="large"
                      variant="contained"
                      disabled={this.getTimeEquality(
                        type === "loss"
                          ? monthlyLoss?.amountEffectiveTime
                          : monthlyDeposit?.amountEffectiveTime
                      )}
                      color="primary"
                    >
                      <Typography
                        textTransform="capitalize"
                        variant="string"
                        fontWeight={600}
                      >
                        {this.getNewLimitsText(type, "monthly")}
                      </Typography>
                    </Button>
                  ) : null}
                </Grid>
                {/* <Grid item xs={12} md={12} >
                  <FormGroup onChange={this.handleRenew}>
                    <FormControlLabel control={<Checkbox checked={this.state.autoRenew} color="primary" />} label={translate.labels.limits_wager_text_10} />
                  </FormGroup>
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={2} md={2}></Grid>
            <Grid item xs={2} md={2}></Grid>
            <Grid
              item
              xs={8}
              md={8}
              style={{ textAlign: "center", padding: "3em" }}
            >
              <Button
                type="submit"
                variant="contained"
                size="small"
                color="primary"
                style={{ color: "var(--btn-text)" }}
              >
                <Translate value="labels.btn_save" />
              </Button>
            </Grid>
            <Grid item xs={2} md={2}></Grid>
          </Grid>
        </form>

        {/* WAGER */}

        <form
          onSubmit={this.onSubmit}
          className={`${type === "stake" ? null : "hidden"}`}
        >
          <Grid container spacing={2} className={classes.root}>
            <Grid item xs={1} md={2}></Grid>
            <Grid item xs={10} md={8}>
              <b>
                <h3>
                  <Translate value="labels.limits_wager_text_1" />
                </h3>
              </b>
            </Grid>
            <Grid item xs={1} md={2}></Grid>
            <Grid item xs={1} md={2}></Grid>
            <Grid item xs={10} md={8}>
              <Translate value="labels.limits_wager_text_2" />
            </Grid>
            <Grid item xs={1} md={2}></Grid>
            <Grid item xs={1} md={2}></Grid>
            <Grid item xs={10} md={8}>
              {/* <b style={{ color: 'var(--color-contras)' }}><Translate value="labels.limits_wager_text_4" /></b><br></br> */}
              <Translate value="labels.limits_wager_text_3" />
            </Grid>
            <Grid item xs={1} md={2}></Grid>
            <Grid item xs={1} md={2}></Grid>
            <Grid item xs={10} md={8}>
              {/* <b style={{ color: 'var(--color-contras)' }}><Translate value="labels.limits_wager_text_4" /></b><br></br> */}
              <Translate value={`labels.limits_${type}_common_vl`} />
            </Grid>
            <Grid item xs={1} md={2}></Grid>
            <Grid item xs={1} md={2}></Grid>
            <Grid item xs={10} md={8}>
              <Grid
                container
                spacing={2}
                style={{
                  padding: "1em",
                  borderRadius: "0.5em",
                  margin: "1em 0 0",
                }}
              >
                <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
                  <Box position="relative" display="inline-flex">
                    <CircularProgress
                      variant="determinate"
                      value={
                        currentLimit["daily"] &&
                        currentLimit["daily"].active &&
                        currentLimit["daily"].active.limitValue &&
                        currentLimit["daily"].active.usedAmount
                          ? this.getUsedAmountPercentage(
                              currentLimit["daily"].active.limitValue,
                              currentLimit["daily"].active.usedAmount
                            )
                          : 0
                      }
                      size={70}
                      thickness={8}
                      style={{ margin: "1em 0" }}
                    />
                    <Box
                      top={0}
                      left={0}
                      bottom={0}
                      right={0}
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        variant="caption"
                        component="div"
                        color="textSecondary"
                      >
                        {currentLimit["daily"] &&
                        currentLimit["daily"].active &&
                        currentLimit["daily"].active.limitValue &&
                        currentLimit["daily"].active.usedAmount
                          ? this.getUsedAmountPercentage(
                              currentLimit["daily"].active.limitValue,
                              currentLimit["daily"].active.usedAmount
                            )
                          : 0}{" "}
                        %
                      </Typography>
                    </Box>
                  </Box>
                  <TextField
                    name="dailyAmount"
                    label={translate.labels.limits_time_day}
                    placeholder={translate.labels.limits_time_day}
                    helperText={this.getHelperText("daily")}
                    id="outlined-basic"
                    variant="standard"
                    value={this.state.dailyAmount}
                    onChange={this.handleDailyAmount}
                    type="number"
                    inputProps={{
                      min: this.getMinAmount("daily"),
                      max:
                        this.state.weeklyAmount && this.state.weeklyAmount > 10
                          ? this.state.weeklyAmount
                          : this.state.monthlyAmount > 10
                          ? this.state.monthlyAmount
                          : this.getMaxAmount("daily"),
                    }}
                    disabled={
                      dailyWager?.newAmount && dailyWager?.newAmount > 0
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {
                            dailyWager?.newAmount &&
                            dailyWager?.newAmount > 0 ? (
                              <ConfirmAbortLimit
                                onConfirm={() => this.abortNewLimit(type, 1)}
                              />
                            ) : null
                            //   (
                            //   currentLimit["daily"] &&
                            //   currentLimit["daily"].active &&
                            //   currentLimit["daily"].active.limitValue && (
                            //       <ConfirmRemoveLimit
                            //       isDisabled={this.getRemoveableStatus(dailyWager?.startTime)}
                            //       onConfirm={() => this.removeLimits(type, 1)}
                            //     />
                            //   )
                            // )
                          }
                        </InputAdornment>
                      ),
                    }}
                  />
                  {dailyWager?.newAmount && dailyWager?.newAmount > 0 ? (
                    <Button
                      onClick={() => this.confirmLimits(type, 1)}
                      fullWidth
                      size="large"
                      variant="contained"
                      disabled={this.getTimeEquality(
                        dailyWager?.amountEffectiveTime
                      )}
                      color="primary"
                    >
                      <Typography
                        textTransform="capitalize"
                        variant="string"
                        fontWeight={600}
                      >
                        {this.getNewLimitsText(type, "daily")}
                      </Typography>
                    </Button>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
                  <Box position="relative" display="inline-flex">
                    <CircularProgress
                      variant="determinate"
                      value={
                        currentLimit["weekly"] &&
                        currentLimit["weekly"].active &&
                        currentLimit["weekly"].active.limitValue &&
                        currentLimit["weekly"].active.usedAmount
                          ? this.getUsedAmountPercentage(
                              currentLimit["weekly"].active.limitValue,
                              currentLimit["weekly"].active.usedAmount
                            )
                          : 0
                      }
                      size={70}
                      thickness={8}
                      style={{ margin: "1em 0" }}
                    />
                    <Box
                      top={0}
                      left={0}
                      bottom={0}
                      right={0}
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        variant="caption"
                        component="div"
                        color="textSecondary"
                      >
                        {currentLimit["weekly"] &&
                        currentLimit["weekly"].active &&
                        currentLimit["weekly"].active.limitValue &&
                        currentLimit["weekly"].active.usedAmount
                          ? this.getUsedAmountPercentage(
                              currentLimit["weekly"].active.limitValue,
                              currentLimit["weekly"].active.usedAmount
                            )
                          : 0}{" "}
                        %
                      </Typography>
                    </Box>
                  </Box>
                  <TextField
                    name="weeklyAmount"
                    label={translate.labels.limits_time_week}
                    placeholder={translate.labels.limits_time_week}
                    helperText={this.getHelperText("weekly")}
                    id="outlined-basic"
                    variant="standard"
                    value={this.state.weeklyAmount}
                    onChange={this.handleWeeklyAmount}
                    type="number"
                    inputProps={{
                      min: this.state.dailyAmount
                        ? this.state.dailyAmount
                        : this.getMinAmount("weekly"),
                      max: this.state.monthlyAmount
                        ? this.state.monthlyAmount
                        : this.getMaxAmount("weekly"),
                    }}
                    disabled={
                      weeklyWager?.newAmount && weeklyWager?.newAmount > 0
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {
                            weeklyWager?.newAmount &&
                            weeklyWager?.newAmount > 0 ? (
                              <ConfirmAbortLimit
                                onConfirm={() => this.abortNewLimit(type, 7)}
                              />
                            ) : null
                            //   (
                            //   currentLimit["weekly"] &&
                            //   currentLimit["weekly"].active &&
                            //   currentLimit["weekly"].active.limitValue && (
                            //       <ConfirmRemoveLimit
                            //       isDisabled={this.getRemoveableStatus(weeklyWager?.startTime)}
                            //       onConfirm={() => this.removeLimits(type, 7)}
                            //     />
                            //   )
                            // )
                          }
                        </InputAdornment>
                      ),
                    }}
                  />
                  {weeklyWager?.newAmount && weeklyWager?.newAmount > 0 ? (
                    <Button
                      onClick={() => this.confirmLimits(type, 7)}
                      fullWidth
                      size="large"
                      variant="contained"
                      disabled={this.getTimeEquality(
                        weeklyWager?.amountEffectiveTime
                      )}
                      color="primary"
                    >
                      <Typography
                        textTransform="capitalize"
                        variant="string"
                        fontWeight={600}
                      >
                        {this.getNewLimitsText(type, "weekly")}
                      </Typography>
                    </Button>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
                  <Box position="relative" display="inline-flex">
                    <CircularProgress
                      variant="determinate"
                      value={
                        currentLimit["monthly"] &&
                        currentLimit["monthly"].active &&
                        currentLimit["monthly"].active.limitValue &&
                        currentLimit["monthly"].active.usedAmount
                          ? this.getUsedAmountPercentage(
                              currentLimit["monthly"].active.limitValue,
                              currentLimit["monthly"].active.usedAmount
                            )
                          : 0
                      }
                      size={70}
                      thickness={8}
                      style={{ margin: "1em 0" }}
                    />
                    <Box
                      top={0}
                      left={0}
                      bottom={0}
                      right={0}
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        variant="caption"
                        component="div"
                        color="textSecondary"
                      >
                        {currentLimit["monthly"] &&
                        currentLimit["monthly"].active &&
                        currentLimit["monthly"].active.limitValue &&
                        currentLimit["monthly"].active.usedAmount
                          ? this.getUsedAmountPercentage(
                              currentLimit["monthly"].active.limitValue,
                              currentLimit["monthly"].active.usedAmount
                            )
                          : 0}{" "}
                        %
                      </Typography>
                    </Box>
                  </Box>
                  <TextField
                    name="monthlyAmount"
                    label={translate.labels.limits_time_month}
                    placeholder={translate.labels.limits_time_month}
                    helperText={this.getHelperText("monthly")}
                    id="outlined-basic"
                    variant="standard"
                    value={this.state.monthlyAmount}
                    onChange={this.handleMonthlyAmount}
                    type="number"
                    inputProps={{
                      min: this.state.weeklyAmount
                        ? this.state.weeklyAmount
                        : this.getMinAmount("monthly"),
                      max: this.getMaxAmount("monthly"),
                    }}
                    disabled={
                      monthlyWager?.newAmount && monthlyWager?.newAmount > 0
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {
                            monthlyWager?.newAmount &&
                            monthlyWager?.newAmount > 0 ? (
                              <ConfirmAbortLimit
                                onConfirm={() => this.abortNewLimit(type, 30)}
                              />
                            ) : null
                            //   (
                            //   currentLimit["monthly"] &&
                            //   currentLimit["monthly"].active &&
                            //   currentLimit["monthly"].active.limitValue && (
                            //       <ConfirmRemoveLimit
                            //       isDisabled={this.getRemoveableStatus(monthlyWager?.startTime)}
                            //       onConfirm={() => this.removeLimits(type, 30)}
                            //     />
                            //   )
                            // )
                          }
                        </InputAdornment>
                      ),
                    }}
                  />
                  {monthlyWager?.newAmount && monthlyWager?.newAmount > 0 ? (
                    <Button
                      onClick={() => this.confirmLimits(type, 30)}
                      fullWidth
                      size="large"
                      variant="contained"
                      disabled={this.getTimeEquality(
                        monthlyWager?.amountEffectiveTime
                      )}
                      color="primary"
                    >
                      <Typography
                        textTransform="capitalize"
                        variant="string"
                        fontWeight={600}
                      >
                        {this.getNewLimitsText(type, "monthly")}
                      </Typography>
                    </Button>
                  ) : null}
                </Grid>
                {/* <Grid item xs={12} md={12} >
                  <FormGroup onChange={this.handleRenew}>
                    <FormControlLabel control={<Checkbox checked={this.state.autoRenew} color="primary" />} label={translate.labels.limits_wager_text_10} />
                  </FormGroup>
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={1} md={2}></Grid>
            <Grid item xs={1} md={2}></Grid>
            <Grid
              item
              xs={10}
              md={8}
              style={{ textAlign: "center", padding: "3em" }}
            >
              <Button
                type="submit"
                variant="contained"
                size="small"
                color="primary"
                style={{ color: "var(--btn-text)" }}
              >
                <Translate value="labels.btn_save" />
              </Button>
            </Grid>
            <Grid item xs={1} md={2}></Grid>
          </Grid>
        </form>
        <StakePad
          stakePadOpen={this.state.stakePadOpen ? true : false}
          parentCallBack={this.setAmount}
          forwardRef={(c) => {
            this.openDrawer = c;
          }}
        />
        <LimitsConfirmationPopUp
          weekly={this.state.weeklyAmount}
          monthly={this.state.monthlyAmount}
          daily={this.state.dailyAmount}
          isModalOpen={this.state.isLimitConfirmModalOpen}
          handleModal={() => this.handleLimitsModal()}
          onConfirm={() => this.handleLimitConfirm()}
          limitType={this.state.type}
        />
      </div>
    );
  }
}

const validate = (values, props) => {
  const errors = {};

  const requiredFields = [];

  requiredFields?.forEach((field) => {
    if (!values[field]) {
      errors[field] = props.translate.labels.field_required;
    }
  });

  // if (!values.address1) {
  //   errors.address1 = props.translate.labels.field_required;
  // }

  return errors;
};

const mapStateToProps = (state, ownProps) => ({
  auth: state.user.auth,
  type: ownProps.type,
  limits: state.user.limits ? state.user.limits : null,
  layout: state.settings.layout,
  translate: state.i18n.translations[state.i18n.locale],
  limitTypes:
    state.user.limits && state.user.limits.types ? state.user.limits.types : [],
  initialValues: ownProps.initialValues,
  autoRenew: state.user.limits && state.user.limits.extraData[0].autoRenew,
  extraData: state.user.limits.extraData,
});

export default connect(mapStateToProps, {
  saveLimits,
  updateLimitsStatus,
  getLimits,
})(
  reduxForm({
    form: "LimitsForm",
    validate,
  })(withStyles(styles)(LimitsForm))
);
