/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconCU = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g><path d="M1602.4,889.5L1602.4,889.5l-46.4,0V866c0-98.2-79.8-178.3-178.3-178.3H963.9v-98.8c0-1.5,1.2-2.7,2.7-2.7h430.1
	c65.4,0,118.4-53,118.4-118.4c0-65.4-53-118.4-118.4-118.4H926.2c-109.6,0-199.1,89.2-199.1,199.1v139.2H622.3
	C524.1,687.6,444,767.5,444,866v23.5h-46.4c-153.6,0-278.6,125-278.6,278.6V1372c0,153.6,125,278.6,278.6,278.6h1204.9
	c153.6,0,278.6-125,278.6-278.6v-203.9C1881.1,1014.2,1756.1,889.5,1602.4,889.5z M802.4,548.5c0-68.4,55.4-123.8,123.8-123.8h470.5
	c23.8,0,43.1,19.3,43.1,43.1s-19.3,43.1-43.1,43.1H966.6c-43.1,0-78,34.9-78,78V675h-86.1V548.5z M519.3,865.7
	c0-56.6,46.1-103,103-103h755.5c56.6,0,103,46.1,103,103v19.9H519.3V865.7z M397.6,964.8h1204.9c99.1,0,181.9,71.4,199.7,165.7
	H197.9C215.6,1036.1,298.5,964.8,397.6,964.8z M1602.4,1575.3H397.6c-99.1,0-181.9-71.4-199.7-165.7h1604.3
	C1784.4,1503.6,1701.5,1575.3,1602.4,1575.3z M1805.8,1334.4H194.2v-128.6h1611.5V1334.4z"/></g>
			</svg>
		</div>
	);
};

export default IconCU;
