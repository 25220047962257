/**
 * Reducer for email confirmation
 */

import { GET_COMBO_BOOST } from "../actions/types";

const INTIAL_STATE = {
  bonuses: [],
  systemBonuses: [],
  includedMarkets: [],
  minOdds: undefined,
  total: 0,
};

let stateCopy;
const fn = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case GET_COMBO_BOOST:
      stateCopy = { ...state };
      stateCopy["bonuses"] = action.payload?.data ? action.payload?.data : [];
      stateCopy["minOdds"] = action.payload?.minOdds ? action.payload?.minOdds : 1.2;
      stateCopy["includedMarkets"] = action.payload?.includedMarkets ? action.payload?.includedMarkets : [];
      stateCopy["total"] = action.payload?.total ? action.payload?.total : 0;
      stateCopy["systemBonuses"] = action.payload?.systemCombi?.data ? action.payload.systemCombi.data : [];

      return stateCopy;
    default:
      return state;
  }
};

export default fn;
