import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const CategoryDropsWinsIcon = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 200 200"
      {...props}
    >
      <path d="M85.1,183.7c0,0,11-9.5,44.6-19.4c26.1-7.7,44.3-7.7,44.3-7.7s-7-20.9-7.2-43.1c-0.4-39.1,13.7-65.7,13.7-65.7
	s-21.1,14.8-30.4,42.6c-6.2,18.7-5.5,40.3-5.5,40.3s-18.3-45.5-22.4-53.5c-10.7-21.1-40.1-60.7-40.1-60.7s-2,47.6,4,80.7
	c2.2,12.3,8.7,49,8.7,49s-1-8.7-24.6-29.4c-17.3-15.1-50.5-18.2-50.5-18.2s27.4,16.7,43.8,41.3c15.9,23.8,21.7,43.8,21.7,43.8
	L85.1,183.7z"/>
    </SvgIcon>
  );
};

export default CategoryDropsWinsIcon;
