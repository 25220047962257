import { Button, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { getWPContent } from "../../actions";
import fi from "date-fns/locale/fi";
import enAU from "date-fns/locale/en-AU";
import et from "date-fns/locale/et";
import ReactHtmlParser from "react-html-parser";
import withRouter from "../../helpers/withRouter";

const locales = {
  fi: fi,
  et: et,
  en: enAU,
};

const styles = (theme) => ({
  root: {
    "@media (hover:hover)": {
      backgroundColor: "var(--color-main)",
    },
    "& .MuiTypography-root": {
      fontSize: "1em !important",
    },
    "& p": {
      margin: "2em 0",
    },
    "& li": {
      margin: "1em 0",
    },
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  postDetail: {
    padding: "1em 3em 3em ",
  },
  postTitle: {
    color: "var(--color-contrast)",
  },
});

class WPCampaigns extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sk: 0,
      lm: 1,
    };
  }

  componentDidMount() {
    const { locale } = this.props;
    this.props.getWPContent(2, this.state.sk, locale, this.state.lm, this.constructor.name);
  }

  goToRegisterDeposit = () => {
    const { auth, locale } = this.props;
    auth.isSignedIn ? this.props.navigate(`/${locale}/payments`) : this.props.navigate(`/${locale}/register-switch`);
  };

  loadMoreData = () => {
    const { locale } = this.props;
    this.setState({ sk: this.state.sk + 10, lm: this.state.lm + 5 });
    this.props.getWPContent(2, this.state.sk + 10, locale, this.state.lm + 5, this.constructor.name);
  };

  render() {
    log(this.constructor.name, "render");
    const { classes, wpContent, locale } = this.props;

    return (
      <div className={classes.root}>
        {wpContent?.map((item) => {
          const itemDate = new Date(item?.date);
          return (
            <div key={item.id} className={classes.postDetail}>
              <Typography className={classes.postTitle} component={"span"}>
                <h2>{ReactHtmlParser(item.title)}</h2>
              </Typography>
              <Typography className={classes.postContent} component={"span"}>
                {ReactHtmlParser(item.content)}
              </Typography>
              <div>
                <Button onClick={this.goToRegisterDeposit} variant="contained" color="primary" style={{ margin: "0 0 1.9em 0" }}>
                  Register or Deposit now
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const selectedLocale = props?.match?.params?.lang;
  return {
    locale: selectedLocale ? selectedLocale : state.i18n.locale,
    wpContent: state.wpContent.campaigns,
    auth: state.user.auth,
  };
};

export default withRouter(connect(mapStateToProps, { getWPContent })(withStyles(styles)(WPCampaigns)));
