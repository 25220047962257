import { useState, useEffect } from "react";
import { Box, Grid2, Tab, Tabs, Typography, styled } from "@mui/material";
import RacingSelectionButton from "../../@components/RacingSelectionButton";
import { useDispatch, useSelector } from "react-redux";
import { getRacingGamesAsync } from "../../../../redux-store/sports-reducer";
import { Localize } from "react-redux-i18n";

const MuiTab = styled(Tab)(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: 6,
  marginRight: theme.spacing(1),
  "&.Mui-selected": {
    color: theme.palette.primary.contrastText,
  },
}));

const RacingRegularLayout = ({ gamesList, leagueId }) => {
  const [tabValue, setTabValue] = useState(null);

  const dispatch = useDispatch();
  // get league filter and leagues
  const lang = useSelector((state) => state.i18n.locale);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  /**
   * Check if game stateTime is one week later or not
   */

  const checkOneWeekLater = (timeStamp) => {
    let today = new Date();
    let time = new Date(timeStamp);
    let difference = Math.floor((Date.UTC(time.getFullYear(), time.getMonth(), time.getDate()) - Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())) / (1000 * 60 * 60 * 24));

    if (difference > 7) return true;
    // if (difference > 7 || content !== 'selectedGames') return true;

    return false;
  };

  const selectedGame = gamesList.find((game) => game.gameid === tabValue);

  useEffect(() => {
    if (!gamesList.length) {
      dispatch(getRacingGamesAsync({ lang, leagueId }));
    }
    if (!tabValue) {
      setTabValue(gamesList[0]?.gameid);
    }
  }, [leagueId, gamesList.length]);
  let odds = selectedGame?.odds ? [...selectedGame.odds] : [];
  odds = odds.sort((a, b) => a.ord - b.ord);
  return (
    <Box>
      <Tabs indicatorColor="none" value={tabValue} onChange={handleTabChange} aria-label="races-regular-layout-tabs">
        {gamesList?.map((game) => (
          <MuiTab key={game.gameid} value={game.gameid} label={game.game_title} sx={{ padding: "5px" }} />
        ))}
      </Tabs>
      <Box pt={3}>
        <Box mb={2}>
          <Typography color="text.secondary" variant="string">
            <Localize value={selectedGame?.nextBetStop} dateFormat={checkOneWeekLater(selectedGame?.nextBetStop) ? "date.game1" : "date.game2"} />
          </Typography>
        </Box>
        <Grid2 container spacing={2}>
          {odds.map((odd) => (
            <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3 }} key={odd.s}>
              <RacingSelectionButton odd={odd} game={selectedGame} />
            </Grid2>
          ))}
        </Grid2>
      </Box>
    </Box>
  );
};

export default RacingRegularLayout;
