import { useMemo } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSelector } from "react-redux";
import { CacheProvider } from "@emotion/react";
import { componentsOverrides } from "./overrides";
import palette from "./palette";
import createEmotionCache from "./createEmotionCache";
import { typography } from "./typography";
import locales from "../helpers/datefns-locales";
import { shadows } from "./shadows";

const ThemeProvider = ({ children }) => {
  const themeMode = useSelector((state) => state.settings.theme);
  const themeLayout = useSelector((state) => state.settings.layout);
  const lang = useSelector((state) => state.i18n.locale);

  const baseOption = useMemo(
    () => ({
      palette: palette(themeMode),
      typography: typography(themeMode),
      shadows: shadows(themeMode),
      direction: "ltr",
      layout: themeLayout,
    }),
    [themeMode]
  );

  let theme = createTheme(baseOption);

  theme.components = componentsOverrides(theme);
  theme = responsiveFontSizes(theme);

  const emotionCache = createEmotionCache();

  return (
    <CacheProvider value={emotionCache}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={locales[lang]}
        >
          {children}
        </LocalizationProvider>
      </MuiThemeProvider>
    </CacheProvider>
  );
};

export default ThemeProvider;
