/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconABOUT = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own-drawer" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g>
					<path d="M1673.2,554.1c-65.4-211.5-237.5-383.6-448.9-449c-223.8-69.3-462-30.8-643.7,103.1c-180.4,133-288.1,346.1-288.1,570
		c0,154.4,48.9,301.2,141.3,424.3l566.4,726.4l566.4-726.4C1704.8,1018,1743.7,781.7,1673.2,554.1z M1000.1,1159.2
		c-210.1,0-381.1-171-381.1-381.1c0-210.1,171-381.1,381.1-381.1c210.1,0,381.1,171,381.1,381.1
		C1381.1,988.2,1210.2,1159.2,1000.1,1159.2z"/>
					<path d="M1000.1,506.7c-150.1,0-272.2,121.4-272.2,271.5c0,150.1,122.1,272.2,272.2,272.2s272.2-122.1,272.2-272.2
		C1272.3,628.1,1150.1,506.7,1000.1,506.7z"/>
				</g>
			</svg>
		</div>
	);
};

export default IconABOUT;
