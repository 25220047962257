/**
 * i18n localization
 */

import { setLocale } from 'react-redux-i18n';
import { supportedLocales } from '../config/i18n';

/**
 * Get default locale from localStorage or browser language
 */

export function setLocaleWithFallback(desiredLocale) {
  let defaultLocale = localStorage.getItem('lang') ? localStorage.getItem('lang'): '';
  if (defaultLocale === '') {
    // get browser LOCALE
    let language = (navigator.languages && navigator.languages[0]) || // Chrome / Firefox
         navigator.language ||   // All browsers
         navigator.userLanguage; // IE <= 10

         defaultLocale = 'fi';
  }
    
  const finalLocale = Object.keys(supportedLocales).includes(desiredLocale)
    ? desiredLocale
    : defaultLocale;
  return dispatch => dispatch(setLocale(finalLocale));
}