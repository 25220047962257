/**
 * OutdatedBrowser - Finnish version
 */
import React from "react";
import { Translate } from "react-redux-i18n";

class OutdatedBrowser_FI extends React.PureComponent {
  goTop = () => {};

  render() {
    return (
      <div id="legalcontent" style={{ backgroundColor: "var(--color-main)", height: "100vh", padding: "5em 0" }}>
        <div style={{ textAlign: "center", padding: "2em 0", fontSize: "3em", color: "var(--color-contrast)" }}>
          <Translate value={`labels.outdated_1`} />
        </div>
        <div style={{ textAlign: "center", fontSize: "1.5em" }}>
          <Translate value={`labels.outdated_2`} />
        </div>
      </div>
    );
  }
}

export default OutdatedBrowser_FI;
