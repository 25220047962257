/**
 * ThemeSwitcher component
 ** Change theme of the app
 */

import { log } from "../config/log";
import React from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { withStyles } from "@mui/styles";
import { setTheme } from "../redux-store/settings-reducer";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import hostConfig from "../config/hostConfig";

const styles = (theme) => ({
  root: {
    "& .MuiButton-root": {
      textTransform: "none",
      fontSize: "0.92rem",
      color: "var(--grey)",
      fontWeight: "400",
      "@media (hover:hover)": {
        fontSize: "0.75rem",
      },
    },
    "& .MuiButton-startIcon": {
      marginRight: "0.8rem",
      "@media (hover:hover)": {
        marginRight: "0.8rem",
      },
    },
    "& .MuiButtonGroup-groupedTextHorizontal:not(:last-child)": {
      border: "0",
    },
    "& .MuiListItemAvatar-root": {
      minWidth: "30px",
    },
    flexDirection: "column",
    alignItems: "left",
    "& > *": {
      zIndex: 9999,
    },
  },
});

class ThemeSwitcher extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      availableThemes: [],
    };
  }

  UNSAFE_componentWillMount() {
    let host = window.location.hostname ? window.location.hostname.replace(/\./g, "_").replace(/\-/g, "_") : "betv3_frontend_herokuapp_com";
    let themes = hostConfig[host]["themes"] ? hostConfig[host]["themes"] : [];

    this.setState({ availableThemes: themes });
  }

  handleChange = (event, mode) => {
    event.preventDefault();

    this.props.setTheme(mode);

    localStorage.setItem("theme", mode);
    document.getElementsByTagName("html")[0].className = "";
    document.getElementsByTagName("html")[0].classList.add(mode);
    this.setState({ open: false }, () => {});
    window.location.reload();
  };

  handleToggle = () => {
    this.setState({ open: !this.state.open });
  };

  handleClose = (event) => {
    if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  render() {
    log(this.constructor.name, "render");
    const { selectedTheme, classes } = this.props;
    const { availableThemes } = this.state;

    return (
      <div className={classes.root}>
        <ButtonGroup variant="text" size="small" color="primary" ref={(ref) => (this.anchorRef = ref)} aria-label="split button">
          <Button sx={{ paddingLeft: "3px" }} startIcon={<Avatar src={`https://api.nubet.com/media/icons/theme-${selectedTheme}.png`} sx={{ width: "20px", height: "20px" }} />}>
            <Translate value={`theme.${selectedTheme}`} />
          </Button>
          <Button
            variant="text"
            color="primary"
            size="small"
            aria-controls={this.state.open ? "split-button-menu" : undefined}
            aria-expanded={this.state.open ? "true" : undefined}
            aria-haspopup="menu"
            onClick={this.handleToggle.bind(this)}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>

        <Popper open={this.state.open} anchorEl={this.anchorRef} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose.bind(this)}>
                  <MenuList id="split-button-menu">
                    {availableThemes.map(
                      (theme) =>
                        theme && (
                          <MenuItem key={theme} selected={theme === selectedTheme} onClick={(event) => this.handleChange(event, theme)}>
                            <ListItemAvatar>
                              <Avatar src={`https://api.nubet.com/media/icons/theme-${theme}.png`} sx={{ width: "20px", height: "20px" }} />
                            </ListItemAvatar>
                            <Translate value={`theme.${theme}`} />
                          </MenuItem>
                        )
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedTheme: state.settings.theme,
  };
};

const mapDispatchToProps = { setTheme };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ThemeSwitcher));
