import api from "../../apis/nubet";
import authHeader from "../../apis/auth-header";
import { v4 as uuid } from "uuid";

export const generateDatabetToken = async ({ isAuthenticated, lang }) => {
  let unique_id = uuid();
  const saved_id = localStorage.getItem("databet_unique_id");
  if (saved_id) {
    unique_id = saved_id;
  } else {
    localStorage.setItem("databet_unique_id", unique_id);
  }
  try {
    const token_value = localStorage.getItem("jwt");
    const user = JSON.parse(localStorage.getItem("betworx_logged_in_user"));
    const reply = await api.post(
      "/token/create",
      {
        locale: lang,
        currency: "EUR",
        player_id: user?.user_id,
        params: {
          some_token: token_value || unique_id,
          internal_player_id: user?.user_id,
          session_id: token_value || unique_id,
        },
        context: {
          bet: {
            min_stake_sum: "1.00",
          },
        },
      },
      {
        headers: authHeader(),
      }
    );
    return {
      token: reply.data?.token,
      isAuthenticated,
    };
  } catch (error) {
    console.log(error);
  }
};
