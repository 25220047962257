/**
 * This is Account Panel component when our app is being viewed in TV
 */

import { log } from "../../config/log";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import clsx from "clsx";
import { Translate } from "react-redux-i18n";
import { ListItemText } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Avatar from "@mui/material/Avatar";
import SignInIcon from "@mui/icons-material/LockOpen";
import SignOutIcon from "@mui/icons-material/ExitToApp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PaymentIcon from "@mui/icons-material/Payment";
import AdultIcon18 from "../../media/img/18+.svg";
import AdultIcon21 from "../../media/img/21+.svg";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import IconABOUT from "../sports/@components/@icons/IconABOUT";

import { signOut } from "../../actions";
import { toggleMyTicketsPanel } from "../../actions";
import { generateDatabetTokenAsync } from "../../redux-store/databet-reducer";
import { setLiveChatWidget } from "../../redux-store/settings-reducer";
import withRouter from "../../helpers/withRouter";
import BalanceDropDown from "./BalanceDropDown";
import LanguageSwitcher from "../LanguageSwitcher";
import ThemeSwitcher from "../ThemeSwitcher";

const styles = (theme) => ({
  root: {
    "& .MuiListItemIcon-root": {
      minWidth: "30px",
    },
    "& .MuiListItem-root": {
      fontSize: "1rem",
    },
    "& .MuiSvgIcon-root": {
      color: "var(--grey)",
    },
    list: {
      width: 250,
    },
    fullList: {
      width: "auto",
    },
  },
  avatar: {
    backgroundColor: "var(--color-contrast-5) !important",
    color: "var(--btn-text) !important",
    width: "2.8em !important",
    height: "2.8em !important",
    fontSize: "0.5em !important",
    lineHeight: "3em !important",
  },
});

class AccountPanel extends React.Component {
  // export default function AccountPanel(props) {

  constructor(props) {
    super(props);

    this.state = {
      top: false,
      left: false,
      bottom: false,
      right: false,
      isActive: false,
    };
  }

  handleClearCache = () => {
    const { locale, trustlyDepositStatus, navigate } = this.props;
    if (trustlyDepositStatus?.type) {
      window.localStorage.clear();
      navigate(`/${locale}`);
      window.location.reload();
    }
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    /**
     * Redirect to casino page again after login when sign_out request comes from casino page
     */

    if (newProps.auth !== this.props.auth) {
      if (!newProps.auth.isSignedIn) {
        const pathName = window.location.pathname;
        if (pathName.indexOf("/casino/dashboard") > -1) {
          // user is in playing casino game - return to login page
          this.props.navigate(`/${this.props.locale}/sign-in?redirect_to=casino`);
        }
      }
    }
  }

  toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    this.setState({ ...this.state, [anchor]: open });
  };

  renderList = (anchor) => {
    const { auth, classes, host, locale } = this.props;
    return (
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        })}
        role="presentation"
        onKeyDown={this.toggleDrawer(anchor, false)}
      >
        <div className={classes.root}>
          <List>
            {!auth.isSignedIn ? (
              <div>
                <Link to={`/${locale}/sign-in`} onClick={this.toggleDrawer(anchor, false)}>
                  <ListItem button key="sign-in" onClick={this.handleClearCache}>
                    <ListItemIcon>
                      <SignInIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText>
                      <Translate value="labels.account_login" />
                    </ListItemText>
                  </ListItem>
                </Link>
                <Link to={`/${locale}/register-switch`} onClick={this.toggleDrawer(anchor, false)}>
                  <ListItem button key="register">
                    <ListItemIcon>
                      <PersonAddIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText>
                      <Translate value="labels.register" />
                    </ListItemText>
                  </ListItem>
                </Link>
              </div>
            ) : (
              [
                <div key="signed-in-items">
                  <Link to={`/${locale}/payments`} id="payments_link" onClick={this.toggleDrawer(anchor, false)}>
                    <ListItem button key="payin">
                      <ListItemIcon>
                        <PaymentIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText>
                        <Translate value="labels.account_payment" />
                      </ListItemText>
                    </ListItem>
                  </Link>
                  <Link to={`/${locale}/statement`} id="statement_link" onClick={this.toggleDrawer(anchor, false)}>
                    <ListItem button key="statement">
                      <ListItemIcon>
                        <AccountBalanceIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText>
                        <Translate value="labels.statement_title" />
                      </ListItemText>
                    </ListItem>
                  </Link>
                  <Divider />
                  <Link to={`/${locale}/settings`} id="settings_link" onClick={this.toggleDrawer(anchor, false)}>
                    <ListItem button key="settings">
                      <ListItemIcon>
                        <SettingsIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText>
                        <Translate value="labels.setting_title" />
                      </ListItemText>
                    </ListItem>
                  </Link>
                </div>,
              ]
            )}
            <Divider />
            <ListItem
              style={{ cursor: "pointer" }}
              key="customersupport"
              onClick={(e) => {
                this.props.setLiveChatWidget(true);
                const openDrawer = this.toggleDrawer(anchor, false);
                openDrawer(e);
              }}
            >
              <ListItemIcon>
                <IconABOUT />
              </ListItemIcon>
              <ListItemText primary={<Translate value="labels.customer_support" />} />
            </ListItem>
            <Divider />
            <ListItem>
              <LanguageSwitcher />
            </ListItem>
            <Divider />
            <ListItem>
              <ThemeSwitcher />
            </ListItem>
            <Divider />
            {auth.isSignedIn && (
              <div>
                <ListItem key="sign_out" onClick={this.signOut.bind(this)} id="logout_btn" style={{ cursor: "pointer" }}>
                  <ListItemIcon>
                    <SignOutIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText>
                    <Translate value="labels.logout" />
                  </ListItemText>
                </ListItem>

                <Divider />
              </div>
            )}
            <ListItem>
              <Grid container spacing={0}>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  {host.brand === "nubet_et" ? (
                    <img style={{ height: "3em" }} src={"https://api.nubet.com/media/icons/maksujatolliameti.png"} alt="licence-ee" />
                  ) : (
                    <img style={{ height: "3em" }} src={"https://api.nubet.com/media/icons/spelinspektionen.png"} alt="licence-se" />
                  )}
                </Grid>
                <Grid item xs={6} style={{ textAlign: "center" }}>
                  <Link to={`/${locale}/info/responsible-game`}>
                    <img style={{ height: "2em", margin: "0.5em 0 0 0.3em" }} src={AdultIcon18} alt="18+" />
                  </Link>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "center" }}>
                  {host.brand === "nubet_et" ? (
                    <Link to={`/${locale}/info/responsible-game`}>
                      <img style={{ height: "2em", margin: "0.5em 0 0 0.3em" }} src={AdultIcon21} alt="21+" />
                    </Link>
                  ) : null}
                </Grid>
              </Grid>
            </ListItem>
            <Divider />
            <Grid
              item
              xs={12}
              sx={{
                width: "18em",
                margin: "1em",
                textAlign: "center",
                color: "var(--grey)",
                fontSize: "0.7rem",
                lineHeight: 1.1,
              }}
            >
              <Translate value="labels.attention_gambling" />
            </Grid>

            <Divider />
            <ListItem>
              <Grid container spacing={0} align="center">
                <Grid item xs={12} className={"font-release-version"}>
                  Release v 4.103 - 13/12/24
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </div>
      </div>
    );
  };

  numberWithCommas = (x) => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return "0,00";
  };

  handleBlur = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  signOutCallback = () => {
    this.props.toggleMyTicketsPanel(false);
    this.props.generateDatabetTokenAsync({ isAuthenticated: false, lang: this.props.locale });
  };

  signOut = () => {
    this.props.signOut(this.constructor.name, true, this.signOutCallback);
  };

  render() {
    log(this.constructor.name, "render");
    const { auth, host, classes } = this.props;

    if (!host) return null;

    return (
      <div key="ticket_balance_info" className="grey-3 font-l vertical-center cursor-pointer">
        {auth.isSignedIn ? <BalanceDropDown /> : null}
        <IconButton edge="end" className={classes.menuButton} aria-label="open drawer" onClick={this.toggleDrawer("right", true)} color={auth.isSignedIn ? "primary" : "default"} id="user_drawer_opener">
          {auth.isSignedIn ? <Avatar className={classes.avatar}>{auth.initials}</Avatar> : <AccountCircleIcon color="primary" />}
        </IconButton>
        <Drawer anchor="right" open={this.state["right"]} onClose={this.toggleDrawer("right", false)} elevation={0}>
          {this.renderList("right")}
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.user.auth,
    host: state.settings.host,
    balance: state.user.balanceDetails.balance,
    layout: state.settings.layout,
    showMyTicketsPanel: state.settings.showMyTicketsPanel,
    url: state.settings.url,
    locale: state.i18n.locale,
    trustlyDepositStatus: state.user.trustlyDepositStatus,
  };
};

export default withRouter(connect(mapStateToProps, { signOut, setLiveChatWidget, toggleMyTicketsPanel, generateDatabetTokenAsync })(withStyles(styles)(AccountPanel)));
