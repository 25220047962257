/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconBD = (props) => {

    return (
        <div style={{ display: "flex" }}>
            <svg className="icon-own" viewBox="0 0 2000 2000">
                {/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
                    <stop className="icon-color-1" offset="0%" />
                    <stop className="icon-color-2" offset="50%" />
                    <stop className="icon-color-1" offset="100%" />
                </linearGradient> */}
                <g><path d="M1601.8,178c-9.3,0-18.1,4.3-23.8,11.7c-54.5,70.6-340.2,441.1-412.3,534.2l-28.3,36.6
		c-331.1,429.3-591,771.7-594.6,776.6c-10,13.6-29.2,16.6-42.8,6.7c-9.6-7-14.3-19.1-12-30.7c9.4-47.9-10-97.5-49.5-126.3
		c-14.9-10.9-31.8-18.3-50.1-21.8c-68.3-13.4-134.7,31.3-148.1,99.6c-21.1,107.5,22.5,218.6,110.9,283.1
		c126.1,92,303.4,64.3,395.5-62c3.3-4.7,249.9-356.6,558.4-802.4l23.3-33.7c76.2-110.3,379.3-539.6,455.1-647
		c7.3-10.3-0.1-24.5-12.7-24.5H1601.8z"></path>
                    <circle cx="488.1" cy="1066.2" r="180.1" /></g>
            </svg>
        </div>
    );
};

export default IconBD;
