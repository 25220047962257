// Player props catalog
import * as React from "react";
import Box from "@mui/material/Box";
import { useLocation, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPlayerPropsAsync } from "../../../redux-store/sports-reducer";
// components
import LeagueRow from "../@components/LeagueRow";
import PlayerPropsGamesList from "./GamesList";
import SportsTabs from "../@components/SportsTabs";
// import FeaturedGamesBannerV1 from "../../banners/featured-game/v1";
import PrecannedBetsCarousel from "../../banners/precanned-bets";
import EmptyScreen from "../@components/EmptyScreen";
import { Translate } from "react-redux-i18n";
import PageLoader from "../../PageLoader";
import SportsLayout from "../@components/SportsLayout";

const PlayerPropsCatalog = () => {
  // redux action dispatcher
  const dispatch = useDispatch();
  // router hooks
  const [searchParams] = useSearchParams();
  const location = useLocation();
  // selectors
  const isPageLoading = useSelector((state) => state.sports.playerProps.isLoading);
  const leagues = useSelector((state) => state.sports.playerProps.leagues);
  const sportCodes = useSelector((state) => state.sports.playerProps.sportCodes);
  const locale = useSelector((state) => state.i18n.locale);
  // seaerch params
  const selectedSportCode = searchParams.get("sport-code") || sportCodes[0];
  // getSelected
  const selectedLeagues = leagues && leagues[selectedSportCode] ? leagues[selectedSportCode] : [];

  React.useEffect(() => {
    if (!selectedLeagues?.length) {
      dispatch(getPlayerPropsAsync(locale));
    }
  }, [location.pathname]);

  return (
    <SportsLayout>
      <Box>
        <Box>
          <PrecannedBetsCarousel topLeagueIds={[]} isInBBModal />
        </Box>
        {!selectedLeagues?.length && isPageLoading ? (
          <PageLoader />
        ) : !selectedLeagues?.length && !isPageLoading ? (
          <EmptyScreen message={<Translate value="labels.empty_screen_message" />} />
        ) : (
          <>
            <Box pt={1.2}>
              <SportsTabs sportCodes={sportCodes} initialCode={selectedSportCode} />
            </Box>
            {selectedLeagues?.map((league, indx) => (
              <LeagueRow key={league?.leagueId} rowIndex={indx} {...league} content={<PlayerPropsGamesList league={league} />} />
            ))}
          </>
        )}
      </Box>
    </SportsLayout>
  );
};

export default PlayerPropsCatalog;
