// MUI Paper
export function paper(theme) {
    return {
      MuiPaper: {
        styleOverrides: {
          root: {
            background: theme.palette.background.paper,
          },
        },
      },
    };
  }