import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import PrecannedCard from "../@cards/precanned-card";
import { getBetbuilderBannersAsync } from "../../../redux-store/banners-reducer";
import SwiperSlider from "../../swiper";
import { SwiperSlide } from "swiper/react";
import featuredPrecannedSliderSettings from "../../swiper/settings/featured-precanned-slider";

const shuffleBets = (precannedBetsList) => {
  for (let i = precannedBetsList.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [precannedBetsList[i], precannedBetsList[j]] = [precannedBetsList[j], precannedBetsList[i]];
  }

  return precannedBetsList;
};

const styles = (theme) => ({
  root: {
    "& .slick-custom-next": {
      position: "absolute",
      top: "40%",
      right: -45,
    },
    "& .slick-custom-prev": {
      position: "absolute",
      top: "40%",
      left: -45,
      zIndex: 1,
    },
    "@media only screen and (max-width: 603px)": {
      width: "100%",
      height: "100dvh",
      "& .slick-custom-next": {
        right: -30,
      },
      "& .slick-custom-prev": {
        left: -30,
      },
    },
    "@media (hover:hover)": {
      backgroundColor: "var(--color-main)",
    },
    "& .MuiTypography-root": {
      fontSize: "1.1em !important",
    },
    "& p": {
      margin: "1em 0 1em",
    },
    "& li": {
      margin: "1em 0",
    },
    "& .MuiDivider-root": {
      backgroundColor: "var(--color-contrast)",
    },
    padding: "0 0 10px 0",
    flexGrow: 1,
    height: "max-content !important",
    backgroundColor: theme.palette.background.paper,
  },
});

class PrecannedBetsCarousel extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { lang } = this.props;
    this.props.getBetbuilderBannersAsync(lang);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.precannedBetsList.length < nextProps.precannedBetsList.length) {
      return true;
    }
    return false;
  }

  render() {
    const { classes, precannedBetsList, layout } = this.props;
    if (precannedBetsList?.length === 0) return;
    return (
      <div className={classes.root}>
        <div className="featured-matches-container">
          <SwiperSlider {...featuredPrecannedSliderSettings} nextButton={layout === "mouse" && precannedBetsList?.length > 3} prevButton={layout === "mouse" && precannedBetsList?.length > 3}>
            {shuffleBets(precannedBetsList)?.map((bet, indx) => (
              <SwiperSlide key={indx}>
                <PrecannedCard game={bet.game} bet={bet} />
              </SwiperSlide>
            ))}
          </SwiperSlider>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const precannedList = state.banner?.precanned && typeof state.banner.precanned === "object" ? state.banner.precanned : null;
  let precannedBetsList = [];
  console.log(precannedList);
  
  if (precannedList) {
    Object.keys(precannedList).forEach((lgkey) => {
      const league = precannedList[lgkey];
      Object.values(league).forEach((lgGame) => {
        lgGame.bets?.forEach((bet) => {
          precannedBetsList.push({ ...bet, game: { ...lgGame, bets: [] } });
        });
      });
    });
  }

  return {
    slip: state.banner.slip,
    lang: state.i18n.locale,
    precannedBetsList: precannedBetsList,
    layout: state.settings.layout,
  };
};

export default connect(mapStateToProps, { getBetbuilderBannersAsync })(withStyles(styles)(PrecannedBetsCarousel));
