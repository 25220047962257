/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconMM = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g>
					<path d="M1784.3,544.4l-224.7-209.6c-139.5-130.2-358.9-122.6-489.1,16.9L912.1,521.4l-40.4-37.7c-75.7-70.7-194.9-66.5-265.5,9.2
	L412.7,700.4c-60.5,64.9-73.7,157.4-41,234.4l-150.8,161.6c-98.8,105.9-94.7,271.4,8,372.4c0.7,0.8,1.4,1.6,2.2,2.3l2.8,2.6
	l328.1,306.1l2.8,2.6c0.8,0.7,1.6,1.4,2.5,2.1c107.8,95.5,273.2,88.2,372.1-17.7l166.8-178.7c92.2,15.2,194.8-18.4,268.4-97.2
	l264.7-283.7c0.1-0.1,0.1-0.1,0.2-0.2l162-173.6C1931.4,894,1923.9,674.6,1784.3,544.4z M1679.4,527.6l64.4,60.1
	c12.6,11.7,23.7,24.4,33.7,37.6l-280.2,300.3c-28.2-36.8-58.5-71.9-91-105.4L1679.4,527.6z M1505.5,366.2c4.6,3.8,9.2,7.7,13.7,11.9
	l116.9,109.1l-272,291.5c-2.5-2.3-4.9-4.7-7.4-7c-41.6-38.3-85.4-73.4-131.3-105.2L1505.5,366.2z M1113.9,392.1
	c89.6-96,230-116.5,341.3-59L1175,633.4c-61.7-38.3-126.8-71-195-97.8L1113.9,392.1z M583.5,1718.9L293.2,1448l32.2-34.5
	c48.4-51.8,129.9-54.6,181.7-6.3l102.3,95.5c51.8,48.4,54.6,129.9,6.3,181.7L583.5,1718.9z M628.8,1757.4l30.4-32.5
	c70.7-75.7,66.5-194.9-9.2-265.5l-102.3-95.5c-75.7-70.7-194.9-66.5-265.5,9.2l-30.4,32.5c-61.1-79-57.9-193.2,12.5-268.6
	l139.4-149.4c5.9,7.2,12.3,14.1,19.2,20.6l554.3,517.2c19.8,18.4,41.9,32.9,65.6,43.6l-146.8,157.3l0,0
	C825.6,1801.8,711.9,1812.9,628.8,1757.4z M1331,1450.5c-89.1,95.5-229.7,109.7-313.4,31.6L463.3,964.9
	c-63.5-59.8-66.8-160.3-7.3-224l193.6-207.4c48.4-51.8,129.9-54.6,181.7-6.3l40.4,37.7L741,704.8c-11.2,12-10.5,30.7,1.5,41.9
	c12,11.2,30.7,10.5,41.9-1.5l151.9-162.8c284.6,105.4,519.3,322.9,645.8,598.9L1331,1450.5z M1625.8,1134.6
	c-27-56-58-109.4-92.9-160l276.2-296.1c46.5,102.4,30.1,227.3-51.2,314.5L1625.8,1134.6z"/>
				</g>
			</svg>
		</div>
	);
};

export default IconMM;
