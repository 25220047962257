// Racing odd selection button
import { Box, Typography, styled, Paper, Stack, Grid2 } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { checkSelectedOdd } from "./@utils/odds-utils";
import { toggleMyTicketsPanel } from "../../../redux-store/settings-reducer";
// import { saveBet } from "../../../redux-store/slips-reducer";
import { saveBet } from "../../../actions";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  cursor: "pointer",
  userSelect: "none",
}));

const StyledPaper = styled(Paper)(({ theme, nonRunner, isSelected }) => ({
  backgroundColor: theme.palette.background.results1,
  paddingTop: theme.spacing(0.1),
  paddingBottom: theme.spacing(0.1),
  paddingLeft: theme.spacing(0.7),
  paddingRight: theme.spacing(0.7),
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  border: "1px solid",
  borderColor: theme.palette.background.results,
  "&:hover": {
    background: theme.palette.background.selectionHover,
  },
  ...(nonRunner && {
    color: theme.palette.text.disabled,
    textDecoration: "line-through",
    "&:hover": {
      background: theme.palette.background.results,
    },
    "& .MuiTypography-root": {
      color: theme.palette.text.disabled,
    },
  }),
  ...(isSelected && {
    borderColor: theme.palette.primary.main,
  }),
}));

const HorseIcon = styled(Box)(({ theme, nonRunner }) => ({
  backgroundImage: "url(https://api.nubet.com/media/icons/icon_horse.svg)",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "object-fit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  color: theme.palette.primary.main,
  ...(nonRunner && {
    color: theme.palette.error.main,
  }),
}));

const RacingSelectionButton = ({ odd, game }) => {
  // REDUX ACTION DISPATCHER
  const dispatch = useDispatch();
  // values
  const selectedSlipTemplate = 0;
  const index = 0;
  const priceChange = odd?.priceChange;
  // redux selectors
  const slip = useSelector((state) => state.slips[selectedSlipTemplate]);
  const bets = Object.values(slip.settings.bets);
  const isSelected = checkSelectedOdd(odd, game, bets, index);
  // ADD BET
  const addBet = () => {
    if (odd?.nr) return;
    let payload = {
      gameId: game.gameid,
      isLive: game.isLive,
    };

    const marketId = odd.m;
    const selectionId = odd.s;
    const price = odd.p;
    const hc = odd.hc;
    const isActive = odd.iA;

    payload.marketId = marketId;
    payload.selectionId = selectionId;
    payload.price = price;
    payload.hc = hc;
    payload.hn = odd.hn;
    payload.sn = odd.sn;
    payload.nr = odd?.nr;
    payload.isActive = isActive;
    payload.game = game;
    payload.isOutright = true;
    payload.isMultibet = 0;
    payload.isBank = -1;
    dispatch(toggleMyTicketsPanel(false));
    dispatch(saveBet(payload, selectedSlipTemplate, "RacingSelectionButton"));
  };

  return (
    <Container onClick={addBet}>
      <Grid2 container spacing={1}>
        <Grid2 item size={2}>
          <HorseIcon nonRunner={odd?.nr}>
            <Typography mt={2} mr={1} variant="string" align="center">
              {odd?.ord}
            </Typography>
          </HorseIcon>
        </Grid2>
        <Grid2 item size={10}>
          <StyledPaper nonRunner={odd?.nr} isSelected={isSelected}>
            <Stack width="100%">
              <Typography color="primary" variant="body2" textTransform="uppercase">
                {odd?.hn}
              </Typography>
              <Typography variant="body2">{odd?.dn}</Typography>
            </Stack>
            <Typography variant="2" color="primary.menuTopActive" className={priceChange === "=" ? "" : priceChange === ">" ? "odds-raise" : priceChange === "<" ? "odds-drop" : ""}>
              {odd?.p}
            </Typography>
          </StyledPaper>
        </Grid2>
      </Grid2>
    </Container>
  );
};

export default RacingSelectionButton;
