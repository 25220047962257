import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const styles = (theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    padding: 15,
    top: "35%",
    left: "40%",
    borderRadius: "8px",
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "15px",
  },
  body: {
    padding: "15px 0 ",
  },
});

class LimitsConfirmationPopUp extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    log(this.constructor.name, "render");
    const {
      classes,
      isModalOpen,
      onConfirm,
      weeklyLimit,
      dailyLimit,
      monthlyLimit,
      limitType,
      translate,
    } = this.props;

    return (
      <Modal
        open={isModalOpen}
        onClose={this.props.handleModal}
        aria-labelledby="loss-limit-modal"
        aria-describedby="loss-limit-description"
      >
        <div className={classes.paper}>
          <Typography variant="h6" component="h2" align="center">
            {translate?.labels[`${limitType}_limits_update`]}
          </Typography>
          <div className={classes.body}>
            <div>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography>{translate?.labels["daily"]}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  {dailyLimit ? (
                    <Typography>{parseInt(dailyLimit)} €</Typography>
                  ) : null}
                </Grid>
                <Grid item xs={6}>
                  <Typography>{translate?.labels["weekly"]}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  {weeklyLimit ? (
                    <Typography>{parseInt(weeklyLimit)} €</Typography>
                  ) : null}
                </Grid>
                <Grid item xs={6}>
                  <Typography>{translate?.labels["monthly"]}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  {monthlyLimit ? (
                    <Typography>{parseInt(monthlyLimit)} €</Typography>
                  ) : null}
                </Grid>
              </Grid>
            </div>
          </div>

          <div className={classes.footer}>
            <Button
              onClick={this.props.closeModal}
              variant="outlined"
              color="error"
            >
              {translate?.labels?.limits_cancel}
            </Button>
            <Button onClick={onConfirm} variant="contained" color="primary">
              {translate?.labels?.limits_confirm}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.user.auth,
    locale: state.i18n.locale,
    isModalOpen: ownProps.isModalOpen,
    closeModal: ownProps.handleModal,
    onConfirm: ownProps.onConfirm,
    dailyLimit: ownProps.daily,
    weeklyLimit: ownProps.weekly,
    monthlyLimit: ownProps.monthly,
    limitType: ownProps.limitType,
    translate: state.i18n.translations[state.i18n.locale],
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(LimitsConfirmationPopUp)
);
