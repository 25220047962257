/**
 * User register with deposit component
 */
import "./RegisterSwitch.css";
import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";

import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { withStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { registerUserWithTrustly } from "../../actions";
import AdultIcon18 from "../../media/img/18+.svg";
import AdultIcon21 from "../../media/img/21+.svg";

import { Box, InputAdornment } from "@mui/material";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";

const styles = (theme) => ({
  root: {
    padding: "2em 0 1em",
    fontSize: "var(--content)",
    backgroundColor: "var(--color-main)",
    height: "100%",
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
    "& .MuiFormControl-root": {
      width: "20em",
      // textAlign: 'center'
      // "@media (hover:hover)": {
      //     width: '50%'
      // },
    },
    textAlign: "center",
  },
  formText: {
    margin: "0 0 1em 0",
    // color: 'var(--white)',
    fontSize: "1.5em",
  },
  formTextTerms: {
    margin: "0 0 1em 0",
    // color: 'var(--white)',
    fontSize: "1em",
  },
  featText: {
    textTransform: "uppercase",
    fontStyle: "italic",
    fontWeight: 500,
    color: "var(--color-contrast)",
  },
  bonusText: {
    // textTransform: "uppercase",
    // fontStyle: "italic",
    fontSize: "1em",

    // color: "var(--color-contrast)",
  },
  ammountButton: {
    color: "var(--btn-text)",
    fontSize: "1.2em",
    margin: "0 0.5em 0 0",
    padding: "0.2em 0.3em",
    "@media (hover:hover)": {
      fontSize: "1.45em",
    },
    // background: "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
  },
  ammountField: {
    "& .MuiOutlinedInput-input": {
      color: "var(--color-input-amount)",
      fontSize: "1.5em",
      textAlign: "center",
      margin: "0 0 0 1em",
      "&::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "&::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  },
  title: {
    color: "var(--color-headline)",
    fontSize: "1.2em",
    padding: "0.5em",
    textTransform: "uppercase",
  },
});

class RegisterSwitch extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      amount: "0",
    };
  }

  goTop = () => {
    /**
     * scroll to top of the page
     */
  };

  setAmount = (value) => {
    this.setState({ amount: value });
  };

  handleRegistration = () => {
    if (parseInt(this.state.amount) < 10 || parseInt(this.state.amount) > 5000) {
      return;
    }
    window.localStorage.setItem("affiliate_type", "none");
    this.props.registerUserWithTrustly(this.constructor.name, {
      amount: this.state.amount,
    });
  };

  render() {
    log(this.constructor.name, "render");
    const { host, classes, locale, lang } = this.props;
    // console.log(this.state.amount);

    if (!host) return null;

    return (
      <Container className={classes.root} maxWidth={false}>
        {/* <Typography component="span">
                    <div id="legalcontent" className={classes.title}>
                        <Translate value={`labels.user_register`} />
                    </div>
                </Typography> */}
        <Grid container className={classes.form}>
          <Grid item xs={12} className={classes.formText}>
            <Translate value="labels.register_trustly_1" />
            <span>
              {" "}
              <Translate value="labels.register_trustly_2" />
            </span>
            <br></br>
            <Translate value="labels.register_trustly_3" />
            <br></br>
            {locale === "fi" ? (
              <span className={classes.bonusText}>
                {/* <br></br><br></br> */}
                {/* <Translate value="labels.register_bonus_1" /> */}
              </span>
            ) : (
              <span className={classes.featText}>
                <Translate value="labels.register_trustly_4" />
              </span>
            )}
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" justifyContent="center" gap={3} my={3}>
              <Button onClick={() => this.setAmount(parseInt(this.state.amount) + 25)} variant="contained" color="primary" className={classes.ammountButton} style={{ color: "var(--btn-text)" }}>
                <Translate value="+ 25€" />
              </Button>
              <Button onClick={() => this.setAmount(parseInt(this.state.amount) + 50)} variant="contained" color="primary" className={classes.ammountButton} style={{ color: "var(--btn-text)" }}>
                <Translate value="+ 50€" />
              </Button>
              <Button onClick={() => this.setAmount(parseInt(this.state.amount) + 100)} variant="contained" color="primary" className={classes.ammountButton} style={{ color: "var(--btn-text)" }}>
                <Translate value="+ 100€" />
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} style={{ margin: "1em 0 1em 0" }}>
            <TextField
              onChange={(e) => this.setAmount(e.target.value)}
              variant="outlined"
              color="primary"
              className={classes.ammountField}
              inputProps={{ type: "number" }}
              value={this.state.amount}
              helperText=""
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <EuroSymbolIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ margin: "1em 0 0.5em 0" }}>
            <Button
              onClick={this.handleRegistration}
              variant="contained"
              color="primary"
              style={{ color: "var(--btn-text)", margin: "1em 0" }}
              disabled={parseInt(this.state.amount ? this.state.amount : 0) < 10 || parseInt(this.state.amount ? this.state.amount : 0) > 5000}
            >
              <Translate value="labels.register_new" />
            </Button>
          </Grid>
          {/* <Grid item xs={12} className="vertical-center bank-logos">
            <img style={{ height: "1.8em", margin: "1em" }} src={TrustlybankIcon} alt="trustly" />
            <img style={{ height: "1.5em", margin: "1em" }} src={NordeaIcon} alt="nordea" />
            <img style={{ height: "2em", margin: "1em" }} src={SaastopankkiIcon} alt="saastopankki" />
            <img style={{ height: "2em", margin: "1em" }} src={OsuuspankkiIcon} alt="osuuspankki" />
            <img style={{ height: "1.3em", margin: "1em" }} src={AktiaIcon} alt="aktia" />
            <img style={{ height: "1.8em", margin: "1em" }} src={DanskebankIcon} alt="danskebank" />
            <img style={{ height: "1.8em", margin: "1em" }} src={SpankkiIcon} alt="spankki" />
          </Grid>
          </Grid> */}

          {/* <Grid item xs={12} style={{ margin: '2em 0 1.5em 0' }} >
                        <Divider variant="middle" style={{ backgroundColor: 'var(--color-contrast-3)' }}/>
                    </Grid>
                    <Grid item xs={12} style={{ margin: '1em 0 1em 0' }} className={classes.formText} >
                    <Translate value="labels.register_trustly_5" /> <br></br>
                        <Link to={`/register`}><Button variant="contained" color="primary" size="small" className="button-std"><Translate value="labels.register_old" /></Button></Link>
                    </Grid> */}
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Link to={`/${locale}/info/responsible-game`}>
              <img style={{ height: "3em", margin: "0.5em 0 0 0.3em" }} src={AdultIcon18} alt="18+" />
            </Link>{" "}
            {host.brand === "nubet_et" ? (
              <Link to={`/${locale}/info/responsible-game`}>
                <img style={{ height: "3em", margin: "0.5em 0 0 3em" }} src={AdultIcon21} alt="21+" />
              </Link>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              width: "18em",
              margin: "1em",
              textAlign: "center",
              color: "var(--grey)",
            }}
          >
            <Translate value="labels.attention_gambling" />
          </Grid>
          <Grid item xs={12} style={{ margin: 0 }}>
            <Box>
              <Translate value="labels.minimum_amount" />
            </Box>
          </Grid>
          <Grid item xs={12} style={{ margin: "0" }} className={classes.formTextTerms}>
            <Translate value="labels.register_trustly_6" />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
    lang: ownProps.lang,
    searchParams: ownProps?.location?.search,
    locale: state.i18n.locale,
  };
};

export default connect(mapStateToProps, { registerUserWithTrustly })(withStyles(styles)(RegisterSwitch));
