import React, { Component } from 'react'
import Container from "@mui/material/Container";
import Button from '@mui/material/Button';

class FooterExpand extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false
    }
  }

  showButton = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    const { expanded } = this.state
    return (
      <Container>
        <div>
          <h2 style={{ color: 'var(--color-contrast)' }}>NUBET - MAGIC OF THE NORTH</h2>
          Are you too smart to play the lottery?<br></br><br></br>
        </div>
        {
          expanded &&
          <div style={{ textAlign: 'left' }}>
            This is Nubet, in our opinion one of the coolest new betting sites in the industry. It's not a lottery, for us and our customers it's an intellectual challenge as well as a pastime. However, this requires fair gaming terms, unlike in lottery games.<br></br><br></br>
            Our idea is to create an elegant betting service that stands up to comparison in the competitive Nordic markets as well as elsewhere in Europe. We try to be fair, reliable and stylish. A gaming site worth of your trust.<br></br><br></br>
            The fierce competition in the European licensing market demands a lot from the service. We try to offer competitive odds as much as possible, and our risk management tools do not include smoking out the winning customer after a few bets. Fair play is the basis of everything we do, and we expect the same from our customers. Nubet's risk management tools aim to ensure that all customers are treated fairly.<br></br><br></br>
            There is a strong focus on mobile. We are well aware that the vast majority of games now go through this channel. Ease and speed of play are the guiding objectives behind our development. The same applies, of course, to money transfers. In addition to a smooth gaming experience, a good customer experience requires a smooth money transfer. In this Nubet relies on market leaders such as Trustly. With Nubet, your money is not only safe, but also quickly transferred to your bank account.<br></br><br></br>
            Nubet operates under an Estonian licence, which is more natural choice for us than Malta. Our goal is to be a strong player in the gaming market from Europe to Asia and South America by 2028. After all, Nubet has something that players, wherever they are located, have been looking for a long time. Nubet has the Magic of the North!<br></br><br></br>
          </div>
        }
        <Button color="primary" variant="outlined" style={{ padding: '0.2em 0.5em', fontSize: '0.8em' }} onClick={this.showButton}>{expanded ? 'Close' : 'read more'}</Button>

      </Container>
    )
  }
}

export default FooterExpand