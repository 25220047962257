/**
 * gameFunctions library
 */

import { format, addDays } from "date-fns";

/**
 * Calculate game information
 ** time, team colors, stateIcon, stateBackground, stateText, stateTeam, scoreAway and scoreHome
 */

export const calcGame = (item) => {
  let game = { ...item };
  const ts = new Date().getTime();
  let stateInfo = null;

  game["ts"] = ts;
  game["score_set_calculated"] = calcScoreSet(
    "",
    game.state,
    game.score_set,
    game.sport_code
  );
  // game['time'] = game.isLive === 1 ? calcGameTime(game.state, game.liveTime, game.sport_code) : game.startTime;
  game["time"] = game.startTime;
  game["team1Color1"] = randomHexCode();
  game["team1Color2"] = randomHexCode();
  game["team2Color1"] = randomHexCode();
  game["team2Color2"] = randomHexCode();

  // switch (game.sport_code) {
  //   case 'FB':
  //     stateInfo = calcFootballStateInfo(game.danger_state, game.var_state, false);
  //     game['stateIcon'] = stateInfo ? stateInfo.stateIcon : '';
  //     game['stateBackground'] = stateInfo ? stateInfo.stateBackground : '';
  //     game['stateText'] = stateInfo ? stateInfo.stateText : '';
  //     game['stateTeam'] = stateInfo ? stateInfo.stateTeam : '';
  //     break;
  //   case 'TE':
  //     stateInfo = calcTennisStateInfo(game.current_server);
  //     game['stateIcon'] = stateInfo ? stateInfo.stateIcon : '';
  //     game['stateBackground'] = '';
  //     game['stateText'] = '';
  //     game['stateTeam'] = '';
  //     break;
  //   default:
  //     game['stateIcon'] = '';
  //     game['stateBackground'] = '';
  //     game['stateText'] = '';
  //     game['stateTeam'] = '';
  //     break;
  // }

  if (game.sport_code === "TE" && game.isLive === 1) {
    let scoreSets = game.score_set;
    scoreSets = scoreSets.split("|");
    const scoreData = scoreSets[scoreSets?.length - 1];
    const scoreHome = scoreData.split(":")[0];
    const scoreAway = scoreData.split(":")[1];
    game["score_home"] = parseInt(scoreHome) === 50 ? "A" : scoreHome;
    game["score_away"] = parseInt(scoreAway) === 50 ? "A" : scoreAway;
  }

  return game;
};

// filters games
export const checkGameSession = (game, filterMethod) => {
  const givenDate = new Date(game?.startTime);
  const currentDate = new Date();
  const startDate = format(givenDate, "yyy-MMM-dd");
  const today = format(currentDate, "yyy-MMM-dd");
  const lt = addDays(currentDate, 1);
  const tomorrow = format(lt, "yyy-MMM-dd");
  if (filterMethod === "today" && startDate === today) {
    return game;
  } else if (filterMethod === "tomorrow" && startDate === tomorrow) {
    return game;
  } else if (
    filterMethod === "lastMinute" && givenDate.getHours() - currentDate.getHours() <= 3
  ) {
    return game;
  } else if (
    filterMethod === "all" ||
    filterMethod === "results" ||
    !filterMethod ||
    filterMethod === "live"
  ) {
    return game;
  } else {
    return undefined;
  }
};

const getGameTime = (game) => {
  let gameEndTime = new Date();
  let gameTime = new Date();
  if (game?.endTime) {
    gameEndTime = new Date(game?.endTime);
  }
  if (game?.startTime) {
    gameTime = new Date(game?.startTime);
  }
  if (!game?.startTime && game?.time) {
    gameTime = new Date(game?.time);
  }
  //format them to compare
  const formattedGameEndTime = format(gameEndTime, "yyyy-MMMM-dd");
  const formattedGameTime = format(gameTime, "yyyy-MMMM-dd");
  if (formattedGameEndTime !== formattedGameTime) {
    return gameEndTime;
  } else {
    return gameTime;
  }
};

// filters games
export const checkOutrightGameSession = (game, filterMethod) => {
  // const gameStartTime = getGameTime(game);
  const givenDate = new Date(game?.nextBetStop ? game?.nextBetStop : game?.startTime);
  const currentDate = new Date();
  const startDate = format(givenDate, "yyy-MMM-dd");
  const today = format(currentDate, "yyy-MMM-dd");
  const lt = addDays(currentDate, 1);
  const tomorrow = format(lt, "yyy-MMM-dd");
  // console.log(startDate, today, givenDate);
  if (filterMethod === "today" && startDate === today) {
    // console.log(1);
    return game;
  } else if (filterMethod === "tomorrow" && startDate === tomorrow) {
    // console.log(2);
    return game;
  } else if (
    filterMethod === "lastMinute" &&
    startDate === today &&
    givenDate.getHours() - currentDate.getHours() <= 3
  ) {
    // console.log(3);
    return game;
  } else if (
    filterMethod === "all" ||
    filterMethod === "results" ||
    !filterMethod ||
    filterMethod === "live"
  ) {
    return game;
  } else {
    // console.log(5);
    return undefined;
  }
};

/**
 * Calculate football state informations
 ** dangerout states and some other states as well
 */

export const calcFootballStateInfo = (
  dangerStates,
  varStateInfo,
  blockDangerState
) => {
  let varState = null;
  let varInfo = null;
  let varStateText = "";
  let varStateTeam = "";
  let dangerState = "";
  let dangerStateText = "";
  let dangerStateTeam = "";

  let priorities = [
    ["home_penalty", "away_penalty"],
    ["home_shot", "away_shot", "home_pole", "away_pole"],
    [
      "home_corner",
      "away_corner",
      "home_free_kick",
      "away_free_kick",
      "home_attacking_free_kick",
      "away_attacking_free_kick",
      "home_dangerous_free_kick",
      "away_dangerous_free_kick",
    ],
  ];

  if (
    typeof varStateInfo !== "undefined" &&
    varStateInfo !== null &&
    varStateInfo !== ""
  ) {
    //console.log('varStateInfo:' + varStateInfo);

    varState = varStateInfo.split("|")[0];
    varInfo = varStateInfo.split("|")[1];
    varInfo = varInfo.includes("var_") ? varInfo.replace("var_", "") : varInfo;

    if (varState !== "safe") {
      switch (varInfo) {
        case "goal":
        case "home_goal":
        case "away_goal":
        case "no_goal":
        case "home_no_goal":
        case "away_no_goal":
        case "goal_awarded":
        case "home_goal_awarded":
        case "away_goal_awarded":
        case "penalty":
        case "home_penalty":
        case "away_penalty":
        case "no_penalty":
        case "home_no_penalty":
        case "away_no_penalty":
        case "penalty_awarded":
        case "home_penalty_awarded":
        case "away_penalty_awarded":
        case "red_card":
        case "home_red_card":
        case "away_red_card":
        case "no_red_card":
        case "home_no_red_card":
        case "away_no_red_card":
        case "red_card_given":
        case "home_red_card_given":
        case "away_red_card_given":
          varStateText = varInfo.replace("home_", "").replace("away_", "");
          varStateTeam = varInfo.split("_")[0];
          varStateTeam =
            varStateTeam === "home" || varStateTeam === "away"
              ? varStateTeam
              : null;

          break;

        case "mistaken_identity":
        case "home_mistaken_identity":
        case "away_mistaken_identity":
        case "unknown":
        case "home_unknown":
        case "away_unknown":
        case "not_set":
        case "home_no_action":
        case "away_no_action":
        default:
          break;
      }
    }
  }

  // if (blockDangerState === true) {
  //   return false;
  // }

  if (
    (typeof dangerStates === "undefined" ||
      dangerStates === null ||
      dangerStates === "") &&
    varState === null
  ) {
    //console.log('ds:' + dangerStates);
    return false;
  }

  //console.log('dangerStates:' + dangerStates);

  dangerStates = dangerStates.split("|");

  if (dangerStates?.length > 1) {
    for (let i = 0; i < priorities?.length; i++) {
      for (let j = 0; j < priorities[i]?.length; j++) {
        let item = priorities[i][j];
        if (dangerStates.includes(item)) {
          // if ($.inArray(y, dangerStates) > -1) {
          //console.log('prio-val:' + item);
          dangerState = item;
          break;
        }
      }

      if (dangerState !== "") {
        break;
      }
    }

    if (dangerState === "" || !dangerState) {
      dangerState = dangerStates[dangerStates?.length - 1];
    }

    //console.log('ds:' + dangerState);
  } else {
    dangerState = dangerStates[0];
  }

  //console.log('dangerState:' + dangerState);
  //reset both boxes
  // gameBox.find('.mb_danger_state').attr("class", "mb_danger_state");
  dangerStateText = dangerState.replace("home_", "").replace("away_", "");
  dangerStateTeam = dangerState.split("_")[0];

  switch (dangerState) {
    case "home_safe":
    case "away_safe":
    case "home_attack":
    case "away_attack":
    case "home_dangerous_attack":
    case "away_dangerous_attack":
    default:
      // gameBox.unblinkGoal();
      // gameBox.unblinkDanger();
      dangerState = dangerState
        .replace("home_attack", "home_safe")
        .replace("away_attack", "away_safe");

      return {
        stateIcon: "football-state-icon-" + dangerState.split("_").join("-"),
        stateBackground: dangerState.includes("dangerous")
          ? "game_danger_bg"
          : "",
        stateText: varStateText !== "" ? varStateText : dangerStateText,
        stateTeam: varStateTeam !== "" ? varStateTeam : dangerStateTeam,
      };

    case "home_corner":
    case "away_corner":
    case "home_penalty":
    case "away_penalty":
    case "home_free_kick":
    case "away_free_kick":
    case "home_attacking_free_kick":
    case "away_attacking_free_kick":
    case "home_dangerous_free_kick":
    case "away_dangerous_free_kick":
      //console.log('varStateTeam:' + varStateTeam);
      return {
        stateIcon: "football-state-icon-" + dangerState.split("_").join("-"),
        stateBackground: "football-state-background-danger",
        stateText:
          varStateText !== ""
            ? varStateText
            : dangerState.includes("free_kick")
            ? "free_kick"
            : dangerStateText,
        stateTeam: varStateTeam !== "" ? varStateTeam : dangerStateTeam,
      };

    case "home_shot":
    case "away_shot":
    case "home_pole":
    case "away_pole":
    case "home_yellow_card":
    case "away_yellow_card":
    case "home_red_card":
    case "away_red_card":
    case "home_substitution":
    case "away_substitution":
      // console.log('varStateTeam:' + varStateTeam);

      // this.blockGameInfoBox = true;

      // setTimeout(() => {
      //   this.blockGameInfoBox = false;
      // }, dangerState.contains('shot') || dangerState.contains('pole') ? 10000 : 1000);

      // gameBox.unblinkGoal();
      // gameBox.unblinkDanger();
      // gameBox.blinkDanger();
      return {
        stateIcon:
          dangerState.includes("shot") || dangerState.includes("pole")
            ? "football-state-icon-" + dangerState.split("_").join("-")
            : "",
        stateBackground:
          dangerState.includes("yellow_card") ||
          dangerState.includes("substitution")
            ? ""
            : "football-state-background-danger",
        stateText: varStateText !== "" ? varStateText : dangerStateText,
        stateTeam: varStateTeam !== "" ? varStateTeam : dangerStateTeam,
      };
  }

  // gameBox.attr('dangerState', dangerState);
};

/**
 * Calculate game time
 */

export const calcGameTime = (state, minute, sportCode) => {
  // let overTime = null;
  let gameTime = null;
  let period = null;
  let overTime;

  switch (state) {
    case "1p":
    case "2p":
    case "3p":
    case "1q":
    case "2q":
    case "3q":
    case "4q":
    case "ot":
      switch (sportCode) {
        case "FB":
          minute += 1;
          //console.log(minute);

          if (minute > 45 && state === "1p") {
            overTime = minute - 45;
            gameTime = "45'+" + overTime;
          } else if (minute > 90 && state === "2p") {
            overTime = minute - 90;
            gameTime = "90'+" + overTime;
          } else {
            gameTime = minute + "'";
          }

          // gameTime = minute;

          break;
        case "BB":
          gameTime = minute;

          break;

        case "IH":
        case "BA":
          switch (state) {
            case "1p":
              period = "P1";
              break;
            case "2p":
              period = "P2";
              break;
            case "3p":
              period = "P3";
              break;
            case "1q":
              period = "Q1";
              break;
            case "2q":
              period = "Q2";
              break;
            case "3q":
              period = "Q3";
              break;
            case "4q":
              period = "Q4";
              break;
            case "ot":
              period = "OT";
              break;
            default:
              break;
          }

          gameTime = minute;
          //gameTime = period + " " + minute; THIS COULD BE MY SUGGESTION FOR DISPLAY (mm:ss instead of minutes )
          break;

        default:
          gameTime = minute;

          break;
      }

      break;

    case "et":
    case "pen":
    case "interrupted":
    case "first break":
    case "second break":
    case "third break":
    case "fourth break":
    case "fifth break":
    case "sixth break":
      switch (state) {
        case "interrupted":
          //maks: interrupted needs to be translated
          gameTime = "INTERRUPTED";
          break;
        case "pen":
          gameTime = "PEN";
          break;
        case "et":
          gameTime = "ET";
          break;
        default:
          //maks: pause needs to be translated
          gameTime = "PAUSE";
          break;
      }

      break;

    case "1set":
    case "2set":
    case "3set":
    case "4set":
    case "5set":
      //maks translate "SET"
      gameTime = state.charAt(0) + ". SET";

      break;

    case "p1":
    case "paused":
    case "pause1":
    case "pause2":
    case "pause3":
      switch (state) {
        case "p1":
          gameTime = "HT";
          break;

        case "pause1":
          gameTime = "HT1";
          break;

        case "pause2":
          gameTime = "HT2";
          break;

        case "pause3":
          gameTime = "HT3";
          break;

        default:
          break;
      }
      //maks
      // box.find('.gt_stoppage').html('');

      break;

    case "ft":
      //maks: translate Ended
      // gameTime = 'Ended';
      gameTime = "End";

      //maks
      // if (browserTabIsVisible) {
      //   setTimeout(function () {

      //     deleteGame(gameid);
      //   }, 60000);
      // } else {
      //   deleteGame(gameid);
      // }

      break;

    default:
      gameTime = state;
      break;
  }

  if (sportCode === "BB") {
    gameTime = state;
  }

  return gameTime;
};

// PSML TASK 24 - IF game.state IS FT IT MUST BE TAKEN FROM RESULT NOT FROM SCORESET > COMPARE THE TICKETS IN BETWORX AND V3 AND SEE THAT THE SHOWN RESULTS ARE DIFFERENT

export const getGameStatus = (state, sport) => {
  let period = "";
  switch (state) {
    case "1p":
      period = "P1";
      break;
    case "2p":
      period = "P2";
      break;
    case "3p":
      period = "P3";
      break;
    case "1q":
      period = "Q1";
      break;
    case "2q":
      period = "Q2";
      break;
    case "3q":
      period = "Q3";
      break;
    case "4q":
      period = "Q4";
      break;
    case "ot":
      period = "OT";
      break;
    default:
      break;
  }
  if (sport === "IH" || sport === "BA") {
    return period;
  } else {
    return null;
  }
};

/**
 * Calculate score set
 */

export const calcScoreSet = (result, state, scoreSet, sportCode) => {
  let scoreSets = {};
  scoreSets.home = [];
  scoreSets.away = [];
  let showBrPeriods = [];
  let availableBrPeriods = [];
  let availableBrResults = [];

  // if (!scoreSet) {
  //   scoreSets.home.push(null);
  //   scoreSets.away.push(null);
  //   return scoreSets;
  // }
  if (!scoreSet && state === "FT" && result) {
    // copy result from state.
    var resultArray = result?.split(",");
    resultArray.splice(0, 1);
    scoreSet = resultArray.join(",");
  }

  switch (sportCode) {
    case "FB":
      showBrPeriods = [6, 100, 110, 120];
      break;

    case "TE":
    case "TT":
      showBrPeriods = [8, 9, 10, 11, 12];
      break;

    case "BA":
      showBrPeriods = [13, 14, 15, 16, 100];
      break;

    case "IH":
      showBrPeriods = [1, 2, 3, 4, 5, 100];
      break;
    case "CS":
      showBrPeriods = [141, 142];
      break;
    case "PS":
      showBrPeriods = [100, 1, 2, 4];
      break;

    default:
      showBrPeriods = [100];
      break;
  }

  for (let i = 0; i < showBrPeriods?.length; i++) {
    // 100|2:0,50|3:0,40|5:0
    let scoreSetArray = scoreSet ? scoreSet?.split(",") : [];

    for (let j = 0; j < scoreSetArray?.length; j++) {
      // 100|2:0
      let periodArray = scoreSetArray[j].split("|");

      let period = Number(periodArray[0]);
      let score = periodArray[1];

      if (showBrPeriods[i] === period) {
        availableBrPeriods.push(period);
        availableBrResults.push(score);
      }
    }
  }

  if (availableBrPeriods?.length > 0) {
    for (let i = 0; i < availableBrPeriods?.length; i++) {
      let scores = availableBrResults[i].split(":");

      scoreSets.home.push(scores[0]);
      scoreSets.away.push(scores[1]);

      // html += '<div>';
      // html += '<div class="mb_score_set_item">' + scores[0] + '</div>';
      // html += '<div class="mb_score_set_item">' + scores[1] + '</div>';
      // html += '</div>';
    }
  }
  return scoreSets;
};

/**
 * Calculate tennis state info
 */

export const calcTennisStateInfo = (currentServer) => {
  switch (currentServer) {
    case 1:
      return {
        stateIcon: "tennis-state-icon-home-current-server",
      };
    case 2:
      return {
        stateIcon: "tennis-state-icon-away-current-server",
      };
    default:
      return {
        stateIcon: "",
      };
  }
};

/**
 * Generate random hex code for team colors
 */

export const randomHexCode = () => {
  let items = [
    "#FF5733",
    "#FFE033",
    "#DDFF33",
    "#93FF33",
    "#33FFE0",
    "#33A5FF",
    "#AF33FF",
    "#FF337D",
    "#920538",
    "#FFFFFF",
    "#000000",
    "#8D0592",
    "#360592",
    "#100592",
    "#056F92",
    "#059252",
    "#928105",
    "#924D05",
    "#C14304",
  ];
  var item = items[Math.floor(Math.random() * items?.length)];

  return item;
};

/**
 * update game score when new data from SSE service
 */

export const updateScore = (currentGameData, newGamesData) => {
  let updatedGameData = currentGameData;
  let stateInfo = null;

  const filteredGames = newGamesData.filter(
    (obj) => obj.gId === currentGameData?.gameid
  );
  if (filteredGames?.length) {
    let newGameData = filteredGames[0];

    updatedGameData["isLive"] =
      newGameData.st !== "not_started" ? 1 : newGameData.iL;

    updatedGameData["isActive"] = newGameData.iA;
    updatedGameData["state"] = newGameData.st;
    updatedGameData["startTime"] = newGameData.gT;
    updatedGameData["time"] =
      newGameData.iL === 1
        ? calcGameTime(newGameData.st, newGameData.gT, newGameData.spC)
        : newGameData.gT;
    // updatedGameData['second'] = newGameData.eventSecond;

    if (updatedGameData["state"] === "FT") {
      updatedGameData["time"] = "End";
      updatedGameData["endTime"] = new Date().getTime() + 1000 * 60;
    }

    // if(newGameData.eventID == '23312087') console.log(newGameData.eventSecond);

    // if (newGameData.stoppageTime) {

    //   let stoppageTime = newGameData.stoppageTime;
    //   stoppageTime = stoppageTime.split("|");

    //   if (stoppageTime[0] === newGameData.st) {
    //     updatedGameData['stoppageTime'] = stoppageTime[1];
    //   } else {
    //     updatedGameData['stoppageTime'] = '';
    //   }
    // } else {
    //   updatedGameData['stoppageTime'] = '';
    // }

    updatedGameData["stoppageTime"] = "";
    updatedGameData["danger_state"] = newGameData.dangerState;

    updatedGameData["score_set"] = newGameData.scS;
    updatedGameData["score_set_calculated"] = calcScoreSet(
      "",
      newGameData.st,
      newGameData.scS,
      newGameData.spC
    );

    updatedGameData["current_server"] = newGameData.cuS;

    switch (newGameData.spC) {
      case "FB":
        stateInfo = calcFootballStateInfo(
          newGameData.dangerState,
          newGameData.varState,
          false
        );

        // if (updatedGameData['stateTimeDelay'] && new Date().getTime() < updatedGameData['stateTimeDelay']) {
        //   // do nothing
        // } else {
        //   updatedGameData['stateIcon'] = stateInfo ? stateInfo.stateIcon : '';
        //   updatedGameData['stateBackground'] = stateInfo ? stateInfo.stateBackground : '';
        //   updatedGameData['stateText'] = stateInfo ? stateInfo.stateText : '';
        //   updatedGameData['stateTeam'] = stateInfo ? stateInfo.stateTeam : '';
        //   updatedGameData['stateTimeDelay'] = '';

        //   if (['goal', 'shot', 'pole'].includes(stateInfo.stateText)) {
        //     if (!updatedGameData['stateTimeDelay']) {
        //       updatedGameData['stateTimeDelay'] = new Date().getTime() + 1000 * 10;
        //     }
        //   }
        // }

        let infoFlag = false;

        if (updatedGameData["red_home"] !== newGameData.reH) {
          infoFlag = true;
          updatedGameData["infoIcon"] = "football-state-icon-red-card";
          updatedGameData["infoText"] = "red_card";
          updatedGameData["infoTimeDelay"] = new Date().getTime() + 10000;
          updatedGameData["infoTeam"] = "home";
        }

        if (updatedGameData["red_away"] !== newGameData.reA) {
          infoFlag = true;
          updatedGameData["infoIcon"] = "football-state-icon-red-card";
          updatedGameData["infoText"] = "red_card";
          updatedGameData["infoTimeDelay"] = new Date().getTime() + 10000;
          updatedGameData["infoTeam"] = "away";
        }

        if (updatedGameData["yellow_home"] !== newGameData.yeH) {
          infoFlag = true;
          updatedGameData["infoIcon"] = "football-state-icon-yellow-card";
          updatedGameData["infoText"] = "yellow_card";
          updatedGameData["infoTimeDelay"] = new Date().getTime() + 10000;
          updatedGameData["infoTeam"] = "home";
        }

        if (updatedGameData["yellow_away"] !== newGameData.yeA) {
          infoFlag = true;
          updatedGameData["infoIcon"] = "football-state-icon-yellow-card";
          updatedGameData["infoText"] = "yellow_card";
          updatedGameData["infoTimeDelay"] = new Date().getTime() + 10000;
          updatedGameData["infoTeam"] = "away";
        }

        // if (updatedGameData['substitutions_home'] !== newGameData.substitutionsHome) {
        //   infoFlag = true;
        //   updatedGameData['infoIcon'] = 'football-state-icon-substitution';
        //   updatedGameData['infoText'] = 'substitution';
        //   updatedGameData['infoTimeDelay'] = new Date().getTime() + 10000;
        //   updatedGameData['infoTeam'] = 'home';
        // }

        // if (updatedGameData['substitutions_away'] !== newGameData.substitutionsAway) {
        //   infoFlag = true;
        //   updatedGameData['infoIcon'] = 'football-state-icon-substitution';
        //   updatedGameData['infoText'] = 'substitution';
        //   updatedGameData['infoTimeDelay'] = new Date().getTime() + 10000;
        //   updatedGameData['infoTeam'] = 'away';
        // }

        // if (updatedGameData['var_state'] !== newGameData.varState) {
        //   infoFlag = true;
        //   updatedGameData['infoIcon'] = 'football-state-icon-var';
        //   updatedGameData['infoText'] = 'var';
        //   updatedGameData['infoTimeDelay'] = new Date().getTime() + 10000;
        // }

        if (infoFlag === false) {
          // check infoTimeDelay;

          if (new Date().getTime() >= updatedGameData["infoTimeDelay"]) {
            updatedGameData["infoIcon"] = "";
            updatedGameData["infoTimeDelay"] = "";
          }
        }
        break;
      case "TE":
        stateInfo = calcTennisStateInfo(newGameData.cuS);
        updatedGameData["stateIcon"] = stateInfo ? stateInfo.stateIcon : "";
        updatedGameData["stateBackground"] = "";
        updatedGameData["stateText"] = "";
        updatedGameData["stateTeam"] = "";
        break;
      default:
        updatedGameData["stateIcon"] = "";
        updatedGameData["stateBackground"] = "";
        updatedGameData["stateText"] = "";
        updatedGameData["stateTeam"] = "";
        break;
    }

    if (newGameData.spC === "TE" && newGameData.iL === 1) {
      let scoreSets = newGameData.scS;
      scoreSets = scoreSets.split("|");
      const scoreData = scoreSets[scoreSets?.length - 1];
      const scoreHome = scoreData.split(":")[0];
      const scoreAway = scoreData.split(":")[1];

      if (scoreHome !== updatedGameData["score_home"]) {
        updatedGameData["score_home_updated"] = true;
        updatedGameData["score_home"] =
          parseInt(scoreHome) === 50 ? "A" : scoreHome;
      } else {
        updatedGameData["score_home_updated"] = false;
      }

      if (scoreAway !== updatedGameData["score_away"]) {
        updatedGameData["score_away_updated"] = true;
        updatedGameData["score_away"] =
          parseInt(scoreAway) === 50 ? "A" : scoreAway;
      } else {
        updatedGameData["score_away_updated"] = false;
      }
    } else {
      if (newGameData.spC === "FB") {
        if (updatedGameData["score_home"] !== newGameData.scH) {
          updatedGameData["stateIcon"] = "football-state-icon-home-goal";
          updatedGameData["stateText"] = "goal";
          updatedGameData["stateTeam"] = "home";
          updatedGameData["stateTimeDelay"] = new Date().getTime() + 20000;

          updatedGameData["score_home_updated"] = true;
        } else {
          updatedGameData["score_home_updated"] = false;
        }

        if (updatedGameData["score_away"] !== newGameData.scA) {
          updatedGameData["stateIcon"] = "football-state-icon-away-goal";
          updatedGameData["stateText"] = "goal";
          updatedGameData["stateTeam"] = "away";
          updatedGameData["stateTimeDelay"] = new Date().getTime() + 20000;

          updatedGameData["score_away_updated"] = true;
        } else {
          updatedGameData["score_away_updated"] = false;
        }
      }

      updatedGameData["score_home"] = newGameData.scH;
      updatedGameData["score_away"] = newGameData.scA;
    }

    updatedGameData["var_state"] = newGameData.varState;
    updatedGameData["red_home"] = newGameData.reH;
    updatedGameData["red_away"] = newGameData.reA;
    updatedGameData["yellow_home"] = newGameData.yeH;
    updatedGameData["yellow_away"] = newGameData.yeA;
    updatedGameData["corners_home"] = newGameData.coH;
    updatedGameData["corners_away"] = newGameData.coA;
    // updatedGameData['substitutions_home'] = newGameData.substitutionsHome;
    // updatedGameData['substitutions_away'] = newGameData.substitutionsAway;

    // updatedGameData['total_odds'] = newGameData.aL;

    // updatedGameData['betStatus'] = newGameData.bSt;

    // updatedGameData['game_code'] = newGameData.eventCode;
    updatedGameData["sport_code"] = newGameData.spC;
    updatedGameData["leagueID"] = newGameData.lId;

    // updatedGameData['isOfferedLive'] = newGameData.isOfferedLive;
    // updatedGameData['isVisibleLive'] = newGameData.isVisibleLive;

    updatedGameData["ts"] = new Date().getTime();
  }

  return updatedGameData;
};

/**
 * update odd when new data from SSE service
 */

export const updateOdd = (game, gameID, odd, newOdds) => {
  let selectionIDs = odd["s"]?.split(",");
  let isActives = odd["iA"]?.split(",");
  let prices = odd["p"]?.split(",");
  let priceChanges = [];
  let priceChangesLifeTime;

  let plusIndex = 0;
  if (selectionIDs?.length < 3) {
    plusIndex = 1;
  }

  let filteredOdds = newOdds.filter(
    (item) =>
      item.gId === gameID &&
      item.m === odd.m &&
      item.hc === odd.hc &&
      selectionIDs.indexOf(item.s.toString()) > -1
  );

  if (filteredOdds?.length) {
    filteredOdds?.forEach((item) => {
      let index = selectionIDs.indexOf(item.s.toString());
      isActives[index] = item.iA;

      if (parseFloat(item.p) > parseFloat(prices[index])) {
        // price was increased
        priceChanges[index + plusIndex] = ">";
      }

      if (parseFloat(item.p) < parseFloat(prices[index])) {
        // price was decreased
        priceChanges[index + plusIndex] = "<";
      }

      if (parseFloat(item.p) === parseFloat(prices[index])) {
        // price was decreased
        priceChanges[index + plusIndex] = "=";
      }

      prices[index] = item.p;
      odd.iF = item.iF;
      odd.iL = item.iL;
    });

    odd["iA"] = isActives.join(",");
    odd["p"] = prices.join(",");
    odd["priceChanges"] = priceChanges;
    // odd['priceChangesLifeTime'] = new Date().getTime() + 5000;
  }

  // check if there are new hcs odds from SSE
  // 1 - if gameId, marketId, selectionId and hc does not exist it's a new odd
  // 2 - add them in game odds store

  let availableNewOdds = [];

  newOdds?.forEach((newOdd) => {
    if (
      newOdd.st === "pre" ||
      [1, 8, 18].indexOf(newOdd.m) === -1 ||
      newOdd.gId !== gameID
    )
      return;

    let isExistingArray = game.odds.filter(
      (odd) =>
        newOdd.m === odd.m &&
        newOdd.hc === odd.hc &&
        odd["s"]?.split(",").indexOf(newOdd.s.toString()) > -1
    );

    if (isExistingArray?.length === 0) {
      // this means new odds
      // please add it
      availableNewOdds.push(newOdd);
    }
  });

  if (availableNewOdds?.length) {
    // find same hc odds
    let allHcsValues = [];
    availableNewOdds?.forEach((odd) => {
      allHcsValues.push(odd.hc);
    });

    const hcs = [...new Set(allHcsValues)];

    hcs?.forEach((hc) => {
      let targets = availableNewOdds.filter((odd) => odd.hc === hc);

      targets.sort(function (a, b) {
        var keyA = parseInt(a.s),
          keyB = parseInt(b.s);
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });

      let newlyFormattedOdd = {};
      let p = [];
      let s = [];
      let iA = [];

      targets?.forEach((item) => {
        p.push(item.p);
        s.push(item.s);
        iA.push(item.iA);
      });

      newlyFormattedOdd["hc"] = targets[0].hc;
      newlyFormattedOdd["iA"] = iA.join(",");
      newlyFormattedOdd["s"] = s.join(",");
      newlyFormattedOdd["p"] = p.join(",");
      newlyFormattedOdd["m"] = targets[0].m;

      game.odds.push(newlyFormattedOdd);

      let updatedOdds = game.odds;
      let allMs = [];
      let sortedCorrectOdds = [];

      updatedOdds?.forEach((odd) => {
        allMs.push(odd.m);
      });

      let uniqueMs = [...new Set(allMs)];

      uniqueMs.sort(function (a, b) {
        return a - b;
      });

      uniqueMs?.forEach((m) => {
        // no need to sort if marketId is for over/under
        let mTargets = updatedOdds.filter((odd) => odd.m === m);
        if (m !== 18) {
          mTargets.sort(function (a, b) {
            var keyA = a.hc,
              keyB = b.hc;
            // Compare the 2 dates
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0;
          });
        }

        sortedCorrectOdds = sortedCorrectOdds.concat(mTargets);
      });

      game.odds = sortedCorrectOdds;
    });

    // console.log(game.team1 + ' - ' + game.team2)
    // console.log(availableNewOdds);
    // console.log('===================');
  }
};
/**
 * update OutrightOdd when new data from SSE service
 */

export const updateOutrightOdd = (game, gameID, odd, newOdds) => {
  let selectionID = odd.s;
  let priceChange = null;
  const filteredOdd = newOdds.find(
    (item) =>
      item.gId === gameID &&
      item.m === odd.m &&
      item.hc === odd.hc &&
      selectionID === item.s
  );

  if (filteredOdd) {
    const elementsRaise = document.querySelectorAll(".odds-raise");
    const elementsDrop = document.querySelectorAll(".odds-drop");
    if (parseFloat(filteredOdd.p) > parseFloat(odd.p)) {
      // price was increased
      elementsRaise?.forEach((element) => {
        element.classList.remove("odds-raise");
      });
      priceChange = ">";
    }

    if (parseFloat(filteredOdd.p) < parseFloat(odd.p)) {
      // price was decreased
      elementsDrop?.forEach((element) => {
        element.classList.remove("odds-drop");
      });
      priceChange = "<";
    }

    if (parseFloat(filteredOdd.p) === parseFloat(odd.p)) {
      // price was not changed
      priceChange = "=";
    }
    const allOdds = [...game?.odds];
    const filteredOdds = allOdds.filter((item) => item.s !== odd.s);
    const oddClone = { ...odd };
    oddClone["iF"] = filteredOdd.iF;
    oddClone["iL"] = filteredOdd.iL;
    oddClone["iA"] = filteredOdd.iA;
    oddClone["p"] = filteredOdd.p;
    oddClone["priceChange"] = priceChange;
    if (filteredOdd?.isNonRunner === 0 || filteredOdd?.isNonRunner === 1) {
      oddClone["nr"] = filteredOdd?.isNonRunner;
    }
    // odd['priceChangesLifeTime'] = new Date().getTime() + 5000;
    filteredOdds.push(oddClone);
    game.odds = filteredOdds;
  }
  // check if there are new hcs odds from SSE
  // 1 - if gameId, marketId, selectionId and hc does not exist it's a new odd
  // 2 - add them in game odds store
  let availableNewOdds = [];
  newOdds?.forEach((newOdd) => {
    if (
      newOdd.st === "pre" ||
      [1, 8, 18].indexOf(newOdd.m) === -1 ||
      newOdd.gId !== gameID
    )
      return;

    let isExistingArray = game.odds.filter(
      (odd) => newOdd.m === odd.m && newOdd.hc === odd.hc && odd.s === newOdd.s
    );

    if (isExistingArray?.length === 0) {
      // this means new odds
      // please add it
      availableNewOdds.push(newOdd);
    }
  });

  if (availableNewOdds?.length) {
    // find same hc odds
    let allHcsValues = [];
    availableNewOdds?.forEach((odd) => {
      allHcsValues.push(odd.hc);
    });

    const hcs = [...new Set(allHcsValues)];

    hcs?.forEach((hc) => {
      let targets = availableNewOdds.filter((odd) => odd.hc === hc);

      targets.sort(function (a, b) {
        var keyA = parseInt(a.s),
          keyB = parseInt(b.s);
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });

      let newlyFormattedOdd = {};
      let p;
      let s;
      let iA;

      targets?.forEach((item) => {
        p = item.p;
        s = item.s;
        iA = item.iA;
      });

      newlyFormattedOdd["hc"] = targets[0].hc;
      newlyFormattedOdd["iA"] = iA;
      newlyFormattedOdd["s"] = s;
      newlyFormattedOdd["p"] = p;
      newlyFormattedOdd["m"] = targets[0].m;
      if (filteredOdd?.isNonRunner === 0 || filteredOdd?.isNonRunner === 1) {
        newlyFormattedOdd["nr"] = filteredOdd.isNonRunner;
      }

      game.odds.push(newlyFormattedOdd);

      let updatedOdds = game.odds;
      let allMs = [];
      let sortedCorrectOdds = [];

      updatedOdds?.forEach((odd) => {
        allMs.push(odd.m);
      });
      let uniqueMs = [...new Set(allMs)];

      uniqueMs.sort(function (a, b) {
        return a - b;
      });

      uniqueMs?.forEach((m) => {
        // no need to sort if marketId is for over/under
        let mTargets = updatedOdds.filter((odd) => odd.m === m);
        if (m !== 18) {
          mTargets.sort(function (a, b) {
            var keyA = a.hc,
              keyB = b.hc;
            // Compare the 2 dates
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0;
          });
        }

        sortedCorrectOdds = sortedCorrectOdds.concat(mTargets);
      });
      game.odds = sortedCorrectOdds;
    });

    // console.log(game.team1 + ' - ' + game.team2)
    // console.log(availableNewOdds);
    // console.log('===================');
  }
};

/**
 * Add updated game state and return it
 */

export const addStateItem = (game, stateList) => {
  let item = {
    ts: game.ts,
    gameTime: game.time,
    gameId: game.gameid,
    state: game.state,
    startTime: game.startTime,
    sportCode: game.sportCode,
    team1: game.team1,
    team2: game.team2,
    team1Color1: game.team1Color1,
    team1Color2: game.team1Color2,
    team2Color1: game.team2Color1,
    team2Color2: game.team2Color2,
    stateTeam: game.stateTeam,
    stateTeamName: game.stateTeam
      ? game.stateTeam.replace("home", game.team1).replace("away", game.team2)
      : null,
    stateIcon: game.stateIcon,
    stateBackground: game.stateBackground,
    stateText: game.stateText,
    ...game,
  };

  let itemsInHistory = stateList ? Object.keys(stateList)?.length : 0;
  let alreadyInHistory = false;
  let lastGameStateChecked = false;

  if (itemsInHistory === 0) {
    stateList.push(item);
    return;
  } else {
    stateList
      .slice()
      .reverse()
      ?.forEach((stateHistoryItem) => {
        if (lastGameStateChecked) return;
        if (stateHistoryItem.gameId === game.gameid) {
          lastGameStateChecked = true;

          if (
            stateHistoryItem.stateIcon !== game.stateIcon ||
            stateHistoryItem.stateText !== game.stateText ||
            stateHistoryItem.stateTeam !== game.stateTeam
          ) {
            stateList.push(item);
            if (itemsInHistory === 4) stateList.shift();

            alreadyInHistory = true;
            return;
          }
        }
      });

    if (!alreadyInHistory && !lastGameStateChecked) {
      stateList.push(item);
      if (itemsInHistory === 4) stateList.shift();
    }
  }

  return stateList;
};
