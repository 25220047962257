/**
 * Block user for specific days
 * User can be blocked for 1, 7, 30 days
 */
import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withStyles } from "@mui/styles";
import { Translate } from "react-redux-i18n";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import PasswordField from "../Inputs/PasswordInput";

import { blockUser, signOut } from "../../actions";
import { toggleMyTicketsPanel } from "../../redux-store/settings-reducer";
import { generateDatabetTokenAsync } from "../../redux-store/databet-reducer";
import RFSelectInput from "../Inputs/SelectInput";
import withRouter from "../../helpers/withRouter";

const styles = (theme) => ({
  root: {
    "& .MuiContainer-root": {
      padding: theme.spacing(2),
      width: "100%",
    },
    "& .MuiFormControl-root": {
      margin: "1em 0",
      width: "100%",
    },
    "& .MuiButton-root": {
      margin: "1em 1em 0 0",
      // background:
      //   "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
    },
  },
  close_acc_container: {
    padding: "10px 0",
    marginTop: "20px",
  },
  close_acc_content: {
    fontSize: 16,
    color: "var(--yellow-1)",
    textAlign: "center",
  },
});

class UserBlock extends React.PureComponent {
  componentDidMount() {
    const { auth, locale, navigate } = this.props;
    if (!auth.isSignedIn) navigate(`/${locale}/sign-in`);
  }

  componentDidUpdate() {
    const { auth, locale, navigate } = this.props;
    if (!auth.isSignedIn) navigate(`/${locale}/sign-in`);
  }

  signOutCallback = () => {
    this.props.toggleMyTicketsPanel(false);
    this.props.generateDatabetTokenAsync({ isAuthenticated: false, lang: this.props.locale });
  };

  onSubmit = (formValues) => {
    this.props.blockUser(formValues, this.constructor.name, this.signOutCallback);
    this.signOut.bind(this);
  };

  signOut = () => {
    this.props.signOut(this.constructor.name, true, this.signOutCallback);
  };

  render() {
    log(this.constructor.name, "render");
    const { translate, locale, classes } = this.props;
    const blockDays = [1, 3, 7];

    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)} className={classes.root} autoComplete="off">
        <div>
          <Container maxWidth="md">
            <Typography variant="body2" component="span">
              <Translate value="labels.account_block" />
            </Typography>
            <Field name="password" classes={classes} component={PasswordField} label={translate.labels.password} variant="standard" />
            <Field classes={classes} name="coolOffDays" component={RFSelectInput} label={translate.labels.period} placeholder="" variant="standard">
              <MenuItem value="" disabled>
                {translate.labels.please_select}&hellip;
              </MenuItem>
              {blockDays.map((key, index) => {
                let days = key === 1 ? translate.labels.days_singular : translate.labels.days_plural;
                return (
                  <MenuItem value={key} key={index}>
                    {key} {days}{" "}
                  </MenuItem>
                );
              })}
            </Field>
            <Button type="submit" variant="contained" size="small" color="primary">
              <Translate value="labels.block_acct_btn" />
            </Button>
            <div className={classes.close_acc_container}>
              <h4 className="text-align-center">
                <Translate value="labels.close_account" />
              </h4>
              {locale === "et" ? (
                <p className={classes.close_acc_content}>
                  Kui soovite oma mängukonto jäädavalt sulgeda, palun pea meeles, et mängukonto sulgemist ei saa tühistada mis tahes asjaoludel ja te ei tohi hiljem uut mängukontot avada. Samuti saate esmalt oma
                  mängimist juhtida, kasutades näiteks mängupiiranguid. Kui tunned, et sul on probleeme mängimise juhtimisega siis sulgege oma konto jäädavalt ja võtke meiega viivitamatult ühendust aadressil
                  support@nubet.com.
                </p>
              ) : locale === "fi" ? (
                <p className={classes.close_acc_content}>
                  Jos haluat sulkea pelitilisi pysyvästi niin huomioi, että pelitilin sulkemista ei voida peruuttaa eikä uutta pelitiliä avata myöhemmin. Voit hallita pelaamistasi ensisijaisesti käyttämällä vastuullisia
                  pelityökaluja, kuten pelirajoja. Kuitenkin, jos sinulla on ongelmia pelaamisen hallinnassa ja haluat sulkea tilisi pysyvästi, ota meihin yhteyttä välittömästi osoitteessa support@nubet.com.
                </p>
              ) : (
                <p className={classes.close_acc_content}>
                  If you want to close your game account permanently, please remember that closing the game account cannot be canceled under any circumstances and you may not open a new game account later. You can also
                  control your gaming first by making use of responsible gaming tools, such as game limits. If you feel that you have problems with the control of playing and you want to close your account permanently,
                  please contact us immediately at support@nubet.com.
                </p>
              )}
            </div>
          </Container>
        </div>
      </form>
    );
  }
}

const validate = (values, props) => {
  const errors = {};

  const requiredFields = ["coolOffDays", "password"];

  requiredFields?.forEach((field) => {
    if (!values[field]) {
      errors[field] = props.translate.labels.field_required;
    }
  });

  return errors;
};

const mapStateToProps = (state) => ({
  auth: state.user.auth,
  translate: state.i18n.translations[state.i18n.locale],
  locale: state.i18n.locale,
});

export default withRouter(
  connect(mapStateToProps, { blockUser, signOut, toggleMyTicketsPanel, generateDatabetTokenAsync })(
    reduxForm({
      form: "UserBlock",
      validate,
      enableReinitialize: true,
    })(withStyles(styles)(UserBlock))
  )
);
