/**
 * User information form component
 */
import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import { withStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Translate } from "react-redux-i18n";
import format from "date-fns/format";

import countryConfig from "../../config/countryConfig";
import TextFieldWithCheck from "../Inputs/TextFieldWithCheck";
import CustomDatePicker from "../Inputs/DatePicker";
import RFSelectInput from "../Inputs/SelectInput";

const styles = (theme) => ({
  root: {
    "& .MuiContainer-root": {
      padding: theme.spacing(2),
      paddingBottom: 0,
      width: "100%",
    },
    "& .MuiFormControl-root": {
      margin: "0.3em 0",
      width: "100%",
    },
    // '& .MuiGrid-container': {
    //   width: "100%",
    // },
    "@media (hover:hover)": {
      "& .MuiFormControl-root": {
        margin: "1em 1em 0 0",
        width: "60% !important",
      },
      // '& .MuiGrid-container': {
      //   width: "60% !important",
      // },
      "& .MuiContainer-root": {
        padding: theme.spacing(3),
      },
      backgroundColor: "var(--color-main)",
    },
  },
  hidden: {
    width: "0 !important",
    height: "0 !important",
    visibility: "hidden",
  },
  legal: {
    fontSize: "10em",
  },
  greenColor: {
    color: "green",
  },
  smallFont: {
    fontSize: "13px",

    "& .MuiFormControlLabel-label": {
      fontSize: "13px",
    },
  },
});

class UserUpdateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recaptchaVerified: false,
      errMsgs: [],
      step: this.props.step,
    };
  }

  goTop = () => {};

  componentDidMount() {
    if (typeof this.props.onRef === "function") {
      this.props.onRef(this);
    }

    if (this.props.content !== "update") {
      this.props.initialize({
        countryCode: "EN",
        nationality: "EN",
        birthCountry: "EN",
      });
    }
  }
  componentWillUnmount() {
    if (typeof this.props.onRef === "function") {
      this.props.onRef(undefined);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // var flag = false;

    if (this.state.recaptchaVerified !== nextState.recaptchaVerified) {
      // flag = true;
    }

    if (this.state.errMsgs !== nextState.errMsgs) {
      // flag = true;
    }

    if (this.props.lang !== nextProps.lang) {
      // flag = true;
    }

    if (this.state.step !== nextState.step) {
      if (this.props.content !== "update") {
        this.props.initialize({
          countryCode: "EN",
          nationality: "EN",
          birthCountry: "EN",
        });
      }

      // flag = true;
    }

    // return flag;
    return true;
  }

  radioButton = ({ input, ...rest }) => {
    const { translate, content } = this.props;

    return (
      <FormControl>
        <RadioGroup row {...input} {...rest}>
          <FormControlLabel value="Female" control={<Radio color="primary" />} label={translate.labels.female} disabled={content === "update"} />
          <FormControlLabel value="Male" control={<Radio color="primary" />} label={translate.labels.male} disabled={content === "update"} />
        </RadioGroup>
      </FormControl>
    );
  };

  renderFromHelper = ({ touched, error }) => {
    if (!(touched && error)) {
      return;
    } else {
      return <FormHelperText>{touched && error}</FormHelperText>;
    }
  };

  /**
   * Check all fields are valid
   * And submit updated user information
   */

  onSubmit = (formValues) => {
    let flag = true;
    let { errMsgs } = this.state;
    errMsgs = [];

    if (!formValues.address1) {
      flag = false;
      errMsgs.push("missing_address");
    }

    if (!formValues.zip) {
      flag = false;
      errMsgs.push("missing_postal_code");
    }

    if (!formValues.city) {
      flag = false;
      errMsgs.push("missing_city");
    }

    if (!formValues.countryCode) {
      flag = false;
      errMsgs.push("missing_country");
    }

    this.setState({ errMsgs }, () => {
      if (flag) {
        this.props.onSubmit(formValues);
      }
    });
  };

  renderProfileData() {
    const { classes, translate, content, locale } = this.props;

    return (
      <div>
        <Container maxWidth="xl">
          <Field name="password" component={TextFieldWithCheck} classes={classes} label={translate.labels.password} className={content === "update" ? classes.hidden : null} type={"password"} />
          <Field name="passwordConfirm" component={TextFieldWithCheck} classes={classes} label={translate.labels.confirm_pass} className={content === "update" ? classes.hidden : null} type={"password"} />
          <Field name="email" component={TextFieldWithCheck} classes={classes} label={translate.labels.email} disabled={content === "update"} />
          <Field name="emailConfirm" component={TextFieldWithCheck} classes={classes} label={translate.labels.confirm_mail} className={content === "update" ? classes.hidden : null} />

          <Field name="sex" component={this.radioButton} style={{ textAlignLast: "left" }}>
            <Radio value="Male" label={translate.labels.male} />
            <Radio value="Female" label={translate.labels.female} />
          </Field>
          <Field name="firstName" component={TextFieldWithCheck} classes={classes} label={translate.labels.form_fname} disabled={content === "update"} />
          <Field name="lastName" component={TextFieldWithCheck} classes={classes} label={translate.labels.form_lname} disabled={content === "update"} />
          <Field name="dob" component={CustomDatePicker} label={translate.labels.form_} disabled={content === "update"} classes={classes} />
          <Field name="address1" component={TextFieldWithCheck} classes={classes} label={translate.labels.street} disabled={content === "update"} />
          <Field name="zip" component={TextFieldWithCheck} classes={classes} label={translate.labels.postal} disabled={content === "update"} />
          <Field name="city" component={TextFieldWithCheck} classes={classes} label={translate.labels.city} disabled={content === "update"} />
          <Field name="countryCode" component={RFSelectInput} label={translate.labels.country} variant="standard" native disabled={content === "update"}>
            {countryConfig.allowedResidenceCountries.map((key) => {
              return (
                <option key={key[0]} value={countryConfig.getTripple(key[0])} disabled={key[0] === "---"}>
                  {key[1]}
                </option>
              );
            })}
          </Field>
          <Field name="phoneNumber" component={TextFieldWithCheck} classes={classes} label={translate.labels.mobile} disabled={content === "update"} />
          <Field name="refCode" component={TextFieldWithCheck} classes={classes} label={translate.labels.ref_code} className={content === "update" ? classes.hidden : null} />
          <br></br>
          <br></br>
          <br></br>

          <Grid container spacing={1}>
            <Grid item xs={"auto"} sm={3}></Grid>
            <Grid item xs={"auto"} sm={3}></Grid>
          </Grid>

          <Typography style={{ fontSize: "1.2em", color: "var(--color-contrast" }} component="span">
            <Link to={`/${locale}/info/terms-conditions`} onClick={this.goTop.bind(this)}>
              <Translate value="legal.terms_conditions_link" />
            </Link>{" "}
            |{" "}
            <Link to={`/${locale}/info/privacy-policy`} onClick={this.goTop.bind(this)}>
              <Translate value="legal.privacy_policy_link" />
            </Link>{" "}
          </Typography>
        </Container>
      </div>
    );
  }

  submitForm = () => {
    document.getElementById("userUpdateSubmitButton").click();
  };

  render() {
    log(this.constructor.name, "render");
    const { classes } = this.props;

    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)} className={classes.root} autoComplete="off" id="userUpdateForm">
        <div>{this.renderProfileData()}</div>

        {this.state.errMsgs && this.state.errMsgs?.length
          ? this.state.errMsgs.map((msg, key) => (
              <div
                style={{
                  fontSize: "1rem",
                  color: "var(--red)",
                  marginBottom: 3,
                }}
                key={key}
              >
                <Translate value={`labels.${msg}`} />
              </div>
            ))
          : null}

        <Button type="submit" variant="contained" size="small" color="primary" className={classes.hidden} id="userUpdateSubmitButton" disabled>
          <Translate value="labels.btn_save" />
        </Button>
      </form>
    );
  }
}

const validate = (values, props) => {
  const errors = {};

  const requiredFields = ["countryCode", "address1", "zip", "city", "phoneNumber"];

  requiredFields?.forEach((field) => {
    if (!values[field]) {
      errors[field] = props.translate.labels.field_required;
    }
  });
  return errors;
};

const mapStateToProps = (state, ownProps) => {
  return {
    translate: state.i18n.translations[state.i18n.locale],
    content: ownProps.content,
    step: ownProps.step,
    lang: ownProps.lang,
    formValues: getFormValues("UserUpdateForm")(state),
    locale: state.i18n.locale,
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: "UserUpdateForm",
    validate,
  })(withStyles(styles)(UserUpdateForm))
);
