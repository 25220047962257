/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconGO = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g><path d="M1580.4,1067.1l-240.5,251.5l-265.9-278l0-0.1l769.2-804.1l49.8-52.1l-74-77.4l-819,856.3L181,107.1l-74,77.3l819,856.2
		l-265.8,277.9l-240.5-251.4c-71.4-74.6-187.6-74.6-259,0c-71.4,74.6-71.4,196.1,0,270.8l92.5,96.7c122.4,128,321.6,128,444,0
		l27.5-28.8l0,0L1000,1118l278.5,291.2l0,0l24.4,25.5c122.4,128,321.6,128,444,0l92.5-96.7c71.4-74.6,71.4-196.1,0-270.8
		C1768,992.5,1651.8,992.5,1580.4,1067.1z"/>
					<rect x="947.7" y="1810.1" width="104.6" height="164.1" />
					<ellipse cx="1000" cy="1536.6" rx="157" ry="164.1" /></g>
			</svg>
		</div>
	);
};

export default IconGO;
