/**
 * Reducer for Alert notification
 */

import { SET_ALERT, CLEAR_ALERT } from "../actions/types";

const INTIAL_STATE = {};

const fn = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case SET_ALERT:
      // return { message: action.payload };
      let payload = action.payload;
      
      if (action.from) {
        payload.from = action.from;
      }

      return payload;

    case CLEAR_ALERT:
      return INTIAL_STATE;

    default:
      return state;
  }
};

export default fn;