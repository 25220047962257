import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-redux-i18n";
import { handleUpdateAlert } from "../../../actions";

const BetBuilderUpdateAlert = ({ handleConfirm }) => {
  const shouldAlert = useSelector((state) => state.update.shouldAlert);
  const payload = useSelector((state) => state.update.data);
  const dispatch = useDispatch();

  const closeUpdatealert = () => {
    dispatch(handleUpdateAlert());
  };


  const acceptChanges = () => {
    handleConfirm();
    closeUpdatealert();
  }

  return (
    <Dialog
      open={shouldAlert}
      onClose={closeUpdatealert}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        <Translate value="labels.price_changed" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div>
            <Translate value="labels.old_price" />
            <span className="color-odds">{" "}{payload?.price}</span>
          </div>
        </DialogContentText>
        <DialogContentText sx={{my: 2}} id="alert-dialog-description">
          <div>
            <Translate value="labels.new_price" />
            <span className="color-odds">{" "}{payload?.newPrice}</span>
          </div>
        </DialogContentText>
        <DialogContentText id="alert-dialog-description">
          <div>
            <Translate value="labels.accept_price_change" />
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeUpdatealert} color="primary">
          <Translate value="labels.button_decline" />
        </Button>
        <Button onClick={acceptChanges} color="primary" autoFocus>
          <Translate value="labels.button_accept" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BetBuilderUpdateAlert.propTypes = {
  handleConfirm: PropTypes.func,
};

BetBuilderUpdateAlert.defaultProps = {
  handleConfirm: () => {},
};

export default BetBuilderUpdateAlert;
