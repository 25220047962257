/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconDA = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g><path d="M1462,723l-76.4,76.4c35.8,65.9,56.2,141,56.2,221.1c0,254.8-207.5,462.3-462.3,462.3s-462.3-207.5-462.3-462.3
		s207.5-462.3,462.3-462.3c80.1,0,155.1,20.4,221.1,56.2L1277,538c-86.6-53.6-188.4-84.5-297.5-84.5
		c-312.6,0-566.9,254.3-566.9,566.9s254.3,566.9,566.9,566.9s566.9-254.3,566.9-566.9C1546.5,911.3,1515.6,809.6,1462,723z
		 M1249.8,935.4L1158,1027c-3.4,95.7-81.9,172.1-178.4,172.1c-98.6,0-178.7-80-178.7-178.7c0-96.5,76.4-175,172.1-178.4l91.8-91.6
		c-26.9-8.6-55.5-13.3-85.3-13.3c-156.2,0-283.3,127.1-283.3,283.3s127.1,283.3,283.3,283.3s283.3-127.1,283.3-283.3
		C1262.9,990.9,1258.4,962.1,1249.8,935.4z M1719.6,576.5l-76.4,76.4c60.4,108.8,95,234.1,95,367.6c0,418.3-340.4,758.7-758.7,758.7
		s-758.7-340.4-758.7-758.7c0-418.3,340.4-758.7,758.7-758.7c133.4,0,258.7,34.5,367.6,95l76.4-76.4
		c-129.8-78.2-281.8-123.2-444-123.2c-476.1,0-863.3,387.2-863.3,863.3s387.2,863.3,863.3,863.3s863.3-387.2,863.3-863.3
		C1842.9,858.2,1797.9,706.2,1719.6,576.5z M1606.3,578.7l-99.9-11.1l-492.1,492.1c-20.4,20.4-53.6,20.4-74,0
		c-20.4-20.4-20.4-53.6,0-74l492.1-492.1l-11.1-99.9l277.5-277.5l37,148l148,37L1606.3,578.7z"/></g>
			</svg>
		</div>
	);
};

export default IconDA;
