/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconGF = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g>
					<path d="M1450.6,412.9c85.9,0,155.6-69.7,155.6-155.6c0-85.9-69.7-155.6-155.6-155.6c-86,0-155.6,69.7-155.6,155.6
		C1295,343.2,1364.6,412.9,1450.6,412.9z"/>
					<path d="M1664.7,661.7c-0.7-28.4-16.3-52.8-38.8-66.7l0.5-0.6l-516.8-349.2l-0.7-0.5l-7.1-4.8l-0.2,0.9c-6.8-3.5-14-6.5-22-8
		l-389.1-74.9c-31.2-6-63,6.8-81.3,32.8L465.2,395.3c-10,14.3-14.6,30.7-14.5,46.9c3.1-0.2,6.2-0.5,9.4-0.5
		c22.1,0,44.2,3.3,65.5,9.8c26.4,8,50.2,21,71.2,37.1c0.1-0.1,0.3-0.2,0.3-0.4l114.1-162l337.9,65.1c3.6,0.7,7.1,0.8,10.6,1
		L929.6,862.8c-4.3,9-7.6,18.8-8.8,29.3l-16.4,143.7L870,1007c-39-32.7-97.2-27.5-129.9,11.5c-32.7,39-27.5,97.2,11.5,129.9
		l127.7,106.9l-13,114.2l-264.8,384.3c-28.9,41.9-18.3,99.4,23.6,128.3c16,11,34.2,16.3,52.3,16.3c29.3,0,58.1-13.9,76-39.9
		l277.9-403.3c8.6-12.5,14-26.8,15.7-41.9l1.8-16.2l44.8,37.5c16.9,14.1,37.9,21.5,59.2,21.5c11.3,0,22.6-2,33.4-6.3
		c31.3-12.2,53.6-40.5,58-73.8l51.9-391.4L1505,737.9l15.6,659.7c1,43.9,37,78.8,80.6,78.8c0.6,0,1.3,0,1.9,0
		c44.6-1.1,79.8-38,78.8-82.6L1664.7,661.7z M1115.1,946.4l-31.7,239.2l-9.5-8l27.7-242.4l14.8,6
		C1116,943,1115.4,944.6,1115.1,946.4z"/>
					<path d="M468.2,467.5c-86,0-155.6,69.7-155.6,155.6c0,86,69.7,155.6,155.6,155.6c85.9,0,155.6-69.7,155.6-155.6
		C623.8,537.2,554.2,467.5,468.2,467.5z"/>
				</g>
			</svg>
		</div>
	);
};

export default IconGF;
