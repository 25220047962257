import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import PropTypes from "prop-types";

const RFSelectInput = ({
  input,
  label,
  meta: { touched, error },
  children,
  variant,
  ...custom
}) => {
  return (
    <FormControl fullWidth error={touched && error}>
      <InputLabel variant={variant} htmlFor="color-native-simple">
        {label}
      </InputLabel>
      <Select {...input} {...custom} variant={variant} labelId="color-native-simple">
        {children}
      </Select>
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

RFSelectInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
  classes: PropTypes.object,
  variant: PropTypes.string,
  label: PropTypes.string,
};

RFSelectInput.defaultProps = {
  input: {
    onChange: () => {},
    value: null,
  },
  meta: {
    touched: false,
    invalid: false,
    error: undefined,
  },
  classes: {},
  variant: "standard",
  label: "",
};

export default RFSelectInput;
