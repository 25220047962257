/**
 * SlotsRules - English version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import Obfuscate from "react-obfuscate";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class SlotsRules extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>NUBET.COM CASINO RULES</h1>
            English version 1.1 Last update January 27th, 2023.<br></br>
            <br></br>
            <h3 id="General">1. GENERAL INFORMATION</h3>
            1.1. Casino organizer Nubet and the website Nubet.com is operated by Vana Lauri OÜ, a company registered in Estonia under company registration number 16427120 having its registered address at Roseni 10-135,
            10111 Tallinn, Estonia (hereinafter referred to as <b>“Nubet”</b> or <b>“The Company”</b>). Vana Lauri OÜ is licensed and supervised by the Estonian Tax and Customs Board and is holding the following
            licenses: HKT000066 (date of issue: 10.07.2022) and HKT000067 (date of issue: 10.07.2022).<br></br>
            <br></br>
            1.2. These Nubet Casino Rules apply to Nubet’s Casino on the website Nubet.com and govern the provision of casino games played with real money provided by Nubet on its website Nubet.com (hereinafter referred
            also as <b>“Nubet Casino”</b>). If the player does not agree with these Casino Rules and Nubet’s Terms and Conditions, they should immediately stop using Nubet Casino.<br></br>
            <br></br>
            1.3. When playing a game at Nubet Casino the player must acknowledge that products are consumed immediately and the Company cannot return, cancel or refund products during the game or afterwards. Nubet Casino
            offers games of chance where the risk of loss falls entirely for the player when he or she decides to play the games of chance provided on the Nubet Casino.<br></br>
            <br></br>
            1.4. Nubet Casino reserves the right to restrict or block a player's access to Nubet Casino's games, offers, or services without explanation at the company's sole discretion.<br></br>
            <br></br>
            1.5. If you have any questions about Nubet Casino, please contact <Obfuscate email={"support@nubet.com"}>support@nubet.com</Obfuscate>.<br></br>
            <br></br>
            <h3 id="Rules">2. RULES FOR THE NUBET CASINO</h3>
            2.1. The software related to, or which enables use of Nubet Casino games and other services, offered to the player by Nubet Casino, may be provided partially or completely through a third-party provider that
            has a contractual relationship with Nubet. Software is protected by copyright laws and intellectual property protection. The player may use the software only for personal and recreational purposes in
            accordance with all applicable laws, rules, and regulations.<br></br>
            <br></br>
            2.2. When playing jackpot games at Nubet Casino, the player is aware that the jackpot display does not necessarily every single time correspond to the actual offered jackpot amount due to, for example, a
            delay in updating the amount. Therefore, the displayed value may not be 100% accurate. After the jackpot has been won, the exact amount will be calculated according to the number of rounds played until to the
            moment when the jackpot is won.<br></br>
            <br></br>
            2.3. Under no circumstances may the player use any type of error, malfunction, virus, bug, defect, or inconsistencies in the software used at Nubet Casino for his/her own benefit or to the detriment of other
            players. The player must report such an incident to customer service immediately after the discovery.<br></br>
            <br></br>
            2.4. Breach of Nubet Casino Rules or Nubet Terms and Conditions applicable to the Nubet Casino may lead to disqualification of the player from participating in the games, promotions, bonus offers or special
            offers without further notice. If Nubet determines that funds won by the player came from an account that has obtained these winnings fraudulently and/or in breach of these Nubet Casino Rules or Nubet Terms
            and Conditions, Nubet reserves the right to withhold or confiscate those funds/winnings. All players involved in any suspected fraudulent activity may be reported to the relevant authorities.<br></br>
            <br></br>
            2.5. Nubet Casino offers its players a possibility to play games in the so-called play for fun mode without a real money stake and without a deposit requirement or with real money mode, for which the player
            must have deposited funds and for which the game account must have sufficient funds to play the game round with the selected stake.<br></br>
            <br></br>
            2.6. Funds can be deposited at Nubet Casino by using payment methods offered by Nubet Casino, which can be found under “Deposit” in the menu.<br></br>
            <br></br>
            2.7. Funds can be withdrawn at Nubet Casino by requesting a pay-out and choosing available withdrawal method, which can be found under “Withdrawal” in the menu. The available withdrawal method may depend on
            the used deposit method.<br></br>
            <br></br>
            2.8. Detailed game instructions can be found in the game description, which is available after opening the game at Nubet Casino.<br></br>
            <br></br>
            <h3 id="Disclaimer">3. DISCLAIMERS</h3>
            3.1. At Nubet Casino, the use of digital aids such as robots or mechanical, electronic, or other devices to automatically make game decisions is prohibited. The company has the right to suspend the game
            account, suspend deposits and withdrawals, and to confiscate all related winnings, if the use of such an aid or device is suspected. The described situation can also lead to legal action.<br></br>
            <br></br>
            <h3 id="Severability">4. SEVERABILITY</h3>
            4.1. Each clause of these Nubet Casino Rules is separate and severable from all others. If any clause is found to be void, invalid, or unenforceable for any reason, the other clauses will remain in full force
            and effect.<br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(SlotsRules));
