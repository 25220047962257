/**
 * Reducer for leagues
 ** favorite leagues, tree view leagues
 */

import {
    SET_LEAGUES,
    SAVE_FAVORITE_LEAGUES,
    SAVE_TREEVIEW_LEAGUES,
    UPDATE_LEAGUE_FILTER,
    SAVE_BETBUILDER_GAMES,
    UPDATE_BETBUILDER_GAMES,
    GET_PLAYERPROPS_GATALOG_GAMES,
} from '../actions/types';
import { calcGame, checkGameSession } from '../helpers/gameFunctions';

const INITIAL_STATE = {
  favorites: {
    // leagues: {}
  },
  treeViewLeagues: {},
};

let stateCopy, favoriteLeaguesFromCookie, gamesList;
const fn = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LEAGUES:
      return action.leagues;

    case SAVE_FAVORITE_LEAGUES:
      stateCopy = { ...state };
      // favoriteLeaguesFromCookie = Cookies.getJSON('favoriteLeagues');
      favoriteLeaguesFromCookie = localStorage.getItem("favoriteLeagues")
        ? JSON.parse(localStorage.getItem("favoriteLeagues"))
        : {};

      return { ...stateCopy, favorites: favoriteLeaguesFromCookie };

    case SAVE_TREEVIEW_LEAGUES:
      stateCopy = { ...state };

      return { ...stateCopy, treeViewLeagues: action.payload };

    case UPDATE_LEAGUE_FILTER:
      stateCopy = { ...state };
      stateCopy._leagueFilter = action.payload;

      return stateCopy;
    case SAVE_BETBUILDER_GAMES:
      stateCopy = { ...state };
      const payload = { ...action.payload };
      let games = payload.games;
      const leagueId = action.leagueId;
      const sportCode = action.sportCode;

      games = games.map((eachGame) => {
        const returnedGame = checkGameSession(eachGame, "all");
        if (returnedGame) {
          const calculatedGame = calcGame(returnedGame);
          return calculatedGame;
        }
        return eachGame;
      });
      
      if (stateCopy?.betbuilder) {    
        stateCopy["betbuilder"][sportCode] = stateCopy["betbuilder"][
          sportCode
        ]?.map((league) => {
          if (Number(league.leagueId) === Number(leagueId)) {
            return { ...league, games: games };
          }
          return league;
        });
      }
      return stateCopy;
    case UPDATE_BETBUILDER_GAMES:
        stateCopy = { ...state };
        const gamesList = [...action.payload];
        if (stateCopy?.betbuilder) {
          gamesList?.forEach((selectedGame) => {
            stateCopy["betbuilder"][selectedGame["spC"]] = stateCopy[
              "betbuilder"
            ][selectedGame["spC"]].map((league) => {
              const shouldUpdate = league["leagueId"] === selectedGame["lId"];
              if (shouldUpdate) {
                let leagueCopy = { ...league };
                const lgGames = [...leagueCopy.games];
                if (selectedGame["st"] === "FT") {
                  leagueCopy["games"] = lgGames.filter(
                    (game) => game.gameid !== selectedGame["gId"]
                  );
                }
                return leagueCopy;
              }
              return league;
            });
          });
        }
        return stateCopy;
        case UPDATE_BETBUILDER_GAMES:
            stateCopy = { ...state };
            gamesList = [...action.payload];
            if (stateCopy?.betbuilder) {
                gamesList.forEach((selectedGame) => {
                    stateCopy["betbuilder"][selectedGame["spC"]] = stateCopy["betbuilder"][selectedGame["spC"]].map((league) => {
                        const shouldUpdate = league["leagueId"] === selectedGame["lId"];
                        if (shouldUpdate) {
                            let leagueCopy = { ...league };
                            const lgGames = [...leagueCopy.games];
                            if (selectedGame["st"] === "FT") {
                                leagueCopy["games"] = lgGames.filter((game) => game.gameid !== selectedGame["gId"]);
                            } 
                            return leagueCopy;
                        };
                        return league;
                   })
                })
            }
            return stateCopy;
        case GET_PLAYERPROPS_GATALOG_GAMES:
            stateCopy = { ...state };
            let loadedGames = action.payload.games;
            loadedGames = loadedGames.map((eachGame) => {
                const returnedGame = checkGameSession(eachGame, "all");
                if (returnedGame) {
                    const calculatedGame = calcGame(returnedGame);
                    return calculatedGame;
                }
                return eachGame;
            });
            if (stateCopy?.playerprops) {
                let leaguesList = stateCopy.playerprops[action.sportCode];
                leaguesList = leaguesList.map((league) => {
                    if (league.leagueId === action.leagueId) {
                        return { ...league, games: loadedGames };
                    }
                    return league;
                });
                stateCopy.playerprops[action.sportCode] = leaguesList;
            }
            return stateCopy;
        default:
            return state;
    }
};

export default fn;
