/**
 *  Component for showing game momentum widget from widgets.sir.sportradar.com service
 */
 
import React from 'react';
import './theme.css';

const Momentum = (props) => {
  React.useEffect(() => {
    window.SIR("addWidget", ".sr-widget-10", "match.momentum", {
      matchId: props.gameId,
      disableWidgetHeader: true,
      chartStyle: 'thinBars'
    });
  }, [props.gameId]);

  return (
    <div className="widgets">
      <div><div className="sr-widget sr-widget-10"></div></div>
    </div>
  );
}

export default Momentum;