/**
 * Payment component ( for deposit and withdraw )
 */

import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import  { withStyles } from "@mui/styles";
import PaymentsDeposit from "./PaymentsDeposit";
import PaymentsWithdraw from "./PaymentsWithdraw";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import withRouter from "../../helpers/withRouter";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  root: {
    "& .MuiTextField-root": {
      width: "100%",
    },
    "& .MuiTypography-paragraph": {
      margin: "1em 0",
    },
    "& .MuiTabs-root": {
      minHeight: "3.2em",
    },
    "& .MuiTab-root": {
      fontSize: "1.2em",
      minHeight: "1em",
      textTransform: "uppercase !important",
      color: "var(--grey)",
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    flexGrow: 1,
    width: "100%",
    position: "sticky",
    zIndex: 999,
    top: 0,
    "@media (hover:hover)": {
      backgroundColor: "var(--color-main)",
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      fontWeight: "bold",
      color: "var(--color-contrast)",
    },
  },
  menuItems: {
    flexGrow: 1,
    width: "100%",
    position: "fixed",
    bottom: "66px",
    zIndex: 1,
  },
});

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const parseQueryString = function (str) {
  let objURL = {};

  str.replace(
    new RegExp("([^?=&]+)(=([^&]*))?", "g"),
    function ($0, $1, $2, $3) {
      objURL[$1] = $3;
    }
  );
  return objURL;
};

class Payments extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      open: false,

      op: "",
      type: "",
      method: "",
      transactionStatus: "",
    };
  }

  componentDidMount() {
    const { auth, locale, navigate } = this.props;
    if (!auth.isSignedIn) navigate(`/${locale}/sign-in`);

    if (
      auth.app_tld === ".de" &&
      auth.autoIdentStatus &&
      auth.autoIdentStatus !== "Completed"
    ) {
      this.setState({ open: true });
    }

    /**
     * Get payment deposit status from link ( OK, DECLINED, FAIL )
     */

    let location = parseQueryString(this.props.location.search);
    if (location.op) {
      // need to parse transaction status to correct component
      const op = location.op;
      const type = location["amp;type"];
      const method = location["amp;method"];
      const params = decodeURIComponent(location["params"]);
      const transactionStatus =
        params.indexOf("OK") > -1
          ? "success"
          : params.indexOf("DECLINED") > -1
          ? "declined"
          : "fail";

      this.setState({ op, type, method, transactionStatus });
    }
  }

  componentDidUpdate() {
    const { auth, locale, navigate } = this.props;
    if (!auth.isSignedIn) navigate(`/${locale}/sign-in`);
  }

  renderTabContent = () => {
    /**
     * Show payment deposit or withdraw component
     */
    const { op, type, method, transactionStatus } = this.state;

    switch (this.state.value) {
      case 0:
        return (
          <PaymentsDeposit
            op={op}
            type={type}
            method={method}
            transactionStatus={transactionStatus}
          />
        );
      case 1:
        return (
          <PaymentsWithdraw
            op={op}
            type={type}
            method={method}
            transactionStatus={transactionStatus}
          />
        );
      default:
        return;
    }
  };

  menuItemChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  menuItemChangeIndex = (newValue) => {
    this.setState({ value: newValue });
  };

  handleAutoIdentification = () => {
    window.open(this.props.auth.autoIdentUrl, "_self");
  };

  render() {
    log(this.constructor.name, "render");
    const { classes } = this.props;
    const menuItems = [
      <Translate value={`labels.deposit_title`} />,
      <Translate value={`labels.withdraw_title`} />,
    ];

    return (
      <div>
        <div className={classes.root}>
          <AppBar
            position="static"
            style={{ backgroundColor: "var(--bg-tabs-1)" }}
            elevation={0}
          >
            <Tabs
              value={this.state.value}
              onChange={this.menuItemChange.bind(this)}
              variant="scrollable"
              indicatorColor="primary"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {menuItems.map((item, key) => (
                <Tab key={key} value={key} label={item} {...a11yProps(key)} />
              ))}
            </Tabs>
          </AppBar>
          {this.renderTabContent()}
        </div>

        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            style={{ color: "var(--color-contrast)" }}
            id="alert-dialog-slide-title"
          >
            <Translate value="labels.identity_verification" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Translate value="labels.AutoIdentityNotCompletedText" />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleAutoIdentification.bind(this)}
              variant="outlined"
              color="primary"
              size="small"
            >
              <Translate value="labels.auto_identification" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.user.auth,
    locale: state.i18n.locale
  };
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(Payments)));
