/**
 * Component for game compact avatar svg
 */

import React from 'react';

const GameBoxCompactAvatar = (props) => {


    switch (props.pattern) {

        case "1":
            return (
                <div>
                    <svg className="" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="1.3em" height="1.3em"
                        viewBox="0 0 260 260" style={{ enableBackground: "new 0 0 260 260" }} xmlSpace="preserve">

                        <g>
                            <g>
                                <g>
                                    <path fill={props.color1} d="M218.1,92.6c-0.1-0.7-0.1-1.2-0.2-2.1c0-0.4-0.1-0.9-0.2-1.6c0-0.3-0.1-0.8-0.1-1.3c0-0.4-0.1-1-0.2-1.6
       c-0.1-0.6-0.2-1.3-0.3-1.8c-0.1-1.1-0.2-1.9-0.3-2.6c-0.1-0.7-0.2-1.2-0.2-1.8c-0.1-0.8-0.1-1.4-0.2-2c-0.1-0.7-0.2-1.4-0.3-2.4
       c-0.1-1.2-0.3-2.4-0.5-3.6c-0.2-1.2-0.4-2.4-0.6-3.8c-0.2-1-0.3-1.9-0.5-2.8c-0.1-0.9-0.3-1.8-0.5-3c-0.2-1.4-0.3-2.3-0.5-3.4
       c-0.2-1-0.4-2.2-0.8-4.3c-0.2-0.9-0.3-1.7-0.5-2.6c-0.2-1-0.5-2.2-1.1-3.9c-0.2-0.7-0.5-1.5-0.7-2.4c-0.3-0.8-0.6-1.6-0.8-2.3
       c-0.3-0.6-0.4-1.1-0.6-1.6c-0.2-0.4-0.3-0.7-0.5-1.1c-0.3-0.6-0.5-1-0.7-1.3c-0.2-0.3-0.3-0.6-0.6-1c-0.1-0.3-0.3-0.6-0.4-0.8
       c-0.2-0.3-0.4-0.6-0.5-0.8c-0.2-0.3-0.4-0.6-0.6-0.9c-0.2-0.3-0.5-0.6-0.6-0.8c-0.3-0.3-0.5-0.7-0.7-0.9
       c-0.2-0.2-0.4-0.5-0.7-0.8c-0.3-0.3-0.6-0.7-0.9-1.1c-0.3-0.4-0.7-0.8-1.1-1.2c-0.3-0.3-0.6-0.6-1-0.8c-0.3-0.3-0.7-0.5-1.1-0.9
       c-0.4-0.3-1.1-0.6-1.8-0.9c-0.6-0.3-1.2-0.6-1.5-0.7c-0.5-0.2-0.9-0.3-1.4-0.5c-0.4-0.2-0.9-0.3-1.3-0.5
       c-1.1-0.4-2.1-0.8-3.2-1.3c-1-0.4-2-0.8-2.9-1.2c-1.5-0.6-3.2-1.1-4.8-1.5c-1.6-0.4-3.4-0.8-5.1-1.2c-2.4-0.6-4.8-1.1-7.2-1.6
       c-2.4-0.5-4.8-0.9-7.2-1.4c-0.7-0.1-1.4-0.2-2.1-0.3c-0.7-0.1-1.4-0.1-2.1-0.2c-0.1,0-0.5,0-1-0.1c-0.4,0-0.8,0-1.3,0
       c0,0,0,0,0,0c-0.1-0.1-0.2-0.1-0.4-0.2c-0.1-0.1-0.2-0.1-0.2-0.1c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.5-0.3
       c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.4-0.3-0.6-0.4c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.2-0.7-0.5-1-0.7c-0.1,0-0.1-0.1-0.1-0.1
       c-0.3-0.2-0.6-0.4-0.9-0.6c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.1-0.4-0.3-0.6-0.5c-0.1,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.2
       c-0.1-0.1-1.8-1-2.5-1.2c-0.7-0.2-1.4-0.4-1.6-0.4c-1.3-0.3-2.9-0.5-4.6-0.7c-0.8-0.1-1.6-0.2-2.4-0.2c-0.9-0.1-1.8-0.2-2.7-0.3
       c-2.2-0.2-4.4-0.3-6.4-0.4c-2-0.1-3.8-0.1-5.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-1.4,0-3.1,0-5.1,0.1c-2,0.1-4.2,0.2-6.3,0.4
       c-0.9,0.1-1.8,0.2-2.7,0.3c-0.8,0.1-1.6,0.2-2.4,0.2c-1.8,0.2-3.3,0.4-4.6,0.7c-0.2,0.1-0.9,0.2-1.6,0.4c-0.7,0.2-2.4,1-2.5,1.2
       c-0.1,0.1-0.2,0.2-0.3,0.2c0,0-0.1,0.1-0.1,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.4,0.2-0.6,0.4c-0.3,0.2-0.6,0.4-0.8,0.6
       c0,0-0.1,0-0.1,0.1c-0.3,0.2-0.7,0.5-1,0.7c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.3,0.2-0.5,0.3
       c-0.1,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.5,0.2-0.7,0.3c0-0.1-0.1-0.1-0.1-0.2
       c-0.4,0-0.9,0-1.2,0c-0.5,0-0.9,0.1-1,0.1c-0.7,0.1-1.4,0.1-2.1,0.2c-0.7,0.1-1.4,0.2-2.1,0.3c-2.4,0.4-4.8,0.9-7.2,1.4
       c-2.4,0.5-4.8,1.1-7.2,1.6c-1.7,0.4-3.4,0.8-5.1,1.2c-1.7,0.4-3.3,0.9-4.8,1.5c-1,0.4-2,0.8-3,1.2c-1,0.4-2.1,0.8-3.2,1.3
       c-0.4,0.2-0.9,0.3-1.3,0.5c-0.4,0.2-0.9,0.3-1.3,0.5c-0.3,0.1-0.9,0.3-1.5,0.7c-0.6,0.3-1.3,0.6-1.7,0.9
       c-0.5,0.3-0.8,0.6-1.2,0.9c-0.3,0.2-0.6,0.5-1,0.8c-0.4,0.4-0.8,0.8-1.1,1.2c-0.3,0.4-0.6,0.8-0.9,1.1c-0.3,0.3-0.5,0.6-0.7,0.8
       c-0.2,0.3-0.4,0.6-0.7,0.9c-0.2,0.2-0.4,0.5-0.6,0.8c-0.2,0.3-0.4,0.6-0.6,0.9c-0.1,0.2-0.3,0.6-0.5,0.8
       c-0.1,0.3-0.3,0.6-0.4,0.8c-0.2,0.4-0.4,0.7-0.6,1c-0.1,0.3-0.3,0.7-0.7,1.3c-0.2,0.4-0.3,0.7-0.5,1.1c-0.1,0.4-0.3,0.9-0.6,1.6
       c-0.3,0.6-0.6,1.4-0.8,2.3c-0.2,0.8-0.5,1.7-0.7,2.4c-0.6,1.7-0.9,2.9-1.1,3.9c-0.2,0.9-0.3,1.7-0.5,2.6
       c-0.4,2.1-0.6,3.3-0.8,4.3c-0.2,1.1-0.3,1.9-0.5,3.4c-0.2,1.2-0.3,2.1-0.5,3c-0.2,0.9-0.3,1.8-0.5,2.8c-0.2,1.4-0.4,2.6-0.6,3.8
       c-0.2,1.2-0.3,2.3-0.5,3.6c-0.1,1.1-0.2,1.8-0.3,2.4c-0.1,0.6-0.2,1.2-0.2,2c0,0.6-0.1,1.1-0.2,1.8c-0.1,0.7-0.2,1.5-0.4,2.6
       c-0.1,0.5-0.2,1.2-0.2,1.8c-0.1,0.6-0.2,1.2-0.2,1.6c0,0.5-0.1,1-0.1,1.3c-0.1,0.7-0.1,1.2-0.2,1.6c-0.1,0.9-0.1,1.4-0.2,2.1
       c0,0.2,0,0.4-0.1,0.6c0,0.2,0,0.3,0,0.5c0.9,0.9,1.8,1.7,2.9,2.4c1,0.7,2.1,1.4,3.3,1.9c1.3,0.7,2.6,1.2,3.9,1.7
       c1.3,0.5,2.6,0.9,3.8,1.3c0.8,0.2,1.6,0.5,2.3,0.7c0.7,0.2,1.4,0.4,2,0.6c1.4,0.4,2.8,0.7,4.1,1c1.3,0.2,2.6,0.4,3.7,0.4
       c0.9,0.1,1.8,0.1,2.6,0.1c0.8,0,1.6,0,2.3,0c0.4,0,0.7,0,1.1-0.1c0.3,0,0.7,0,1,0c0.3,0,0.6,0,0.8-0.1c0.2,0,0.5,0,0.7,0
       c0,0,0.4,1.3,0.9,2.8c0.4,1.6,0.9,3.5,1,4.7c0.1,1.6,0.1,3.2,0.2,4.8c0.1,1.6,0.1,3.2,0.1,4.9c0.1,2.2,0.1,4.5,0.2,6.8
       c0.1,2.2,0.1,4.5,0.1,6.8c0,1.1,0,2.2,0,3.3c0,1.1,0,2.2,0,3.3c0,0.9,0,1.7,0,2.6c0,0.9,0,1.8,0,2.6c0,3.4,0,6.7-0.1,10.1
       c0,3.4-0.1,6.8-0.2,10.1c0,2.4-0.1,4.8-0.2,7.1c0,2.3-0.1,4.7-0.2,6.9c0,2-0.1,4.1-0.2,6.1c-0.1,2-0.1,4.1-0.2,6
       c-0.1,2.8-0.2,5.5-0.3,8.3c-0.1,2.8-0.3,5.5-0.4,8.3c-0.1,2.9-0.3,5.9-0.4,8.8c-0.1,2.9-0.3,5.9-0.4,8.8c0,1.1-0.1,2-0.1,2.9
       c0,0.9-0.1,1.7-0.1,2.4c0,0.5,0,1,0,1.4c0,0.4,0,0.8,0,1.1c0.3,0.1,0.6,0.3,1,0.4c0.3,0.1,0.6,0.3,0.9,0.5c0.8,0.3,1.6,0.7,2.4,1
       c0.8,0.3,1.6,0.6,2.4,0.9c1.3,0.4,2.7,0.9,4.1,1.3c1.4,0.4,2.8,0.7,4.2,1c3.2,0.7,6.6,1.2,10.1,1.6c1.2,0.1,2.4,0.2,3.7,0.3
       c2.2,0.2,4.5,0.3,6.8,0.4c1,0.1,2.1,0.1,3.1,0.1c1,0,2.1,0,3.2,0.1c0.4,0,0.7,0,1.1,0c0.3,0,0.7,0,1.1,0c0.7,0,1.4,0,2.1,0
       c0.7,0,1.4,0,2.1,0c1.8,0,3.8,0,5.8,0c2,0,4,0,6,0c1.9,0,3.9,0,6,0c2,0,4,0,5.8,0c0.7,0,1.4,0,2.2,0c0.7,0,1.4,0,2.1,0
       c0.4,0,0.7,0,1,0c0.3,0,0.7,0,1,0c1.1,0,2.1,0,3.2-0.1c1.1-0.1,2.1-0.1,3.2-0.1c2.3-0.1,4.6-0.2,6.8-0.4c1.3-0.1,2.5-0.2,3.7-0.3
       c3.5-0.4,6.9-0.9,10.1-1.6c1.4-0.3,2.8-0.6,4.2-1c1.4-0.4,2.7-0.8,4.1-1.3c0.8-0.3,1.6-0.6,2.4-0.9c0.8-0.3,1.6-0.7,2.4-1
       c0.3-0.1,0.6-0.3,0.9-0.5c0.3-0.1,0.6-0.3,0.9-0.4c0-0.3,0-0.7,0-1.1c0-0.4-0.1-0.9-0.1-1.4c0-0.7-0.1-1.5-0.1-2.4
       c0-0.9-0.1-1.9-0.1-2.9c-0.1-2.9-0.3-5.9-0.4-8.8c-0.1-2.9-0.3-5.9-0.4-8.8c-0.1-2.8-0.2-5.5-0.3-8.3c-0.1-2.7-0.2-5.5-0.4-8.3
       c-0.1-2-0.2-4-0.2-6c-0.1-2-0.1-4.1-0.2-6.1c-0.1-2.3-0.1-4.6-0.2-6.9c-0.1-2.3-0.1-4.7-0.2-7.1c-0.1-3.4-0.1-6.8-0.2-10.1
       c0-3.4,0-6.7-0.1-10.1c0-0.9,0-1.8,0-2.6c0-0.9,0-1.7,0-2.6c0-1.1,0-2.2,0-3.3c0-1.1,0-2.2,0-3.3c0-2.3,0.1-4.5,0.1-6.8
       c0-2.3,0.1-4.5,0.2-6.8c0-1.6,0.1-3.3,0.1-4.9c0.1-1.6,0.1-3.2,0.2-4.8c0.1-1.3,0.5-3.2,0.9-4.7c0.5-1.6,0.9-2.8,0.9-2.8
       c0.2,0,0.4,0,0.7,0c0.2,0,0.5,0,0.8,0.1c0.3,0,0.6,0,0.9,0c0.3,0.1,0.7,0.1,1.1,0.1c0.7,0,1.5,0,2.3,0c0.8,0,1.7,0,2.6-0.1
       c1.2-0.1,2.4-0.2,3.7-0.4c1.3-0.2,2.7-0.6,4.1-1c0.6-0.2,1.3-0.4,2-0.6c0.7-0.2,1.5-0.5,2.3-0.7c1.2-0.4,2.5-0.8,3.8-1.3
       c1.3-0.5,2.6-1.1,3.9-1.7c1.1-0.6,2.2-1.2,3.3-1.9c1.1-0.7,2-1.5,2.9-2.4c0-0.1,0-0.3,0-0.5C218.1,93,218.1,92.8,218.1,92.6z
        M104.9,33.2c-0.2-0.1-0.5-0.3-0.8-0.4C104.4,32.9,104.6,33.1,104.9,33.2z"/>
                                    <g>
                                        <path fill={props.color2} d="M92.4,19.4c0.1,0.3,0.3,0.7,0.5,1c0.1,0.4,0.3,0.7,0.5,1c0.8,1.7,1.8,3.2,3,4.7c0.2,0.2,0.4,0.5,0.6,0.7
         c1.4,1.5,3,3,5,4.4c2.9,2.1,6.7,3.4,10.3,4.3c3.7,0.8,7.1,1.2,9.5,1.4c0.5,0,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.2,0c0,0,0,0,0,0
         c0.4,0,0.9,0,1.3,0c0.5,0,0.9,0,1.4-0.1c2.3-0.1,5.8-0.5,9.4-1.3c3.6-0.8,7.4-2.1,10.3-4.2c2-1.4,3.7-2.9,5.1-4.4
         c0.2-0.2,0.4-0.5,0.7-0.7c1.2-1.4,2.2-2.9,3-4.6c0.1-0.3,0.3-0.7,0.5-1c0.2-0.3,0.3-0.7,0.4-0.9c0.1-0.3,0.2-0.6,0.3-0.8
         c0.1-0.2,0.1-0.5,0.2-0.8c-0.2-0.1-0.5-0.3-0.8-0.4c-0.1,0-0.2-0.1-0.2-0.1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.5-0.3
         c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.3-0.6-0.4c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.2-0.6-0.5-1-0.7c0,0-0.1-0.1-0.1-0.1
         c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.1-0.4-0.3-0.6-0.5c-0.1-0.1-0.1-0.1-0.2-0.1
         c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.5-0.4c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0
         c-0.2-0.1-0.9-0.4-1.6-0.6c-0.7-0.2-1.4-0.4-1.6-0.4c-1.3-0.3-2.9-0.5-4.6-0.7c-0.8-0.1-1.5-0.2-2.4-0.2
         c-0.9-0.1-1.8-0.2-2.7-0.3c-2.2-0.2-4.4-0.3-6.4-0.4c-1.9-0.1-3.7-0.1-5-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
         c-1.3,0-3.1,0-5,0.1c-2,0.1-4.2,0.2-6.3,0.4c-0.9,0.1-1.8,0.2-2.7,0.3c-0.8,0.1-1.6,0.2-2.3,0.2c-1.8,0.2-3.3,0.5-4.6,0.7
         c-0.2,0.1-0.9,0.2-1.6,0.4c-0.6,0.1-1.1,0.4-1.4,0.5c0,0,0-0.1,0-0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.1-0.1,0.1-0.2,0.2
         c-0.2,0.1-0.3,0.2-0.5,0.4c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.4
         c-0.3,0.2-0.6,0.4-0.9,0.5c0,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.6,0.4-1,0.7c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.4
         c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0-0.1,0.1-0.2,0.1c-0.3,0.1-0.6,0.3-0.8,0.4
         c0,0.3,0.1,0.5,0.2,0.7C92.2,18.8,92.3,19.1,92.4,19.4z M103.6,19c0.3-0.1,0.7-0.1,1.1-0.2c1.3-0.3,2.6-0.5,3.9-0.6
         c1.3-0.1,2.7-0.2,4-0.4c1.9-0.1,3.9-0.2,5.8-0.3c2-0.1,3.9-0.1,5.8-0.1c2,0,3.9,0,5.9,0.1c2,0,3.9,0.1,5.9,0.3
         c1.3,0.1,2.7,0.2,4,0.4c1.3,0.1,2.7,0.3,4,0.6c0.4,0.1,0.8,0.2,1.1,0.2c0.3,0.1,0.7,0.2,1.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.3
         c-0.1,0.2-0.3,0.4-0.4,0.6c-1.1,1.5-2.4,2.9-3.8,4.1c-2.1,1.8-5,2.9-7.8,3.5c-2.8,0.7-5.6,1-7.5,1.1c-0.3,0-0.7,0-1.1,0
         c-0.3,0-0.7,0-1,0c-0.3,0-0.7,0-1,0c-0.4,0-0.8,0-1.2-0.1c-1.9-0.1-4.7-0.4-7.5-1.1c-2.8-0.7-5.7-1.8-7.8-3.6
         c-1.4-1.2-2.7-2.6-3.8-4.1c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.1-0.1-0.2-0.2-0.3C103,19.1,103.3,19,103.6,19z"/>
                                        <path fill={props.color2} d="M217.2,83.8c0,0-8,12.7-33.9,12.2c0-4.8,0.9-7.8,1.6-12c0.9-6.1,3.2-11.5,2-10c-3,4.1-125.3-0.1-125.3-0.1
         s4.3,17.2,4.5,21.2c0,0.2,0,0.5,0,0.7c-14.7,0.3-23.8-3.7-29.1-7.2c0.6-8.1,1.3-19.2,1.9-22.5c1.5-8.6,4.7-12.5,8.3-13.7
         c5.9-2,9.8,1.9,9.8,1.9l-4.4-26.6c0,0-12.6,2.7-16.8,25.6c-1.3,6.9-3,16.4-3.5,21.5c-0.2,2.6-0.8,5.9-1.1,8.5
         c-0.5,4.3-0.8,7.9-0.9,9.4L30,93.6c0-0.1,0-0.1,0,0c0,0,0,0,0,0c0.1,0.3,0.6,1,2.7,2.3c4.1,3,11.2,5.1,11.2,5.1
         c10.6,4,19.1,2.8,20.7,2.6l2,8.6c0,0,61.1,1.9,94.6,1.1c-1.3,2.9-2.4,6.1-3.4,9.6c-1.1,4-1.8,11.9-2.2,22
         c-1.2,10.3-1.1,28.9-0.6,47c0.2,26.3,0.9,49.7,0.9,49.7l3.8-0.4c0,0-0.4-76.7,2.4-92.2c1.6-8.6,0.4-11.5,8.1-14.8
         c6.8-2.9,11.1,3.3,11.1,3.3s2.7-31.7,2.5-34c2,0.2,13.4,1,20.5-2.4c0,0,10.8-3.7,13.9-7.4L217.2,83.8z"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
            );

        default:
            return (
                <div>no pattern</div>
            );
    }

};

export default GameBoxCompactAvatar;