import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { getWPContent, getWPNewsContent } from "../../actions";
import fi from "date-fns/locale/fi";
import enAU from "date-fns/locale/en-AU";
import et from "date-fns/locale/et";
import { format } from "date-fns";
import ReactHtmlParser from "react-html-parser";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { newsConfigs } from "../../config/newsConfigs";
import withRouter from "../../helpers/withRouter";

const locales = {
  fi: fi,
  et: et,
  en: enAU,
};


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}



const styles = (theme) => ({
  root: {
    "@media (hover:hover)": {
      backgroundColor: "var(--color-main)",
    },
    "& .MuiTypography-root": {
      fontSize: "1.1em !important",
    },
    "& p": {
      margin: "1em 0 1em",
    },
    "& li": {
      margin: "1em 0",
    },
    "& .MuiDivider-root": {
      backgroundColor: "var(--color-contrast)",
    },
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  postDetail: {
    padding: "1em 3em 1em ",
  },
  postLoadMore: {
    padding: "1em 3em 3em ",
    "@media (hover:none)": {
      textAlignLast: "center",
    },
  },
  postTitle: {
    color: "var(--color-contrast)",
  },
  postDate: {
    color: "var(--color-contrast)",
    fontSize: "0.9em",
  },
  postContent: {
    textAlign: "justify",
  },
  newsTabs: {
    backgroundColor: "var(--color-main)",
    "& .MuiTab-root": {
      minHeight: "1.95rem !important",
      padding: "0.35em 0 !important",
      color: "var(--grey)",
      "@media (hover:hover)": {
        minHeight: "2.3em !important",
        padding: "0.44em 0 !important",
        fontSize: "1.2em !important",
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  tabSelection: {
    "&.MuiButtonBase-root.MuiTab-root": {
      backgroundColor: "var(--color-slip-tabs)",
      borderRadius: "0.4em",
      height: "1em",
      color: "var(--btn-text)",
      fontSize: "0.9em !important",
      margin: "1em 0.8em",
    },
  },
});

class WPNews extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { locale, search } = this.props;
    let tabActiveValue = new URLSearchParams(search).get("news-feed-index");
    if (!tabActiveValue) tabActiveValue = 0;
    this.props.getWPNewsContent(newsConfigs[locale][tabActiveValue]["id"], this.constructor.name);
  }




  loadMoreData = () => {
    this.props.getWPNewsContent();
  };

  handleTabChange = (event, newValue) => {
    const { locale } = this.props;
    this.props.getWPNewsContent(newsConfigs[locale][newValue]["id"], this.constructor.name);
    this.props.navigate(`/${locale}/platform/news?news-feed-index=${newValue}`)
  }

  render() {
    log(this.constructor.name, "render");
    const { classes, wpContent, locale, search } = this.props;
    
    let tabActiveValue = new URLSearchParams(search).get("news-feed-index");
    if (!tabActiveValue) tabActiveValue = 0;

    return (
      <div className={classes.root}>
        <div className={classes.newsTabs}>
          <Tabs
            value={parseInt(tabActiveValue)}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            aria-label="full width tabs example"
            scrollButtons="auto"
          >
            {newsConfigs[locale]?.map((category, indx) => (
              <Tab
                key={category.id}
                label={category.label}
                {...a11yProps(indx)}
                className={classes.tabSelection}
              />
            ))}
          </Tabs>
        </div>
        <Grid container style={{ padding: "0 0 1em 0" }}>
          {wpContent?.map((item) => {
            const itemDate = new Date(item?.date);
            return (
              <Grid item xs={12} sm={6} md={4}>
                <div key={item.id} className={classes.postDetail}>
                  <Typography className={classes.postTitle} component={"span"}>
                    <h2>{ReactHtmlParser(item.title?.rendered)}</h2>
                  </Typography>
                  {item.featured_media != 0
                    ? [
                        <div>
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              margin: "0.5em 0 0.5em 0",
                            }}
                            src={`https://storage.googleapis.com/nubet-qa-content-news/nbt_news_${item.id}.jpg`}
                            alt="Nubet news"
                          />
                        </div>,
                      ]
                    : null}
                  <Typography
                    className={classes.postContent}
                    component={"span"}
                  >
                    {ReactHtmlParser(item.content?.rendered)}
                  </Typography>
                  <Typography className={classes.postDate} component={"span"}>
                    {format(itemDate, "MMMM, yyyy", {
                      locale: locales[locale],
                    })}
                  </Typography>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const selectedLocale = props?.match?.params?.lang;
  return {
    locale: selectedLocale ? selectedLocale : state.i18n.locale,
    wpContent: state.wpContent.news,
    host: state.settings.host,
    selectedTheme: state.settings.theme,
    search: props.location.search
  };
};

export default withRouter(connect(mapStateToProps, { getWPContent, getWPNewsContent })(
  withStyles(styles)(WPNews)
));
