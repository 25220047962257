/**
 * All host domain configurations
 */

// let host = window.location.hostname;
// console.log(host.replace('.','_'));

const hostConfig = {
  ee_nubet_com: {
    brand: "nubet_et",
    logo: "nubet",
    themes: ["bluedark", "bluelight"],
    bet: true,
    gaming: true,
    crmBrand: "",
    api: "nubet_test",
    deposit: ["trustly"],
    withdraw: ["trustly"],
    title: "NBT",
    description: "",
    keywords: "",
    title_et: "QA EN",
    title_fi: "QA FI",
    title_en: "QA ET",
    description_en: "",
    description_et: "",
    description_fi: "",
    keywords_et: "",
    keywords_fi: "",
    keywords_en: "",
    favicon: "nubet",
    appicon: "nubetappicon",
    pageManifest: "nubetmanifest",
    pageManifest_en: "nubetmanifest_en",
    lightBox: "nubet",
    isPasswordOn: true,
    robots: "nofollow, noindex",
    promoBanner: true,
    promoPage: true,
    bonusSports: false,
    bonusCasino: false,
    sportcast_url: "https://demo-uat-gen2.sportcastlive.com",
    databet_api: "//spa.databet.cloud",
    databet_gql: "//nubet-gql.int.databet.cloud",
  },
  nubet_com: {
    brand: "nubet_et",
    logo: "nubet",
    themes: ["bluedark", "bluelight"],
    bet: true,
    gaming: true,
    crmBrand: "",
    api: "nubet_prod",
    deposit: ["trustly"],
    withdraw: ["trustly"],
    title: "NBT",
    description: "",
    keywords: "",
    title_et: "NUBET - spordikihlvedude kodu",
    title_fi: "NUBET vedonlyönti - Parhaat kertoimet lajista riippumatta!",
    title_en: "NUBET - The home of sportsbetting",
    description_en: "Lightning-fast registration, best odds, good limits and fantastic casino Jackpots. Nubet – Magic of the North",
    description_et: "Välkkiire registreerimine, parimad koefitsiendid, head limiidid ja fantastilised kasiino jackpotid. Nubet - Põhja maagia",
    description_fi: "Salamannopea rekisteröityminen vie pelaajan parhaiden vedonlyöntikertoimien äärelle. Nubetin casinolta parhaat jackpotit ja uusimmat kolikkopelit.",
    keywords_et: "",
    keywords_fi: "",
    keywords_en: "",
    favicon: "nubet",
    appicon: "nubetappicon",
    pageManifest: "nubetmanifest",
    pageManifest_en: "nubetmanifest_en",
    lightBox: "nubet",
    isPasswordOn: false,
    robots: "",
    promoBanner: true,
    promoPage: true,
    bonusSports: false,
    bonusCasino: false,
    sportcast_url: "https://public-prod-gen2.sportcastlive.com",
    databet_api: "//spa.databet.cloud",
    databet_gql: "//nubet-gql.databet.cloud",
  },
  localhost: {
    brand: "nubet_et",
    logo: "nubet",
    themes: ["bluedark", "bluelight"],
    bet: true,
    gaming: true,
    crmBrand: "",
    api: "nubet_test",
    deposit: ["trustly"],
    withdraw: ["trustly"],
    title: "Localhost NBT DE",
    description: "",
    keywords: "",
    title_en: "LOCALHOST EN",
    title_fi: "LOCALHOST FI",
    title_et: "LOCALHOST ET",
    description_en: "",
    description_et: "",
    description_fi: "",
    keywords_et: "",
    keywords_fi: "",
    keywords_en: "",
    favicon: "nubetlocalhost",
    appicon: "nubetappicon",
    pageManifest: "nubetmanifest",
    pageManifest_en: "nubetmanifest_en",
    lightBox: "nubet",
    isPasswordOn: false,
    robots: "nofollow, noindex",
    promoBanner: true,
    promoPage: true,
    bonusSports: true,
    bonusCasino: true,
    sportcast_url: "https://demo-uat-gen2.sportcastlive.com",
    databet_api: "//spa.databet.cloud",
    databet_gql: "//nubet-gql.int.databet.cloud",
  },
};

export default hostConfig;
