import authHeader from "../../apis/auth-header";
import api from "../../apis/nubet";
import apiCatalog from "../../apis/nubetCatalog";

// format date

function formatDateToApi(date) {
  const ye = new Intl.DateTimeFormat("de", { year: "numeric" }).format(date);
  const mo = new Intl.DateTimeFormat("de", { month: "2-digit" }).format(date);
  const da = new Intl.DateTimeFormat("de", { day: "2-digit" }).format(date);

  // return (`${da}.${mo}.${ye}`);
  return `${ye}-${mo}-${da}`;
}

export const getTicketGames = async ({ lang, ticketId }) => {
  try {
    const reply = await api.get("account/ticket", {
      params: {
        id: ticketId,
        lang: lang,
      },
      headers: authHeader(),
    });
    return reply.data?.payload && reply.data?.type === "data" ? reply.data.payload : [];
  } catch (error) {
    console.log(error);
  }
};

export const getTickets = async ({ startDate, endDate, status, start, limit, lang, callbacks }) => {
  try {
    let startDateValue = new Date(startDate);
    let endDateValue = new Date(endDate);
    if (status === "pending") {
      startDateValue = new Date();
      startDateValue = startDateValue.setDate(startDateValue.getDate() - 365);
      endDateValue = new Date();
    } else {
      startDateValue = startDateValue.setDate(startDateValue.getDate() - 1);
    }
    startDateValue = formatDateToApi(startDateValue);
    endDateValue = formatDateToApi(endDateValue);
    const reply = await api.get("account/tickets", {
      params: {
        startDate: startDateValue,
        endDate: endDateValue,
        status: status,
        start: start ? start : 0,
        lang: lang ? lang : "fi",
      },
      headers: authHeader(),
    });
    let tickets = reply?.data?.payload?.tickets ? reply?.data?.payload?.tickets : [];
    let pendingTickets = [];
    const ticketsIds = [];
    for (let key in tickets) {
      ticketsIds.push(tickets[key]?.ticketID);
      if (tickets[key].status === "pending") pendingTickets.push(tickets[key].ticketID);
    }
    if (ticketsIds?.length) {
      callbacks.getTicketGames({ ticketId: ticketsIds?.toString(), lang: lang });
    }

    if (pendingTickets?.length > 0) {
      callbacks.getTicket(pendingTickets.join(","));
    }
    return {
      data: reply?.data?.payload?.tickets ? reply?.data?.payload?.tickets : [],
      count: reply.data.payload?.count,
      status: status,
    };
  } catch (error) {
    console.log(error);
  }
};

export const getTicket = async ({ type, ticketId, lang }) => {
  try {
    const reply = await api.get("account/ticket", {
      params: {
        id: ticketId,
        lang: lang,
      },
      headers: authHeader(),
    });
    let payload = {
      tickets: reply.data?.payload,
      type: type,
    };
    return payload;
  } catch (error) {
    console.log(error);
  }
};

export const postTicket = async ({ data, receiptPayload, callback }) => {
  try {
    const reply = await api.post(
      "account/ticket",
      {
        params: {
          data: data,
        },
      },
      { headers: authHeader() }
    );
    callback();
    return {
      shouldAlert: true,
      response: reply.data,
      data: { ...receiptPayload, ...reply.data.payload, date: new Date() },
    };
  } catch (error) {
    return error?.response?.data;
  }
};
