// games by provider
// @react
import { useEffect, useRef } from "react";
// @redux
import { useDispatch, useSelector } from "react-redux";
// router
import { useSearchParams } from "react-router-dom";
// @actions
import { getCasinoGamesByProviderAsync } from "../../../redux-store/casino-reducer";
// @mui
import { Box, Grid2, Typography } from "@mui/material";
// @components
import CasinoGameCard from "../components/CasinoGameCard";

const CasinoProviderFilterView = () => {
  const observerTarget = useRef(null);
  // router handler
  const [searchParams] = useSearchParams();
  // action dispatcher
  const dispatch = useDispatch();
  // route selectors
  const providerId = searchParams.get("provider-id");
  const providerTitle = searchParams.get("provider-title");
  // selectors
  const gamesList = useSelector((state) => state.casino.gamesByProvider.list);
  const currentPage = useSelector((state) => state.casino.gamesByProvider.page);
  const pageSize = useSelector((state) => state.casino.gamesByProvider.pageSize);
  const pageCount = useSelector((state) => state.casino.gamesByProvider.pageCount);

  const getCasinoGames = () => {
    if (!providerId || pageCount + 1 === currentPage) return;
    let params = {};
    params["groupName"] = "all";
    params["providerID"] = providerId;
    params["page"] = currentPage;
    params["size"] = pageSize;
    dispatch(getCasinoGamesByProviderAsync(params));
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          getCasinoGames();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, providerId, currentPage]);

  return (
    <Box>
      <Box>
        <Typography variant="h2" m={2}>
          {providerTitle}
        </Typography>
      </Box>
      <Grid2 container>
        {gamesList.map((gameData) => (
          <Grid2 item size={{ xs: 6, sm: 4, md: 3 }} key={gameData.id}>
            <Box p={1}>
              <CasinoGameCard game={gameData} />
            </Box>
          </Grid2>
        ))}
      </Grid2>
      <Box ref={observerTarget} />
    </Box>
  );
};

export default CasinoProviderFilterView;
