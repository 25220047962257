import GameBoxDetailedAvatarShort from "../@avatars/GameBoxDetailedAvatarShortBB";
import GameBoxDetailedAvatarLong from "../@avatars/GameBoxDetailedAvatarLongBB";
import GameBoxDetailedAvatarBX from "../@avatars/GameBoxDetailedAvatarBX";
import GameBoxDetailedAvatarBB from "../@avatars/GameBoxDetailedAvatarBB";
import GameBoxDetailedAvatarSN from "../@avatars/GameBoxDetailedAvatarSN";
import GameBoxDetailedAvatarTE from "../@avatars/GameBoxDetailedAvatarTEBB";
import GameBoxDetailedAvatarNo from "../@avatars/GameBoxDetailedAvatarNoBB";
import GameBoxDetailedAvatarFBBB from "../@avatars/GameBoxDetailedAvatarFBBB";

const createJersey = (game, teamColor, teamHorStripes, teamNumber, teamSleeve, teamSplit, teamSquares, teamStripes, teamSleeveDetail, sportCode, isTeam1) => {
  const defaultColor = isTeam1 ? "bdbdbd" : "636363";
  const defaultColorTe = isTeam1 ? "afcff1" : "3da3fd";
  const defaultNumberColor = isTeam1 ? "f5f5f5" : "030303";
  const teamSleeveDetailColor = teamSleeveDetail != "" ? teamSleeveDetail : teamSleeve;
  const teamSplitColor = teamSplit != "false" ? teamSleeveDetailColor : "ffffff00";
  const teamHorStripesColor = teamHorStripes != "false" ? teamSleeveDetailColor : "ffffff00";
  const teamStripesColor = teamStripes != "false" ? teamSleeveDetailColor : "ffffff00";
  const teamSquaresColor = teamSquares != "false" ? teamSleeveDetailColor : "ffffff00";
  const teamSleeveColor = teamSleeve != "" ? teamSleeve : "ffffff00";

  return (
    <div className="featured-game-team">
      {game?.sport_code === "FB" && (
        <GameBoxDetailedAvatarFBBB
          pattern="1"
          color1={`#${teamColor || defaultColor}`}
          color2={`#${teamNumber || defaultNumberColor}`}
          color3={`#${teamHorStripesColor}`}
          color4={`#${teamSleeveColor}`}
          color5={`#${teamSplitColor}`}
          color6={`#${teamSquaresColor}`}
          color7={`#${teamStripesColor}`}
        />
      )}
      {game?.sport_code === "BA" && <GameBoxDetailedAvatarNo pattern="1" color1={`#${teamColor || defaultColor}`} color2={`#${teamNumber || defaultNumberColor}`} />}
      {(game?.sport_code === "AF" || game?.sport_code === "FL" || game?.sport_code === "VB" || game?.sport_code === "HB") && (
        <GameBoxDetailedAvatarShort pattern="1" color1={`#${teamColor || defaultColor}`} color2={`#${teamNumber || defaultNumberColor}`} />
      )}
      {(game?.sport_code === "IH" || game?.sport_code === "BD") && <GameBoxDetailedAvatarLong pattern="1" color1={`#${teamColor || defaultColor}`} color2={`#${teamNumber || defaultNumberColor}`} />}
      {game?.sport_code === "DA" && <GameBoxDetailedAvatarTE pattern="1" color1={`#${teamColor || defaultColor}`} color2={`#${teamNumber || defaultNumberColor}`} />}
      {game?.sport_code === "TE" && <GameBoxDetailedAvatarTE pattern="1" color1={`#${teamColor || defaultColorTe}`} color2={`#${teamNumber || defaultNumberColor}`} />}
      {(game?.sport_code === "BX" || game?.sport_code === "MM") && <GameBoxDetailedAvatarBX pattern="1" color1={`#${teamColor || defaultColor}`} color2={`#${teamNumber || defaultNumberColor}`} />}
      {game?.sport_code === "SN" && <GameBoxDetailedAvatarSN pattern="1" color1={`#${teamColor || defaultColor}`} color2={`#${teamNumber || defaultNumberColor}`} />}
      {game?.sport_code === "BB" && <GameBoxDetailedAvatarBB pattern="1" color1={`#${teamColor || defaultColor}`} color2={`#${teamNumber || defaultNumberColor}`} />}
      {!game?.sport_code && <GameBoxDetailedAvatarLong pattern="1" color1={`#${teamColor || defaultColor}`} color2={`#${teamNumber || defaultNumberColor}`} />}
    </div>
  );
};

export default createJersey;
