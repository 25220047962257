import api from "../../apis/nubet";

export const getMaintananceStatus = async (locale) => {
  try {
    const reply = await api.get("/services/Maintenance", {
      params: {
        lang: locale,
      },
    });
    if (reply?.data?.type === "data" && reply?.data?.payload) {
      return reply.data.payload;
    }
    return {};
  } catch (error) {
    console.log(error);
  }
};
