import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const CategoryClassicsIcon = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 200 200"
      {...props}
    >
      <path d="M59.8,118.7c4-0.2,10.9-1.7,14.9-1.3c29.5,2.7,25.6,54.9-3.7,54.9s-8.2-2.1-11.9-2.3c-3.9,0.2-7.8,2.3-12,2.3
	c-29.3,0-33.2-52.2-3.7-54.9c2.2-0.2,5.4,0.1,8.5,0.5c-1.3-16.9,3.3-35.1,11.7-50.6c9.1-16.6,22.7-29.8,37.8-34.5
	c12.8-7.3,29.4-6.9,42.5,2.2c13.2,9.2,19.2,24.6,16.7,39.1c-12.8,7.3-29.4,6.9-42.5-2.2c-6.9-4.8-11.8-11.3-14.6-18.6
	c0,8.9,2.2,18.6,6.4,28c6.5,15.1,17.7,29.1,31,37.4c3.8,0,11.4-1.7,15.6-1.3c29.5,2.7,25.7,54.9-3.7,54.9s-8.1-2.1-11.9-2.3
	c-3.9,0.2-7.7,2.3-12,2.3c-29.3,0-33.2-52.2-3.6-54.9c0.2-0.1,0.4-0.1,0.6-0.1c-9.8-8.9-18-20.5-23.4-32.8c-6-13.8-8.5-28.5-6-41.1
	C86.2,49,77.1,59,70.5,71.1C62.5,85.7,58.2,103,59.8,118.7C59.8,118.7,59.8,118.7,59.8,118.7z"/>
    </SvgIcon>
  );
};

export default CategoryClassicsIcon;
