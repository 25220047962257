/**
 * BettingRules - Fin version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import ListItem from "@mui/material/ListItem";
import Obfuscate from "react-obfuscate";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class BettingRules extends React.PureComponent {
  goTop = () => { };

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>NUBET.COM VEDONLYÖNTI SÄÄNNÖT</h1>
            Suomenkielinen versio 1.4 Viimeisin päivitys 12.11.2024.<br></br>
            <br></br>
            <h3 id="General">1. YLEISTÄ</h3>
            1.1. Vedonlyöntijärjestäjä Nubet ja verkkosivusto Nubet.com ovat Vana Lauri OÜ:n ylläpitämiä, joka on Virossa rekisteröity yritys numerolla 16427120 ja jonka rekisteröity osoite on Roseni 10-135, 10111
            Tallinna, Viro (jäljempänä "Nubet" tai "Yhtiö"). Vana Lauri OÜ:llä on Viron vero- ja tullihallituksen myöntämä toimilupa ja sen valvonta, ja sillä on seuraavat toimiluvat: HKT000066 (myöntämispäivä:
            10.07.2022) ja HKT000067 (myöntämispäivä: 10.07.2022).<br></br>
            <br></br>
            1.2. Näitä Nubetin vedonlyöntisääntöjä sovelletaan Nubetin urheiluvedonlyöntiin verkkosivustolla Nubet.com ja ne koskevat Nubetin verkkosivustolla Nubet.com oikealla rahalla pelaamien vedonlyöntien
            tarjoamista (jäljempänä myös "Nubet-vedonlyönti"). Jos pelaaja ei hyväksy näitä vedonlyöntisääntöjä ja Nubetin käyttöehtoja, hänen tulee välittömästi lopettaa Nubet-vedonlyönnin käyttö.<br></br>
            <br></br>
            1.3. Nubetin vedonlyöntisäännöt ovat osa Nubetin käyttöehtoja, jotka hyväksytään asiakkaan rekisteröitymisen yhteydessä Nubetin verkkosivustolla.<br></br>
            <br></br>
            1.4. Pelaaja vakuuttaa, että hän on lukenut ja ymmärtänyt Nubet-vedonlyöntisäännöt asettaessaan vedon.<br></br>
            <br></br>
            1.5. Yhtiö voi aika ajoin joutua muuttamaan näitä Nubetin vedonlyöntisääntöjä. Jos näihin Nubet-vedonlyöntisääntöihin tehdään muutoksia, muutoksista ilmoitetaan aina erikseen Nubetin verkkosivuilla Nubet.com.
            Nubet pidättää oikeuden päivittää vedonlyöntisääntöjä milloin tahansa.<br></br>
            <br></br>
            1.6. Jos Nubetin ehtojen ja Nubetin vedonlyöntisääntöjen välillä on ristiriitaa, sovelletaan Nubetin vedonlyöntisääntöjä. Jos Nubetin vedonlyöntisääntöjen eri osa-alueiden välillä on ristiriita, sovelletaan
            seuraavaa järjestystä: Jos on ristiriitoja tai puuttuu lajikohtaisia sääntöjä, lajikohtaiset säännöt ovat etusijalla vedonlyöntisääntöihin nähden ja vedonlyöntisäännöt ovat etusijalla yleisiin sääntöihin
            nähden.<br></br>
            <br></br>
            1.7. Asettaessaan vedon Nubet Vedonlyönnissä pelaajan on tiedostettava, että asetettuja vetoja pidetään tuotteina, jotka kulutetaan välittömästi, eikä yhtiön voida oletusarvoisesti katsoa olevan vastuussa
            asiakkaan asettamien vetojen ja panosten palauttamisesta, peruuttamisesta tai hyvittämisestä. Tämä pätee ennen kyseisen vedonlyöntitapahtuman alkamista, kyseisen vedonlyöntitapahtuman aikana tai kyseisen
            vedonlyöntitapahtuman jälkeen lukuun ottamatta vedon peruuttamista tai kotiuttamista, joka on toteutettu erikseen sallitulla tavalla yhtiön vedonlyöntisääntöjen mukaisesti. Huomaa, että Nubet-vedonlyönnissä
            on mahdollisuus kotiuttaa asetetut vedot cashout -mekanismilla tietyissä olosuhteissa ja tietyin edellytyksin, jotka on selitetty omassa osiossaan Nubet-vedonlyöntisäännöissä.<br></br>
            <br></br>
            1.8. Nubet-vedonlyönti tarjoaa vedonlyöntituotteita, joissa tappioriski on kokonaan pelaajalla, kun hän päättää asettaa panoksen tai vedon, jonka Nubet-vedonlyönti tarjoaa ja järjestää.<br></br>
            <br></br>
            1.9. Nubet-vedonlyönti pidättää oikeuden rajoittaa tai estää pelaajan pääsyn Nubet-vedonlyönnin tuotteisiin, tarjouksiin tai palveluihin ilman selitystä yhtiön oman harkinnan mukaan.<br></br>
            <br></br>
            1.10. Ohjelmistot, jotka liittyvät Nubet-vedonlyönnin -tuotteisiin tai mahdollistavat Nubet-vedonlyöntituotteiden käytön, ja muut palvelut, joita Nubet-vedonlyönti tarjoaa pelaajalle, voidaan tarjota osittain
            tai kokonaan kolmannen osapuolen tarjoajan kautta, jolla on sopimussuhde Nubetin kanssa. Ohjelmisto on suojattu tekijänoikeuslainsäädännöllä ja immateriaalioikeuksien suojalla. Pelaaja saa käyttää ohjelmistoa
            vain henkilökohtaisiin ja vapaa-ajan tarkoituksiin kaikkien sovellettavien lakien, sääntöjen ja määräysten mukaisesti.<br></br>
            <br></br>
            1.11. Nubet-vedonlyönnissä on kiellettyä käyttää digitaalisia apuvälineitä, kuten robotteja tai mekaanisia, elektronisia tai muita laitteita Nubet-vedonlyönnissä käytettävien ohjelmistojen manipuloimiseksi
            tai johtamiseksi. Yhtiöllä on oikeus sulkea pelitili, keskeyttää talletukset ja nostot sekä takaisinperiä kaikki niihin liittyvät voitot, jos on perusteltua syytä epäillä, että tällaista apuvälinettä tai
            laitetta on käytetty.<br></br>
            <br></br>
            1.12. Pelaaja ei saa missään tapauksessa käyttää minkäänlaista virhettä, toimintahäiriötä, virusta, bugia, vikaa tai epäjohdonmukaisuutta Nubet-vedonlyönnissä käytettävässä ohjelmistossa omaksi hyödykseen tai
            muiden pelaajien vahingoksi. Pelaajan on ilmoitettava tällaisesta tapauksesta asiakaspalveluun välittömästi sen havaitsemisen jälkeen.<br></br>
            <br></br>
            1.13. Nubet-vedonlyöntisääntöjen tai Nubet-vedonlyöntiin sovellettavien Nubet-ehtojen rikkominen voi johtaa pelaajan sulkemiseen pois vedonlyönnistä, osallistumisesta kampanjoihin, bonustarjouksiin tai
            erikoistarjouksiin ilman eri ilmoitusta.<br></br>
            <br></br>
            1.14. Jos Nubet toteaa, että pelaajan voittamat varat ovat peräisin tililtä, joka on hankkinut kyseiset voitot vilpillisesti ja/tai näitä Nubet-vedonlyöntisääntöjä tai Nubet-ehtoja rikkoen, Nubet pidättää
            oikeuden pidättää tai takaisinperiä kyseiset voitot tai hyvityssaldon. Kaikista pelaajista, jotka ovat osallisina epäillyssä petollisessa toiminnassa, voidaan ilmoittaa asianomaisille viranomaisille.<br></br>
            <br></br>
            1.15. Varoja voidaan tallettaa Nubet-vedonlyöntiin käyttämällä Nubet-vedonlyönnin tarjoamia maksutapoja, jotka löytyvät valikosta kohdasta "Talletus".<br></br>
            <br></br>
            1.16. Varoja voidaan nostaa Nubet-vedonlyönnistä pyytämällä maksua ja valitsemalla käytettävissä oleva nostomenetelmä, joka löytyy valikosta kohdasta "Nosto". Käytettävissä oleva nostotapa voi riippua
            käytetystä talletustavasta.<br></br>
            <br></br>
            1.17. Näiden Nubet-vedonlyönnin -sääntöjen jokainen lauseke on erillinen ja erotettavissa kaikista muista. Jos jokin lauseke todetaan jostain syystä mitättömäksi, pätemättömäksi tai
            täytäntöönpanokelvottomaksi, muut lausekkeet pysyvät täysin voimassa.<br></br>
            <br></br>
            1.18. Jos pelitili menee miinukselle, Nubetilla on oikeus periä nämä varat takaisin asiakkaalta, ja asiakkaalla on velvollisuus korjata virheellisesti saamansa varat yhtiölle.<br></br>
            <br></br>
            1.19. Jos sinulla on kysyttävää Nubet-vedonlyönnistä, ota yhteyttä osoitteeseen <Obfuscate email={"support@nubet.com"}>support@nubet.com</Obfuscate>.<br></br>
            <br></br>
            <h3 id="Rules">2. YLEISET SÄÄNNÖT</h3>
            <br></br>
            <br></br>
            <h4>2.1. Vedon asettaminen</h4>
            2.1.1. Vedot voidaan asettaa single-, yhdistelmä- järjestelmävetoina- tai Betbuilder -vetoina.<br></br>
            <br></br>
            2.1.2. Single-veto tarkoittaa vedonlyöntiä vain yhdestä valinnasta, kun taas yhdistelmä-veto tarkoittaa useiden valintojen yhdistämistä yhdeksi vedoksi.<br></br>
            <br></br>
            2.1.3. Yhden ja useamman vedon lisäksi veto voidaan asettaa myös järjestelmä-vetona. Järjestelmä-vedossa yhdistät useita single/yhdistelmä-vetoja yhdeksi vetotapahtumaksi.<br></br>
            <br></br>
            {/* 2.1.4. Niin sanottu Banker on erityinen järjestelmä-veto. Banker-valinta on yhdistelmä vetoja vedonlyöntikupongilla. Jotta Banker olisi voittava, jokaisen mukana olevan Banker-kohteen on osuttava. Pelaaja voi
            valita vetoja vedonlyöntikupongilla Bankerin napsauttamalla vetokupongin erillistä painiketta. Järjestelmävetojen määrä lasketaan vain otteluista, joita ei ole valittu Bankeriksi.<br></br>
            <br></br> */}
            2.1.4. Betbuilder mahdollistaa vedon lyömisen saman tapahtuman kohteista, esimerkiksi jalkapallo-ottelusta. Voit lyödä vetoa, että yksittäinen pelaaja tekee maalin ja samassa ottelussa tulee yli 2,5 maalia.
            Vaikka normaalisti tällainen veto olisi yhdistelmäveto, niin Betbuilder -vedossa se muistuttaa single -vetoa.<br></br>
            <br></br>
            <h4>2.2. Vetotosite</h4>
            2.2.1. Vetotosite on pelaajan ja Nubet-vedonlyönnin välinen sopimus, joka luodaan automaattisesti, kun pelaaja asettaa vedon ja Nubet-vedonlyönti hyväksyy sen. <br></br>
            <br></br>
            2.2.2. Pelaajan ja Nubet-vedonlyönnin välisen vetositteen ja sopimuksen voimassaolo riippuu siitä, onko tapahtuma tallennettu oikein Nubetin keskustietokoneelle. Tätä voidaan tarkastella ja vahvistaa
            tilitiedoissa. <br></br>
            <br></br>
            2.2.3. Jos ohjelmisto toimittaa vetositteen automaattisesti, mutta todellista vetoa ei tallenneta oikein Nubetin keskustietokoneelle, asetettu veto voidaan julistaa mitättömäksi.<br></br>
            <br></br>
            2.2.4. Asiakas voi korjata tai poistaa valmiin virtuaalisen vedonlyöntikupongin milloin tahansa Nubet-vedonlyönnissä ennen sen asettamista. Asiakas on yksin vastuussa tekemiensä vetojen valinnasta, eikä hän
            voi väittää, että Nubet-vedonlyönnin tarjoamat ja/tai antamat tiedot ja suositukset olisivat mahdollisesti virheellisiä tai puutteellisia.<br></br>
            <br></br>
            2.2.5. Pelaaja hyväksyy vedonlyöntikupongilla ilmoitetut kertoimet vedon asettamisen yhteydessä. Pelaaja on myös tietoinen ja hyväksyy, että kertoimet voivat poiketa kertoimista vedonlyöntikupongin
            lähettämisen yhteydessä, erityisesti live-vedonlyönnissä, jossa vedon hyväksyminen viivästyy sisäänrakennettuna.<br></br>
            <br></br>
            <h4>2.3. Velvollisuus</h4>
            2.3.1. Nubet-vedonlyönnillä ei ole velvollisuutta paljastaa lisätietoja, kuten kilpailutyyppiä (esim. liigapeli tai cup-peli), lyhennettyä/pidennettyä peliaikaa (esim. ystävyysotteluiden, miniturnausten,
            nuoriso-otteluiden, sisäturnausten jne. tapauksessa), ottelupaikkoja (esim. neutraali paikka), kilpailumatkoja jne. <br></br>
            <br></br>
            2.3.2. Jos edellä mainitut tiedot annetaan, ne esitetään ilman takuita tarkkuudesta, eikä niillä ole vaikutusta vedon ratkaisemiseen. Sama koskee kaikenlaisia tilastoja, taulukoita ja tuloksia.<br></br>
            <br></br>
            <h4>2.4. Vetojen ratkaiseminen</h4>
            2.4.1. Vedot ratkaistaan virallisten tulosten julkistamisen jälkeen, ellei vedonlyöntisäännöissä toisin mainita.<br></br>
            <br></br>
            2.4.2. Kaikki sovintoratkaisuun tai vedonlyöntivoittojen hyvittämiseen liittyvät valitukset on toimitettava kirjallisesti ja lähetettävä Nubetille sähköpostitse tai postitse 14 arkipäivän kuluessa ratkaisun
            tekemisestä.<br></br>
            <br></br>
            <h4>2.5. Vetojen peruuttaminen</h4>
            2.5.1. Nubet-vedonlyönti pidättää oikeuden peruuttaa vedon, mikä tarkoittaa, että veto ei ole enää voimassa ja panossumma palautetaan pelaajalle riippumatta siitä, onko tapahtuma ratkaistu vai ei. Tämä koskee
            myös kotiutettuja vetoja.<br></br>
            <br></br>
            2.5.2. Jos Nubet-vedonlyönti peruuttaa vedon, kertoimet asetetaan vastaamaan panoksen palauttamista, mikä tarkoittaa, että voittosumma vastaa 1,00 kerrointa.<br></br>
            <br></br>
            2.5.3. Yhdistelmä- tai järjestelmä- vetona asetettu veto pysyy voimassa lukuun ottamatta yhdistelmä- tai järjestelmävedon peruutettua valintaa, aina kun se on mahdollista. Tämä tarkoittaa, että peruutettu
            veto suljetaan pois yhdistelmä- tai järjestelmä-vedon lopullisista kertoimista, eikä sen tarvitse olla voittava, jotta yhdistelmä- tai järjestelmä-veto olisi voittava veto.<br></br>
            <br></br>
            2.5.4. Jos vedonlyöntitapahtumaa ei järjestetä vetotositteessa kuvatulla tavalla, esimerkiksi väärät osallistujat tai tapahtuman paikkaa muutetaan, veto voidaan mitätöidä ja Nubet-vedonlyönti asettaa
            kertoimeksi 1,00.<br></br>
            <br></br>
            2.5.5. Jos tapahtuma perutaan ja/tai se ei ala aiotun ajan puitteissa ja kohdassa 2.5 tästä säännöstä poiketen sisältyvän ajan kuluessa. Peruutettua vetoa voidaan pitää mitättömänä, ja Nubet-vedonlyönti
            asettaa kertoimeksi 1,00. Jos ottelua tai tapahtumaa lykätään ja se saatetaan päätökseen 48 tunnin kuluessa alkuperäisestä aikataulun mukaisesta alkamisajasta, kaikki avoimet vedot ratkaistaan tuloksella.
            <br></br>
            <br></br>
            2.5.6. Jos ottelua tai tapahtumaa ei pelata loppuun 48 tunnin kuluessa, kaikki siihen liittyvät vedot voidaan mitätöidä ellei virallista tulosta julisteta. Ottelut ja tapahtumat, joiden alkamisaikaa on muutettu hyvissä ajoin etukäteen suorien
            TV-lähetysten vastaanottamiseksi tai otteluruuhkien helpottamiseksi tai muu ymmärrettävä ja hyvin ilmoitettu syy, kuten on kuvattu, voidaan jättää tämän säännön ulkopuolelle ja katsoa lykätyksi etukäteen
            tunnetusta ymmärrettävästä syystä.<br></br>
            <br></br>
            2.5.7. Vedot voivat myös olla voimassa pidempään kuin 48 tuntia, jos vedonlyöntitapahtuma on osa turnausta (tennisturnaus, olympialaiset, maailmanmestaruuskilpailut, Euroopan mestaruuskilpailut jne.) ja se
            suoritetaan tämän turnauksen aikana ja turnauksen sääntöjen mukaisesti, mutta eri aikaan.<br></br>
            <br></br>
            2.5.8. Vetoja, joiden tulos on jo ratkaistu ennen pelin lopettamista (esim. vetotyypit, kuten yli/alle, asetettu vedonlyönti, ensimmäinen maali jne.), ja vetoja, joiden tulos ratkaistaan vedon kohteena olevan
            tapahtuman alkamisen jälkeen, ei peruuteta, ja ne ratkaistaan joka tapauksessa.<br></br>
            <br></br>
            2.5.9. Vedot, joita Nubet-vedonlyönti tarjoaa jatkuvasti tai jotka ovat erityisen riippuvaisia sen nykyisestä ajasta ja joita tarjotaan myös tapahtuman alkamisen jälkeen, esimerkiksi pitkäaikais-vedot tai
            live-vedot, jotka voidaan suorittaa tapahtuman alkamisen jälkeen, ovat pääasiassa näiden sääntöjen soveltamisalan ulkopuolella.<br></br>
            <br></br>
            2.5.10. Jos tapahtuma päättyy ratkaisemattomana tai tasapeliin tilanteessa, jossa tulokselle tai tasapelille ei tarjottu kertoimia (esim. baseball-ystävyysottelut, amerikkalainen jalkapallo jne.), veto
            voidaan mitätöidä ja Nubet-vedonlyönti asettaa kertoimeksi 1,00.<br></br>
            <br></br>
            2.5.11. Jos näytetyissä kertoimissa on ilmeinen virhe (esim. väärä tasoitus, väärät desimaalit jne.), veto voidaan mitätöidä ja Nubet-vedonlyönti asettaa kertoimeksi 1,00.<br></br>
            <br></br>
            2.5.12. Jos vedon hyväksymishetkellä johtuen verkkosivustolla tai vedonvälittäjän järjestelmissä olevasta teknisestä viasta tai kolmannen osapuolen järjestelmästä, joka tarjoaa vedonvälittäjälle tai
            Nubet-vedonlyönnille tarvittavan toiminnon oikeiden kertoimien antamiseksi, tai vedonvälittäjän tai Nubet-vedonlyönnin kannalta merkityksellisestä teknisestä virheestä, jotta se toimisi oikein eikä kumpikaan
            vedonlyöntituotteiden toimittamiseen liittyvä toiminto täyttyisi, jos vedonlyöntisopimuksen perustaksi tulee virheellisiä kertoimia, veto voidaan mitätöidä ja Nubet-vedonlyönti asettaa kertoimeksi 1,00. Tämä
            sisältää kaikki selvät poikkeamat, joiden olisi voitu olettaa olevan virheellisiä verrattuna silloiseen keskimääräiseen markkinakertoimeen.<br></br>
            <br></br>
            2.5.13. On kiellettyä asettaa yhdistelmä- tai järjestelmä-vetoja samaan vetokuponkiin, jonka valinnoilla on keskinäinen korrelaatio (ovat toisistaan riippuvaisia). Huolimatta kaikista tarvittavista
            toimenpiteistä tällaisten vedonlyöntimahdollisuuksien estämiseksi, mutta tapauksissa, joissa niin tapahtuu, Nubet pidättää oikeuden oman harkintansa mukaan peruuttaa kaikki useiden vetojen osat, jotka
            sisältävät toisistaan riippuvaisia valintoja. Tällaiset vedot voidaan katsoa mitättömiksi myös sen jälkeen, kun tapahtuman tulos on tiedossa.<br></br>
            <br></br>
            2.5.14. Betbuilder -vedossa kaikkien valintojen tulee olla voittavia (ei palautuksia, ties, push, jne), jotta veto on voittava. Jos valinta ei ole voittava, se on häviävä. Jos yksittäinen valinta
            mitätöidään (void), tai pelaaja ei osallistu otteluun missään vaiheessa, silloin koko veto mitätöidään.<br></br>
            <br></br>
            <h4>2.6. Kotiedun muuttuminen</h4>
            2.6.1. Otteluissa, joissa yksi joukkueista pelaa kotonaan (liigaottelu, cup-ottelu, kansainvälinen kilpailu jne.), vedonlyöntikupongilla ensimmäisenä mainittu joukkue on aina kotijoukkue. Tämä sääntö ei
            välttämättä koske turnauksia, koska isäntäjoukkuetta ei aina pidetä kotijoukkueena, joka saattaa riippua tietyistä turnauksen määrityksistä.<br></br>
            <br></br>
            2.6.2. Jos ottelu pelataan muualla kuin kotijoukkueen tavallisella kotikentällä mistä tahansa erityisestä tai määrittelemättömästä syystä, kaikki asetetut vedot säilyttävät pätevyytensä, ellei kotijoukkuetta
            muuteta, mikä tarkoittaa, että kilpailun järjestäjä julistaa alkuperäisen kotijoukkueen vierasjoukkueeksi.<br></br>
            <br></br>
            <h4>2.7. Vedonlyöntitulos</h4>
            2.7.1. Vain kilpailun järjestäjän virallisesti ilmoittamat tulokset ovat ratkaisevia vedon ratkaisemisessa heti vedonlyöntitapahtuman päättymisen jälkeen.<br></br>
            <br></br>
            2.7.2. Kilpailun järjestäjän myöhemmät muutokset tulokseen/sijoitukseen eivät vaikuta vedon ratkaisemiseen.<br></br>
            <br></br>
            2.7.3. Nubet voi saada virallisia tuloksia kolmannen osapuolen kumppaneilta.<br></br>
            <br></br>
            2.7.4. Nubet yrittää ratkaista markkinat ja saada lopullisen vedon tuloksen mahdollisimman pian vedonlyöntitapahtuman päätyttyä, mutta joissakin poikkeuksellisissa tilanteissa se ei voi taata ja määritellä
            etukäteen vedon ratkaisemisen enimmäiskestoa tai viivettä. Tämä johtuu tekijöistä, joihin Nubet ei voi vaikuttaa, ja Nubet on sitoutunut ratkaisemaan vedot mahdollisimman pian joka kerta.<br></br>
            <br></br>
            2.7.5. Kun vedon tulos on määritetty ennen tapahtuman päättymistä, veto voidaan ratkaista Nubetin vedonlyöntisääntöjen mukaisesti, vaikka tapahtuma olisi keskeytetty tai tapahtuman kestoa lyhennetty.<br></br>
            <br></br>
            2.7.6. Turnauksissa, joissa on pudotusjärjestelmä yksittäisissä urheilulajeissa (tennis, tikanheitto, snooker jne.), jos peli keskeytetään (loukkaantumisen vuoksi jne.), seuraavalle kierrokselle etenevää
            osallistujaa (pudotuspeliottelun finaalissa voittajaksi julistettua osallistujaa) pidetään voittajana. Ei ole väliä, tuleeko hän seuraavalle kierrokselle vai ei. Kaikki vedot tarkasta tuloksesta (tulosvedot,
            sarjavedot, tasoitusvedot jne.) voidaan mitätöidä ja Nubet-vedonlyönti asettaa kertoimeksi 1,00. Vedot, joiden ovat saaneet tuloksen ennen pelin keskeyttämistä (alle/over, 1 erä jne.), eivät kuulu tämän
            säännön piiriin ja ratkaistaan joka tapauksessa.<br></br>
            <br></br>
            2.7.7. Tapahtumissa, joissa pidetään palkintoseremonia (podium-presentation), ovat nämä tapahtumat ovat mahdollisten muutosten takaraja. Myöhempiä hylkäyksiä ja muutoksia palkintoseremonian jälkeen ei
            huomioida. Tämä sisältää myös valitukset, oikeusjutut, doping-testaukset tai muut syyt.<br></br>
            <br></br>
            2.7.8. Nubet pidättää oikeuden korjata kohteiden virheellisen voitonmaksun tarvittaessa.<br></br>
            <br></br>
            <h4>2.8. Tasapääjuoksu (Dead heat)</h4>
            2.8.1. Kahden tai useamman voittajan (esim. parhaan maalin tekijän) tapauksessa tilannetta, jota kutsutaan myös tasapääjuoksuksi, vedon voittosumma jaetaan vastaavasti, esimerkiksi vedon panos 100 euroa,
            kerroin 1.80 johtaisi vedon tulokseen voitolla 180 ja siten 80 on voitto-osuuden määrä, kahdella voittajalla voitto-osuus on 40 (80 jaettuna kahdella) ja voitto on siksi alkuperäinen panos johon lisätään 40
            euron voitto-osuus, joka johtaa 140 euron suuruiseen lopulliseen maksuun. Kun vastaavia tuloksia on enemmän, voittosumma jaetaan samalla menetelmällä ja lopullinen voitto on alkuperäinen panos, joka lisätään
            jaetulla voitolla.<br></br>
            <br></br>
            2.8.2. Tasapääjuoksu -poikkeus nro 1: "Head-to-head " -vedot, jotka on kuvattu omassa osiossaan näissä Nubetin vedonlyöntisäännöissä.<br></br>
            <br></br>
            2.8.3. Tasapääjuoksu -poikkeus nro 2: On olemassa tiettyjä markkinatyyppejä, jotka sisältävät useamman kuin yhden voittavan valinnan. Esimerkkejä, kuten Double Chance, Anytime Goal Scorer ja Top X. Näissä
            vetotyypeissä ja niihin liittyvissä vetokohteissa tasapääjuoksu -säännöt eivät ole voimassa. Jos Top X -markkinatyypissä on kuitenkin enemmän valintoja kuin alun perin määriteltiin markkinakuvauksessa,
            jaetuille sijoille sovelletaan tasapääjuoksun sääntöjä. <br></br>
            <br></br>
            <h4>2.9. Voittajavedot</h4>
            2.9.1. Voittajavedot (tunnetaan myös nimellä outright/futuurit) käsitellään ”All In Run Or Not” -kohteina, eli ne ovat häviäviä, jos valinta/kilpailija ei osallistu tapahtumaan. Tasapääjuoksu -säännöt ovat
            voimassa.<br></br>
            <br></br>
            2.9.2. Ellei toisin mainita, aina kun kilpailun järjestäjä sisällyttää tarvittavat kierrokset, ottelut tai ottelusarjat (esim. pudotuspelit) niin sanotun runkosarjan päättymisen jälkeen määrittääkseen
            sijoitukset, liigan voittajat, nousut/putoamisen jne., Nubet ottaa näistä otteluista saadut tulokset huomioon lopulliseen sijoitukseen viittaavien vetojen ratkaisemista varten. Esimerkiksi kausivedot NHL:n
            voittaneesta joukkueesta viittaavat Stanley Cup -voittajiin.<br></br>
            <br></br>
            2.9.3. Kaikki vedot ratkaistaan palkintoseremonian tai kilpailun virallisen tulostaulukon perusteella ottamatta huomioon myöhempien tutkimusten tai hylkäysten tuloksia.<br></br>
            <br></br>
            <h4>2.10. Head-to-Head</h4>
            2.10.1. Osallistuja, joka sijoittuu korkeammalle sijalle virallisissa tuloksissa, arvioidaan voittajaksi. Jos osallistuja putoaa kilpailusta aikaisin, vedot tästä osallistujasta menetetään ja vastustaja tai
            toinen kilpailun, pelin tai tapahtuman osallistuja katsotaan voittajaksi.<br></br>
            <br></br>
            2.10.2. Jos toinen tai kumpikaan osallistuja ei osallistu kilpailuun, peliin, tapahtumaan tai turnaukseen, kaikille niihin liittyville vedoille annetaan kerroin 1,00, joka johtaa siihen, että pelaaja saa alun
            perin asetetun panoksen takaisin.<br></br>
            <br></br>
            2.10.3. Jos molemmilla osallistujilla on sama sijoitus virallisessa sijoituksessa, kaksintaistelu ratkaistaan tasapelinä. Tämä koskee myös molempien osallistujien ennenaikaista vetäytymistä. Jos tasapeliä ei
            tarjota eikä sitä voida ratkaista, kaikille näiden kaksintaistelujen vedoille annetaan kerroin 1,00, joka johtaa siihen, että pelaaja palauttaa alkuperäisen eräpanoksen.<br></br>
            <br></br>
            2.10.4. Turnauksissa, joissa on pudotus-järjestelmä (tennisturnaukset jne.), voittajana pidetään osallistujaa, joka etenee kilpailun seuraavaan vaiheeseen (toinen kierros ennen ensimmäistä, puolivälierät
            ennen neljännesvälieriä jne.) tai saavuttaa paremman sijoituksen lopullisessa sijoituksessa (finaalin voittaja ennen finaalin häviäjää jne.). Jos jatkoonmennyt osallistuja ei ole paikalla seuraavalla
            kierroksella, hänet katsotaan silti edenneeksi jatkoon.<br></br>
            <br></br>
            <h4>2.11. Pelaaja-kohteet:</h4>
            2.11.1. Jos pelaaja-kohteisiin valittu pelaaja ei osallistu peliin, kaikki kyseiseen pelaajaan liittyvät vedot mitätöidään.<br></br>
            <br></br>
            2.11.2. Pelaajakohteissa huomioidaan jatkoaika tai vastaava. Rangaistuspotkukilpailua tai vastaavaa ei huomioda.<br></br>
            <br></br>
            2.11.3. Pelaaja-vedot jotka on pelattu ennen ottelupaikan muutosta mitätöidään.<br></br>
            <br></br>
            2.11.4. Jos ottelu alkaa mutta keskeytyy ennen kuin ottelu saavuttaa luonnollisen lopputuloksen ja ottelua ei jatketa 5 tunnin sisällä, niin kaikki pelaaja-vedot mitätöidään.<br></br>
            <br></br>
            <h4>2.12. Vastuuvapaus</h4>
            2.12.1. Nubet ei ota vastuuta syöttö-, siirto- ja/tai arviointivirheistä. <br></br>
            <br></br>
            2.12.2. Nubet pidättää oikeuden korjata ilmeisiä virheitä vedonlyöntikertoimien syöttämisessä ja/tai vedonlyöntitulosten arvioinnissa (esim. kirjoitusvirhe, väärä pelaajapari, kertoimien ilmeinen
            virheellisyys, virheelliset tasoitusvaatimukset, selvästi virheellisen maalimäärän asettaminen yli/alle-vedoille tai vastaava). Tätä sääntöä voidaan soveltaa myös tapahtuman jälkeen heti, kun virhe on
            havaittu, mikä johtaa asetettujen vetojen mitätöintiin myöhemmin. Tämä sisältää kaikki selvät poikkeamat, joiden olisi voitu olettaa olevan virheellisiä verrattuna silloiseen keskimääräiseen
            markkinakertoimeen.<br></br>
            <br></br>
            2.12.3. Nubet pidättää oikeuden muuttaa kertoimia milloin tahansa sekä keskeyttää tai lopettaa vedonlyönnin tapahtumissa ennen aikataulun mukaista alkamisaikaa. Yhtiöllä on oikeus päättää, haluaako yritys
            tarjota vedonlyöntituotteita tiettyyn tapahtumaan tiettynä ajankohtana asiakkailleen.<br></br>
            <br></br>
            2.12.4. Tietyntyyppisille vedonlyöntimarkkinoille on olemassa erityissääntöjä, jotka ovat saatavilla näiden vedonlyöntisääntöjen omassa osiossaan. Erityissäännöt ovat etusijalla yleisiin sääntöihin nähden,
            joten pelaajan tulee olla tietoinen näistä säännöistä ennen kuin hän pelaa mitään Nubet-vedonlyönnin tarjoamista peleistä.<br></br>
            <br></br>
            <h4>2.13. Cash Out</h4>
            2.13.1. Cash Out -vaihtoehdon avulla pelaaja voi lunastaa panoksen Cash Out -valinnassa näkyvällä arvolla tiettynä hetkenä. On pelaajan päätettävissä, haluaako hän käyttää Cash Out -vaihtoehtoa, ja pelaaja
            voi yhtä hyvin päättää odottaa vedon ratkaisemista normaalin ajan kuluessa. Pelaaja ei voi peruuttaa Cash Out -vaihtoehdon käyttöä sen jälkeen, kun pyyntö on vahvistettu. Kun Cash Out -pyyntö on hyväksytty,
            se mitätöi alkuperäisen vedon ja pelaaja saa vedon Cash Out – arvon mukaisen voittosumman lopputuloksesta riippumatta.<br></br>
            <br></br>
            2.13.2. Nubet pidättää oikeuden lopettaa Cash Out -vaihtoehdon tarjoamisen tai keskeyttää sen tarjoamisen markkinoilla oleville käyttäjille ilman ennakkoilmoitusta. Yhtiöllä on oikeus päättää, haluaako se
            tarjota asiakkailleen Cash Out -option tiettyyn tapahtumaan tiettynä ajankohtana.<br></br>
            <br></br>
            2.13.3. Jos on perusteltua syytä epäillä Nubetin säännöissä ja ehdoissa määriteltyä petosyritystä, esimerkiksi vedonlyönnin tai Cash Out -pyynnön on tehnyt yhdessä toimivat henkilöt, kuten, mutta ei
            rajoittuen, vedonvälittäjän sukulaiset, järjestöt tai työntekijät, joko huijatakseen tai saadakseen etuja, jotka ovat nimenomaisesti kiellettyjä, tai jos alkuperäinen veto tai Cash Out -pyyntö tehtiin
            kyseisen tapahtuman päätyttyä, Nubet voi mitätöidä alkuperäisen vedon, joka lunastettiin, kieltäytyä hyväksymästä Cash Outia tai vaatia Nubetin maksamien summien palauttamista alkuperäiseen panoksen
            suhteessa, mukaan lukien kotiutussumma.<br></br>
            <br></br>
            2.13.4. Siinä tapauksessa, että alkuperäinen veto olisi mitätöity, Nubet pidättää oikeuden julistaa Cash Outin pätemättömäksi ja Cash Out -summan mitätöidyksi kuten alkuperäisen vedon ja takaisinperiä minkä
            tahansa summan, joka ylittää alkuperäisen vedon. Alkuperäinen veto katsotaan ratkaistuksi, eikä Nubetilla ole muita velvoitteita sinua kohtaan alkuperäisen vedon suhteen.<br></br>
            <br></br>
            2.13.5. Jos Cash Out -pyyntö on hyväksytty virheellisesti tai siinä on tapahtunut virhe, mikä on johtanut virheelliseen tarjoukseen tai maksuun, tai Cash Out -summa on virheellisesti hyvitetty pelitilille,
            Nubet pidättää oikeuden korjata tilin saldoa vastaavasti ja takaisinperiä minkä tahansa pelaajalle hyvitetyn summan minkä tahansa virheen vuoksi. tyyppi. Jos tällaisen oikaisun vuoksi pelaajan tilin saldo on
            negatiivinen, Nubetilla on oikeus periä nämä varat takaisin asiakkaalta, ja asiakkaalla on velvollisuus korjata yritykselle virheellisesti saamansa varat.<br></br>
            <br></br>
            <h3 id="Betrules">3. VEDONLYÖNTISÄÄNNÖT</h3>
            <h4>3.1.</h4>
            Varsinainen peliaika: Ellei toisin mainita, tulos varsinaisen peliajan jälkeen katsotaan tapahtuman tulokseksi. Varsinainen peliaika sisältää lisäajan loukkaantumisille, vaihdoille jne. Lisäaika ja äkillinen
            kuolema jne. eivät sisälly säännölliseen aikaan. Varsinainen peliaika määritellään kilpailun järjestäjän julkaisemissa virallisissa säännöissä. Nubet ei ota vastuuta ilmoittaa asiakkaille, jos ottelun tai
            tapahtuman kesto on erilainen kuin normaalisti.<br></br>
            <br></br>
            <h4>3.2. Yleisimmät vetotyypit</h4>
            3.2.1. Yleisimmät alla luetellut vetotyypit, joita kutsutaan myös kohteiksi tai markkinatyypeiksi, ovat käytössä useimmissa urheilutapahtumissa. Lisää vetotyyppejä tai lajikohtaisia erityissääntöjä, jotka
            pelaajan on otettava huomioon, löytyy lajikohtaisista säännöistä.<br></br>
            <br></br>
            3.2.2. Voittaja<br></br>
            Kumpi joukkue voittaa ottelun tasapeli mukaan lukien? 1X2-vedossa pätee seuraava: 1 = kotijoukkueen voitto, X = tasapeli, 2 = vierasjoukkueen voitto.<br></br>
            <br></br>
            3.2.3. Voittaja (2Way)<br></br>
            Voittaja kohde, jossa ei ole tasapeli -vaihtoehtoa. Jos tasapeli tapahtuu varsinaisen peliajan jälkeen, jatkoaika, rangaistuslaukaukset tai vastaavat huomioidaan. Voittaja (2way) -kohteessa pätee seuraava: 1
            = kotijoukkueen voitto, 2 = vierasjoukkueen voitto.<br></br>
            <br></br>
            3.2.4. 1X2 Loput ottelusta<br></br>
            Kumpi joukkue voittaa loput ottelusta? 1 = kotijoukkueen voitto, X = tasapeli, 2 = vierasjoukkueen voitto. Tämä on vain live-vedonlyöntikohde. Vain maalit, jotka tehdään vedon hyväksymisen jälkeen,
            huomioidaan. Näin ollen ottelu alkaa 0:0 tilanteesta, kun veto on hyväksytty.<br></br>
            <br></br>
            3.2.5. Tuplamahdollisuus<br></br>
            Tuplamahdollisuus -kohteessa sovelletaan seuraavaa: 1X = kotijoukkueen voitto tai tasapeli, 12 = kotijoukkueen tai vierasjoukkueen voitto, X2 = vierasjoukkueen voitto tai tasapeli.<br></br>
            <br></br>
            3.2.6. Tasapeli ei vetoa<br></br>
            Tasapeli ei vetoa -kohteessa sovelletaan seuraavaa: 1 = kotijoukkueen voitto, 2 = vierasjoukkueen voitto. Tasapelin sattuessa veto mitätöidään.<br></br>
            <br></br>
            3.2.7. Tasoitus (Point Spread)<br></br>
            Erikoistapaus: Jos raja-arvona on kokonaisluku ja tulos on tasoitus huomioiden tasapeli, veto mitätöidään.<br></br>
            <br></br>
            3.2.8. Tasoitus Joukkue saa yhden tai useamman maalin johtoaseman. Nämä lisätään tavalliseen tulokseen (ottelun tulos + tasoitus). Tulos arvioidaan sisällyttämällä tasoitusehto.<br></br>
            <br></br>
            3.2.9. Tasoitus (Aasialainen)<br></br>
            Aasialaisia tasoituksia on kolmea tyyppiä:<br></br>
            <br></br>
            Kokonainen tasoitus [0], [-1], [-2], ...<br></br>
            <br></br>
            <ListItem>
              Tasoitus koostuu kokonaisluvuista ja se lisätään normaaliin tulokseen (ottelun tulos + tasoitus), tulos arvioidaan sisällyttämällä tasoitusehto. Jos tasoituksen jälkeen pelataan tasapeli (ottelun tulos +
              tasoitus), summa palautetaan (ei vetoa).
            </ListItem>
            <br></br>
            Puolikas tasoitus [-0.5], [-1.5], [-2.5], ...<br></br>
            <br></br>
            <ListItem>
              Tasoitus koostuu puolikkaasta numerosta ja se lisätään normaalitulokseen (ottelun tulos + tasoitus). Tulos arvioidaan sisällyttämällä siihen tasoitusehto. Tasapeli suljetaan pois puolikkaiden lisäämisen
              seurauksena. Tämä tarkoittaa, että tässä muunnelmassa veto on aina joko voitettu tai hävitty. Panosta ei voi palauttaa tämän tyyppisessä vedossa.
            </ListItem>
            <br></br>
            Neljännes tasoitus [-0.25], [-0.75], [-1.25] , ...<br></br>
            <br></br>
            <ListItem>
              Tasoitus koostuu jaetuista puolinumeroista ja se lisätään normaaliin tulokseen (ottelun tulos + tasoitus), tulos arvioidaan sisällyttämällä tasoitusehto. Tässä vaihtoehdossa veto jaetaan yhtä suureen osaan
              puolikkaan ja täyden tasoituksen kesken.
            </ListItem>
            <br></br>
            Ottelun lopputuloksesta riippuen seuraavat vaihtoehdot ovat mahdollisia:<br></br>
            <br></br>
            <ListItem>Veto on voitettu tai hävitty</ListItem>
            <ListItem>Puolet summasta palautetaan ja toinen puoli häviää.</ListItem>
            <ListItem>Puolet summasta palautetaan ja toinen puoli voittaa.</ListItem>
            <br></br>
            Kaikki puoliaikoihin, eriin, neljänneksiin jne. liittyvät tasoitukset arvioidaan kyseisten jaksojen tulosten perusteella. <br></br>
            <br></br>
            3.2.10. Yli/Alle<br></br>
            Yli/Alle -kohteet ovat vetoja siitä, onko ottelussa tehtyjen maalien kokonaismäärä yli vai alle määritetyn maalimäärän.<br></br>
            <br></br>
            3.2.11. Yli/Alle (Aasialainen)<br></br>
            Aasialaiset Yli/Alle - kohteet ovat vetoja siitä, onko ottelussa tehtyjen maalien kokonaismäärä yli vai alle määritetyn maalimäärän. Logiikka toimii samalla tavalla kuin aasialaisessa tasoituksessa. Alla
            versiota kyseisistä kohteista.<br></br>
            <br></br>
            <ListItem>
              Yli/Alle 2<br></br>
              Yli: Veto voittaa, jos ottelussa tehdään vähintään kolme maalia. Jos tasan kaksi maalia, veto palautetaan. Jos ei maaleja tai yksi maali, veto häviää.<br></br>
              Alle: Veto voittaa, jos ottelussa tehdään yksi tai ei lainkaan maaleja. Jos tasan kaksi maalia, veto palautetaan. Jos tehdään kolme tai useampi maali, veto häviää.
            </ListItem>
            <ListItem>
              Yli/Alle 2.25<br></br>
              Yli: Veto voittaa, jos tehdään vähintään kolme maalia. Jos tasan kaksi maalia tehdään, puolet vedosta palautetaan ja toinen puoli voittaa. Ei maaleja tai yksi maali, veto häviää.<br></br>
              Alle: Veto voittaa, jos ottelussa tehdään yksi tai ei lainkaan maaleja. Jos tehdään tasan kaksi maalia, puolet summasta palautetaan ja toinen puoli häviää. Veto häviää, jos tehdään vähintään kolme maalia.
            </ListItem>
            <ListItem>
              Yli/Alle 2.5<br></br>
              Yli: Veto voittaa, jos ottelussa tehdään vähintään kolme maalia. Jos tehdään kaksi tai vähemmän maaleja, veto häviää.<br></br>
              Alle: Veto voittaa, jos pelissä tehdään kaksi tai vähemmän maaleja. Kolmella tai useammalla maalilla veto häviää.
            </ListItem>
            <ListItem>
              Yli/Alle 2.75<br></br>
              Yli: Veto voittaa, jos tehdään vähintään neljä maalia. Jos tasan kolme maalia, puolet vedosta palautetaan ja toinen puoli voittaa. Jos ei maaleja, yksi tai kaksi maalia, veto häviää.<br></br>
              Alle: Veto voittaa, jos ei maaleja, yksi tai kaksi maalia. Jos tehdään tasan kolme maalia, puolet vedosta palautetaan ja toinen puoli häviää. Veto häviää, jos tehdään vähintään kolme maalia.
            </ListItem>
            <ListItem>
              Yli/Alle 3<br></br>
              Yli: Veto voittaa, jos ottelussa tehdään vähintään neljä maalia. Jos tasan kolme maalia, veto palautetaan. Jos ei maaleja, yksi tai kaksi maalia, veto häviää.<br></br>
              Alle: Veto voittaa, jos ei maaleja, yksi tai kaksi maalia. Jos tasan kolme maalia, veto palautetaan. Neljällä tai useammalla maalilla veto häviää.
            </ListItem>
            <br></br>
            Kaikki muut aasialaiset Yli/Alle -kohteet, joilla on erilaiset raja-arvot (x, x.25, x.5, x.75, x+1) toimivat samalla tavalla.<br></br>
            <br></br>
            3.2.12. Molemmat tekee maalin<br></br>
            Molemmat tekee maalin kohteessa oin seuraavat vaihtoehdot.
            <ListItem>Kyllä: Molemmat joukkueet tekevät vähintään yhden maalin.</ListItem>
            <ListItem>Ei: Molemmat joukkueet eivät tee maalia.</ListItem>
            <br></br>
            3.2.13. Pariton/Parillinen<br></br>
            Onko ottelun maalien/pisteiden kokonaismäärä pariton vai parillinen luku. Nolla katsotaan parilliseksi luvuksi.<br></br>
            <br></br>
            3.2.14. Tulosveto<br></br>
            Vedonlyönti siitä, mikä on ottelun tarkka tulos varsinaisen peliajan lopussa. Jos ottelun tulos ei ole valittu vaihtoehto, veto häviää.<br></br>
            <br></br>
            3.2.15. Puoliaika/Lopputulos<br></br>
            Mikä on ottelun tulos 1. puoliajan ja varsinaisen peliajan jälkeen? Ensimmäinen arvo on puoliaikatulokselle ja toinen lopputulokselle. Puoliaika/Lopputulos -vedossa seuraavat vaihtoehdot ovat tarjolla alla
            kuvatulla tavalla:<br></br>
            <br></br>
            <ListItem>1/1 = kotivoitto / kotivoitto</ListItem>
            <ListItem>1/X = kotivoitto / tasapeli</ListItem>
            <ListItem>1/2 = kotivoitto / vierasvoitto</ListItem>
            <ListItem>X/1 = tasapeli / kotivoitto</ListItem>
            <ListItem>X/X = tasapeli / tasapeli</ListItem>
            <ListItem>X/2 = tasapeli / vierasvoitto</ListItem>
            <ListItem>2/1 = vierasvoitto / kotivoitto</ListItem>
            <ListItem>2/X = vierasvoitto / tasapeli</ListItem>
            <ListItem>2/2 = vierasvoitto / vierasvoitto</ListItem>
            <br></br>
            3.2.16. Aikaväli-vedonlyönti<br></br>
            Mikä on 1. puoliajan, 2. puoliajan tai vastaavan aikavälin tulos (jaksot, neljännekset, vuoroparit,...).<br></br>
            <br></br>
            3.2.17. Ensimmäinen maali<br></br>
            Ensimmäinen maali - vedossa seuraavat tulosvaihtoehdot ovat käytettävissä:<br></br>
            <br></br>
            <ListItem>1 = kotijoukkue tekee ensimmäisen maalin</ListItem>
            <ListItem>X = ei maalia (0:0)</ListItem>
            <ListItem>2 = vierasjoukkue tekee ensimmäisen maalin</ListItem>
            <br></br>
            3.2.18. Head-to-Head (H2H)<br></br>
            Head-to-Head on kahden osallistujan välinen kilpailu, jossa asiakas lyö vetoa siitä, kumpi näistä saa parhaan lopullisen sijoituksen tai lopputuloksen tapahtumassa. Jos jompikumpi tai molemmat eivät aloita
            tapahtumaa tai jos kumpikaan heistä ei saa tulosta, panos palautetaan. Jos molemmat aloittavat tapahtuman, mutta vain toinen heistä saa tuloksen, veto pysyy voimassa. Tapahtuman aloittaminen tarkoittaa, että
            osallistuja aloittaa ensimmäisen hyppynsä, heittonsa jne.<br></br>
            <br></br>
            3.2.19. Millä puoliajalla/jaksolla/neljänneksellä eniten maaleja/pisteitä<br></br>
            Kohteessa arvioidaan millä puoliajalla/jaksolla/neljänneksellä tulee eniten maaleja/pisteitä.<br></br>
            <br></br>
            3.2.20. Joukkue Yli/Alle<br></br>
            Tämä on veto koti – tai vierasjoukkueen tekemien maalien/pisteiden määrästä yli tai alle määritetyn lukumäärän.<br></br>
            <br></br>
            3.2.21. Pelaaja-vedot<br></br>
            Kaikkien kohteessa listattujen pelaajien on osallistuttava (saatava todellinen virallinen peliaika), jotta vedot ovat voimassa.<br></br>
            <br></br>
            3.2.22. Avausmaalin syntyaika<br></br>
            Milloin ottelun ensimmäinen maali tehdään. Selvyyden vuoksi ottelun 1. minuutti on 0:00 - 0:59, 2. minuutti on 1:00 - 1:59, 3. minuutti on 2:00 - 2:59 ja niin edelleen.<br></br>
            <br></br>
            3.2.23. Voittajaveto<br></br>
            Mikä joukkue/osallistuja voittaa tapahtuman.<br></br>
            <br></br>
            3.2.24. Voittajaveto tasoituksella<br></br>
            Joukkueille annetaan piste-etu. Mitkä ovat sijoitukset sen jälkeen, kun tämä piste-etu on otettu huomioon. Piste-etua lukuun ottamatta taulukko lasketaan kilpailun järjestäjän sääntöjen mukaisesti.<br></br>
            <br></br>
            3.2.25. Top X<br></br>
            Mikä joukkue / osallistuja päätyy määriteltyjen sijoitusten joukkoon (esim. 2-50) tietyssä kilpailussa.<br></br>
            <br></br>
            3.2.26. Triplehead (H3H)<br></br>
            Kolme kilpailijaa asetetaan toisiaan vasten. Veikkaa, mikä näistä kolmesta saavuttaa parhaan lopullisen sijoituksen. Kaikkien osallistujien on aloitettava tapahtuma ja vähintään yhden on saavutettava
            lopullinen tulos/sijoitus, jotta veto pysyy voimassa.<br></br>
            <br></br>
            3.2.27. Live-vedonlyönti<br></br>
            Hyväksyttyä live-vetoa ei voi enää peruuttaa. Jos tiedonsiirto viivästyy ja/tai TV-lähetys siirtyy live-vedonlyönnissä ja siitä seuraa merkittävä muutos pelin etenemisessä, jota ei ole otettu huomioon
            kertoimia laskettaessa, Nubet pidättää oikeuden julistaa vedot mitättömiksi. Jos livevedonlyönnin vetotositteella näkyy väärä puoliaika- tai välitilanne, veto mitätöidään tai yhdistelmävedoissa valinnalle
            annetaan kerroin 1,00. Tämä koskee myös väärin määriteltyjä kohteita tai virheellisiä kertoimia.<br></br>
            <br></br>
            Jos ottelun alkamisen ja vedon asettamisen jälkeen vedonlyöntimarkkinat eivät ole enää käytettävissä (esim. tiedonsiirto-ongelmat jne.), vetotosite arvioidaan vasta ottelun päättymisen jälkeen virallisten
            tulosten perusteella.<br></br>
            <br></br>
            <h3 id="Specificrules">4. LAJIKOHTAISET SÄÄNNÖT</h3>
            <h4>4.1. Amerikkalainen jalkapallo</h4>
            4.1.1. Jatkoaika ei sisälly tulokseen, ellei toisin mainita. Jos ottelu päättyy tasapeliin jatkoajan jälkeen, vedot mitätöidään ja kertoimeksi asetetaan 1.00.<br></br>
            <br></br>
            4.1.2. Jos ottelu peruutetaan tai siirretään, kaikki ratkaisemattomat kohteet mitätöidään, ellei ottelu jatku samassa viikko-ohjelmassa (torstaista keskiviikkoon paikallista aikaa).<br></br>
            <br></br>
            4.1.3. Betbuilder -vedossa, kaikki toisen puoliajan ja neljännen neljänneksen valinnat sisältävät mahdollisen jatkoajan. Esimerkiksi, toisen puoliajan Yli/Alle -kohteessa huomioidaan pisteet toisella
            puoliajalla sekä mahdollisella jatkoajalla.<br></br>
            <br></br>
            <h4>4.2. Yleisurheilu</h4>
            4.2.1. Jos tapahtumaa keskeytetään tai viivästyy, vedot pysyvät voimassa, jos tapahtuma aloitetaan 72 tunnin kuluessa alun perin ilmoitetusta alkamisajasta.<br></br>
            <br></br>
            <h4>4.3. Baseball</h4>
            4.3.1. Lisävuoroparit eivät sisälly tulokseen, ellei toisin mainita<br></br>
            <br></br>
            4.3.2. Kaikki joukkueiden saman päivän tupla-pelit (double header) ovat voimassa normaalisti.<br></br>
            <br></br>
            4.3.3. Jos ottelu keskeytyy, kaikki ratkaisemattomat kohteet voittaja (moneyline) markkinaa lukuunottamatta mitätöidään ellei peli jatku 12 tunnin sisällä ja pelattuna on 5 täyttä vuoroparia ottelun alusta asti.<br></br>
            <br></br>
            4.3.4. Jos ottelu keskeytetään tai perutaan, vedot ovat voimassa jos alkuperäinen ottelu alkaa 24 kuluessa alkuperäisestä alkamisajasta ja vähintään 7 vuoroparia on pelattu. Jos ottelu ei ala 24 tunnin sisällä vedot mitätöidään.<br></br>
            <br></br>
            4.3.5. Kaikki vedot ovat voimassa riippumatta aloittavista syöttäjistä tai aloittavan syöttäjän muutoksista.<br></br>
            <br></br>
            <h4>4.4. Koripallo</h4>
            4.4.1. Jatkoaika ei sisälly tulokseen, ellei toisin mainita.<br></br>
            <br></br>
            4.4.2. Jos ottelu ei pääty tasapeliin, mutta jatkoaika pelataan karsintatarkoituksessa, kohteet ratkaistaan varsinaisen peliajan tuloksen mukaan.<br></br>
            <br></br>
            4.4.3. Vetosekoitin-vedossa, kaikki toisen puoliajan ja neljännen neljänneksen valinnat sisältävät mahdollisen jatkoajan. Esimerkiksi, toisen puoliajan Yli/Alle -kohteessa huomioidaan pisteet toisella
            puoliajalla sekä mahdollisella jatkoajalla.<br></br>
            <br></br>
            <h4>4.5. Nyrkkeily</h4>
            4.5.1. Kaikki markkinat ratkaistaan heti ottelun päättymisen jälkeen saatavilla olevan tuloksen mukaan. Mahdollisia myöhempiä valituksia tai tuloksen muutoksia ei oteta huomioon.<br></br>
            <br></br>
            4.5.2. Jos jompikumpi ottelija ei vastaa seuraavan erän kelloon, hänen vastustajansa katsotaan voittaneen edellisessä erässä.<br></br>
            <br></br>
            4.5.3. Jos jompi kumpi ottelijoista ei osallistu tai korvataan, vedot mitätöidään.<br></br>
            <br></br>
            4.5.4. Jos ottelu julistetaan ei-kilpailuksi (No Contest), kaikki vedot mitätöidään.<br></br>
            <br></br>
            4.5.5. Jos alkuperäisen mukaista oteltujen erien määrää muutetaan ennen ottelua, kaikki "Erien lukumäärä", "Voittaja ja erät" ja "Voittotapa" -vedot mitätöidään.<br></br>
            <br></br>
            4.5.6. Puolet erästä on läpäistävä, jotta se voidaan huomioida erien lukumäärävedoissa.<br></br>
            <br></br>
            4.5.7. Kohteissa, joissa ilmoitetaan puolikas erä, 1 minuutti 30 sekuntia vastaavasta erästä määrittää puolikkaan, joka määritetään alle tai yli. Näin ollen 9.5 erää olisi yksi minuutti ja kolmekymmentä
            sekuntia 10. erästä. Jos ottelu päättyy täsmälleen 1 minuutti 30 sekuntia 10. erästä, tulos olisi yli 9.5 erää.<br></br>
            <br></br>
            4.5.8. Jos nyrkkeilijä ei vastaa seuraavan erän kelloon, hänen vastustajansa katsotaan voittaneen kilpailun edellisessä erässä. Jos tekninen päätös tehdään ennen ottelun päättymistä, kaikki vedot ratkaistaan
            päätöksellä (Win by Decision) ja erävedot katsotaan häviäjiksi.<br></br>
            <br></br>
            <h4>4.6. Kriketti</h4>
            4.6.1. Markkinat eivät ota huomioon lisävuoropareja (super-over), ellei toisin mainita.<br></br>
            <br></br>
            <h4>4.7. Jalkapallo</h4>
            4.7.1. Aikaväli -vedonlyönti:<br></br>
            <br></br>
            4.7.1.1. Aikavälit määritellään seuraavasti: 1-10 minuuttia tarkoittaa 0:00-9:59, 11-20 minuuttia tarkoittaa 10:00-19:59, jne. 1-15 minuttia tarkoittaa 00:00-14:59, 16-30 minuuttia tarkoittaa 15:00-29:59, jne.<br></br>
            <br></br>
            4.7.1.2. Erotuomarin myöntämä lisäaika otetaan huomioon aikaväli -vedonlyönnissä.<br></br>
            <br></br>
            4.7.2. Maalintekijävedot:<br></br>
            <br></br>
            4.7.2.1. Omia maaleja ei huomioida vetojen ratkaisemisen yhteydessä (paitsi silloin, kun valinnoissa on mukana ei maalia, muut ja oma maali -valinnat).<br></br>
            <br></br>
            4.7.2.2. Jos listaamaton pelaaja jostain syystä tekee maalin, kaikki listattujen pelaajien vedot ovat voimassa.<br></br>
            <br></br>
            4.7.2.3. Kaikki pelaajat, jotka osallistuivat otteluun ottelun alkaessa tai edellisen maalin jälkeen, huomioidaan.<br></br>
            <br></br>
            4.7.2.4. Kaikki pelaajat, jotka osallistuvat tällä hetkellä, huomioidaan.<br></br>
            <br></br>
            4.7.2.5. Jos listaamaton pelaaja jostain syystä tekee maalin, kaikki listattujen pelaajien vedot ovat voimassa.<br></br>
            <br></br>
            4.7.2.6. Markkinat ratkaistaan televisio-kuvan ja Press Associationin toimittamien tilastojen perusteella, ellei ole syytä epäillä, että nämä tilastot eivät ole oikeita. Betbuilder -kohteissa käytetään
            jalkapallossa StatsBombin julkaisemia tilastoja virallisena lähteenä ja ne voivat täten erota muiden vedonlyöntikohteiden tuloksista.<br></br>
            <br></br>
            4.7.3. Kortti- ja korttipiste -pelikohteet:<br></br>
            <br></br>
            4.7.3.1. Kortti-pelikohteissa keltainen kortti vastaa yhtä korttia ja punainen tai kelta-punainen kahta kortti. Pelaajan toista keltaista, joka johtaa kelta-punaiseen korttiin, ei oteta huomioon. Näin ollen
            yksi pelaaja ei voi aiheuttaa enempää kuin 3 korttia. Jos pelikohteen kuvauksessa mainitaan täsmällisesti, että se koskee vain keltaisia tai punaisia kortteja, pelikohdetta ei pidetä yleisenä kortti-kohteena (bookings), eikä edellä mainittua sääntöä sovelleta.<br></br>
            <br></br>
            4.7.3.2. Korttipiste-pelikohteissa keltainen kortti vastaa 10 pistettä ja punaiset tai kelta-punaiset kortit 25 pistettä. Pelaajan toista keltaista, joka johtaa kelta-punaiseen korttiin, ei oteta huomioon.
            Näin ollen yksi pelaaja ei voi aiheuttaa enempää kuin 35 korttipistettä. Jos pelikohteen kuvauksessa mainitaan täsmällisesti, että se koskee vain keltaisia tai punaisia kortteja, pelikohdetta ei pidetä yleisenä korttipiste-kohteena (booking points), eikä edellä mainittua sääntöä sovelleta.<br></br>
            <br></br>
            4.7.3.3. Voitonmaksu suoritetaan kaikkien saatavilla olevien todisteiden perusteella korteista, jotka näytetään varsinaisella peliajalla.<br></br>
            <br></br>
            4.7.3.4. Ottelun jälkeen tuomittavia kortteja ei huomioida.<br></br>
            <br></br>
            4.7.3.5. Muiden kuin kenttäpelaajien (vaihtoon tulleet pelaajat, managerit, penkillä olevat pelaajat) kortteja ei huomioida.<br></br>
            <br></br>
            4.7.4. Kulmapotku -vedot<br></br> Tuomittuja mutta ei suoritettuja kulmapotkuja ei huomioida.<br></br>
            <br></br>
            4.7.5. Tilanteet, missä pallo osuu maalin kehikkoon ja sen seurauksena pallo menee suoraan maaliin ei huomioida maalikehikkoon osumisena.<br></br>
            <br></br>
            <h4>4.8. Golf</h4>
            4.8.1. 2-Balls: Kumpi pelaaja selviää pienimmällä lyöntimäärällä määritellyllä kierroksella. Mikäli kierrosta ei ole määritelty, on kyseessä seuraava kierros. Jos molemmat pelaajat keskeyttävät samalla
            reiällä, vedot mitätöidään. Vedot mitätöidään, jos molemmat kilpailijat saavuttavat saman lyöntimäärän kyseisellä kierroksella. Pelaajaa osallistuu kilpailuun, jos hän suorittaa avauslyönnin ensimmäisellä
            reiällä.<br></br>
            <br></br>
            4.8.2. 3-Balls: Kuka listatuista pelaajista selviää pienimmällä lyöntimäärällä määritellyllä kierroksella. Mikäli kierrosta ei ole määritelty, on kyseessä seuraava kierros. Tasapääjuoksu -sääntö pätee.
            <br></br>
            <br></br>
            4.8.3. Voittajaveto: Kaikki voittajavedot ratkaistaan pelaajalle, joka on saanut pokaalin. Mahdollisten jatkoreikien tulos otetaan huomioon. Jos kilpailua muutetaan alkuperäisen suunnitelman mukaisesta
            reikien määrästä mistä tahansa syystä (esim. huonot sääolosuhteet), ennen viimeistä päättynyttä kierrosta asetetut voittajavedot (lukuun ottamatta "Ensimmäisen kierroksen voittaja" vetoja) ratkaistaan
            pokaalin saaneelle pelaajalle.<br></br>
            <br></br>
            4.8.4. Ryhmävedonlyönti: Voittaja on pelaaja, joka saavuttaa korkeimman sijoituksen turnauksen lopussa. Tasapääjuoksu -sääntöjä sovelletaan, paitsi jos voittaja määräytyy pudotuspelien perusteella. Jos
            tarjoamme ryhmävedonlyöntiä ja vain yksi kilpailija aloittaa, vedot mitätöidään.<br></br>
            <br></br>
            4.8.5. Nimetyn pelaajan loppusijoitus: Jos loppusijoitus on jaettu, jaettu sija huomioidaan. Esimerkiksi jaettu 7. sija neljän muun pelaajan kanssa, lasketaan 7. sijan loppusijoitukseksi.<br></br>
            <br></br>
            4.8.6. Kierroksen voittaja: Ratkaisu perustuu lyöntimäärään määritetyn kierroksen lopussa. Tasapääjuoksu -sääntöjä sovelletaan.<br></br>
            <br></br>
            4.8.7. Kilpailun Top 4/Top 5/top 6/Top 10/Top 20: Tasapääjuoksu -sääntöjä sovelletaan.<br></br>
            <br></br>
            4.8.8. Pelaajan katsotaan pelanneen turnauksessa tai tietyllä kierroksella, kun hän on suorittanut avauslyönnin. Jos pelaaja keskeyttää tai hylätään sen jälkeen, kun hän on suorittanut avauslyönnin, vedot
            ovat voimassa.<br></br>
            <br></br>
            4.8.9. Turnauksissa, joihin vaikuttaa huono sää tai muut vastaavat syyt, vedot perustuvat viralliseen tulokseen riippumatta pelattujen kierrosten määrästä.<br></br>
            <br></br>
            4.8.10. Jos turnaus keskeytetään, kaikki viimeisen päättyneen kierroksen jälkeen asetetut vedot mitätöidään.<br></br>
            <br></br>
            4.8.11. Virallisia kiertuepaikan tuloksia palkintojenjaon yhteydessä käytetään voitonmaksuun (myöhempää hylkäämistä tämän ajan jälkeen ei huomioida).<br></br>
            <br></br>
            4.8.12. Ryhmät, joita ei pelata suunnitellulla tavalla: Jos kyseessä on poissaolija, 2-ball ja 3-ball vedot mitätöidään.<br></br>
            <br></br>
            <h4>4.9. Jääkiekko</h4>
            4.9.1. Kaikissa kohteissa (lukuun ottamatta kausi-, jatkoaika- ja rangaistuslaukausmarkkinoita) otetaan huomioon vain varsinainen peliaika, ellei toisin mainita.<br></br>
            <br></br>
            4.9.2. Jos peli ratkaistaan rangaistuslaukauskilpailulla, yksi maali lisätään voittajajoukkueen tulokseen ja pelin kokonaismaalimäärään. Tämä koskee kaikkia markkinoita, joissa huomioidaan jatkoaika ja
            rangaistuslaukauskilpailu.<br></br>
            <br></br>
            4.9.3. Jos maalivahti ei aloita ottelua niin kyseistä maalivahtia koskevat vedot mitätöidään.<br></br>
            <br></br>
            4.9.4. Betbuilder -vedossa, kaikki 3. erän kohdevalinnat sisältävät jatkoajan ja rl-kilpailun. Esimerkiksi yli/alle -kohde kolmannessa erässä sisältää 3. erän maalit ja mahdollisen voittomaalin jatkoajalla tai rl-kilpailussa.<br></br>
            <br></br>
            <h4>4.10. Moottoriurheilu</h4>
            4.10.1. Jos tapahtuma keskeytetään tai viivästyy, vedot pysyvät voimassa, jos tapahtuma suoritetaan loppuun 72 tunnin kuluessa alun perin ilmoitetusta alkamisajasta.<br></br>
            <br></br>
            4.10.2. Kaikki kohteet ratkaistaan podium -seremonian perusteella tai enintään 15 minuuttia sen jälkeen.<br></br>
            <br></br>
            4.10.3. Jos olosuhteista johtuen kilpailussa ajetaan kierroksia suunniteltua pienempi määrä tai kilpailu suoritetaan aika-ajona, tulos määräytyy virallisen kilpailun järjerjestäjä ilmoituksen perusteella.<br></br>
            <br></br>
            <h4>4.11. Mixed Martial Arts (MMA)</h4>
            4.11.1. Kaikki markkinat ratkaistaan heti ottelun päättymisen jälkeen saatavilla olevan tuloksen mukaan. Mahdollisia myöhempiä valituksia tai tuloksen muutoksia ei oteta huomioon. 4.11.2. Jos jompikumpi
            ottelija ei vastaa seuraavan erän kelloon, hänen vastustajansa katsotaan voittaneen edellisessä erässä. 4.11.3. Jos jompi kumpi ottelijoista ei osallistu tai korvataan, vedot mitätöidään. 4.11.4. Jos ottelu
            julistetaan ei-kilpailuksi (No Contest), kaikki vedot mitätöidään. 4.11.5. Jos alkuperäisen mukaista oteltujen erien määrää muutetaan ennen ottelua, kaikki "Erien lukumäärä", "Voittaja ja erät" ja
            "Voittotapa" -vedot mitätöidään. 4.11.6. Puolet erästä on läpäistävä, jotta se voidaan huomioida erien lukumäärävedoissa. 4.11.7. Kaikki vedot ratkaistaan ilmoitetun virallisen tuloksen perusteella.
            Hylkäysvoitto lasketaan tyrmäykseksi/tekniseksi tyrmäykseksi. 4.11.8. Voittotapa -markkinoilla tyrmäys (KO) sisältää seuraavat:<br></br>
            <br></br>
            <ListItem>Erotuomarin keskeytys iskujen vuoksi, kun jompikumpi kilpailija tai molemmat seisovat</ListItem>
            <ListItem>Erotuomarin keskeytys iskujen vuoksi, kun jompikumpi kilpailija tai molemmat ovat kanveesissa</ListItem>
            <ListItem>Lääkärin keskeytys</ListItem>
            <ListItem>Kilpailijan kulman/joukkueen luovutus</ListItem>
            <ListItem>Kilpailijan vetäytyminen loukkaantumisen vuoksi</ListItem>
            <ListItem>Hylkäysvoitto</ListItem>
            <br></br>
            4.11.8.1. Voittotapa -markkinoilla mattoonvienti (submission) sisältää seuraavat:<br></br>
            <br></br>
            <ListItem>Erotuomarin keskeytys (tap-out)</ListItem>
            <ListItem>Erotuomarin keskeytys (technical submission)</ListItem>
            <ListItem>Kilpailijan verbaalinen keskeytys</ListItem>
            <ListItem>Aikataulun ja/tai ottelupäivän muutos</ListItem>
            <br></br>
            <h4>4.12. Tennis</h4>
            4.12.1. Kaikissa vedoissa, jotka viittaavat pelattujen pelien määrään, tie-break lasketaan yhdeksi peliksi.<br></br>
            <br></br>
            4.12.2. Voitonmaksu keskeytystilanteessa:<br></br>
            <br></br>
            4.12.2.1. Voittaja: Vähintään yksi täysi erä pitää olla pelattuna jotta voittaja pelikohde voidaan ratkaista.<br></br>
            <br></br>
            4.12.2.2. Kaikki muut ratkaisemattomat pelikohteet mitätöidään. Tuloksen saaneet kohteet  ovat voimassa.<br></br>
            <br></br>
            4.12.3. Jos pelaajat/joukkueet näytetään väärin, pidätämme oikeuden mitätöidä vedonlyönnin.<br></br>
            <br></br>
            4.12.4. Seuraavissa tilanteissa kaikki vedot ovat voimassa:<br></br>
            <br></br>
            <ListItem>Aikataulun ja/tai ottelupäivän muutos</ListItem>
            <ListItem>Ottelupaikan muutos</ListItem>
            <ListItem>Muutos sisäkentästä ulkokenttään tai päinvastoin</ListItem>
            <ListItem>Pinnan muutos (joko ennen ottelua tai sen aikana)</ListItem>
            <br></br>
            <h4>4.13. Ravit</h4>
            4.13.1. Jos kilpailija ilmoitetaan poissaolevaksi kilpailusta, kaikki kyseiselle kilpailijalle asetetut vedot palautetaan (edellyttäen, että vetoa ei ole asetettu pitkäaikaisvetona ennen kuin lähtölista on
            virallisesti julistettu). Kaikkien muiden kilpailijoiden kertoimet lasketaan uudelleen. Uudelleenlaskenta tehdään kertomalla kilpailijoiden kertoimet korjauskertoimella (1 – poissaolevan kilpailijan
            marginaalikorjattu todennäköisyys) sillä hetkellä, kun tieto poisjäännistä vastaanotetaan. Jos useampi kilpailija jää pois kilpailusta myöhemmin, sama prosessi toistetaan.<br></br>
            <br></br>
            <h4>4.14. Talviurheilu</h4>
            4.14.1. Jos tapahtuma keskeytetään tai viivästyy, vedot pysyvät voimassa, jos tapahtuma suoritetaan loppuun 72 tunnin kuluessa alun perin ilmoitetusta alkamisajasta.<br></br>
            <br></br>
            <h4>4.15. Esports</h4>
            4.15.1. Teknisen tappion sattuessa kaikki vedot mitätöidään, ellei tulos ole jo ratkennut.<br></br><br></br>
            Tekninen tappio voidaan julistaa seuraavista syistä:<br></br><br></br>
            a. Pelaaja/joukkue ei ilmesty paikalle<br></br>
            b. Ennenaikainen vetäytyminen turnauksen aikana<br></br>
            c. Pelin lopettaminen ennen sen päättymistä<br></br>
            d. Virheellisen pelaajan osallistuminen<br></br>
            e. Turnauksen sääntöjen tai urheilullisen käyttäytymisen rikkominen.<br></br>
            f. Pelaajan loukkaantuminen tai välinehäiriö, joka estää pelin jatkamisen.<br></br>
            g. Peli, jossa pelaaja tai striimaaja osallisena, kaatuu.<br></br>
            h. Pelaajan tai joukkueen jäsenen hylkääminen.<br></br><br></br>
            4.15.2. Jos tekninen tappio tuomitaan pelin päätyttyä, kaikki vedot ratkaistaan, kun
            tulos sellaisena kuin se on pelattu.<br></br><br></br>

            4.15.3. Jos kyseessä on todistettavasti epäreilu urheilukilpailu, kaikki vedot joko keskeytetään ajaksi 72 tuntiin asti tai julistetaan mitättömiksi harkintamme mukaan.<br></br><br></br>

            4.15.4. Epäreilu urheilukilpailu määritellään seuraavasti:<br></br>
            <ListItem>Edun hankkiminen pelissä petoksella, harhaanjohtamisella, salaisella yhteistyöllä, huijaamisella, pelaamalla kiellettyjä pelaajan apuvälineitä, kuten aimbotteja, ja pelissä olevien virheiden hyväksikäyttöä.</ListItem>
            <ListItem>Muut toimet, jotka kyseenalaistavat pelin rehellisyyden.</ListItem><br></br>

            4.15.5. Esports-otteluiden tavanomaisia muotoja ovat joskus Bo1, Bo2, Bo3 jne. (Best of 1, 2, 3, 5,jne.) - ottelun karttojen kokonaismäärä, jossa on saavutettava enemmistö karttojen
            voittoja. Ottelun voittaja määräytyy voitettujen karttojen summan perusteella, esimerkiksi Bo3 - vähintään 2 karttaa, Bo5:een tarvitaan 3 voittoa ja niin edelleen.<br></br><br></br>

            4.15.6. Muissa urheilulajeissa, kuten Dota 2, League of Legends, Wild Rift, King of
            Glory jne. perustuu tietoihin, jotka on tallennettu välittömästi sen jälkeen, kun pääpeli on tuhoutunut rakennuksen (Throne/Fortress/Nexus) tuhoamisen jälkeen. Vastaavasti laskelma tehdään jos toinen joukkueista antautuu (valtaistuin/linnoitus/nexus ei tuhoudu suoraan pelissä) vastustajan tuhoa tässä tapauksessa). Voitto annetaan antautuvan joukkueen vastustajalle.<br></br><br></br>

            4.15.7. Jos jokin tietty joukkue saa merkittävän edun tapahtuman alussa, säännöt tai erotuomari voi tehdä päätöksen kartan voiton myöntämisestä oletusarvoisesti. Vedot katsotaan tällöin päteviksi ja pelatuiksi.<br></br><br></br>

            4.15.8. Jos ottelu/kierros keskeytetään ja se pelataan uudelleen seuraavan 48 tunnin kuluessa, kaikki vedot, jotka koskevat joiden tulos oli tiedossa keskeytyshetkellä, pysyvät voimassa ja ne lasketaan ratkaistaan tämänhetkisen tuloksen perusteella. Karttamarkkinoiden ratkaisemattoma vedot mitätöidään. Ottelua koskevat vedot pysyvät voimassa ja ne ratkaistaan ottelun lopullisen tuloksen perusteella.<br></br><br></br>

            4.15.9.Hylkäyksen yhteydessä, kaikki markkinat (ottelu- ja kausimarkkinat) mitätöidään.
            lukuun ottamatta niitä, joiden tulos on jo vahvistettu ja ratkaistu.<br></br><br></br>

            4.15.10. Jos ottelu/kartta keskeytyy ja se pelataan uudelleen seuraavan 48 tunnin kuluessa, kaikki vedot, jotka koskevat joiden tulos oli tiedossa keskeytyshetkellä, pysyvät voimassa ja ne palautetaan ratkaistaan tämänhetkisen tuloksen perusteella. Karttamarkkinoiden määrittelemättömät vedot mitätöidään. Ottelua koskevat vedot pysyvät voimassa ja ne ratkaistaan ottelun lopullisen tuloksen perusteella.<br></br><br></br>

            4.15.11. Jos ottelumuodon muutos tarkoittaa muutosta ottelun suunnitellussa karttojen määrässä, niin karttamarkkinoille asetetut vedot ratkaistaan vastaavasti, kun taas ottelumarkkinoille asetetut vedot (mukaan lukien ottelun voittaja, ottelun tarkka tulos, tasoitukset kartoittain, yli/alle ja parittomat/parittomat kartat) mitätöidään.<br></br><br></br>

            4.15.12. Ottelumuodon muutos sisältää myös järjestäjien päätöksen myöntää pelaajalle (tai pelaajille) joukkueelle) yhden jakson etu (esim. CS-ottelu, joka alkaa karttatuloksella 1-0). Tässä tällöin kaikkien seuraavien jaksojen (ensimmäisen jakson jälkeen) markkinoille asetetut vedot ratkaistaan vastaavasti, kun taas ottelumarkkinoille asetetut vedot (mukaan lukien ottelun voittaja, tarkka ottelutulos, tasoitukset kartoittain, yli/alle ja pariton/parillinen määrä karttoja) mitätöidään.<br></br><br></br>

            4.15.13. Jos joukkueen nimi säilyy ennallaan, mutta pelaajaluettelo muuttuu (yli 50 % joukkueesta jäseniä) sen jälkeen, kun ottelu on ilmoitettu, pidätämme oikeuden mitätöidä kaikki vedot.<br></br><br></br>

          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(BettingRules));
