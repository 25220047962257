import { SHOULD_ALERT_ODDS_UPDATE } from "../actions/types";

const INITIAL_STATE = {
  data: {},
  shouldAlert: false,
};

const fn = (state = INITIAL_STATE, action) => {
  let newState = {};
  switch (action.type) {
    case SHOULD_ALERT_ODDS_UPDATE:
      newState = { shouldAlert: action.isOpen, data: action.payload ? action.payload: {} };
      return newState;
    default:
      return state;
  }
};

export default fn;
