/**
 * Reducer for email confirmation
 */

import { CONFIRM_EMAIL_ADDRESS, RESEND_CONFIRM_EMAIL_ADDRESS } from "../actions/types";

const INTIAL_STATE = {};

const fn = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case CONFIRM_EMAIL_ADDRESS:     
      return action.payload;
      
    case RESEND_CONFIRM_EMAIL_ADDRESS:      
      return action.payload;
      
    default:
      return state;
  }
};

export default fn;