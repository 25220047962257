/**
 * Component for game box detail avatar svg
 */

import { log } from '../../../../config/log';
import React from 'react';
import { connect } from 'react-redux';

import './GameBoxDetailedAvatar.css';

class GameBoxDetailedAvatarTE extends React.PureComponent {

  renderShirt() {
    const { pattern, color1, color2 } = this.props;

    switch (pattern) {

      case "1":
        return (
          <div>
            <svg className="team-avatar" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="3em" height="3em"
              viewBox="0 0 520.7 565.9" style={{ enableBackground: "0 0 520.7 565.9" }} xmlSpace="preserve">
              <path fill={color1} d="M488.6,270.4c-16.5-116.2-39.9-172.6-45.2-180C436,79.8,430.4,77,429.1,76.5c0-0.1,0-0.1,0-0.1
                L317.8,31.7c0,0-18.8,14.5-55.8,13.2C225.1,46.2,206,31.7,206,31.7L94.7,76.4c0,0-6.1,1.8-14.7,14c-4.9,7-25.5,56.2-41.6,156.6
                c-1.3,7.9-2.5,16.1-3.7,24.6c-3.5,24.5,75.2,3.8,75.2,3.8l2.9-19.6c0.9,15.1,1.4,29.1,1.4,41.3c0,74.4-19.4,238.5-19.4,238.5
                c79.4,19.6,167.2,20,167.2,20s87.8-0.3,167.2-20c0,0-19.4-164.1-19.4-238.5c0-11.8,0.5-25.4,1.3-39.9l3.4,23
                C414.3,280.1,492.2,295.2,488.6,270.4z"/>
              <path fill={'#00000060'} d="M429.1,76.4l-93-37.3
                c-5.8,17.2-17.3,32.7-10.9,26.8c8.9-8.1,18-12.5,30.3-10.3c4.7,0.9,9.8,2.4,14.7,4.5c7.8,2.9,15.3,6.7,22.6,10.8
                c7.5,4.1,16,9,20.1,16.8c5.6,10.7,5.1,24.3,4.6,35.9c-0.6,12.8-2.4,25.3-4.2,37.9c-0.8,5.5-2.1,16.9-10.6,16.3
                c-6.9-0.5-10.5-7.5-13-13c-8.3-18-9.1-20.6-16-39.1c6.4,23,8.6,29.4,14.9,52.5c2.4,8.9,5.1,17.9,6,27.1c0.5,4.9,1.3,11.8-2.8,15.6
                c-3.8,3.5-10.6-0.6-13.5-3.3c-7.4-6.8-12-16.1-16.4-24.9c-8.2-16.4-18.4-31.7-27.6-47.5c16.9,29.2,29.9,64.6,42.2,96
                c14.7,37.7,21.5,77.4,26.6,117.2c5.4,41.7,21,110.5,0.2,150.2c-10.3,19.7-11.4,17.3-39.5,31.5c-12.7,6.4-50.2,12-68,14.1
                c0.2,0.2,18.7-1.6,18.8-1.3c32-2.5,74.3-7.5,114.4-17.4c0,0-19.4-164.1-19.4-238.5C409.7,222.5,429.1,76.4,429.1,76.4z"/>
              <path fill={'#00000060'} d="M143.7,528.5c-10.7-8.4-18.1-20-20.7-33.2c-6.6-34.3,7.6-68.2,16-101c5.2-20.2,9.8-40.6,16.3-60.4
                c6.1-18.7,24.1-61.2,26.4-80.8c-1.4,11.5-21.5,48.2-27.7,58.2c-4.5,7.2-14.8,25.4-26,20.6c-7.6-3.3-2.6-20.2-1.8-25.6
                c4.7-33.3,15.6-64.3,27.3-96c13.7-37.3,24.3-52.6,33.4-80c-10.4,14.5-19.6,30-28.2,45.4c-7.1,12.7-13.9,28-27.8,34.8
                c-0.9,0.4-2,0.1-2.3-0.9c-4.3-15,3.2-31.7,6.9-46.2c4.7-18.1,10.2-36.1,13.6-54.5c-2.7,14.7-12.2,28.6-17.9,42.3
                c-4.1,9.9-12.7,10.4-14.7,0.4c-14.9-70.9-11.4-72.6,15-83.8c16.6-7,40.9-23.2,58.6-11.7c8.3,5.4,14.7,13.3,22,20
                c-10.5-10.1-17.9-23.5-21.9-37.6L94.7,76.7c0,0,19.4,146,19.4,220.4c0,74.4-19.4,238.3-19.4,238.3c45.3,11.2,93.4,16.1,126.3,18.3
                c-9.7-1.7-19.3-3.8-28.8-6.3C175.9,543.1,157.1,539,143.7,528.5z"/>
              <path fill={'#e6e6e6'} d="M328.2,7.9C318.3,2.1,216.9-3.2,192.6,8c0,0-18.7,36-18.7,36.4c-0.3,5.8,18.3,37.8,35.4,39.1
                l51.3-25.8c0,0,50.9,26.1,51,26.1c24.1-1,35.5-39.4,35.5-39.4L328.2,7.9z"/>
              <path fill={'#4d4d4d'} d="M266,116.6c0,2.9-2.6,5.2-5.7,5.2s-5.7-2.3-5.7-5.2s2.6-5.2,5.7-5.2S266,113.7,266,116.6z" />
              <path fill={'#4d4d4d'} class="st3" d="M266,94.1c0,2.9-2.6,5.2-5.7,5.2
	              s-5.7-2.3-5.7-5.2s2.6-5.2,5.7-5.2S266,91.2,266,94.1z"/>
              <path fill={'#4d4d4d'} d="M265.5,71.5c0,2.6-2.4,4.8-5.3,4.8
	              c-2.9,0-5.3-2.1-5.3-4.8c0-2.6,2.4-4.8,5.3-4.8C263.1,66.8,265.5,68.9,265.5,71.5z"/>
              <path fill={'#808080'} d="M322.6,22.7c0,0-24.7-4.7-60.9-4.7c-32.4,0-59.1,5.1-59.1,5.1s-14.2,1,4.7,14.5
	              c12.1,8.7,34.4,17.4,54.3,19.7c19.9-2.4,45.1-11.7,56.9-20.5C333.2,26,322.6,22.7,322.6,22.7z"/>
            </svg>
          </div>
        );

      default:
        return (
          <div>no pattern</div>
        );
    }


  }

  render() {
    log(this.constructor.name, 'render');
    return <div>{this.renderShirt()}</div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pattern: ownProps.pattern,
    color1: ownProps.color1,
    color2: ownProps.color2
  };
};

export default connect(mapStateToProps)(GameBoxDetailedAvatarTE);
