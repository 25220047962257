/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconBA = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g><path d="M1911.7,956.2c-17.3-360.8-245.8-677.4-582.7-807.6C992,18.5,610,99.3,354.7,354.7c-11.4,11.3-22.4,22.9-33,34.8
	c-21.3,23.6-41.3,48.4-60,74.2C149.4,618.5,88.5,804.6,87.4,995.8c0,16,0.3,32,1,48c22.7,469.5,398.3,845.1,867.8,867.8
	c14.4,0.7,28.8,1,43.1,1c211.1-0.2,415.6-73.2,578.9-206.9c23.2-19,45.5-39.2,66.9-60.7c169.9-170.2,266-400.5,267.4-641
	C1912.7,988.2,1912.4,972.2,1911.7,956.2z M1850.8,1039.6c-0.3,6.9-0.8,13.7-1.3,20.5c-0.5,7.8-1.2,15.5-1.9,23.2
	c-0.7,6.7-1.4,13.4-2.3,20c-1,7.8-2,15.5-3,23.2c-1,6.6-2.1,13.2-3.2,19.8c-1.3,7.7-2.8,15.4-4.4,23.1c-1.3,6.5-2.7,13-4.2,19.4
	c-1.8,7.7-3.7,15.2-5.6,23c-1.6,6.4-3.3,12.7-5.1,19c-2.2,7.6-4.5,15.2-6.8,22.8c-1.9,6.1-3.9,12.4-6.1,18.6c-2.6,7.5-5.3,15-8,22.4
	c-2.3,6.1-4.5,12.2-6.8,18.3c-3,7.5-6.1,14.9-9.1,22.2c-2.5,5.9-5,11.8-7.7,17.6c-3.3,7.4-6.9,14.6-10.5,21.9
	c-2.8,5.7-5.5,11.4-8.5,17.1c-3.7,7.2-7.7,14.4-11.7,21.5c-3,5.5-6.1,11.1-9.1,16.6c-4.1,7.1-8.5,14.1-12.9,21
	c-3.3,5.3-6.5,10.7-9.9,15.9c-4.6,7-9.5,13.9-14.3,20.8c-3.5,5-6.8,10.1-10.5,15c-5.2,7.1-10.7,14.1-16.1,21
	c-3.5,4.5-6.9,9.1-10.5,13.6c-6.1,7.5-12.6,14.9-19,22.3c-3,3.7-6.1,7.4-9.5,11c-3.4,3.6-6.1,6.5-9.3,9.8l-365-364.6
	c160.8-149.7,373.2-231.6,592.9-228.5c0,2.8,0.2,5.7,0.2,8.5c0,7,0,14-0.2,20.9c-0.2,7.8-0.4,15.4-0.7,23H1850.8z M1552.4,1648
	c-4.8,4.1-9.6,8.4-14.5,12.4c-7.8,6.4-15.9,12.5-24,18.6c-3.3,2.5-6.6,5.2-10,7.7c-9.1,6.6-18.2,13-27.6,19.2
	c-2.5,1.7-5,3.5-7.5,5.1c-9.9,6.5-20,12.7-30.2,18.8c-2,1.2-4,2.5-6.1,3.7c-10.5,6.1-21.3,12.2-31.8,17.8c-1.8,0.9-3.6,2-5.4,3
	c-10.9,5.7-21.9,11.1-32.9,16.3c-1.8,0.8-3.6,1.7-5.4,2.6c-11,5.1-22.1,9.8-33.2,14.4l-6.1,2.6c-10.8,4.4-21.8,8.4-32.7,12.3
	l-7.8,2.8c-10.5,3.6-21.1,7-31.7,10.2l-9.8,3c-10,2.9-20.1,5.6-30.3,8.1c-4.1,1-8.1,2-12.2,3c-9.4,2.2-18.9,4.2-28.4,6.1
	c-5,1-10,2-15,2.9c-8.7,1.6-17.4,3-26.1,4.3c-6.1,0.9-12,1.7-18,2.5c-7.9,1-15.8,1.9-23.8,2.7c-6.9,0.7-13.8,1.3-20.7,1.8
	c-7.3,0.5-14.6,1-21.9,1.3c-7.3,0.3-15.2,0.6-22.8,0.7c-6.8,0-13.7,0.3-20.5,0.2c-3,0-5.8-0.2-8.8-0.2
	c-3-219.7,78.8-432.2,228.3-593.2l364.6,364.7c-3.2,3-6.3,6.1-9.6,9.1c-6,5.4-12.2,10.5-18.3,15.6H1552.4z M151.4,1073.6
	c3,0,6.1,0.2,9,0.2c231.7-0.2,454.7-88.3,624-246.4L957,1000l-580.3,580.3C247,1441.4,167.7,1263,151.4,1073.6z M149.3,960.5
	c0.3-6.9,0.8-13.8,1.3-20.6c0.5-7.7,1.2-15.4,1.9-23.1c0.7-6.7,1.4-13.4,2.3-20.1c1-7.8,2-15.5,3.2-23.3c1-6.6,2-13.1,3-19.7
	c1.4-7.8,2.9-15.5,4.4-23.2c1.3-6.5,2.7-13,4.2-19.4c1.8-7.7,3.7-15.2,5.6-23c1.6-6.4,3.3-12.7,5.1-19c2.2-7.6,4.5-15.2,6.8-22.8
	c1.9-6.1,3.9-12.4,6.1-18.6c2.6-7.5,5.3-15,8-22.5c2.2-6.1,4.5-12.2,6.8-18.3c3-7.4,6.1-14.8,9.1-22.2c2.5-5.9,5-11.8,7.7-17.6
	c3.3-7.3,6.9-14.6,10.4-21.8c2.8-5.7,5.6-11.5,8.5-17.2c3.7-7.2,7.7-14.2,11.6-21.3c3-5.6,6.1-11.2,9.3-16.7
	c4.2-7.1,8.6-14.1,12.9-21.3c3.3-5.3,6.5-10.6,9.9-15.8c4.6-7.1,9.5-14,14.3-20.9c3.5-5,6.8-10,10.4-14.9c5.2-7.1,10.7-14.1,16.1-21
	c3.5-4.5,6.9-9.1,10.5-13.6c6.1-7.5,12.6-14.9,19-22.3c3-3.6,6.1-7.4,9.5-11c3.4-3.6,6.2-6.5,9.3-9.8l364.8,364.7
	c-161,149.4-373.3,231.2-592.9,228.6c0-2.8-0.2-5.7-0.2-8.5c0-7,0-14,0.2-20.9C148.7,975.7,148.9,968.1,149.3,960.5z M452.8,347.6
	c2.6-2.2,5.1-4.4,7.7-6.6c119-97.2,262-160.6,414-183.4h0.9c12.2-1.8,24.3-3.3,36.5-4.5l6.5-0.7c10.4-1,20.8-1.7,31.2-2.3
	c4.2-0.2,8.4-0.5,12.6-0.7c8.4-0.4,16.9-0.5,25.3-0.6c6.5,0,12.9-0.2,19.4-0.2h6.1c2.9,219.7-78.9,432.1-228.5,593L419.7,376.8
	c3-2.7,5.8-5.6,8.7-8.3C436.4,361.2,444.6,354.4,452.8,347.6z M827.3,784.3c160.2-171.5,248.3-398.1,246.1-632.7
	c189.4,16.2,367.8,95.5,506.8,225.1L1000,957L827.3,784.3z M1000,1043l172.7,172.7c-160.3,171.6-248.4,398.3-246.1,633
	c-189.4-16.2-367.8-95.6-506.8-225.3L1000,1043z M1215.7,1172.7L1043,1000l580.3-580.3c129.7,138.9,209.1,317.4,225.4,506.8
	C1613.9,924.3,1387.3,1012.4,1215.7,1172.7z"/></g>
			</svg>
		</div>
	);
};

export default IconBA;
