/**
 * React app wrapper
 */
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "react-lazy-load-image-component/src/effects/blur.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { setLocale, loadTranslations, syncTranslationWithStore } from "react-redux-i18n";
import { PersistGate } from "redux-persist/es/integration/react";

import App from "./components/App";
import translations from "./l10n/translations";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";
import ThemeProvider from "./theme";
import { store, persistor } from "./redux-store/store";

Bugsnag.start({
  apiKey: "02ebe461780cfb52fce01a85e57325d0",
  plugins: [new BugsnagPluginReact()],
  // enabledReleaseStages: ['production'],
});
BugsnagPerformance.start({ apiKey: "02ebe461780cfb52fce01a85e57325d0" });

const getLanguage = () => {
  const activeRoute = window.location.pathname;
  const savedLang = localStorage.getItem("lang");
  const routeLang = activeRoute.split("/")?.filter((bl) => bl === "en" || bl === "fi" || bl === "et")[0];
  const browserLangAgent = navigator.language || navigator.userLanguage;
  let browserLang = undefined;
  if (browserLangAgent?.includes("fi")) {
    browserLang = "fi";
  }
  if (browserLangAgent?.includes("et")) {
    browserLang = "et";
  }
  if (browserLangAgent?.includes("en")) {
    browserLang = "en";
  }

  const list = ["en", "fi", "et"];
  if (list.includes(routeLang)) {
    localStorage.setItem("lang", savedLang);
    if (activeRoute?.includes("sitemap.xml")) return;
    return routeLang;
  } else if (savedLang && list.includes(savedLang)) {
    localStorage.setItem("lang", savedLang);
    if (activeRoute?.includes("sitemap.xml")) return;
    return savedLang;
  } else if (browserLang) {
    localStorage.setItem("lang", browserLang);
    if (activeRoute?.includes("sitemap.xml")) return;
    return browserLang;
  } else {
    localStorage.setItem("lang", list[1]);
    if (activeRoute?.includes("sitemap.xml")) return;
    return list[1];
  }
};
syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));
store.dispatch(setLocale(getLanguage()));
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider>
        <ErrorBoundary>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ErrorBoundary>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

//Bugsnag.notify(new Error('Test error'))
