/**
 * Password Reset component
 *
 */

import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withStyles } from "@mui/styles";
import { Translate } from "react-redux-i18n";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import PasswordField from "../Inputs/PasswordInput";

import { updatePassword } from "../../actions";
import withRouter from "../../helpers/withRouter";

const styles = (theme) => ({
  greenColor: {
    color: "var(--color-contrast)",
  },
  root: {
    "& .MuiContainer-root": {
      padding: "20px 10px",
      width: "100%",
    },
    "& .MuiFormControl-root": {
      margin: "1em 0",
      width: "100%",
    },
    "& .MuiButton-root": {
      margin: "1em 1em 0 0",
      // background:
      //   "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
    },
  },
});

class PasswordUpdate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAlert: false,
    };
  }

  componentDidMount() {
    const { auth, locale, navigate } = this.props;
    if (!auth.isSignedIn) navigate(`/${locale}/sign-in`);
  }

  componentDidUpdate() {
    const { auth, locale, navigate } = this.props;
    if (!auth.isSignedIn) navigate(`/${locale}/sign-in`);
  }

  renderTextField = ({
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
  }) => (
    <TextField
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  );

  onSubmit = (formValues) => {
    this.props.updatePassword(formValues, this.constructor.name).then(() => {});
  };

  render() {
    log(this.constructor.name, "render");
    const { translate, classes } = this.props;
    return (
      <div className={classes.root}>
        <form
          onSubmit={this.props.handleSubmit(this.onSubmit)}
          autoComplete="off"
        >
          <div>
            <Container maxWidth="md">
              <Field
                name="password_current"
                type="password"
                classes={classes}
                component={PasswordField}
                label={translate.labels.current_pass}
                variant="standard"
              />
              <Field
                name="password_new"
                type="password"
                classes={classes}
                component={PasswordField}
                label={translate.labels.new_pass}
                variant="standard"
              />
              <Field
                name="password_new_confirm"
                type="password"
                classes={classes}
                component={PasswordField}
                label={translate.labels.confirm_pass}
                variant="standard"
              />
              <Button
                type="submit"
                variant="contained"
                size="small"
                color="primary"
                style={{ color: "var(--btn-text)" }}
              >
                <Translate value="labels.change_pass" />
              </Button>
            </Container>
          </div>
        </form>
      </div>
    );
  }
}

const validate = (values, props) => {
  const errors = {};

  const requiredFields = [
    "password_current",
    "password_new",
    "password_new_confirm",
  ];

  requiredFields?.forEach((field) => {
    if (!values[field]) {
      errors[field] = props.translate.labels.field_required;
    }
  });

  if (
    values.password_new &&
    (values.password_new?.length < 8 ||
      !values.password_new.match(/\d/) ||
      !values.password_new.match(/[A-Z]/))
  ) {
    errors.password_new = props.translate.labels.invalid_pass;
  }

  if (
    values.password_new_confirm &&
    values.password_new_confirm !== values.password_new
  ) {
    errors.password_new = props.translate.labels.invalid_pass_confirm;
  }

  return errors;
};

const mapStateToProps = (state) => ({
  auth: state.user.auth,
  settings: state.settings,
  // initialValues: state.user,
  translate: state.i18n.translations[state.i18n.locale],
  locale: state.i18n.locale,
});

export default withRouter(
  connect(mapStateToProps, { updatePassword })(
    reduxForm({
      form: "PasswordUpdate",
      validate,
      enableReinitialize: true,
    })(withStyles(styles)(PasswordUpdate))
  )
);
