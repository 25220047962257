// Customized MUI DatePicker
import * as React from "react";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const CustomDatePicker = ({
  label,
  input,
  classes,
  meta: { touched, invalid, error },
  variant,
  ...custom
}) => {
  const layout = useSelector((state) => state.settings.layout);
  // returns mobile or desktop version of date picker based on layout change
  return layout === "mouse" ? (
    <DesktopDatePicker
      value={new Date(input.value)}
      onChange={input.onChange}
      {...custom}
      slotProps={{
        textField: {
          variant: variant,
          helperText: touched && invalid && error,
          error: touched && invalid && error,
        },
      }}
    />
  ) : (
    <MobileDatePicker
      value={new Date(input.value)}
      onChange={input.onChange}
      slotProps={{
        textField: {
          variant: variant,
          helperText: touched && invalid && error,
          error: touched && invalid && error,
        },
      }}
      {...custom}
    />
  );
};

CustomDatePicker.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
  classes: PropTypes.object,
  variant: PropTypes.string,
  label: PropTypes.string,
};

CustomDatePicker.defaultProps = {
  input: {
    onChange: () => {},
    value: null,
  },
  meta: {
    touched: false,
    invalid: false,
    error: undefined,
  },
  classes: {},
  variant: "standard",
  label: "",
};

export default CustomDatePicker;
