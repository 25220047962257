/*
 * Translation file Datum/Uhrzeit slots winner limits operation deposit limit campaigns
 */

const translations = {
  de: {
    date: {
      long: "MMMM Do, YYYY",
      short: "MMM D YYYY",
      game: " HH:mm",
      game1: "DD.MM. HH:mm",
      game2: "dd HH:mm",
      ticket: "DD.MM. HH:mm",
      ticketDateFilter: "dd/MM/yyyy",
      extra: "DD.MM.YYYY HH:mm",
    },
    seo: {
      card_title: "NUBET - The home of sportsbetting",
      card_description: "Lightning-fast registration, best odds, good limits and fantastic casino Jackpots. Nubet – Magic of the North",
    },
    menu: {
      lb_shanghai: "Leaderboard Shanghai",
      trotting: "Trotting",
      balance_cash: "Withdrawable cash",
      balance_promo: "Bonus",
      free_spins: "Free spins",
      bonus_wr: "Wagering Requirement",
      out_of: "out of",
      promotions: "Bonus",
      campaigns: "Campaigns",
      bugreport: "Fehler melden",
      matchstatistics: "Spiel Statistiken",
      maxOdd: "Quoten:",
      panic: "24h Sperre",
      sportsBetting: "Wetten",
      betting: "Wetten",
      slip: "Wettschein",
      myBets: "Meine Wetten",
      casino: "Casino",
      live: "Live",
      livecasino: "Live Casino",
      esports: "Esports",
      all: "Alle",
      threeHours: "< 3h",
      today: "Heute",
      mySports: "Mein Sport",
      tomorrow: "Morgen",
      standings: "Tabelle",
      results: "Ergebnisse",
      conference: "Live-Konferenz",
      h2h: "H2H",
      summary: "Live-Ticker",
      statistics: "Statistik",
      lineups: "Aufstellung",
      stream: "Live Stream",
      horseRacing: "Horseracing",
      horseRacing_1: "Racing",
    },
    stream: {
      availabel: "NBT Live-Stream im Moment nicht verfügbar.",
    },
    marketSets: {
      trotting: "Racing",
      databet: "Databet",
      eSports: "E-Sports",
      betbuilder: "Betbuilder",
      player: "Player props",
      corners: "Corners",
      bookings: "Bookings",
      favorites: "Meine Quoten",
      mainbets: "Hauptwetten",
      ou: "Über/Unter",
      handicap: "Handicap (3way)",
      handicap_ou: "Handicap & Über/Unter",
      asian: "Asiatische Wetten",
      asianhc: "Handicap",
      bothscore: "Teams treffen",
      game_totals: "Spiel & Über/Unter",
      game_bothscore: "Spiel & Beide treffen",
      winzero: "Gewinnt zu null",
      firsthalf: "Halbzeit",
      rest: "Restzeit",
      firstperiod: "Drittel",
      configbets: "Konfig Wetten",
      more: "Mehr",
      firstset: "Satz",
      correctscore: "Genaues Ergebnis",
      outrights: "Langzeit",
      goalscorer: "Goal scorer",
    },

    markets: {
      label_null: " ",
      label_1: "Sieger",
      label_10: "Doppelte Chance",
      label_18000: "Über/Unter",
    },
    selections: {
      label_null: " ",
      label_1: "1",
      label_2: "X",
      label_3: "2",
      label_12: "+",
      label_13: "-",
      yes: "Ja",
      no: "Nein",
      over: "Über",
      under: "Unter",
      odd: "Ungerade",
      even: "Gerade",
      draw: "Unentschieden",
      no_goal: "Kein Tor",
    },
    slip: {
      nonrunner: "Bet can not include selection marked as non-runner. Please, remove the selection.",
      maxselections: "Bet can have maximum 15 markets.",
      outrightcombi: "Combi cannot include outright and regular market from the same league.",
      outrightsystem: "Combi cannot include outright and regular market from the same league.",
      maximum: "Die maximale Auszahlung pro Wettschein beträgt 100.000,00",
      new_template: "Neue Wettschein-Vorlage",
      delete: "Löschen",
      select: "Auswählen",
      active: "Aktiv",
      copy: "Kopieren",
      stake: "Einsatz",
      slip_empty: "Ihr Wettschein ist leer",
      my_templates: "Meine Vorlagen",
      slip: "Wettschein",
      templates_all: "Alle",
      templates_new: "Neue",
      templates_import: "Importieren",
      templates_info: "Info",
      single: "Einzel",
      combi: "Kombi",
      system: "System",
      post_ticket_place_bet: "Wette abgeben",
      post_ticket_verifying: "Wette wird überprüft",
    },
    acceptance: {
      same_player_used: "Same player can be used once in a Betbuilder bet.",
      token_expired: "Your session expired. Please log in again.",
      multiple_outright_system_bet: "Bet can not include multiple outrights form the same game",
      maxStake_limit_exceeded: "Maximales Einsatzlimit überschritten",
      internal_error: "Es ist ein interner Fehler aufgetreten, bitte versuchen Sie es später noch einmal.",
      wager_limit_exceeded: "Sie haben das selbst gesetzte Einsatzlimit überschritten.",
      loss_limit_exceeded: "Sie haben das selbst gesetzte Verlustlimit überschritten.",
      cash_bet_limit_exceeded: "Max Cash Bet Limit überschritten",
      error_bet_risk_1: "Der Höchsteinsatz beträgt ",
      error_bet_risk_2: "für dieses Event. Für einen höheren Einsatz versuchen Sie es zu einem späteren Zeitpunkt noch einmal.",
      invalid_system_combi: "Ungültige Systemkombination. Der Betrag muss größer als 0 sein.",
      error_price_inactive_all: "Nicht wettbare Märkte entfernen.",
      bet_risk: "Wir nehmen derzeit keine Einsätze auf dieses Event an.",
      coupons_per_event: "Sie können jedes Event maximal %%1%% mal in verschiedenen Wettscheinen wählen.",
      event_out_of_date: "Dieses Event ist beendet.",
      event_unique: "Dieses Event darf nur einmal pro Wettschein vorkommen.",
      inactive_game: "Auf dieses Event kann derzeit nicht gewettet werden.",
      inactive_market: "Dieser Markt ist derzeit nicht für Wetten geöffnet.",
      incorrect_bet_type: "Falsche Wettart. Bitte leeren Sie Ihren Schein und versuchen Sie es erneut.",
      insufficient_min_bets: "Unzureichende Anzahl von ausgewählten Events.",
      insufficient_min_combi_type: "Für dieses Event muss im Wettschein (System) eine Mindestkombination ausgewählt werden.",
      invalid_hc: "Das Handicap hat sich geändert.",
      invalid_price: "Die Quote hat sich geändert.",
      limits_exceeded_loss: "Wenn Sie diese Wette platzieren, überschreiten Sie das selbst gesetzte Verlustlimit von %%1%% ",
      limits_exceeded_wager: "Wenn Sie diese Wette platzieren, überschreiten Sie das selbst gesetzte Einsatzlimit von %%1%% ",
      min_unique_events: "Slip requires a minimum of %%1%% events.",
      no_language_defined: "You have to choose a valid language.",
      no_stake: "Bitte geben Sie einen gültigen Einsatz ein.",
      no_user_found: "Sie müssen sich anmelden, um eine Wette zu platzieren.",
      not_enough_credit: "Ihr Guthaben beträgt: %%1%%. Bitte tätigen Sie eine Einzahlung, um diese Wette zu platzieren.",
      over_max_stake: "The maximum stake you can play is 2000.",
      rule_operator_0: "",
      rule_operator_1: "Equal to",
      rule_operator_2: "Not equal to",
      rule_operator_3: "Containing",
      rule_operator_4: "Smaller than",
      rule_operator_5: "Maximum",
      rule_operator_6: "Bigger than",
      rule_operator_7: "minimum EUR",
      soap_entication_not_successful: "You have to login to place bets.",
      stake_limits: "Please enter a valid stake between %%1%% and %%2%% %%3%%.",
      ticket_awaiting_approval: "Your ticket is awaiting approval.",
      ticket_bets: "The method of bets on your slip needs to be ",
      ticket_cooldown: "You've already placed a ticket recently. Please wait a little longer.",
      ticket_not_valid: "No valid bet(s) submitted",
      ticket_stake: "Der Mindesteinsatz pro Wettschein beträgt ",
      ticket_successful: "Ihre Wette wurde platziert.",
      ticket_uniqueness: "You have already placed other identical ticket(s).",
      ticket_win: "The potential win on your slip needs to be ",
      total_bets: "We currently do not accept stakes for this event.",
      total_stake: "We currently do not accept stakes for this event.",
      total_win: "We currently do not accept stakes for this event.",
      user_disabled: "You are currently not allowed to place bets.",
      user_not_active: "Your account is not active at the moment.",
      code_already_used: "Token has expired. Please, refresh the page",
      promo_bet_limit_exceeded: "Maximum promo bet amount is 25 EUR.",
    },
    tickets: {
      now: "Aktuell",
      placed: "Gewettet",
      win_cashout: "Cashout",
      paid_cashout: "Cashout",
      pending: "Offen",
      all: "Alle",
      paid: "Gewonnen",
      cashedout: "Cashout",
      lost: "Verloren",
      cancelled: "Storniert",
    },
    stateInfo: {
      safe: "Ballbesitz",
      attack: "Ballbesitz",
      dangerous_attack: "Gefährlicher Angriff",
      corner: "Eckball",
      penalty: "Elfmeter",
      free_kick: "Freistoss",
      shot: "Schuss",
      pole: "Pfosten",
      goal: "Tor",
      yellow_card: "Gelbe Karte",
      red_card: "Rote Karte",
      substitution: "Spielerwechsel",
      var: "Videobeweis",
    },
    theme: {
      bluelight: "Hell",
      bluedark: "Dunkel",
      purpledark: "Lila Dunkel",
      reddark: "Rot Dunkel",
      redlight: "Rot",
      pink: "Pink",
      light: "Hell",
      dark: "Dunkel",
      classic: "Classic",
    },
    layout: {
      touch: "Tablet-Modus",
      mouse: "Desktop-Modus",
    },
    mode: {
      standard: "Normal",
      compact: "Kompakt",
      title: "Ansicht",
    },
    oddsFormat: {
      decimal: "Dezimal",
      fractional: "Fraktional",
      american: "Amerikanisch",
    },
    navView: {
      list: "Liste",
      tab: "Auswahl-Links",
    },
    marketsLayout: {
      horizontal: "Quoten-Spalten",
      vertical: "Quoten-Zeilen",
    },
    paymentMethods: {
      sofort: "Sofort",
      skrill: "Skrill",
      paysafecard: "Paysafe Card",
      bankwire: "Banküberweisung",
    },
    slots: {
      seeall: "See all",
      home: "Home",
      casino_back: "Zurück zum Casino",
      all: "Alle",
      livecasino: "Live Casino",
      new: "Neu",
      popular: "Populär",
      mygames: "Meine Games",
      not_logged_in: "Bitte einloggen >> ",
      link_login: "LOGIN",
      search: "Suche",
      provider_all: "ALLE",
      hotgames: "Beliebte Spiele",
      video_slots: "Alle Spiele",
      promotions: "Promotionen",
      vip_program: "VIP Programm",
      book: "Book Spiele",
      megaways: "Megaways",
      irish: "Irische Spiele",
      winalot: "Win a Lot",
      jackpots: "Jackpots",
      classics: "Classics",
      bonusbuy: "Bonus Buy",
      winoften: "Win Often",
      dropsandwins: "Drops-Wins",
      tournament: "Tournament",
    },
    legal: {
      tAndc_click: "View Terms and Conditions",
      rules_click: "View Betting Rules",
      terms_conditions_link: "Geschäftsbedingungen",
      privacy_policy_link: "Datenschutzerklärung",
      terms_conditions: "Ich bin mindestens 18 Jahre alt und habe die Geschäftsbedingungen gelesen, verstanden und stimme diesen zu.",
      privacy_policy: "Ich habe die Datenschutzerklärung gelesen und verstanden.",
      welcome_bonus: "Ich möchte einen Willkommensbonus erhalten und bevorzuge folgende Wege der Kommunikation um die neuesten Werbeaktionen und exklusiven Angebote nicht zu verpassen:",
    },
    cookieConsent: {
      title: " Diese Webseite verwendet Cookies",
      content1: "Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, Funktionen für soziale Medien anbieten zu können und die Zugriffe auf unsere Website zu analysieren.",
      content2:
        "Außerdem geben wir Informationen zu Ihrer Verwendung unserer Website an unsere Partner für soziale Medien, Werbung und Analysen weiter. Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die Sie ihnen bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben.",
      button1: "Anpassen",
      button2: "Alle zulassen",
      button3: "Auswahl zulassen",
      option1: "Notwendig",
      option2: "Präferenzen",
      option3: "Statistiken",
      option4: "Marketing",
    },
    trustly: {
      complete_deposit: "Complete deposit",
      deposit_back_btn: "Zurück zur Startseite.",
      deposit_waiting1: "Die Zahlung wird gerade von Trustly bearbeitet! Bitte, schließen Sie die Seite nicht! Sie werden in ",
      deposit_waiting2: " Sekunden weitergeleitet.",
      deposit_failed: "Die Einzahlung ist fehlgeschlagen",
      deposit_canceled: "Die Einzahlung wurde storniert",
      deposit_rejected: "Die Einzahlung wurde abgelehnt",
      withdraw_rejected: "Abhebung wurde abgelehnt",
      withdraw_canceled: "Die Abhebung wurde storniert",
      withdraw_failed: "Die Abhebung ist fehlgeschlagen",
      deposit_limit_exceeded: "Deposit limit exceeded",
      withdraw_limit_exceeded: "Withdraw limit exceeded",
      default_deposit_limit: "Deafult deposit limit is {{price}}",
      deposit_not_initialized: "Deposit  has not been initialized yet!",
      deposit_not_completed: "You have initialized deposit but have not completed yet. You can click the button below to complete your deposit.",
    },
    labels: {
      amount: "Amount",
      bet_id: "Bet ID",
      empty_screen_message: "Currently there are no events available for betting.",
      racing_empty_screen_message: "Currently there are no events available for betting. Racing events open around 16 EET on weekdays and before noon on Saturdays.",
      combination: "combination",
      combinations: "combinations",
      spins_left: "Spins Left",
      expires: "Expires",
      games: "Games",
      receipt_copied: "Receipt copied to clipboard",
      show_receipt: "Show Receipt",
      hide_receipt: "Hide Receipt",
      ticket_charged: "Ticket is charged {{!amount}} EUR",
      reuse_selection: "Reuse Selection",
      copy_receipt: "Copy The Receipt",
      matches: "Matches",
      bet_type: "Bet Type",
      example_searches: "EXAMPLE SEARCHES",
      BadCredentialsException: "Wrong password",
      token_expired: "Your session expired. Please log in again.",
      connection_error: "Oops! Something went wrong. Please try again later.",
      token_invalid: "Your session expired. Please log in again.",
      quick_pick: "Quick pick",
      build_your_own: "Build your own",
      boosted_odds: "Boosted",
      old_odds: "Old odds",
      new_odds: "New odds",
      banner_btn: "Bet",
      daily_slip: "Daily ",
      daily_slip_double: "double",
      daily_slip_treble: "treble",
      daily_slip_fold: "acca",
      add_to_slip: "ADD TO BETSLIP",
      tr_bets_exceeded: "Max number of combinations in a system is 512. Please remove some selections.",
      dead_heat_info: "Odds updated due to dead-heat. Original odds {placed_price}, updated odds {price}.",
      confirm_alert_limits: "Confirm  limits",
      limits_requested_changes: "You have requested changes for following limits",
      abort_limit_change: "Abort limit change",
      confirm_limit_abort: "Are you sure you want to abort scheduled limit change? This action cannot be undone.",
      i_am_sure: "Yes, I am sure",
      remove_limit: "Remove Limit",
      confirm_remove_limit: "Are you sure you want to remove the  limit?",
      keep_my_limit: "No, Keep my limit",
      remove_the_limit: "Yes, remove the limit",
      old_price: "Old price",
      new_price: "New price",
      price_changed: "Bet price changed",
      accept_price_change: "Do you accept the updated price?",
      new: "NEU",
      betbuilder_info_combi: "Combination of bet builder and platform bets are currently not possible.",
      betbuilder_headline: "Betbuilder - Build your own bet",
      betbuilder_content: "Bet Builder allows you to combine selections from different markets within the same match to create a custom single bet with bigger returns.",
      betbuilder_button: "START BUILDING NOW",
      search_no_result: "Ihre Suche hat zu keinem Ergebnis geführt!",
      block_activated: "Block activated",
      limits_updated: "Limits updated",
      close_account: "Close Account",
      limit_renewal_period: "Your new limit {{!amount}} will take effect on {{!time}}",
      loss_limits_update: "New loss limits",
      stake_limits_update: "New wager limits",
      deposit_limits_update: "New deposit limits",
      featured_matches: "Featured match",
      view_all: "Alle anzeigen",
      load_more: "Mehr anzeigen",
      page_not_found: "Page not found",
      reality_successful: "You are back",
      result: "Ergebnis",
      minimum_stake: "Mindesteinsatz 1 EUR",
      limit: "Limit",
      register_infotext: "Um die Anmeldung abzuschließen, füllen Sie bitte das untenstehende Formular aus. Vergessen Sie nicht, am Ende auf die Schaltfläche Registrieren zu klicken.",
      min_value_10: "Mindestbetrag 10€",
      max_value_100000: "Maximalbetrag 100.000€",
      limits_infotext:
        "Gambling is exciting. Make sure that it stays under control. In Nubet, you have an option if you prefer to set limits to control your gambling behaviour. Please note that if setting loss limits they are common across Vana Lauri brands.",
      notifications_infotext: "We regularly inform customers about products, bonuses and offers. Please choose how you would like to receive them:",
      info_limits: "If you want to set your own personal playing limits, you can do so in the settings or set them later.",
      back_to_home: "Back to home",
      maximum_withdraw: "Minimum withdraw 25€. Maximum withdraw 5000€",
      minimum_amount: "Minimum deposit 10€. Maximum deposit 5000€.",
      wrong_birthdate: "You entered a wrong birthdate.",
      renewaltime_1: "Die Zeit bis zur Erneuerung wird angezeigt, wenn das Limit eingestellt ist.",
      renewaltime_2: "Erneuerungszeit:",
      outdated_1: "Achtung!",
      outdated_2: "Ihr Browser ist veraltet. Bitte aktualisieren Sie Ihren Browser, um fortzufahren.",
      limits_saved: "Limits saved",
      customer_support: "Customer Support",
      attention_gambling:
        "Achtung! Dies ist eine Glücksspielwerbung. Glücksspiele sind kein geeignetes Mittel, um finanzielle Probleme zu lösen. Machen Sie sich mit den Regeln vertraut und handeln Sie verantwortungsbewusst!",
      slip_banner_1: "Trotting Races",
      slip_banner_2: "@Nubet",
      deposit_succes: "Einzahlung erfolgreich",
      deposit_fail: "Einzahlung fehlgeschlagen",
      reset_pass_text: "Bitte wählen und bestätigen Sie ein neues PAsswort.",
      reset_pass: "Neues Passwort",
      withdraw_trustly_1: "Die ",
      withdraw_trustly_2: " schnelle Auszahlung ",
      withdraw_trustly_3: " mit Trustly.",
      deposit_trustly_1: "Nimm die ",
      deposit_trustly_2: " Überholspur ",
      deposit_trustly_3: " und zahle jetzt mit Trustly ein.",
      login_trustly_1: "oder anmelden mit  ",
      login_trustly_2: "banklogin ",
      login_trustly_3: " Einzahlung inklusive",
      register_trustly_1: "Nehmen Sie die ",
      register_trustly_2: "Überholspur ",
      register_trustly_3: " um sich zu registrieren und gleichzeitig einzuzahen",
      register_trustly_4: "mit Trustly",
      register_trustly_5: "oder wählen Sie den klassischen Weg",
      register_trustly_6: "Wenn Sie fortfahren, erklären Sie sich mit den AGBs einverstanden.",
      login_banklogin: "Melden Sie sich bei der Bank an > (Einzahlung tätigen)",
      user_register: "Anmeldung",
      register_new: "Registrieren",
      register_old: "Standard Registrierung",
      user_wrong_password: "Falsches Passwort.",
      user_frozen: "Account is frozen after 3 unsuccessful login attemps. Please contact customer support.",
      username_not_found: "Benutzername falsch.",
      username_self_exclusion: "Benutzer aufgrund eines Selbst-Ausschlusses gesperrt.",
      contact_message: "Hier Nachricht eingeben.",
      send: "Senden",
      no_open_tickets: "Sie haben im Moment keine offenen Wettscheine.",
      cashout_not_successful: "Cashout nicht erfolgreich",
      download_app: "Download App",
      no_odds: "Für dieses Spiel sind im Moment keine Quoten verfügbar.",
      sample_id1: "Hier können Sie beide Seiten Ihres Ausweises oder Ihren Reisepass hochladen ",
      sample_id2: "(bitte beachten Sie, dass alle 4 Ecken erkennbar sein müssen)",
      sample_id3: "Die max. Dateigröße kann 4 MB nicht überschreiten",
      sample_id4: "Muster Dokument:",
      sample_address1: "Hier können Sie Ihren Adressnachweis hochladen ",
      sample_address2: "(nicht älter als 3 Monate, kein Screenshot)",
      sample_address3: "Die max. Dateigröße kann 4 MB nicht überschreiten",
      sample_address4: "Muster Dokument:",
      sample_deposit1: "Hier können Sie Ihren Banknachweis hochladen ",
      sample_deposit2: "(IBAN oder Skrill Email muss erkennbar sein, keine nachträglich bearbeiteten Dokumente)",
      sample_deposit3: "Die max. Dateigröße kann 4 MB nicht überschreiten",
      sample_deposit4: "Muster Dokument:",
      all: "Alle",
      search: "Suche",
      search: "Suche",
      panic_button: "24h Sperre",
      slots_play: "Jetzt Spielen",
      slots_test: "Testen",
      slots_close: "Schließen",
      settings_change_pw: "Passwort ändern",
      settings_limits: "Limits",
      settings_blocks: "Sperren",
      settings_notes: "Benachrichtigungen",
      stmt_tickets: "Wettscheine",
      stmt_slots: "Slots",
      stmt_payin: "Einzahlungen",
      stmt_payout: "Auszahlungen",
      stmt_24: "24h",
      stmt_7: "7 Tage",
      stmt_30: "30 Tage",
      stmt_other: "Andere",
      stmt_btn: "Anzeigen",
      redirect_title: "Für unsere Spieler ausserhalb von Deutschland",
      redirect_1: "Exklusiver Wettspaß ist nun mit deutscher Lizenz nur noch auf unserer domain.de Webseite möglich.",
      redirect_2: "Bitte logge dich mit deinem Benutzernamen und Passwort wie gewohnt unter domain.com anstatt domain.de an.",
      redirect_btn: "Weiter zu domain.com",
      stay_loggedin: "Angemeldet bleiben",
      accept_oddchanges: "Quotenänderungen automatisch akzeptieren",
      slip_login: "Anmelden oder Registrieren",
      slip_stake: "Einsatz",
      slip_bet: " Wette",
      slip_bets: " Wetten",
      slip_win_chance: "Gewinnchance",
      avatar_now_live: "Jetzt Live",
      avatar_all: "Alle",
      group_events: "Bewerbe groupieren",
      ticket_stake: "Einsatz:",
      ticket_win_chance: "Gewinnchance:",
      ticket_show: "Anzeigen",
      ticket_copy: "Kopieren",
      cashout_confirm: "Bestätigen",
      cashout_successful: "Cashout erfolgreich",
      cashout_sec: "Sek",
      cashout_no: "Cashout nicht verfügbar",
      cashout_in: "Cashout in",
      kyc_upload: "Klicken Sie hier oder ziehen Sie die Bilddatei zum Hochladen in diesen Bereich.",
      kyc_id: "Identitätsnachweis",
      kyc_address: "Adressnachweis",
      kyc_deposit: "Banknachweis",
      news: "News",
      slot_rules: "Slot Regeln",
      bonus_terms_title_slots: "Bonusbedingungen Slots",
      whithdraw_verify_account_1: "Vor Ihrer Auszahlung muss Ihr Spielerkonto verifiziert sein. Bitte laden Sie Ihre Dokumente in Ihrem Profil unter ",
      whithdraw_verify_account_2: " hoch oder senden Sie uns diese per Mail an: ",
      payment_bonus: "Exklusive Bonusangebote! Bitte wählen Sie Ihren Bonus (",
      payment_bonus_discard: "Keinen Bonus verwenden.",
      payment_bonus_code: "oder den Bonus Code eingeben:",
      payment_method: "Betrag",
      auto_logout_1: "Sie sind seit ",
      auto_logout_2: " Minuten angemeldet. Ihre Aktivität in den letzten ",
      auto_logout_3: " Minuten:",
      auto_logout_4: "Sie werden in ",
      auto_logout_5: " Sekunden automatisch abgemeldet.",
      auto_logout_stake: "Einsatz",
      auto_logout_win: "Gewinn",
      auto_logout_profit: "Profit/Verlust",
      auto_logout_statement: "Ihre Spielhistorie können Sie in Ihren Kontoauszügen einsehen",
      birth_name: "Geburtsname",
      back: "Zurück",
      next: "Weiter",
      register_detail_data: "Kontaktdaten",
      register_person_data: "Personenbezogene Daten",
      register_user_data: "Benutzerdaten",
      register_title: "Anrede",
      privacy_pol_link: "Datenschutzerklärung anzeigen",
      account_payment: "Ein-/Auszahlen",
      account_login: "Anmelden",
      no_favorite: "Sie haben noch keine Favoriten ausgewählt",
      selected_currency: "EUR",
      min: "min.",
      max: "max.",
      attention: "Achtung",
      error: "Fehler",
      please_login_in: "Bitte melden Sie sich an",
      show_tickets: "Meine Wetten anzeigen",
      hide_tickets: "Meine Wetten verbergen",
      contact_data: "Kontaktdaten",
      gender: "Geschlecht",
      username: "Benutzername",
      wrong_username_or_password: "Benutzername oder Passwort falsch.",
      WRONG_CREDENTIALS: "Benutzername oder Passwort falsch.",
      CREDENTIALS_VALID_ON_OTHER_DOMAIN: "Für unsere Spieler ausserhalb von Deutschland. Bitte logge dich mit deinem Benutzernamen und Passwort wie gewohnt unter domain.com anstatt domain.de an.",
      identity_verification: "Identitätsüberprüfung",
      AutoIdentityPending: "Die Identitätsprüfung für Ihr Konto steht noch aus.",
      AutoIdentityNotCompletedLink: "entifizierung offen, bitte <a href='%{link}' id='auto-identification'>hier</a> klicken um fortzufahren.",
      AutoIdentityNotCompletedText: "entifizierung offen, bitte klicken um fortzufahren.",
      auto_identification: "Auto-Identifizierung",
      missing_username: "Benutzername ist erforderlich",
      missing_password: "Passwort ist erforderlich",
      missing_confirm_pass: "Passwortbestätigung ist erforderlich",
      missing_email: "E-Mail ist erforderlich",
      missing_confirm_email: "Bestätigung der E-Mail ist erforderlich",
      missing_gender: "Geschlecht ist erforderlich",
      missing_first_name: "Vorname ist erforderlich",
      missing_last_name: "Nachname ist erforderlich",
      missing_: "Geburtsdatum ist erforderlich",
      missing_address: "Adresse ist erforderlich",
      missing_postal_code: "Postleitzahl ist erforderlich",
      missing_city: "Stadt ist erforderlich",
      missing_country: "Land ist erforderlich",
      missing_birthName: "Geburtsname ist erforderlich",
      missing_birthCity: "Geburtsstadt ist erforderlich.",
      login: "Sign In",
      register: "Open account",
      period: "Zeitraum",
      account_block: "Hier können Sie Ihr Spielerkonto schließen oder sperren. Sie können sich entweder für einen gewissen Zeitraum oder dauerhaft sperren lassen.",
      deposit_title: "Einzahlen",
      deposit_now: "Jetzt Einzahlen",
      deposit_terms_1: "Wenn Sie fortfahren, erklären Sie sich einverstanden mit den  ",
      deposit_terms_2: "Allgemeinen Geschäftsbedingungen ",
      withdraw_title: "Auszahlen",
      acc_stmt_title: "KONTOAUSZUG",
      profile_title: "PROFIL",
      messages_title: "MITTEILUNGEN",
      setting_title: "Einstellungen",
      retail_create_account: "Shop-Konto eröffnen",
      retail_show_card: "Shop-Kundenkarte",
      statement_title: "Kontoauszug",
      all_title: "Alle",
      tickets_title: "Tickets",
      payment_title: "Zahlungen",
      from_title: "Von",
      to_title: "Bis",
      logout: "Abmelden",
      p_acc_title: "Sie haben keinen Kontoauszug",
      p_acc_title1: "Sie haben keinen Kontoauszug 1",
      p_acc_title2: "Sie haben keinen Kontoauszug 2",
      salu_title: "Anrede",
      form_fname: "Vorname",
      form_lname: "Nachname",
      form_: "Geburtsdatum",
      form_pob: "Geburtsort",
      select_data: "Datei auswählen",
      proof_id: "Identitätsnachweis",
      proof_add: "Adressennachweis",
      deposit_slip: "Einzahlungsnachweis",
      heading: "Bitte laden Sie Ihr Dokument hoch.",
      upload_data: "Bitte laden Sie Ihre Daten hoch",
      btn_upload: "Hochladen",
      upload_data1: "Hier können Sie Ihren Adressnachweis hochladen",
      upload_data2: "(nicht älter als 3 Monate, kein Screenshot)",
      upload_data3: "Die max. Dateigröße kann 4 MB nicht überschreiten",
      overview: "Übersicht",
      my_data: "Meine Daten",
      document: "Dokumente",
      time: "12.02.2018, 09: 00 Uhr",
      test: "test",
      female: "Frau",
      male: "Herr",
      street: "Strasse / Hausnummer",
      street2: "Adresszusatz",
      zip: "PLZ",
      current_zip: "Derzeitige Plz.",
      city: "Stadt",
      email: "E - Mail Adresse",
      mobile: "Telefonnummer / Mobile",
      country: "Land",
      nationality: "Nationalität",
      cob: "Geburtsland",
      cib: "Geburtsort",
      message: "Nachricht",
      date: "Datum / Zeit",
      booking: "Buchungen",
      method: "Betrag",
      betslipnr: "Wettschein Nr.",
      message_text: "Sie haben keine Nachrichten.",
      limits: "Limits",
      betting_limits: "Wetten Limits",
      deposit_limits: "Einzahlungslimit",
      limits_stake: "Einsatz",
      limits_loss: "Verlust",
      limits_deposit: "Einzahlen",
      limits_max_bet: "Max bet",
      limits_max_bet_text_1: "MEINE MAX BET LIMITS",
      limits_max_bet_text_2: "Diese Limits können sie nicht selbst ändern. Bitte kontaktieren Sie unseren Support für weitere Informationen.",
      limits_max_bet_text_3: "Max Cash Wettbetrag",
      limits_max_bet_text_4: "Max Promo Wettbetrag",
      limits_max_promo_bet: "Max promo bet",
      limits_max_promo_bet_text_1: "Meine Max Promo Bet Limits",
      limits_deposit_text_1: "KUMULATIVES EINZAHLUNGSLIMIT",
      limits_deposit_text_2: "Das kumulative Einzahlungslimit ist ein Höchstbetrag, der während eines Tages, einer Woche oder eines Monats eingezahlt werden kann. Die Höhe des Limits kann jederzeit geändert werden.",
      limits_deposit_text_3: "Bitte beachten Sie, dass die Erhöhung oder Aufhebung des bestehenden Limits nach 1 Stunde(n) in Kraft tritt.",
      limits_deposit_text_4: "Eine Herabsetzung des Limits wird sofort wirksam.",
      limits_loss_text_1: "VERLUST LIMIT",
      limits_loss_text_2: "Das Verlustlimit ist ein Höchstbetrag, der während eines Tages, einer Woche oder eines Monats in Spielen verloren werden kann. Die Höhe des Limits kann jederzeit geändert werden.",
      limits_loss_text_3: "Bitte beachten Sie, dass die Erhöhung oder Aufhebung des bestehenden Limits nach 1 Stunde(n) in Kraft tritt.",
      limits_loss_text_4: "Eine Herabsetzung des Limits wird sofort wirksam.",
      limits_wager_text_1: "EINSATZ LIMIT",
      limits_wager_text_2:
        "Sie haben die Möglichkeit, den Geldbetrag zu begrenzen, den Sie über einen bestimmten Zeitraum setzen möchten, indem Sie das Einsatzlimit wählen. So können Sie genau kontrollieren, wie viel Geld Sie pro Tag, pro Woche oder pro Monat spielen möchten. Wenn Sie zum Beispiel ein Einsatzlimit von 100 pro Woche festgelegt haben, bedeutet dies, dass Sie nur 100 pro Woche setzen können, unabhängig von Gewinnen oder Verlusten mit diesem Betrag.",
      limits_wager_text_3: " Sie können den Betrag nur verringern oder die Erneuerung ändern, nachdem Sie Ihr Einsatzlimit festgelegt haben.",
      limits_wager_text_4: "{max} pro {Time} ist aktiv. IF NO LIMIT SET HIDE THIS TRANSLATE  ",
      limits_wager_text_5: "Einsatz-Betrag",
      limits_wager_text_6: "Limitierungszeitraum",
      limits_wager_text_7: "pro Tag",
      limits_wager_text_8: "pro Woche",
      limits_wager_text_9: "pro Monat",
      limits_wager_text_10: "Selbes Limit erneut festlegen, wenn das vorherige Limit abgelaufen ist.",
      limits_stake_common_vl: "Please note that the wager limits are common between the different brands of Vana Lauri OÜ (Nubet and Lataamo).",
      limits_loss_common_vl: "Please note that the loss limits are common between the different brands of Vana Lauri OÜ (Nubet and Lataamo).",
      limits_deposit_common_vl: "Please note that the deposit limits are common between the different brands of Vana Lauri OÜ (Nubet and Lataamo).",
      limits_time_day: "Täglicher Betrag",
      limits_time_week: "Wöchentlicher Betrag",
      limits_time_month: "Monatlicher Betrag",
      limits_btn_save: "Daten speichern",
      limits_btn_change: "Daten ändern",
      limits_logout: "Automatisch ausloggen nach: ",
      block_acct: "Sperren",
      block_acct_2: "Konto sperren",
      block_acct_text: "Text",
      block_acct_deactive: "Grund für Deaktivierung",
      block_acct_demo: "Ihre Nachricht an NBT",
      block_acct_btn: "Sperre bestätigen",
      user: "Benutzer",
      user_post: "Post",
      user_email: "Email",
      user_sms: "SMS",
      user_all: "Alle",
      user_phone: "Telefon",
      user_eng: "English",
      user_deut: "Deutsch",
      user_accept: "Quotenänderung akzeptieren",
      change_pass: "Passwort ändern",
      current_pass: "Aktuelles Passwort",
      new_pass: "Neues Passwort",
      confirm_pass: "Neues Passwort bestätigen",
      change_btn: "Passwort ändern",
      retail_id: "Shop Kennung",
      head_text1: "Sportwetten",
      head_text2: "Livewetten",
      head_text3: "Langzeitwetten",
      head_text4: "Analysen",
      head_text5: "Slots",
      head_text6: "Live Casino",
      head_text7: "Esports",
      choose_bnk: "Bank - Konto auswählen",
      password: "Passwort",
      deposit_cancel: "Abbrechen",
      ortype: "oder eingeben: ",
      confirm_mail: "E - Mail bestätigen",
      register_text1: "Ich bin in dem Land, in dem mein Wohnsitz liegt, gesetzlich volljährig. Ich habe die ",
      register_text2: "Geschäftsbedingungen",
      register_text3: "und",
      register_text31: "Datenschutzerklärung",
      register_text4: "gelesen und erkenne diese an. *",
      register_text5: "Registrieren",
      register_text6: "* Pflichtfelder",
      forget_pass: "Passwort vergessen",
      forget_text: "Bitte füllen Sie das folgende Formular aus.Wir senden Ihnen dann eine E - Mail, um Ihr Konto wiederherzustellen.",
      forget_text1: "E - Mail - Adresse oder Benutzername",
      forget_send: "Senden",
      deposit_choose: "Einzahlungsbetrag",
      deposit_transf: "Sofortüberweisung",
      postal: "PLZ",
      postal_acc: "Kontodaten ",
      withdraw_choose: "Auszahlungsbetrag",
      withdraw_text1:
        "Wählen Sie hier eine Zahlungsmöglichkeit für Ihre Anforderung einer Auszahlung.Zur Prävention von Geldwäsche und Betrugsdelikten müssen Kunden mit der gleichen Methode Auszahlungen vornehmen, mit der sie in den vorherigen zwölf Monaten Einzahlungen vorgenommen haben.Es wird eine Ausnahme gemacht, wenn keine andere Option der Auszahlung durch den Zahlungsanbieter zur Verfügung steht.",
      withdraw_text2: "Die Bearbeitung von drei Auszahlungen pro Kalenderwoche ist gebührenfrei.Für jede weitere Auszahlung pro Kalenderwoche behält NBT eine Bearbeitungsgebühr in Höhe von EUR 5 ein. ",
      withdraw_text3: "Jede Auszahlungsaufforderung wird vor der Zahlung von den Mitarbeitern von NBT überprüft.",
      withdraw_text4: "Es gibt keine automatischen Transaktionen.",
      withdraw_bnk_text1: "Auszahlung muss für mindestens 25 --TRANSLATE_CURRENCY-- sein.",
      withdraw_bnk_text2: "Banküberweisungen werden als SEPA - Überweisungen arrangiert.Der Überweisungsvorgang kann zwischen 3 - 5 Bankarbeitstagen dauern.",
      withdraw_bnk_text3: "Bitte beachten Sie, dass Ihre Bank möglicherweise Zahlungen aus dem Ausland erhebt.",
      withdraw_bnk_text4: "Die maximale Auszahlung pro Anfrage beträgt 100.000 --TRANSLATE_CURRENCY--",
      btn_withdraw: "Auszahlen",
      information_title: "Information",
      security_title: "Sicherheit",
      company_title: "Unternehmen",
      pay_opt: "Zahlungsmöglichkeiten",
      term_condi: "Geschäftsbedingungen",
      privacy_pol: "Datenschutzerklärung",
      help: "Hilfe",
      resp_game: "Verantwortungsvolles Spielen",
      game_care: "Spielen mit Verantwortung",
      legal_comp: "Geschäftsfähigkeit",
      data_sec: "Datensicherheit",
      about: "Über Uns",
      contact: "Kontakt",
      imprint: "Impressum",
      help_prof: "Mein Profil",
      bett_type: "Wettmöglichkeiten",
      bett_single: "Einzel",
      bett_comb: "Kombi aus",
      bett_comb_row: "Kombi",
      bett_sys: "System",
      bett_row: "Wege",
      bett_of: "aus",
      bett_bets: "Wetten",
      bett_bank: "Bank",
      bett_odd: "Quoten",
      bett_min: "Min",
      bett_max: "Max",
      bett_maxstake: "Maximaler Gesamteinsatz",
      bett_possiwin: "Gewinnchance",
      bett_maxwin: "Maximaler Gewinn",
      bett_minstake: "Mindesteinsatz",
      bett_numb: "Anzahl der Wetten",
      bett_fee: "Gebühren",
      bett_oddchange: "Quotenänderung akzeptieren",
      cancle_text5: "Die Auswertung der abgegebenen Wettscheine erfolgt direkt nach der Überprüfung der offiziellen Ergebnisse.",
      forget1: "Benutzername oder Passwort vergessen?",
      forget2: "Noch keinen Account ?",
      withdraw_owner: "Besitzer",
      withdraw_esta: "Bankinstitut",
      withdraw_save_bnk: "Bank-Konto speichern",
      withdraw_iban: "IBAN",
      withdraw_swift: "SWIFT-Code / BIC",
      state_24: "24h",
      state_7day: "7 Tage",
      state_30day: "30 Tage",
      state_timer: "Zeitraum",
      state_betslip: "Wettscheinabgabe",
      btn_save: "Speichern",
      please_select: "Bitte auswählen",
      setting_not_interested: "Ich bin nicht mehr an Wetten interessiert.",
      setting_not_satisfied: "Ich bin mit NBT unzufrieden",
      setting_compulsive_prevention: "Ich möchte mein Konto zur Spielsuchtprävention schliessen.",
      hours: "Stunde(n)",
      days: "Tag(e)",
      weeks: "Woche(n)",
      months: "Monat(e)",
      no_limit: "kein Limit",
      never: "niemals",
      permanently: "für immer",
      DD: "TT",
      MM: "MM",
      YYYY: "JJJJ",
      min18Y: "Sie müssen mindestens 18 Jahre alt sein um sich anzumelden",
      field_required: "wird benötigt",
      invalid_date: "Ungültiges Datum",
      invalid_phone: "Ungültige Telefonnummer.",
      invalid_country: "Bitte wählen Sie ein Land aus",
      invalid_email: "Ungültige E-Mail-Adresse",
      invalid_email_confirm: "Die E-Mail-Adressen stimmen nicht überein",
      invalid_screen: "Muss aus 6-12 Buchstaben und Zahlen bestehen und mit einem Buchstaben beginnen.",
      invalid_user_name: "Der Benutzername muss aus 6-12 Buchstaben und Ziffern bestehen und mit einem Buchstaben beginnen. KEINE SONDERZEICHEN.",
      invalid_pass: "Das Passwort sollte mindestens acht Zeichen lang sein.",
      invalid_pass_confirm: "Die Passwörter stimmen nicht überein",
      invalid_recaptcha: "Bitte stellen Sie ReCAPTCHA fertig",
      invalid_tos_privacy: "Bitte stimmen Sie den AGBs zu",
      invalid_zip: "Plz. nicht korrekt",
      email_exists: "Diese E-Mail-Adresse existiert bereits",
      user_exists: "Dieser Benutzername existiert bereits",
      user_registered: "Dieser Benutzer ist bereits registriert",
      invalid_fname: "Ungültiger Vorname",
      invalid_lname: "Ungültiger Nachname",
      register_general: "Interner Serverfehler, bitte probieren Sie es nochmals",
      continue: "Weiter",
      user_unavailable: "Es scheint dass Sie bereits ein registrierter Benutzer sind, bitte kontaktieren Sie unseren Kundendienst falls Sie noch nicht registriert sind",
      email_not_confirmed: "E-Mail-Adresse nicht bestätigt.Klicken Sie hier um das Bestätigungs-E-Mail noch einmal zu senden.",
      email_already_confirmed: "Die E-Mail-Adresse wurde bereits bestätigt",
      confirmation_email_sent: "Bestätigungs-E-Mail gesendet, bitte überprüfen Sie Ihr E-mail Konto.",
      invalid_confirmation_code: "Ungültiger Bestätigungs-Code",
      account_confirmed: "Ihr Konto wurde bestätigt, Sie können sich nun anmelden",
      account_confirmed_auto_identification: "Ihr Konto wurde bestätigt, bitte Auto-Identifizierung abschließen.",
      account_created: "Danke für Ihre Registrierung! Ihr Konto wurde erstellt und ein Aktivierungsemail wurde an die bei der Registrierung angegebene E-Mail-Adresse gesendet.",
      invalid_login: "Ungültiger Benutzername oder falsches Passwort. Zugangsdaten vergessen?",
      invalid_login_empty: "Bitte geben Sie einen Benutzernamen und ein Passwort ein",
      select_a_country: "Land auswählen&hellip;",
      new_livebetting: "Livewetten",
      new_sportsbetting: "Wetten",
      new_outrights: "Langzeitwetten",
      new_analyses: "Analysen",
      new_max_odd: "Max. Quote",
      new_empty_slip: "Wettschein leeren",
      new_show_all_bets: "Alle Wetten anzeigen",
      new_my_bets: "Meine Wetten",
      new_my_bets_pending: "Offen",
      new_my_bets_won: "Gewonnen",
      new_my_bets_cashout: "Cashout",
      new_my_bets_lost: "Verloren",
      new_my_bets_voided: "Storniert",
      new_my_bets_all: "Alle",
      new_my_bets_status: "Status Ihrer Wette",
      new_show: "Anzeigen",
      new_stake_per_bet: "Einsatz pro Wette",
      new_stake_total: "Gesamteinsatz",
      new_stake: "Einsatz",
      new_stake_max: "Max",
      new_winning_chance: "Gewinnchance",
      error_price_inactive_all: "Alle nicht-wettbaren Märkte entfernen",
      place_ticket_pending: "Wette wird geprüft",
      place_ticket_success: "Wette platziert",
      new_pending_coupons: "Offene Wetten",
      new_won_coupons: "gewonnene Tickets",
      new_search: "Suche",
      new_search_term: "Suchbegriff eingeben...",
      new_search_remove: "Suche Zurücksetzen",
      new_now_live: "Jetzt LIVE",
      new_offered_live: "Liveangebot",
      new_select_all: "Alle auswählen",
      new_new_total: "Anzahl",
      new_please_login_in: "Bitte melden Sie sich an",
      betting_rules: "Wettregeln",
      profile_updated: "Profil aktualisiert",
      user_not_found: "Email Adresse nicht gefunden",
      forgot_email_sent: "Email gesendet, Bitte überprüfen Sie Ihren Posteingang ",
      password_changed: "Passwort geändert, weiter zu Login",
      change_password: "Passwort ändern",
      change_password_confirm: "Um Ihr Konto wiederherzustellen, müssen Sie Ihr Passwort ändern. Bitte geben Sie ein neues Passwort ein.",
      change: "Ändern",
      page_not_found_title: "Seite nicht gefunden",
      page_not_found: "Die von Ihnen angeforderte Seite wurde nicht gefunden.",
      partners: "Partner",
      invalid_method: "Ungültiger Betrag",
      operation_successful: "Vorgang erfolgreich",
      operation_unsuccessful: "Vorgang fehlgeschlagen",
      operation_type_success: "Vorgang erfolgreich",
      operation_type_fail: "Vorgang fehlgeschlagen",
      operation_type_cancel: "Vorgang abgebrochen",
      incorrect_password: "Falsches Passwort",
      upload_error_type: "Bitte laden Sie nur PNG- oder JPG-Bilder hoch.",
      upload_error_size: "Die maximale Bildgröße beträgt 2 MB.",
      upload_select: "Datei auswählen&hellip;",
      kyc_vrfn_verified: "Dokument verifiziert",
      kyc_vrfn_failed: "Prüfung des Dokumentes fehlgeschlagen. Bitte lade ein neues Dokument hoch.",
      kyc_vrfn_init: "Ihr Dokument wird überprüft",
      kyc_upload_date: "Letztes Dokument hochgeladen am: ",
      bonus_title: "Bonusse",
      deposits_title: "Einzahlungen",
      withdraws_title: "Auszahlungen",
      other: "Andere",
      date_precedence: "Das `vom`-Datum muss vor dem` bis`-Datum stehen",
      statement_header_ts: "Datum/Uhrzeit",
      statement_header_amount: "Betrag",
      statement_header_method: "Betrag",
      statement_header_trtype: "Status",
      statement_header_prevbal: "Vorherige Balance",
      statement_no_entries: "Keine Einträge zum Anzeigen.",
      statement_header_provider: "Anbieter",
      statement_header_deposits_status: "Einzahlungsstatus",
      statement_header_withdraws_status: "Auszahlungsstatus",
      statement_header_stake: "Einsatz",
      statement_header_win: "Gewinn",
      statement_header_ticket_id: "Ticket/Game",
      statement_header_game: "Spiel",
      statement_header_balance: "Gesamtguthaben",
      pending: "Ausstehend",
      transaction_status_txn_confirmed_success: "Erfolgreich",
      transaction_status_txn_confirmed_failure: "Fehlgeschlagen",
      transaction_status_txn_initiated: "in Bearbeitung",
      transaction_status_co_initiated: "in Bearbeitung",
      transaction_status_co_reversed: "Abgebrochen",
      transaction_status_co_success: "Erfolgreich",
      transaction_status_sb_buyin: "Einsatz",
      transaction_status_sb_win: "Gewinn",
      transaction_status_sb_leave_table: "Gewinn",
      transaction_success: "Transaktion Erfolgreich. Sie können nun dieses Popup schließen",
      transaction_success_short: "Transaktion Erfolgreich",
      transaction_fail: "Transaktion fehlgeschlagen. Bitte versuche es erneut",
      transaction_fail_short: "Transaktion fehlgeschlagen",
      transaction_declined: "Transaktion abgelehnt",
      withdraw_initiated_success: "Auszahlung angefordert",
      withdraw_initiated_fail: "Vorgang fehlgeschlagen",
      withdraw_to: "Auszahlung zu:",
      withdraw_name_on_bank: "Name des Bankkontoinhabers",
      invalid_iban: "Ungültige IBAN",
      invalid_swift: "Ungültiger SWIFT Code",
      swift_iban_mismatch: "SWIFT und IBAN Länder stimmen nicht überein",
      limits_value: "Betrag",
      daily: "Täglich",
      weekly: "Wöchentlich",
      monthly: "Monatlich",
      limits_current: "Gegenwärtig: EUR ",
      active_limits: "Aktive Limits",
      pending_limits: "Anstehende Limits",
      limits_delete: "Löschen",
      limits_confirm: "Bestätigen",
      limits_cancel: "Abbrechen",
      limits_remaining_time: "verbleibende Zeit",
      limits_note: "Limits können nur aktualisiert werden, wenn es keine ausstehenden Limits für diesen Zeitraum gibt",
      days_singular: "Tag",
      days_plural: "Tage",
      invalid_reason: "Ungültiger Grund",
      invalid_period: "Ungültiger Zeitraum",
      block_confirmation: "Sind Sie sicher, dass Sie Ihr Konto sperren und sich ausloggen wollen?",
      rg_account_blocked: "Konto gesperrt",
      rg_temp_block: "Temporärer gesperrt",
      rg_perm_block: "Dauerhaft gesperrt",
      more: "Mehr",
      message_sent: "Nachricht gesendet",
      message_sent_fail: "Nachricht konnte nicht gesendet warden. Bitte versuchen Sie es später erneut.",
      captcha_validation_failed: "Validierung fehlgeschlagen",
      total_odds: "Gesamtquote",
      round_qualification: "Qualifikation",
      round_round_of_16: "Achtelfinale",
      round_quarterfinal: "Viertelfinale",
      round_semifinal: "Halbfinale",
      round_final: "Finale",
      round_3rd_place_final: "Spiel um platz 3",
      round_5th_place_final: "Spiel um platz 5",
      round_7th_place_final: "Spiel um platz 7",
      round_3rd_place_playoff: "Spiel um platz 3",
      round_5th_place_playoff: "Spiel um platz 5",
      round_7th_place_playoff: "Spiel um platz 7",
      explainer_video: "Erkläringsvideo",
      choose_several_leagues: "Mehrere Ligen auswählen",
      js_required: "NBT benötigt Javascript um die Seite korrekt auszuführen!",
      security_question: "Sicherheitsfrage",
      invalid_answer: "Falsche Antwort",
      ref_code: "Code",
      invalid_ref_code: "Falscher Code",
      correct_answers: "Bitte überprüfen Sie die rot markierten Felder",
      button_decline: "Decline",
      button_accept: "Akzeptieren",
      accept_cookie: "Wir verwenden Cookies, um Ihre Nutzererfahrung zu optimieren. Durch die Nutzung unserer Website stimmen Sie der Verwendung von Cookies zu. ",
      affiliate_program: "Affiliateprogramm",
      help_balance: "Gesamtguthaben",
      help_withdrawable: "Auszahlbar",
      help_restricted: "Bonusgeld",
      help_distance: "Echtgeld",
      login_cooldown: "Zu viele fehlgeschlagene Login Versuche. Bitte versuchen sie es später erneut.",
      cooldown: "Zu viele fehlgeschlagene Login Versuche. Bitte versuchen sie es später erneut.",
      depositing_disabled: "Die Einzahlungen wurden für Ihr Konto vorübergehend deaktiviert. Bitte wenden Sie sich an den Kundensupport.",
      withdrawing_disabled: "Die Auszahlungen wurden für Ihr Konto vorübergehend deaktiviert. Bitte wenden Sie sich an den Kundensupport.",
      forced_system: "Die ausgewählte Anzahl an Wetten ist nur als System wettbar.",
      notifications: "Benachrichtigungen",
      notifications_text: "Wie informieren Sie regelmäßig über persönlich abgestimmte NBT Promotions, Boni und Angebote. Bitte wählen Sie, wie Sie diese erhalten möchten:",
      profile_update: "Profil-Aktualisierung",
      profile_update_notice:
        "Bitte füllen Sie die folgenden zusätzlichen Felder aus, um fortzufahren. Bitte beachten Sie, dass diese Felder nur einmal aktualisiert werden können. Bitte stellen Sie daher sicher, dass Sie die korrekten Daten speichern.",
      bonus_terms_title: "Bonusbedingungen Wetten",
      conference_start_title: "Die Konferenz beginnt in",
      welcome: "Willkommen",
      maintenance: "Wartungsarbeiten",
      try_again_later: "Bitte probieren Sie es später noch einmal",
    },
  },
  en: {
    date: {
      long: "MMMM Do, YYYY",
      short: "ddd, MMM Do",
      game: " HH:mm",
      game1: "DD.MM. HH:mm",
      game2: "dd HH:mm",
      ticket: "ddd, MMM Do HH:mm",
      ticketDateFilter: "dd/MM/yyyy",
      extra: "DD.MM.YYYY HH:mm",
    },
    seo: {
      card_title: "NUBET - The home of sportsbetting",
      card_description: "Lightning-fast registration, best odds, good limits and fantastic casino Jackpots. Nubet – Magic of the North",
    },
    menu: {
      lb_shanghai: "Leaderboard Shanghai",
      trotting: "Trotting",
      out_of: "out of",
      balance_cash: "Withdrawable",
      balance_promo: "Bonus",
      free_spins: "Free spins",
      bonus_wr: "Wagering requirement",
      promotions: "Promotions",
      campaigns: "Campaigns",
      bugreport: "Report a bug",
      matchstatistics: "Match Statistics",
      maxOdd: "Odds:",
      panic: "24h Block",
      sportsBetting: "Sports",
      betting: "Betting",
      slip: "Slip",
      myBets: "My Bets",
      casino: "Casino",
      live: "Live",
      livecasino: "Live Casino",
      esports: "Esports",
      all: "All",
      threeHours: "< 3h",
      today: "Today",
      mySports: "My Sports",
      tomorrow: "Tomorrow",
      standings: "Standings",
      results: "Results",
      conference: "Live-Conference",
      h2h: "Comparison",
      summary: "Live-Ticker",
      statistics: "Statistics",
      lineups: "Lineups",
      stream: "Live Stream",
      horseRacing: "Horse Racing",
      horseRacing_1: "Racing",
    },
    stream: {
      availabel: "Nubet Live Stream currently not available.",
    },
    marketSets: {
      trotting: "Racing",
      databet: "Databet",
      eSports: "E-Sports",
      betbuilder: "Betbuilder",
      player: "Player props",
      corners: "Corners",
      bookings: "Bookings",
      favorites: "My Odds",
      mainbets: "Popular",
      ou: "Over/Under",
      handicap: "Handicap (3way)",
      handicap_ou: "Handicap & Over/Under",
      asian: "Asian Markets",
      asianhc: "Handicap",
      bothscore: "Teams to Score",
      game_totals: "Match & Over/Under",
      game_bothscore: "Match & Both score",
      winzero: "Wins to Nil",
      firsthalf: "Half",
      rest: "Rest of the Match",
      firstperiod: "Period",
      configbets: "Specials",
      more: "More",
      firstset: "Set ",
      correctscore: "Correct Score",
      outrights: "Outrights",
      goalscorer: "Goalscorer",
    },

    markets: {
      label_null: " ",
      label_1: "Winner",
      label_10: "Double Chance",
      label_18000: "Over / Under",
    },
    selections: {
      label_null: " ",
      label_1: "1",
      label_2: "X",
      label_3: "2",
      label_12: "+",
      label_13: "-",
      yes: "Yes",
      no: "No",
      over: "Over",
      under: "Under",
      odd: "Odd",
      even: "Even",
      draw: "Draw",
      no_goal: "No Goal",
    },
    slip: {
      nonrunner: "Bet can not include selection marked as non-runner. Please, remove the selection.",
      maxselections: "Bet can have maximum 15 markets.",
      outrightcombi: "Combi cannot include outright and regular market from the same league.",
      outrightsystem: "Combi cannot include outright and regular market from the same league.",
      maximum: "The maximum payout per ticket is 100,000.00",
      new_template: "New Slip-Template",
      delete: "Delete",
      select: "Select",
      active: "Active",
      copy: "Copy",
      stake: "Stake",
      slip_empty: "Your slip is empty",
      my_templates: "My Templates",
      slip: "Betslip",
      templates_all: "All",
      templates_new: "New",
      templates_import: "Import",
      templates_info: "Info",
      single: "Single",
      combi: "Combi",
      system: "System",
      post_ticket_place_bet: "Place Bet",
      post_ticket_verifying: "Bet is being verified",
      betbuilder: "Betbuilder",
    },
    acceptance: {
      same_player_used: "Same player can be used once in a Betbuilder bet.",
      combo_boost_exist_use_cash: "It is not possible to play combo boost with bonus. Please deposit money or place a bet as single or double.",
      token_expired: "Your session expired. Please log in again.",
      maxStake_limit_exceeded: "Your maximum stake limit is reached.",
      internal_error: "An internal error has occurred, please try again later.",
      wager_limit_exceeded: "You have exceeded the wager limit you set yourself.",
      loss_limit_exceeded: "You have exceeded the loss limit you set yourself",
      cash_bet_limit_exceeded: "Max cash bet limit exceeded",
      error_bet_risk_1: "The maximum stake is",
      error_bet_risk_2: "for this event. For a higher stake please try again later.",
      invalid_system_combi: "Invalid system combination. The method has to be larger than 0.",
      error_price_inactive_all: "Remove non-bettable Markets",
      bet_risk: "We currently do not accept stakes for this event.",
      coupons_per_event: "The maximum number of times you can play each event in different coupons is %%1%%.",
      event_out_of_date: "This event is closed.",
      event_unique: "This event needs to be unique on the ticket.",
      inactive_game: "This event is currently not open for betting.",
      inactive_market: "This market is currently not open for betting.",
      incorrect_bet_type: "Incorrect bet-type. Please empty your slip and try again.",
      insufficient_min_bets: "Insufficient number of selected events.",
      insufficient_min_combi_type: "This event requires a minimum combination type to be selected in the system slip.",
      invalid_hc: "The handicap has changed.",
      invalid_price: "The price has changed.",
      limits_exceeded_loss: "Placing this bet puts you over the self-imposed loss limit of eur %%1%%",
      limits_exceeded_wager: "Placing this bet puts you over the self-imposed stake limit of eur %%1%%",
      min_unique_events: "Slip requires a minimum of %%1%% events.",
      no_language_defined: "You have to choose a valid language.",
      no_stake: "You have to enter a valid stake.",
      no_user_found: "You have to login to place a bet.",
      not_enough_credit: "Your balance is too low. Please deposit to place this bet.",
      over_max_stake: "The maximum stake you can play is 2000 eur.",
      rule_operator_0: "",
      rule_operator_1: "Equal to",
      rule_operator_2: "Not equal to",
      rule_operator_3: "Containing",
      rule_operator_4: "Smaller than",
      rule_operator_5: "Maximum",
      rule_operator_6: "Bigger than",
      rule_operator_7: "minimum EUR",
      soap_entication_not_successful: "You have to login to place bets.",
      stake_limits: "Please enter a valid stake between %%1%% and %%2%% %%3%%.",
      ticket_awaiting_approval: "Your ticket is awaiting approval.",
      ticket_bets: "The method of bets on your slip needs to be ",
      ticket_cooldown: "You've already placed a ticket recently. Please wait a little longer.",
      ticket_not_valid: "No valid bet(s) submitted",
      ticket_stake: "The stake on your slip needs to be ",
      ticket_successful: "Bet successfully placed.",
      ticket_uniqueness: "You have already placed other identical ticket(s).",
      ticket_win: "The potential win on your slip needs to be ",
      total_bets: "We currently do not accept stakes for this event.",
      total_stake: "We currently do not accept stakes for this event.",
      total_win: "We currently do not accept stakes for this event.",
      user_disabled: "You are currently not allowed to place bets.",
      user_not_active: "Your account is not active at the moment.",
      multiple_outright_system_bet: "Bet can not include multiple outrights form the same game",
      code_already_used: "Token has expired. Please, refresh the page",
      promo_bet_limit_exceeded: "Maximum promo bet amount is 25 EUR.",
    },
    tickets: {
      now: "Now",
      placed: "Placed",
      win_cashout: "Cashout",
      paid_cashout: "Cashout",
      pending: "Pending",
      all: "All",
      paid: "Won",
      cashedout: "Cashout",
      lost: "Lost",
      cancelled: "Voided",
    },
    stateInfo: {
      safe: "Ball Possession",
      attack: "Ball Possession",
      dangerous_attack: "Dangerous Attack",
      corner: "Corner",
      penalty: "Penalty",
      free_kick: "Free Kick",
      shot: "Shot",
      pole: "Pole",
      goal: "Goal",
      yellow_card: "Yellow Card",
      red_card: "Red Card",
      substitution: "Substitution",
      var: "Video Assistant Referee",
    },
    theme: {
      bluelight: "Light",
      bluedark: "Dark",
      purpledark: "Purple Dark",
      reddark: "Red Dark",
      redlight: "Red",
      pink: "Pink",
      light: "Light",
      dark: "Dark",
      classic: "Classic",
    },
    layout: {
      touch: "Tablet-Mode",
      mouse: "Desktop-Mode",
    },
    mode: {
      standard: "Regular",
      compact: "Compact",
      title: "View",
    },
    oddsFormat: {
      decimal: "Decimal",
      fractional: "Fractional",
      american: "American",
    },
    navView: {
      list: "List",
      tab: "Left-Menu",
    },
    marketsLayout: {
      horizontal: "Odds-Columns",
      vertical: "Odds-Lines",
    },
    paymentMethods: {
      sofort: "Sofort",
      skrill: "Skrill",
      paysafecard: "Paysafe Card",
      bankwire: "Bank Wire",
    },
    slots: {
      tournament: "Tournament",
      seeall: "See all",
      home: "Home",
      casino_back: "Go back to Casino",
      all: "All",
      livecasino: "Live Casino",
      new: "New",
      popular: "Popular",
      mygames: "My Games",
      not_logged_in: "Please log first >> ",
      link_login: "LOGIN",
      search: "Search",
      provider_all: "ALL",
      hotgames: "Hot Games",
      video_slots: "All Games",
      promotions: "Promotions",
      vip_program: "VIP Program",
      book: "Book Spiele",
      megaways: "Megaways",
      irish: "Irish Games",
      winalot: "Win a Lot",
      jackpots: "Jackpots",
      classics: "Classics",
      bonusbuy: "Bonus Buy",
      winoften: "Win Often",
      dropsandwins: "Drops-Wins",
    },
    legal: {
      tAndc_click: "View Terms and Conditions",
      rules_click: "View Betting Rules",
      terms_conditions_link: "Terms and Conditions",
      privacy_policy_link: "Privacy Policy",
      terms_conditions: "I am at least 18 years of age and I have read, accept and agree to the Terms and Conditions.",
      privacy_policy: "I have read and understood the Privacy Policy.",
      welcome_bonus: "I would like to receive a welcome bonus and promotional offers via E-Mail and SMS.",
    },
    cookieConsent: {
      title: "This website uses cookies",
      content1: "We use cookies to personalise content and ads, to provide social media features and to analyse our traffic.",
      content2:
        "We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.",
      button1: "Selection",
      button2: "Allow all cookies",
      button3: "Allow selection",
      option1: "Necessary",
      option2: "Preferences",
      option3: "Statistics",
      option4: "Marketing",
    },
    trustly: {
      complete_deposit: "Complete deposit",
      deposit_back_btn: "Bring me back to main page",
      deposit_waiting1: "The payment is being processed by Trustly! Please, do not close the page! You will be redirected in",
      deposit_waiting2: "seconds.",
      deposit_failed: "Deposit has failed",
      deposit_canceled: "Deposit has been canceled",
      deposit_rejected: "Deposit has been rejected",
      withdraw_rejected: "Withdrawal has been rejected",
      withdraw_canceled: "Withdrawal has been canceled",
      withdraw_failed: "Withdrawal has failed",
      deposit_limit_exceeded: "Deposit limit exceeded",
      withdraw_limit_exceeded: "Withdraw limit exceeded",
      default_deposit_limit: "Deafult deposit limit is {{price}}",
      deposit_not_initialized: "Deposit  has not been initialized yet!",
      deposit_not_completed: "You have initialized deposit but have not completed yet. You can click the button below to complete your deposit.",
    },
    labels: {
      bet_id: "Bet ID",
      amount: "Amount",
      empty_screen_message: "Currently there are no events available for betting.",
      racing_empty_screen_message: "Racing events open around 16 EET on weekdays and before noon on Saturdays.",
      combination: "combination",
      combinations: "combinations",
      spins_left: "Spins Left",
      expires: "Expires",
      games: "Games",
      receipt_copied: "Receipt copied to clipboard",
      show_receipt: "Show Receipt",
      hide_receipt: "Hide Receipt",
      ticket_charged: "Ticket is charged {{!amount}} EUR",
      reuse_selection: "Reuse Selection",
      copy_receipt: "Copy The Receipt",
      matches: "Matches",
      bet_type: "Bet Type",
      example_searches: "EXAMPLE SEARCHES",
      BadCredentialsException: "Wrong password",
      token_expired: "Your session expired. Please log in again.",
      connection_error: "Oops! Something went wrong. Please try again later.",
      token_invalid: "Your session expired. Please log in again.",
      quick_pick: "Quick pick",
      build_your_own: "Build your own",
      old_odds: "Old odds",
      new_odds: "New odds",
      boosted_odds: "Boosted",
      banner_btn: "Bet",
      daily_slip: "Daily ",
      daily_slip_double: "double",
      daily_slip_treble: "treble",
      daily_slip_fold: "combo",
      add_to_slip: "ADD TO BETSLIP",
      tr_bets_exceeded: "Max number of combinations in a system is 1024. Please remove some selections.",
      dead_heat_info: "Odds updated due to dead-heat. Original odds {placed_price}, updated odds {price}.",
      confirm_alert_limits: "Confirm  limits",
      limits_requested_changes: "You have requested changes for following limits",
      confirm_limit_period: " Confirm new limit {{!amount}} EUR in {{!time}} hours",
      confirm_limit_period_ended: "Confirm new limit {{!amount}} EUR",
      abort_limit_change: "Abort limit change",
      confirm_limit_abort: "Are you sure you want to abort scheduled limit change? This action cannot be undone.",
      i_am_sure: "Yes, I am sure",
      remove_limit: "Remove Limit",
      confirm_remove_limit: "Are you sure you want to remove the  limit?",
      keep_my_limit: "No, Keep my limit",
      remove_the_limit: "Yes, remove the limit",
      old_price: "Old price",
      new_price: "New price",
      price_changed: "Bet price changed",
      accept_price_change: "Do you accept the updated price?",
      new: "NEW",
      betbuilder_info_combi: "Vetosekoittimen ja normaalin vedon yhdistelmä ei ole mahdollinen.",
      betbuilder_headline: "Betbuilder - Build your own bet",
      betbuilder_content: "Bet Builder allows you to combine selections from different markets within the same match to create a custom single bet with bigger returns.",
      betbuilder_button: "START BUILDING NOW",
      search_no_result: "Your search has not returned any result!",
      block_activated: "Block activated",
      limits_updated: "Limits updated",
      close_account: "Close Account",
      limit_renewal_period: "Your new limit {{!amount}} will take effect on {{!time}}",
      loss_limits_update: "New loss limits",
      stake_limits_update: "New wager limits",
      deposit_limits_update: "New deposit limits",
      featured_matches: "Featured match",
      view_all: "View all",
      load_more: "Load more",
      page_not_found: "Page not found",
      reality_successful: "You are back",
      result: "Result",
      minimum_stake: "Minimum stake is 1 EUR",
      limit: "Limit",
      register_infotext: "To complete the registration please fill out the form below. Remember to press “Register” button at the end.",
      min_value_10: "Minimum amount 10€",
      max_value_100000: "Maximum amount 100.000€",
      limits_infotext:
        "Gambling is exciting. Make sure that it stays under control. In Nubet, you have an option if you prefer to set limits to control your gambling behaviour. Please note that if setting loss limits they are common across Vana Lauri brands.",
      notifications_infotext: "We regularly inform customers about products, bonuses and offers. Please choose how you would like to receive them:",
      info_limits: "If you want to set your own personal playing limits, you can do so in the settings or set them later.",
      back_to_home: "Back to home",
      maximum_withdraw: "Minimum withdraw 25€. Maximum withdraw 5000€",
      minimum_amount: "Minimum deposit 10€. Maximum deposit 5000€.",
      wrong_birthdate: "You entered a wrong birthdate.",
      maxStake_limit_exceeded: "Your maximum stake limit is reached.",
      renewaltime_1: "Renewal time will be shown when limit is set.",
      renewaltime_2: "Renewal time:",
      outdated_1: "Attention!",
      outdated_2: "Your browser is outdated. Please, update your browser to continue.",
      limits_saved: "Limits saved",
      nonerunner_info: "Odds updated due to horse withdrawals and/or dead-heat. Original odds {placed_price}, updated odds {price}.",
      see_rules: "For more information see rules.",
      customer_support: "Customer Support",
      attention_gambling: "Attention! This is a gambling advertisement. Gambling is not a suitable way to solve financial problems. Familiarize yourself with the rules and act responsibly!",
      slip_banner_1: "Trotting Races",
      slip_banner_2: "@Nubet",
      deposit_succes: "Deposit succeded, complete register",
      deposit_fail: "Deposit failed",
      reset_pass_text: "Please select and confirm a new password.",
      reset_pass: "New Password",
      withdraw_trustly_1: "The ",
      withdraw_trustly_2: " FAST ",
      withdraw_trustly_3: " withdraw with Trustly.",
      deposit_trustly_1: "The ",
      deposit_trustly_2: " FAST ",
      deposit_trustly_3: " deposit with Trustly.",
      login_trustly_1: "or use ",
      login_trustly_2: "banklogin ",
      login_trustly_3: " deposit included",
      register_trustly_1: "Deposit and register ",
      register_trustly_2: "in one step ",
      register_trustly_3: "with ",
      register_trustly_4: "Trustly",
      register_trustly_5: "or the old way with",
      register_trustly_6: "By proceeding you agree to the Terms and Conditions",
      login_banklogin: "Log in to the bank > (Make a deposit)",
      user_register: "Register",
      register_new: "Register",
      register_old: "Standard Registration",
      user_wrong_password: "Wrong password.",
      user_frozen: "Account is frozen after 3 unsuccessful login attemps. Please contact customer support.",
      username_not_found: "Wrong email.",
      username_self_exclusion: "User locked due to self-exclusion.",
      contact_message: "Please leave a message here.",
      send: "Send",
      no_open_tickets: "You have no open tickets at the moment.",
      cashout_not_successful: "Cashout not successful",
      download_app: "Download App",
      no_odds: "There are no odds available for this match at the moment.",
      sample_id1: "Here you can upload both sides of your ID card or passport",
      sample_id2: "(please note that all 4 corners must be recognisable)",
      sample_id3: "The max. file size cannot exceed 4 MB",
      sample_id4: "Sample document:",
      sample_address1: "You can upload your proof of address here",
      sample_address2: "(not older than 3 months, no screenshot)",
      sample_address3: "The max. file size cannot exceed 4 MB",
      sample_address4: "Sample document:",
      sample_deposit1: "You can upload your bank statement here ",
      sample_deposit2: "(IBAN or Skrill email must be recognisable, no subsequently edited documents)",
      sample_deposit3: "The max. file size cannot exceed 4 MB",
      sample_deposit4: "Sample document:",
      all: "All",
      search: "Search",
      panic_button: "24h Block",
      slots_play: "Play Now",
      slots_test: "Practice",
      slots_close: "Close",
      settings_change_pw: "Change Password",
      settings_limits: "Limits",
      settings_blocks: "Block Account",
      settings_notes: "Notifications",
      stmt_tickets: "Transactions",
      stmt_slots: "Casino Games",
      stmt_payin: "Deposits",
      stmt_payout: "Withdraws",
      stmt_24: "24h",
      stmt_7: "7 Days",
      stmt_30: "30 Days",
      stmt_other: "Other",
      stmt_btn: "Show",
      redirect_title: "For our players residing outside of Germany",
      redirect_1: "Exclusive betting fun is now live under the German license on our domain.de website.",
      redirect_2: "Please log yourself into your account with your usual username and password, however on domain.com and not domain.de.",
      redirect_btn: "Continue to domain.com",
      stay_loggedin: "Stay Logged in",
      accept_oddchanges: "Accept odds changes automatically",
      slip_login: "Login or Register",
      slip_stake: "Stake",
      slip_bet: " Bet",
      slip_bets: " Bets",
      slip_win_chance: "Win",
      avatar_now_live: "Now Live",
      avatar_all: "All",
      group_events: "Group events",
      ticket_stake: "Stake:",
      ticket_win_chance: "Win:",
      ticket_show: "Show",
      ticket_copy: "Copy",
      cashout_confirm: "Confirm",
      cashout_successful: "Cashout successfully",
      cashout_sec: "secs",
      cashout_no: "No Cashout",
      cashout_in: "Cashout in",
      kyc_upload: "Click or drag image file to this area to upload.",
      kyc_id: "Proof of Identity",
      kyc_address: "Proof of Address",
      kyc_deposit: "Deposit Slip",
      news: "News",
      slot_rules: "Casino Rules",
      bonus_terms_title_slots: "Bonus Terms Casino",
      whithdraw_verify_account_1: "Your player account must be verified before you can make a withdrawal. Please upload the requested documents in your player profile under ",
      whithdraw_verify_account_2: " or send them via mail to: ",
      payment_bonus: "Exclusive bonus offers! Please select your bonus (",
      payment_bonus_discard: "Don´t use a bonus",
      payment_bonus_code: "or type in a bonus code: ",
      payment_method: "method",
      auto_logout_1: "You are logged in since ",
      auto_logout_2: " minutes. Your activity in the last ",
      auto_logout_3: " minutes:",
      auto_logout_4: "You will get logged out automatically in ",
      auto_logout_5: " seconds.",
      auto_logout_stake: "Stake",
      auto_logout_win: "Win",
      auto_logout_profit: "Profit/Loss",
      auto_logout_statement: "You can view your gaming history in your account statements",
      birth_name: "Birth Name",
      back: "Back",
      next: "Next",
      register_detail_data: "Details",
      register_person_data: "Person",
      register_user_data: "User Data",
      register_title: "Title",
      privacy_pol_link: "Show Privacy Policy",
      account_payment: "Deposit/Withdraw",
      account_login: "Login",
      no_favorite: "You have not yet selected any favourites",
      selected_currency: "EUR",
      min: "Min.",
      max: "Max.",
      attention: "Attention",
      error: "Error",
      please_login_in: "Please sign in",
      show_tickets: "My Bets",
      hide_tickets: "Bettingslip",
      contact_data: "Contact Data",
      gender: "Gender",
      username: "Username",
      wrong_username_or_password: "Wrong username or password.",
      WRONG_CREDENTIALS: "Wrong username or password.",
      CREDENTIALS_VALID_ON_OTHER_DOMAIN: "For our players residing in Germany. Please log yourself into your account with your usual username and password, however on domain.com and not domain.de",
      identity_verification: "IDENTITY VERIFICATION",
      AutoIdentityPending: "Identity verification is pending for your account.",
      AutoIdentityNotCompletedLink: "Auto-identification is not complete yet. Click <a href='%{link}' id='auto-identification'>here</a> to finish.",
      AutoIdentityNotCompletedText: "Auto-identification is not complete yet. Click the next button to finish.",
      auto_identification: "Auto-Identification",
      missing_username: "Username is required",
      missing_password: "Password is required",
      missing_confirm_pass: "Confirm Password is required",
      missing_email: "Email is required",
      missing_confirm_email: "Confirm Email is required",
      missing_gender: "Gender is required",
      missing_first_name: "First name is required",
      missing_last_name: "Last name is required",
      missing_: "Date of birth is required",
      missing_address: "Address is required",
      missing_postal_code: "Postal code is required",
      missing_city: "City is required",
      missing_country: "Country is required",
      missing_birthName: "Birth name is required",
      missing_birthCity: "City of Birth is required",
      login: "Login",
      register: "Open account",
      period: "Period",
      account_block: "You can block your betting account for a specific period. Your betting account will be activated again automatically at the end of the period.",
      deposit_title: "Deposit",
      deposit_now: "Deposit Now",
      deposit_terms_1: "By proceeding you agree to the ",
      deposit_terms_2: "Terms and Conditions ",
      withdraw_title: "Withdraw",
      acc_stmt_title: "ACCOUNT STATEMENT",
      profile_title: "PROFILE",
      messages_title: "MESSAGES",
      setting_title: "Settings",
      retail_create_account: "Create Shop-Account",
      retail_show_card: "Shop-Membercard",
      statement_title: "Statement",
      all_title: "All",
      tickets_title: "Tickets",
      payment_title: "Payments",
      from_title: "From",
      to_title: "To",
      logout: "Logout",
      p_acc_title: "You have no account Statement",
      p_acc_title1: "You have no account Statement 1",
      p_acc_title2: "You have no account Statement 3",
      salu_title: "Salutation",
      form_fname: "First name",
      form_lname: "Last name",
      form_: "Date of birth",
      form_pob: "Place of birth",
      select_data: "SELECT DATA",
      proof_id: "Proof of Identity",
      proof_add: "Proof of Address",
      deposit_slip: "Deposit Slip",
      heading: "Please upload your document",
      upload_data: "Please upload your Data",
      btn_upload: "File Upload",
      upload_data1: "You can upload your proof of address here",
      upload_data2: "(not older than 3 months, no screenshot)",
      upload_data3: "The max. file size cannot exceed 4 MB",
      overview: "Overview",
      my_data: "My Data",
      document: "Documents",
      time: "12.02.2018, 09:00",
      test: "test",
      female: "Female",
      male: "Male",
      street: "Address",
      street2: "Address (continued)",
      zip: "Zip",
      current_zip: "Current Zip",
      city: "City",
      email: "Email",
      mobile: "Phone/Mobile",
      country: "Country",
      nationality: "Nationality",
      cob: "Country of Birth",
      cib: "City of Birth",
      message: "Message",
      date: "Date/Time",
      booking: "Bookings",
      method: "Method",
      betslipnr: "Betslip Nr.",
      message_text: "You have no messages.",
      limits: "Limits",
      betting_limits: "Betting Limits",
      deposit_limits: "Deposit Limits",
      limits_stake: "Wager",
      limits_loss: "Loss",
      limits_deposit: "Deposit",
      limits_max_bet: "Max bet",
      limits_max_bet_text_1: "MY MAX BET LIMITS",
      limits_max_bet_text_2: "You cannot change these limits yourself. Please contact our support for more information.",
      limits_max_bet_text_3: "Max cash bet amount",
      limits_max_bet_text_4: "Max promo bet amount",
      limits_max_promo_bet: "Max promo bet",
      limits_max_promo_bet_text_1: "My Max Promo Bet Limit",
      limits_deposit_text_1: "DEPOSIT LIMIT",
      limits_deposit_text_2: "The deposit limit is the maximum amount a customer can deposit per day, week or month.",
      limits_deposit_text_3: "Any decrease of the deposit limit from a previously set limit takes effect immediately. An increase of the deposit limit will take effect 48 hours after a separate confirmation.",
      limits_deposit_text_4: "Decreasing the limit max comes with immediate effect.",
      limits_loss_text_1: "LOSS LIMIT",
      limits_loss_text_2: "The loss limit is the maximum amount a customer can lose in a day, week or month.",
      limits_loss_text_3: "Any reduction of the loss limit from the previously set limit will take effect immediately. An increase in the loss limit will take effect 48 hours after separate confirmation.",
      limits_loss_text_4: "Decreasing the limit max comes with immediate effect.",
      limits_wager_text_1: "WAGER LIMIT",
      limits_wager_text_2: "Wager limit is the maximum amount a customer can play in a day, week or month.",
      limits_wager_text_3: "Any reduction of the wager limit from the previously set limit will take effect immediately. An increase of the limit will take effect 48 hours after a separate confirmation.",
      limits_wager_text_4: "{max} per {Time} is active. IF NO LIMIT SET HIDE THIS TRANSLATE",
      limits_wager_text_5: "Wager max",
      limits_wager_text_6: "Period for limitation",
      limits_wager_text_7: "per day",
      limits_wager_text_8: "per week",
      limits_wager_text_9: "per month",
      limits_wager_text_10: "Set the same limit max again, once the previous limit has expired.",
      limits_stake_common_vl: " ",
      limits_loss_common_vl: "Please note that the loss limits are common between the different brands of Vana Lauri OÜ (Nubet, Lataamo and Jokeri).",
      limits_deposit_common_vl: " ",
      limits_time_day: "Daily max",
      limits_time_week: "Weekly max",
      limits_time_month: "Monthly max",
      limits_btn_save: "Save Data",
      limits_btn_change: "Change Data",
      limits_logout: "Automatically log out after:",
      block_acct: "Block Account",
      block_acct_2: "Account Block",
      block_acct_text: "Text",
      block_acct_deactive: "Reason For Deactivation",
      block_acct_demo: "Your message to us",
      block_acct_btn: "Block",
      user: "User",
      user_post: "Post",
      user_email: "Email",
      user_sms: "SMS",
      user_all: "All",
      user_phone: "Phone",
      user_eng: "English",
      user_deut: "Deutsch",
      user_accept: "Accept quotes change",
      change_pass: "Change Password",
      current_pass: "Current Password",
      new_pass: "New Password",
      confirm_pass: "Confirm New Password",
      change_btn: "Change Password",
      retail_id: "Shop Code",
      head_text1: "Sports",
      head_text2: "Live",
      head_text3: "Outrights",
      head_text4: "Analyses",
      head_text5: "Casino",
      head_text6: "Live Casino",
      head_text7: "Esports",
      choose_bnk: "Choose Bank Account:",
      password: "Password",
      deposit_cancel: "Cancel",
      ortype: "or type in:",
      confirm_mail: "Confirm Email ",
      register_text1: "I am in the country where my residence is legally of age. I have read and accept the",
      register_text2: "Terms and Conditions",
      register_text3: "and",
      register_text31: "Privacy Policy",
      register_text4: "and consent to the use of my personal data for informational and promotional purposes. *",
      register_text5: "Register",
      register_text6: "* Mandatory Fields",
      forget_pass: "Forgot Password",
      forget_text: "Please complete the following form. We will then send you an e-mail to recover your account.",
      forget_text1: "Email Address Or UserName",
      forget_send: "Send",
      deposit_choose: "Choose max",
      deposit_transf: "Immediate Transfer",
      postal: "Postal Code",
      postal_acc: "Account Data ",
      withdraw_choose: "Choose max:",
      withdraw_text1:
        "Choose a payout method for your withdrawal request here. For money laundering and fraud prevention, a method of withdrawal can only be used if the method was used to deposit in the previous 12 months. An exception is made in the case that no payout option is available by the payment provider.",
      withdraw_text2:
        "The processing of three payouts per calendar week is free of charge. If you should request more than three payouts per calendar week, NBT will deduct a processing fee of 5€ from the payout max per payout.",
      withdraw_text3: "Every withdraw request is checked by a NBT employee and transferred manually.",
      withdraw_text4: "There are no automatic transfers.",
      withdraw_bnk_text1: "Payout must be for at least 25--TRANSLATE_CURRENCY-- ",
      withdraw_bnk_text2: "Bank transfers will be arranged as SEPA transfers. The transfer process can take between 3-5 bank working days.",
      withdraw_bnk_text3: "Please note that your bank might make a charge for payments from abroad.",
      withdraw_bnk_text4: "Maximum payout per request is 100,000--TRANSLATE_CURRENCY-- ",
      btn_withdraw: "Withdraw",
      information_title: "Information",
      security_title: "Security",
      company_title: "Company",
      pay_opt: "Payment Options",
      term_condi: "Terms and Conditions",
      privacy_pol: "Privacy Policy",
      help: "Help",
      resp_game: "Responsible Gaming",
      game_care: "Game Care",
      legal_comp: "Legal Competence",
      data_sec: "Data Security",
      about: "About Us",
      contact: "Contact Us",
      imprint: "Imprint",
      help_prof: "My Profile",
      bett_type: "Types of Bets",
      bett_single: "Single",
      bett_comb: "Combi of",
      bett_comb_row: "System (Free)",
      bett_sys: "System",
      bett_row: "Free",
      bett_of: "of",
      bett_bets: "Bets",
      bett_bank: "Bank",
      bett_odd: "Odds",
      bett_min: "Min",
      bett_max: "Max",
      bett_maxstake: "Maximum Stake",
      bett_possiwin: "Win",
      bett_maxwin: "Max Win",
      bett_minstake: "Minimum Stake",
      bett_numb: "Number of Bets",
      bett_fee: "Fee",
      bett_oddchange: "Accept Odds Changes",
      cancle_text5: "The evaluation and settling of the bet slips will happen after the official results for all events on the bet slip are checked and added to the system.",
      forget1: "Forgot the Username or Password ?",
      forget2: "No Account Yet ?",
      withdraw_owner: "Owner",
      withdraw_esta: "bank establishment",
      withdraw_save_bnk: "Save bank account",
      withdraw_iban: "IBAN",
      withdraw_swift: "SWIFT-Code / BIC",
      state_24: "24h",
      state_7day: "7 Days",
      state_30day: "30 Days",
      state_timer: "Time Range",
      state_betslip: "bet slip release",
      btn_save: "Save",
      please_select: "Please select",
      setting_not_interested: "I am no longer interested in betting/playing",
      setting_not_satisfied: "I am not satisfied with Nubet",
      setting_compulsive_prevention: "I would like to close my account to prevent compulsive gambling",
      hours: "Hour(s)",
      days: "Day(s)",
      weeks: "Week(s)",
      months: "Month(s)",
      no_limit: "no limit",
      never: "never",
      permanently: "Permanently",
      DD: "DD",
      MM: "MM",
      YYYY: "YYYY",
      min18Y: "You must be at least 18 years old to register",
      field_required: "Is required",
      invalid_date: "Invalid date",
      invalid_phone: "Invalid phone number.",
      invalid_country: "Please select a country",
      invalid_email: "Invalid Email address",
      invalid_email_confirm: "The email addresses do not match",
      invalid_screen: "Must consist of 6-12 letters and numbers and begin with a letter.",
      invalid_user_name: "Minimum 6 digits. Letters and numbers reqired.",
      invalid_pass: "Password should be minimum 8 characters.",
      invalid_pass_confirm: "The passwords do not match",
      invalid_recaptcha: "Please complete ReCAPTCHA",
      invalid_tos_privacy: "Please agree to TOS and Privacy Policy",
      invalid_zip: "Invalid zip code",
      email_exists: "Email address already exists",
      phonenumber_exists: "Phone number already exists",
      user_exists: "Username already exists",
      user_registered: "User already registered",
      invalid_fname: "Invalid first name",
      invalid_lname: "Invalid last name",
      register_general: "Internal server error, please retry",
      continue: "Continue",
      user_unavailable: "It seems you are already a registered Customer, please contact Customer Care if you are not yet registered",
      email_not_confirmed: "Email address not confirmed. Click here to resend the confirmation email.",
      email_already_confirmed: "Email address already confirmed",
      confirmation_email_sent: "Confirmation email sent, please check your inbox",
      invalid_confirmation_code: "Invalid confirmation code",
      account_confirmed: "Account confirmed, you may now log in",
      account_confirmed_auto_identification: "Account confirmed, please complete auto identification.",
      account_created: "Thank you for registering! Your account has been created and an activation email has been sent to your inbox.",
      invalid_login: "Invalid username or password. Forgot password?",
      invalid_login_empty: "Please enter a username and password",
      select_a_country: "Select a country&hellip;",
      new_livebetting: "Live",
      new_sportsbetting: "Betting",
      new_outrights: "Outrights",
      new_analyses: "Analyses",
      new_max_odd: "Max Odds",
      new_empty_slip: "Empty Slip",
      new_show_all_bets: "Show all Bets",
      new_my_bets: "My Bets",
      new_my_bets_pending: "Pending",
      new_my_bets_won: "Won",
      new_my_bets_cashout: "Cashout",
      new_my_bets_lost: "Lost",
      new_my_bets_voided: "Voided",
      new_my_bets_all: "All",
      new_my_bets_status: "Status",
      new_show: "Show",
      new_stake_per_bet: "Stake per Bet",
      new_stake_total: "Total Stake",
      new_stake: "Stake",
      new_stake_max: "Max",
      new_winning_chance: "Win",
      error_price_inactive_all: "Remove non-bettable Markets",
      place_ticket_pending: "Bet is verified",
      place_ticket_success: "Bet placed",
      new_pending_coupons: "Pending Coupons",
      new_won_coupons: "Won Coupons",
      new_search: "Search",
      new_search_term: "Enter Search Term...",
      new_search_remove: "Reset Search",
      new_now_live: "Now LIVE",
      new_offered_live: "Live Offers",
      new_select_all: "Select All",
      new_new_total: "Total",
      new_please_login_in: "Please log in",
      betting_rules: "Betting Rules",
      profile_updated: "Profile updated",
      user_not_found: "Email address not found",
      forgot_email_sent: "Email sent, please check your inbox",
      password_changed: "Password changed, continue to login ",
      change_password: "Change password",
      change_password_confirm: "In order to recover your account you need to change your password. Please enter a new password below.",
      change: "Change",
      page_not_found_title: "Page not found",
      page_not_found: "The page you requested was not found.",
      partners: "Partners",
      invalid_max: "Invalid max",
      operation_successful: "Operation successful",
      operation_unsuccessful: "Operation failed",
      operation_type_success: "Operation successful",
      operation_type_fail: "Operation failed",
      operation_type_cancel: "Operation cancelled",
      incorrect_password: "Incorrect password",
      upload_error_type: "Please upload PNG or JPG images only.",
      upload_error_size: "Maximum image size is 2MB.",
      upload_select: "Select file&hellip;",
      kyc_vrfn_verified: "Document verified",
      kyc_vrfn_failed: "Document review failed. Please upload a new document.",
      kyc_vrfn_init: "Your document is being reviewed",
      kyc_upload_date: "Last document uploaded on ",
      bonus_title: "Bonuses",
      deposits_title: "Deposits",
      withdraws_title: "Withdraws",
      other: "Other",
      date_precedence: "The `From` date needs to come before the `To` date",
      statement_header_ts: "Date/Time",
      statement_header_amount: "Amount",
      statement_header_max: "max",
      statement_header_trtype: "Status",
      statement_header_prevbal: "Previous Balance",
      statement_no_entries: "No statement entries to show",
      statement_header_provider: "Provider",
      statement_header_deposits_status: "Deposit Status",
      statement_header_withdraws_status: "Withdraw Status",
      statement_header_stake: "Stake",
      statement_header_win: "Win",
      statement_header_ticket_id: "Ticket/Game",
      statement_header_game: "Game",
      statement_header_balance: "Balance",
      pending: "Pending",
      transaction_status_txn_confirmed_success: "Success",
      transaction_status_txn_confirmed_failure: "Failed",
      transaction_status_txn_initiated: "Initiated",
      transaction_status_co_initiated: "Initiated",
      transaction_status_co_reversed: "Reversed",
      transaction_status_co_success: "Success",
      transaction_status_sb_buyin: "Stake",
      transaction_status_sb_win: "Win",
      transaction_status_sb_leave_table: "Win",
      transaction_success: "Transaction successful. You may now close this popup",
      transaction_success_short: "Transaction successful",
      transaction_fail: "Transaction failed. Please try again",
      transaction_fail_short: "Transaction failed",
      transaction_declined: "Transaction declined",
      withdraw_initiated_success: "Withdraw successful",
      withdraw_initiated_fail: "Operation failed",
      withdraw_to: "Withdraw to",
      withdraw_name_on_bank: "Name on bank account",
      invalid_iban: "Invalid IBAN",
      invalid_swift: "Invalid SWIFT Code",
      swift_iban_mismatch: "SWIFT and IBAN countries do not match",
      limits_value: "Value",
      daily: "Daily",
      weekly: "Weekly",
      monthly: "Monthly",
      limits_current: "Current: EUR ",
      active_limits: "Active limits",
      pending_limits: "Pending limits",
      limits_delete: "Delete",
      limits_confirm: "Confirm",
      limits_cancel: "Cancel",
      limits_remaining_time: "Remaining time",
      limits_note: "Limits can only be updated if there are no pending limits for that time period",
      days_singular: "day",
      days_plural: "days",
      invalid_reason: "Invalid reason",
      invalid_period: "Invalid period",
      block_confirmation: "Are you sure you want to block your account and log out?",
      rg_account_blocked: "Account Blocked",
      rg_temp_block: "Temporary block",
      rg_perm_block: "Permanent block",
      more: "More",
      message_sent: "Message sent",
      message_sent_fail: "Operation failed. Please try again later.",
      captcha_validation_failed: "Validation failed",
      total_odds: "Odds",
      round_qualification: "Qualification",
      round_round_of_16: "Round of 16",
      round_quarterfinal: "Quarterfinal",
      round_semifinal: "Semifinal",
      round_final: "Final",
      round_3rd_place_final: "3rd place playoff",
      round_5th_place_final: "5th place playoff",
      round_7th_place_final: "7th place playoff",
      round_3rd_place_playoff: "3rd place playoff",
      round_5th_place_playoff: "5th place playoff",
      round_7th_place_playoff: "7th place playoff",
      explainer_video: "Quickstart-Guide",
      choose_several_leagues: "Choose several leagues",
      js_required: "NBT needs Javascript to work!",
      security_question: "Security Question",
      invalid_answer: "Invalid answer",
      ref_code: "Code",
      invalid_ref_code: "Invalid code",
      correct_answers: "Please check the boxes highlighted in red",
      button_decline: "Decline",
      button_accept: "Accept",
      accept_cookie: "We use cookies to optimize your user experience. By browsing our website, you agree to the use of cookies. ",
      affiliate_program: "Affiliate program",
      help_balance: "Balance",
      help_withdrawable: "Withdrawable",
      help_restricted: "Restricted",
      help_distance: "Distance to release",
      login_cooldown: "Too many failed login attempts. Please try again later.",
      cooldown: "Too many failed login attempts. Please try again later.",
      depositing_disabled: "Depositing money has been temporarily disabled for your account. Please contact customer support.",
      withdrawing_disabled: "Withdrawing money has been temporarily disabled for your account. Please contact customer support.",
      forced_system: "Your current selections can only be combined in a System ticket.",
      notifications: "Notifications",
      notifications_text: "We regularly inform you about personalised NBT promotions, bonuses and offers. Please choose how you would like to receive them:",
      profile_update: "Profile update",
      profile_update_notice: "Please fill in the following additional fields in order to continue. Note that these fields can only be updated once, so please make sure you save the correct data.",
      bonus_terms_title: "Bonus Terms Betting",
      conference_start_title: "Conference starts in",
      welcome: "Welcome",
      maintenance: " Under Maintenance",
      try_again_later: "Please try again later",
    },
  },
  fi: {
    date: {
      long: "MMMM Do, YYYY",
      short: "ddd, MMM Do",
      game: " HH:mm",
      game1: "DD.MM. HH:mm",
      game2: "dd HH:mm",
      ticket: "ddd, MMM Do HH:mm",
      ticketDateFilter: "dd/MM/yyyy",
      extra: "DD.MM.YYYY HH:mm",
    },
    seo: {
      card_title: "NUBET vedonlyönti - Parhaat kertoimet lajista riippumatta!",
      card_description: "Salamannopea rekisteröityminen vie pelaajan parhaiden vedonlyöntikertoimien äärelle. Nubetin casinolta parhaat jackpotit ja uusimmat kolikkopelit",
    },
    menu: {
      lb_shanghai: "Leaderboard Shanghai",
      trotting: "Ravit",
      out_of: "ulos",
      balance_cash: "Nostettavissa",
      balance_promo: "Bonus",
      free_spins: "Ilmaiskierrokset",
      bonus_wr: "Kierrätettävä",
      promotions: "Kampanjat",
      campaigns: "Kampanjat",
      bugreport: "Raportoi bugi",
      matchstatistics: "Live-tilastot ",
      maxOdd: "Kerroin:",
      panic: "24h Esto",
      sportsBetting: "Urheilu",
      betting: "Vedonlyönti",
      slip: "Kuponki",
      myBets: "Omat vedot",
      casino: "Kasino",
      live: "Live",
      livecasino: "Live Kasino",
      esports: "Esports",
      all: "Kaikki",
      threeHours: "< 3h",
      today: "Tänään",
      mySports: "Omat lajit",
      tomorrow: "Huomenna",
      standings: "Sarjataulukko",
      results: "Tulokset",
      conference: "Live-Conference",
      h2h: "Vertailu",
      summary: "Live-Ticker",
      statistics: "Tilastot",
      lineups: "Kokoonpanot",
      stream: "Live Stream",
      horseRacing: "Ravit",
      horseRacing_1: "Ravit",
    },
    stream: {
      availabel: "Nubet Live Stream ei saatavilla.",
    },
    marketSets: {
      trotting: "Ravi",
      databet: "Databet",
      eSports: "E-Sports",
      player: "Pelaajavedot",
      corners: "Kulmat",
      bookings: "Kortit",
      favorites: "Omat kertoimet",
      mainbets: "Suositut",
      ou: "Yli/Alle",
      handicap: "Tasoitus (3way)",
      handicap_ou: "Tasoitus & Yli/Alle",
      asian: "Aasialaiset",
      asianhc: "Tasoitus",
      bothscore: "Tekee maalin",
      game_totals: "1X2 & Yli/Alle",
      game_bothscore: "1X2 & Molemmat tekee maalin",
      winzero: "Voittaa nollapelillä",
      firsthalf: "Puoliaika",
      rest: "Loput ottelusta ",
      firstperiod: "Erä ",
      configbets: "Erikoiset",
      more: "Lisää",
      firstset: "Erä ",
      correctscore: "Tulosveto",
      outrights: "Voittajavedot",
      goalscorer: "Maalintekijä",
      betbuilder: "Betbuilder",
    },

    markets: {
      label_null: " ",
      label_1: "Voittaja",
      label_10: "Tuplamahdollisuus",
      label_18000: "Yli / Alle",
    },
    selections: {
      label_null: " ",
      label_1: "1",
      label_2: "X",
      label_3: "2",
      label_12: "Yli",
      label_13: "Alle",
      yes: "Kyllä",
      no: "Ei",
      over: "Yli",
      under: "Alle",
      odd: "Pariton",
      even: "Parillinen",
      draw: "Tasapeli",
      no_goal: "Ei Maalia",
    },
    slip: {
      betbuilder: "Betbuilder",
      nonrunner: "Veto ei voi sisältää poissa-olevaa hevosta. Ole hyvä ja poista valinta.",
      maxselections: "Vedossa voi olla maksimissaan 15 pelikohdetta.",
      outrightcombi: "Yhdistelmä ei voi sisältää voittajavetoa ja tavallista kohdetta samasta sarjasta.",
      outrightsystem: "Yhdistelmä ei voi sisältää voittajavetoa ja tavallista kohdetta samasta sarjasta.",
      maximum: "Vedon maksimi ulosmaksu on 100,000.00 ",
      new_template: "Uusi vetokuponki-sapluuna",
      delete: "Poista",
      select: "Valitse",
      active: "Aktiivinen",
      copy: "Kopioi",
      stake: "Panos",
      slip_empty: "Kuponkisi on tyhjä",
      my_templates: "Omat sapluunat",
      slip: "Kuponki",
      templates_all: "Kaikki",
      templates_new: "Uusi",
      templates_import: "Tuo",
      templates_info: "Info",
      single: "Single",
      combi: "Yhdistelmä",
      system: "Järjestelmä",
      post_ticket_place_bet: "Lyö veto",
      post_ticket_verifying: "Vetoa vahvistetaan",
    },
    acceptance: {
      same_player_used: "Samaa pelaajaa voi käyttää vain kerran Vetosekoitin vedossa.",
      combo_boost_exist_use_cash: "Turbo-rekkaa ei voi pelata bonus-rahalla. Talleta rahaa pelitilille tai lyö veto singlenä tai tuplana.",
      token_expired: "Istuntosi on vanhentunut. Kirjaudu uudelleen.",
      multiple_outright_system_bet: "Bet can not include multiple outrights form the same game",
      maxStake_limit_exceeded: "Maksimipanos ylitetty.",
      internal_error: "Sisäinen virhe. Yritä myöhemmin uudestaan.",
      wager_limit_exceeded: "Olet ylittänyt asettamasi pelirajan.",
      loss_limit_exceeded: "Olet ylittänyt asettamasi tappiorajan.",
      cash_bet_limit_exceeded: "Maksimi käteisraja ylitetty",
      error_bet_risk_1: "Maksimipanos on",
      error_bet_risk_2: "tälle tapahtumalle. Jos haluat pelata korkeamman panoksen, yritä myöhemmin uudestaan.",
      invalid_system_combi: "Invalid system combination. The method has to be larger than 0.",
      error_price_inactive_all: "Poista ei-pelattavissa olevat kohteet ",
      bet_risk: "Emme toistaiseksi ota vastaan vetoja tälle tapahtumalle.",
      coupons_per_event: "Maksimimäärä vetoja per tapahtuma on %%1%%.",
      event_out_of_date: "Tapahtuma on päättynyt.",
      event_unique: " This event needs to be unique on the ticket.",
      inactive_game: "Tapahtuma ei pelattavissa.",
      inactive_market: "Kohde ei pelattavissa.",
      incorrect_bet_type: "Väärä pelimuoto. Tyhjennä kuponki ja yritä uudestaan.",
      insufficient_min_bets: "Riittämätön määrä valittuja tapahtumia. ",
      insufficient_min_combi_type: "Tässä yhdistelmässä pitää olla useamman tapahtuman kohde valittuna.",
      invalid_hc: "Tasoitus on muuttunut.",
      invalid_price: "Kerroin on muuttunut.",
      limits_exceeded_loss: "Tämä veto ylittää asettamasi tappiorajan %%1%%",
      limits_exceeded_wager: " Tämä veto ylittää asettamasi pelirajan %%1%%",
      min_unique_events: "Veto vaatii vähintään %%1%% tapahtumaa.",
      no_language_defined: "Ole hyvä ja valitse kieli.",
      no_stake: "Valitse oikea panos.",
      no_user_found: "Kirjaudu sisään lyödäksesi vetoa.",
      not_enough_credit: "Saldosi on liian alhainen. Talleta rahaa pelataksesi vedon.",
      over_max_stake: "Maksimipanos minkä voit pelata on 2000 e.",
      rule_operator_0: "",
      rule_operator_1: "Yhtä suuri kuin",
      rule_operator_2: "Eri suuri kuin",
      rule_operator_3: "Sisältää",
      rule_operator_4: "Pienempi kuin",
      rule_operator_5: "Maksimi",
      rule_operator_6: "Isompi kuin",
      rule_operator_7: "Minimi EUR",
      soap_entication_not_successful: " Kirjaudu sisään lyödäksesi vetoa.",
      stake_limits: "Syötä panos väliltä %%1%% ja %%2%% %%3%%.",
      ticket_awaiting_approval: "Vetosi odottaa vahvistusta.",
      ticket_bets: "The method of bets on your slip needs to be ",
      ticket_cooldown: "Olet jo lyönyt vedon. Odota hieman pidempään.",
      ticket_not_valid: "No valid bet(s) submitted",
      ticket_stake: "Kuponkisi panos pitää olla ",
      ticket_successful: "Veto hyväksytty.",
      ticket_uniqueness: "Olet jo pelannut identtisen vedon.",
      ticket_win: "Kupongin mahdollinen voitto pitää olla ",
      total_bets: " Emme toistaiseksi ota vastaan vetoja tälle tapahtumalle.",
      total_stake: "Emme toistaiseksi ota vastaan vetoja tälle tapahtumalle.",
      total_win: "Emme toistaiseksi ota vastaan vetoja tälle tapahtumalle.",
      user_disabled: "Et ole oikeutettu lyömään vetoa tällä hetkellä. ",
      user_not_active: "Tilisi ei ole aktiivinen tällä hetkellä. ",
      code_already_used: "Token on vanhentunut. Päivitä sivu",
      promo_bet_limit_exceeded: "Maksimi bonus-rahalla pelattu veto on 25 EUR.",
    },
    tickets: {
      now: "Nyt",
      placed: "Pelattu",
      win_cashout: "Cashout",
      paid_cashout: "Cashout",
      pending: "Avoimet",
      all: "Kaikki",
      paid: "Voitto",
      cashedout: "Cashout",
      lost: "Häviö",
      cancelled: "Peruttu",
    },
    stateInfo: {
      safe: "Pallon hallinta",
      attack: " Pallon hallinta ",
      dangerous_attack: "Vaarallinen hyökkäys",
      corner: "Kulmapotku",
      penalty: "Rangaistuspotku",
      free_kick: "Vapaapotku",
      shot: "Laukaus",
      pole: "Tolppa",
      goal: "Maali",
      yellow_card: "Keltainen kortti",
      red_card: "Punainen kortti",
      substitution: "Vaihto",
      var: "VAR",
    },
    theme: {
      bluelight: "Vaalea",
      bluedark: "Tumma",
      purpledark: "Purppura Tumma",
      reddark: "Punainen Tumma",
      redlight: "Punainen",
      pink: "Vaaleanpunainen",
      light: "Vaalea",
      dark: "Tumma",
      classic: "Klassinen",
    },
    layout: {
      touch: "Tablet-versio",
      mouse: "Desktop-versio",
    },
    mode: {
      standard: "Tavallinen",
      compact: "Tiivis",
      title: "Näkymä",
    },
    oddsFormat: {
      decimal: "Desimaali",
      fractional: "Murtoluku",
      american: "Amerikkalainen",
    },
    navView: {
      list: "Lista",
      tab: "Vasen-menu",
    },
    marketsLayout: {
      horizontal: "Vaakasuora",
      vertical: "Pystysuora",
    },
    paymentMethods: {
      sofort: "Sofort",
      skrill: "Skrill",
      paysafecard: "Paysafe",
      bankwire: "Pankkisiirto",
    },
    slots: {
      tournament: "Kisa",
      seeall: "Katso kaikki",
      home: "Koti",
      casino_back: "Takaisin kasinolle",
      all: "Kaikki",
      livecasino: "Live Kasino",
      new: "Uudet",
      popular: "Suositut",
      mygames: "Omat pelit",
      not_logged_in: "Kirjaudu ensin sisään >> ",
      link_login: "LOGIN",
      search: "Etsi",
      provider_all: "KAIKKI",
      hotgames: "Suositut",
      video_slots: "Kaikki ",
      promotions: "Bonus",
      vip_program: "VIP Ohjelma",
      book: "Book Spiele",
      megaways: "Megaways",
      irish: "Irlantilaiset Pelit",
      winalot: "Voita paljon",
      jackpots: "Jättipotit",
      classics: "Klassikot",
      bonusbuy: "Bonusosto",
      winoften: "Voita usein",
      dropsandwins: "Drops-Wins",
    },
    legal: {
      tAndc_click: "Katso käyttöehdot",
      rules_click: "Katso vedonlyönnin säännöt",
      terms_conditions_link: "Käyttöehdot",
      privacy_policy_link: "Tietosuojakäytäntö",
      terms_conditions: "Olen vähintään 18 vuotta ja olen hyväksynyt ja olen lukenut ja hyväksynyt käyttöehdot.",
      privacy_policy: "Olen lukenut ja ymmärtänyt tietosuojakäytännön.",
      welcome_bonus: "Haluan vastaanottaa tarjouksia sähköpostitse tai tekstiviestillä.",
    },
    cookieConsent: {
      title: "Tämä sivusto käyttää evästeitä",
      content1: "Käytämme evästeitä personoidaksemme sisältöä ja mainoksia, tarjotaksemme sosiaalisen median palveluita ja analysoidaksemme sivuston liikennettä.",
      content2:
        "Jaamme myös tietoa käyttäytymisestäsi sivustollamme sosiaalisen median, mainonnan ja analytiikan kumppaneittemme kanssa, jotka voivat yhdistää tietoja muihin heidän palvelussa valtuuttamiisi sinusta kerättyihin tietoihin.",
      button1: "Valinta",
      button2: "Hyväksy kaikki evästeet",
      button3: "Hyväksy valinnat",
      option1: "Pakolliset",
      option2: "Suositellut",
      option3: "Tilastot",
      option4: "Mainonta",
    },
    trustly: {
      complete_deposit: "VIIMEISTELE TALLETUS",
      deposit_back_btn: "Palaa pääsivulle",
      deposit_waiting1: "Trustlyn rahansiirto käynnissä. Älä sulje selainta. Sinut uudelleenohjataan",
      deposit_waiting2: "sekunnissa.",
      deposit_failed: "Talletus epäonnistui",
      deposit_canceled: "Talletus peruttu",
      deposit_rejected: "Talletus hylätty",
      withdraw_rejected: "Nosto hylätty",
      withdraw_canceled: "Nosto peruttu",
      withdraw_failed: "Nosto epäonnistui",
      deposit_limit_exceeded: "Talletusraja ylitetty",
      withdraw_limit_exceeded: "Nostoraja ylitetty",
      default_deposit_limit: "Talletusraja on  {{price}}",
      deposit_not_initialized: "Talletusta ei ole vielä alustettu!",
      deposit_not_completed: "Olet alustanut talletuksen, mutta et ole vielä suorittanut sitä. Voit viimeistellä talletuksen napsauttamalla alla olevaa painiketta.",
    },
    labels: {
      amount: "Määrä",
      bet_id: "Vedon tunnus",
      empty_screen_message: "Juuri nyt ei ole kohteita saatavilla.",
      racing_empty_screen_message: "Ravikohteet aukeavat arkisin noin klo 16 ja lauantaisin ennen puolta päivää.",
      combination: "yhdistelmä",
      combinations: "yhdistelmää",
      spins_left: "Kierroksia jäljellä",
      expires: "Vanhenee",
      games: "Pelit",
      receipt_copied: "Kuitti kopioitu leikepöydälle",
      show_receipt: "Näytä kuitti",
      hide_receipt: "Piilota kuitti",
      ticket_charged: "Lippu maksaa {{!amount}} EUR",
      reuse_selection: "Käytä valintaa uudelleen",
      copy_receipt: "Kopioi kuitti",
      matches: "Ottelut",
      bet_type: "Vetotyyppi",
      example_searches: "ESIMERKKIHAKUJA",
      BadCredentialsException: "Väärä salasana",
      phonenumber_exists: "Puhelinnumero on jo olemassa",
      token_expired: "Istuntosi on vanhentunut. Kirjaudu uudelleen.",
      connection_error: "Voi ei! Jotain meni pieleen. Yritä uudelleen myöhemmin.",
      token_invalid: "Istuntosi on vanhentunut. Kirjaudu uudelleen.",
      quick_pick: "Pikapeli",
      build_your_own: "Sekoita oma",
      old_odds: "Vanhat kertoimet",
      new_odds: "Uudet kertoimet",
      boosted_odds: "Tehostettu",
      banner_btn: "Pelaa",
      daily_slip: "Päivän ",
      daily_slip_double: "tupla",
      daily_slip_treble: "tripla ",
      daily_slip_fold: " rekka",
      add_to_slip: "LISÄÄ KUPONGILLE",
      tr_bets_exceeded: "Järjestelmä voi sisältää maksimissaan 512 yhdistelmää. Ole hyvä ja poista valintoja.",
      dead_heat_info: "Kerroin päivittynyt tasapääjuoksun seurauksena. Alkuperäinen kerroin {placed_price}, päivitetty kerroin {price}.",
      confirm_alert_limits: "Vahvista rajat",
      limits_requested_changes: "Olet pyytänyt muutoksia seuraaviin rajoihin",
      confirm_limit_period: "Vahvista uusi raja {{!amount}} EUR {{!time}} tunnin kuluttua",
      confirm_limit_period_ended: "Vahvista uusi raja {{!amount}} EUR",
      abort_limit_change: "Keskeytä rajan muutos",
      confirm_limit_abort: "Haluatko varmasti keskeyttää ajoitetun rajan muutoksen? Tätä toimintoa ei voi kumota.",
      i_am_sure: "Kyllä, olen varma",
      remove_limit: "Poista raja",
      confirm_remove_limit: "Haluatko varmasti poistaa rajoituksen?",
      keep_my_limit: "Ei, pidä rajani",
      remove_the_limit: "Kyllä, poista raja",
      old_price: "Vanha hinta",
      new_price: "Uusi hinta",
      price_changed: "Vedon hinta muuttunut",
      accept_price_change: "Hyväksytkö päivitetyn hinnan?",
      new: "UUSI",
      betbuilder_info_combi: "Vetosekoittimen ja normaalin vedon yhdistelmä ei ole mahdollinen.",
      betbuilder_headline: "BETBUILDER YHDISTÄÄ OTTELUN PARHAAT PALAT",
      betbuilder_content: "Nubetin uusi Vetosekoitin tarjoaa mahdollisuuden yhdistellä kaikki suosikkiottelun parhaat palat samaan kuponkiin. Varmasti hauskaa ja taatusti isompia voittoja!",
      betbuilder_button: "Aloita vetojen sekoittaminen heti!",
      search_no_result: "Hakusi ei löytänyt tuloksia!",
      block_activated: "Esto aktivoitu",
      limits_updated: "Rajat päivitetty",
      close_account: "Sulje tili",
      limit_renewal_period: "Uusi raja {{!amount}} astuu voimaan {{!time}}",
      loss_limits_update: "Uudet tappiorajat",
      stake_limits_update: "Uudet pelaamisrajat",
      deposit_limits_update: "Uudet talletusrajat",
      featured_matches: "Päivän Peli",
      view_all: "Katso kaikki",
      load_more: "Lataa lisää",
      page_not_found: "Sivua ei löydy",
      reality_successful: "Tervetuloa takaisin",
      result: "Tulos",
      minimum_stake: "Minimipanos on 1 EUR",
      limit: "Raja",
      register_infotext: "Rekisteröinnin loppuunsaattamiseksi täytä alla oleva lomake. Muista painaa lopuksi 'Rekisteröidy' -painiketta.",
      min_value_10: "Pienin arvo 10€",
      max_value_100000: "Suurin arvo 100000€",
      limits_infotext:
        "Rahapelaaminen on jännittävää. Varmista, että se pysyy hallinnassa. Nubetissa sinulla on halutessasi mahdollisuus asettaa rajoja pelaamisen hallintaan. Huomiothan, että jos asetat rajoja niin tappiorajat ovat yhteisiä Vana Lauri OÜ:n eri pelisivustojen välillä.",
      notifications_infotext: "Sinun on mahdollista vastaanottaa viestejä tuotteista, bonuksista ja tarjouksista. Valitse, miten haluat vastaanottaa ne:",
      info_limits: "Jos haluat asettaa itsellesi henkilökohtaisia pelaamisrajoja voit tehdä sen asetuksissa tai asettaa ne myöhemmin.",
      back_to_home: "Takaisin kotiin",
      minimum_amount: "Minimitalletus 10€. Maksimitalletus 5000€.",
      maximum_withdraw: "Miniminosto 25€. Maksiminosto 5000€",
      wrong_birthdate: "Annoit väärän syntymäajan.",
      renewaltime_1: "Uusiutumisaika näytetään, kun raja on asetettu.",
      renewaltime_2: "Uusiutumisaika:",
      outdated_1: "Huomio!",
      outdated_2: "Selaimesi on vanhentunut. Ole hyvä selain ja päivitä jatkaaksesi.",
      limits_saved: "Rajat tallennettu",
      nonerunner_info: "Kerroin päivittynyt hevosten poisjääntien ja/tai tasapääjuoksun seurauksena. Alkuperäinen kerroin {placed_price}, päivitetty kerroin {price}.",
      see_rules: "Katso lisätietoja säännöistä.",
      customer_support: "Asiakaspalvelu",
      attention_gambling: "Huomio! Rahapelit eivät ole sopiva tapa ratkaista taloudellisia ongelmia. Tutustu sääntöihin ja toimi vastuullisesti!",
      slip_banner_1: "Pohjoismaiset ",
      slip_banner_2: "ravikohteet ",
      deposit_succes: "Talletus onnistui",
      deposit_fail: "Talletus epäonnistui",
      reset_pass_text: "Valitse ja hyväksy uusi salasana.",
      reset_pass: "Uusi Salasana",
      withdraw_trustly_1: "Tee ",
      withdraw_trustly_2: " nopea ",
      withdraw_trustly_3: " nosto Trustlyn avulla.",
      deposit_trustly_1: "Tee ",
      deposit_trustly_2: " nopea ",
      deposit_trustly_3: " talletus Trustlyn avulla.",
      login_trustly_1: "tai käytä ",
      login_trustly_2: "pankkitunnusta ",
      login_trustly_3: " sisältää talletuksen",
      register_trustly_1: "Talleta turvallisesti ",
      register_trustly_2: " ",
      register_trustly_3: " verkkopankissa ja pelaa heti.",
      register_trustly_4: " Trustlya",
      register_bonus_1: "Ensitalletuksesi palkitaan riskittömällä vedolla ja 100:lla käteiskierroksella.",
      register_bonus_sports_1: "Sports Bonus Text",
      register_bonus_casino_1: "Casino Bonus Text",
      register_trustly_5: "tai perinteisellä tavalla",
      register_trustly_6: "Etenemällä hyväksyt palvelun käyttöehdot",
      login_banklogin: "Kirjaudu pankkiin > (Tee talletus)",
      user_register: "Rekisteröidy",
      register_new: "TALLETA JA PELAA",
      register_old: "Perinteinen rekisteröityminen",
      user_wrong_password: "Väärä salasana.",
      user_frozen: "Tilisi on jäädytetty kolmen epäonnistuneen kirjautumisyrityksen jälkeen. Ota yhteyttä asiakaspalveluun.",
      username_not_found: "Väärä sähköposti.",
      username_self_exclusion: "Käyttäjä lukittu pelieston vuoksi.",
      contact_message: "Jätä viesti tähän.",
      send: "Lähetä",
      no_open_tickets: "Ei avoimia vetoja.",
      cashout_not_successful: "Cashout epäonnistui",
      download_app: "Lataa sovellus",
      no_odds: "Otteluun ei ole juuri nyt kertoimia tarjolla.",
      sample_id1: "Lataa henkilökorttisi tai passisi molemmat puolet",
      sample_id2: "(huomioi että kaikki 4 kulmaa pitää olla tunnistettavissa)",
      sample_id3: "Maksimi tiedoston koko ei voi olla yli 4 MB",
      sample_id4: "Esimerkki dokumentti:",
      sample_address1: "Lataa todiste osoitteestasi tähän ",
      sample_address2: "(ei 3 kk vanhempia, ei kuvakaappauksia)",
      sample_address3: " Maksimi tiedoston koko ei voi olla yli 4 MB",
      sample_address4: " Esimerkki dokumentti:",
      sample_deposit1: " Lataa pankkitiliotteesi tähän ",
      sample_deposit2: "( IBAN täytyy olla tunnistettavissa, ei jälkikäteen editoituja dokumentteja)",
      sample_deposit3: " Maksimi tiedoston koko ei voi olla yli 4 MB ",
      sample_deposit4: "Esimerkki dokumentti:",
      all: "All",
      search: "Etsi",
      panic_button: "24h Esto",
      slots_play: "Pelaa",
      slots_test: "Harjoittele",
      slots_close: "Sulje",
      settings_change_pw: "Vaihda salasana",
      settings_limits: "Pelirajat",
      settings_blocks: "Tee peliesto",
      settings_notes: "Ilmoitukset",
      stmt_tickets: "Tapahtumat",
      stmt_slots: "Kasino-pelit",
      stmt_payin: "Talletukset",
      stmt_payout: "Nostot",
      stmt_24: "24h",
      stmt_7: "7 päivää",
      stmt_30: "30 päivää",
      stmt_other: "Muu",
      stmt_btn: "Näytä",
      redirect_title: "For our players residing outside of Germany",
      redirect_1: "Exclusive betting fun is now live under the German license on our domain.de website.",
      redirect_2: "Please log yourself into your account with your usual username and password, however on domain.com and not domain.de.",
      redirect_btn: "Jatka osoitteeseen nubet.com",
      stay_loggedin: "Pysy sisäänkirjautuneena",
      accept_oddchanges: "Hyväksy kerroinmuutokset automaattisesti",
      slip_login: "Kirjaudu tai rekisteröidy ",
      slip_stake: "Panos",
      slip_bet: " veto",
      slip_bets: " vetoa",
      slip_win_chance: "Voitto",
      avatar_now_live: "Nyt Livenä",
      avatar_all: "Kaikki",
      group_events: "Group events",
      ticket_stake: "Panos:",
      ticket_win_chance: "Voitto:",
      ticket_show: "Näytä",
      ticket_copy: "Kopioi",
      cashout_confirm: "Vahvista",
      cashout_successful: "Cashout onnistui",
      cashout_sec: "sek",
      cashout_no: "Ei cashoutia",
      cashout_in: "Cashout in",
      kyc_upload: "Siirrä tiedosto tähän lataamista varten. ",
      kyc_id: "Henkilöllisyystodistus",
      kyc_address: "Todistus osoitteesta",
      kyc_deposit: "Pankkitiliote",
      news: "Uutiset",
      slot_rules: "Kasino säännöt",
      bonus_terms_title_slots: "Bonus käyttöehdot kasino",
      whithdraw_verify_account_1: "Pelitilisi pitää vahvistaa ennen kuin voit tehdä noston. Ole hyvä ja lataa tarvittavat dokumentit pelaajaprofiilissasi",
      whithdraw_verify_account_2: " tai lähetä ne postitse osoitteeseen: ",
      payment_bonus: "Ekslusiivinen bonus tarjous! Valitse bonus (",
      payment_bonus_discard: "Älä käytä bonusta",
      payment_bonus_code: "tai syötä bonuskoodi: ",
      payment_method: "tapa (EUR)",
      auto_logout_1: "Olet ollut sisäänkirjautuneena ",
      auto_logout_2: " minuuttia. Toimintasi viimeiset ",
      auto_logout_3: " minuuttia:",
      auto_logout_4: "Sinut uloskirjatataan automaattisesti ",
      auto_logout_5: " sekunnin aikana.",
      auto_logout_stake: "Panos",
      auto_logout_win: "Voitto",
      auto_logout_profit: "Voitto/Tappio",
      auto_logout_statement: "Voit tarkastella pelihistoriaasi tilitapahtumissa. ",
      birth_name: "Nimi",
      back: "Takaisin",
      next: "Seuraava",
      register_detail_data: "Details",
      register_person_data: "Henkilö",
      register_user_data: "User Data",
      register_title: "Title",
      privacy_pol_link: "Näytä Tietosuojakäytäntö",
      account_payment: "Talletus/Nosto",
      account_login: "Kirjaudu",
      no_favorite: "Et ole valinnut suosikkeja",
      selected_currency: "EUR",
      min: "Min.",
      max: "Max.",
      attention: "Huomio",
      error: "Virhe",
      please_login_in: "Kirjaudu sisään",
      show_tickets: "Omat vedot",
      hide_tickets: "Kuponki",
      contact_data: "Yhteystiedot",
      gender: "Sukupuoli",
      username: "Käyttäjätunnus",
      wrong_username_or_password: "Väärä käyttäjätunnus tai salasana.",
      WRONG_CREDENTIALS: " Väärä käyttäjätunnus tai salasana.",
      CREDENTIALS_VALID_ON_OTHER_DOMAIN: "For our players residing in Germany. Please log yourself into your account with your usual username and password, however on domain.com and not domain.de",
      identity_verification: "HENKILÖLLLISYYDEN VAHVISTUS",
      AutoIdentityPending: "Henkilöllisyyden vahvistaminen on käynnissä. ",
      AutoIdentityNotCompletedLink: " Henkilöllisyyden vahvistaminen ei vielä valmistunut. Paina <a href='%{link}' id='auto-identification'>tästä</a> viimeistelläksesi.",
      AutoIdentityNotCompletedText: " Henkilöllisyyden vahvistaminen ei vielä valmistunut. Paina tästä viimeistelläksesi.",
      auto_identification: "Henkilöllisyyden vahvistaminen",
      missing_username: "Käyttäjätunnus tarvitaan",
      missing_password: "Salasana tarvitaan",
      missing_confirm_pass: "Salasanan vahvistus tarvitaan",
      missing_email: "Sähköposti tarvitaan",
      missing_confirm_email: " Sähköpostin vahvistus tarvitaan ",
      missing_gender: "Sukupuoli tarvitaan",
      missing_first_name: "Etunimi tarvitaan",
      missing_last_name: "Sukunimi tarvitaan",
      missing_: "Syntymäaika tarvitaan ",
      missing_address: "Osoite tarvitaan",
      missing_postal_code: "Postinumero tarvitaan",
      missing_city: "Kaupunki tarvitaan",
      missing_country: "Maa tarvitaan",
      missing_birthName: "Nimi tarvitaan",
      missing_birthCity: "Syntymäpaikka tarvitaan",
      login: "Kirjaudu",
      register: "Avaa tili",
      period: "Aikaväli",
      account_block: "Voit lukita pelitilisi tietylle aikavälille. Peliti aktivoituu automaattisesti tämän ajan päätyttyä.",
      deposit_title: "Talleta",
      deposit_now: "Talleta nyt",
      deposit_terms_1: "Jatkamalla eteenpäin hyväksyt ",
      deposit_terms_2: "Käyttöehdot ",
      withdraw_title: "Nosta",
      acc_stmt_title: "TILIOTE",
      profile_title: "PROFIILI",
      messages_title: "VIESTIT",
      setting_title: "Asetukset",
      retail_create_account: "Create Shop-Account",
      retail_show_card: "Shop-Membercard",
      statement_title: "Tiliote",
      all_title: "Kaikki",
      tickets_title: "Tapahtumat",
      payment_title: "Siirrot",
      from_title: "Mistä",
      to_title: "Mihin",
      logout: "Kirjaudu ulos",
      p_acc_title: "Sinulla ei ole tiliotetta",
      p_acc_title1: "Sinulla ei ole tiliotetta 1",
      p_acc_title2: "Sinulla ei ole tiliotetta 3",
      salu_title: "Tervehdys",
      form_fname: "Etunimi",
      form_lname: "Sukunimi",
      form_: "Syntymäaika",
      form_pob: "Syntymäpaikka",
      select_data: "VALTISE",
      proof_id: "Henkilötodistus",
      proof_add: "Todistus osoitteesta",
      deposit_slip: "Talletustosite",
      heading: "Lataa dokumentti",
      upload_data: "Lataa tiedot",
      btn_upload: "Tiedoston lataaminen",
      upload_data1: "Voit ladata todituksen osoitteestasi tähän",
      upload_data2: "(ei 3 kk vanhempia, ei kuvakaappauksia)",
      upload_data3: "Maksimi tiedoston koko ei voi olla yli 4 MB",
      overview: "Yhteenveto",
      my_data: "Tiedot",
      document: "Dokumentit",
      time: "12.02.2018, 09:00",
      test: "testi",
      female: "Nainen",
      male: "Mies",
      street: "Osoite",
      street2: "Osoite (jatkuu)",
      zip: "Postinumero",
      current_zip: "Nykyinen postinumero",
      city: "Kaupunki",
      email: "Sähköposti",
      mobile: "Puhelin",
      country: "Maa",
      nationality: "Kansallisuus",
      cob: "Synnyinmaa",
      cib: "Synnyinkaupunki",
      message: "Viesti",
      date: "Päivä/aika",
      booking: "Bookings",
      method: "Method",
      betslipnr: "Vetokupongin nro",
      message_text: "Sinulla ei ole viestejä.",
      limits: "Pelirajat",
      betting_limits: "Pelaamisrajat",
      deposit_limits: "Talletusrajat",
      limits_stake: "Pelaaminen",
      limits_loss: "Tappio",
      limits_deposit: "Talletus",
      limits_max_bet: "Maksimipanos",
      limits_max_bet_text_1: "OMAT MAKSIMIPANOS RAJAT",
      limits_max_bet_text_2: "Et voi muuttaa näitä rajoja itse. Ole yhteydessä asiakaspalveluun.",
      limits_max_bet_text_3: "Maksimi käteisveto määrä",
      limits_max_bet_text_4: "Maksimi bonusveto määrä",
      limits_max_promo_bet: "Maksimi bonusveto",
      limits_max_promo_bet_text_1: "Maksimi bonusveto Raja",
      limits_deposit_text_1: "TALLETUSRAJA",
      limits_deposit_text_2: "Talletusraja on maksimi, minkä asiakas voi tallettaa päivässä, viikossa tai kuukaudessa.",
      limits_deposit_text_3: "Talletusrajan laskeminen aiemmin asetetusta astuu voimaan välittömästi. Talletusrajan nostaminen astuu voimaan 48 tunnin kuluttua erilllisen vahvistuksen jälkeen.",
      limits_deposit_text_4: "Rajan laskeminen astuu voimaan välittömästi. ",
      limits_loss_text_1: "TAPPIORAJA",
      limits_loss_text_2: "Tappioraja on maksimi, minkä asiakas voi hävitä päivässä, viikossa tai kuukaudessa.",
      limits_loss_text_3: "Tappioirajan laskeminen aiemmin asetetusta astuu voimaan välittömästi. Tappiorajan nostaminen astuu voimaan 48 tunnin kuluttua erilllisen vahvistuksen jälkeen.",
      limits_loss_text_4: "Rajan laskeminen astuu voimaan välittömästi.",
      limits_wager_text_1: "PELAAMISRAJA",
      limits_wager_text_2: "Pelaamisraja on maksimi, minkä asiakas voi pelata päivässä, viikossa tai kuukaudessa.",
      limits_wager_text_3: "Pelaamisrajan laskeminen aiemmin asetetusta astuu voimaan välittömästi. Pelaamisrajan nostaminen astuu voimaan 48 tunnin kuluttua erilllisen vahvistuksen jälkeen.",
      limits_wager_text_4: "{max} per {Time} is active. IF NO LIMIT SET HIDE THIS TRANSLATE",
      limits_wager_text_5: "Veto maksimi",
      limits_wager_text_6: "Pelirajan aikaväli",
      limits_wager_text_7: "päivässä",
      limits_wager_text_8: "viikossa",
      limits_wager_text_9: "kuukaudessa",
      limits_wager_text_10: "Aseta sama raja kun edellinen rajaa menee umpeen.",
      limits_stake_common_vl: " ",
      limits_loss_common_vl: "Huomioithan, että tappiorajat ovat yhteisiä Vana Lauri OÜ:n eri tuotemerkkien välillä (Nubet, Lataamo ja Jokeri).",
      limits_deposit_common_vl: " ",
      limits_time_day: "Päivittäinen maksimi",
      limits_time_week: "Viikottainen maksimi",
      limits_time_month: "Kuukausittainen maksimi",
      limits_btn_save: "Talleta",
      limits_btn_change: "Muokkaa",
      limits_logout: "Kirjaudu ulos automaattisesti:",
      block_acct: "Tee peliesto",
      block_acct_2: "Pelitilin esto",
      block_acct_text: "Teksti",
      block_acct_deactive: "Deaktivoinnin syy",
      block_acct_demo: "Viestisi meille",
      block_acct_btn: "Estä",
      user: "Käyttäjä",
      user_post: "Osoite",
      user_email: "Sähköposti",
      user_sms: "Tekstiviesti",
      user_all: "Kaikki",
      user_phone: "Puhelin",
      user_eng: "Englanti",
      user_deut: "Saksa",
      user_accept: "Hyväksy kerroinmuutokset",
      change_pass: "Vaihda salasana",
      current_pass: "Nykyinen salasana",
      new_pass: "Uusi salasana",
      confirm_pass: "Vahvista uusi salasana",
      change_btn: "Vaihda Salasana",
      retail_id: "Shop Code",
      head_text1: "Urheilu",
      head_text2: "Live",
      head_text3: "Voittajavedot",
      head_text4: "Analyysit",
      head_text5: "Kasino",
      head_text6: "Live Kasino",
      head_text7: "Esports",
      choose_bnk: "Valitse Pankkitili:",
      password: "Salasana",
      deposit_cancel: "Kumoa",
      ortype: "or type in:",
      confirm_mail: "Vahvista sähköposti ",
      register_text1: "Olen ikäni puolesta oikeutettu osallistumaan rahapelien pelaamiseen. Olen lukenut ja hyväksynyt ",
      register_text2: "käyttöehdot",
      register_text3: "ja",
      register_text31: "tietosuojakäytännön",
      register_text4: "ja suostun henkilötietojeni käyttöön tiedotus- ja myynninedistämistarkoituksiin. *",
      register_text5: "Rekisteröidy",
      register_text6: "* Pakolliset kentät",
      forget_pass: "Salasana unohtunut",
      forget_text: "Täytä seuraava lomake. Lähetämme sinulle sähköpostin palauttaaksesi salasanan.",
      forget_text1: "Sähköpostiosoite tai käyttäjätunnus ",
      forget_send: "Lähetä",
      deposit_choose: "Valitse maksimi:",
      deposit_transf: "Pikatalletus",
      postal: "Postinumero",
      postal_acc: "Account Data ",
      withdraw_choose: "Valitse maksimi:",
      withdraw_text1:
        "Choose a payout method for your withdrawal request here. For money laundering and fraud prevention, a method of withdrawal can only be used if the method was used to deposit in the previous 12 months. An exception is made in the case that no payout option is available by the payment provider.",
      withdraw_text2:
        "The processing of three payouts per calendar week is free of charge. If you should request more than three payouts per calendar week, NBT will deduct a processing fee of 5€ from the payout max per payout.",
      withdraw_text3: "Every withdraw request is checked by a NBT employee and transferred manually.",
      withdraw_text4: "There are no automatic transfers.",
      withdraw_bnk_text1: "Payout must be for at least 25--TRANSLATE_CURRENCY-- ",
      withdraw_bnk_text2: "Bank transfers will be arranged as SEPA transfers. The transfer process can take between 3-5 bank working days.",
      withdraw_bnk_text3: "Please note that your bank might make a charge for payments from abroad.",
      withdraw_bnk_text4: "Maximum payout per request is 100,000--TRANSLATE_CURRENCY-- ",
      btn_withdraw: "Nosto",
      information_title: "Tietoja",
      security_title: "Turvallisuus",
      company_title: "Yritys",
      pay_opt: "Maksuvälineet",
      term_condi: "Käyttöehdot",
      privacy_pol: "Tietosuojakäytäntö",
      help: "Apua",
      resp_game: "Vastuullinen pelaaminen",
      game_care: "Game Care",
      legal_comp: "Legal Competence",
      data_sec: "Data Security",
      about: "Tietoa meistä",
      contact: "Yhteystiedot",
      imprint: "Imprint",
      help_prof: "Oma profiili",
      bett_type: "Vetomuodot",
      bett_single: "Single",
      bett_comb: "Yhdistelmä",
      bett_comb_row: "Järjestelmä (Vapaa)",
      bett_sys: "Järjestelmä",
      bett_row: "Rivi",
      bett_of: "/",
      bett_bets: "vetoa",
      bett_bank: "Pankki",
      bett_odd: "Kerroin",
      bett_min: "Min",
      bett_max: "Max",
      bett_maxstake: "Maksimipanos",
      bett_possiwin: "Voitto",
      bett_maxwin: "Maksimivoitto",
      bett_minstake: "Minimipanos",
      bett_numb: "Vetojen lkm",
      bett_fee: "Maksu",
      bett_oddchange: "Hyväksy kerroinmuutokset",
      cancle_text5: "Voitonmaksu tapahtuu sen jälkeen kun viralliset tulokset on tarkistettu ja kirjattu järjestelmään.",
      forget1: "Käyttäjätunnus tai salasana unohtunut ?",
      forget2: "Ei vielä pelitiliä ?",
      withdraw_owner: "Omistaja",
      withdraw_esta: "pankki",
      withdraw_save_bnk: "Talleta pankkitili",
      withdraw_iban: "IBAN",
      withdraw_swift: "SWIFT-Code / BIC",
      state_24: "24h",
      state_7day: "7 päivää",
      state_30day: "30 päivää",
      state_timer: "Aikaväli",
      state_betslip: "bet slip release",
      btn_save: "Tallenna",
      please_select: "Valitse",
      setting_not_interested: "En ole enää kiinnostunut rahapeleistä ",
      setting_not_satisfied: "En ole tyytyväinen Nubet.comiin",
      setting_compulsive_prevention: "Haluaisin sulkea tilini ehkäistäkseni peliriippuvuutta.",
      hours: "tuntia",
      days: "päivää",
      weeks: "viikkoa",
      months: "kuukautta",
      no_limit: "ei rajaa",
      never: "ei koskaan",
      permanently: "pysyvästi",
      DD: "DD",
      MM: "MM",
      YYYY: "YYYY",
      min18Y: "Sinun täytyy olla yli 18-vuotias rekisteröityäksesi.",
      field_required: "Vaaditaan",
      invalid_date: "Väärä pvm",
      invalid_phone: "Väärän muotoinen puhelinnumero",
      invalid_country: "Valitse maa",
      invalid_email: "Väärän muotoinen sähköpostiisoite",
      invalid_email_confirm: "Sähköpostiosoitteet eivät täsmää",
      invalid_screen: "Pitää sisältää 6-12 kirjainta/numeroa ja alkaa kirjaimella.",
      invalid_user_name: "Vähintään 6 merkkiä. Joko kirjaimia tai numeroita.",
      invalid_pass: "Salasanan pitää sisältää vähintää 8 merkkiä.",
      invalid_pass_confirm: "Salasanat eivät täsmää",
      invalid_recaptcha: "Täydennä ReCAPTCHA",
      invalid_tos_privacy: "Ole hyvä ja hyväksy Käyttöehdot ja Tietosuojakäytäntö",
      invalid_zip: "Väärä postinumero",
      email_exists: "Sähköpostiosoite on jo olemassa",
      user_exists: "Käyttäjätunnus jo rekisteröity",
      user_registered: "Käyttäjä jo rekisteröity",
      invalid_fname: "Väärän muotoinen etunimi",
      invalid_lname: "Väärän muotoinen sukunimi",
      register_general: "Sisäinen virhe, yritä uudelleen",
      continue: "Jatka",
      user_unavailable: "Vaikuttaa siltä, että olet jo rekisteröitynyt. Ota yhteys asiakaspalveluun, jos et ole vielä rekisteröitynyt. ",
      email_not_confirmed: "Sähköpostitunnusta ei vahvistettu. Klikkaa tästä vahvistussähköpostin uudelleen lähetys.",
      email_already_confirmed: "Sähköpostiosoite on jo vahvistettu",
      confirmation_email_sent: "Vahvistussähköposti lähetetty. Tarkista Saapuneet -kansiosi.",
      invalid_confirmation_code: "Väärä vahvistuskoodi",
      account_confirmed: "Tili vahvistettu. Voit sisäänkirjautua.",
      account_confirmed_auto_identification: "Tili vahvistettu. Täytä automaattinen tunnistautuminen.",
      account_created: "Kiitos rekisteröitymisestä! Tilisi on avattu ja aktivointi sähköposti lähetetty.",
      invalid_login: "Väärä käyttäjätunnus tai salasana. Salasana unohtunut?",
      invalid_login_empty: "Syötä käyttäjätunnus ja salasana",
      select_a_country: "Valitse maa&hellip;",
      new_livebetting: "Live",
      new_sportsbetting: "Vedonlyönti",
      new_outrights: "Voittajavedot",
      new_analyses: "Analyysit",
      new_max_odd: "Maksimikerroin",
      new_empty_slip: "Tyhkennä kuponki",
      new_show_all_bets: "Näytä kaikki vedot",
      new_my_bets: "Omat vedot",
      new_my_bets_pending: "Odottaa",
      new_my_bets_won: "Voitot",
      new_my_bets_cashout: "Cashout",
      new_my_bets_lost: "Tappiot",
      new_my_bets_voided: "Mitätöidyt",
      new_my_bets_all: "Kaikki",
      new_my_bets_status: "Tila",
      new_show: "Näytä",
      new_stake_per_bet: "Panos per veto",
      new_stake_total: "Kokonaispanos",
      new_stake: "Panos",
      new_stake_max: "Max",
      new_winning_chance: "Voittaa",
      error_price_inactive_all: "Poista ei-pelattavissa olevat kohteet",
      place_ticket_pending: "Veto odottaa hyväksyntää",
      place_ticket_success: "Veto hyväksytty",
      new_pending_coupons: "Odottavat vedot",
      new_won_coupons: "Voitetut vedot",
      new_search: "Etsi",
      new_search_term: "Syötä hakuehto...",
      new_search_remove: "Nollaa haku",
      new_now_live: "Nyt LIVE",
      new_offered_live: "Live tarjoukset",
      new_select_all: "Valitse kaikki",
      new_new_total: "Total",
      new_please_login_in: "Kirjaudu sisään",
      betting_rules: "Vedonlyönnin säännöt",
      profile_updated: "Profiili päivitetty",
      user_not_found: "Sähköpostiosoitetta ei löydy",
      forgot_email_sent: "Sähköposti lähetetty. Tarkista Saapuneet -kansio.",
      password_changed: "Salasana vaihdettu. Jatka sisäänkirjautumalla",
      change_password: "Salasana vaihdettu",
      change_password_confirm: "Jotta voit palauttaa tilisi, sinun pitää vaihtaa salasana. Syötä uusi salasana alla olevaan kenttään.",
      change: "Vaihda",
      page_not_found_title: "Sivua ei löytynyt",
      page_not_found: "Sivua ei löytynyt.",
      partners: "Kumppanit",
      invalid_max: "Väärä maksimi",
      operation_successful: "Operaatio onnistui",
      operation_unsuccessful: "Operaatio epäonnistui",
      operation_type_success: " Operaatio onnistui",
      operation_type_fail: "Operaatio epäonnistui",
      operation_type_cancel: "Operaatio peruuntui",
      incorrect_password: "Väärä salasana",
      upload_error_type: "Lataa vain PNG or JPG kuvatiedostoja.",
      upload_error_size: "Maksimi tiedoston koko on 2MB.",
      upload_select: "Valitse tiedosto&hellip;",
      kyc_vrfn_verified: "Tiedosto vahvistettu",
      kyc_vrfn_failed: "Tiedoston vahvistus epäonnistui. Ole hyvä ja lataa uusi dokumentti.",
      kyc_vrfn_init: "Dokumenttia vahvistetaan",
      kyc_upload_date: "Viimeisin dokumentti ladattu ",
      bonus_title: "Bonukset",
      deposits_title: "Talletukset",
      withdraws_title: "Nostot",
      other: "Muut",
      date_precedence: "`Mistä` päivä täytyy olla ennen `Mihin` päivää",
      statement_header_ts: "Päivä/Aika",
      statement_header_amount: "Summa",
      statement_header_max: "max",
      statement_header_trtype: "Status",
      statement_header_prevbal: "Edellinen saldo",
      statement_no_entries: "Ei tilitapahtumia ",
      statement_header_provider: "Provider",
      statement_header_deposits_status: "Talletuksen tila",
      statement_header_withdraws_status: "Noston tila",
      statement_header_stake: "Panos",
      statement_header_win: "Voitto",
      statement_header_ticket_id: "Kuponki/Peli",
      statement_header_game: "Peli",
      statement_header_balance: "Saldo",
      pending: "Odottaa",
      transaction_status_txn_confirmed_success: "Onnistui",
      transaction_status_txn_confirmed_failure: "Epäonnistui",
      transaction_status_txn_initiated: "Aloitettu",
      transaction_status_co_initiated: "Aloitettu",
      transaction_status_co_reversed: "Käännetty",
      transaction_status_co_success: "Onnistui",
      transaction_status_sb_buyin: "Panos",
      transaction_status_sb_win: "Voitto",
      transaction_status_sb_leave_table: "Voitto",
      transaction_success: "Siirto onnistui. Voit sulkea ikkunan.",
      transaction_success_short: "Siirto onnistui",
      transaction_fail: "Siirto epäonnistui. Yritä uudestaan",
      transaction_fail_short: "Siirto epäonnistui",
      transaction_declined: "Siirto hylätty",
      withdraw_initiated_success: "Nosto onnistui",
      withdraw_initiated_fail: "Operaatio epäonnistui",
      withdraw_to: "Nosta tilille",
      withdraw_name_on_bank: "Pankkitilin nimi",
      invalid_iban: "Väärä IBAN",
      invalid_swift: "Väärä SWIFT koodi",
      swift_iban_mismatch: "SWIFT ja IBAN maat eivät täsmää",
      limits_value: "Arvo",
      daily: "Päivittäinen",
      weekly: "Viikottainen",
      monthly: "Kuukausittainen",
      limits_current: "Nykyinen: EUR ",
      active_limits: "Aktiiviset rajat",
      pending_limits: "Odottavat rajat",
      limits_delete: "Poista",
      limits_confirm: "Vahvista",
      limits_cancel: "Kumoa",
      limits_remaining_time: "Jäljelläoleva aika",
      limits_note: "Rajoja voi muuttaa vain, jos kyseisellä aikavälillä ei ole odottavia rajoja.",
      days_singular: "päivä",
      days_plural: "päivää",
      invalid_reason: "Väärä syy",
      invalid_period: "Väärä aikaväli",
      block_confirmation: "Oletko varma, että haluat lukita pelitilisi ja kirjautua ulos?",
      rg_account_blocked: "Pelitili lukittu",
      rg_temp_block: "Tilapäinen peliesto",
      rg_perm_block: "Pysyvä peliesto",
      more: "Lisää",
      message_sent: "Viesti lähetetty",
      message_sent_fail: "Operaatio epäonnistui. Yritä myöhemmin uudestaan.",
      captcha_validation_failed: "Validointi epäonnistui",
      total_odds: "Kerroin",
      round_qualification: "Karsinta",
      round_round_of_16: "Round of 16",
      round_quarterfinal: "Puolivälierä",
      round_semifinal: "Välierä",
      round_final: "Finaali",
      round_3rd_place_final: "3. sija playoff",
      round_5th_place_final: "5. sija playoff",
      round_7th_place_final: "7.sija playoff",
      round_3rd_place_playoff: "3. sija playoff",
      round_5th_place_playoff: "5. sija playoff",
      round_7th_place_playoff: "7. sija playoff",
      explainer_video: "Pikaopas",
      choose_several_leagues: "Valitse usampi sarja",
      js_required: "Nubet tarvitsee toimiakseen Javascriptiä!",
      security_question: "Turvakysymys",
      invalid_answer: "Väärä vastaus",
      ref_code: "Koodi",
      invalid_ref_code: "Väärä koodi",
      correct_answers: "Valitse punaisella merkityt ruudut.",
      button_decline: "HYLKÄÄ",
      button_accept: "Hyväksy",
      accept_cookie: "Käytämme evästeitä optimoidaksemme käyttäjäkokemuksen. Selailemalla sivuillamme, hyväksyt evästeiden käytön.",
      affiliate_program: "Affiliate ohjelma",
      help_balance: "Saldo",
      help_withdrawable: "Nostettavissa",
      help_restricted: "Bonussaldo",
      help_distance: "Kierrätettävä (summa)",
      login_cooldown: "Liian monta sisäänkirjautumista. Yritä myöhemmin uudestaan.",
      cooldown: "Liian monta sisäänkirjautumista. Yritä myöhemmin uudestaan.",
      depositing_disabled: "Talletukset pelitilillesi eivät ole toistaiseksi mahdolisia. Ole yhteydessä asiakaspalveluumme.",
      withdrawing_disabled: "Nostot pelitililtäsi eivät ole toistaiseksi mahdolisia. Ole yhteydessä asiakaspalveluumme.",
      forced_system: "Voit pelata nykyisiä valintojasi vain järjestelmänä.",
      notifications: "Ilmoitukset",
      notifications_text: "Ilmoitamme sinulle säännöllisesti personoiduita kohdeista, bonuksista ja tarjouksista. Valitse miten haluaisit ne vastaanottaa: ",
      profile_update: "Profiilin päivitys",
      profile_update_notice: "Täytä seuraavat kentät jatkaaksesi. Huomioi, että nämä kentät voi täyttää vain kerran joten ole huolellinen.",
      bonus_terms_title: "Bonus käyttöehdot vedonlyönti",
      conference_start_title: "Conference starts in",
      welcome: "Tervetuloa",
      maintenance: "Huoltokatko",
      try_again_later: "Yritä myöhemmin uudestaan",
    },
  },
  et: {
    date: {
      long: "MMMM Do, YYYY",
      short: "ddd, MMM Do",
      game: " HH:mm",
      game1: "DD.MM. HH:mm",
      game2: "dd HH:mm",
      ticket: "ddd, MMM Do HH:mm",
      ticketDateFilter: "dd/MM/yyyy",
      extra: "DD.MM.YYYY HH:mm",
    },
    seo: {
      card_title: "NUBET - spordikihlvedude kodu",
      card_description: "Välkkiire registreerimine, parimad koefitsiendid, head limiidid ja fantastilised kasiino jackpotid. Nubet - Põhja maagia",
    },
    menu: {
      lb_shanghai: "Leaderboard Shanghai",
      trotting: "Traav",
      out_of: "otsas",
      balance_cash: "Väljavõetav",
      balance_promo: "Boonus",
      free_spins: "Tasuta keerutused",
      bonus_wr: "Panustamise nõue",
      promotions: "Kampaaniad",
      campaigns: "Kampaaniad",
      bugreport: "Teatab veast",
      matchstatistics: "Matš Statistika",
      maxOdd: "Koef:",
      panic: "24h Enesevälistus",
      sportsBetting: "Sports",
      betting: "Sport",
      slip: "Pilet",
      myBets: "Minu Piletid ",
      casino: "Kasiino",
      live: "Live",
      livecasino: "Live Kasiino",
      esports: "Esports",
      all: "Kõik",
      threeHours: "< 3h",
      today: "Täna",
      mySports: "Minu Sport",
      tomorrow: "Homme",
      standings: "Turniiritabel",
      results: "Tulemused",
      conference: "Live-Conference",
      h2h: "H2H",
      summary: "Live-Ticker",
      statistics: "Statistika",
      lineups: "Rivistus",
      stream: "Live Stream",
      horseRacing: "Hobuste võidusõit",
      horseRacing_1: "Racing",
    },
    stream: {
      availabel: "Nubet Live Stream currently not available.",
    },
    marketSets: {
      trotting: "Traav",
      databet: "Databet",
      eSports: "E-Sports",
      player: "Mängija",
      corners: "Nurgad",
      bookings: "Broneeringud",
      favorites: "Minu koefitsiente",
      mainbets: "Populaarsed",
      ou: "Üle/Alla",
      handicap: "Händikäp (3way)",
      handicap_ou: "Händikäp & Üle/Alla",
      asian: "Asian Markets",
      asianhc: "Händikäp",
      bothscore: "Meeskonnad skoorivad",
      game_totals: "Võitja & Üle/Alla",
      game_bothscore: " Võitja & Mõlemad skoorivad",
      winzero: "Võidab nulliga",
      firsthalf: "Poolaja",
      rest: "Ülejäänud matš",
      firstperiod: "Perioodi",
      configbets: "Eripakkumised",
      more: "Rohkem",
      firstset: "Seti",
      correctscore: "Lõpptulemus",
      outrights: "Võitjapanus",
      goalscorer: "Väravalööja",
      betbuilder: "Betbuilder",
    },

    markets: {
      label_null: " ",
      label_1: "Võitja",
      label_10: "Topeltvõimalus",
      label_18000: "Üle / Alla",
    },
    selections: {
      label_null: " ",
      label_1: "1",
      label_2: "X",
      label_3: "2",
      label_12: "Üle",
      label_13: "Alla",
      yes: "Jah",
      no: "Ei",
      over: "Üle",
      under: "Alla",
      odd: "Paaritu",
      even: "Paaris",
      draw: "Viik",
      no_goal: "Pole väravaid",
    },
    slip: {
      betbuilder: "Betbuilder",
      nonrunner: "Panus ei saa sisaldada puuduvat hobust. Palun eemaldage valik.",
      maxselections: "Panus võib olla maksimaalselt 15 kihlveo.",
      outrightcombi: "Mitmik ei saa hõlmata võitja ja tavapärast panust samast liigast.",
      outrightsystem: "Süsteem ei saa hõlmata võitja ja tavapärast panust samast liigast.",
      maximum: "Maksimaalne väljamakse pileti kohta on 100,000.00",
      new_template: "Uus Kupong-malli",
      delete: "Kustutama",
      select: "Vali",
      active: "Aktiivne",
      copy: "Koopia",
      stake: "Panus",
      slip_empty: "Teie kupong on tühi",
      my_templates: "Minu mallit",
      slip: "Pilet",
      templates_all: "Kõik",
      templates_new: "Uus",
      templates_import: "Import",
      templates_info: "Info",
      single: "Üksik",
      combi: "Mitmik",
      system: "Süsteem",
      post_ticket_place_bet: "Panusta",
      post_ticket_verifying: "Panust kontrollitakse",
    },
    acceptance: {
      same_player_used: "Sama mängijat saab Betmiksreri panuses kasutada ainult üks kord.",
      combo_boost_exist_use_cash: "Boonusrahaga ei ole võimalik mängida combo boost'i. Palun tagastage raha või tehke panus üksik-või topeltpanusena.",
      token_expired: "Teie seanss aegus. Palun logige uuesti sisse.",
      multiple_outright_system_bet: "Bet can not include multiple outrights form the same game",
      maxStake_limit_exceeded: "Maksimipanos ylitetty.",
      internal_error: "Ilmnes sisemine viga, proovige hiljem uuesti.",
      wager_limit_exceeded: "Olete ületanud enda seatud panuste limiidi.",
      loss_limit_exceeded: "Olete ületanud enda seatud kahjulimiidi",
      cash_bet_limit_exceeded: "Maksimaalne panus ületatud.",
      error_bet_risk_1: "Maksimaalne panus on",
      error_bet_risk_2: "antud üritusele. Suurema panuse saamiseks proovige hiljem uuesti.",
      invalid_system_combi: "Invalid system combination. The method has to be larger than 0.",
      error_price_inactive_all: "Eemaldage mittepanustatavad valikud",
      bet_risk: "Praegu me sellele sündmusele panuseid ei aktsepteeri.",
      coupons_per_event: "Maksimaalne kordade arv, mil saate iga sündmust erinevate kupongidega mängida, on %%1%%.",
      event_out_of_date: "See üritus on suletud.",
      event_unique: "This event needs to be unique on the ticket.",
      inactive_game: "See sündmus peab olema piletil ainulaadne.",
      inactive_market: "See turg ei ole praegu kihlvedude jaoks avatud.",
      incorrect_bet_type: "Vale panuse tüüp. Palun tühjendage sedel ja proovige uuesti.",
      insufficient_min_bets: "Ebapiisav arv valitud sündmusi.",
      insufficient_min_combi_type: "See sündmus nõuab minimaalse kombinatsioonitüübi valimist süsteemi libisemisel.",
      invalid_hc: "Händikäp on muutunud.",
      invalid_price: "Koefitsiendid on muutunud",
      limits_exceeded_loss: "Selle panuse tegemine ületab endakehtestatud euro suuruse kahjulimiidi %%1%%",
      limits_exceeded_wager: "Selle panuse tegemine ületab teie enda kehtestatud panuse limiidi %%1%%",
      min_unique_events: "Kupong nõuab vähemalt %%1%% sündmusi.",
      no_language_defined: "Valige keele.",
      no_stake: "Sisestage kehtiv panus.",
      no_user_found: "Panuse tegemiseks logige sisse.",
      not_enough_credit: "Teie saldo on liiga madal. Selle panuse tegemiseks tehke sissemakse.",
      over_max_stake: "Maksimaalne panus, mida saate mängida, on 2000 eur.",
      rule_operator_0: "",
      rule_operator_1: "Võrdne",
      rule_operator_2: "Ei ole võrdne",
      rule_operator_3: "Sisaldama",
      rule_operator_4: "Väiksem kui",
      rule_operator_5: "Maksimaalselt",
      rule_operator_6: "Suurem kui",
      rule_operator_7: "miinimum EUR",
      soap_entication_not_successful: "Panuste tegemiseks peate sisse logima.",
      stake_limits: "Sisestage kehtiv panus vahemikus %%1%% kuni %%2%% %%3%%.",
      ticket_awaiting_approval: "Teie pilet ootab kinnitamist.",
      ticket_bets: "Panuste tegemise meetod teie slipile peab olema ",
      ticket_cooldown: "Olete juba hiljuti pileti sisestanud. Palun oodake veel veidi.",
      ticket_not_valid: "Kehtivaid panuseid ei esitatud",
      ticket_stake: "Teie kupongi panus peab olema",
      ticket_successful: "Panus edukalt tehtud.",
      ticket_uniqueness: "Olete juba paigutanud teised identsed piletid.",
      ticket_win: "Võimalik võit teie libisemisel peab olema",
      total_bets: "We currently do not accept stakes for this event.",
      total_stake: "Praegu me sellel üritusel panuseid vastu ei võta.",
      total_win: "Praegu me sellel üritusel panuseid vastu ei võta.",
      user_disabled: "Praegu ei ole teil lubatud panuseid teha.",
      user_not_active: "Teie konto ei ole hetkel aktiivne.",
      code_already_used: "Token on aegunud. Palun värskendage lehte",
      promo_bet_limit_exceeded: "Maksimaalne boonusrahaga mängitav panus on 25 EUR.",
    },
    tickets: {
      now: "Nüüd",
      placed: "Tehtud",
      win_cashout: "Cashout",
      paid_cashout: "Cashout",
      pending: "Ootel",
      all: "Kõik",
      paid: "Võitis",
      cashedout: "Cashout",
      lost: "Kaotus",
      cancelled: "Tühi",
    },
    stateInfo: {
      safe: "Palli valdamine",
      attack: "Palli valdamine",
      dangerous_attack: "Ohtlik rünnak",
      corner: "Nurgalöök",
      penalty: "Karistuslöök",
      free_kick: "Vaba löök",
      shot: "Löök",
      pole: "Pole",
      goal: "Värav",
      yellow_card: "Kollane kaart",
      red_card: "Punane kaart",
      substitution: "Vahetus",
      var: "Video Assistant Referee",
    },
    theme: {
      bluelight: "Valge",
      bluedark: "Tume",
      purpledark: "Lilla tume",
      reddark: "Punane Tume",
      redlight: "Punane",
      pink: "Roosa",
      light: "Valge",
      dark: "Tume",
      classic: "Klassikaline",
    },
    layout: {
      touch: "Tablet-Mode",
      mouse: "Desktop-Mode",
    },
    mode: {
      standard: "Regulaarne",
      compact: "Kompaktne",
      title: "Vaade",
    },
    oddsFormat: {
      decimal: "Kümnend",
      fractional: "Murdosaline",
      american: "Ameeriklane",
    },
    navView: {
      list: "Nimekirja",
      tab: "Vasakule-Menu",
    },
    marketsLayout: {
      horizontal: "Horisontaalne",
      vertical: "Vertikaalne",
    },
    paymentMethods: {
      sofort: "Sofort",
      skrill: "Skrill",
      paysafecard: "Paysafe Card",
      bankwire: "Panga traat",
    },
    slots: {
      tournament: "Turniir",
      seeall: "Vaata kõiki",
      home: "Kodu",
      casino_back: "Tagasi kasiinosse",
      all: "Kõik",
      livecasino: "Live Kasiino",
      new: "Uued",
      popular: "Populaarne",
      mygames: "Minu Mängud",
      not_logged_in: "Please log first >> ",
      link_login: "LOGI SISSE",
      search: "Otsing",
      provider_all: "KÕIK",
      hotgames: "Kuumad",
      video_slots: "Kõik",
      promotions: "Kampaaniad",
      vip_program: "VIP programm",
      book: "Raamat Spiele",
      megaways: "Megaways",
      irish: "Iirimaa mängud",
      winalot: "Võida palju",
      jackpots: "Jackpotid",
      classics: "Klassika",
      bonusbuy: "Boonusost",
      winoften: "Võida sageli",
      dropsandwins: "Drops-Wins",
    },
    legal: {
      tAndc_click: "Vaata tingimusi",
      rules_click: "Vaata kihlvedude reegleid",
      terms_conditions_link: "Tingimused",
      privacy_policy_link: "Privaatsuspoliitika",
      terms_conditions: "Olen vähemalt 21-aastane ja olen tingimustega tutvunud, nõustun nendega.",
      privacy_policy: "Olen privaatsuspoliitikat lugenud ja sellest aru saanud.",
      welcome_bonus: "Soovin saada tervitusboonust ja sooduspakkumisi e-posti ja SMS-i teel.",
    },
    cookieConsent: {
      title: "See veebisait kasutab küpsiseid",
      content1: "Kasutame küpsiseid sisu ja reklaamide isikupärastamiseks, sotsiaalmeedia funktsioonide pakkumiseks ja liikluse analüüsimiseks.",
      content2:
        "Samuti jagame teavet meie saidi kasutamise kohta oma sotsiaalmeedia-, reklaami- ja analüüsipartneritega, kes võivad seda kombineerida muu teabega, mille olete neile esitanud või mille nad on kogunud teie teenuste kasutamisest.",
      button1: "Valik",
      button2: "Luba kõik küpsised",
      button3: "Luba valik",
      option1: "Vajalik",
      option2: "Eelistused",
      option3: "Statistika",
      option4: "Turundus",
    },
    trustly: {
      complete_deposit: "Täielik sissemakse",
      deposit_back_btn: "Tagasi pealehele",
      deposit_waiting1: "Ootan Trustly makseprotsessi! Palun ärge sulgege lehte! Teid suunatakse sisse",
      deposit_waiting2: "sekundiga.",
      deposit_failed: "Sissemakse ebaõnnestunud",
      deposit_canceled: "Sissemakse tühistatud",
      deposit_rejected: "Sissemakse tagasi lükatud",
      withdraw_rejected: "Väljamakse tagasi lükatud",
      withdraw_canceled: "Väljamakse tühistatud",
      withdraw_failed: "Väljamakse ebaõnnestunud",
      deposit_limit_exceeded: "Sissemakse limiit on ületatud",
      widthdraw_limit_exceeded: "Väljavõtmise limiit on ületatud",
      default_deposit_limit: "Sissemakse limiit on is {{price}}",
      deposit_not_initialized: "Sissemakset pole veel algatatud!",
      deposit_not_completed: "Olete sissemakse alustanud, kuid pole veel lõpetanud. Sissemakse lõpuleviimiseks võite klõpsata alloleval nupul.",
    },
    labels: {
      amount: "Summa",
      bet_id: "Panuse ID",
      empty_screen_message: "Praegu ei ole sündmused saadaval.",
      racing_empty_screen_message: "Traavivõistlused avatakse tööpäeviti umbes kell 16 EET ja laupäeviti enne keskpäeva.",
      combination: "kombinatsioon",
      combinations: "kombinatsioonid",
      spins_left: "Keerutused vasakule",
      expires: "Aegub",
      games: "Mängud",
      receipt_copied: "Kviitungi kopeerimine lõikelauale",
      show_receipt: "Näita kviitungit",
      hide_receipt: "Peida kviitung",
      ticket_charged: "Pilet maksab {{!amount}} EUR",
      reuse_selection: "Taaskasuta valikut",
      copy_receipt: "Kopeerige kviitung",
      matches: "Tikud",
      bet_type: "Panuse tüüp",
      example_searches: "NÄIDISOTSINGUD",
      BadCredentialsException: "Vale salasõna",
      phonenumber_exists: "Telefoninumber on juba olemas",
      token_expired: "Teie seanss aegus. Palun logige uuesti sisse.",
      connection_error: "Vabandust! Midagi läks valesti. Palun proovi hiljem uuesti.",
      token_invalid: "Teie seanss aegus. Palun logige uuesti sisse.",
      quick_pick: "Kiire valik",
      build_your_own: "Sega oma",
      old_odds: "Vanad koefitsiendid",
      new_odds: "Uued koefitsiendid",
      boosted_odds: "Tugevdatud",
      banner_btn: "Panustada",
      daily_slip: "Päevana ",
      daily_slip_double: "topelt",
      daily_slip_treble: "kolmik",
      daily_slip_fold: "kombi",
      add_to_slip: "LISA PANUSESEDELILE",
      tr_bets_exceeded: "Maksimaalne kombinatsioonide arv süsteemis on 512. Palun eemaldage mõned valikud.",
      dead_heat_info: "Koefitsiendid on uuendatud viigiline tulemusi tõttu. Esialgne koefitsient {placed_price}, uuendatud koefitsient {price}.",
      confirm_alert_limits: "Kinnitada piirangud",
      limits_requested_changes: "Te olete taotlenud järgmiste piirangute muutmist",
      confirm_limit_period: "Kinnitage uus limiit {{!amount}} EUR {{!time}} tunni juoksul",
      confirm_limit_period_ended: "Kinnitage uus limiit {{!amount}} EUR",
      abort_limit_change: "Katkesta limiidi muutmine",
      confirm_limit_abort: "Kas olete kindel, et soovite plaanitud limiidi muutmise katkestada? Seda toimingut ei saa tagasi võtta.",
      i_am_sure: "Jah, ma olen kindel",
      remove_limit: "Eemalda piirang",
      confirm_remove_limit: "Kas olete kindel, et soovite piirangu eemaldada?",
      keep_my_limit: "Ei, hoia oma piiri",
      remove_the_limit: "Jah, eemaldage piirang",
      old_price: "Vana hind",
      new_price: "Uus hind",
      price_changed: "Hind muudetud",
      accept_price_change: "Kas nõustute uuendatud hinnaga?",
      new: "UUS",
      betbuilder_info_combi: "Betmikseri ja tavalise panuse kombineerimine ei ole praegu võimalik.",
      betbuilder_headline: "KIHLVEO MIKSER KOONDAB KOKKU MÄNGU PARIMAD PALAD",
      betbuilder_content: "Nubeti uus kihlvedude mikser pakub võimalust kombineerida oma lemmikmängu parimad palad ühte panusesse. Kindlasti lõbus ja võimalus suuremateks võitudeks!",
      betbuilder_button: "Alusta panuste segamist kohe!",
      search_no_result: "Teie otsing ei andnud ühtegi tulemust!",
      block_activated: "Panustamisplokk aktiveeritud",
      limits_updated: "Piirangud ajakohastatud",
      close_account: "Sule konto",
      limit_renewal_period: "Uus limiit {{!amount}} jõustub {{!time}}",
      loss_limits_update: "Uued kaotuslimiidid",
      stake_limits_update: "Uued panuslimiidid",
      deposit_limits_update: "Uued sissemakselimiidid",
      new_limits: "Uued piirangud",
      featured_matches: "Päeva Mäng",
      view_all: "Vaata kõiki",
      load_more: "Laadige rohkem",
      page_not_found: "Lehte ei leitud",
      reality_successful: "Tere tulemast tagasi",
      result: "Tulemus",
      minimum_stake: "Minimaalne panus on 1 EUR",
      limit: "Piiravad",
      register_infotext: "Registreerimise lõpuleviimiseks täitke palun allpool olev vorm. Ärge unustage lõpus vajutada nuppu 'Registreeri'.",
      min_value_10: "Minimaalne väärtus 10€",
      max_value_100000: "Maksimaalne väärtus 100000€",
      limits_infotext:
        "Hasartmängud on põnevad. Veenduge, et hoiate seda kontrolli all. Nubetis on teil võimalus, kui soovite oma hasartmängukäitumise kontrollimiseks piiranguid seada. Pange tähele, et kaotulimiidid on need ühised kõikidele Vana Lauri veebisaidid.",
      notifications_infotext: "Teil on võimalik saada teateid toodete, boonuste ja kampaaniate kohta. Valige, kuidas soovite neid saada:",
      info_limits: "Kui soovite määrata oma isiklikud mängupiirangud, saate seda teha seadetes (link seadetele) või määrata need hiljem.",
      back_to_home: "Tagasi koju",
      maximum_withdraw: "Minimaalne väljamakse 25€. Maksimaalne väljavõtmine 5000€",
      minimum_amount: "Minimaalne sissemakse 10€. Maksimaalne sissemakse 5000€.",
      wrong_birthdate: "Sisestasite vale sünnikuupäeva.",
      renewaltime_1: "Limiidi kehtestamisel kuvatakse uuendamise aeg.",
      renewaltime_2: "Uuendamisaeg:",
      outdated_1: "Tähelepanu!",
      outdated_2: "Teie brauser on vananenud. Palun uuendage oma brauserit, et jätkata.",
      limits_saved: "Salvestatud piirid",
      nonerunner_info: "Koefitsiendid on uuendatud hobuste mitteosalemise ja/või Viigiline tulemusi tõttu. Esialgne koefitsient {placed_price}, uuendatud koefitsient {price}.",
      see_rules: "Lisainformatsiooni saamiseks vaata reegleid.",
      customer_support: "Klienditeenindus",
      attention_gambling: "Tähelepanu! Tegemist on hasartmängu reklaamiga. Hasartmäng pole sobiv viis rahaliste probleemide lahendamiseks. Tutvuge reeglitega ja käituge vastutustundlikult!",
      slip_banner_1: "Traavivõistlused",
      slip_banner_2: "@Nubet",
      deposit_succes: "Sissemakse õnnestus",
      deposit_fail: "Sissemakse ebaõnnestus",
      reset_pass_text: "Valige ja kinnitage uus salasõna.",
      reset_pass: "Uus salasõna",
      withdraw_trustly_1: "Teha ",
      withdraw_trustly_2: " kiire ",
      withdraw_trustly_3: " väljavõtmine ühes Trustly.",
      deposit_trustly_1: "Tee ",
      deposit_trustly_2: " kiire ",
      deposit_trustly_3: " sissemakse läbi Trustly.",
      login_trustly_1: "või kasuta ",
      login_trustly_2: "panga sisselogimist ",
      login_trustly_3: "sissemakse hinna sees",
      register_trustly_1: "Maksa ja registreeru ",
      register_trustly_2: "ühe klikiga ",
      register_trustly_3: "läbi ",
      register_trustly_4: "Trustly",
      register_trustly_5: " või vanaviisi",
      register_trustly_6: "Jätkates nõustute tingimustega",
      login_banklogin: "Logi panka sisse > (Tee sissemakse)",
      user_register: "Registreeri",
      register_new: "Registeeri",
      register_old: "Standardne registreerimine",
      user_wrong_password: "Vale salasõna.",
      user_frozen: "Konto külmutatakse pärast 3 ebaõnnestunud sisselogimiskatset. Palun võtke ühendust klienditoega.",
      username_not_found: "email kasutajanimi.",
      username_self_exclusion: "Kasutaja lukustatud enesekontrolli tõttu.",
      contact_message: "Palun jätke siia sõnum.",
      send: "Saada",
      no_open_tickets: "Teil pole hetkel avatud pileteid.",
      cashout_not_successful: "Cashout ei õnnestunud ",
      download_app: "Lae alla App",
      no_odds: "Selle matši jaoks ei ole hetkel koefitsiente saadaval.",
      sample_id1: "Siin saate üles laadida oma ID-kaardi või passi mõlemad pooled ",
      sample_id2: "(pange tähele, et kõik 4 nurka peavad olema äratuntavad)",
      sample_id3: "Maksimaalne faili suurus ei tohi ületada 4 MB",
      sample_id4: "Dokumendi näidis:",
      sample_address1: "Siin saate oma aadressitõendi üles laadida",
      sample_address2: "(mitte vanem kui 3 kuud, ekraanipilti pole)",
      sample_address3: "Maksimaalne faili suurus ei tohi ületada 4 MB",
      sample_address4: "Dokumendi näidis:",
      sample_deposit1: "Siin saate oma pangaväljavõtte üles laadida ",
      sample_deposit2: "(IBAN või Skrill e-post peab olema äratuntav, hiljem redigeeritud dokumente ei tohi teha)",
      sample_deposit3: "Maksimaalne faili suurus ei tohi ületada 4 MB",
      sample_deposit4: "Dokumendi näidis:",
      all: "Kõik",
      search: "Otsing",
      panic_button: "24h Enesevälistus",
      slots_play: "Mängi nüüd",
      slots_test: "Harjutada",
      slots_close: "Sulge",
      settings_change_pw: "Muuda salasõna",
      settings_limits: "Piirid",
      settings_blocks: "Blokeeri konto",
      settings_notes: "Märguanded",
      stmt_tickets: "Tehingud",
      stmt_slots: "Kasiinomängud",
      stmt_payin: "Sissemaksed",
      stmt_payout: "Väljamaksed",
      stmt_24: "24h",
      stmt_7: "7 päevadel",
      stmt_30: "30 päevadel",
      stmt_other: "Muud",
      stmt_btn: "Näita",
      redirect_title: "For our players residing outside of Germany",
      redirect_1: "Exclusive betting fun is now live under the German license on our domain.de website.",
      redirect_2: "Please log yourself into your account with your usual username and password, however on domain.com and not domain.de.",
      redirect_btn: "Jätkake saidiga nubet.com",
      stay_loggedin: "Jää sisselogituks",
      accept_oddchanges: "Võta koefitsientide muudatused automaatselt",
      slip_login: " Logi sisse või registreeru",
      slip_stake: "Panus",
      slip_bet: " panus",
      slip_bets: " panust",
      slip_win_chance: "Võit",
      avatar_now_live: "Nüüd Live",
      avatar_all: "Kõik",
      group_events: "Group events",
      ticket_stake: "Panus:",
      ticket_win_chance: "Võit:",
      ticket_show: "Näita",
      ticket_copy: "Kopeeri",
      cashout_confirm: "Kinnita",
      cashout_successful: "Cashout õnnestatud",
      cashout_sec: "seks",
      cashout_no: "Cashout ei ole",
      cashout_in: "Cashout sisse",
      kyc_upload: "Üleslaadimiseks klõpsake või lohistage pildifail sellele alale.",
      kyc_id: "Isikutunnistus",
      kyc_address: "Aadressi tõend",
      kyc_deposit: "Panga väljavõte",
      news: "Uudised",
      slot_rules: "Kasiino reeglid",
      bonus_terms_title_slots: "Boonusreeglid kasiino",
      whithdraw_verify_account_1: "Enne väljamakse tegemist tuleb teie mängijakonto kinnitada. Palun laadige nõutud dokumendid alla oma mängijaprofiilile ",
      whithdraw_verify_account_2: " või saatke need posti teel aadressile: ",
      payment_bonus: "Eksklusiivsed boonuspakkumised! Palun valige oma boonus (",
      payment_bonus_discard: "Ärge kasutage boonust",
      payment_bonus_code: "või sisestage boonuskood: ",
      payment_method: "meetod (EUR)",
      auto_logout_1: "Olete sisse logitud alates ",
      auto_logout_2: " minutit. Teie tegevus viimasel ajal ",
      auto_logout_3: " minutit:",
      auto_logout_4: "Teid logitakse automaatselt välja ",
      auto_logout_5: " sekundit.",
      auto_logout_stake: "Panus",
      auto_logout_win: "Võida",
      auto_logout_profit: "Kasum/Kaotus",
      auto_logout_statement: "Oma mänguajalugu saate vaadata oma konto väljavõtetes",
      birth_name: "Sünninimi",
      back: "Tagasi",
      next: "Edasi",
      register_detail_data: "Üksikasjad",
      register_person_data: "Isik",
      register_user_data: "Kasutaja andmed",
      register_title: "Pealkiri",
      privacy_pol_link: "Näita privaatsuspoliitika",
      account_payment: "Sissemakse/väljamakse",
      account_login: "Logi sisse",
      no_favorite: "Te pole veel ühtegi lemmikut valinud",
      selected_currency: "EUR",
      min: "Min.",
      max: "Max.",
      attention: "Tähelepanu",
      error: "Viga",
      please_login_in: "Palun logi sisse",
      show_tickets: "Minu piletid",
      hide_tickets: "Pilet",
      contact_data: "Kontaktandmed",
      gender: "Sugu",
      username: "Kasutajanimi",
      wrong_username_or_password: "Vale kasutajanimi või salasõna.",
      WRONG_CREDENTIALS: "Vale kasutajanimi või salasõna.",
      CREDENTIALS_VALID_ON_OTHER_DOMAIN: "For our players residing in Germany. Please log yourself into your account with your usual username and password, however on domain.com and not domain.de",
      identity_verification: "IDENTITEEDI KONTROLLIMINE",
      AutoIdentityPending: "Teie konto identiteedi kinnitamine on ootel.",
      AutoIdentityNotCompletedLink: " Automaatne tuvastamine pole veel lõppenud. Lõpetamiseks klõpsake <a href='%{link}' id='auto-identification'>siin</a>",
      AutoIdentityNotCompletedText: " Automaatne tuvastamine pole veel lõppenud. Lõpetamiseks klõpsake nuppu Edasi.",
      auto_identification: "Automaatne identifitseerimine",
      missing_username: "Kasutajanimi on nõutav",
      missing_password: "Salasõna on nõutav",
      missing_confirm_pass: "Kinnita salasõna on nõutav",
      missing_email: "E-post on nõutav",
      missing_confirm_email: "Kinnita e-post on nõutav",
      missing_gender: "Sugu on nõutav ",
      missing_first_name: "Eesnimi on nõutav",
      missing_last_name: "Perekonnanimi on nõutav",
      missing_: "Sünnikuupäev on nõutav",
      missing_address: "Aadress on nõutav",
      missing_postal_code: "Postiindeks on nõutav",
      missing_city: "Linn on nõutav",
      missing_country: "Riik on nõutav",
      missing_birthName: "Sünninimi on nõutav",
      missing_birthCity: "Sünnilinn on nõutav",
      login: "Logi sisse",
      register: "Avada konto",
      period: "Periood",
      account_block: "Saate oma panustamiskonto teatud perioodiks blokeerida. Teie ennustuskonto aktiveeritakse perioodi lõpus uuesti automaatselt.",
      deposit_title: "Sissemakse",
      deposit_now: "Tee Sissemakse",
      deposit_terms_1: "Jätkates nõustud ",
      deposit_terms_2: "tingimustega ",
      withdraw_title: "Väljamakse",
      acc_stmt_title: "KONTOVÄLJAVÕTTE",
      profile_title: "PROFIIL",
      messages_title: "SÕNUMID",
      setting_title: "Seaded",
      retail_create_account: "Loo poe konto",
      retail_show_card: "Kauplus-Liikmekaart",
      statement_title: "Väljavõte",
      all_title: "Kõik",
      tickets_title: "Piletid",
      payment_title: "Maksed",
      from_title: "Alates",
      to_title: "Kuni",
      logout: "Logi välja",
      p_acc_title: "Teil pole konto väljavõtet",
      p_acc_title1: "Teil pole konto väljavõtet 1",
      p_acc_title2: "Teil pole konto väljavõtet 3",
      salu_title: "Tervitus",
      form_fname: "Eesnimi",
      form_lname: "Perekonnanimi",
      form_: "Sünnikuupäev",
      form_pob: "Sünnikoht",
      select_data: "VALI ANDMED",
      proof_id: "Isikutunnistus",
      proof_add: "Aadressi tõend",
      deposit_slip: "Panga väljavõte",
      heading: "Palun laadige oma dokument üles",
      upload_data: "Palun laadige oma andmed üles",
      btn_upload: "Faili üleslaadimine",
      upload_data1: "Siin saate oma aadressitõendi üles laadida",
      upload_data2: "(mitte vanem kui 3 kuud, ekraanipilti pole)",
      upload_data3: "Maksimaalne faili suurus ei tohi ületada 4 MB",
      overview: "Ülevaade",
      my_data: "Minu andmed",
      document: "Dokumendid",
      time: "12.02.2018, 09:00",
      test: "test",
      female: "Naine",
      male: "Mees",
      street: "Aadress",
      street2: "Aadress (jätkub)",
      zip: "Postiindeks",
      current_zip: "Praegune postiindeks",
      city: "Linn",
      email: "E-post",
      mobile: "Telefon/Mobile",
      country: "Riik",
      nationality: "Rahvus",
      cob: "Sünniriik",
      cib: "Sünnilinn",
      message: "Sõnum",
      date: " Kuupäev/Kellaaeg ",
      booking: "Hoiatused",
      method: "Meetod",
      betslipnr: "Kupongi number",
      message_text: "Teil pole sõnumeid.",
      limits: "Piirid",
      betting_limits: "Panuste limiidid",
      deposit_limits: "Hoiuste limiidid",
      limits_stake: "Panus",
      limits_loss: "Kaotus",
      limits_deposit: "Sissemakse",
      limits_max_bet: "Max panus",
      limits_max_bet_text_1: "MINU MAX PANUSE PIIRID",
      limits_max_bet_text_2: "Te ei saa neid piire ise muuta. Lisateabe saamiseks võtke ühendust meie toega.",
      limits_max_bet_text_3: "Max sularaha panus summa",
      limits_max_bet_text_4: "Max promo panus summa",
      limits_max_promo_bet: "Max promo panus",
      limits_max_promo_bet_text_1: "Minu Max Promo panuse limiit",
      limits_deposit_text_1: "SISSEMAKSE LIMIIT",
      limits_deposit_text_2: "Hoiuse limiit on maksimaalne summa, mida klient saab päevas, nädalas või kuus hoiustada.",
      limits_deposit_text_3: "Iga hoiustamislimiidi vähendamine eelnevalt kehtestatud limiidist jõustub kohe. Hoiustamislimiidi suurendamine jõustub 48 tundi pärast eraldi kinnitust.",
      limits_deposit_text_4: "Maksimaalse limiidi vähendamine jõustub kohe.",
      limits_loss_text_1: " KAOTUSLIMIIT",
      limits_loss_text_2: "Kaotuslimiit on maksimaalne summa, mille klient võib kaotada ühe päeva, nädala või kuu jooksul.",
      limits_loss_text_3: "Kaotuse limiidi vähendamine eelnevalt kehtestatud limiidist jõustub kohe. Kahjulimiidi suurendamine jõustub 48 tundi pärast eraldi kinnitust.",
      limits_loss_text_4: "Maksimaalse limiidi vähendamine jõustub kohe.",
      limits_wager_text_1: " PANUSLIMIIT",
      limits_wager_text_2: "Panustamislimiit on maksimaalne summa, mida klient võib ühe päeva, nädala või kuu jooksul mängida.",
      limits_wager_text_3: "Iga panustamislimiidi vähendamine eelnevalt kehtestatud limiidist jõustub kohe. Limiidi suurendamine jõustub 48 tundi pärast eraldi kinnitust.",
      limits_wager_text_4: "{max} per {Time} on aktiivne. KUI PIIRANGU POLE, PEIDA SEE TÕLGE",
      limits_wager_text_5: "Panus max",
      limits_wager_text_6: "Aegumistähtaeg",
      limits_wager_text_7: "päeva kohta",
      limits_wager_text_8: " nädalas",
      limits_wager_text_9: "kuus",
      limits_wager_text_10: "Kui eelmine limiit on aegunud, määrake sama limiit uuesti.",
      limits_stake_common_vl: " ",
      limits_loss_common_vl: "Pange tähele, et kahjulimiidid on Vana Lauri OÜ erinevate kaubamärkide (Nubet, Lataamo ja Jokeri) puhul ühised.",
      limits_deposit_common_vl: " ",
      limits_time_day: "Päevane max",
      limits_time_week: "Nädala max",
      limits_time_month: "Kuu max",
      limits_btn_save: "Salvesta andmed",
      limits_btn_change: "Muuda andmeid",
      limits_logout: "Logige automaatselt välja pärast:",
      block_acct: "Blokeeri konto",
      block_acct_2: "Konto blokeerimine",
      block_acct_text: "Tekst",
      block_acct_deactive: "Deaktiveerimise põhjus",
      block_acct_demo: "Teie sõnum meile",
      block_acct_btn: "Blokeeri",
      user: "Kasutaja",
      user_post: "Postituse",
      user_email: "Epost",
      user_sms: "SMS",
      user_all: "Kõik",
      user_phone: "Telefon",
      user_eng: "Inglise",
      user_deut: "Saksa keel",
      user_accept: "Nõustuge koefitsientide muutmisega",
      change_pass: "Muuda salasõna",
      current_pass: "Praegune salasõna",
      new_pass: "Uus salasõna",
      confirm_pass: " Kinnita uus salasõna",
      change_btn: "Muuda salasõna",
      retail_id: "Kaupluse kood",
      head_text1: "Sport",
      head_text2: "Live",
      head_text3: " Võitjapanus",
      head_text4: "Analüüsid",
      head_text5: "Kasiino",
      head_text6: "Live Kasiino",
      head_text7: "Esports",
      choose_bnk: "Valige pangakonto:",
      password: "Salasõna",
      deposit_cancel: "Tühista",
      ortype: "või sisestage:",
      confirm_mail: "Kinnita e-post",
      register_text1: "Olen riigis, kus mu elukoht on seaduslikult täisealine. Olen lugenud ja nõustun ",
      register_text2: "Tingimused",
      register_text3: "ja",
      register_text31: " Privaatsuspoliitika",
      register_text4: "ja nõustun oma isikuandmete kasutamisega teavitamise ja reklaamimise eesmärgil. *",
      register_text5: "Registreeri",
      register_text6: "* Kohustuslikud väljad",
      forget_pass: "Unustasid salasõna",
      forget_text: "Palun täitke järgmine vorm. Seejärel saadame teile konto taastamiseks meili.",
      forget_text1: "E-posti aadress või kasutajanimi",
      forget_send: "Saada",
      deposit_choose: "Valige max",
      deposit_transf: "Kohene ülekanne",
      postal: "Postiindeks",
      postal_acc: "Konto andmed ",
      withdraw_choose: "Valige max:",
      withdraw_text1:
        " Valige siit väljamaksetaotluse jaoks väljamakseviis. Rahapesu ja pettuste tõkestamiseks saab väljamaksemeetodit kasutada vaid juhul, kui seda meetodit kasutati sissemakse tegemiseks eelneva 12 kuu jooksul. Erand tehakse juhul, kui makseteenuse pakkujal pole väljamaksevõimalust.",
      withdraw_text2: " Kolme väljamakse töötlemine kalendrinädalas on tasuta. Kui taotlete kalendrinädalas rohkem kui kolm väljamakset, arvestab Nubet väljamakse maksimumsummast maha töötlemistasu 5 €.",
      withdraw_text3: "Nubeti töötaja kontrollib iga väljamaksetaotlust ja edastab need käsitsi.",
      withdraw_text4: "Automaatseid ülekandeid pole.",
      withdraw_bnk_text1: "Väljamakse peab olema vähemalt 25----TRANSLATE_CURRENCY-- ",
      withdraw_bnk_text2: "Pangaülekanded korraldatakse SEPA ülekannetena. Ülekandeprotsess võib kesta 3-5 pangatööpäeva.",
      withdraw_bnk_text3: " Pange tähele, et teie pank võib võtta välismaksete eest tasu.",
      withdraw_bnk_text4: " Maksimaalne väljamakse taotluse kohta on 100 000 --TRANSLATE_CURRENCY-- ",
      btn_withdraw: "Väljamakse",
      information_title: "Informatsioon",
      security_title: "Turvalisus",
      company_title: "Ettevõte",
      pay_opt: "Maksevõimalused",
      term_condi: "Tingimused",
      privacy_pol: "Privaatsuspoliitika",
      help: "Abi",
      resp_game: "Vastutustundlik mängimine",
      game_care: "Mänguhooldus",
      legal_comp: "Juriidiline pädevus",
      data_sec: "Andmeturve",
      about: "Meist",
      contact: "Võta meiega ühendust",
      imprint: "Imprint",
      help_prof: "Minu profiil",
      bett_type: "Panuste tüübid",
      bett_single: "Üksik",
      bett_comb: "Mitmik ",
      bett_comb_row: "Süsteem (Vaba)",
      bett_sys: "Süsteem",
      bett_row: "Rida",
      bett_of: "",
      bett_bets: "Panused",
      bett_bank: "Pank",
      bett_odd: "Koefitsiendid",
      bett_min: "Min",
      bett_max: "Max",
      bett_maxstake: "Maksimaalne panus",
      bett_possiwin: "Võit",
      bett_maxwin: "Max võit",
      bett_minstake: "Minimaalne panus",
      bett_numb: "Panuste arv",
      bett_fee: "Tasu",
      bett_oddchange: "Aktsepteerige koefitsientide muudatusi",
      cancle_text5: "Panussedelite hindamine ja arveldamine toimub pärast seda, kui kõigi kihlveosedel olevate sündmuste ametlikud tulemused on kontrollitud ja süsteemi lisatud.",
      forget1: "Unustasite kasutajanime või salasõna?",
      forget2: "Pole veel kontot?",
      withdraw_owner: "Omanik",
      withdraw_esta: "panga asutamine",
      withdraw_save_bnk: "Salvestage pangakonto",
      withdraw_iban: "IBAN",
      withdraw_swift: "SWIFT-Code / BIC",
      state_24: "24h",
      state_7day: "7 päevadel",
      state_30day: "30 päevadel",
      state_timer: "Ajavahemik ",
      state_betslip: "panusekupongi vabastamine",
      btn_save: "Salvesta",
      please_select: "Palun vali",
      setting_not_interested: "Mind ei huvita enam panustamine/mängimine",
      setting_not_satisfied: "Ma ei ole Nubetiga rahul",
      setting_compulsive_prevention: "Soovin oma konto sulgeda, et vältida hasartmängusõltuvust",
      hours: "Tund(a)",
      days: "Päev(ad)",
      weeks: "Week(s)",
      months: "Nädal(id)",
      no_limit: "pole limiiti",
      never: "mitte kunagi",
      permanently: "Püsivalt",
      DD: "DD",
      MM: "MM",
      YYYY: "YYYY",
      min18Y: "Registreerimiseks pead olema vähemalt 21-aastane",
      field_required: "On vajalik",
      invalid_date: "Kehtetu kuupäev",
      invalid_phone: "Kehtetu telefoninumber.",
      invalid_country: "Valige riik",
      invalid_email: "Vale emaili aadress",
      invalid_email_confirm: "Meiliaadressid ei ühti",
      invalid_screen: "Peab koosnema 6-12 tähest ja numbrist ning algama tähega.",
      invalid_user_name: "Vähemalt 6 tähemärki. Kas tähed või numbrid.",
      invalid_pass: "Salasõna peab olema vähemalt 8 tähemärki pikk.",
      invalid_pass_confirm: "Paroolid ei ühti",
      invalid_recaptcha: "Täitke ReCAPTCHA",
      invalid_tos_privacy: "Nõustuge TOS-i ja privaatsuspoliitikaga",
      invalid_zip: "Vale postiindeks",
      email_exists: "Meiliaadress on juba olemas",
      user_exists: "Kasutajanimi on juba olemas",
      user_registered: "Kasutaja on juba registreerunud",
      invalid_fname: "Kehtetu eesnimi",
      invalid_lname: "Kehtetu perekonnanimi",
      register_general: "Siseserveri viga, proovige uuesti",
      continue: "Jätka",
      user_unavailable: "Näib, et olete juba registreeritud klient. Kui te pole veel registreerunud, võtke ühendust klienditeenindusega",
      email_not_confirmed: "E-posti aadress pole kinnitatud. Kinnitusmeili uuesti saatmiseks klõpsake siin.",
      email_already_confirmed: "Meiliaadress juba kinnitatud",
      confirmation_email_sent: "Kinnitusmeil on saadetud, kontrollige oma postkasti",
      invalid_confirmation_code: "Vigane kinnituskood",
      account_confirmed: "Konto kinnitatud, saate nüüd sisse logida",
      account_confirmed_auto_identification: "Konto kinnitatud, täitke automaatne tuvastamine.",
      account_created: "Täname registreerumast! Teie konto on loodud ja teie postkasti on saadetud aktiveerimismeil.",
      invalid_login: " Vale kasutajanimi või parool. Unustasid salasõna?",
      invalid_login_empty: "Palun sisesta kasutajanimi ja salasõna",
      select_a_country: "Valige riik&hellip;",
      new_livebetting: "Live",
      new_sportsbetting: "Spordiennustused",
      new_outrights: " Võitjapanused",
      new_analyses: "Analüüsid",
      new_max_odd: "Max koefitsiendid",
      new_empty_slip: "Tühi kupong",
      new_show_all_bets: "Näita kõiki panuseid",
      new_my_bets: "Minu panused",
      new_my_bets_pending: "Ootel",
      new_my_bets_won: "Võitis",
      new_my_bets_cashout: "Cashout",
      new_my_bets_lost: "Kadunud",
      new_my_bets_voided: " Tühistatud",
      new_my_bets_all: "Kõik",
      new_my_bets_status: "Olek",
      new_show: "Näita",
      new_stake_per_bet: "Panus panuse kohta",
      new_stake_total: "Kogupanus",
      new_stake: "Panus",
      new_stake_max: "Max",
      new_winning_chance: "Maksimaalne võit",
      error_price_inactive_all: "Eemaldage mittepanustavad turud",
      place_ticket_pending: "Panus on kinnitatud",
      place_ticket_success: "Panus tehtud",
      new_pending_coupons: "Ootel kupongid",
      new_won_coupons: "Võitis kupongid",
      new_search: "Otsing",
      new_search_term: "Sisesta otsingutermin...",
      new_search_remove: "Lähtesta otsing",
      new_now_live: "Nüüd LIVE",
      new_offered_live: "Reaalajas pakkumised",
      new_select_all: "Vali kõik",
      new_new_total: "Kokku",
      new_please_login_in: "Palun logige sisse",
      betting_rules: "Panustamise reeglid",
      profile_updated: "Profiili värskendati",
      user_not_found: "E-posti aadressi ei leitud",
      forgot_email_sent: "Meil on saadetud, kontrollige oma postkasti",
      password_changed: "Salasõna muudetud, jätkake sisselogimist ",
      change_password: "Muuda salasõna",
      change_password_confirm: "Konto taastamiseks peate oma salasõna muutma. Palun sisestage allpool uus salasõna.",
      change: "Muuda",
      page_not_found_title: "Lehte ei leitud",
      page_not_found: "Teie soovitud lehte ei leitud.",
      partners: "Partnerid",
      invalid_max: "Kehtetu max",
      operation_successful: "Operatsioon õnnestus",
      operation_unsuccessful: " Operatsioon ebaõnnestus",
      operation_type_success: "Operatsioon õnnestus",
      operation_type_fail: " Operatsioon ebaõnnestus",
      operation_type_cancel: " Operatsioon tühistati",
      incorrect_password: "Vale salasõna",
      upload_error_type: "Laadige üles ainult PNG- või JPG-kujutised.",
      upload_error_size: "Maksimaalne pildi suurus on 2 MB.",
      upload_select: "Valige fail&hellip;",
      kyc_vrfn_verified: "Dokument kinnitatud",
      kyc_vrfn_failed: "Dokumendi ülevaatus ebaõnnestus. Palun laadige üles uus dokument.",
      kyc_vrfn_init: "Teie dokumenti vaadatakse üle",
      kyc_upload_date: "Viimane dokument üles laaditud ",
      bonus_title: "Boonused",
      deposits_title: "Sissemakse",
      withdraws_title: "Väljamakse",
      other: "Muud",
      date_precedence: " Kuupäev Alates peab olema enne kuupäeva Kuni.",
      statement_header_ts: "Kuupäev/Kellaaeg",
      statement_header_amount: "Summa",
      statement_header_max: "max",
      statement_header_trtype: "Olek",
      statement_header_prevbal: "Eelmine saldo",
      statement_no_entries: "Kuvatavaid väljavõtte kirjeid pole",
      statement_header_provider: "Pakkuja",
      statement_header_deposits_status: "Sissemaks olek",
      statement_header_withdraws_status: "Väljamaks olek",
      statement_header_stake: "Panus",
      statement_header_win: "Võida",
      statement_header_ticket_id: "Pilet/mäng",
      statement_header_game: "Mäng",
      statement_header_balance: "Saldo",
      pending: "Ootel",
      transaction_status_txn_confirmed_success: "Edu",
      transaction_status_txn_confirmed_failure: "Ebaõnnestunud",
      transaction_status_txn_initiated: "Algatatud",
      transaction_status_co_initiated: "Algatatud",
      transaction_status_co_reversed: "Tagurpidi",
      transaction_status_co_success: "Edu",
      transaction_status_sb_buyin: "Panus",
      transaction_status_sb_win: "Võida",
      transaction_status_sb_leave_table: "Võida",
      transaction_success: "Tehing õnnestus. Nüüd saate selle hüpikakna sulgeda",
      transaction_success_short: "Tehing õnnestus",
      transaction_fail: "Tehing ebaõnnestus. Palun proovi uuesti",
      transaction_fail_short: "Tehing ebaõnnestus",
      transaction_declined: "Tehing lükati tagasi",
      withdraw_initiated_success: "Väljavõtmine edukas",
      withdraw_initiated_fail: "Toiming ebaõnnestus",
      withdraw_to: "Loobumine",
      withdraw_name_on_bank: "Nimi pangakontol",
      invalid_iban: "Kehtetu IBAN",
      invalid_swift: "Vale SWIFT-kood",
      swift_iban_mismatch: "SWIFTi ja IBANi riigid ei ühti",
      limits_value: "Väärtus",
      daily: "Igapäevane",
      weekly: "Iganädalane",
      monthly: "Igakuine",
      limits_current: "Praegune: EUR ",
      active_limits: "Aktiivsed limiidid",
      pending_limits: " Ootel limiidid",
      limits_delete: "Kustuta",
      limits_confirm: "Kinnita",
      limits_cancel: "Tühista",
      limits_remaining_time: "Järelejäänud aeg",
      limits_note: "Limiite saab värskendada ainult siis, kui sellel ajavahemikul pole ootel limiite",
      days_singular: "päeval",
      days_plural: "päevadel",
      invalid_reason: "Vale põhjus",
      invalid_period: "Vale perioodi",
      block_confirmation: "Kas olete kindel, et soovite oma konto blokeerida ja välja logida",
      rg_account_blocked: "Konto blokeeritud",
      rg_temp_block: "Ajutine blokk",
      rg_perm_block: "Püsiv blokk",
      more: "Rohkem",
      message_sent: "Sõnum saadetud",
      message_sent_fail: "Toiming ebaõnnestus. Palun provi hiljem uuesti.",
      captcha_validation_failed: "Valideerimine ebaõnnestus",
      total_odds: "Koefitsiendid",
      round_qualification: "Kvalifikatsioon",
      round_round_of_16: "Round of 16",
      round_quarterfinal: "Veerandfinaal",
      round_semifinal: "Poolfinaal",
      round_final: "Finaal",
      round_3rd_place_final: "3-4 playoff",
      round_5th_place_final: "5-6 playoff",
      round_7th_place_final: "7-8 playoff",
      round_3rd_place_playoff: "3-4 playoff",
      round_5th_place_playoff: "5-6 playoff",
      round_7th_place_playoff: "7-8 playoff",
      explainer_video: "Quickstart-Guide",
      choose_several_leagues: "Valige mitu liigat",
      js_required: "Nubet vajab töötamiseks Javascripti!",
      security_question: "Turvaküsimus",
      invalid_answer: "Vale vastus",
      ref_code: "Kood",
      invalid_ref_code: "Vale kood",
      correct_answers: "Märkige punasega esiletõstetud ruudud",
      button_decline: "TAGASILÜKKAMISE",
      button_accept: "Nõustun",
      accept_cookie: "Kasutame teie kasutuskogemuse optimeerimiseks küpsiseid. Meie veebisaiti sirvides nõustute küpsiste kasutamisega. ",
      affiliate_program: "Sidusprogramm",
      help_balance: "Saldo",
      help_withdrawable: "Väljavõetav",
      help_restricted: "Boonuse saldo",
      help_distance: "Läbi mängida (summa)",
      login_cooldown: "Liiga palju ebaõnnestunud sisselogimiskatseid. Palun proovi hiljem uuesti.",
      cooldown: "Liiga palju ebaõnnestunud sisselogimiskatseid. Palun proovi hiljem uuesti.",
      depositing_disabled: "Raha deponeerimine on teie kontol ajutiselt keelatud. Võtke ühendust klienditoega.",
      withdrawing_disabled: "Raha väljavõtmine on teie kontol ajutiselt keelatud. Võtke ühendust klienditoega.",
      forced_system: "Teie praeguseid valikuid saab kombineerida ainult süsteemipiletiga.",
      notifications: "Märguanded",
      notifications_text: "Teavitame teid regulaarselt isikupärastatud Nubet kampaaniatest, boonustest ja pakkumistest. Valige, kuidas soovite neid kätte saada:",
      profile_update: "Profiili värskendus",
      profile_update_notice: "Jätkamiseks täitke järgmised täiendavad väljad. Pange tähele, et neid välju saab värskendada ainult üks kord, seega veenduge, et salvestate õiged andmed.",
      bonus_terms_title: "Boonuse tingimused",
      conference_start_title: "Konverents algab aastal",
      welcome: "Tere tulemast",
      maintenance: "Hooldus",
      try_again_later: "Palun proovi hiljem uuesti",
    },
  },
};

export default translations;
