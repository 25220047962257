/**
 * Component for showing confirmation messages
 * Type : email confirmation, account confirmation, email confirmation error, email already confirmed, auto identification message and link
 */

import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import withStyles from "@mui/styles/withStyles";
import { confirmEmail } from "../../actions";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Button from "@mui/material/Button";

const styles = (theme) => ({
  container: {
    padding: 20,
    minHeight: 300,
    backgroundColor: "var(--color-main)",
  },
  centerWrapper: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    fontSize: "1rem",
    textAlign: "center",
  },
});

const parseQueryString = function (str) {
  let objURL = {};

  str.replace(
    new RegExp("([^?=&]+)(=([^&]*))?", "g"),
    function ($0, $1, $2, $3) {
      objURL[$1] = $3;
    }
  );
  return objURL;
};

class EmailConfirm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
    };
  }

  UNSAFE_componentWillMount() {
    /**
     * get userid and code from link and run confirm check API.
     */

    const searchString = this.props.location.search;
    const params = parseQueryString(searchString);

    if (params.userid && params.code) {
      this.setState({ email: params.userid });
      this.props.confirmEmail(
        this.constructor.name,
        params.userid,
        params.code
      );
    }
  }

  render() {
    log(this.constructor.name, "render");
    const { classes, confirm } = this.props;

    return (
      <div className={classes.container}>
        {confirm.autoIdentUrl ? (
          <div>
            <div className={classes.centerWrapper}>
              <Translate value="labels.account_confirmed_auto_identification" />
            </div>

            <div className={classes.centerWrapper}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => window.open(confirm.autoIdentUrl, "_blank")}
              >
                <Translate value="labels.auto_identification" />
              </Button>
            </div>
          </div>
        ) : confirm?.success ? (
          <div className={classes.centerWrapper}>
            <span>{confirm?.message}</span>
          </div>
        ) : confirm?.error === "AlreadyVerifiedException" ? (
          <div className={classes.centerWrapper}>
            <Translate value="labels.email_already_confirmed" />
          </div>
        ) : confirm?.error === "NoAvailableSessionException" ? (
          <div className={classes.centerWrapper}>
            <Translate
              value="labels.invalid_confirmation_code"
              dangerousHTML
              link={`/resend?email=${this.state.email}`}
            />
          </div>
        ) : confirm.general === "invalid_confirmation_code" ? (
          <div className={classes.centerWrapper}>
            <Translate value="labels.invalid_confirmation_code" />
          </div>
        ) : null}
        <div className={classes.centerWrapper}>
          <Button
            component="a"
            href="/"
            startIcon={<KeyboardBackspaceIcon />}
            variant="outlined"
          >
            <Translate value="labels.back_to_home" />
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.user.auth,
    locale: state.i18n.locale,
    confirm: state.confirm,
  };
};

export default connect(mapStateToProps, { confirmEmail })(
  withStyles(styles)(EmailConfirm)
);
