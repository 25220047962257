/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconTE = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g><path d="M373.7,1876.3l335.3-383.5l0.9,1l2.1-4.4l78.9-90.2c7.8-8.7,13.3-18.7,17.8-28.9
			c63.1-53.2,166.1-112.5,323.3-135.3c49,7.2,100.7,7,154-1.1c24.6-3.8,55.4-13.8,86.8-25.9c-12.8-17.9-23-37.4-31.3-57.8
			c-150.5,45.2-302,23.4-403.6-74.1C768,913.6,801.4,603.4,1012.2,383.4s519.3-266.6,689-104c132.4,126.8,140.7,343.4,38.2,537.1
			c7.2,4.5,14.6,8.8,21.4,14.1c9,6.8,17,14.4,24.9,22.3c42.4-79.1,62.3-141.1,62.3-141.1s30.1-121.7,15.1-211.6
			c-13.5-105.6-59.2-197.2-132-264.7c-199.4-184.6-547.1-134.3-774.9,111.8C829.3,484.4,761.9,659.7,770,823.6l-2.6,0.2
			c0.1,2.3,15,209.9-133.7,415.8c-4.2,3.5-8.4,7.2-12,11.4L204,1728.1c-40.9,46.6-36.1,117.7,10.8,158.9
			C261.6,1928,332.7,1923.2,373.7,1876.3z M780.4,1240.6c-22.3-19.7-50.2-28.6-77.7-27.6c54.6-89.8,82.1-176.2,95.5-245.1
			c23,62.1,58.6,116.6,106.3,160.7c38.7,35.9,83.6,63.2,132.6,82.1c-94.8,24.1-166.5,61-219.8,98.7
			C813.6,1283.5,801.4,1259,780.4,1240.6z"/>
					<path d="M1563.7,809.5c0.6,53.5-19.2,112.8-59.3,164.6c-40.1,51.9-92.8,85.9-144.6,98.7
			c10.5,47.5,37.3,91.7,78.7,123.7c41.4,32.1,90.9,46.8,139.4,45.1c-0.5-53.3,19.2-112.8,59.3-164.6c40.2-51.8,92.7-85.8,144.6-98.7
			c-10.6-47.5-37.2-91.6-78.7-123.7C1661.6,822.6,1612.3,807.9,1563.7,809.5z"/>
					<path d="M1604.7,1239c52.4-8.2,102-35.7,137.2-81c35-45.3,49.2-100.2,44.2-153.3c-45.3,11.8-91.7,42.6-127.5,88.8
			C1622.7,1139.7,1604.7,1192.3,1604.7,1239z"/>
					<path d="M1537,812.2c-52.5,8.3-102.1,35.7-137.2,81.1c-35.1,45.3-49.4,100.3-44.1,153.1
			c45.2-11.7,91.6-42.5,127.3-88.7C1518.8,911.6,1536.9,858.9,1537,812.2z"/></g>
			</svg>
		</div>
	);
};

export default IconTE;
