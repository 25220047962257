import TextField from "@mui/material/TextField";
import CheckIcon from "@mui/icons-material/Check";
import PropTypes from "prop-types";

const TextFieldWithCheck = ({
  label,
  input,
  meta: { touched, invalid, error },
  classes,
  variant,
  ...custom
}) => {
  return (
    <TextField
      {...input}
      {...custom}
      label={label}
      helperText={touched && invalid && error}
      error={touched && invalid && error}
      variant={variant}
      InputProps={{
        endAdornment: !invalid && !error && (
          <CheckIcon className={classes?.greenColor} />
        ),
      }}
    />
  );
};

TextFieldWithCheck.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
  classes: PropTypes.object,
  variant: PropTypes.string,
  label: PropTypes.string,
};

TextFieldWithCheck.defaultProps = {
  input: {
    onChange: () => {},
    value: null,
  },
  meta: {
    touched: false,
    invalid: false,
    error: undefined,
  },
  classes: {},
  variant: "standard",
  label: "",
};

export default TextFieldWithCheck;
