/**
 * Reducer for slip Auto update checkbox
 */
import {
  SAVE_AUTO_SLIP_UPDATE_STATUS
} from '../actions/types';

const INITIAL_STATE = localStorage.getItem('slipAutoUpdateStatus')&&localStorage.getItem('slipAutoUpdateStatus') === 'true' ? true : false;
const fn = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case SAVE_AUTO_SLIP_UPDATE_STATUS:
      localStorage.setItem('slipAutoUpdateStatus', action.payload);
      return action.payload;

    default:
      return state;
  }
};

export default fn;