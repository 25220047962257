/**
 * PrivacyPolicy - English version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import ListItem from "@mui/material/ListItem";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
    "& .MuiListItem-root": {
      padding: "0.2em",
    },
  },
});

class PrivacyPolicy extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>NUBET.COM PRIVACY POLICY</h1>
            English version 1.1 Last update January 27th, 2023.<br></br>
            <br></br>
            This Privacy Policy has been approved by the management board of Vana Lauri OÜ on 17th of January 2023 in accordance with the Estonian Gambling Act.<br></br>
            <br></br>
            <h3 id="General">1. GENERAL</h3>
            1. The website Nubet.com (hereinafter “We”, “Us” and/or the “Company”) is operated by Vana Lauri OÜ, a Company registered in Estonia under Company registration number 16427120 having its registered address at
            Roseni 10-135, 10111 Tallinn, Estonia. The Company is committed to comply with the EU General Data Protection Regulation (the “GDPR”) as well as the national legislation related to privacy and data
            protection, for the purposes of this Policy. The Company is licensed and supervised by The Estonian Tax and Customs Board. Vana Lauri OÜ is holding the license number HKT000066 (date of issue: 10.07.2022) and
            HKT000067 (date of issue: 10.07.2022) for commercial online gambling in accordance with the Gambling Act.<br></br>
            <br></br>
            2. This Privacy and Cookies Policy, hereinafter referred to as the “Policy”, provides information on how and why your personal information will be collected and processed by us.<br></br>
            <br></br>
            3. Personal data/information is defined as any information relating to an identified or identifiable natural person which includes data such as name, surname, date of birth, residing address, IP address, an
            email address, a telephone number, ID documentation, electronic identity card (e-ID) information, financial documents, utility bill, bank statements and due diligence process, etc., hereinafter referred to as
            the “Personal Data” or “Your Information”. Processing activities include, among others, the collection, use, retention and disclosure of suchdata.<br></br>
            <br></br>
            4. Your Personal Data will be processed by us (including any of our agents and/or employees), and (where appropriate) any other company in our company Group and/or our partners and subcontractors, in
            accordance with this Policy.<br></br>
            <br></br>
            5. Kindly note that when we are collecting, processing, and retaining your personal information, us or our affiliates will be acting as a data controller.<br></br>
            <br></br>
            6. By continuing to use our services after publication of this Privacy Policy, you agree to the terms of this Privacy Policy and to the use of your personal information in accordance with these terms. This
            Policy applies to this website, the mobile and tablet and any other our apps and accounts.<br></br>
            <br></br>
            7. We are reserving the right to change this Policy including altering the purposes for which it processes your personal information. Any changes will be published on our website. We therefore recommend that
            you check the Privacy Policy frequently for updates. Moreover, by continuing to use the Website, you agree to be bound by this Policy as well as by the latest modifications to it. If you do not agree to be
            bound by the changes to this Policy, then you shall have no right to use or access our Services.<br></br>
            <br></br>
            <h3 id="Collection">2. THE COLLECTION, PROCESSING AND USE OF DATA</h3>
            Please note that we may collect and process the following personal data about you. However, only if one of the following legal requirements of the GDPR is met:<br></br>
            <br></br>
            <ListItem>You have given us consent for the processing of your personal data for one or more specific purposes (Art.6 (1) a);</ListItem>
            <ListItem>processing is necessary for the performance of the agreement between the Company and yourself or in order to take steps at your request prior to entering into a contract (Art.6 (1) b);</ListItem>
            <ListItem>processing is necessary for compliance with a legal obligation to which the We are subject (Art.6 (1) c);</ListItem>
            <ListItem>processing is necessary in order to protect your vital interests or of another natural person (Art.6 (1) d);</ListItem>
            <ListItem>processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority (Art.6 (1) e);</ListItem>
            <ListItem>
              processing is necessary for the purposes of the legitimate interests pursued by Us or by a third party, except where such interests are overridden by Your interests or fundamental rights and freedoms which
              require protection of personal data (Art.6 (1) f).
            </ListItem>
            <br></br>
            1. Registration/Login<br></br>
            By registering as account holder and user of our services (or by completing the registration form), the information provided shall be used for the identification of the user and for account management by us.
            This information may include but is not limited to the following information: your name, surname, date of birth, tax information, electronic identity card (e-ID) information, email address, residing address,
            phone, IP-address, device, and any other mandatory details as might be requested from you for the purpose of registration and/or continued use of the service. The said data is processed for the Company to
            perform its contractual obligation with its users and also pursuant to the legitimate interests of the Company.<br></br>
            <br></br>
            2. Support/Contact Form<br></br>
            We monitor and/or record your conversations with us and retain the recordings for such periods as may be necessary to handle your enquires or as may be required by law and/or in the legitimate interest of the
            Company.<br></br>
            <br></br>
            3. Payment Processing<br></br>
            Any provided account, credit card or other information regarding payment transactions will be collected and may be transmitted to payment service provider, to fulfil the payment requested by the user.
            Additional terms and conditions of the payment providers may apply which should be available in the transaction process.<br></br>
            <br></br>
            4. Identification Documentation<br></br>
            We collect and store uploaded Documents, to identify customers, to process your requests and/or to comply with effective laws and regulations. These documents are also used for the purpose of the prevention
            and detection of fraud, prevention of money-laundering and terrorist funding and ensuring responsible gambling.<br></br>
            <br></br>
            5. Newsletters<br></br>
            Should you have already provided your contact details to the Company to receive marketing material, we shall process your data in order to send you any news which might be of interest to you. If you want to
            unsubscribe from receiving the said newsletter, you are kindly requested to change the setting at the personal settings of your account or use the unsubscribe functionality in any marketing material. The said
            contact details are lawfully processed by the Company on the basis of your consent.<br></br>
            <br></br>
            6. Player Behaviour<br></br>
            We may also process Information for the prevention of money laundering and/or funding of terrorism, the prevention of fraud, ensuring responsible gambling, improvement of quality of service and customer
            recommendations, if the Player behaviour and information let us draw to this conclusion. We process such data based on a legal obligation, legitimate interest and consent.<br></br>
            <br></br>
            <h3 id="Duration">3. DURATION OF DATA STORAGE AND DATA DELETION</h3>
            The Company deletes your personal data as a matter of principle when the purpose for which it was stored no longer exists. The above-mentioned data is obligatory for the use and implementation of the website,
            as well as the fulfilment of the terms and conditions. It is conceivable, however, that any storage beyond this may result from European or national laws, ordinances, or other regulations to which the Company
            is subject. Such data will only be deleted when the corresponding storage periods resulting from the aforementioned legal sources come to an end. The only exception to this is if the stored data is required
            for the fulfilment of a contract or the conclusion of a contract. For instance, the Estonian Money Laundering Act states that the license holder is obliged to retain documents and data on customer due
            diligence, information on transactions carried out within a business relationship and single transactions subject to the customer due diligence requirement. The Company must keep documents and data relating
            to customer due diligence measures for five years and the storage periods of up to ten years are legally prescribed for certain data due to tax regulations.<br></br>
            <br></br>
            <h3 id="Transmission">4. DATA TRANSMISSION</h3>
            1. Third party services<br></br>
            We may share personal data with other third-party providers, when you request a service, which we offer partly or fully through the third-party provider. The third-party providers have a contractual
            relationship with us. We took precautions to ensure that these third-party providers have the same privacy standards, and we only transfer data to states outside the European Economic Area provided the
            recipient guarantees a standard of data protection comparable to that in place in Europe.<br></br>
            <br></br>
            2. Identification<br></br>
            We reserve the right to carry out security checks at any time to validate your identity. Security checks can include, but are not limited to, age, registration data, financial transactions, credit information
            and/or other forms of confirmation of your data from third-party databases. To facilitate such security checks, you also agree to provide us with all additional information and documents that we may request.
            This is necessary especially to control a possible breach of our General Terms and Conditions and/or applicable legislation.<br></br>
            By agreeing to our General Terms and Conditions and our Privacy Policy, you authorize Us and our employees, representatives and suppliers to use your personal information. We may obtain information about you
            from third parties for the purposes of our compliance with anti-money laundering, anti-fraud, and customer due diligence checks, such as credit reference agencies and fraud prevention agencies.<br></br>
            <br></br>
            3. Regulatory and Enforcement Authorities<br></br>
            In fulfilment of the Company’s compliance with its legal obligations, we may be required to transfer your registration details, identification documentation, financial transactions, gaming transactions,
            general information about Your account held with the Company to regulatory and enforcement authorities, such as the Estonian Financial Intelligence Unit (FIU), within the European Union/EEA. The said
            disclosure shall also include any disclosure to the Financial Intelligence Authorities in accordance with our legal obligation in terms of Directive 2015/849 of the European Parliament and of the Council, as
            this may be amended from time to time. In addition to this, the Company must provide and receive certain information with national self-exclusion registry (the Estonian Tax and Customs Board) particularly
            concerning information about player’s self-exclusion data.<br></br>
            <br></br>
            4. Gaming and Data Platform Providers<br></br>
            In order for the Company to provide its services, it makes use of a Gaming Platform Provider and a Data Platform Provider.<br></br>
            <br></br>
            5. Match fixing<br></br>
            We are engaged into the integrity of sports, therefore your Personal Data may be disclosed to any regulatory or sporting body in connection with policing the integrity or enforcing the rules of a sport or
            game and/or prevention and detection of crime and with whom the Group has agreements from time to time for the sharing of such data and where the Group considers that there are reasonable grounds to suspect
            that you may be involved in a breach of such rules or the law, have knowledge of a breach of such rules or the law or otherwise pose a threat to the integrity of the relevant sport or game. Those bodies may
            then use your Personal Data to investigate and act on any such breaches in accordance with their procedures. The information we receive from them will serve as a basis for our decision.<br></br>
            <br></br>
            6. Customer Support<br></br>
            In order for the Company to provide support services to You, we use a third-party provider as a tool for customer support. This said tool allows us to offer E-Mail as well as live chat support.<br></br>
            <br></br>
            7. Marketing<br></br>
            In order for the Company to provide you information that is of interest to you, we use third party providers to assist us with the provision of our marketing practices. This would only apply if you are
            subscribed to receiving the Company’s newsletter as per clause 2.5 above.<br></br>
            <br></br>
            <h3 id="External">5. EXTERNAL CONTENT & COOKIES</h3>
            1. External Content & JavaScript<br></br>
            Please note that while our services may contain links to websites and applications operated by parties other than us (for example the Estonian Tax and Customs Board’s website or gambling harm organization’s
            websites), We (and/or any other company in our company Group) are not responsible for the privacy practices or content of any such websites or applications.<br></br>
            In addition, we use active JavaScript contents from external providers on our websites. When you access our site, these external providers may receive personal information relating to your visit to our
            websites.<br></br>
            By installing a JavaScript blocker such as the browser plugin 'NoScript' (www.noscript.net) or by disabling JavaScript in your browser you can prevent it. Please note that if you do so, you may not be able to
            use the full functionality of the websites you visit. Please also note that we cannot accept any responsibility for external content.<br></br>
            <br></br>
            2. Plug-ins<br></br>
            For the functionality of our websites or services, we may use plugins and active script contents (including JavaScript) from third-party providers. We reserve the right to use additional plugins and active
            script contents at any time.<br></br>
            <br></br>
            a. Google-Analytics<br></br>
            This website uses Google Analytics, a web analytics service provided by Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (hereinafter referred to as Google). Google Analytics uses various
            techniques, including so-called 'cookies', which are text files placed on your computer to help the website analyze how users use the site. The information obtained by Google Analytics about your use of the
            website will be transmitted to and stored by Google on servers, which may be operated outside member states of the European Union as well outside states party to the Agreement on the European Economic Area.
            In case of activation of IP anonymization within the Google Analytics tracking code for this website, Google will anonymise your IP address before transmission. This website uses a Google Analytics tracking
            code, to which the operator “gat.anonymizelp();” is added to enable anonymized collection of IP addresses (so-called IP masking). Google will use this information on behalf of the website operator for
            evaluating your use of the website, compiling reports on website activity and for providing other services relating to website activity and internet usage to the website operator. Google Analytics will not
            associate your IP address with any other data held by Google. You may refuse the use of Google cookies by selecting the appropriate settings on your browser. However, please note that if you do so, you may
            not be able to use the full functionality of this website. You can prevent Google’s collection and use of data relating to your use of the website (incl. your IP address) by downloading and installing the
            browser plug-in available here.<br></br>
            <br></br>
            3. Cookies<br></br>
            While you visit the website your web browser may automatically download a copy of the site you are visiting in addition to a text file called “Cookies”. This file is then stored on the hard drive of your
            personal computer. Cookies help us improve our Websites. Cookies may be declined by changing the settings to the web browser. Kindly note that should you decide to decline Cookies, you may not be able to
            access certain sections of our Websites. To ensure that our mailing tools are working properly, we may also use cookies and web beacons. By using our website/services you agree to the use of these cookies.
            <br></br>
            Some cookies provide us with anonymous information about how many visitors access our Websites, the date and time of their visit, the length of their stay, and which pages they view. We also use these Cookies
            to determine which web browsers our visitors use and the address from which they accessed our sites (for example, if they connected to a website by clicking on one of our banner ads). We may share this
            information with third parties to improve the content and functionality of our websites, to better understand our customers and markets, and to improve our products and services. <br></br>
            Some cookies may enable us to compile statistics about our visitors and their use of our sites (e.g. IP address, your operating system, website from which file was accessed, name of file, date and time of
            retrieval, volume of data transmitted, web-logs etc.).<br></br>
            <br></br>
            a. We use the following types of cookies:<br></br>
            <br></br>
            <ListItem>
              Session-based cookie - A session-based cookie is a cookie that is erased when the user closes the Web browser. The session cookie is stored in temporary memory and is not retained after the browser is
              closed. Session cookies do not collect information from the user’s computer. A session-based cookie helps you move around our website faster and, if You are a registered customer, it allows us to give you
              information that is more relevant to you.
            </ListItem>
            <ListItem>
              Persistent cookie - A persistent cookie type is a cookie that is stored on a users hard drive until it expires (persistent cookies are set with expiration dates) or until the user deletes the cookie.
              Persistent cookies are used to collect identifying information about the user, such as web surfing behaviour, or user preferences for a specific web site (e.g. language setting).
            </ListItem>
            <ListItem>
              Analytical cookie - Cookies in this category are used to collect information about the site. Analytical cookies allow us to recognize and count the number of visitors and to see how visitors move around the
              site when they are using it. These cookies collect anonymous data on the activity of users and as it came on the site.
            </ListItem>
            <br></br>
            b. Cookies allow us to:<br></br>
            <br></br>
            <ListItem>Improve the use of the website/services and to make the content more interesting and relevant to the wishes of users, like offering you more relevant promotional offers.</ListItem>
            <ListItem>Distinguish you from other users of our sites to grant a good user experience.</ListItem>
            <ListItem>Identify your settings and preferences regarding customized content or functionality.</ListItem>
            <ListItem>Your log-in details for the confirmation as registered user for our website/services</ListItem>
            <ListItem>Collect statistical analysis on the use of our website/services.</ListItem>
            <br></br>
            c. Third Party Cookies<br></br>
            <br></br>
            These are cookies set on your device by third party service providers. The setting of these cookies is regulated by the cookie policies of the respective third parties. The following are the third parties
            storing cookies on your terminal device:<br></br>
            <br></br>
            Tracker:<br></br>
            Netrefer<br></br>
            Link to Privacy Policy:<br></br>
            Lawful Basis:<br></br>
            Legitimate Interest: identification of third-party marketing suppliers<br></br>
            <br></br>
            Tracker:<br></br>
            Google Analytics<br></br>
            Link to Privacy Policy:<br></br>
            Lawful Basis:<br></br>
            Legitimate Interest: enabling, optimising and improvement of services<br></br>
            <br></br>
            Tracker:<br></br>
            Betradar<br></br>
            Link to Privacy Policy:<br></br>
            Lawful Basis:<br></br>
            Legitimate Interest: enabling, optimising and provision of the services<br></br>
            <br></br>
            d. Managing Your Cookies<br></br>
            <br></br>
            Most browsers accept cookies automatically. However, one can configure the browser in a way that no cookies are stored on its computer, or a hint appears before a new cookie is created. Please see the
            particular operator’s advice for the deactivation of cookies. Disabling cookies completely may lead to decreased functionality regarding certain features of the Company’s website. For further information on
            cookies, including how to see what cookies have been set on Your device and how to manage and delete them, may be obtained by visiting www.allaboutcookies.org.<br></br>
            <br></br>
            <h3 id="Security">6. DATA SECURITY</h3>
            We employ security measures to protect your information from access by unauthorized persons and to prevent unlawful processing, accidental loss, destruction and damage. We use state of the art technological
            security solutions, including encryption when transmitting sensitive information across the internet. This means that all personal and financial information sent to and from the Website are encrypted in
            accordance with good industry practice.<br></br>
            In order to protect both ourselves and our customers from identity theft we may verify the information you have provided with our banking institutions over secure lines. This will be carried out in accordance
            with our data protection obligations.<br></br>
            Where we have given you (or where you have chosen) a password which enables you to access certain parts of our websites, you are responsible for keeping the password, as well as any means to manage the
            password such as a registered email address, secure and confidential at all times.<br></br>
            As the security of some communications via the internet is not completely secure, we cannot guarantee the security of any information that you disclose using your internet connection. You accept the inherent
            security implications of using the internet and the Company will accept no liability for any direct, consequential, incidental, indirect, or punitive losses or damages arising out of such an occurrence.
            <br></br>
            While we are following best practice, including but not limited to implementing administrative, technical and physical measures to safeguard your Personal Data against loss, theft and misuse you should also
            take personal steps to protect the security of your data both physically and electronically by following common best practice procedures such as:<br></br>
            <br></br>
            <ListItem>Running Anti-Virus software and keeping it up to date;</ListItem>
            <ListItem>Applying operating system, web browser and other security updates;</ListItem>
            <ListItem>Ensuring your PC or other device is not left unattended whilst logged into our Websites; and</ListItem>
            <ListItem>Using strong passwords for all Services and logins.</ListItem>
            <br></br>
            When users submit sensitive information via the Website, their information is protected both online and offline. When our registration form asks users to enter sensitive information (such as a credit card
            number), that information is encrypted and is protected using Secure Socket Layer– (SSL) technology. While on a secure page, such as our deposit page, the lock icon at the bottom, or in the url, of your web
            browser becomes locked. The lock icon is unlocked, or open, when users are simply 'surfing' and are not inputting sensitive information. All payments made on our Site and Mobile Offer are secured, in
            compliance with the relevant EU rules and regulations.<br></br>
            While we use SSL encryption to protect sensitive information online, we also do everything in our power to protect user information offline. All of our users' information, not just the sensitive information
            mentioned above, is restricted in our offices. Only authorized employees/contractors are granted access to personally identifiable information. We perform checks on all of our potential employees/contractors
            to ensure that none of them has a criminal record. Our employees/contractors are regularly made aware of security best practices within the industry and security audits are carried out on a regular basis.
            Finally, the servers that store personally identifiable information are in a secure environment.<br></br>
            <br></br>
            <h3 id="Rights">7. WHAT ARE YOUR RIGHTS?</h3>
            1. Right to information as to whether and how your data are processed (Article 15 GDPR)<br></br>
            <br></br>
            2. Right to rectify data which are inaccurate and/or inaccurate (Article 16 GDPR)<br></br>
            <br></br>
            3. Right to erasure ("right to be forgotten") of data concerning you, provided that the conditions of Article 17 GDPR are met.<br></br>
            <br></br>
            4. Right to limit the processing of your personal data under the conditions of Article 18 GDPR<br></br>
            <br></br>
            5. Right of notification - Obligation to notify in connection with the correction or deletion of personal data or the restriction of processing (Article 19 GDPR)<br></br>
            <br></br>
            6. Right to data transferability (Article 20 GDPR)<br></br>
            <br></br>
            7. Right of opposition under the conditions of Article 21 GDPR<br></br>
            <br></br>
            8. Right to revoke the consent(s) you have given at any time with effect for the future (Article 7 GDPR)<br></br>
            <br></br>
            9. Right not to be subject to a decision based solely on automated processing, including profiling (Article 22 GDPR)<br></br>
            <br></br>
            10. Right of appeal to supervisory authorities (Art. 77 GDPR). - If you are of the opinion that the processing of your data violates the provisions of the GDPR or your data protection rights have otherwise
            been violated in any way, you can contact Us, as the controller, our data protection officer or a supervisory authority at any time (for contact details, see 8. COMPLAINTS AND CONTACT FOR MATTERS OF DATA
            PROTECTION).<br></br>
            <br></br>
            <h3 id="Contact">8. COMPLAINTS AND CONTACT FOR MATTERS OF DATA PROTECTION</h3>
            1. If you want to make a complaint about use of your personal data, how we process your personal data, or any other data processing or privacy matter, for which you are not satisfied with us, our service, or
            our website(s), you can do it by contacting our Data Processing Officer (DPO), or by submitting a complaint to the Estonian Data Protection Inspectorate as instructed below. According to the Estonian Data
            Protection Inspectorate, the initial attempt to solve the issue should be done between the involved parties. Thus, we recommend you contact our Data Protection Officer firstly, to have the matter investigated
            carefully, before proceeding with submitting a complaint at Privacy Protection Authority’s website.<br></br>
            <br></br>
            2. Our responsible Data Protection Officer can be contacted by sending an Email to the following Email address: dpo@vanalauri.com<br></br>
            <br></br>
            3. The Estonian Data Protection Inspectorate can be reached from the below listed contact details, or you can directly submit the complaint by using the instructions given in the following link at the
            Estonian Data Protection Inspectorate website : https://www.aki.ee/en<br></br>
            <br></br>
            <b>The Estonian Data Protection Inspectorate</b>
            <br></br>
            <br></br>
            Phone: +372 627 4135<br></br>
            E-mail: info@aki.ee<br></br>
            Postal address: 39 Tatari St., 10134 Tallinn<br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(PrivacyPolicy));
