/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconBI = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g>
					<path d="M908,1611l170.4,85.2c0.5,0.2,1,0.5,1.5,0.9l107.8-186.5c38.7-66.9,23.9-151.9-35-201.9l-202.8-172.2
		c-6-5.1-11-11.2-14.8-18.1c-2.4,0.6-4.9,0.9-7.4,0.9c-3.2-0.1-6.4-0.5-9.5-1.5l-278.5-92.9c-3.4,60.3,21.7,118.6,67.7,157.7
		l260.1,220.6c23.5,20.2,29.4,54.2,14,81L908,1611z"/>
					<path d="M663.4,943.3c-4.1,7.6-7.7,15.4-10.7,23.5c0.3,0,0.9,0.3,1.2,0.3l280,93.2c1.6-3.7,3.6-7.2,5.9-10.4l171.3-234l90.3,155.9
		c7.2,12.8,16.7,24.2,27.9,33.6l8.3,6.8c1.2-1.8,2.7-3.3,4.5-4.5l152.3-114.3l-19.6-16.6c-8.4-7.1-15.5-15.5-21.1-24.9l-84.6-147
		c-22-38-54.6-68.7-93.8-88.5l-122.6-61.2c-76.8-38.2-170.1-9.2-211.7,65.9L663.4,943.3z"/>
					<path d="M1327.3,1088.4c20.1,17.1,46.4,25.1,72.6,22.2c26.2-2.9,50.1-16.4,66.1-37.4c30.2-42.8,22.1-101.8-18.7-134.8l-7.1-6.2
		c-1.3,1.8-3,3.4-4.7,4.7l-152,114L1327.3,1088.4z"/>
					<path d="M295.5,1002.9l56.7,17.8c15.6,5.1,24.2,21.8,19.3,37.4l-8.9,27.8l-0.2,0.3l-35.7,113.3l-28.3-8.9
		c-10.3-3.7-21.7-1.5-29.9,5.8c-8.1,7.3-11.6,18.4-9.1,29c2.5,10.6,10.7,19,21.2,21.8l56.6,17.8l169.8,53.7
		c15.6,4.9,32.3-3.7,37.2-19.3c4.9-15.6-3.7-32.3-19.3-37.3l-28.3-8.9l35.6-112.8l0.2-0.4l170.1-538l0.2-0.8l125.1-395.5
		c10.3,3.7,21.7,1.5,29.9-5.7c8.1-7.3,11.7-18.4,9.1-29c-2.5-10.6-10.7-19-21.2-21.8l-28.3-8.9c-15.6-4.9-32.3,3.7-37.3,19.3
		l-109,344.7c-7.9-6.3-17-11.1-26.7-14c-8.6-2.8-17.7-4.2-26.7-4.2c-38.8,0-73.1,25.1-84.9,62.1l-89.7,283.3
		c-5,15.5-21.5,24.2-37.1,19.3l-56.7-17.8c-15-4.8-31.3-3.5-45.3,3.8c-14,7.3-24.5,19.8-29.2,34.8l-18.1,56.7
		C247.1,959.7,264.4,992.9,295.5,1002.9z M410.2,1132.5l56.4,18.1l-26.7,84.8l-56.6-17.8L410.2,1132.5z"/>
					<path d="M1199,1057.3l-7.4-6.2c-1.5-1.5-3.3-2.7-4.8-4.2l-152.9,83.1l48.7,41.3l152.9-83.1L1199,1057.3z" />
					<path d="M1520.3,933.5l102.7-55.8c9.3-5.1,15.2-14.8,15.5-25.4c0.3-10.6-5.2-20.6-14.2-26.1c-9.1-5.5-20.4-5.8-29.8-0.7
		l-114.9,62.3l6.8,6.2C1499.7,905.3,1511.2,918.6,1520.3,933.5z"/>
					<path d="M434.4,1650.1c14.7-7.3,20.6-25.2,13.3-39.9l-36.9-73.7l379.1-206l-48.7-41.3l-357,194l-25.4-51.1
		c-7.3-14.7-25.2-20.6-39.8-13.3c-14.7,7.3-20.6,25.2-13.3,39.8l26.4,52.9l-103.3,56.1c-12,6.5-18,20.3-14.7,33.5
		c3.4,13.2,15.3,22.4,28.9,22.3c5,0,9.9-1.2,14.2-3.6l101.4-55.1l36,71.9C401.9,1651.4,419.7,1657.4,434.4,1650.1z"/>
					<path d="M1864.3,1653.2c-16.4,0-29.7,13.3-29.7,29.7c0,65.6-53.2,118.8-118.8,118.8h-699.4c5.5-5.5,10.1-11.7,14-18.4l19.9-34.7
		l-170.4-85.2c-0.6-0.3-0.9-0.6-1.5-0.9l-11.6,20.2c-22.5,37.9-18.1,86,11,119.1H140.1c-16.4,0-29.7,19.3-29.7,35.7
		c0,16.4,13.3,29.7,29.7,29.7h1575.7c98.3-0.1,184.1-85.8,184.1-184.1C1900,1666.5,1880.7,1653.2,1864.3,1653.2z"/>
					<circle cx="1180.6" cy="409.1" r="180.6" />
				</g>
			</svg>
		</div>
	);
};

export default IconBI;
