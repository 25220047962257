import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const AffiliateLoader = () => {
  const location = useLocation();

  const handleAffiliateParams = () => {
    const searchParams = location?.search;
    if (searchParams) {
      const params = new URLSearchParams(searchParams);
        const id = params?.get("user_id");
        const token = params?.get("token");
      if (id && token) {
        localStorage.setItem("affiliate_id", id);
        localStorage.setItem("affiliate_token", token);
      }
    }
  };

  useEffect(handleAffiliateParams, [location.pathname]);

  return null;
};

export default AffiliateLoader;
