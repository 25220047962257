/**
 * PrivacyPolicy - Fin version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import ListItem from "@mui/material/ListItem";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
    "& .MuiListItem-root": {
      padding: "0.2em",
    },
  },
});

class PrivacyPolicy extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>NUBET.COM TIETOSUOJAKÄYTÄNTÖ</h1>
            Suomenkielinen versio 1.1 Viimeisin päivitys 27.1.2023.<br></br>
            <br></br>
            Vana Lauri OÜ:n johtokunta on hyväksynyt tämän tietosuojakäytännön 17. tammikuuta 2023 Viron rahapelilain mukaisesti.<br></br>
            <br></br>
            <h3 id="General">1. YLEISTÄ</h3>
            1. Verkkosivustoa Nubet.com (jäljempänä "Me", "Me" ja/tai "Yritys") ylläpitää Vana Lauri OÜ, joka on Virossa rekisteröity yritys numerolla 16427120 ja jonka rekisteröity osoite on Roseni 10-135, 10111
            Tallinna, Viro. Yhtiö on sitoutunut noudattamaan EU:n yleistä tietosuoja-asetusta ("GDPR") sekä yksityisyyteen ja tietosuojaan liittyvää kansallista lainsäädäntöä tätä käytäntöä sovellettaessa. Yhtiöllä on
            Viron vero- ja tullihallituksen myöntämä toimilupa, ja sitä valvoo Viron vero- ja tullihallitus. Vana Lauri OÜ:llä on rahapelilain mukainen kaupallisten online-rahapelien lisenssinumero HKT000066
            (myöntämispäivä: 10.07.2022) ja HKT000067 (myöntämispäivä: 10.07.2022).<br></br>
            <br></br>
            2. Tässä tietosuoja- ja evästekäytännössä, jäljempänä "Käytäntö", kerrotaan, miten ja miksi keräämme ja käsittelemme henkilötietojasi.<br></br>
            <br></br>
            3. Henkilötiedoilla tarkoitetaan kaikkia tunnistettuun tai tunnistettavissa olevaan luonnolliseen henkilöön liittyviä tietoja, joihin kuuluvat esimerkiksi etu- ja sukunimi, syntymäaika, asuinosoite,
            IP-osoite, sähköpostiosoite, puhelinnumero, henkilöllisyystodistukset, sähköisen henkilökortin (e-ID) tiedot, rahoitusasiakirjat, yleishyödylliset laskut, tiliotteet ja due diligence -prosessi jne.,
            jäljempänä "henkilötiedot" tai "tietosi". Käsittelytoimiin kuuluvat muun muassa tällaisten tietojen kerääminen, käyttö, säilyttäminen ja luovuttaminen.<br></br>
            <br></br>
            4. Henkilötietojasi käsittelemme me (mukaan lukien kaikki edustajamme ja/tai työntekijämme) ja (tarvittaessa) mikä tahansa muu yritys konsernissamme ja/tai kumppanimme ja alihankkijamme tämän käytännön
            mukaisesti.<br></br>
            <br></br>
            5. Huomaa, että kun keräämme, käsittelemme ja säilytämme henkilötietojasi, me tai tytäryhtiömme toimimme rekisterinpitäjänä.<br></br>
            <br></br>
            6. Jatkamalla palveluidemme käyttöä tämän tietosuojakäytännön julkaisemisen jälkeen hyväksyt tämän tietosuojakäytännön ehdot ja suostut siihen, että henkilötietojasi käytetään näiden ehtojen mukaisesti. Tätä
            käytäntöä sovelletaan tähän verkkosivustoon, mobiililaitteeseen ja tablettiin sekä kaikkiin muihin sovelluksiimme ja tileihimme.<br></br>
            <br></br>
            7. Pidätämme oikeuden muuttaa tätä käytäntöä, mukaan lukien niiden tarkoitusten muuttaminen, joita varten se käsittelee henkilötietojasi. Kaikki muutokset julkaistaan verkkosivustollamme. Siksi suosittelemme,
            että tarkistat tietosuojakäytännön usein päivitysten varalta. Lisäksi jatkamalla verkkosivuston käyttöä hyväksyt, että tämä käytäntö sekä siihen tehdyt viimeisimmät muutokset sitovat sinua. Jos et suostu
            noudattamaan tämän käytännön muutoksia, sinulla ei ole oikeutta käyttää tai käyttää palveluitamme.<br></br>
            <br></br>
            <h3 id="Collection">2. TIETOJEN KERÄÄMINEN, KÄSITTELY JA KÄYTTÖ</h3>
            Huomaa, että voimme kerätä ja käsitellä seuraavia henkilötietoja sinusta. Kuitenkin vain, jos jokin seuraavista GDPR:n oikeudellisista vaatimuksista täyttyy:<br></br>
            <br></br>
            <ListItem>Olet antanut meille suostumuksen henkilötietojesi käsittelyyn yhtä tai useampaa erityistä tarkoitusta varten (6 artiklan 1 kohdan a alakohta);</ListItem>
            <ListItem>
              käsittely on tarpeen yhtiön ja sinun välisen sopimuksen toteuttamiseksi tai jotta voimme toteuttaa toimenpiteitä pyyntösi perusteella ennen sopimuksen tekemistä (6 artiklan 1 kohdan b alakohta);
            </ListItem>
            <ListItem>käsittely on tarpeen meitä koskevan oikeudellisen velvoitteen noudattamiseksi (6 artiklan 1 kohdan c alakohta);</ListItem>
            <ListItem>käsittely on tarpeen sinun tai jonkun muun luonnollisen henkilön elintärkeiden etujen suojaamiseksi (6 artiklan 1 kohdan d alakohta);</ListItem>
            <ListItem>käsittely on tarpeen yleistä etua koskevan tehtävän suorittamiseksi tai julkisen vallan käyttämiseksi (6 artiklan 1 kohdan e alakohta);</ListItem>
            <ListItem>
              käsittely on tarpeen meidän tai kolmannen osapuolen oikeutettujen etujemme toteuttamiseksi, paitsi jos etusi tai perusoikeutesi ja -vapauksiesi suojaa edellyttävät henkilötietojen suojaa koskevat edut
              syrjäyttävät nämä edut (6 artiklan 1 kohta f).
            </ListItem>
            <br></br>
            1. Rekisteröinti/Kirjautuminen<br></br>
            Rekisteröitymällä tilinomistajaksi ja palveluidemme käyttäjäksi (tai täyttämällä rekisteröintilomakkeen) annettuja tietoja käytetään käyttäjän tunnistamiseen ja tilinhallintaan. Näihin tietoihin voivat kuulua
            muun muassa seuraavat tiedot: etunimi, sukunimi, syntymäaika, verotustiedot, sähköisen henkilökortin (e-ID) tiedot, sähköpostiosoite, asuinosoite, puhelin, IP-osoite, laite ja kaikki muut pakolliset tiedot,
            joita sinulta saatetaan pyytää rekisteröitymistä ja/tai palvelun jatkuvaa käyttöä varten. Mainittuja tietoja käsitellään, jotta yhtiö voi täyttää sopimusvelvoitteensa käyttäjiä kohtaan ja myös yhtiön
            oikeutettujen etujen mukaisesti.<br></br>
            <br></br>
            2. Asiakastuki/Yhteystietolomake<br></br>
            Seuraamme ja/tai nauhoitamme kanssamme käymiäsi keskusteluja ja säilytämme nauhoituksia niin kauan kuin se on tarpeen tiedustelujesi käsittelemiseksi tai kuten laki ja/tai yhtiön oikeutetut edut edellyttävät.
            <br></br>
            <br></br>
            3. Maksuliikenne<br></br>
            Kaikki annetut tili-, luottokortti- tai muut maksutapahtumiin liittyvät tiedot kerätään ja ne voidaan välittää maksupalveluntarjoajalle käyttäjän pyytämän maksun suorittamiseksi. Maksupalveluntarjoajiin voi
            liittyä lisäehtoja, joiden pitäisi olla saatavilla maksutapahtuman yhteydessä.<br></br>
            <br></br>
            4. Tunnistusasiakirjat<br></br>
            Keräämme ja tallennamme ladattuja asiakirjoja asiakkaiden tunnistamiseksi, pyyntöjesi käsittelemiseksi ja/tai voimassa olevien lakien ja asetusten noudattamiseksi. Näitä asiakirjoja käytetään myös petosten
            ehkäisemiseen ja havaitsemiseen, rahanpesun ja terrorismin rahoituksen estämiseen sekä vastuullisen pelaamisen varmistamiseen.<br></br>
            <br></br>
            5. Uutiskirjeet<br></br>
            Jos olet jo antanut yhteystietosi yritykselle markkinointimateriaalin saamiseksi, käsittelemme tietojasi lähettääksemme sinulle uutisia, jotka saattavat kiinnostaa sinua. Jos haluat peruuttaa mainitun
            uutiskirjeen vastaanottamisen, sinua pyydetään muuttamaan asetusta tilisi henkilökohtaisissa asetuksissa tai käyttämään markkinointimateriaalin peruutustoimintoa. Yhtiö käsittelee mainittuja yhteystietoja
            laillisesti suostumuksesi perusteella.<br></br>
            <br></br>
            6. Pelaaja-käyttäytyminen<br></br>
            Voimme myös käsitellä tietoja rahanpesun ja/tai terrorismin rahoittamisen estämiseksi, petosten estämiseksi, vastuullisen pelaamisen varmistamiseksi, palvelun laadun parantamiseksi ja asiakassuositusten
            antamiseksi, jos pelaajan käyttäytyminen ja tiedot antavat meille mahdollisuuden tehdä tällaisen johtopäätöksen. Käsittelemme tällaisia tietoja lakisääteisen velvoitteen, oikeutetun edun ja suostumuksen
            perusteella.<br></br>
            <br></br>
            <h3 id="Duration">3. TIETOJEN SÄILYTTÄMINEN JA POISTAMINEN</h3>
            Yhtiö poistaa henkilötietosi periaatteessa silloin, kun tarkoitusta, jota varten ne on tallennettu, ei enää ole. Edellä mainitut tiedot ovat pakollisia verkkosivuston käytön ja toteuttamisen sekä ehtojen
            täyttämisen kannalta. On kuitenkin mahdollista, että tätä laajempi tallentaminen voi johtua eurooppalaisista tai kansallisista laeista, asetuksista tai muista säännöistä, joita yhtiöön sovelletaan. Tällaiset
            tiedot poistetaan vasta, kun edellä mainituista oikeuslähteistä johtuvat vastaavat säilytysajat päättyvät. Ainoa poikkeus tästä on, jos tallennettuja tietoja tarvitaan sopimuksen täyttämiseksi tai sopimuksen
            tekemistä varten. Esimerkiksi Viron rahanpesulaissa todetaan, että toimiluvan haltijan on säilytettävä asiakirjat ja tiedot, jotka koskevat asiakkaan tuntemisvelvollisuutta, tietoja liikesuhteessa
            toteutetuista liiketoimista ja yksittäisistä liiketoimista, joihin sovelletaan asiakkaan tuntemisvelvollisuutta koskevaa vaatimusta. Yhtiön on säilytettävä asiakkaan due diligence -toimenpiteisiin liittyviä
            asiakirjoja ja tietoja viisi vuotta, ja verosäännösten vuoksi tietyille tiedoille on laissa säädetty jopa kymmenen vuoden säilytysaika.<br></br>
            <br></br>
            <h3 id="Transmission">4. TIETOJEN SIIRTO</h3>
            1. Kolmansien osapuolien palvelut<br></br>
            Saatamme jakaa henkilötietoja muiden kolmansien osapuolten palveluntarjoajien kanssa, kun pyydät palvelua, jonka tarjoamme osittain tai kokonaan kolmannen osapuolen palveluntarjoajan kautta. Kolmannen
            osapuolen palveluntarjoajat ovat sopimussuhteessa meihin. Olemme ryhtyneet varotoimiin varmistaaksemme, että näillä kolmannen osapuolen palveluntarjoajilla on samat tietosuojanormit, ja siirrämme tietoja
            Euroopan talousalueen ulkopuolisiin valtioihin vain, jos vastaanottaja takaa vastaavanlaisen tietosuojan tason kuin Euroopassa.<br></br>
            <br></br>
            2. Tunnistautuminen<br></br>
            Pidätämme oikeuden tehdä turvallisuustarkastuksia milloin tahansa henkilöllisyytesi vahvistamiseksi. Turvatarkastuksiin voi kuulua muun muassa ikä, rekisteröintitiedot, rahoitustapahtumat, luottotiedot ja/tai
            muut kolmannen osapuolen tietokannoista saadut tietojesi vahvistukset. Tällaisten turvatarkastusten helpottamiseksi suostut myös toimittamaan meille kaikki pyytämämme lisätiedot ja asiakirjat. Tämä on tarpeen
            erityisesti yleisten käyttöehtojemme ja/tai sovellettavan lainsäädännön mahdollisen rikkomisen valvomiseksi.<br></br>
            <br></br>
            Hyväksymällä yleiset käyttöehtomme ja tietosuojakäytäntömme annat meille ja työntekijöillemme, edustajillemme ja toimittajillemme luvan käyttää henkilötietojasi. Saatamme hankkia tietoja sinusta kolmansilta
            osapuolilta, kuten luottotietotoimistoilta ja petostentorjuntaviranomaisilta, jotta voimme noudattaa rahanpesun, petosten ja asiakkaan due diligence -tarkastuksia.<br></br>
            <br></br>
            3. Sääntely- ja täytäntöönpanoviranomaiset<br></br>
            Yhtiön noudattaessa lakisääteisiä velvoitteitaan meitä saatetaan vaatia siirtämään rekisteröintitietosi, tunnistamisasiakirjasi, rahoitustapahtumat, pelitapahtumat ja yleiset tiedot tilistäsi Yhtiössä
            sääntely- ja lainvalvontaviranomaisille, kuten Viron rahanpesun selvittelykeskukselle (FIU), Euroopan unionin/ETA:n alueella. Mainittu luovuttaminen sisältää myös kaikenlaisen luovuttamisen rahanpesun
            selvittelystä vastaaville viranomaisille Euroopan parlamentin ja neuvoston direktiivin 2015/849, sellaisena kuin sitä voidaan ajoittain muuttaa, mukaisen lakisääteisen velvollisuutemme mukaisesti. Tämän
            lisäksi Yhtiön on annettava ja vastaanotettava tiettyjä tietoja kansalliselle itsesulkurekisterille (Viron vero- ja tullihallitus) erityisesti pelaajien peliesto-tietoja koskevien tietojen osalta.<br></br>
            <br></br>
            4. Peli- ja data -alustojen tarjoajat<br></br>
            Jotta yhtiö voi tarjota palvelujaan, se käyttää pelialustan tarjoajaa ja tietoalustan tarjoajaa.<br></br>
            <br></br>
            5. Ottelumanipulointi<br></br>
            Olemme mukana urheilun integriteetin valvonnassa, joten henkilötietojasi voidaan luovuttaa mille tahansa sääntely- tai urheiluelimelle, joka liittyy urheilun tai pelin integriteetin valvontaan tai sääntöjen
            noudattamisen valvontaan ja/tai rikosten ehkäisyyn ja havaitsemiseen ja jonka kanssa konsernilla on ajoittain sopimuksia tällaisten tietojen jakamisesta, ja jos konserni katsoo, että on perusteltua syytä
            epäillä, että saatat olla osallisena tällaisten sääntöjen tai lain rikkomisessa, sinulla on tietoa tällaisten sääntöjen tai lain rikkomisesta tai muutoin uhkaat kyseisen urheilun tai pelin integriteettiä.
            Kyseiset elimet voivat tällöin käyttää henkilötietojasi tutkiakseen tällaisia rikkomuksia ja toimiakseen niiden menettelytapojen mukaisesti. Niiltä saamamme tiedot toimivat päätöksemme perustana.<br></br>
            <br></br>
            6. Asiakaspalvelu<br></br>
            Jotta Yhtiö voi tarjota sinulle tukipalveluja, käytämme kolmannen osapuolen palveluntarjoajaa asiakastuen välineenä. Tämän työkalun avulla voimme tarjota sähköposti- ja live-chat-tukea.<br></br>
            <br></br>
            7. Markkinointi<br></br>
            Jotta yhtiö voi tarjota sinulle sinua kiinnostavaa tietoa, käytämme kolmansia osapuolia avustamaan meitä markkinointikäytäntöjemme toteuttamisessa. Tämä koskee vain, jos olet tilannut Yhtiön uutiskirjeen
            edellä olevan kohdan 2.5 mukaisesti.<br></br>
            <br></br>
            <h3 id="External">5. ULKOPUOLINEN SISÄLTÖ JA EVÄSTEET</h3>
            1. Ulkopuolinen sisältö & JavaScript<br></br>
            Huomaa, että vaikka palvelumme saattavat sisältää linkkejä muiden osapuolten kuin meidän ylläpitämille verkkosivustoille ja sovelluksille (esimerkiksi Viron vero- ja tullihallituksen verkkosivustolle tai
            uhkapelien haittaorganisaatioiden verkkosivustoille), me (ja/tai muut konserniimme kuuluvat yritykset) emme ole vastuussa tällaisten verkkosivustojen tai sovellusten tietosuojakäytännöistä tai sisällöstä.
            <br></br>
            Lisäksi käytämme verkkosivuillamme ulkopuolisten palveluntarjoajien aktiivista JavaScript-sisältöä. Kun käytät sivustoamme, nämä ulkoiset palveluntarjoajat voivat saada henkilökohtaisia tietoja, jotka
            liittyvät vierailuusi verkkosivustoillamme.<br></br>
            Voit estää sen asentamalla JavaScript-estoohjelman, kuten selaimen lisäosan "NoScript" (www.noscript.net), tai poistamalla JavaScriptin käytöstä selaimessasi. Huomaa, että jos teet näin, et ehkä pysty
            käyttämään vierailemiesi verkkosivustojen kaikkia toimintoja. Huomaa myös, että emme voi ottaa vastuuta ulkopuolisesta sisällöstä.<br></br>
            <br></br>
            2. Liitännäiset (Plug-ins)<br></br>
            Verkkosivustojemme tai palveluidemme toiminnallisuuden varmistamiseksi saatamme käyttää kolmannen osapuolen palveluntarjoajilta saatavia liitännäisiä ja aktiivista skriptisisältöä (mukaan lukien JavaScript).
            Pidätämme oikeuden käyttää milloin tahansa lisää liitännäisiä ja aktiivisia skriptisisältöjä.<br></br>
            <br></br>
            a. Google-Analytics<br></br>
            Tämä verkkosivusto käyttää Google Analyticsia, joka on Google Inc:n, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (jäljempänä Google) tarjoama web-analyysipalvelu. Google Analytics käyttää
            erilaisia tekniikoita, kuten niin sanottuja "evästeitä", jotka ovat tietokoneellesi sijoitettuja tekstitiedostoja, joiden avulla verkkosivusto voi analysoida, miten käyttäjät käyttävät sivustoa. Google
            Analyticsin saamat tiedot verkkosivuston käytöstäsi siirretään Googlen palvelimille, joita se tallentaa palvelimille, joita voidaan käyttää Euroopan unionin jäsenvaltioiden ja Euroopan talousalueesta tehdyn
            sopimuksen osapuolina olevien valtioiden ulkopuolella. Jos Google Analyticsin seurantakoodissa aktivoidaan IP-osoitteen anonymisointi tämän verkkosivuston osalta, Google anonymisoi IP-osoitteesi ennen
            siirtoa. Tämä verkkosivusto käyttää Google Analytics -seurantakoodia, johon on lisätty operaattori "gat.anonymizelp();", jotta IP-osoitteiden anonymisoitu kerääminen (niin sanottu IP-peittäminen) on
            mahdollista. Google käyttää näitä tietoja verkkosivuston ylläpitäjän puolesta arvioidakseen verkkosivuston käyttöäsi, laatiakseen raportteja verkkosivuston toiminnasta ja tarjotakseen verkkosivuston
            ylläpitäjälle muita verkkosivuston toimintaan ja internetin käyttöön liittyviä palveluja. Google Analytics ei yhdistä IP-osoitettasi muihin Googlen hallussa oleviin tietoihin. Voit kieltää Googlen evästeiden
            käytön valitsemalla selaimesi asianmukaiset asetukset. Huomaa kuitenkin, että jos teet niin, et ehkä pysty käyttämään tämän verkkosivuston kaikkia toimintoja. Voit estää Googlea keräämästä ja käyttämästä
            verkkosivuston käyttöön liittyviä tietoja (mukaan lukien IP-osoitteesi) lataamalla ja asentamalla selaimen laajennuksen, joka on saatavilla täältä.<br></br>
            <br></br>
            3. Evästeet<br></br>
            Kun vierailet verkkosivustolla, selaimesi saattaa automaattisesti ladata kopion sivustosta, jolla vierailet, sekä tekstitiedoston nimeltä "Evästeet". Tämä tiedosto tallennetaan sitten henkilökohtaisen
            tietokoneesi kiintolevylle. Evästeet auttavat meitä parantamaan verkkosivustojamme. Evästeet voidaan kieltää muuttamalla verkkoselaimen asetuksia. Huomaa, että jos päätät kieltäytyä evästeistä, et ehkä pääse
            käyttämään tiettyjä verkkosivustojemme osioita. Varmistaaksemme, että postitustyökalumme toimivat oikein, saatamme myös käyttää evästeitä ja verkkomajakoita. Käyttämällä verkkosivustoamme/palveluitamme
            hyväksyt näiden evästeiden käytön.<br></br>
            Jotkin evästeet antavat meille nimettömiä tietoja siitä, kuinka monta kävijää vierailee verkkosivustoillamme, vierailun päivämäärästä ja kellonajasta, vierailun kestosta ja siitä, mitä sivuja he katsovat.
            Käytämme näitä evästeitä myös määrittääksemme, mitä verkkoselaimia kävijämme käyttävät ja mistä osoitteesta he ovat päässeet sivustoillemme (esimerkiksi jos he ovat liittyneet verkkosivustolle klikkaamalla
            jotain mainosbanneriamme). Voimme jakaa näitä tietoja kolmansien osapuolten kanssa parantaaksemme verkkosivustojemme sisältöä ja toiminnallisuutta, ymmärtääksemme paremmin asiakkaitamme ja markkinoitamme sekä
            kehittääksemme tuotteitamme ja palveluitamme.<br></br>
            Joidenkin evästeiden avulla voimme kerätä tilastoja kävijöistämme ja heidän sivustojemme käytöstä (esim. IP-osoite, käyttöjärjestelmäsi, verkkosivusto, jolta tiedostoa käytettiin, tiedoston nimi, hakupäivä ja
            -aika, siirrettyjen tietojen määrä, verkkopäiväkirjat jne.)<br></br>
            <br></br>
            a. Käytämme seuraavanlaisia evästeitä:<br></br>
            <br></br>
            <ListItem>
              Istuntokohtainen eväste - Istuntokohtainen eväste on eväste, joka poistetaan, kun käyttäjä sulkee verkkoselaimen. Istuntoeväste tallennetaan väliaikaiseen muistiin, eikä se säily selaimen sulkemisen
              jälkeen. Istuntoevästeet eivät kerää tietoja käyttäjän tietokoneesta. Istuntopohjainen eväste auttaa sinua liikkumaan verkkosivustollamme nopeammin ja, jos olet rekisteröitynyt asiakas, sen avulla voimme
              antaa sinulle tietoja, jotka ovat sinulle merkityksellisempiä.
            </ListItem>
            <ListItem>
              Pysyvä eväste - Pysyvä evästetyyppi on eväste, joka tallennetaan käyttäjän kiintolevylle, kunnes sen voimassaolo päättyy (pysyville evästeille asetetaan voimassaolon päättymispäivämäärät) tai kunnes
              käyttäjä poistaa evästeen. Pysyviä evästeitä käytetään keräämään tunnistetietoja käyttäjästä, kuten verkkoselailukäyttäytymisestä tai käyttäjän mieltymyksistä tiettyä verkkosivustoa varten (esim.
              kieliasetukset)
            </ListItem>
            <ListItem>
              Analyyttiset evästeet - Tämän luokan evästeitä käytetään keräämään tietoa sivustosta. Analyyttisten evästeiden avulla voimme tunnistaa ja laskea kävijöiden määrän ja nähdä, miten kävijät liikkuvat
              sivustolla, kun he käyttävät sitä. Nämä evästeet keräävät nimettömiä tietoja käyttäjien toiminnasta ja siitä, miten se tuli sivustolle.
            </ListItem>
            <br></br>
            b. Evästeet mahdollistavat:<br></br>
            <br></br>
            <ListItem>
              verkkosivuston/palveluiden käytön parantaminen ja sisällön tekeminen kiinnostavammaksi ja käyttäjien toiveiden mukaisemmaksi, esimerkiksi tarjoamalla sinulle sopivampia myynninedistämistarjouksia.
            </ListItem>
            <ListItem>erottamaan sinut muista sivustojemme käyttäjistä hyvän käyttökokemuksen takaamiseksi.</ListItem>
            <ListItem>yksilöidä räätälöityä sisältöä tai toimintoja koskevat asetuksesi ja mieltymyksesi.</ListItem>
            <ListItem>tallentamaan tilapäisesti sisäänkirjautumistietosi verkkosivujemme/palvelujemme rekisteröityneen käyttäjän aseman vahvistamiseksi.</ListItem>
            <ListItem>kerätä tilastollisia analyysejä verkkosivustomme/palveluidemme käytöstä.</ListItem>
            <br></br>
            c. Kolmannen osapuolen evästeet<br></br>
            <br></br>
            Nämä ovat kolmannen osapuolen palveluntarjoajien laitteellesi asettamia evästeitä. Näiden evästeiden asettamista säännellään kyseisten kolmansien osapuolten evästekäytännöillä. Seuraavat kolmannet osapuolet
            tallentavat evästeitä päätelaitteeseesi:<br></br>
            <br></br>
            Tracker:<br></br>
            Netrefer<br></br>
            Link to Privacy Policy:<br></br>
            Lawful Basis:<br></br>
            Oikeutettu etu: kolmannen osapuolen markkinointitoimittajien tunnistaminen<br></br>
            <br></br>
            Tracker:<br></br>
            Google Analytics<br></br>
            Link to Privacy Policy:<br></br>
            Lawful Basis:<br></br>
            Oikeutettu etu: palvelujen mahdollistaminen, optimointi ja parantaminen.<br></br>
            <br></br>
            Tracker:<br></br>
            Betradar<br></br>
            Link to Privacy Policy:<br></br>
            Lawful Basis:<br></br>
            Oikeutettu etu: palvelujen mahdollistaminen, optimointi ja tarjoaminen.<br></br>
            <br></br>
            d. Evästeiden hallinta<br></br>
            <br></br>
            Useimmat selaimet hyväksyvät evästeet automaattisesti. Selaimen voi kuitenkin määrittää niin, että sen tietokoneelle ei tallenneta evästeitä tai että ennen uuden evästeen luomista näytetään vihje. Tutustu
            kunkin operaattorin ohjeisiin evästeiden poistamiseksi käytöstä. Evästeiden poistaminen kokonaan käytöstä voi johtaa toimintojen heikkenemiseen yhtiön verkkosivuston tiettyjen ominaisuuksien osalta.<br></br>
            Lisätietoja evästeistä, mukaan lukien siitä, miten voit nähdä, mitä evästeitä laitteellesi on asetettu ja miten voit hallita ja poistaa niitä, saat osoitteesta www.allaboutcookies.org.<br></br>
            <br></br>
            <h3 id="Security">6. TIETOTURVA</h3>
            Käytämme turvatoimia suojellaksemme tietojasi asiattomien henkilöiden pääsyltä ja estääkseen laittoman käsittelyn, vahingossa tapahtuvan katoamisen, tuhoutumisen ja vahingoittumisen. Käytämme uusimpia
            teknisiä turvaratkaisuja, kuten salausta, kun siirrämme arkaluonteisia tietoja internetissä. Tämä tarkoittaa, että kaikki verkkosivustolle ja verkkosivustolta lähetetyt henkilökohtaiset ja taloudelliset
            tiedot salataan alan hyvän käytännön mukaisesti.<br></br>
            Suojellaksemme sekä itseämme että asiakkaitamme identiteettivarkauksilta saatamme tarkistaa antamasi tiedot pankkilaitoksemme kanssa turvallisten yhteyksien kautta. Tämä tapahtuu tietosuojavelvoitteidemme
            mukaisesti.<br></br>
            Jos olemme antaneet sinulle (tai jos olet itse valinnut) salasanan, jonka avulla pääset verkkosivustojemme tiettyihin osiin, olet vastuussa siitä, että salasana sekä kaikki salasanan hallintakeinot, kuten
            rekisteröity sähköpostiosoite, ovat aina turvassa ja luottamuksellisia.<br></br>
            Koska joidenkin internetin kautta tapahtuvien viestien turvallisuus ei ole täysin turvallista, emme voi taata internet-yhteyden kautta antamiesi tietojen turvallisuutta. Hyväksyt internetin käyttöön liittyvät
            turvallisuusriskit, eikä yhtiö ota vastuuta mistään suorista, välillisistä, satunnaisista, epäsuorista tai rangaistusluonteisista menetyksistä tai vahingoista, jotka johtuvat tällaisesta tapahtumasta.
            <br></br>
            Vaikka noudatamme parhaita käytäntöjä, mukaan lukien, mutta ei rajoittuen, hallinnollisten, teknisten ja fyysisten toimenpiteiden toteuttaminen henkilötietojesi suojaamiseksi häviämiseltä, varkaudelta ja
            väärinkäytöltä, sinun tulisi myös itse ryhtyä toimenpiteisiin suojellaksesi tietojesi tietoturvaa sekä fyysisesti että sähköisesti noudattamalla yleisiä parhaita käytäntöjä, kuten esimerkiksi:<br></br>
            <br></br>
            <ListItem>Virustorjuntaohjelmiston käyttäminen ja sen pitäminen ajan tasalla;</ListItem>
            <ListItem>Käyttöjärjestelmän, verkkoselaimen ja muiden tietoturvapäivitysten käyttäminen;</ListItem>
            <ListItem>Varmistaminen, että tietokonettasi tai muuta laitettasi ei jätetä vartioimatta, kun olet kirjautuneena verkkosivuillemme; ja</ListItem>
            <ListItem>Vahvojen salasanojen käyttäminen kaikissa palveluissa ja kirjautumisissa.</ListItem>
            <br></br>
            Kun käyttäjät toimittavat arkaluonteisia tietoja verkkosivuston kautta, heidän tietonsa suojataan sekä verkossa että sen ulkopuolella. Kun rekisteröintilomakkeemme pyytää käyttäjiä antamaan arkaluonteisia
            tietoja (kuten luottokortin numeron), nämä tiedot salataan ja suojataan SSL-tekniikalla (Secure Socket Layer). Kun olet suojatulla sivulla, kuten talletussivullamme, verkkoselaimesi alareunassa tai
            url-osoitteessa oleva lukituskuvake lukittuu. Lukkokuvake on auki, kun käyttäjät vain "surffailevat" eivätkä syötä arkaluonteisia tietoja. Kaikki sivustollamme ja mobiilitarjouksessamme suoritetut maksut on
            suojattu asiaankuuluvien EU-sääntöjen ja -määräysten mukaisesti.<br></br>
            <br></br>
            Käytämme SSL-salausta arkaluonteisten tietojen suojaamiseksi verkossa, mutta teemme myös kaikkemme suojellaksemme käyttäjien tietoja offline-tilassa. Kaikkia käyttäjien tietoja, ei vain edellä mainittuja
            arkaluonteisia tietoja, rajoitetaan toimistossamme. Vain valtuutetuilla työntekijöillä/urakoitsijoilla on pääsy henkilökohtaisesti tunnistettaviin tietoihin. Tarkastamme kaikki mahdolliset
            työntekijämme/urakoitsijat varmistaaksemme, ettei kenelläkään heistä ole rikosrekisteriä. Työntekijöillemme/urakoitsijoillemme tiedotetaan säännöllisesti alan parhaista turvallisuuskäytännöistä, ja
            turvallisuustarkastuksia tehdään säännöllisesti. Henkilökohtaisia tietoja tallentavat palvelimet sijaitsevat turvallisessa ympäristössä.<br></br>
            <br></br>
            <h3 id="Rights">7. MITKÄ OVAT OIKEUTESI?</h3>
            1. Oikeus saada tietoa siitä, käsitelläänkö tietojasi ja miten niitä käsitellään (yleisen tietosuoja-asetuksen 15 artikla).<br></br>
            <br></br>
            2. Oikeus oikaista virheelliset ja/tai epätarkat tiedot (tietosuoja-asetuksen 16 artikla).<br></br>
            <br></br>
            3. Oikeus sinua koskevien tietojen poistamiseen ("oikeus tulla unohdetuksi") edellyttäen, että yleisen tietosuoja-asetuksen 17 artiklan edellytykset täyttyvät.<br></br>
            <br></br>
            4. Oikeus rajoittaa henkilötietojesi käsittelyä yleisen tietosuoja-asetuksen 18 artiklan ehtojen mukaisesti.<br></br>
            <br></br>
            5. Ilmoitusoikeus - Ilmoitusvelvollisuus henkilötietojen korjaamisen tai poistamisen tai käsittelyn rajoittamisen yhteydessä (GDPR 19 artikla).<br></br>
            <br></br>
            6. Oikeus tietojen siirrettävyyteen (yleisen tietosuoja-asetuksen 20 artikla).<br></br>
            <br></br>
            7. Oikeus vastustaa tietoja yleisen tietosuoja-asetuksen 21 artiklan edellytysten mukaisesti.<br></br>
            <br></br>
            8. Oikeus peruuttaa antamasi suostumus tai antamasi suostumukset milloin tahansa tulevaisuutta varten (GDPR 7 artikla).<br></br>
            <br></br>
            9. Oikeus olla joutumatta sellaisen päätöksen kohteeksi, joka perustuu yksinomaan automaattiseen käsittelyyn, mukaan lukien profilointi (yleisen tietosuoja-asetuksen 22 artikla).<br></br>
            <br></br>
            10. Oikeus hakea muutosta valvontaviranomaisilta (yleisen tietosuoja-asetuksen 77 artikla). - Jos olet sitä mieltä, että tietojesi käsittelyssä rikotaan GDPR:n säännöksiä tai tietosuojaoikeuksiasi on muutoin
            loukattu jollakin tavalla, voit milloin tahansa ottaa yhteyttä meihin rekisterinpitäjänä, tietosuojavastaavaamme tai valvontaviranomaiseen (yhteystiedot ks. 8. kohta. VALITUKSET JA YHTEYDENOTOT
            TIETOSUOJA-ASIOISSA).<br></br>
            <br></br>
            <h3 id="Contact">8. VALITUKSET JA YHTEYDENOTOT TIETOSUOJA-ASIOISSA</h3>
            1. Jos haluat tehdä valituksen henkilötietojesi käytöstä, siitä, miten käsittelemme henkilötietojasi, tai mistä tahansa muusta tietojenkäsittelyyn tai yksityisyyden suojaan liittyvästä asiasta, jonka vuoksi
            et ole tyytyväinen meihin, palveluumme tai verkkosivustoihimme, voit tehdä sen ottamalla yhteyttä tietosuojavastaavaan tai tekemällä valituksen Viron tietosuojavaltuutetulle alla olevien ohjeiden mukaisesti.
            Viron tietosuojavaltuutetun mukaan ongelman ratkaiseminen olisi aluksi yritettävä ratkaista asianosaisten osapuolten kesken. Näin ollen suosittelemme, että otat ensin yhteyttä tietosuojavastaavaamme, jotta
            asia tutkitaan huolellisesti, ennen kuin aloitat valituksen tekemisen tietosuojaviranomaisen verkkosivustolla.<br></br>
            <br></br>
            2. Vastuulliseen tietosuojavastaavaamme voi ottaa yhteyttä lähettämällä sähköpostia seuraavaan sähköpostiosoitteeseen: dpo@vanalauri.com.<br></br>
            <br></br>
            3. Viron tietosuojavaltuutettuun voi ottaa yhteyttä alla luetelluista yhteystiedoista, tai voit tehdä valituksen suoraan Viron tietosuojavaltuutetun verkkosivuston seuraavassa linkissä annettujen ohjeiden
            mukaisesti: https://www.aki.ee/en.<br></br>
            <br></br>
            <b>The Estonian Data Protection Inspectorate</b>
            <br></br>
            Puhelin: +372 627 4135<br></br>
            Sähköposti: info@aki.ee<br></br>
            Postiosoite: 39 Tatari St., 10134 Tallinn<br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(PrivacyPolicy));
