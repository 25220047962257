import et from "date-fns/locale/et";
import enAU from "date-fns/locale/en-AU";
import fi from "date-fns/locale/fi";

export const expandedLocales = {
  en: "en-GB",
  et: "et-EE",
  fi: "fi-FI",
};

const locales = {
  en: enAU,
  fi: fi,
  et: et,
};

export default locales;
