// MUI Dialog style overrides
export function dialog(theme) {
  return {
    MuiDialog: {
      styleOverrides: {
        root: {
          // background: theme.palette.background.paper,
        },
      },
    },
  };
}
