import authHeader from "../../apis/auth-header";
import api from "../../apis/nubet";

export const getCustomerFreeSpins = async () => {
  try {
    const reply = await api.get("/account/bonuses/freeSpins", {
      headers: authHeader(),
      params: {
        page: 1,
      },
    });
    if (reply?.data?.payload?.bonuses) {
      return reply.data.payload.bonuses;
    }
    return {};
  } catch (error) {
    console.log(error);
  }
};

export const getCasinoSlidersGames = async (sliderFilter) => {
  try {
    const reply = await api.get("/casino/groups", {
      params: {
        api: "group_list",
        groupName: sliderFilter,
      },
    });
    return {
      groupType: sliderFilter,
      games: reply.data?.payload?.games ? reply.data.payload.games : [],
    };
  } catch (error) {
    console.log(error);
  }
};

export const getVendors = async () => {
  try {
    const reply = await api.get("/casino/providers", {
      headers: authHeader(),
    });
    const vendors = reply.data?.payload ? reply.data.payload : [];
    return vendors;
  } catch (error) {
    console.log(error);
  }
};

export const getCasinoGamesByFeature = async (params) => {
  try {
    const reply = await api.get("/casino/groups", {
      params: {
        api: "group_list",
        ...params,
      },
    });
    return reply.data?.payload ? { data: reply.data.payload, groupId: params.groupName } : {};
  } catch (error) {
    console.log(error);
  }
};

export const getCasinoGamesGroupNames = async () => {
  try {
    const reply = await api.get("/casino/groups", {
      params: {
        api: "group_names",
      },
    });
    return reply.data?.payload?.groups || [];
  } catch (error) {
    console.log(error);
  }
};

export const searchCasinoGames = async (data) => {
  try {
    const reply = await api.get("/casino/search", {
      params: {
        src: data,
      },
    });
    return reply.data?.payload || {};
  } catch (error) {
    console.log(error);
  }
};

export const getCasinoGamesByProvider = async (params) => {
  try {
    const reply = await api.get("/casino/groups", {
      params: {
        api: "group_list",
        ...params,
      },
    });
    return reply.data?.payload ? reply.data.payload : {};
  } catch (error) {
    console.log(error);
  }
};

export const getUserFavGamesIds = async () => {
  try {
    const reply = await api.get("/casino/groups", {
      params: {
        api: "fav",
      },
      headers: authHeader(),
    });
    return reply.data.payload?.games ? reply.data.payload.games : [];
  } catch (error) {
    console.log(error);
  }
};

export const getUserFavoriteCasinoGames = async () => {
  try {
    const reply = await api.get("/casino/groups", {
      params: {
        api: "fav",
        type: "data",
      },
      headers: authHeader(),
    });
    return reply.data.payload?.games;
  } catch (error) {
    console.log(error);
  }
};

export const getCasinoGame = async ({ gameId, type, layout }) => {
  try {
    const reply = await api.get("casino/game", {
      params: {
        type: type,
        gameId: gameId,
        device: layout === "touch" ? "mobile" : "desktop",
      },
      headers: authHeader(),
    });
    return reply.data?.payload;
  } catch (error) {
    console.log(error);
  }
};

export const saveFavoriteCasinoGames = async ({ data, callback }) => {
  try {
    const reply = await api.post("/casino/favourite", data, {
      headers: authHeader(),
    });
    if (reply.data) {
      callback();
    }
  } catch (error) {
    console.log(error);
  }
};
