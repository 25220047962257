/**
 * Reducer for user information
 */

import {
  SIGN_IN_SUCCESS,
  SIGN_IN_FAIL,
  SIGN_IN_FAIL_BLOCKED_USER,
  SIGN_OUT,
  SIGN_IN_NOT_CONFIRMED_EMAIL,
  GET_USER_DETAILS,
  UPDATE_USER,
  BLOCK_USER,
  GET_SUBSCRIPTIONS,
  SET_KYC_STATUS,
  SET_VALID_INSTRUMENT,
  SET_PENDING_CASHOUTS,
  GET_KYC_DETAILS,
  GET_LIMITS,
  GET_FORGOTTEN_PASSWORD,
  GET_BALANCE,
  GET_BALANCE_DETAILS,
  GET_WALLET_STATEMENT,
  RESET_WALLET_STATEMENT,
  LOAD_MORE_WALLET_STATEMENT,
  POST_WALLET_DEPOSIT,
  GET_ACCOUNT_LIVECHECK,
  POST_WITHDRAW,
  REGISTER_USER_WITH_TRUSTLY,
  CHECK_TRUSTLY_DEPOSIT_STATUS,
  SIGN_IN_USER_WITH_TRUSTLY_TOKEN,
  HANDLE_LIMITS_ALERT,
} from "../actions/types";
import { getTimeEquality } from "../components/account/Limits-dialogs/ConfirmLimitsAlert";

const jwt = localStorage.getItem("jwt");
const authedUser = localStorage.getItem("betworx_logged_in_user")
  ? JSON.parse(localStorage.getItem("betworx_logged_in_user"))
  : {
      isSignedIn: jwt ? true : false,
      emailConfirmed: true,
    };
const trustlyPayload = localStorage.getItem("trustly_register_action")
  ? JSON.parse(localStorage.getItem("trustly_register_action"))
  : {};

let INITIAL_STATE = {
  auth: authedUser,
  balanceDetails: {},
  liveCheck: {},
  kycCompleted: true,
  validInstrument: false,
  pendingCashouts: 0,
  trustlyPayload: trustlyPayload,
  trustlyDepositStatus: {},
  shouldConfirmLimits: false,
};

const fn = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HANDLE_LIMITS_ALERT:
      return { ...state, shouldConfirmLimits: action.payload };
    case SIGN_IN_SUCCESS:
      let getAuthState = { ...state };
      const { jwt, ...rest } = action.payload;
      getAuthState.auth = rest;
      getAuthState.auth.isSignedIn = true;

      localStorage.setItem(
        "betworx_logged_in_user",
        JSON.stringify(getAuthState.auth)
      );

      return getAuthState;

    case SIGN_IN_FAIL:
    case SIGN_OUT:
      let getAuthState2 = { ...state };
      getAuthState2.auth = {
        isSignedIn: false,
      };

      if (action.isManualSignOut) {
        getAuthState2.balanceDetails = {};
      }

      localStorage.removeItem("betworx_logged_in_user");

      return getAuthState2;

    case SIGN_IN_FAIL_BLOCKED_USER:
      let getAuthState4 = { ...state };
      getAuthState4.auth = action.payload;
      getAuthState4.auth.isSignedIn = false;
      getAuthState4.auth.isBlockedUser = true;

      return getAuthState4;

    case SIGN_IN_NOT_CONFIRMED_EMAIL:
      let getAuthState3 = { ...state };
      getAuthState3.auth = {
        emailConfirmed: false,
        email: action.email,
      };

      return getAuthState3;

    case GET_USER_DETAILS:
      let getUserState = { ...state };
      getUserState.profile = action.payload;
      return getUserState;

    case UPDATE_USER:
      return state;

    case BLOCK_USER:
      //maks: user should be logged out after self blocking
      return state;

    case GET_SUBSCRIPTIONS:
      let getUserSubscriptions = { ...state };
      getUserSubscriptions.subscriptions = action.payload;
      return getUserSubscriptions;

    // case UPDATE_SUBSCRIPTIONS:
    //     return state;

    case SET_KYC_STATUS:
      let setKeyState = { ...state };
      setKeyState.kycCompleted = action.payload;
      return setKeyState;

    case SET_VALID_INSTRUMENT:
      let setValidInstrument = { ...state };
      setValidInstrument.validInstrument = action.payload;
      return setValidInstrument;

    case SET_PENDING_CASHOUTS:
      let setPendingCashouts = { ...state };
      setPendingCashouts.pendingCashouts = action.payload;
      return setPendingCashouts;

    case GET_KYC_DETAILS:
      let getKycState = { ...state };
      getKycState.kyc = action.payload;
      return getKycState;

    case GET_LIMITS:
      let getLimitState = { ...state };
      getLimitState.limits = action.payload;

      const limitsData = action?.payload?.extraData&& Array.isArray(action?.payload?.extraData) ?  action.payload.extraData : [];
      const findLimitWithUpdate = limitsData.find((limit) => {
        if (
          limit &&
          limit?.newAmount &&
          limit?.amountEffectiveTime &&
          getTimeEquality(limit?.amountEffectiveTime)
        ) {
          return true;
        }
        return false;
      });

      if (findLimitWithUpdate) {
        getLimitState["shouldConfirmLimits"] = true;
      }

      return getLimitState;

    case GET_FORGOTTEN_PASSWORD:
      let getForgottenPasswordState = { ...state };
      getForgottenPasswordState.forgottenPassword = action.payload;
      return getForgottenPasswordState;

    case GET_BALANCE:
      let result1 = { ...state.balanceDetails };
      result1.balance = action.payload;

      return { ...state, balanceDetails: result1 };

    case GET_BALANCE_DETAILS:
      return { ...state, balanceDetails: action.payload };

    case GET_WALLET_STATEMENT:
      let data = action.payload;
      if (data.pageCount > 1) {
        data.loadMore = true;
      } else {
        data.loadMore = false;
      }

      return { ...state, statement: data };

    case LOAD_MORE_WALLET_STATEMENT:
      let payload = action.payload;
      if (payload.currentPage < payload.pageCount) {
        payload.loadMore = true;
      } else {
        payload.loadMore = false;
      }

      let items = [...state.statement.transactions];
      items = items.concat(payload.transactions);

      payload.transactions = items;

      return { ...state, statement: payload };

    case RESET_WALLET_STATEMENT:
      let result2 = { ...state };
      delete result2.statement;
      return result2;

    case POST_WALLET_DEPOSIT:
      return { ...state, deposit: action.payload };

    case POST_WITHDRAW:
      return { ...state, withdraw: action.payload };

    case GET_ACCOUNT_LIVECHECK:
      return { ...state, liveCheck: action.payload };

    case REGISTER_USER_WITH_TRUSTLY:
      return { ...state, trustlyPayload: action.payload };

    case CHECK_TRUSTLY_DEPOSIT_STATUS:
      return { ...state, trustlyDepositStatus: action.payload };

    case SIGN_IN_USER_WITH_TRUSTLY_TOKEN:
      return { ...state, trustlyDepositStatus: action.payload };

    default:
      return state;
  }
};

export default fn;
