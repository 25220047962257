import { Box, Grid2 } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBetbuilderGamesAsync } from "../../../../redux-store/sports-reducer";
import BetbuilderGameCard from "./game-card";
import { useLocation } from "react-router-dom";
import _ from "lodash";

const BetbuilderGamesList = ({ gamesList = [], leagueId }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  // selectors
  const lang = useSelector((state) => state.i18n.locale);

  useEffect(() => {
    if (!gamesList.length) {
      dispatch(getBetbuilderGamesAsync({ leagueId, lang }));
    }
  }, [leagueId, location.pathname]);

  const sortedGames = _.cloneDeep(gamesList).sort((a, b) => {
    const dateA = new Date(a.startTime);
    const dateB = new Date(b.startTime);
    return dateA - dateB;
  });

  return (
    <Box px={1}>
      <Grid2 container spacing={1}>
        {sortedGames.map((game) => (
          <Grid2 item size={{ xs: 12, sm: 6, md: 3, xl: 3 }} key={game.gameid}>
            <BetbuilderGameCard game={game} />
          </Grid2>
        ))}
      </Grid2>
    </Box>
  );
};

export default BetbuilderGamesList;
