/**
 * Payment Deposit component
 */
import { log } from "../../config/log";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Translate } from "react-redux-i18n";
import { withStyles } from "@mui/styles";
import paymentProviderConfig from "../../config/paymentProviderConfig";
import { postWalletDeposit, checkIfUserAllowedToDeposit } from "../../actions";
import hostConfig from "../../config/hostConfig";
import { AppBar, Container, InputAdornment } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import TextField from "@mui/material/TextField";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import AddIcon from "@mui/icons-material/Add";

import PaymentsModal from "./PaymentsModal";
import withRouter from "../../helpers/withRouter";

const styles = (theme) => ({
  root: {
    padding: "3em",
    "& .MuiContainer-root": {
      width: "100%",
      minHeight: "50vH",
      "@media (hover:none)": {
        padding: "0",
      },
    },
    "& .MuiButton-root": {
      margin: "1.5em 1em 0 0",
      fontSize: "1.45em",

      "@media (hover:none)": {
        width: "100%",
        fontSize: "1.2em",
      },
      "@media screen and (max-width: 680px)": {
        width: "100%",
        fontSize: "1.2em",
      },
    },
    "& .MuiFormControl-root": {
      margin: "1em 1em 0 0",
      width: "100%",
      "@media (hover:hover)": {
        width: "48%",
      },
      "@media screen and (max-width: 680px)": {
        width: "100%",
      },
    },
    "& .MuiFormLabel-root": {
      fontSize: "1.3em",
    },
    "& .MuiInput-root": {
      fontSize: "1.3em",
    },
    backgroundColor: "var(--color-main)",
    "& .MuiIconButton-root": {
      fontSize: "2em",
    },
    "& .MuiGrid-spacing-xs-2": {
      margin: "0",
    },
  },
  input: {
    width: "90%",
  },
  bottomMenu: {
    flexGrow: 1,
    width: "100%",
    zIndex: 1,
    "& .MuiAppBar-root": {
      backgroundColor: "var(--color-main)",
    },
  },
  depositText: {
    margin: "0 0 2em 0",
    fontSize: "1.5em",
  },
  featText: {
    textTransform: "uppercase",
    fontStyle: "italic",
    fontWeight: 500,
    color: "var(--color-contrast)",
  },
  radioGrid: {
    "& .MuiTypography-body2": {
      color: "var(--color-contrast)",
      fontSize: "1.5em",
    },
    "& .MuiTypography-body1": {
      fontSize: "1.3em",
    },
    border: "1px solid var(--color-contrast-3)",
    borderRadius: "0.5em",
    padding: "0.5em 1.5em 0",
    backgroundColor: "var(--bg-tabs-1)",
  },
  formText: {
    margin: "0 0 1em 0",
    // color: 'var(--color-input-amount)',
    fontSize: "1.5em",
  },
  ammountButton: {
    color: "var(--btn-text)",
    fontSize: "1.5em !important",
    margin: "0 1em !important",
    "@media (hover:none)": {
      width: "22% !important",
      fontSize: "1.5em",
    },
    "@media screen and (max-width: 680px)": {
      width: "18% !important",
      fontSize: "1.5em",
    },
    background:
      "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
  },
  ammountField: {
    "& .MuiOutlinedInput-input": {
      color: "var(--color-input-amount)",
      fontSize: "1.5em",
      textAlign: "center",
      width: "100%",
      "&::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "&::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  },
  form: {
    alignItems: "center",
    justifyContent: "center",
  },
});

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

class PaymentsDeposit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      merchantIndex: 0,
      slidingFlag: false,
      modalShow: false,
      modalUrl: null,
      modalParams: null,
      depositAmount: "0",
      depositAmountOption: "20",
      customDepositAmount: "",
      bonusCodeFlag: "no",
      bonusCode: "",
    };
  }

  componentDidMount() {
    const { auth, locale, navigate } = this.props;
    if (!auth.isSignedIn) navigate(`/${locale}/sign-in`);
    // this.props.checkIfUserAllowedToDeposit(this.constructor)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    /**
     * Select correct merchant page from page URL returning
     */
    if (
      newProps.op !== this.props.op ||
      newProps.type !== this.props.type ||
      newProps.method !== this.props.method ||
      newProps.transactionStatus !== this.props.transactionStatus
    ) {
      const { op, type, method } = newProps;
      let host = window.location.hostname
        ? window.location.hostname.replace(/\./g, "_").replace(/\-/g, "_")
        : "betv3_frontend_herokuapp_com";

      if (op && type === "deposit") {
        Object.entries(hostConfig[host].deposit)?.forEach((item) => {
          if (item[1] === method) {
            this.setState({ merchantIndex: parseInt(item[0]) });
          }
        });
      }
    }
  }

  componentDidUpdate() {
    const { auth, locale, navigate } = this.props;
    if (!auth.isSignedIn) navigate(`/${locale}/sign-in`);
  }

  renderAmountInput = ({
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
  }) => {
    const { classes } = this.props;

    return (
      <Input
        className={classes.input}
        margin="dense"
        // label="EUR"
        onChange={this.handleInputChange}
        onBlur={this.handleBlur}
        inputProps={{
          // step: 10,
          // min: 0,
          // max: 100,
          type: "number",
          "aria-labelledby": "input-slider",
        }}
        label={label}
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}
      />
    );
  };

  setAmount = (value) => {
    this.setState({ depositAmount: value });
  };

  onSubmit = (formValues) => {
    let host = window.location.hostname
      ? window.location.hostname.replace(/\./g, "_").replace(/\-/g, "_")
      : "betv3_frontend_herokuapp_com";

    let merchantKey = Object.values(hostConfig[host].deposit)[
      this.state.merchantIndex
    ];

    if (!this.state.depositAmount) return;

    let bonusCode =
      this.state.bonusCodeFlag === "no" ? 0 : this.state.bonusCode;

    this.props
      .postWalletDeposit(
        this.state.depositAmountOption === "or"
          ? this.state.customDepositAmount
          : this.state.depositAmount,
        merchantKey,
        bonusCode,
        this.constructor.name
      )
      .then(() => {
        if (
          !this.props.deposit ||
          !this.props.deposit.r ||
          !this.props.deposit.x
        ) {
          return;
        }

        this.setState({
          modalShow: true,
          modalUrl: this.props.deposit.r,
          modalParams: this.props.deposit.x,
        });
      });
  };

  valuetext(value) {
    return `${value}°C`;
  }

  handleSliderChange = (event, newValue) => {
    this.setState({ depositAmount: newValue, slidingFlag: true });
  };

  handleSliderChangeCommitted = (event, newValue) => {
    this.setState({ depositAmount: newValue, slidingFlag: false });
  };

  handleInputChange = (event) => {
    this.setState({
      depositAmount:
        event.target.value === "" ? "" : Number(event.target.value),
    });
  };

  handleBlur = () => {
    if (this.state.depositAmount < 0) {
      this.setState({ depositAmount: 0 });
    } else if (this.state.depositAmount > 100) {
    }
  };

  renderTabContent(item, key) {
    const { classes, op, type, transactionStatus, method, host } = this.props;

    let merchantName = item[1];
    let merchantSettings = paymentProviderConfig.deposit[merchantName];

    return (
      <div key={`key_${merchantName}`}>
        <form
          onSubmit={this.props.handleSubmit(this.onSubmit)}
          autoComplete="off"
        >
          <div className={classes.root}>
            {merchantName === method &&
              op &&
              type === "deposit" &&
              transactionStatus && (
                <Typography
                  variant="body2"
                  component="h2"
                  paragraph
                  style={{ textAlign: "center" }}
                >
                  {transactionStatus === "success" ? (
                    <Translate
                      value={`labels.operation_successful`}
                      style={{ color: "var(--green)", fontSize: 24 }}
                    />
                  ) : transactionStatus === "declined" ? (
                    <Translate
                      value={`labels.transaction_declined`}
                      style={{ color: "var(--red-1)", fontSize: 24 }}
                    />
                  ) : (
                    <Translate
                      value={`labels.transaction_fail_short`}
                      style={{ color: "var(--yellow)", fontSize: 24 }}
                    />
                  )}
                </Typography>
              )}
            <Grid item xs={12} className={classes.depositText}>
              <Translate value="labels.deposit_trustly_1" />
              <span className={classes.featText}>
                <Translate value="labels.deposit_trustly_2" />
              </span>
              <Translate value="labels.deposit_trustly_3" />
            </Grid>
            <div tyle={{ textAlign: "center" }}>
              <Grid container spacing={2} className={classes.form}>
                <Grid
                  item
                  xs={12}
                  style={{
                    margin: "1em 0 1em 0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={() =>
                      this.setAmount(parseInt(this.state.depositAmount) + 20)
                    }
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon sx= {{ width: "0.7em"}}/>}
                    size="large"
                  >
                    20€
                  </Button>
                  <Button
                    onClick={() =>
                      this.setAmount(parseInt(this.state.depositAmount) + 50)
                    }
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon sx= {{ width: "0.7em"}}/>}
                    size="large"
                  >
                    50€
                  </Button>
                  <Button
                    onClick={() =>
                      this.setAmount(parseInt(this.state.depositAmount) + 100)
                    }
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon sx= {{ width: "0.7em"}}/>}
                    size="large"
                  >
                    100€
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    margin: "1em 0 1em 0",
                  }}
                  container
                >
                  <Grid item xs={12} md={4}></Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="trustly_input"
                      onChange={(e) =>
                        this.setAmount(e.target.value ? e.target.value : 0)
                      }
                      variant="outlined"
                      color="primary"
                      size="small"
                      className={classes.ammountField}
                      value={this.state.depositAmount}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <EuroSymbolIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ margin: 0 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  <Translate value="labels.minimum_amount" />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                style={{ textAlign: "center", margin: "1em 0 0 0" }}
              >
                <Button
                  disabled={
                    parseInt(
                      this.state.depositAmount ? this.state.depositAmount : 0
                    ) < 10 ||
                    parseInt(
                      this.state.depositAmount ? this.state.depositAmount : 0
                    ) > 5000
                  }
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    color: "var(--btn-text)",
                    // background:
                    //   "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
                  }}
                  startIcon={<CreditCardIcon />}
                >
                  <Translate value="labels.deposit_now" />
                </Button>
                <Typography
                  variant="body2"
                  paragraph
                  style={{ margin: "2em 0 0 0" }}
                >
                  <Translate value="labels.deposit_terms_1" />
                  <Link to="/">
                    <Translate value="labels.deposit_terms_2" />
                  </Link>
                </Typography>
              </Grid>
            </div>
          </div>
        </form>
      </div>
    );
  }

  menuItemChange = (event, newValue) => {
    this.setState({ merchantIndex: newValue }, () => {
      let host = window.location.hostname
        ? window.location.hostname.replace(/\./g, "_").replace(/\-/g, "_")
        : "betv3_frontend_herokuapp_com";
      let merchantKey = Object.values(hostConfig[host].deposit)[
        this.state.merchantIndex
      ];
      if (merchantKey === "paysafecard") {
        this.setState({ depositAmount: "25", depositAmountOption: "25" });
      }
    });
  };

  menuItemChangeIndex = (newValue) => {
    this.setState({ merchantIndex: newValue }, () => {
      let host = window.location.hostname
        ? window.location.hostname.replace(/\./g, "_").replace(/\-/g, "_")
        : "betv3_frontend_herokuapp_com";
      let merchantKey = Object.values(hostConfig[host].deposit)[
        this.state.merchantIndex
      ];
      if (merchantKey === "paysafecard") {
        this.setState({ depositAmount: "25", depositAmountOption: "25" });
      }
    });
  };

  changeDepositAmountOption = (e) => {
    this.setState(
      { depositAmountOption: e.target.value, depositAmount: "" },
      () => {
        if (this.state.depositAmountOption !== "or") {
          this.setState({
            depositAmount: parseInt(this.state.depositAmountOption),
          });
          this.setState({ customDepositAmount: "" });
        }
      }
    );
  };

  changeBonusCodeOption = (e) => {
    this.setState({ bonusCodeFlag: e.target.value });
  };

  handleCustomInputChange = (event) => {
    this.setState({
      customDepositAmount:
        event.target.value === "" ? "" : Number(event.target.value),
      depositAmount:
        event.target.value === "" ? "" : Number(event.target.value),
    });
  };

  render() {
    log(this.constructor.name, "render");
    const { classes } = this.props;

    let host = window.location.hostname
      ? window.location.hostname.replace(/\./g, "_").replace(/\-/g, "_")
      : "betv3_frontend_herokuapp_com";

    return (
      <Container maxWidth="md">
        <div className={classes.bottomMenu} key="tab-menu">
          <AppBar position="static" elevation={0}>
            <Tabs
              value={this.state.merchantIndex}
              onChange={this.menuItemChange.bind(this)}
              // variant="fullWidth"
              indicatorColor="primary"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {Object.entries(hostConfig[host].deposit).map(
                (item, key) =>
                  item[1] !== "bankwire" && (
                    <Tab
                      key={key}
                      icon={
                        <img
                          style={{ height: "1.4em", margin: "2em 0 0 0.5em" }}
                          src={`https://api.nubet.com/media/icons/${
                            paymentProviderConfig.deposit[item[1]]?.icon
                          }`}
                          alt="item"
                        ></img>
                      }
                      {...a11yProps({ key })}
                    />
                  )
              )}
            </Tabs>
          </AppBar>
        </div>
        <SwipeableViews
          axis={"x"}
          index={this.state.merchantIndex}
          onChangeIndex={this.menuItemChangeIndex.bind(this)}
          disabled={this.state.slidingFlag}
        >
          {Object.entries(hostConfig[host].deposit).map((item, key) => {
            return this.renderTabContent(item, key);
          })}
        </SwipeableViews>
        {this.state.modalShow ? (
          <PaymentsModal
            open="true"
            url={this.state.modalUrl}
            params={this.state.modalParams}
          />
        ) : null}
      </Container>
    );
  }
}

const validate = (values, props) => {
  const errors = {};

  const requiredFields = ["amount"];

  requiredFields?.forEach((field) => {
    if (!values[field]) {
      errors[field] = props.translate.labels.field_required;
    }
  });

  return errors;
};

const mapStateToProps = (state) => ({
  initialValues: {
    amount: 50,
  },
  auth: state.user.auth,
  deposit: state.user.deposit,
  translate: state.i18n.translations[state.i18n.locale],
  host: state.settings.host,
  locale: state.i18n.locale,
});

export default withRouter(
  connect(mapStateToProps, {
    postWalletDeposit,
    checkIfUserAllowedToDeposit,
  })(
    reduxForm({
      form: "PaymentsDeposit",
      validate,
      enableReinitialize: true,
    })(withStyles(styles)(PaymentsDeposit))
  )
);
