/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconCH = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g>
					<path d="M828.8,738.2h-12.1c-14.5,0-26.2,11.7-26.2,26.2c0,14.5,11.7,26.2,26.2,26.2h366.5c14.5,0,26.2-11.7,26.2-26.2
		c0-14.5-11.7-26.2-26.2-26.2h-12.1l55.6-125.2c23.1-52.1-15.1-110.4-71.8-110.4h-76.5V424h26.2c43.3,0,78.5-35.2,78.5-78.5
		S1148,267,1104.7,267h-26.2v-26.2c0-43.3-35.2-78.5-78.5-78.5c-43.3,0-78.5,35.2-78.5,78.5V267h-26.2c-43.3,0-78.5,35.2-78.5,78.5
		S852,424,895.3,424h26.2v78.5h-76.5c-56.8,0-94.8,58.4-71.8,110.4L828.8,738.2z"/>
					<path d="M1130.9,1000c43.3,0,78.5-35.2,78.5-78.5s-35.2-78.5-78.5-78.5H869.1c-43.3,0-78.5,35.2-78.5,78.5s35.2,78.5,78.5,78.5
		H1130.9z"/>
					<path d="M1260.6,1418.9c7.2,0,11.9-7.5,8.9-14c-67-142.3-84.8-254.1-99.2-347.4c-0.9-5.7-6.4-9.3-12-8.1c-8.8,1.9-18,2.9-27.4,2.9
		c-238.9,0-269.8,1.5-289.4-2.8c-5.5-1.2-10.9,2.5-11.7,8.1c-12.1,79.9-28.9,197.6-99.2,347.2c-3.1,6.5,1.7,14,8.9,14v0H1260.6z"/>
					<path d="M1285.8,1303.7c-0.6,2.1-0.6,4.3,0.2,6.4c6.5,17,13.4,33.9,20.8,50.6c1.6,3.5,5.1,5.8,9,5.8h347.9c6.8,0,11.5-6.7,9.2-13.1
		c-27.5-78.1-47.6-158.6-60-240.5c-0.7-4.8-4.9-8.4-9.7-8.4c-41.8,0.4-222.2,0.4-264,0c-4.9,0-9,3.5-9.7,8.4v0
		C1319.7,1177.3,1305.2,1241.1,1285.8,1303.7z"/>
					<path d="M1430.1,1784.6c-27.1,3.2-834.2,1.8-860.7-0.1c-5.1-0.4-8.5,5-6.3,9.6c12.9,25.8,39.6,43.7,70.3,43.7h733
		c30.8,0,57.5-17.8,70.4-43.7C1439.3,1789.3,1435.4,1784,1430.1,1784.6z"/>
					<polygon points="569.4,1784.5 569.4,1784.5 569.4,1784.5 	" />
					<path d="M1830.5,1584.1L1830.5,1584.1c-1.7-2-1.4-1.5-45.1-34.3c-32.7-24.5-52.2-63.5-52.4-104.3c0-14.6-11.5-26.6-26.2-26.6
		h-372.1l0,0c1.1,2.1,2.9,3.6,5.1,4.3c31.2,10.8,52.9,40.4,52.9,74.2c0,47.6,19.4,91.5,51.1,123.2l4.8,4.8c1.8,1.8,4.3,2.9,6.9,2.9
		h356.1C1834.7,1628.3,1846.3,1600.5,1830.5,1584.1z"/>
					<path d="M1440.9,1692.8L1440.9,1692.8c-11.9-19.1-100.6-73.6-100.6-195.4c0-14.5-11.7-26.2-26.2-26.2H685.8
		c-14.5,0-26.2,11.7-26.2,26.2c0,52.7-20.1,93.1-20.4,93.9c-34.6,76.1-87,88.3-84.2,118.1c0.4,3.6,1.6,7.7,3.8,11.2
		c3,4.9,6.4,6.9,7.7,8c9.2,6.1-8.2,4.4,302.5,4.4c584.4,0,554.1,1.5,562.2-3.2c7-2.9,14.9-14,13.6-25.6
		C1444.9,1699.7,1441.7,1693.8,1440.9,1692.8z"/>
					<path d="M1340.3,1052.4h261.8c43.3,0,78.5-35.2,78.5-78.5c0-43.3-35.2-78.5-78.5-78.5h-36.6c53.3-32.1,89-90.5,89-157.1
		c0-101.1-82.2-183.3-183.3-183.3c-101.1,0-183.3,82.2-183.3,183.3c0,66.6,35.7,125,89,157.1h-36.6c-43.3,0-78.5,35.2-78.5,78.5
		S1297,1052.4,1340.3,1052.4z"/>
					<path d="M660.8,1104.5c-41.8,0.4-222.2,0.4-264,0c-4.9,0-9,3.5-9.8,8.3h0c-12,79.3-31.7,160-60.1,240.6c-2.3,6.4,2.4,13.1,9.2,13.1
		h347.9c3.9,0,7.4-2.3,9-5.8c7.4-16.7,14.3-33.6,20.8-50.6c0.8-2,0.8-4.3,0.2-6.4c-19.2-62.4-33.8-126.2-43.6-190.9
		C669.8,1108,665.7,1104.5,660.8,1104.5z"/>
					<path d="M611.8,1471.2c7.9-22.4,25.8-40.2,48.3-48c2.2-0.8,4.1-2.3,5.1-4.3H293.1c-14.6,0-26.1,12-26.2,26.6
		c-0.1,41.1-19.7,79.8-52.4,104.3c-45.6,34.2-42.6,31.8-44.3,33.4c-2.7,2.4-5.6,6.7-7,11.5c-5,17.4,8.1,33.6,25.1,33.6h356.1
		c2.6,0,5.2-1.1,7-2.9c26-26.4,33.7-40.3,42.5-61.1C613.4,1517.4,602.4,1497.2,611.8,1471.2z"/>
					<path d="M504.6,1688.9c1-4.2-2.1-8.2-6.3-8.2c-252,0-304.4,0.8-321.3-0.8c-5.2-0.5-8.8,4.9-6.5,9.5c12.9,25.8,39.6,43.6,70.3,43.6
		h257.5c4.2,0,7.4-3.9,6.4-8C501.8,1713,501.9,1700.6,504.6,1688.9z"/>
					<path d="M397.9,1052.4h261.8c43.3,0,78.5-35.2,78.5-78.5c0-43.3-35.2-78.5-78.5-78.5H623c53.3-32.1,89-90.5,89-157.1
		c0-101.1-82.2-183.3-183.3-183.3s-183.3,82.2-183.3,183.3c0,66.6,35.7,125,89,157.1h-36.6c-43.3,0-78.5,35.2-78.5,78.5
		S354.6,1052.4,397.9,1052.4z"/>
					<path d="M1822.9,1679.8c-16.9,1.6-69.3,0.9-321.3,0.8c-4.3,0-7.3,4-6.3,8.2c2.8,11.8,2.8,24.2-0.1,36.2c-1,4.1,2.2,8,6.4,8h257.5
		c30.8,0,57.5-17.8,70.3-43.6C1831.7,1684.7,1828.1,1679.3,1822.9,1679.8z"/>
				</g>
			</svg>
		</div>
	);
};

export default IconCH;
