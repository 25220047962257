/**
 * Promotions Page - English version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, Paper, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

const styles = (theme) => ({
  root: {
    padding: "1.8em",
    fontSize: "var(--content)",
    "@media (hover:none)": {},
  },
  listItems: {
    color: "var(--color-contrast)",
    fontSize: "1.2em",
    fontWeight: "bold",
  },
  promo: {
    height: "auto",
    width: "40%",
    "@media only screen and (max-width: 767px)": {
      width: "100%",
    },
  },
  promoGrid: {
    "@media (hover:none)": {
      padding: "1em 0 0 1em",
    },
  },
});

class PromotionsFreespins extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes, locale, auth, siteTheme } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)", height: "100%" }} maxWidth={false}>
        <Grid className={classes.promoGrid} container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Paper style={{ margin: "0.5em" }}>
              <img className={classes.promo} src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_cta_bookofpower_gif_478_230_1_${siteTheme}.gif`} alt="banner-casino-promo" />
              <Typography component="span">
                <div id="legalcontent" className={classes.root}>
                  <h1>Start playing at Nubet Casino with 100 free spins!</h1>
                  <br></br>
                  When you make your FIRST deposit at Nubet, you will instantly receive 100 free spins on Book of Power.<br></br>
                  <br></br>
                  The game offers a whopping 15.000x maximum payout!
                  <br></br>
                  <br></br>
                  <h3>How to get your free spins</h3>
                  <span className={classes.listItems}>1. </span>Deposit and open a Nubet account at the same time<br></br>
                  <br></br>
                  <span className={classes.listItems}>2. </span>Open the Book of Power game and the free spins will be waiting for you in the game<br></br>
                  <br></br>
                  <span className={classes.listItems}>3. </span>The winnings from free spins are subject to 10x wagering requirement.<br></br>
                  <br></br>
                  <div>
                    {!auth.isSignedIn ? (
                      <Link to={`/${locale}/register-switch`}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            margin: "2em 0",
                            color: "var(--btn-text)",
                            padding: "1em 1em",
                            fontSize: "1em",
                            background: "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
                          }}
                        >
                          DEPOSIT AND PLAY FREE SPINS
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled
                        style={{
                          margin: "2em 0",
                          color: "var(--btn-text)",
                          padding: "1em 1em",
                          fontSize: "1em",
                        }}
                      >
                        FOR NEW CUSTOMERS
                      </Button>
                    )}
                  </div>
                </div>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
    locale: state.i18n.locale,
    auth: state.user.auth,
    siteTheme: state.settings.theme,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(PromotionsFreespins));
