/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconSN = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g><path d="M1071.5,543.2c0,39.4-32,71.4-71.4,71.4c-39.4,0-71.4-32-71.4-71.4c0-39.4,32-71.4,71.4-71.4
	C1039.6,471.8,1071.5,503.8,1071.5,543.2z M1000.1,935.8c-39.4,0-71.4,32-71.4,71.4c0,39.4,32,71.4,71.4,71.4
	c39.4,0,71.4-32,71.4-71.4C1071.5,967.7,1039.6,935.8,1000.1,935.8z M857.4,1171.3c-39.4,0-71.4,32-71.4,71.4
	c0,39.4,32,71.4,71.4,71.4c39.4,0,71.4-32,71.4-71.4C928.8,1203.2,896.8,1171.3,857.4,1171.3z M571.9,1171.3
	c-39.4,0-71.4,32-71.4,71.4c0,39.4,32,71.4,71.4,71.4c39.4,0,71.4-32,71.4-71.4C643.3,1203.2,611.3,1171.3,571.9,1171.3z
	 M1142.9,1171.3c-39.4,0-71.4,32-71.4,71.4c0,39.4,32,71.4,71.4,71.4c39.4,0,71.4-32,71.4-71.4
	C1214.3,1203.2,1182.3,1171.3,1142.9,1171.3z M1428.4,1171.3c-39.4,0-71.4,32-71.4,71.4c0,39.4,32,71.4,71.4,71.4
	c39.4,0,71.4-32,71.4-71.4C1499.7,1203.2,1467.8,1171.3,1428.4,1171.3z M711.1,935.8c-39.4,0-71.4,32-71.4,71.4
	c0,39.4,32,71.4,71.4,71.4c39.4,0,71.4-32,71.4-71.4C782.5,967.7,750.5,935.8,711.1,935.8z M1289.2,935.8c-39.4,0-71.4,32-71.4,71.4
	c0,39.4,32,71.4,71.4,71.4c39.4,0,71.4-32,71.4-71.4C1360.6,967.7,1328.6,935.8,1289.2,935.8z M1000.1,1399.7
	c-39.4,0-71.4,32-71.4,71.4c0,39.4,32,71.4,71.4,71.4c39.4,0,71.4-32,71.4-71.4C1071.5,1431.6,1039.6,1399.7,1000.1,1399.7z
	 M711.1,1399.7c-39.4,0-71.4,32-71.4,71.4c0,39.4,32,71.4,71.4,71.4c39.4,0,71.4-32,71.4-71.4
	C782.5,1431.6,750.5,1399.7,711.1,1399.7z M425.6,1399.7c-39.4,0-71.4,32-71.4,71.4c0,39.4,32,71.4,71.4,71.4
	c39.4,0,71.4-32,71.4-71.4C497,1431.6,465,1399.7,425.6,1399.7z M1289.2,1399.7c-39.4,0-71.4,32-71.4,71.4c0,39.4,32,71.4,71.4,71.4
	c39.4,0,71.4-32,71.4-71.4C1360.6,1431.6,1328.6,1399.7,1289.2,1399.7z M1574.7,1399.7c-39.4,0-71.4,32-71.4,71.4
	c0,39.4,32,71.4,71.4,71.4c39.4,0,71.4-32,71.4-71.4C1646,1431.6,1614.1,1399.7,1574.7,1399.7z M857.4,703.8
	c-39.4,0-71.4,32-71.4,71.4c0,39.4,32,71.4,71.4,71.4c39.4,0,71.4-32,71.4-71.4C928.8,735.8,896.8,703.8,857.4,703.8z M1142.9,703.8
	c-39.4,0-71.4,32-71.4,71.4c0,39.4,32,71.4,71.4,71.4c39.4,0,71.4-32,71.4-71.4C1214.3,735.8,1182.3,703.8,1142.9,703.8z
	 M1862.1,1666.2c55.4-81.7,66.9-184.6,30.7-275.2c-14.6-36.6-56.1-54.4-92.7-39.8c-36.6,14.6-54.4,56.1-39.8,92.7
	c18.6,46.5,12.5,99.7-16.3,142.2c-28.8,42.5-74.9,66.9-126.5,66.9H382.8c-56.3,0-106.3-29.4-133.7-78.6
	c-27.5-49.3-26.2-107.4,3.5-155.4l617.3-999.5c28.1-45.5,76.8-72.7,130.2-72.7s102.1,27.2,130.2,72.7l456.6,739.2
	c20.7,33.5,64.7,43.9,98.2,23.2c33.5-20.7,43.9-64.7,23.2-98.2l-456.6-739.2c-54.3-87.9-148.4-140.4-251.7-140.4
	s-197.4,52.5-251.7,140.4l-617.3,999.5c-57.2,92.7-59.7,204.8-6.7,299.9c53,95,149.6,151.8,258.4,151.8h1234.6
	C1717.2,1795.8,1806.3,1748.6,1862.1,1666.2z"/></g>
			</svg>
		</div>
	);
};

export default IconSN;
