import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import sortGamesByTime from "../../../helpers/gameChronologicalsort";
import PropTypes from "prop-types";
import CatalogGameBox from "./CatalogGameBox";
import { getPlayerPropsGamesAsync } from "../../../redux-store/sports-reducer";
import { Box } from "@mui/material";

const PlayerPropsGamesList = ({ league }) => {
  // action dispatcher
  const dispatch = useDispatch();

  // selectors
  const marketSetConfig = useSelector((state) => state.settings.marketSets);
  const lang = useSelector((state) => state.i18n.locale);

  const loadGames = () => {
    let goalScorers = marketSetConfig[league.sportCode]["sidebets"]["goalscorer"];
    goalScorers = goalScorers ? goalScorers : [];
    const currentMarketSet = [...marketSetConfig[league.sportCode]["sidebets"]["player"], ...goalScorers];
    if (!league?.games) {
      dispatch(getPlayerPropsGamesAsync({ sportCode: league.sportCode, leagueId: league.leagueId, marketSet: currentMarketSet, goalScorers, lang }));
    }
  };

  useEffect(loadGames, [league.leagueId]);

  let games = league && league?.games ? [...league.games] : [];
  games = sortGamesByTime(games);

  return (
    <Box>
      {games.map((game, key) => (
        <CatalogGameBox key={game.gameid} game={game} />
      ))}
    </Box>
  );
};

PlayerPropsGamesList.propTypes = {
  league: PropTypes.object,
};

PlayerPropsGamesList.defaultProps = {
  league: {},
};

export default PlayerPropsGamesList;
