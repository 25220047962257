// betbuilder loader
// REACT hooks
import { useEffect } from "react";
// @MUI components
import { Box, IconButton, Stack, styled, Typography } from "@mui/material";
// REDUX utils
import { useDispatch, useSelector } from "react-redux";
import { emptySlipTemplate, saveBet } from "../../../actions";
import { getBetbuilderBetAsync, getSingleGameAsync } from "../../../redux-store/sports-reducer";
// CONFIGs
import sportCastConfig from "../../../config/sportcast";
// router utils
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// components
import Iconify from "../../iconify";
import { format } from "date-fns";
import { toggleMyTicketsPanel } from "../../../redux-store/settings-reducer";
import SportsLayout from "../@components/SportsLayout";

const StyledIFrame = styled(Box)(({ theme }) => ({
  border: 0,
  width: "100%",
  height: "83dvh",
  [theme.breakpoints.down("sm")]: {
    height: "69dvh",
  },
}));

const BetBuilderLoaderPage = () => {
  // url params
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // action dispatcher
  const dispatch = useDispatch();

  // state selectors
  const sportCasturl = useSelector((state) => state.settings.host.sportcast_url);
  const brand = useSelector((state) => state.settings.theme);
  const locale = useSelector((state) => state.i18n.locale);
  const slipTemplate = useSelector((state) => state.selected.slipTemplate);
  const bets = useSelector((state) => state.slips[slipTemplate]["settings"]["bets"]);
  const layout = useSelector((state) => state.settings.layout);
  const selectedSlipTemplate = useSelector((state) => state.selected.slipTemplate);
  const game = useSelector((state) => state.sports.betbuilder.singleGame);
  // constants
  const gameId = params.gameId;
  const leagueId = searchParams.get("league");

  function isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const handleAddBet = (res) => {
    const selectedGame = JSON.parse(localStorage.getItem("betbuilder-game"));
    const data = res?.payload ? res.payload : {};

    let fixtureId = data?.ConsumerFixtureId?.split(":")[2] || data?.FixtureId;
    if (Number(selectedGame?.gameid) !== Number(fixtureId)) return;
    let payload = {
      game: selectedGame,
      gameId: selectedGame?.gameid,
      consumerFixtureId: data?.FixtureId || data?.ConsumerFixtureId,
      isLive: false,
      isBetBuilder: true,
      marketId: undefined,
      betslipUid: data?.BetSlipUid,
      price: data?.PriceDetails?.DecimalPriceRounded,
      isActive: true,
      hc: "",
      selectionId: data?.BetSlipUid,
      description: data?.Description,
      translations: data?.Translations,
      identifier: data?.Identifier,
      marketDescription: data?.LegDetails,
      isMultiBet: 0,
      sn: data?.Description?.toString(),
    };
    dispatch(emptySlipTemplate(0));
    dispatch(toggleMyTicketsPanel(false));
    dispatch(saveBet(payload, selectedSlipTemplate, "BetbuilderView"));
    if (layout === "touch") {
      navigate(`/${locale}/slip`);
    }
    return Object.keys(payload)?.length === 0 ? false : true;
  };

  const handleBuilderResponse = (e) => {
    if (!isJson(e?.data)) return;
    const betData = e?.data ? JSON.parse(e.data) : {};
    const betValues = bets ? Object.values(bets) : [];
    const isBetExist = betValues.find((bet) => bet.betslipUid === betData?.betslipUid);
    if (betData?.command === "addToBetslip" && !isBetExist) {
      const callback = (res) => handleAddBet(res);
      dispatch(getBetbuilderBetAsync({ betslipUid: betData?.betslipUid, callback }));
    }
  };

  const goBack = () => {
    navigate(`/${locale}/betbuilder?sport-code=${game?.sport_code}`);
  };

  useEffect(() => {
    if (leagueId && gameId) {
      dispatch(getSingleGameAsync({ leagueID: leagueId, gameID: gameId, type: "betbuilder" }));
    }
  }, [leagueId, gameId]);

  useEffect(() => {
    window.addEventListener("message", handleBuilderResponse);
    return () => {
      window.removeEventListener("message", handleBuilderResponse);
    };
  }, [game?.sport_code]);

  return (
    <SportsLayout>
      <Box>
        <Stack direction="row" alignItems="center" justifyContent="start" gap={2} px={1} py={1}>
          <IconButton onClick={goBack}>
            <Iconify icon="ic:round-arrow-back" />
          </IconButton>
          <Stack>
            <Stack>
              <Typography variant="string" align="left">
                {game?.team1}
              </Typography>
              <Typography variant="string" align="left">
                {game?.team2}
              </Typography>
            </Stack>
            <Typography variant="string" color="text.secondary">
              {game?.startTime ? format(new Date(game.startTime), "dd.MM. HH:mm") : null}
            </Typography>
          </Stack>
        </Stack>
        {sportCasturl && (
          <StyledIFrame
            component="iframe"
            title="Betbuilder"
            src={`${sportCasturl}/markets?key=${sportCastConfig.WIDGET_KEY}&fixtureid=${params.gameId}&consumerfixtureid=sr:match:${params.gameId}&brand=nubet-${brand}${
              locale !== "en" ? `&culture=${locale}-${locale === "et" ? "EE" : "FI"}` : ""
            }`}
          />
        )}
      </Box>
    </SportsLayout>
  );
};

export default BetBuilderLoaderPage;
