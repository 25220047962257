/**
 * Payment withdraw page component
 */
import { log } from "../../config/log";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Translate } from "react-redux-i18n";
import { withStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { AppBar, Container, InputAdornment } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import AddIcon from "@mui/icons-material/Add";

import {
  postSkrillWithdraw,
  postBankwireWithdraw,
  postTrustlyWithdraw,
  checkKycStatus,
  checkValidInstrument,
  checkPendingCashout,
} from "../../actions";
import FreeSpinsAlert from "./FreeSpinsAlert";

const styles = (theme) => ({
  root: {
    padding: "3em",
    "& .MuiContainer-root": {
      width: "100%",
      minHeight: "50vH",
      "@media (hover:none)": {
        padding: "0",
      },
    },
    "& .MuiButton-root": {
      margin: "1.5em 1em 0 0",
      fontSize: "1.45em",

      "@media (hover:none)": {
        width: "100%",
        fontSize: "1.2em",
      },
      "@media screen and (max-width: 680px)": {
        width: "100%",
        fontSize: "1.2em",
      },
    },
    "& .MuiFormControl-root": {
      margin: "1em 1em 0 0",
      width: "100%",
      "@media (hover:hover)": {
        width: "48%",
      },
      "@media screen and (max-width: 680px)": {
        width: "100%",
      },
    },
    "& .MuiFormLabel-root": {
      fontSize: "1.3em",
    },
    "& .MuiInput-root": {
      fontSize: "1.3em",
    },
    backgroundColor: "var(--color-main)",
    "& .MuiIconButton-root": {
      fontSize: "2em",
    },
    "& .MuiGrid-spacing-xs-2": {
      margin: "0",
    },
  },
  form: {
    "& .MuiTextField-root": {
      width: "100%",
      margin: "1em 0 0 0",
    },
    "& .MuiSlider-root": {
      margin: "0.5em 0 3em 0.55em",
      width: "97%",
    },
  },
  input: {
    width: "100%",
    "& .MuiInputBase-inputMarginDense": {
      margin: "1em 0 0 0",
    },
  },
  bottomMenu: {
    flexGrow: 1,
    width: "100%",
    zIndex: 1,
    "& .MuiAppBar-root": {
      backgroundColor: "var(--color-main)",
    },
  },
  depositText: {
    margin: "0 0 2em 0",
    fontSize: "1.5em",
  },
  featText: {
    textTransform: "uppercase",
    fontStyle: "italic",
    fontWeight: 500,
    color: "var(--color-contrast)",
  },
  radioGrid: {
    "& .MuiTypography-body2": {
      color: "var(--color-contrast)",
      fontSize: "1.5em",
    },
    "& .MuiTypography-body1": {
      fontSize: "1.3em",
    },
    border: "1px solid var(--color-contrast-3)",
    borderRadius: "0.5em",
    padding: "0.5em 1.5em 0",
    backgroundColor: "var(--bg-tabs-1)",
  },
  formText: {
    margin: "0 0 1em 0",
    // color: 'var(--color-input-amount)',
    fontSize: "1.5em",
  },
  ammountButton: {
    color: "var(--btn-text)",
    fontSize: "1.5em !important",
    margin: "0 1em !important",
    "@media (hover:none)": {
      width: "22% !important",
      fontSize: "1.5em",
    },
    "@media screen and (max-width: 680px)": {
      width: "18% !important",
      fontSize: "1.5em",
    },
    background:
      "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
  },
  ammountField: {
    "& .MuiOutlinedInput-input": {
      color: "var(--color-input-amount)",
      fontSize: "1.5em",
      textAlign: "center",
      width: "100%",
      "&::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "&::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  },
});

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

class PaymentsWithdraw extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      slidingFlag: false,
      withdrawAmount: "0",
      withdrawAmountOption: "20",
      customWithdrawAmount: "",
      isAlertOpen: false,
    };

    this.child = React.createRef();
  }

  setAmount = (value) => {
    this.setState({ withdrawAmount: value });
  };

  /**
   * Check KYC status, instrument validation and pending cashout status
   */

  UNSAFE_componentWillMount() {
    const { auth } = this.props;
    const email = auth.email;

    // this.props.checkKycStatus(email);
    // this.props.checkValidInstrument(email, "skrill");
    // this.props.checkPendingCashout(email, "skrill");
  }

  /**
   * Submit withdraw request
   * Skill and BankWire request
   */

  onSubmit = (isConfirmed) => {
    const { withdrawAmount } = this.state;
    const { hasPromoValue } = this.props;
    if (!withdrawAmount) return;
    if (hasPromoValue && isConfirmed !== "confirmed") {
      this.handlePromoAlert();
      return;
    }

    if (this.state.isAlertOpen) {
      this.handlePromoAlert();
    }
    this.props.postTrustlyWithdraw(
      this.state.withdrawAmountOption === "or"
        ? this.state.customWithdrawAmount
        : withdrawAmount,
      "trustly",
      0,
      this.constructor.name
    );
  };

  valuetext(value) {
    return `${value}°C`;
  }

  handleSliderChange = (event, newValue) => {
    this.setState({ withdrawAmount: newValue, slidingFlag: true });
  };

  handleSliderChangeCommitted = (event, newValue) => {
    this.setState({ withdrawAmount: newValue, slidingFlag: false });
  };

  handleInputChange = (event) => {
    this.setState({
      withdrawAmount:
        event.target.value === "" ? "" : Number(event.target.value),
    });
    //setValue(event.target.value === '' ? '' : Number(event.target.value));
  };

  handleBlur = () => {
    if (this.state.withdrawAmount < 0) {
      this.setState({ withdrawAmount: 0 });
      //   setValue(0);
    } else if (this.state.withdrawAmount > 100) {
      //   setValue(100);
      this.setState({ withdrawAmount: 100 });
    }
  };

  //handleWithdraw Alert for promo balance and free spins

  handlePromoAlert = () => {
    this.setState({ isAlertOpen: !this.state.isAlertOpen });
  };

  /**
   * Render withdraw request form
   */

  renderTabContent(item, key) {
    const { classes, kycCompleted, host, pendingCashouts } = this.props;

    // const marks = [

    //   {
    //     value: 20,
    //     label: '20',
    //   },
    //   {
    //     value: 50,
    //     label: '50',
    //   },
    //   {
    //     value: 75,
    //     label: '75',
    //   },
    //   {
    //     value: 100,
    //     label: '100',
    //   }
    // ];

    switch (key) {
      case 2:
        return (
          <Container maxWidth="md" key={key}>
            <div className={classes.bottomMenu}>
              <AppBar position="static" color="primary" elevation={0}>
                <Tabs
                  value={this.state.value}
                  // variant="fullWidth"
                  indicatorColor="primary"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab
                    icon={
                      <img
                        style={{ height: "1.4em", margin: "2em 0 0 0.5em" }}
                        src={`https://api.nubet.com/media/icons/trustly.svg`}
                        alt="Trustly icon"
                      ></img>
                    }
                    aria-label="Trustly"
                    {...a11yProps(0)}
                  />
                </Tabs>
              </AppBar>
            </div>
            <form className={classes.form} autoComplete="off">
              <div className={classes.root}>
                {!kycCompleted && (
                  <Typography
                    variant="body2"
                    color="error"
                    paragraph
                    style={{ whiteSpace: "pre-line" }}
                  >
                    <Translate value={"labels.whithdraw_verify_account_1"} />
                    <Translate value={"labels.document"} />
                    <Translate
                      value={"labels.whithdraw_verify_account_2"}
                    />{" "}
                    <a
                      href="mailto:support@domain.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      support@domain.com
                    </a>
                    <br></br>
                  </Typography>
                )}
                {pendingCashouts > 0 && (
                  <Typography
                    variant="body2"
                    color="error"
                    paragraph
                    style={{ whiteSpace: "pre-line" }}
                  >
                    <Translate
                      value={"labels.x_pending_withdraws"}
                      dangerousHTML
                      pendingCashouts={pendingCashouts}
                      merchant={
                        this.state.value === 0 ? "Skrill" : "Bank Transfer"
                      }
                    />
                    <br></br>
                  </Typography>
                )}
                <Grid item xs={12} className={classes.depositText}>
                  <Translate value="labels.withdraw_trustly_1" />
                  <span className={classes.featText}>
                    {" "}
                    <Translate value="labels.withdraw_trustly_2" />
                  </span>
                  <Translate value="labels.withdraw_trustly_3" />
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      margin: "1em 0 1em 0",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={() =>
                        this.setAmount(parseInt(this.state.withdrawAmount) + 25)
                      }
                      variant="contained"
                      startIcon={<AddIcon sx= {{ width: "0.7em"}}/>}
                      color="primary"
                      size="large"
                    >
                      25€
                    </Button>
                    <Button
                      onClick={() =>
                        this.setAmount(parseInt(this.state.withdrawAmount) + 50)
                      }
                      variant="contained"
                      startIcon={<AddIcon sx= {{ width: "0.7em"}}/>}
                      color="primary"
                      size="large"
                    >
                      50€
                    </Button>
                    <Button
                      onClick={() =>
                        this.setAmount(
                          parseInt(this.state.withdrawAmount) + 100
                        )
                      }
                      variant="contained"
                      startIcon={<AddIcon sx= {{ width: "0.7em"}}/>}
                      color="primary"
                      size="large"
                    >
                      100€
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ margin: "1em 0 1em 0" }}
                    container
                  >
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={4}>
                      {" "}
                      <TextField
                        id="trustly_input"
                        onChange={(e) =>
                          this.setAmount(e.target.value ? e.target.value : 0)
                        }
                        variant="outlined"
                        color="primary"
                        size="small"
                        className={classes.ammountField}
                        inputProps={{ type: "number" }}
                        value={this.state.withdrawAmount}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <EuroSymbolIcon fontSize="small" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}></Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ margin: 0 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "auto",
                      textAlign: "center",
                    }}
                  >
                    <Translate value="labels.maximum_withdraw" />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ textAlign: "center", margin: "1em 0 0 0" }}
                >
                  <Button
                    disabled={
                      parseInt(
                        this.state.withdrawAmount
                          ? this.state.withdrawAmount
                          : 0
                      ) < 25 ||
                      parseInt(
                        this.state.withdrawAmount
                          ? this.state.withdrawAmount
                          : 0
                      ) > 5000 ||
                      parseInt(
                        this.state.withdrawAmount
                          ? this.state.withdrawAmount
                          : 0
                      ) > this.props.cash
                    }
                    type="button"
                    onClick={this.onSubmit}
                    variant="contained"
                    color="primary"
                    style={{
                      color: "var(--btn-text)",
                      // background:
                      //   "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
                    }}
                    startIcon={<CreditCardIcon />}
                  >
                    <Translate value="labels.withdraw_title" />
                  </Button>
                  <Typography
                    variant="body2"
                    paragraph
                    style={{ margin: "2em 0 0 0" }}
                  >
                    <Translate value="labels.deposit_terms_1" />
                    <Link to="/">
                      <Translate value="labels.deposit_terms_2" />
                    </Link>
                  </Typography>
                </Grid>
              </div>
            </form>
          </Container>
        );
      default:
        break;
    }
  }

  menuItemChange = (event, newValue) => {
    this.setState({ value: newValue }, () => {
      const { auth } = this.props;
      const email = auth.email;

      if (newValue !== 1 && newValue !== 2) {
        this.props.checkValidInstrument(email, "skrill");
      }

      // this.props.checkValidInstrument(email, newValue === 0 ? 'skrill' : 'bankwire');
      this.props.checkPendingCashout(
        email,
        newValue === 2 ? "trustly" : "bankwire"
      );
    });
  };

  menuItemChangeIndex = (newValue) => {
    this.setState({ value: newValue }, () => {
      const { auth } = this.props;
      const email = auth.email;

      if (newValue !== 1) {
        this.props.checkValidInstrument(email, "skrill");
      }

      // this.props.checkValidInstrument(email, newValue === 0 ? 'skrill' : 'bankwire');
      this.props.checkPendingCashout(
        email,
        newValue === 0 ? "skrill" : "bankwire"
      );
    });
  };

  changeWithdrawAmountOption = (e) => {
    this.setState(
      { withdrawAmountOption: e.target.value, withdrawAmount: "" },
      () => {
        if (this.state.withdrawAmountOption !== "or") {
          this.setState({
            withdrawAmount: parseInt(this.state.withdrawAmountOption),
          });
          this.setState({ customWithdrawAmount: "" });
        }
      }
    );
  };

  /**
   * Get withdraw amount from input box
   */

  handleCustomInputChange = (event) => {
    this.setState({
      customWithdrawAmount:
        event.target.value === "" ? "" : Number(event.target.value),
      withdrawAmount:
        event.target.value === "" ? "" : Number(event.target.value),
    });
  };

  render() {
    log(this.constructor.name, "render");

    return (
      <div>
        <FreeSpinsAlert
          open={this.state.isAlertOpen}
          handleAlert={this.handlePromoAlert}
          onConfirm={this.onSubmit}
        />
        {this.renderTabContent("", 2)}
      </div>
    );
  }
}

const validate = (values, props) => {
  const errors = {};

  const requiredFields = ["amount"];

  requiredFields?.forEach((field) => {
    if (!values[field]) {
      errors[field] = props.translate.labels.field_required;
    }
  });

  return errors;
};

const mapStateToProps = (state) => {
  const transactions = state.casino.bonuses?.transactions
    ? state.casino.bonuses?.transactions
    : [];
  let freeSpinGamesCount = 0;

  transactions?.forEach((transaction) => {
    if (transaction && transaction?.games && transaction?.games?.length) {
      freeSpinGamesCount += transaction.games?.length;
    }
  });

  return {
    initialValues: {
      amount: 0,
    },
    auth: state.user.auth,
    withdraw: state.user.withdraw,
    kycCompleted: state.user.kycCompleted,
    validInstrument: state.user.validInstrument,
    pendingCashouts: state.user.pendingCashouts,
    translate: state.i18n.translations[state.i18n.locale],
    host: state.settings.host,
    hasPromoValue:
      state.user.balanceDetails.promo > 0 || freeSpinGamesCount > 0,
    cash: state.user.balanceDetails.availableWithdrawal,
  };
};

export default connect(mapStateToProps, {
  postSkrillWithdraw,
  postTrustlyWithdraw,
  postBankwireWithdraw,
  checkKycStatus,
  checkValidInstrument,
  checkPendingCashout,
})(
  reduxForm({
    form: "PaymentsWithdraw",
    validate,
    enableReinitialize: true,
  })(withStyles(styles)(PaymentsWithdraw))
);
