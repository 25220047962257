import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBetbuilderBanners, getFeaturedGamesBanners, getOutrightsBanners } from "./_requests";
import { calcGame, updateOdd, updateOutrightOdd } from "../../helpers/gameFunctions";
import _ from "lodash";

const initialState = {
  games: [],
  isGamesLoading: false,
  outrights: {},
  isOutrightsLoading: false,
  precanned: {},
  isPrecannedLoading: false,
  slip: {},
};

// THUNKS
export const getBetbuilderBannersAsync = createAsyncThunk("banners/getBetbuilderBannersAsync", getBetbuilderBanners);
export const getOutrightsBannerAsync = createAsyncThunk("banners/getOutrightsBannersAsync", getOutrightsBanners);
export const getFeaturedGamesBannersAsync = createAsyncThunk("banners/getFeaturedGamesBannersAsync", getFeaturedGamesBanners);

export const bannersSlice = createSlice({
  name: "slice/banners",
  initialState,
  reducers: {
    updateFeaturedOutrights: (state, { payload }) => {
      const stateCopy = { ...state };
      let outrightLeagues = Object.values(stateCopy.outrights);
      const newOdds = payload;

      if (outrightLeagues?.length && newOdds?.length) {
        outrightLeagues?.forEach((outrights) => {
          Object.values(outrights)?.forEach((outright) => {
            outright.odds?.forEach((odd) => {
              updateOutrightOdd(outright, outright.gameid, odd, newOdds);
            });
          });
        });
      }
      state = stateCopy;
    },
    updateFeaturedGames: (state, { payload }) => {
      const stateCopy = { ...state };
      let leagues = Object.values(stateCopy.games);

      const newOdds = payload;

      if (leagues?.length && newOdds?.length) {
        leagues?.forEach((games) => {
          Object.values(games)?.forEach((game) => {
            game?.odds?.forEach((odd) => {
              updateOdd(game, game.gameid, odd, newOdds);
            });
          });
        });
      }
      state = stateCopy;
    },
  },
  extraReducers: (builder) => {
    builder
      // GET BETBUILDER BANNERS
      .addCase(getBetbuilderBannersAsync.pending, (state) => {
        state.isPrecannedLoading = true;
      })
      .addCase(getBetbuilderBannersAsync.rejected, (state) => {
        state.isPrecannedLoading = false;
      })
      .addCase(getBetbuilderBannersAsync.fulfilled, (state, { payload }) => {
        const payloadCopy = payload && typeof payload === "object" ? _.cloneDeep(payload) : {};
        const updatedPayload = {};
        Object.keys(payloadCopy).forEach((bannerKey) => {
          const bannerData = payloadCopy[bannerKey];
          Object.keys(bannerData).forEach((eventKey) => {
            const eventData = bannerData[eventKey];
            const updatedEventData = calcGame(eventData);
            if (!updatedPayload[bannerKey]) {
              updatedPayload[bannerKey] = {};
            }
            updatedPayload[bannerKey] = {
              ...updatedPayload[bannerKey],
              [eventKey]: updatedEventData,
            };
          });
        });
        state.isPrecannedLoading = false;
        state.precanned = updatedPayload || {};
      })
      // GET GAMES BANNERS
      .addCase(getFeaturedGamesBannersAsync.pending, (state) => {
        state.isGamesLoading = true;
      })
      .addCase(getFeaturedGamesBannersAsync.rejected, (state) => {
        state.isGamesLoading = false;
      })
      .addCase(getFeaturedGamesBannersAsync.fulfilled, (state, { payload }) => {
        state.isGamesLoading = false;
        state.games = typeof payload === "object" ? payload : [];
      })
      // GET OUTRIGHTS BANNERS
      .addCase(getOutrightsBannerAsync.pending, (state) => {
        state.isOutrightsLoading = true;
      })
      .addCase(getOutrightsBannerAsync.rejected, (state) => {
        state.isOutrightsLoading = false;
      })
      .addCase(getOutrightsBannerAsync.fulfilled, (state, { payload }) => {
        let allOutrights = { ...payload };
        Object.keys(allOutrights)?.forEach((key) => {
          const leagueGroup = allOutrights[key];
          Object.keys(leagueGroup)?.forEach((gameKey) => {
            const game = leagueGroup[gameKey];
            allOutrights[key][gameKey] = calcGame(game);
          });
        });
        state.isOutrightsLoading = false;
        state.outrights = allOutrights;
      });
  },
});

export const { updateFeaturedGames, updateFeaturedOutrights } = bannersSlice.actions;

export default bannersSlice.reducer;
