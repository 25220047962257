import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTicket, getTicketGames, getTickets, postTicket } from "./_requests";
import { Translate } from "react-redux-i18n";
import { calcGameTime, calcScoreSet, randomHexCode, updateScore } from "../../helpers/gameFunctions";

const initialState = {
  isTicketsLoading: false,
  limit: 20,
  selectedTicketGames: [],
  isSelectedGamesLoading: false,
  isPostTicketLoading: false,
  isTicketPosted: false,
  postedTicket: {},
  list: [],
  pendingList: [],
  count: 0,
  selected: [],
  calcCashout: [],
  selectedTicketGames: [],
};

export const getTicketGamesAsync = createAsyncThunk("thunk/getTicketGames", getTicketGames);
export const getTicketsAsync = createAsyncThunk("thunk/getTickets", getTickets);
export const getTicketAsync = createAsyncThunk("thunk/getTicket", getTicket);
export const postTicketAsync = createAsyncThunk("thunk/postTicket", postTicket);

const ticketsSlice = createSlice({
  name: "slice/tickets",
  initialState,
  reducers: {
    removePostedTicketState: (state) => {
      state.isTicketPosted = false;
      state.postedTicket = {};
    },
    updateOddsInTicket: (state, { payload }) => {
      const odds = payload;
      if (state.calcCashout) {
        state.calcCashout?.forEach((ticket) => {
          ticket.bets?.forEach((bet) => {
            bet = updateCashOutPrice(bet, odds);
          });
        });
      }
    },
    updateScoresInTicket: (state, { payload }) => {
      if (state.selected) {
        state.selected?.forEach((ticket) => {
          ticket.bets?.forEach((bet) => {
            updateScore(bet.game, payload);
          });
        });
      }
    },
  },
  extraReducers: (builder) => {
    // GET TICKET GAMES
    builder
      .addCase(getTicketGamesAsync.pending, (state) => {
        state.isSelectedGamesLoading = true;
      })
      .addCase(getTicketGamesAsync.rejected, (state) => {
        state.isSelectedGamesLoading = false;
      })
      .addCase(getTicketGamesAsync.fulfilled, (state, { payload }) => {
        state.selectedTicketGames = payload;
        state.isSelectedGamesLoading = false;
      })
      // GET TICKETS
      .addCase(getTicketsAsync.pending, (state) => {
        state.isSelectedGamesLoading = true;
      })
      .addCase(getTicketsAsync.rejected, (state) => {
        state.isSelectedGamesLoading = false;
      })
      .addCase(getTicketsAsync.fulfilled, (state, { payload }) => {
        //   count
        let tickets = payload?.data && typeof payload?.data === "object" ? payload?.data : [];
        let winLabel = "";
        let winValue = "";
        let chipColor = "";
        let chipLabel = "";
        let betTypeLabel = "";
        let betsCount = null;
        let combiTypes = "";
        let arrCombiTypes = [];
        let arrCombiTypesAfterBanks = [];

        tickets?.forEach((ticket) => {
          switch (Number(ticket.gameEngineID)) {
            case 1:
              betTypeLabel = <Translate value="labels.bett_single" />;
              break;

            case 2:
              betTypeLabel = (
                <span>
                  <Translate value="labels.bett_comb" /> {ticket.betsCount}
                </span>
              );
              break;

            case 3:
              betTypeLabel = "";

              if (ticket.totalBanks > 0) betTypeLabel += ticket.totalBanks + "B + ";

              betsCount = ticket.betsCount - ticket.totalBanks;
              arrCombiTypes = ticket.combiTypes?.split(",");
              arrCombiTypesAfterBanks = [];
              arrCombiTypes?.forEach((item, index) => {
                let key = Number(item) - ticket.totalBanks;
                if (key > 0) arrCombiTypesAfterBanks.push(key);
              });
              combiTypes = arrCombiTypesAfterBanks.join();

              betTypeLabel = (
                <span>
                  {betTypeLabel}
                  {combiTypes} <Translate value="labels.bett_of" /> {betsCount} = {ticket.combiCount} <Translate value="labels.combinations" />
                </span>
              );
              // betTypeLabel = ticket.specialLabel === 'multibet' ? <span><Translate value="labels.bett_comb_row" /> ({ticket.combiCount} <Translate value="labels.bett_row" />)</span> : betTypeLabel;
              // betTypeLabel = ticket.specialLabel === 'multibet' ? <span><Translate value="labels.bett_comb_row" /></span> : betTypeLabel;

              break;

            default:
              break;
          }

          ticket["betTypeLabel"] = betTypeLabel;

          switch (ticket.status) {
            case "pending":
              winLabel = <Translate value="labels.ticket_win_chance" />;
              winValue = ticket.potentialWin;
              chipColor = "";
              chipLabel = "";
              break;

            case "paid":
              if (ticket.cashedOut === 1) {
                winLabel = <Translate value="tickets.win_cashout" />;
                winValue = ticket.won;
                chipColor = "var(--green)";
                chipLabel = <Translate value="tickets.paid_cashout" />;
              } else {
                winLabel = <Translate value="tickets.paid" />;
                winValue = ticket.won;
                chipColor = "var(--green)";
                chipLabel = <Translate value="tickets.paid" />;
              }
              break;

            case "lost":
              winLabel = "";
              winValue = "";
              chipColor = "var(--red)";
              chipLabel = <Translate value="tickets.lost" />;
              break;

            case "cancelled":
              winLabel = "";
              winValue = "";
              chipColor = "var(--yellow-1)";
              chipLabel = <Translate value="tickets.cancelled" />;
              break;

            default:
              winLabel = "";
              winValue = "";
              chipColor = "";
              chipLabel = "";
              break;
          }

          ticket["winLabel"] = winLabel;
          ticket["winValue"] = winValue;
          ticket["chipColor"] = chipColor;
          ticket["chipLabel"] = chipLabel;
        });

        if (payload?.status === "pending") {
          state.list = payload?.data || [];
          state.pendingList = tickets;
          state.count = payload?.count || 0;
        } else {
          state.list = payload?.data || [];
          state.count = payload?.count || 0;
        }
        state.isSelectedGamesLoading = false;
      })
      //  GET SINGLE TICKET
      .addCase(getTicketAsync.pending, (state, action) => {
        if (action.meta.type === "calcCashout") {
          state.calcCashout = [];
        } else {
          state.selected = [];
        }
      })
      .addCase(getTicketAsync.fulfilled, (state, { payload }) => {
        let tickets = payload?.tickets || [];
        let type = payload?.type;
        const ts = new Date().getTime();

        let stateInfo = null;

        // console.log(cashout)

        tickets?.forEach((ticket) => {
          ticket.bets?.forEach((bet) => {
            // game['ts'] = ts;
            // console.log(bet.game.gameid);
            bet.game["ts"] = ts;
            bet.game["score_set_calculated"] = calcScoreSet(bet.result, bet.game.state, bet.game.score_set, bet.game.sport_code);
            bet.game["time"] = bet.game.isLive === 1 ? calcGameTime(bet.game.state, bet.game.eventTime, bet.game.sport_code) : bet.game.startTime;
            bet.game["team1Color1"] = randomHexCode();
            bet.game["team1Color2"] = randomHexCode();
            bet.game["team2Color1"] = randomHexCode();
            bet.game["team2Color2"] = randomHexCode();

            switch (bet.game.sport_code) {
              case "FB":
                // stateInfo = calcFootballStateInfo(bet.game.danger_state, bet.game.var_state, false);
                // bet.game['stateIcon'] = stateInfo ? stateInfo.stateIcon : '';
                // bet.game['stateBackground'] = stateInfo ? stateInfo.stateBackground : '';
                // bet.game['stateText'] = stateInfo ? stateInfo.stateText : '';
                // bet.game['stateTeam'] = stateInfo ? stateInfo.stateTeam : '';
                break;
              case "TE":
                stateInfo = calcTennisStateInfo(bet.game.current_server);
                bet.game["stateIcon"] = stateInfo ? stateInfo.stateIcon : "";
                bet.game["stateBackground"] = "";
                bet.game["stateText"] = "";
                bet.game["stateTeam"] = "";
                break;
              default:
                bet.game["stateIcon"] = "";
                bet.game["stateBackground"] = "";
                bet.game["stateText"] = "";
                bet.game["stateTeam"] = "";
                break;
            }

            if (bet.game.sport_code === "TE") {
              let scoreSetsd = bet.game.score_set;
              scoreSetsd = scoreSetsd.split("|");
              const scoreData = scoreSetsd[scoreSetsd?.length - 1];
              const scoreHome = scoreData.split(":")[0];
              const scoreAway = scoreData.split(":")[1];

              bet.game["score_home"] = parseInt(scoreHome) === 50 ? "A" : scoreHome;
              bet.game["score_away"] = parseInt(scoreAway) === 50 ? "A" : scoreAway;
            }

            if (bet.scoreSet === "" && bet.game.state === "FT") {
              // need to update game score away and home from result;
              var resultArray = bet.result?.split(",");
              var target = resultArray[0];
              if (target) {
                var scoreSets = target.split("|");
                const scoreData = scoreSets[scoreSets?.length - 1];
                const scoreHome = scoreData.split(":")[0];
                const scoreAway = scoreData.split(":")[1];
                bet.game["score_home"] = scoreHome;
                bet.game["score_away"] = scoreAway;
              }
            }

            bet["basicPrice"] = bet.price;
          });
        });

        if (type === "calcCashout") {
          state.calcCashout = tickets;
        } else {
          state.selected = tickets;
        }
      })
      // POST TICKET
      .addCase(postTicketAsync.pending, (state) => {
        state.isPostTicketLoading = true;
      })
      .addCase(postTicketAsync.rejected, (state) => {
        state.isPostTicketLoading = false;
      })
      .addCase(postTicketAsync.fulfilled, (state, { payload }) => {
        if (payload?.response?.type === "success") {
          state.isTicketPosted = true;
          state.postedTicket = payload.data;
        }
        state.isPostTicketLoading = false;
      });
  },
});

export const { updateScoresInTicket, removePostedTicketState, updateOddsInTicket } = ticketsSlice.actions;

export default ticketsSlice.reducer;
