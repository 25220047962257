/**
 * HelpAccount - German version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import ListItem from "@mui/material/ListItem";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class Help extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>HILFE</h1>
            <h3>MEIN KONTO</h3>
            Die Webseite von Test company 123 richtet sich an Kunden, die Spielautomaten und Sportwetten als Spaß und Unterhaltung betrachten und ihr Spielverhalten richtig einschätzen können.<br></br>
            Sie können sich von jedem Computer mit jedem Browser in Ihr Spielerkonto einloggen. Dafür brauchen Sie lediglich Ihren persönlichen Benutzernamen und Ihr Passwort.<br></br>
            Wenn Sie angemeldet sind, klicken Sie auf die Schaltfläche „Mein Konto“ in der oberen rechten Ecke der Website.<br></br>
            In „Mein Konto“ können Sie Ein- und Auszahlungen tätigen, Ihre Transaktionen überprüfen sowohl als auch Ihre Limits und persönlichen Daten ändern.<br></br>
            <br></br>
            <h4>1. Einzahlungen</h4>
            Folgende Einzahlmöglichkeiten stehen Ihnen zur Verfügung: <br></br>
            <br></br>
            <ListItem>Sofortüberweisung</ListItem>
            <ListItem>Skrill</ListItem>
            <ListItem>Paysafecard</ListItem>
            <ListItem>Banküberweisung</ListItem>
            <br></br>
            <h4>2. Auszahlungen</h4>
            Ihre Anfrage wird innerhalb von 24 Stunden bei Werktagen bearbeitet.<br></br>
            Die Überweisungsdauer beträgt 3 – 5 Werktage innerhalb der SEPA Region.<br></br>
            Bitte beachten Sie, dass Sie nur die Auszahlungsmöglichkeit wählen können, mit der Sie bereits Geld auf Ihr Spielerkonto eingezahlt haben. Eine Ausnahme wird gemacht, wenn der Zahlungsanbieter keine Option
            zur Auszahlung gibt. Dann kann eine Bankverbindung, die auf Ihren Namen läuft, benutzt werden. Folgende Auszahlmöglichkeiten stehen zur Verfügung:<br></br>
            <br></br>
            <ListItem>Skrill</ListItem>
            <ListItem>Banküberweisung</ListItem>
            <br></br>
            <h4>3. Meine Einsätze</h4>
            In diesem Bereich finden Sie eine Übersicht über Ihre Einsätze während eines Zeitraums, nach dem Sie selber filtern können.<br></br>
            <br></br>
            <h4>4. Kontoauszug</h4>
            In diesem Bereich erhalten Sie eine Komplettübersicht über Ihr Spielerkonto. Sie können einen Zeitraum wählen und nach Einsätzen, Ein- und Auszahlungen filtern.<br></br>
            <br></br>
            <h4>5. Profil</h4>
            In Ihrem Profil können Sie Ihre persönlichen Daten ändern und aktualisieren.<br></br>
            Hier können Sie Ihre Adresse und / oder Telefonnummer ändern. Bitte beachten Sie, dass eine Änderung der Daten in manchen Fällen eine weitere Identitätsprüfung erfordert.<br></br>
            Vorname, Nachname, Geburtstag und -land können nicht geändert werden.<br></br>
            Wenn Sie diese Änderungen benötigen, wenden Sie sich bitte an unseren Kundendienst unter support@testcompany123.com.<br></br>
            <br></br>
            <h4>6. Dokumente</h4>
            Unter dem Reiter „Dokumente“ können Sie die von Test company 123 geforderten Dokumente zur Identitätsprüfung hochladen. Diese Identitätsprüfung wird von den maltesischen Behörden gefordert.<br></br>
            Test company 123 ist gesetzlich verpflichtet, die Identität des Kunden zu überprüfen, wenn Auszahlungen oder Transaktionen insgesamt den Betrag in Höhe von EUR 2.000,00 oder mehr erreichen, sowohl als auch
            wenn eine Auszahlung angefordert wird. <br></br>
            <br></br>
            <h3>EINSTELLUNGEN</h3>
            <h4>1. Passwort ändern</h4>
            Sie können jederzeit Ihr Passwort ändern. Folgen Sie den Anweisungen, wenn Sie auf die Schaltfläche „Passwort ändern“ klicken.<br></br>
            Wenn Sie Ihr Passwort vergessen haben, klicken Sie bitte neben dem Login Bereich auf „Passwort vergessen“ und folgen Sie den Anweisungen.<br></br>
            Wenn Sie Ihren Usernamen vergessen haben, klicken Sie bitte ebenfalls auf „Username vergessen“ und Ihr Username wird Ihnen zu der registrierten E-Mail Adresse gesendet.<br></br>
            <br></br>
            <h4>2. Limitierung</h4>
            Kontrollieren Sie Ihr Budget! Wir bieten mehrere Möglichkeiten sich selbst zu limitieren.<br></br>
            <br></br>
            <h4>3. Limitierung von Einsätzen</h4>
            Hier können Sie den maximalen Betrag festlegen, den Sie in einem bestimmten Zeitrahmen einsetzen möchten.<br></br>
            <br></br>
            <h4>4. Limitierung auf einen möglichen Verlust</h4>
            Hier können Sie den maximalen Betrag festlegen, den Sie in einem bestimmten Zeitrahmen verlieren können.<br></br>
            <br></br>
            <h4>5. Automatisches Abmelden</h4>
            Über diese Funktion können Sie einstellen, wann Sie das System automatisch abmeldet. Nach 1, 2, 5, 12 oder 24 Stunden.<br></br>
            <br></br>
            <h4>6. Einzahlungslimits</h4>
            In diesem Bereich können Sie sich auf die Höhe der Einzahlung in Kombination mit einem Zeitraum limitieren. Wir bieten tägliche, wöchentliche oder monatliche Einzahlungslimits an.<br></br>
            <br></br>
            <h4>7. Sperren</h4>
            Sie können Ihr Spielerkonto für einen bestimmten Zeitraum sperren. Ihr Spielerkonto wird nach Ablauf der Zeit automatisch wieder freigeschaltet.<br></br>
            Wenn Sie eine zeitlich unbegrenzte Sperrung bestätigen, wird Ihr Spielerkonto für weitere Aktivitäten gesperrt. Ein Einloggen ist dann nicht mehr möglich. Wenn Sie in diesem Fall noch einen Restbetrag auf
            Ihrem Konto haben, wird Ihnen dieser auf das zuletzt benutze Einzahlkonto, zurückerstattet.<br></br>
            <br></br>
            <h4>8. Änderung von bestehenden Limitierungen</h4>
            Alle Änderungen die Sie limitieren, werden mit sofortiger Wirkung aktiv. Wenn Sie ein Limit entfernen oder auf weniger beschränken möchten, tritt dies erst nach sieben Tagen in Kraft.<br></br>
            <br></br>
            <h4>9. Benutzer</h4>
            In diesem Bereich können Sie Ihre Kommunikationspräferenzen verwalten, wie z.B.:<br></br>
            E-Mail Benachrichtigungen, zusätzliche SMS Benachrichtigungen, individuelle Informationen oder den Erhalt von Angeboten und Newsletter.<br></br>
            <br></br>
            <h3>WETTSCHEIN</h3>
            <h4>Wettmöglichkeiten</h4>
            <h4>1. Einzelwette</h4>
            Bei einer Einzelwette wird auf nur ein Spiel gewettet. Es ist möglich mehrere Einzelwetten auf einen Wettschein zu platzieren.<br></br>
            <br></br>
            <h4>2. Kombiwette</h4>
            Bei einer Kombiwette wird auf mehrere Spiele gewettet. Mit einer Kombiwette kann auf zwei, drei, vier oder mehr Spiele gleichzeitig gewettet werden. Um die Kombiwette zu gewinnen, müssen alle Wetten in der
            Kombination richtig getippt sein.<br></br>
            <br></br>
            <h4>3. Systemwette</h4>
            Eine Systemwette ist eine Zusammenfassung von einer bestimmten Anzahl an Kombiwetten. Es erfolgt sozusagen eine Zusammenfassung mehrerer Kombiwetten auf einen Schein. Sie können zwischen mehreren Systemen
            wählen: 2 von 3, 5 von 7 und so weiter.<br></br>
            <br></br>
            <h4>4. Bank</h4>
            Die Bank Variante wird nur in der Systemwette verwendet. Die Bank Auswahl ist in jeder Kombination enthalten. Alle Bank Auswahlen im Wettschein müssen gewinnen, ansonsten sind alle Kombinationen verloren.
            <br></br>
            Durch das Anklicken von B werden die Banken ausgewählt. Die Anzahl von Systemen richtet sich nur nach den Spielen, die nicht als Bank markiert sind.<br></br>
            <br></br>
            <h4>5. Maximaler Gesamteinsatz</h4>
            Durch das Anklicken von „Max“ neben dem Gesamteinsatz wird der maximale Gesamteinsatz angezeigt. Dieser variiert je nach Sportart, Wettbewerb und Kombination. Die Gewinnchance zeigt die maximale mögliche
            Auszahlung an.<br></br>
            <br></br>
            <h4>6. Maximaler Gewinn</h4>
            Die maximale Auszahlung pro Wettschein beträgt EUR 250.000,00.<br></br>
            Der maximale Auszahlungsbetrag pro Kunde und pro Woche beträgt EUR 100.000,00.<br></br>
            <br></br>
            <h4>7. Mindesteinsatz</h4>
            Der Mindesteinsatz pro Wettschein beträgt EUR 2,00.<br></br>
            Bei System- und Mehrwegewetten beträgt der Mindesteinsatz pro Kombi EUR 0,25.<br></br>
            <br></br>
            <h4>8. Anzahl der Wetten</h4>
            Auf einem Wettschein können maximal 30 verschiedene Spiele platziert werden.<br></br>
            <br></br>
            <h4>9. Quotenänderung akzeptieren</h4>
            Während einer Platzierung der Wetten kann es vorkommen, dass sich die Quoten in der Zwischenzeit geändert haben. Die Meldung „Quotenänderung“ wird angezeigt. Um die Meldung zu vermeiden, muss das Feld neben
            „Quotenänderung akzeptieren“ angeklickt sein.<br></br>
            <br></br>
            <h3>AUSWERTUNG</h3>
            Die Auswertung der abgegebenen Wettscheine erfolgt direkt nach der Überprüfung der offiziellen Ergebnisse.<br></br>
            <br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(Help));
