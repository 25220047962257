//MUI AppBar component overrides

export function appBar(theme) {
  return {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          padding: 0,
        },
      },
    },
  };
}
