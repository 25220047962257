import { setAlert } from "../../actions";
import { setTermsStatus } from "../settings-reducer";

const alertMiddleware = (storeAPI) => (next) => (action) => {
  if ((action.type.endsWith("/fulfilled") && action.payload?.shouldAlert) || (action.type.endsWith("/rejected") && action.payload?.shouldAlert)) {
    const alert = validate(action.payload?.response);
    if (action.payload?.shouldConfirmTerms) {
      dispatch(setTermsStatus(true));
    }
    if (alert.required && action.payload?.response?.payload?.type) {
      storeAPI.dispatch(
        setAlert({
          ...alert.payload,
          payload: action.payload.response.payload.type || "",
          from: "",
        })
      );
    }
  }
  return next(action);
};

export default alertMiddleware;

function validate(res) {
  let alertRequired = false;
  let alertPayload = {};

  switch (res?.type) {
    default:
      alertPayload = {
        type: "error",
        payload: "connection_error",
        collection: "labels",
      };
      alertRequired = false;
      break;

    case "error":
    case "warning":
    case "success":
    case "info":
      alertPayload = {
        type: res?.type,
        payload: res?.payload,
        collection: res?.collection,
      };
      alertRequired = true;
      break;

    case "data":
      if (!res?.payload) {
        alertPayload = {
          type: "error",
          payload: "connection_error",
        };
        alertRequired = true;
      }
      break;
  }

  return {
    required: alertRequired,
    payload: alertPayload,
    stop: res?.type !== "success",
  };
}
