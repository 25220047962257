/**
 * Footer - English version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import FacebookIcon from "@mui/icons-material/Facebook";
import AdultIcon18 from "../../../media/img/18+.svg";
import AdultIcon21 from "../../../media/img/21+.svg";
import AppStoreSmIcon from "../../../media/img/appstoresm.svg";
import PlayStoreSmIcon from "../../../media/img/playstoresm.svg";

import "../de/Footer.css";

const styles = (theme) => ({
  root: {
    "& .MuiTypography-body1": {
      fontSize: "1em",
    },
    "& .MuiDivider-root": {
      margin: "2em 0 2.5em 0",
    },
    "& .MuiButton-root": {
      opacity: "0.5",
      padding: "0",
      fontSize: "1.2em",
    },
    backgroundColor: "var(--color-main)",
    "& .MuiButton-root:hover": {
      opacity: "1",
      backgroundColor: "#ffffff00",
    },
    padding: "1em 3em 1em 3em",
  },
  logoBar: {
    display: "flex",
    alignItems: "center",
  },
});

class Footer extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes, locale } = this.props;

    if (!host) return null;

    return (
      <Container className={classes.root} style={{ backgroundColor: "var(--color-main)", marginTop: "0.2em" }} maxWidth={false}>
        <Typography component="span">
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Link to={`/${locale}/info/terms-conditions`} onClick={this.goTop.bind(this)}>
                <Typography component="span">Geschäftsbedingungen</Typography>
              </Link>
              <br></br>
              <Link to={`/${locale}/info/privacy-policy`} onClick={this.goTop.bind(this)}>
                <Typography component="span">Datenschutzerklärung</Typography>
              </Link>
              <br></br>
              <Link to={`/${locale}/info/betting-rules`} onClick={this.goTop.bind(this)} key={4}>
                <Typography component="span">Wettregeln</Typography>
              </Link>
              <br></br>
              <Link to={`/${locale}/info/slots-rules`} onClick={this.goTop.bind(this)} key={6}>
                <Typography component="span">Casino Regeln</Typography>
              </Link>
              <br></br>
              <Link to={`/${locale}/info/bonus-terms`} onClick={this.goTop.bind(this)} key={3}>
                <Typography component="span">Bonusbedingungen Wetten</Typography>
              </Link>
              <br></br>
              <Link to={`/${locale}/info/bonus-terms-slots`} onClick={this.goTop.bind(this)} key={5}>
                <Typography component="span">Bonusbedingungen Casino</Typography>
              </Link>
              <br></br>
            </Grid>
            <Grid item xs={4}>
              {/* <h4>Company</h4> */}
              <Link to={`/${locale}/info/about-us`} onClick={this.goTop.bind(this)}>
                <Typography component="span">Über uns</Typography>
              </Link>
              <br></br>
              <Link to={`/${locale}/info/responsible-game`} onClick={this.goTop.bind(this)}>
                <Typography component="span">Verantwortungsvolles Spielen</Typography>
              </Link>
              <br></br>
              <Link to={`/${locale}/info/help`} onClick={this.goTop.bind(this)}>
                <Typography component="span">Hilfe</Typography>
              </Link>
              <br></br>
            </Grid>

            <Grid item xs={5}>
              <Grid container style={{ textAlign: "center" }} className={classes.logoBar}>
                <Grid item xs={4}>
                  <Link to={`/${locale}/payments`} onClick={this.goTop.bind(this)}>
                    <Button>
                      <img style={{ height: "2em", margin: "0 0 0 0.5em" }} src={`https://api.nubet.com/media/icons/trustly.svg`} alt="Trustly icon"></img>
                    </Button>
                  </Link>
                </Grid>
                <Grid item xs={2}>
                  <Link to={`/${locale}/info/responsible-game`} onClick={this.goTop.bind(this)}>
                    <Button>
                      <img style={{ height: "3em" }} src={AdultIcon18} alt="18+" />
                    </Button>
                  </Link>
                </Grid>
                {host.brand === "nubet_et" ? (
                  <Grid item xs={2}>
                    {" "}
                    <Link to={`/info/responsible-game`} onClick={this.goTop.bind(this)}>
                      <Button>
                        <img style={{ height: "3em" }} src={AdultIcon21} alt="21+" />
                      </Button>
                    </Link>{" "}
                  </Grid>
                ) : null}
                <Grid item xs={4}>
                  {host.brand === "nubet_et" ? (
                    <img style={{ height: "4em" }} src={"https://api.nubet.com/media/icons/maksujatolliameti.png"} alt="licence-ee" />
                  ) : (
                    <img style={{ height: "4em" }} src={"https://api.nubet.com/media/icons/spelinspektionen.png"} alt="licence-se" />
                  )}
                </Grid>
                <Grid item xs={12} style={{ margin: "1.5em 0 0 0", textAlign: "left" }}>
                  Achtung! Dies ist eine Glücksspielwerbung. Glücksspiele sind kein geeignetes Mittel, um finanzielle Probleme zu lösen.
                  <br></br>Machen Sie sich mit den Regeln vertraut und handeln Sie verantwortungsbewusst!
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Divider />
                    <Grid container spacing={2}>
                        <Grid item xs={3}><Button><img style={{ height: '2.5em', margin: '0 1.5em 0 0' }} src={AppStoreSmIcon} alt="app store icon"></img></Button><Link to={`/info/apk_download`}><Button><img style={{ height: '2.5em' }} src={PlayStoreSmIcon} alt="play store icon"></img></Button></Link></Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <Link to={{ pathname: "#" }} target="_blank"><FacebookIcon style={{ fontSize: '3em' }} /></Link></Grid>
                    </Grid> */}
          <Divider />
          <Grid container spacing={4} key={7}>
            {host.brand === "nubet_et"
              ? [
                  <Grid item xs={5} key={8}>
                    <h4>Wetten und Glücksspiele können süchtig machen. Seien Sie verantwortungsbewusst!</h4>
                    <b>Nubet.com</b> wird von Vana Lauri OÜ betrieben, einem in Estland registrierten Unternehmen.<br></br>
                    <br></br>
                    <b>Registrierungsnummer:</b> 16427120<br></br>
                    <b>Eingetragene Adresse:</b> Roseni 10-135, 10111 Tallinn, Estonia<br></br>
                    <b>Lizenznummer:</b> : HKT000066 and HKT000067<br></br>
                    <br></br>
                    <b>Achtung! </b> Die Teilnahme an Glücksspielen kann süchtig machen.<br></br>Wenn Sie eine Sucht oder Probleme haben, können Sie{" "}
                    <Link
                      to={`info/responsible-game`}
                      style={{
                        color: "var(--color-contrast",
                        textDecoration: "underline",
                      }}
                      onClick={this.goTop.bind(this)}
                    >
                      {" "}
                      hier
                    </Link>{" "}
                    Hilfe finden!<br></br>
                    <br></br>
                  </Grid>,
                ]
              : [
                  <Grid item xs={5} key={8}>
                    <h4>Wetten und Glücksspiele können süchtig machen. Seien Sie verantwortungsbewusst!</h4>
                    <b>Nubet.com</b> wird von Vana Lauri OÜ betrieben, einem in Estland registrierten Unternehmen.<br></br>
                    <br></br>
                    <b>Registrierungsnummer:</b> 16427120<br></br>
                    <b>Eingetragene Adresse:</b> Roseni 10-135, 10111 Tallinn, Estonia<br></br>
                    <b>Lizenznummer:</b> : HKT000066 and HKT000067<br></br>
                    <br></br>
                    <b>Achtung! </b> Die Teilnahme an Glücksspielen kann süchtig machen.<br></br>Wenn Sie eine Sucht oder Probleme haben, können Sie{" "}
                    <Link
                      to={`info/responsible-game`}
                      style={{
                        color: "var(--color-contrast",
                        textDecoration: "underline",
                      }}
                      onClick={this.goTop.bind(this)}
                    >
                      {" "}
                      hier
                    </Link>{" "}
                    Hilfe finden!<br></br>
                    <br></br>
                  </Grid>,
                ]}
            <Grid item xs={7}>
              <h4>Faire Behandlung bei Wetten</h4>
              Bei Nubet.com wollen wir uns unsere Kunden nicht aussuchen. Wir glauben, dass die einzig akzeptable Art, ein Sportwettengeschäft zu betreiben, darin besteht, alle Kunden zu gleichen Bedingungen zu
              behandeln. Daher sollten die Wettlimits für alle gleich sein. Außerdem glauben wir nicht, dass die derzeitige Geschäftspraxis, bei der Wetten lange auf die manuelle Annahme durch den Händler warten müssen,
              eine Erfahrung ist, die von den Spielern geschätzt wird.<br></br>
              <br></br>
              Bei Nubet.com gibt es so etwas wie eine manuelle Wettannahme nicht. Die Wette wird entweder angenommen oder abgelehnt, aber kein Kunde wird in das Niemandsland der ausstehenden manuellen Annahme geworfen.
              <br></br>
            </Grid>
          </Grid>
          <Divider key={10} />
          <Grid container spacing={4} key={11}>
            <Grid item xs={5} style={{ paddingRight: "4em" }}>
              <h4>Einfach ist schön</h4>
              Bei Nubet.com möchten wir Ihnen die bequemste Möglichkeit bieten, eine Wette zu platzieren. Die Benutzeroberfläche sollte so gestaltet sein, dass die Navigation mit so wenigen Klicks wie möglich erfolgt.
              Wir wollen Sie nicht mit irrelevanten und irreführenden Inhalten langweilen. Unser Ziel ist es, den Überblick über den internationalen Veranstaltungskalender zu behalten und Ihnen dabei zu helfen, die
              richtigen Wetten für Ihr persönliches Interesse zu finden. <br></br>
              <br></br>
              <br></br>
              <h4>Besseres Spielerlebnis</h4>
              Darüber hinaus bietet Nubet.com Casinospiele von verschiedenen Anbietern, verschiedene Arten von Spielautomaten, individuelle Angebote und ein einzigartiges Spielerlebnis, um eine hohe Qualität des
              Glücksspiels zu gewährleisten. Eröffnen Sie jetzt ein Konto und genießen Sie unser Wett- und Casinoangebot!
            </Grid>
            <Grid item xs={7}>
              <h4>Was Sie von Nubet.com erwarten können:</h4>
              Nubet.com ist nicht <b>irgendein</b> Sportwetten- oder Casinoanbieter. <br></br>
              <br></br>
              Nubet.com wurde von leidenschaftlichen Sportwetten- und eGaming-Experten mit dem Ziel gegründet, Folgendes zu bieten:
              <br></br>
              <br></br>
              <li>Gleiche Limits für alle</li>
              <li>Unverfälschte Kundenerfahrung</li>
              <li>Fokus auf den nordischen Trabrennsport</li>
              <li>Einfache und kostenlose Registrierung</li>
              <li>Hochwertige Wett- und Casinospiele, die auf Ihren Vorlieben basieren</li>
              <li>Keine Gebühren für Geldtransaktionen</li>
              <br></br>
              Überzeugt? Dann freuen wir uns darauf, Sie bei Nubet.com begrüßen zu dürfen!<br></br>
              <br></br>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Link to={`/${locale}/register-switch`} onClick={this.goTop.bind(this)}>
                <h4>Registrieren Sie sich kostenlos hier bei Nubet.com!</h4>
              </Link>
            </Grid>
          </Grid>
          <Divider />
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <span>
              {" "}
              © Copyright 2023 Vana Lauri OÜ (Alle Rechte vorbehalten.)<br></br>
              <br></br>
            </span>
          </Grid>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
    locale: state.i18n.locale,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(Footer));
