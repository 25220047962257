/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconWP = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g><path d="M173.3,1537.4c57.4,0,83.5,15.6,116.5,35.4c36.9,22.1,78.7,47.2,159.1,47.2c80.3,0,122.2-25.1,159-47.2
		c33-19.8,59.1-35.4,116.5-35.4c57.4,0,83.5,15.6,116.5,35.4c36.9,22.1,78.7,47.2,159.1,47.2c80.3,0,122.2-25.1,159.1-47.2
		c33-19.8,59.1-35.4,116.5-35.4c57.5,0,83.5,15.6,116.5,35.4c36.9,22.1,78.7,47.2,159.1,47.2s122.2-25.1,159.1-47.2
		c33-19.8,59.1-35.4,116.5-35.4c22.8,0,41.3-18.5,41.3-41.3s-18.5-41.3-41.3-41.3c-80.3,0-122.2,25.1-159.1,47.2
		c-33,19.8-59.1,35.4-116.5,35.4c-57.5,0-83.5-15.6-116.5-35.4c-36.9-22.1-78.7-47.2-159.1-47.2c-80.3,0-122.2,25.1-159.1,47.2
		c-33,19.8-59.1,35.4-116.5,35.4c-57.4,0-83.5-15.6-116.5-35.4c-36.9-22.1-78.7-47.2-159.1-47.2c-80.3,0-122.2,25.1-159,47.2
		c-33,19.8-59.1,35.4-116.5,35.4c-57.4,0-83.5-15.6-116.5-35.4c-36.9-22.1-78.7-47.2-159.1-47.2c-22.8,0-41.3,18.5-41.3,41.3
		C131.9,1518.9,150.4,1537.4,173.3,1537.4z"/>
					<path d="M1826.7,1702.7c-80.3,0-122.2,25.1-159.1,47.2c-33,19.8-59.1,35.4-116.5,35.4s-83.5-15.6-116.5-35.4
		c-36.9-22.1-78.7-47.2-159.1-47.2c-80.3,0-122.2,25.1-159.1,47.2c-33,19.8-59.1,35.4-116.5,35.4c-57.4,0-83.5-15.6-116.5-35.4
		c-36.9-22.1-78.7-47.2-159.1-47.2c-80.3,0-122.2,25.1-159,47.2c-33,19.8-59.1,35.4-116.5,35.4c-57.4,0-83.5-15.6-116.5-35.4
		c-36.9-22.1-78.7-47.2-159.1-47.2c-22.8,0-41.3,18.5-41.3,41.3s18.5,41.3,41.3,41.3c57.4,0,83.5,15.6,116.5,35.4
		c36.9,22.1,78.7,47.2,159.1,47.2c80.3,0,122.2-25.1,159-47.2c33-19.8,59.1-35.4,116.5-35.4c57.4,0,83.5,15.6,116.5,35.4
		c36.9,22.1,78.7,47.2,159.1,47.2c80.3,0,122.2-25.1,159.1-47.2c33-19.8,59.1-35.4,116.5-35.4c57.5,0,83.5,15.6,116.5,35.4
		c36.9,22.1,78.7,47.2,159.1,47.2s122.2-25.1,159.1-47.2c33-19.8,59.1-35.4,116.5-35.4c22.8,0,41.3-18.5,41.3-41.3
		S1849.6,1702.7,1826.7,1702.7z"/>
					<path d="M999.9,1372c341.9,0,620.1-278.1,620.1-620c0-341.9-278.2-620-620.1-620c-341.9,0-620,278.2-620,620
		C379.9,1093.9,658.1,1372,999.9,1372z M988.9,1289.2c-69.2-1.4-135.2-16-195.7-41.3c148-111.3,240-284.8,247.6-471.1
		c61.8-32.2,128.5-51.6,196.3-58.2C1284,941.5,1185.2,1170.9,988.9,1289.2z M1149.1,1268.3C1292,1125.8,1358.4,920,1321,717
		c74.4,4.4,148,24.1,215.8,59.2C1526.3,1010.1,1365.6,1205.6,1149.1,1268.3z M1470.7,493.1c31.9,57.7,53.5,121.9,62.2,190
		c-170.5-72.6-366.7-65.5-531.9,21.1c-58.7-37.4-108.9-85.4-148.5-140.8C1022.2,411.3,1270.2,382.2,1470.7,493.1z M999.9,214.6
		c144.2,0,275.4,57.1,372,149.9c-194.6-52.3-405.9-6.8-562.7,126.9c-33.4-66.6-53.1-140.2-56.7-216.4
		C826.6,236.5,910.8,214.6,999.9,214.6z M673.9,325.2c22.5,183.8,126.6,350,284.2,449.7c-3,69.6-19.5,137.1-47.7,199.1
		c-216.5-70.8-365.8-271-370.1-500C575.2,416.4,620.6,365.9,673.9,325.2z M478.3,623c51.9,195,197,355.4,391.4,424.5
		c-40.9,62.2-94.8,116.1-159,157.2C561.6,1109.1,462.6,941.9,462.6,752C462.6,707.5,468.1,664.3,478.3,623z"/></g>
			</svg>
		</div>
	);
};

export default IconWP;
