/**
 * Resend Email confirmation.
 */

import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import withStyles from "@mui/styles/withStyles";
import { resendConfirm } from "../../actions";

const styles = (theme) => ({
  container: {
    padding: 20,
    minHeight: 300,
    backgroundColor: "var(--color-main)",
  },
  centerWrapper: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "1rem",
  },
});

const parseQueryString = function (str) {
  let objURL = {};

  str.replace(
    new RegExp("([^?=&]+)(=([^&]*))?", "g"),
    function ($0, $1, $2, $3) {
      objURL[$1] = $3;
    }
  );
  return objURL;
};

class ResendConfirm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
    };
  }

  UNSAFE_componentWillMount() {
    /**
     * Request resending email confirmation request
     */
    const searchString = this.props.location.search;
    const params = parseQueryString(searchString);

    if (params.email) {
      this.setState({ email: params.email });
      this.props.resendConfirm(this.constructor.name, params.email);
    }
  }

  render() {
    log(this.constructor.name, "render");
    const { classes, confirm } = this.props;

    return (
      <div className={classes.container}>
        {confirm ? (
          confirm.general === "email_already_confirmed" ? (
            <div className={classes.centerWrapper}>
              <Translate value="labels.email_already_confirmed" />
            </div>
          ) : confirm === "confirmation_email_sent" ? (
            <div className={classes.centerWrapper}>
              <Translate value="labels.confirmation_email_sent" />
            </div>
          ) : (
            <div className={classes.centerWrapper}>
              <Translate value="labels.register_general" />
            </div>
          )
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.user.auth,
    locale: state.i18n.locale,
    confirm: state.confirm,
  };
};

export default connect(mapStateToProps, { resendConfirm })(
  withStyles(styles)(ResendConfirm)
);
