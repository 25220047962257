function sortgamesByName(games) {
  return games.sort((a, b) => {
    const gameA = a?.team1?.toLowerCase();
    const gameB = b?.team1?.toLowerCase();

    if (gameA < gameB) {
      return -1;
    }
    if (gameA > gameB) {
      return 1;
    }
    return 0;
  });
}

export const sortDatesDesc = (dates) => {
  if (!dates && !dates?.length) return [];
  const sortedDates = dates.sort((a, b) => {
    const time1 = new Date(a);
    const time2 = new Date(b);
    return time2 - time1;
  });
  return sortedDates;
};

const sortGamesByTime = (games, leagueFilter) => {
  if (leagueFilter === "live") {
    return games;
  }
  if (leagueFilter !== "live") {
    const sortedTeams = sortgamesByName(games);
    const sortedGames = sortedTeams.sort((a, b) => {
      if (a.isFeatured && !b.isFeatured) {
        return -1; // a comes before b
      }
      if (!a.isFeatured && b.isFeatured) {
        return 1; // b comes before a
      }
      let abTime = new Date();
      let bcTime = new Date();
      if (a?.startTime) {
        abTime = new Date(a?.startTime);
      }
      if (b?.startTime) {
        bcTime = new Date(b?.startTime);
      }
      const aTime = abTime.toISOString();
      const bTime = bcTime.toISOString();
      return aTime.localeCompare(bTime);
    });
    return sortedGames;
  }
};

export default sortGamesByTime;
