// Registration form
import "./Trustlyregister.css";
import "react-phone-input-2/lib/style.css";
import { Box, Button, Checkbox, Container, FormControlLabel, Grid2, TextField } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import React from "react";
import { connect } from "react-redux";
import { Field, getFormValues, reduxForm } from "redux-form";
import PasswordField from "../Inputs/PasswordInput";
import CheckIcon from "@mui/icons-material/Check";
import { Translate } from "react-redux-i18n";
import { Link } from "react-router-dom";
import { checkTrustlyDepositStatus, signinWithTrustlyToken } from "../../actions";
import { generateDatabetTokenAsync } from "../../redux-store/databet-reducer";
import { getCustomerFreeSpinsAsync } from "../../redux-store/casino-reducer";

import hostConfig from "../../config/hostConfig";
import api from "../../apis/nubet";
import authHeader from "../../apis/auth-header";
import AdultIcon18 from "../../media/img/18+.svg";
import AdultIcon21 from "../../media/img/21+.svg";
import PhoneInput from "react-phone-input-2";
import TextFieldWithCheck from "../Inputs/TextFieldWithCheck";
import withRouter from "../../helpers/withRouter";

const styles = (theme) => ({
  root: {
    "& .MuiContainer-root": {
      padding: theme.spacing(2),
      paddingBottom: 0,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    "& .MuiFormControl-root": {
      margin: "0.3em 0",
      width: "100%",
    },
    "& .MuiFormControlLabel-root": {
      margin: "2em 1em 0 0",
      width: "100%",
    },
    "& .MuiTypography-body1": {
      fontSize: "1em",
    },
    "& .MuiInputBase-input": {
      fontSize: "1em",
    },
    "@media (hover:hover)": {
      "& .MuiFormControl-root": {
        // margin: "1em 1em 0 0",
        width: "30%",
      },
      "& .MuiContainer-root": {
        padding: theme.spacing(2),
      },
      "& .MuiFormControlLabel-root": {
        margin: "1em 1em 0 0",
        width: "60% !important",
      },
      backgroundColor: "var(--color-main)",
    },
    "& .MuiFormLabel-root": {
      fontSize: "1.2em",
    },
    "& .MuiInput-root": {
      fontSize: "1.2em",
    },
  },
  hidden: {
    width: "0 !important",
    height: "0 !important",
    visibility: "hidden",
  },
  legal: {
    fontSize: "10em",
  },
  greenColor: {
    color: "var(--color-contrast)",
  },
  success: {
    backgroundColor: "var(--color-contrast)",
    color: "#fff",
    width: "20em",
    margin: "auto",
    padding: "2em",
    textAlign: "center",
    borderRadius: "0.5em",
  },
  error: {
    backgroundColor: "var(--red)",
    color: "var(--white)",
    width: "20em",
    margin: "auto",
    padding: "2em",
    textAlign: "center",
    borderRadius: "0.5em",
  },
  smallFont: {
    fontSize: "1.2em",
    "& .MuiFormControlLabel-label": {
      fontSize: "1.2em",
    },
  },
  tcCheck: {
    fontSize: "12em",
    "& .MuiFormControlLabel-root": {
      width: "12em",
    },
  },
  countryList: {
    ...theme.typography.body1,
  },
  phoneInput: {
    width: "100%",
  },
  boxPhone: {
    width: "30%",
    "@media (hover:none)": {
      width: "100%",
    },
  },
  boxLimit: {
    "@media (hover:hover)": {
      "& .MuiFormControl-root": {
        width: "90%",
      },
    },
  },
  boxLimitText: {
    margin: "2em 0 1em",
    textAlign: "left",
    "@media (hover:hover)": {
      margin: "1em 20em 1em",
      textAlign: "center",
    },
  },
  boxIntroText: {
    margin: "2em 0 1em",
    "@media (hover:hover)": {
      margin: "1em 4em 1em",
    },
  },
});

class TrustlyRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recaptchaVerified: false,
      errMsgs: [],
      step: this.props.step,
    };
  }

  componentDidMount() {
    const { trustlyPayload, auth, trustlyDepositStatus, locale, navigate } = this.props;
    const storedData = sessionStorage.getItem("registerFormData");
    if (storedData) {
      const formData = JSON.parse(storedData);
      if (formData["phone"]) {
        formData["phone"] = parseInt(formData["phone"]);
      }
      Object.keys(formData)?.forEach((val) => {
        this.props.change(val, formData[val]);
      });
    } else {
      this.props.change("sub_email", true);
      this.props.change("sub_sms", true);
    }

    const statusToken = localStorage.getItem("trustly_register_action");
    if (!trustlyPayload?.token) {
      navigate(`/${locale}`);
    }
    if (statusToken) {
      this.props.checkTrustlyDepositStatus(this.constructor.name, trustlyPayload, navigate);
    }
    if (trustlyDepositStatus?.payload?.status && trustlyDepositStatus?.payload?.status !== "ok") {
      if (trustlyDepositStatus.payload.status === "pending") {
        navigate(`/${locale}/trustly/deposit/waiting`);
      } else {
        navigate(`/${locale}/interfaces/message?type=deposit&status=${trustlyDepositStatus.payload.status}`);
      }
    }
    if (auth.isSignedIn) {
      navigate(`/${locale}`);
    }
  }

  componentDidUpdate(prevProps) {
    // Save form data to session storage when the form values change
    if (prevProps.formValues !== this.props.formValues) {
      sessionStorage.setItem(
        "registerFormData",
        JSON.stringify({
          sub_sms: true,
          sub_email: true,
          ...this.props.formValues,
        })
      );
    }
  }

  componentWillReceiveProps() {
    const { trustlyDepositStatus, locale } = this.props;
    if (trustlyDepositStatus?.payload?.status && trustlyDepositStatus?.payload?.status !== "ok") {
      if (trustlyDepositStatus.payload.status === "pending") {
        this.props.navigate(`/${locale}/trustly/deposit/waiting`);
      } else {
        this.props.navigate(`/${locale}/interfaces/message?type=deposit&status=${trustlyDepositStatus.payload.status}`);
      }
    }
  }

  renderTextField = ({ label, input, classes, meta: { touched, invalid, error }, ...custom }) => (
    <TextField
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
      InputProps={{
        endAdornment: !invalid && !error && <CheckIcon className={classes.greenColor} />,
      }}
    />
  );

  renderCheckbox = ({ input, label }) => (
    <div>
      <FormControlLabel control={<Checkbox checked={input.value ? true : false} onChange={input.onChange} color="primary" />} label={label} />
    </div>
  );

  renderPhoneField = ({ label, input, classes, meta: { touched, invalid, error }, ...custom }) => (
    // <Box sx={{ width: "60%", margin: "auto", my: 2 }}>
    <Box sx={{ margin: "auto", my: 2 }} className={classes.boxPhone}>
      <label>{label}</label>
      <PhoneInput
        inputComponent={TextField}
        country={"fi"}
        {...input}
        {...custom}
        inputProps={{
          name: label,
          required: true,
          autoFocus: true,
        }}
      />
      <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-filled">{touched && error ? error : null}</p>
    </Box>
  );
  /**
   * Validate all information
   * Name, Phone, address, TOS, Privacy Policy, DOB
   */

  signInCallback = async () => {
    await this.props.generateDatabetTokenAsync({ isAuthenticated: false, lang: this.props.locale });
    await this.props.getCustomerFreeSpinsAsync();
  };

  onSubmit = (formValues) => {
    let formData = { ...formValues };
    formData["phoneNumber"] = formValues["phoneNumber"]?.replace(/\s/g, "").slice(1, formValues["phoneNumber"]?.length);
    const { trustlyDepositStatus, navigate } = this.props;
    let flag = true;
    let { errMsgs } = this.state;
    errMsgs = [];

    // if (this.validatePhoneNUmber(formValues.phoneNumber) === false) {
    //   flag = false;
    //   errMsgs.push('invalid_phone');trustly_register_action
    // }

    this.setState({ errMsgs }, () => {
      if (flag) {
        this.props.signinWithTrustlyToken(
          this.constructor.name,
          {
            ...formData,
            token: trustlyDepositStatus?.payload?.loginCode,
          },
          navigate,
          this.signInCallback
        );
      }
    });
  };

  onVerificationCompleted(value) {
    this.setState({ recaptchaVerified: true, errMsgs: [] });
  }

  onVerificationFailed(value) {
    this.setState({ recaptchaVerified: false, errMsgs: [] });
  }

  /**
   * Validate password
   */
  validatePassword(str) {
    let pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$");

    return pattern.test(str);
  }

  /**
   * Validate email address
   */
  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  /**
   * Validate phone number
   */
  validatePhoneNUmber(number) {
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(number);
  }

  /**
   * Validate user name is available before clicking Register button
   * Pre-check username existance
   */
  async validateUserNameExistance() {
    const { formValues } = this.props;
    let { errMsgs } = this.state;

    let host = window.location.hostname ? window.location.hostname.replace(/\./g, "_").replace(/\-/g, "_") : "betv3_frontend_herokuapp_com";
    let source;
    let baseURL;

    switch (hostConfig[host]?.api) {
      default:
      case "nubet_test":
        source = "https://test-api.nubet.com";
        break;

      case "nubet_prod":
        source = "https://api.nubet.com";
        break;
    }

    if (formValues.userName) {
      baseURL = `/account/services/ajax?action=/ecr/ups/userNameExist&userName=${formValues.userName}`;

      const reply = await api.get(baseURL, {
        headers: authHeader(),
      });

      if (reply && reply.data && reply.data.exists) {
        errMsgs.push("user_exists");
        this.setState({ errMsgs });
      } else {
        errMsgs.splice(errMsgs.indexOf("user_exists"), 1);
        this.setState({ errMsgs });
      }
    }
  }
  /**
   * Validate email is available before clicking Register button
   * Pre-check email existance
   */
  async validateEmailExistance() {
    const { formValues } = this.props;
    let { errMsgs } = this.state;

    let host = window.location.hostname ? window.location.hostname.replace(/\./g, "_").replace(/\-/g, "_") : "betv3_frontend_herokuapp_com";
    let source;
    let baseURL;

    switch (hostConfig[host]?.api) {
      default:
      case "nubet_test":
        source = "https://test-api.nubet.com";
        break;

      case "nubet_prod":
        source = "https://api.nubet.com";
        break;
    }

    if (formValues.email) {
      baseURL = `/account/services/ajax?action=/ecr/registration/emailExist&email=${formValues.email}`;

      const reply = await api.get(baseURL, {
        headers: authHeader(),
      });

      if (reply && reply.data && reply.data.exists) {
        errMsgs.push("email_exists");
        this.setState({ errMsgs });
      } else {
        errMsgs.splice(errMsgs.indexOf("email_exists"), 1);
        this.setState({ errMsgs });
      }
    }
  }

  render() {
    const { classes, translate, content, formValues } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)} className={classes.root} autoComplete="none" id="userForm">
        <div>
          <Container maxWidth="xl">
            <Grid2 item size={12}>
              <div className={classes.boxIntroText}>
                <span>
                  <Translate value="labels.register_infotext" />
                </span>
              </div>
            </Grid2>
            <Field name="email" id="trustly_email" component={TextFieldWithCheck} classes={classes} label={translate.labels.email} disabled={content === "update" ? true : false} type="email" />
            <Field name="confirm_email" id="trustly_email_confirm" component={TextFieldWithCheck} classes={classes} label={translate.labels.confirm_mail} disabled={content === "update" ? true : false} type="email" />
            <Field name="password" id="trustly_password" maxLength={8} component={PasswordField} classes={classes} label={translate.labels.password} className={content === "update" ? classes.hidden : null} />
            <Field name="phoneNumber" id="trustly_phone" component={this.renderPhoneField} classes={classes} label={translate.labels.mobile} />
            <Grid2 container spacing={0} style={{ textAlign: "center" }}>
              <Grid2 item size={12}>
                <div
                  style={{
                    marginTop: "0",
                    fontSize: "1.3em",
                    color: "var(--color-contrast)",
                  }}
                >
                  <span>
                    <Translate value="labels.limits" />
                  </span>
                </div>
                <div className={classes.boxLimitText}>
                  <span>
                    <Translate value="labels.limits_infotext" />
                  </span>
                </div>
              </Grid2>
            </Grid2>
            <Box className={classes.boxLimit}>
              <Grid2 container>
                <Grid2 item size={{ xs: 12, sm: 6 }} style={{ textAlign: "center" }}>
                  <div style={{ marginTop: "1em", color: "var(--color-contrast)" }}>
                    <span>
                      <Translate value="labels.limits_loss" />
                    </span>
                  </div>
                  <Field
                    name="monthlyLoss"
                    component={TextFieldWithCheck}
                    classes={classes}
                    label={translate.labels.limits_time_month}
                    disabled={content === "update" ? true : false}
                    type="number"
                    style={{ fontSize: "0.8em" }}
                  />
                </Grid2>
                <Grid2 item size={{ xs: 12, sm: 6 }} style={{ textAlign: "center" }}>
                  <div style={{ marginTop: "1em", color: "var(--color-contrast)" }}>
                    <span>
                      <Translate value="labels.limits_deposit" />
                    </span>
                  </div>
                  <Field
                    name="monthlyDeposit"
                    component={TextFieldWithCheck}
                    classes={classes}
                    label={translate.labels.limits_time_month}
                    disabled={content === "update" ? true : false}
                    type="number"
                    style={{ fontSize: "0.8em" }}
                  />
                </Grid2>
              </Grid2>
            </Box>
            <div
              style={{
                margin: "2em 0 1em 0",
                fontSize: "1.3em",
                color: "var(--color-contrast)",
              }}
            >
              <span>
                <Translate value="labels.notifications" />
              </span>
            </div>
            <div>
              <span>
                <Translate value="labels.notifications_infotext" />
              </span>
            </div>
            <div style={{ display: "flex" }}>
              <Field name="sub_email" component={this.renderCheckbox} classes={classes} label={translate.labels.user_email} disabled={content === "update" ? true : false} />
              <Field name="sub_sms" component={this.renderCheckbox} classes={classes} label={translate.labels.user_sms} disabled={content === "update" ? true : false} />
            </div>
          </Container>
        </div>
        {this.state.errMsgs && this.state.errMsgs?.length
          ? this.state.errMsgs.map((msg, key) => (
              <div
                style={{
                  fontSize: "1rem",
                  color: "var(--red)",
                  marginBottom: 3,
                }}
                key={key}
              >
                <Translate value={`labels.${msg}`} />
              </div>
            ))
          : null}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button type="submit" variant="contained" size="medium" color="primary" id="submitButton">
            <Translate value="labels.register" />
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            py: 4,
          }}
        >
          <div
            style={{
              width: "60%",
              margin: "1em",
              textAlign: "center",
              color: "var(--grey)",
            }}
          >
            {" "}
            <Translate value="labels.attention_gambling" />
            <br></br>
            <br></br>
            <Link to={`/info/responsible-game`}>
              <img style={{ height: "3em", margin: "0.5em 0 2em 0" }} src={AdultIcon18} alt="18+" />
            </Link>{" "}
            <Link to={`/info/responsible-game`}>
              <img style={{ height: "3em", margin: "0.5em 0 2em 3em" }} src={AdultIcon21} alt="21+" />
            </Link>
          </div>
        </Box>
      </form>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  const emailValidationCheck = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const requiredFields = ["phoneNumber", "password", "email"];

  const checkEmail = (field, error) => {
    if (values[field] && !emailValidationCheck.test(values[field])) {
      errors[field] = error;
    }
  };

  const checkPassword = (field, error) => {
    if (values[field] && values[field]?.length < 8) {
      errors[field] = error;
    }
  };

  const validateLimits = () => {
    // Loss limits Validation
    if (parseFloat(values?.dailyLoss) && parseFloat(values?.weeklyLoss) && parseFloat(values?.weeklyLoss) > 10 && parseFloat(values?.dailyLoss) > parseFloat(values?.weeklyLoss)) {
      errors["dailyLoss"] = "The value cannot be more than weekly value.";
    }
    if (parseFloat(values?.dailyLoss) && parseFloat(values?.monthlyLoss) && parseFloat(values?.monthlyLoss) > 10 && parseFloat(values?.dailyLoss) > parseFloat(values?.monthlyLoss)) {
      errors["dailyLoss"] = "The value cannot be more than monthly value.";
    }
    if (parseFloat(values?.dailyLoss) && parseFloat(values?.dailyLoss) < 10) {
      errors["dailyLoss"] = "Minimum value is 10";
    }
    if (parseFloat(values?.dailyLoss) && parseFloat(values?.dailyLoss) > 100000) {
      errors["dailyLoss"] = "Maximum value is 100000";
    }

    if (parseFloat(values?.weeklyLoss) && parseFloat(values?.monthlyLoss) && parseFloat(values?.monthlyLoss) > 10 && parseFloat(values?.weeklyLoss) > parseFloat(values?.monthlyLoss)) {
      errors["weeklyLoss"] = "The value cannot be more than monthly value.";
    }
    if (parseFloat(values?.weeklyLoss) && parseFloat(values?.weeklyLoss) < 10) {
      errors["weeklyLoss"] = "Minimum value is 10";
    }
    if (parseFloat(values?.weeklyLoss) && parseFloat(values?.weeklyLoss) > 100000) {
      errors["weeklyLoss"] = "Maximum value is 100000";
    }

    if (parseFloat(values?.monthlyLoss) && parseFloat(values?.monthlyLoss) < 10) {
      errors["monthlyLoss"] = <Translate value="labels.min_value_10" />;
    }
    if (parseFloat(values?.monthlyLoss) && parseFloat(values?.monthlyLoss) > 100000) {
      errors["monthlyLoss"] = <Translate value="labels.max_value_100000" />;
    }

    // Loss limits Validation
    if (parseFloat(values?.dailyDeposit) && parseFloat(values?.weeklyDeposit) && parseFloat(values?.weeklyDeposit) > 10 && parseFloat(values?.dailyDeposit) > parseFloat(values?.weeklyDeposit)) {
      errors["dailyDeposit"] = "The value cannot be more than weekly value.";
    }
    if (parseFloat(values?.dailyDeposit) && parseFloat(values?.monthlyDeposit) && parseFloat(values?.monthlyDeposit) > 10 && parseFloat(values?.dailyDeposit) > parseFloat(values?.monthlyDeposit)) {
      errors["dailyDeposit"] = "The value cannot be more than monthly value.";
    }
    if (parseFloat(values?.dailyDeposit) && parseFloat(values?.dailyDeposit) < 10) {
      errors["dailyDeposit"] = "Minimum value is 10";
    }
    if (parseFloat(values?.dailyDeposit) && parseFloat(values?.dailyDeposit) > 100000) {
      errors["dailyDeposit"] = "Maximum value is 100000";
    }

    if (parseFloat(values?.weeklyDeposit) && parseFloat(values?.monthlyDeposit) && parseFloat(values?.monthlyDeposit) > 10 && parseFloat(values?.weeklyDeposit) > parseFloat(values?.monthlyDeposit)) {
      errors["weeklyDeposit"] = "The value cannot be more than monthly value.";
    }
    if (parseFloat(values?.weeklyDeposit) && parseFloat(values?.weeklyDeposit) < 10) {
      errors["weeklyDeposit"] = "Minimum value is 10";
    }
    if (parseFloat(values?.weeklyDeposit) && parseFloat(values?.weeklyDeposit) > 100000) {
      errors["weeklyDeposit"] = "Maximum value is 100000";
    }

    if (parseFloat(values?.monthlyDeposit) && parseFloat(values?.monthlyDeposit) < 10) {
      errors["monthlyDeposit"] = <Translate value="labels.min_value_10" />;
    }
    if (parseFloat(values?.monthlyDeposit) && parseFloat(values?.monthlyDeposit) > 100000) {
      errors["monthlyDeposit"] = <Translate value="labels.max_value_100000" />;
    }
  };

  requiredFields?.forEach((field) => {
    if (!values[field]) {
      errors[field] = props.translate.labels.field_required;
    }
  });
  if (values["email"] !== values["confirm_email"]) {
    errors["confirm_email"] = props.translate.labels.invalid_email_confirm;
  }
  validateLimits();
  checkEmail("email", props.translate.labels.invalid_email);
  checkEmail("confirm_email", props.translate.labels.invalid_email);
  checkPassword("password", props.translate.labels.invalid_pass);
  return errors;
};

const mapStateToProps = (state) => {
  let formData = getFormValues("TrustlyRegister")(state);
  if (!formData) {
    formData = {};
  }

  const dataToReturn = {
    auth: state.user.auth,
    translate: state.i18n.translations[state.i18n.locale],
    formValues: formData,
    trustlyPayload: state.user.trustlyPayload,
    trustlyDepositStatus: state.user.trustlyDepositStatus,
    trustlyPayload: state.user.trustlyPayload,
    locale: state.i18n.locale,
  };
  return dataToReturn;
};

export default withRouter(
  connect(mapStateToProps, {
    checkTrustlyDepositStatus,
    signinWithTrustlyToken,
    getCustomerFreeSpinsAsync,
    generateDatabetTokenAsync,
  })(
    reduxForm({
      form: "TrustlyRegister",
      validate,
      enableReinitialize: true,
    })(withStyles(styles)(TrustlyRegister))
  )
);
