/**
 * Help - Fin version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import ListItem from "@mui/material/ListItem";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class Help extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>APUA</h1>
            <h3>OMA PELITILI</h3>
            Nubet.com on suunnattu asiakkaille, jotka pitävät uhkapelaamista ja urheiluvedonlyöntiä hauskanpitona ja viihteenä ja voivat arvioida vedonlyöntikäyttäytymistään vastuullisesti. Voit käyttää tiliäsi kaikilla
            laitteilla selaimen avulla. Tarvitset vain käyttäjätunnuksesi ja salasanasi. Kun olet kirjautunut sisään, napsauta vain "Oma tili" oikeassa yläkulmassa. "Oma tili" -osiossa voit tehdä talletuksia, nostoja,
            tarkistaa tapahtumasi sekä muuttaa rajojasi ja muokata henkilötietojasi.<br></br>
            <br></br>
            <h4>1. Talletus</h4>
            Seuraavia vaihtoehtoja voidaan käyttää tallettamaan varoja tilillesi: <br></br>
            <br></br>
            <ListItem>Trustly</ListItem>
            <br></br>
            <h4>2. Nosto</h4>
            Nostopyyntösi käsitellään 24 tunnin kuluessa. Vain vaihtoehtoja, joilla talletus tehdään, voidaan käyttää nostomenetelmänä.<br></br>
            <br></br>
            <ListItem>Trustly</ListItem>
            <br></br>
            <h4>3. Tiliote</h4>
            Tällä alueella voit nähdä panostushistoriasi ja suodattaa tietyn ajanjakson.<br></br>
            <br></br>
            <h4>4. Tapahtumat</h4>
            Tässä osiossa näet yleiskatsauksen kaikista pelitilisi tapahtumista. Voit myös selata talletusten ja nostojen ajankohtia.<br></br>
            <br></br>
            <h4>5. Oma profiili</h4>
            Tässä osiossa voit muokata henkilötietojasi. Täällä voit muuttaa osoitettasi tai puhelinnumeroasi. Nimeä, syntymäpäivää ja kansalaisuutta ei voi muuttaa. Huomaa, että jotkin muutokset edellyttävät
            henkilöllisyystodistusta. Jos tarvitset muutoksia näihin osioihin, ota yhteyttä tukitiimiimme: support@nubet.com.<br></br>
            <br></br>
            <h4> 6. Asiakirjat</h4>
            Välilehden "Asiakirjat" alla voit ladata asiakirjat, joita Nubet tarvitsee tilisi täydelliseen vahvistamiseen. Meillä on lain mukaan velvollisuus tarkistaa jokaisen rekisteröidyn asiakkaan henkilöllisyys. Jos
            tapahtumat saavuttavat 2000 euron kynnyksen, meidän on lain mukaan tehtävä asiakkaalle henkilöllisyystarkastukset. Kotiutuksia ei käsitellä, jos Nubet ei ole toimittanut ja vahvistanut tarvittavia asiakirjoja
            asiakkaasta.<br></br>
            <br></br>
            <h3>ASETUKSET</h3>
            <h4>1. Vaihda salasanasi</h4>
            Voit vaihtaa salasanasi milloin tahansa. Seuraa vain ohjeita napsauttamalla painiketta "Vaihda salasana". Jos olet unohtanut salasanasi, napsauta "Unohditko salasanasi?" ja noudata ohjeita. Jos olet unohtanut
            käyttäjänimesi, napsauta "Unohditko käyttäjänimesi?" ja käyttäjänimesi lähetetään sähköpostiosoitteeseen, jolla olet rekisteröitynyt.<br></br>
            <br></br>
            <h4>2. Rajoitukset</h4>
            Hallitse pelaamisestasi. Tarjoamme useita vaihtoehtoja vastuullisen pelaamisen mahdollistamiseksi.<br></br>
            <br></br>
            <h4>3. Pelirajat</h4>
            Täällä voit määrittää maksimisumman, jonka haluat pelata tietyllä ajanjaksolla.<br></br>
            <br></br>
            <h4>4. Tappiorajat</h4>
            Täällä voit määrittää enimmäismäärän, jonka voit hävitä tietyn ajanjakson aikana.<br></br>
            <br></br>
            <h4>5. Automaattinen uloskirjautuminen</h4>
            Tämän toiminnon avulla voit säätää aikaa, jolloin järjestelmä kirjaa sinut ulos automaattisesti. 1, 2, 5, 12 tai 24 tunnin kuluttua.<br></br>
            <br></br>
            <h4>6. Talletusrajat</h4>
            Tässä kentässä voit asettaa talletusrajat tietylle ajanjaksolle. Tarjoamme päivittäisiä, viikoittaisia tai kuukausittaisia talletusrajoja.<br></br>
            <br></br>
            <h4>7. Peliesto</h4>
            Voit estää pelitilisi tietyksi ajaksi. Pelitilisi avautuu automaattisesti uudelleen jakson päätyttyä. Jos vahvistat rajoittamattoman eston, pelitilisi estetään kaikilta muilta toimilta. Silloin on mahdotonta
            kirjautua sisään jälkikäteen. Jos pelitililläsi on rahaa jäljellä, tämä summa palautetaan tallennuksen yhteydessä käyttämällesi maksuvälineelle.<br></br>
            <br></br>
            <h4>8. Voimassa olevien raja-arvojen muutokset</h4>
            Kaikki asettamasi rajoitukset aktivoituvat välittömästi. Jos haluat poistaa tai pienentää olemassa olevaa rajaa, se tulee voimaan 7 päivän kuluttua.<br></br>
            <br></br>
            <h4>9. Ilmoitukset</h4>
            Tässä kentässä voit hallita viestintäasetuksiasi, kuten sähköposti-ilmoituksia, ylimääräisiä tekstiviesti-ilmoituksia, yksittäisiä tietoja tai tarjousten ja uutiskirjeiden vastaanottamista.<br></br>
            <br></br>
            <h3>VETOKUPONKI</h3>
            <h4>Vetojen tyypit</h4>
            <h4>1. Yksittäinen veto (Single)</h4>
            Yksittäisessä vedossa lyöt vetoa yhdestä tuloksesta yhdessä ottelussa.
            <h4>2. Yhdistelmäveto (Combo)</h4>
            Yhdistelmäveto koostuu useista yksittäisistä vedoista, joiden kaikkien on oltava oikein, jotta yhdistelmäveto voittaa. Yhdistelmävedolla voit lyödä vetoa kahdesta, kolmesta, neljästä tai useammasta vedosta
            samanaikaisesti.<br></br>
            <br></br>
            <h4>3. Järjestelmäveto</h4>
            Järjestelmäveto on kokoaa tietyn määrän yhdistelmävetoja yhdelle vetokupongille. Voit valita useita järjestelmiä, esimerkiksi: 2/3, 5/7 ja niin edelleen.<br></br>
            <br></br>
            <h4>4. Pankki (Banker)</h4>
            Pankkia voidaan käyttää vain järjestelmävetoihin. Pankin valinta on jokaisessa yhdistelmässä. Jotta pankki voittaa, kaikkien kupongille asetettujen vetojen on voitettava. Ilmoitat vedon pankkina klikkaamalla
            vetokupongin B-merkkiä. Järjestelmävetojen määrä lasketaan vain otteluista, joita ei ole valittu pankiksi.<br></br>
            <br></br>
            <h4>5. Maksimipanos</h4>
            Klikkaamalla "Max" panoksen vieressä järjestelmä näyttää suurimman mahdollisen panoksen otteluille, jotka olet lisännyt vetokupongille. Tämä luku voi vaihdella urheilulajin, sarjan, vetotyypin tai muiden
            järjestelmän asetusten mukaan.<br></br>
            <br></br>
            <h4>6. Voitto</h4>
            Tämä näyttää vetokupongin suurimman voiton.<br></br>
            <br></br>
            <h4>7. Maksimivoitto</h4>
            Jokaisen vetokupongin maksimivoittosumma on 100 000,00 euroa. Maksimisumma, jonka voit kokonaisuudessaan voittaa vedonlyöntitililtäsi, on 200 000 euroa viikossa.<br></br>
            <br></br>
            <h4>8. Minimipanos</h4>
            Jokaisen vetokupongin minimipanos on 1 euro. Järjestelmä- ja yhdistelmävedoissa jokaisen yhdistelmän minimipanos on 0,25 euroa.<br></br>
            <br></br>
            <h4>9. Vetojen lukumäärä</h4>
            Otteluiden enimmäismäärä vetokupongilla on 30.<br></br>
            <br></br>
            <h4>10. Hyväksy kertoimien muutokset</h4>
            Kun lisäät tapahtumia vetokupongillesi, voi käydä niin, että kertoimet muuttuvat. Tämä tapahtuu enimmäkseen live-tapahtumissa. Näyttöön tulee viesti, että kertoimet ovat muuttuneet, ja sinun on hyväksyttävä
            muutos. Voit välttää viestin napsauttamalla "Hyväksy kertoimien muutokset".<br></br>
            <br></br>
            <h3>TULOSTEN VAHVISTAMINEN JA VOITONMAKSU</h3>
            Vetokuponkien ratkaiseminen ja voitonmaksu tapahtuu sen jälkeen, kun kaikkien vetokupongin tapahtumien viralliset tulokset on tarkistettu ja lisätty järjestelmään.<br></br>
            <br></br>
            <br></br>
            <h3>KUVA-OIKEUDET</h3>
            Nubet hankkii kuvia ja grafiikkaa käytettäväksi osoitteessa www.nubet.com useista eri lähteistä, mukaan lukien:<br></br>
            <br></br>
            <ListItem>Shutterstockilta lisenssisopimuksella, joka sallii meidän käyttää kuvia ja grafiikkaa kaupallisiin tarkoituksiin verkkosivustollamme. </ListItem>
            <ListItem>Freepik lisenssisopimuksella, joka sallii meidän käyttää kuvia ja grafiikkaa kaupallisiin tarkoituksiin verkkosivustollamme.</ListItem>
            <ListItem>Omat valokuvaajamme ja heidän luomansa kuvat ja grafiikka ovat Nubetin omistuksessa, ja niitä saa käyttää ainoastaan kaupallisiin tarkoituksiin verkkosivustollamme.</ListItem>
            <br></br>
            <h4>Tekijänoikeudet</h4>
            Nubet kunnioittaa muiden tekijänoikeuksia. Jos uskot, että työtäsi on käytetty verkkosivuillamme ilman lupaasi, olethan meihin yhteydessä.<br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(Help));
