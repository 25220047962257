/**
 * Footer - Eesti version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import AdultIcon18 from "../../../media/img/18+.svg";
import AdultIcon21 from "../../../media/img/21+.svg";
import FooterExpand from "./FooterExpand";
import { setLiveChatWidget } from "../../../redux-store/settings-reducer";

import "../de/Footer.css";

const styles = (theme) => ({
  root: {
    "& .MuiTypography-body1": {
      fontSize: "1em",
    },
    "& .MuiDivider-root": {
      margin: "2em 0 2em 0",
    },
    "& .MuiButton-root": {
      // opacity: '0.6',
      padding: "0",
      fontSize: "1.2em",
    },
    backgroundColor: "var(--color-main)",
    "& .MuiButton-root:hover": {
      // opacity: '1',
      backgroundColor: "#ffffff00",
    },
    "& .MuiLink-root": {
      margin: "100em",
    },
    padding: "1em 3em 1em 3em",
    a: {
      margin: "100em",
    },
    backgroundColor: "var(--bg-footer)",
  },
  logoBar: {
    display: "flex",
    alignItems: "center",
  },
});

class Footer extends React.PureComponent {
  goTop = () => { };

  render() {
    log(this.constructor.name, "render");
    const { host, classes, locale, selectedTheme } = this.props;

    if (!host) return null;

    return (
      <Container id="Footer" className={classes.root} style={{ marginTop: "0.2em" }} maxWidth={false}>
        <Typography component="span">
          <Grid container style={{ textAlign: "center" }} className={classes.logoBar}>
            <Grid item xs={12} style={{ margin: "0.5em 0 2em 0" }}>
              <FooterExpand />
            </Grid>
            <Grid item xs={12}>
              <img style={{ height: "4em", margin: "0.5em 0 1.5em 0" }} src={`https://api.nubet.com/media/logos/${host.logo}_${selectedTheme}.svg`} alt="logo" />
              <br></br>
              {host.promoPage ? (
                <p>
                  <Link to={`/${this.props.locale}/promotions`} onClick={this.goTop.bind(this)}>
                    Kampaaniad
                  </Link>
                </p>
              ) : null}
              <p>
                <Link to={`/${locale}/info/terms-conditions`} onClick={this.goTop.bind(this)}>
                  Tingimused
                </Link>
              </p>
              <p>
                <Link to={`/${locale}/info/privacy-policy`} onClick={this.goTop.bind(this)}>
                  Privaatsuspoliitika
                </Link>
              </p>
              <p>
                {" "}
                <Link to={`/${locale}/info/betting-rules`} onClick={this.goTop.bind(this)} key={4}>
                  Panustamise reeglid
                </Link>
              </p>
              <p>
                <Link to={`/${locale}/info/slots-rules`} onClick={this.goTop.bind(this)} key={6}>
                  Kasiino reeglid
                </Link>
              </p>
              <p>
                <Link to={`/${locale}/info/bonus-terms`} onClick={this.goTop.bind(this)} key={3}>
                  Boonuse tingimused ja panused
                </Link>
              </p>
              <p>
                <Link to={`/${locale}/info/bonus-terms-slots`} onClick={this.goTop.bind(this)} key={5}>
                  Kasiino boonustingimused
                </Link>
              </p>
              <p>
                <Link to={`/${locale}/info/about-us`} onClick={this.goTop.bind(this)}>
                  Meist
                </Link>
              </p>
              <p>
                <Link to={`/${locale}/info/responsible-game`} onClick={this.goTop.bind(this)}>
                  Vastutustundlik mängimine
                </Link>
              </p>
              <p>
                <Link to={`/${locale}/info/help`} onClick={this.goTop.bind(this)}>
                  Abi
                </Link>
              </p>
              {/* <p>
                <Link
                  to={`/${this.props.locale}/platform/news`}
                  onClick={this.goTop.bind(this)}
                >
                  Uudised
                </Link>
              </p> */}
              <p>
                <Link to={`/${locale}/info/faq`} onClick={this.goTop.bind(this)}>
                  FAQ
                </Link>
              </p>
              <p style={{ cursor: "pointer", width: "max-content", margin: "auto" }} onClick={() => this.props.setLiveChatWidget(true)}>
                {/* <Obfuscate
                  email={"support@nubet.com"}
                  headers={{ subject: "Report a bug" }}
                > */}
                Klienditeenindus
                {/* </Obfuscate> */}
              </p>
            </Grid>

            <Grid item xs={12}>
              {host.brand === "nubet_et" ? (
                <img style={{ height: "5.5em", margin: "2em 0 0 0" }} src={"https://api.nubet.com/media/icons/maksujatolliameti.png"} alt="licence-ee" />
              ) : (
                <img style={{ height: "4em", margin: "1em 0" }} src={"https://api.nubet.com/media/icons/spelinspektionen.png"} alt="licence-se" />
              )}
              <br></br>
              <br></br>
              <Link to={`/${locale}/info/responsible-game`} onClick={this.goTop.bind(this)}>
                <img style={{ height: "3em", margin: "0.5em 2em" }} src={AdultIcon18} alt="18+" />
              </Link>
              <Link to={`/${locale}/info/responsible-game`} onClick={this.goTop.bind(this)}>
                <img style={{ height: "3em", margin: "0.5em 2em" }} src={AdultIcon21} alt="21+" />
              </Link>
              <br></br>
              <br></br>
              <Link to={`/${locale}/payments`} onClick={this.goTop.bind(this)}>
                <img style={{ height: "2em", margin: "1em 0 0 0" }} src={`https://api.nubet.com/media/icons/trustly.svg`} alt="Trustly icon"></img>
              </Link>
              <br></br>
              <br></br>
            </Grid>
          </Grid>
          <br></br>

          <Divider />
          <Grid item xs={12} key={12} style={{ textAlign: "center" }}>
            Tähelepanu! Tegemist on hasartmängu saitiga. Hasartmäng pole sobiv viis rahaliste probleemide lahendamiseks. Tutvuge reeglitega ja käituge vastutustundlikult! Hasartmängudes osalemine võib tekitada sõltuvust. Kui sul on sõltuvus või probleemid, leiad abi {" "}
            <Link
              to={`/${locale}/info/responsible-game`}
              style={{
                color: "var(--color-contrast",
                textDecoration: "underline",
              }}
              onClick={this.goTop.bind(this)}
            >
              {" "}
              siit
            </Link>
            !
          </Grid>
          <Divider />
          <Grid item xs={12} key={12} style={{ textAlign: "center" }}>
            Vana Lauri OÜ (registrikood 16427120), aadress Harju maakond, Tallinn, Kesklinna linnaosa, Roseni tn 10-135, 10111, on Eestis litsentseeritud ja allub Eesti Maksu- ja Tolliameti järelevalvele ning pakub teenuseid tähtajatu õnnemängu ja toto korraldamise tegevuslubade alusel, tegevuslubade numbrid HKT000066 ja HKT000067 ((kehtivad alates 10.07.2022), ning õnnemängude ja toto korraldamiselubade alusel, õnnemängude korraldamise luba number HKL000390 (kehtiv alates 14.10.2022) ja toto korraldamise luba number HKL000409 (kehtiv alates 10.05.2023)
          </Grid>
          <Divider />
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <span style={{ fontSize: "1em" }}>
              {" "}
              © Copyright 2023 Vana Lauri OÜ (All rights reserved)<br></br>
              <br></br>
            </span>
          </Grid>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
    locale: state.i18n.locale,
    selectedTheme: state.settings.theme,
  };
};

export default connect(mapStateToProps, { setLiveChatWidget })(withStyles(styles)(Footer));
