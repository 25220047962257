/**
 * FAQs - English version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class Faq extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)", height: "100%" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>FAQ - FREQUENTLY ASKED QUESTIONS</h1>
            <br></br>
            <h3>Can betting be anything other than a momentary thrill?</h3>
            At its best, betting develops logical thinking, mathematical reasoning and statistical skills, just like the game of skill poker.<br></br>
            <br></br>
            <h3>Do you have sharable wagers?</h3>
            Not on the first day, but there has been some discussion about sharable wagers and maybe one day we can offer this option too. Social interaction and community are important things when it comes to the
            benefits of betting.<br></br>
            <br></br>
            <h3>Is betting a game of chance like casino games?</h3>
            Luck is at its greatest when it comes to individual matches. It can also bring some of our customers huge winnings. But winning long and consistently requires more than that. In addition to skill, good cash
            management skills should not be overlooked. Play only as much as you can lose with a smile, they say.<br></br>
            <br></br>
            <h3>How can I learn to become a good bettor?</h3>
            Experience is a big help here too. Get in touch with people who have a positive profit curve to show for years. Learn the ins and outs of special bets, where the bookmakers make the most pricing mistakes.
            Read a lot, keep an eye out for injuries, news about teams and other details. Winning often takes work. There are also many books written on the subject, they may help you with your challenge.<br></br>
            <br></br>
            <h3>Does Nubet offer multibet?</h3>
            No, we don't really offer multibet. But there are many correct score markets, and there is nothing to stop you from combining these in the wager.<br></br>
            <br></br>
            <h3>How often does Nubet have trotting events?</h3>
            Daily. We try to be where the action is in all the sports we offer. Horse racing is no exception.<br></br>
            <br></br>
            <h3>Where are the Esport venues?</h3>
            Coming up are. Sorry to keep you waiting with these.<br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(Faq));
