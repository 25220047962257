/**
 * SlotsRules - Estonian version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import Obfuscate from "react-obfuscate";
// import ListItem from '@mui/material/ListItem';

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class SlotsRules extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>NUBET.COM-i KASIINO REEGLID</h1>
            Eestikeelne versioon 1.1, viimati uuendatud 27. jaanuaril 2023.<br></br>
            <br></br>
            <h3 id="General">1. ÜLDTEAVE</h3>
            1.1. Kasiinokorraldajat Nubet ja veebilehte nubet.com haldab Eestis registreeritud ettevõte Vana Lauri OÜ, registrikood 16427120, registreeritud aadress Roseni 10–135, 10111 Tallinn, Eesti (edaspidi:{" "}
            <b>Nubet</b> või <b>ettevõte</b>). Vana Lauri OÜ tegutseb Eesti Maksu- ja Tolliameti järelevalve all ja järgmiste tegevuslubade alusel: HKT000066 (välja antud 10.07.2022) ja HKT000067 (välja antud
            10.07.2022).<br></br>
            <br></br>
            1.2. Siinsed Nubeti kasiino reeglid kehtivad Nubeti kasiino kohta veebisaidil nubet.com ja reguleerivad pärisrahaga mängitavaid mänge, mida Nubet kasutab oma veebisaidil nubet.com (edaspidi:{" "}
            <b>Nubeti kasiino</b>). Kui mängija ei nõustu kasiinoreeglite ja Nubeti tingimustega, peab ta Nubeti kasiino kasutamise kohe lõpetama.<br></br>
            <br></br>
            1.3. Nubeti kasiinos mängides peab mängija tunnistama, et tooteid tarbitakse kohe ning ettevõte ei saa mängu ajal või pärast seda tooteid tagastada, tühistada ega nende eest raha tagasi maksta. Nubeti kasiino
            pakub õnnemänge, kus kaotuse risk on täielikult mängija kanda, kui ta otsustab mängida Nubeti kasiino pakutavaid õnnemänge.<br></br>
            <br></br>
            1.4. Nubeti kasiino jätab endale õiguse piirata või blokeerida mängija juurdepääsu Nubeti kasiino mängudele, pakkumistele või teenustele ilma selgitusteta ja ettevõtte enda äranägemisel.<br></br>
            <br></br>
            1.5. Kui teil on Nubeti kasiino kohta küsimusi, võtke meiega ühendust aadressil <Obfuscate email={"support@nubet.com"}>support@nubet.com</Obfuscate>.<br></br>
            <br></br>
            <h3 id="Rules">2. NUBETI KASIINO REEGLID</h3>
            2.1. Tarkvara, mis on seotud mängijale pakutavate Nubeti kasiino mängude ja muude teenuste kasutamisega või võimaldab neid kasutada, võib osaliselt või täielikult pakkuda kolmandast isikust teenuseosutaja,
            kes on sõlminud Nubetiga lepingu. Tarkvara on kaitstud autoriõiguse ja intellektuaalomandiga. Mängija võib kasutada tarkvara ainult isiklikel ja meelelahutuslikel eesmärkidel kooskõlas kõigi kohaldatavate
            seaduste, eeskirjade ja määrustega.<br></br>
            <br></br>
            2.2. Nubeti kasiinos jackpot-mänge mängides on mängija teadlik, et näidatud jackpot ei pruugi iga kord ühtida tegelikult pakutava jackpot-summaga, näiteks summa uuendamise hilinemise tõttu. Seetõttu ei pruugi
            kuvatud väärtus olla täiesti täpne. Pärast jackpot’i võitmist arvutatakse täpne võidusumma kuni jackpot’i võitmise hetkeni mängitud voorude arvu põhjal.<br></br>
            <br></br>
            2.3. Mängija ei tohi mingil juhul kasutada Nubeti kasiino tarkvaras olevat mis tahes tüüpi viga, tõrget, viirust, puudust, defekti või vastuolusid enda kasuks või teiste mängijate kahjuks. Mängija peab kohe
            pärast vea avastamist teatama veast klienditeenindusele.<br></br>
            <br></br>
            2.4. Nubeti kasiino reeglite või Nubeti kasiino suhtes kohaldatavate Nubeti tingimuste rikkumise korral võidakse mängija diskvalifitseerida mängudest, kampaaniatest, boonus- või eripakkumistest ilma ette
            teatamata. Kui Nubet tuvastab, et mängija võidetud raha on saadud pettuse teel ja/või Nubeti kasiino reegleid või tingimusi rikkudes, jätab Nubet endale õiguse raha/võidu kinni pidada või konfiskeerida.
            Kõigist pettusekahtlusega seotud mängijatest võidakse teatada asjaomastele asutustele.<br></br>
            <br></br>
            2.5. Nubeti kasiino pakub oma mängijatele võimalust mängida mänge nn fun-režiimis, kus panuseid ei tehta pärisrahaga ja sissemakseid ei pea tegema, või pärisraharežiimis, mille jaoks peab mängija oma kontole
            kandma piisavalt raha, et mängida mänguraundi valitud panusega.<br></br>
            <br></br>
            2.6. Nubeti kasiinosse raha kandmiseks tuleb kasutada üht Nubeti kasiino pakutavatest maksemeetoditest, mille leiate menüüst „Deposit“ („Sissemaksed“).<br></br>
            <br></br>
            2.7. Nubeti kasiinost raha välja võtmiseks tuleb taotleda väljamakset ja valida mõni saadaval väljamaksemeetod, mille leiate menüüst „Withdrawal“ („Väljamaksed“). Saadaval väljamaksemeetodid võivad oleneda
            kasutatud sissemaksemeetodist.<br></br>
            <br></br>
            2.8. Üksikasjalikud mängujuhised leiate mängu kirjeldusest, mis on saadaval Nubeti kasiinos pärast mängu avamist.<br></br>
            <br></br>
            <h3 id="Disclaimer">3. LAHTIÜTLUSED</h3>
            3.1. Nubeti kasiinos on keelatud kasutada digitaalseid abivahendeid, nagu robotid või mehaanilised, elektroonilised või muud seadmed, mis teevad mänguotsuseid automaatselt. Sellise abivahendi või seadme
            kasutamise kahtluse korral on ettevõttel õigus blokeerida mängijakonto, peatada sisse- ja väljamaksed ning konfiskeerida kõik seotud võidud. Kirjeldatud olukord võib viia ka kohtumenetluseni.<br></br>
            <br></br>
            <h3 id="Severability">4. SÄTETE LAHUSUS</h3>
            4.1. Nubeti kasiino reeglite klauslid on üksteisest eraldatavad. Kui mõni klausel osutub tühiseks, kehtetuks või mingil põhjusel jõustamatuks, jäävad teised klauslid täies ulatuses kehtima.<br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(SlotsRules));
