/**
 * FAQs - Fin version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class Faq extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)", height: "100%" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>FAQ – USEIN KYSYTTYÄ</h1>
            <br></br>
            <h3>Voiko vedonlyönnistä olla muuta iloa kuin hetkellinen jännitys?</h3>
            Vedonlyönti parhaimmillaan kehittää loogista ajattelua, matemaattisia päätelmiä ja tilastojen hallintaa, aivan kuten taitopeli pokerikin.<br></br>
            <br></br>
            <h3>Onko teillä kimppapelejä?</h3>
            Ei ensimmäisenä päivänä, mutta kimppapleaamisestakin on käyty keskustelua ja ehkä voimme jonain päivänä tarjota tämänkin vaihtoehdon. Sosiaalinen kanssakäyminen ja yhteisöllisyys ovat tärkeitä asioita, kun
            puhutaan vedonlyönnin hyödyllisistä puolista.<br></br>
            <br></br>
            <h3>Onko vedonlyönti onnenkauppaa siinä kuin kasinopelitkin?</h3>
            Onnen osuus on isoimmillaan yksittäisissä otteluissa. Se voi tuoda myös jollekin asiakkaistamme huimia voittoja. Pitkä ja jatkuva voittaminen vaatii kuitenkin muutakin. Taitojen lisäksi hyvää kassanhallinnan
            kykyä ei sovi unohtaa. Pelaa vain sen verran kuin voit hymyillen hävitä, sanotaan.<br></br>
            <br></br>
            <h3>Miten voin oppia hyväksi vedonlyöjäksi?</h3>
            Kokemus on iso apu tässäkin. Hankkiudu sellaisten ihmisten juttusille, joilla on näyttää vuosien ajalta plusmerkkinen tuottokäyrä. Opettele erikoisvetojen yksityiskohdat, niissä pelifirmat tekevät eniten
            hinnoitteluvirheitä. Lue paljon, pidä silmällä loukkaantumisia, uutisia joukkueista ja muista yksityiskohdista. Voittaminen vaatii usein työtä. Aiheesta on myös kirjoitettu paljon kirjoja, ne saattavat auttaa
            haasteessasi.<br></br>
            <br></br>
            <h3>Tarjoaako Nubet monivetoa?</h3>
            Emme varsinaisesti tarjoa monivetoa. Tulosvetokohteita on toki paljon, eikä mikään estä yhdistämästä kolmea kohdetta samaan riviin.<br></br>
            <br></br>
            <h3>Kuinka usein Nubetilla on ravikohteita?</h3>
            Päivittäin. Pyrimme olemaan siellä missä tapahtuu kaikissa lajeissa mitä tarjoamme. Hevosurheilu ei ole tästä poikkeus.<br></br>
            <br></br>
            <h3>Missä viipyvät E-Sport pelikohteet?</h3>
            Tulossa ovat. Pahoittelut, että joudut hetken odottaa näiden kanssa.<br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(Faq));
