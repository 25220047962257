import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUrl } from "../redux-store/settings-reducer";

const RouteListener = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  //Selectors
  const url = useSelector((state) => state.settings.url);
  const lang = useSelector((state) => state.i18n.locale);

  useEffect(() => {
    if (location.pathname !== `/${lang}/sign-in` && url !== location.pathname) {
      dispatch(setUrl(location.pathname));
    }
  }, [location]);

  return null;
};

export default RouteListener;
