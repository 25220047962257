// Trotting system layout
import { useEffect } from "react";
// @MUI
import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import TrottingSystemRow from "./system-row";
import { getRacingGamesAsync } from "../../../../redux-store/sports-reducer";

const TrottingSystemLayout = ({ leagueId, gamesList }) => {
  const dispatch = useDispatch();
  // get league filter and leagues
  const lang = useSelector((state) => state.i18n.locale);

  useEffect(() => {
    if (!gamesList?.length) {
      dispatch(getRacingGamesAsync({ lang, leagueId }));
    }
  }, [leagueId]);

  return (
    <Stack gap={1} px={2} py={2} overflowX="auto" maxWidth="100dvw">
      {gamesList?.map((game) => (
        <TrottingSystemRow game={game} key={game.gameid} />
      ))}
    </Stack>
  );
};

TrottingSystemLayout.propTypes = {
  leagueId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default TrottingSystemLayout;
