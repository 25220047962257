// Casino Providers list
import { useDispatch, useSelector } from "react-redux";
import { resetCasinoProviderGames } from "../../../redux-store/casino-reducer";
import { getVendorsAsync } from "../../../redux-store/casino-reducer";
import { useEffect } from "react";
import { Box, Paper, styled } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
// settings to adjust carousel items
import casinoProviderSliderSettings from "../../swiper/settings/casino-provider-slider";
import SwiperSlider from "../../swiper";
import { SwiperSlide } from "swiper/react";

const StyledProviderContainer = styled(Paper)(({ theme }) => ({
  background: "var(--btn-provider-2)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: 10,
  cursor: "pointer",
  borderRadius: "3em",
  "@media (hover:hover)": {
    minHeight: 100,
    borderRadius: "5em",
  },
}));

const boxStyles = {
  width: "95%",
  padding: "1em 0 1.5em 0",
  "@media (hover:hover)": {
    padding: "3em 0 4em 0",
  },
};

const CasinoProviders = () => {
  // nav
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // action dispatcher
  const dispatch = useDispatch();

  const getProvidersList = () => {
    dispatch(getVendorsAsync());
  };

  useEffect(getProvidersList, []);

  // selectors
  const providersList = useSelector((state) => state.casino.providers);
  const locale = useSelector((state) => state.i18n.locale);
  const layout = useSelector((state) => state.settings.layout);

  // func
  const handleNavigate = (provider) => {
    dispatch(resetCasinoProviderGames());
    searchParams.set("provider-id", provider.supplier_id);
    searchParams.set("provider-title", provider.supplier_name);
    navigate(`/${locale}/casino/provider-games?${searchParams.toString()}`);
  };

  if (!providersList?.length) return null;

  return (
    // <Box p={1} mt={2} sx={{ width: "95%", padding: "3em 0 4em 0" }}>
    <Box p={1} mt={{ xs: 1, md: 2 }} sx={boxStyles}>
      <SwiperSlider nextButton={layout === "mouse"} prevButton={layout === "mouse"} {...casinoProviderSliderSettings}>
        {providersList?.map((supplier) => (
          <SwiperSlide key={supplier.supplier_id}>
            <Box px={1}>
              <StyledProviderContainer onClick={() => handleNavigate(supplier)}>
                <Box
                  component="img"
                  alt={supplier.supplier_name}
                  src={`https://storage.googleapis.com/nubet-casino-gfx/provider/${supplier.supplier_id}_bluedark.png`}
                  height={layout === "mouse" ? 90 : 60}
                  sx={{
                    filter: "var(--casino-provider)",
                  }}
                />
              </StyledProviderContainer>
            </Box>
          </SwiperSlide>
        ))}
      </SwiperSlider>
    </Box>
  );
};

export default CasinoProviders;
