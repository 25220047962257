import { Box, Grid2 } from "@mui/material";
import TicketsList from "../../account/TicketsList";
import Slip from "../slip";
import { useSelector } from "react-redux";

const SportsLayout = ({ children }) => {
  const showMyTicketsPanel = useSelector((state) => state.settings.showMyTicketsPanel);
  const auth = useSelector((state) => state.user.auth);
  const layout = useSelector((state) => state.settings.layout);
  return (
    <Box>
      <Grid2 container spacing={1}>
        <Grid2 item size={layout === "mouse" ? 9 : 12}>
          {children}
        </Grid2>
        {layout === "mouse" && (
          <Grid2 item size={3}>
            {showMyTicketsPanel && auth.isSignedIn ? <TicketsList /> : <Slip />}
          </Grid2>
        )}
      </Grid2>
    </Box>
  );
};

export default SportsLayout;
