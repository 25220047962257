/**
 * ResponsibleGame - English version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import ListItem from "@mui/material/ListItem";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
    "& .MuiListItem-root": {
      padding: "0.2em",
    },
  },
});

class ResponsibleGame extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          {host.gaming && host.bet
            ? [
                <div id="legalcontent" className={classes.root} key={0}>
                  <h1>RESPONSIBLE GAMING</h1>
                  Nubet.com supports responsible gambling and sports betting<br></br>
                  <br></br>
                  The Nubet.com casino and sports betting is aimed at customers that consider gambling and betting as fun and entertainment and can assess their betting behaviour responsibly. If the wish of winning leads
                  to a loss of self-control, you might need to seek help.<br></br>
                  <br></br>
                  Please observe the following principles when you visit our website.<br></br>
                  <br></br>
                  <ListItem>Set the maximum total wager of your deposit first</ListItem>
                  <ListItem>Do not deposit more money into your player account as you specified before you started</ListItem>
                  <ListItem>Decide in advance, at what profit you want to exit the website</ListItem>
                  <ListItem>Set a fixed amount in advance on how high your losses can be</ListItem>
                  <ListItem>Try always to be focused and rested when you gamble</ListItem>
                  <ListItem>Take frequent breaks while gambling or betting, in which you do something else</ListItem>
                  <ListItem>Never gamble or bet under the influence of drugs, strong medications or after excessive alcohol consumption</ListItem>
                  <ListItem>Do not take a credit to gamble or bet</ListItem>
                  <ListItem>Keep the limit of your wagers so that a loss does not affect your daily life</ListItem>
                  <ListItem>Do not ignore or neglect your social environment.</ListItem>
                  <br></br>
                  We provide all users with the ability to set their own limits or to block their own account for a certain period of time. Keep in mind, once you set or lower a limit, it will become effective
                  immediately. Nubet.com will consequently not take any wagers that exceed those limits.<br></br>
                  <br></br>
                  To block yourself from playing, you can also contact support@nubet.com. You have the option to have your account blocked for a limited period of time or permanently.<br></br>
                  <br></br>
                  There are many independent organizations that offer assistance. Please have a look at the following to gain first insights: <br></br>
                  <br></br>
                  <h4>Professional help:</h4>
                  If you have a gambling problem, or if you are a friend or a family member of a problem gambler, you can receive free help and counseling from the gambling addiction center:<br></br>
                  <br></br>
                  tel: 15410<br></br>
                  emai: info@15410.ee<br></br>
                  www: www.15410.ee<br></br>
                  <br></br>
                  <b>Nubet.com strictly prohibits minors to use our gambling machines and betting offers</b>
                  <br></br>
                  We do not take any stakes from customers who are not of legal age under the laws of their own country.
                  <br></br>
                  <br></br>
                  The registration of a minor is a violation of our terms and conditions and can be reported to the appropriate authorities. Nubet.com will request a personal identification document to verify the legal
                  age of customers. Nubet.com is legally obliged to cancel all placed bets of minors and to transfer any profits to regulators as required.<br></br>
                  <br></br>
                </div>,
              ]
            : null}
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(ResponsibleGame));
