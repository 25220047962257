//MUI button component overrides
import { buttonClasses } from "@mui/material/Button";

export function button(theme) {
  const rootStyles = (ownerState) => {
    const smallSize = ownerState.size === "small";

    const mediumSize = ownerState.size === "medium";

    const largeSize = ownerState.size === "large";

    const textVariant = ownerState.variant === "text";

    const size = {
      ...(smallSize && {
        fontSize: "var(--content)",
        padding: "0.5em, 0.7em",
        fontWeight: 500,
        ...(textVariant && {
          paddingLeft: 4,
          paddingRight: 4,
        }),
      }),
      ...(mediumSize && {
        paddingLeft: 12,
        paddingRight: 12,
        ...(textVariant && {
          paddingLeft: 8,
          paddingRight: 8,
        }),
      }),
      ...(largeSize && {
        height: 48,
        fontSize: 15,
        paddingLeft: 16,
        paddingRight: 16,
        ...(textVariant && {
          paddingLeft: 10,
          paddingRight: 10,
        }),
      }),
    };
    return size;
  };

  return {
    MuiButton: {
      styleOverrides: {
        outlined: ({ ownerState }) => ({
          ...rootStyles(ownerState),
        }),
        contained: ({ ownerState }) => ({
          ...rootStyles(ownerState),
          ...(ownerState.color === "primary" && {
            // background:
            //   "linear-gradient(0deg, rgb(29, 88, 154) 0%, rgb(61, 163, 253) 50%, rgb(29, 88, 154) 100%)",
            color: "var(--btn-text)",
          }),
          ...(ownerState.color === "secondary" && {
            background: theme.palette.primary.loginBtn,
            color: theme.palette.common.black,
          }),
        }),
        root: {
          [`&.${buttonClasses.disabled}`]: {
            color: theme.palette.common.black,
          },
          textTransform: "uppercase",
        },
      },
    },
  };
}
