// MUI LIST components overrides
import { listItemIconClasses } from "@mui/material/ListItemIcon";

export function list(theme) {
  return {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "auto",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: "3px"
        },
        selected: {
          [listItemIconClasses.root]: {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: "auto",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: "var(--smedium) !important",
          fontWeight: 400,
          marginLeft: "5px",
          "& span": {
            fontSize: "var(--smedium) !important",
            fontWeight: 400,
          },
        },
      },
    },
  };
}
