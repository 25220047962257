/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconFREESPIN = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own-freespin" viewBox="0 0 2000 2000">
				<path fill="var(--grey-15)" d="M338.5,1579.8h262.9v73.2H445.3v59.5h133.3v68.8H445.3v139.2H338.5V1579.8z" />
				<path fill="var(--grey-15)" d="M660.1,1920.4v-340.6h177.2c32.9,0,58,2.8,75.3,8.4c17.4,5.6,31.4,15.9,42,31c10.6,15.1,16,33.5,16,55.2
					c0,18.9-4.1,35.2-12.2,48.9c-8.1,13.7-19.3,24.8-33.6,33.3c-9.1,5.4-21.5,9.9-37.3,13.5c12.7,4.2,21.9,8.4,27.7,12.6
					c3.9,2.8,9.6,8.8,17,17.9c7.4,9.1,12.4,16.2,14.9,21.2l51.5,98.7H878.4l-56.8-104.1c-7.2-13.5-13.6-22.2-19.2-26.3
					c-7.7-5.3-16.4-7.9-26.1-7.9h-9.4v138.2H660.1z M766.9,1717.8h44.8c4.8,0,14.2-1.5,28.2-4.6c7-1.4,12.8-5,17.3-10.7
					c4.5-5.7,6.7-12.3,6.7-19.7c0-11-3.5-19.4-10.6-25.3c-7-5.9-20.3-8.8-39.7-8.8h-46.7V1717.8z"/>
				<path fill="var(--grey-15)" d="M1032.3,1579.8h284.9v72.7h-178.4v54.1h165.5v69.5h-165.5v67.1h183.5v77.1h-290.1V1579.8z" />
				<path fill="var(--grey-15)" d="M1379.4,1579.8h284.9v72.7H1486v54.1h165.5v69.5H1486v67.1h183.5v77.1h-290.1V1579.8z" />
				<path fill="var(--grey-15)" d="M889.6,716.4l-173.5-419C586.3,361,481.3,466,417.7,595.8l419,173.5C850.8,748.4,868.7,730.5,889.6,716.4z" />
				<path fill="var(--grey-15)" d="M1612.6,668.6l-419,173.5c4.8,24.8,4.8,50.2,0,75l419,173.6C1659.7,954,1659.7,805.4,1612.6,668.6z" />
				<path fill="var(--grey-15)" d="M1163.3,769.3l419-173.5C1518.7,466,1413.7,361,1283.9,297.4l-173.5,419C1131.3,730.5,1149.2,748.4,1163.3,769.3z" />
				<path fill="var(--grey-15)" d="M836.7,990.1l-419,173.5c63.7,129.8,168.6,234.7,298.4,298.4l173.5-419C868.7,1028.9,850.8,1011,836.7,990.1z" />
				<path fill="var(--grey-15)" d="M806.4,842.2l-419-173.5c-47.1,136.8-47.1,285.4,0,422.1l419-173.6C801.6,892.4,801.6,867,806.4,842.2z" />
				<path fill="var(--grey-15)" d="M1110.4,1043l173.5,419c129.8-63.7,234.7-168.6,298.4-298.4l-419-173.5C1149.2,1011,1131.3,1028.9,1110.4,1043z" />
				<path fill="var(--grey-15)" d="M962.5,1073.3l-173.5,419h422.1l-173.6-419C1012.7,1078.1,987.3,1078.1,962.5,1073.3z" />
				<path fill="var(--grey-15)" d="M1000,761.4c-65.3,0-118.3,53-118.3,118.3c0,65.3,53,118.3,118.3,118.3c65.3,0,118.3-53,118.3-118.3
					C1118.2,814.4,1065.3,761.5,1000,761.4z M1000,919.1c-21.8,0-39.4-17.7-39.4-39.4s17.7-39.4,39.4-39.4s39.4,17.7,39.4,39.4
					C1039.4,901.5,1021.8,919.1,1000,919.1z"/>
				<path fill="var(--grey-15)" d="M963.6,327c8.4,20.1,31.4,29.6,51.5,21.3c9.6-4,17.3-11.6,21.3-21.3l73.2-175.8c8.4-20.1-1.1-43.2-21.2-51.6
					c-4.8-2-10-3-15.2-3H926.8c-21.8,0-39.4,17.6-39.4,39.4c0,5.2,1,10.4,3,15.2L963.6,327z"/>
				<path fill="var(--grey-15)" d="M1037.5,686.1l173.5-419c-18.7-6.5-37.7-12.1-57.2-16.9l-44.6,107c-25.1,60.3-94.4,88.8-154.7,63.7
					c-28.8-12-51.7-34.9-63.7-63.7l-44.6-107c-19.4,4.8-38.5,10.4-57.3,16.9l173.5,419C987.2,681.3,1012.7,681.3,1037.5,686.1z"/>
			</svg>
		</div>
	);
};

export default IconFREESPIN;
