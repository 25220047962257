/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconRA = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g><ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -414.2136 1000)" cx="1000" cy="1000" rx="60.2" ry="60.2" />
					<path d="M1000,713.2c-158.2,0-286.8,128.7-286.8,286.8c0,158.2,128.7,286.8,286.8,286.8c158.2,0,286.8-128.7,286.8-286.8
		C1286.8,841.8,1158.2,713.2,1000,713.2z M1000,1166.4c-91.8,0-166.4-74.7-166.4-166.4s74.7-166.4,166.4-166.4
		s166.4,74.7,166.4,166.4S1091.8,1166.4,1000,1166.4z"/>
					<path d="M1682.5,403.5l-74.5-75.8C1447.2,182.2,1234,93.5,1000,93.5c-234,0-447.2,88.7-608.1,234.2l-74.5,75.8
		c-139.4,159.4-224,368.1-224,596.5c0,268.1,116.4,509,301.4,674.9c1.3,1.2,86.8,68.6,88.2,69.8c146.6,102,324.8,161.8,516.9,161.8
		s370.3-59.8,516.9-161.8l84.1-66.2c187.4-166,305.5-408.5,305.5-678.5C1906.5,771.6,1821.9,562.9,1682.5,403.5z M1440.1,1518
		l-87.2,63.1c-35.1,21.4-72.3,39.7-111.2,54.6l-111.2-53.9l37.4-226.4C1117,1379.5,1060,1393,1000,1393c-60,0-117-13.5-167.9-37.7
		l37.4,226.4l-111.2,53.9c-38.9-14.8-76.1-33.2-111.2-54.6l-0.1,0.1c-15-11-89.9-65.5-91.1-66.5c-84.4-72.9-150.8-166.1-191.2-272.2
		c0,0,53.3-118.1,53.6-118.8l216.5,21.5c-17.9-44.9-27.8-93.9-27.8-145.2c0-51.3,9.9-100.2,27.8-145.2l-216.5,21.5l-53.6-118.9
		c27.1-71,65.8-136.2,113.8-193.5l74.3-75.9c119.5-104.5,276-167.9,447.3-167.9c171.3,0,327.7,63.4,447.3,167.9l74.3,75.9
		c47.9,57.3,86.6,122.5,113.8,193.5l-53.6,118.9l-216.5-21.5c17.9,44.9,27.8,93.9,27.8,145.2c0,51.2-9.9,100.2-27.8,145.2
		l216.5-21.5c0.4,0.7,53.6,118.8,53.6,118.8C1594.2,1350.1,1526.4,1444.6,1440.1,1518z"/></g>
			</svg>
		</div>
	);
};

export default IconRA;
