/**
 * FAQs - Estonian version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class Faq extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)", height: "100%" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>FAQ - ÜLDISED KÜSIMUSED</h1>
            <br></br>
            <h3>Kas kihlvedude tegemine võib olla midagi muud kui hetkeline põnevus?</h3>
            Parimal juhul arendab kihlvedude tegemine loogilist mõtlemist, matemaatilist arutlust ja statistilisi oskusi, nagu ka osavuspokkerimäng.<br></br>
            <br></br>
            <h3>Kas teil on jagatavad panused?</h3>
            Esimesel päeval ei ole, kuid on toimunud arutelu jagatavate piletite üle ja võib-olla saame ühel päeval ka seda võimalust pakkuda. Sotsiaalne suhtlus ja kogukond on olulised asjad, kui tegemist on kihlvedude
            eeliste kohta.<br></br>
            <br></br>
            <h3>Kas panustamine on õnnemäng nagu kasiinomängud?</h3>
            Õnne on kõige suurem, kui tegemist on üksikute mängude puhul. See võib ka mõnedele meie klientidele tohutuid võite tuua. Kuid pikaajaline ja järjepidev võitmine nõuab rohkemat. Lisaks oskusele ei tohiks
            tähelepanuta jätta ka head rahakasutuse oskust. Mängi ainult nii palju, kui suudad naeratades kaotada, öeldakse.<br></br>
            <br></br>
            <h3>Kuidas õppida heaks panustajaks?</h3>
            Kogemused on ka siin suureks abiks. Võta ühendust inimestega, kellel on aastate jooksul positiivne kasumikõver ette näidata. Õppige tundma eripanuste sisemust ja olemust, kus kihlveokontorid teevad kõige
            rohkem hinnakujundusvigu. Loe palju, hoia silma peal vigastustel, meeskondade uudistel ja muudel üksikasjadel. Võitmine nõuab sageli tööd. Sellel teemal on kirjutatud ka palju raamatuid, need võivad aidata
            sind oma väljakutses.<br></br>
            <br></br>
            <h3>Kas Nubet pakub multibetti?</h3>
            Ei, me ei paku tegelikult multibetti. Kuid on palju korrektse skoori turge ja miski ei takista sind nende kombineerimisel kihlveos.<br></br>
            <br></br>
            <h3>Kui tihti on Nubetil traavivõistlusi?</h3>
            Iga päev. Püüame olla seal, kus on tegevus kõigil pakutavatel spordialadel. Hobuste võidusõidud ei ole erandiks.<br></br>
            <br></br>
            <h3>Kus on Esport'i võistluspaigad?</h3>
            Tulemas on järgmised. Vabandage, et laseme teid nendega oodata.<br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(Faq));
