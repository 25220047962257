/**
 * BonusTerms - English version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import { ListItem } from "@mui/material";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class BonusTerms extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>NUBET.COM SPORTS BETTING BONUS TERMS AND CONDITIONS </h1>
            English version 1.1 Last update January 27th, 2023.<br></br>
            <br></br>
            <h3 id="General">1. GENERAL INFORMATION</h3>
            1.1 These Sports Betting Terms and Conditions apply to Nubet Sports Betting. Sports Betting Bonus cannot be used on any other products such as Nubet Casino products. If you have an active Sports Betting
            Bonus, you will only be able to play on Nubet Casino or other products if you deposit additional money.<br></br>
            <br></br>
            1.2 The Grantor of the Sports Betting Bonus explained in these Sports Betting Bonus Terms and Conditions is Nubet on the website Nubet.com operated by Vana Lauri OÜ, a company registered in Estonia under
            company registration number 16427120 having its registered address at Roseni 10-135, 10111 Tallinn, Estonia (hereinafter referred to as <b>“Nubet”</b> or <b>“The Company”</b>). Vana Lauri OÜ is licensed and
            supervised by the Estonian Tax and Customs Board and is holding the following licenses: HKT000066 (date of issue: 10.07.2022) and HKT000067 (date of issue: 10.07.2022).<br></br>
            <br></br>
            1.3 Nubet may award you any type of Sports Betting Bonus credit or other type of bonuses such as free bet (hereinafter referred also as <b>“Bonus Money”</b>, <b>“Bonus”</b> or <b>“Sports Betting Bonus”</b>)
            if certain conditions are met. It is up to the Player to decide whether they want to use the bonus, and the Player can opt out of Bonus if they wish by not claiming the Bonus offer. Bonus use related wagering
            Terms and Conditions (hereinafter referred also as a <b>“Wagering”</b>) will apply to any granted Sports Betting Bonus and Wagering rules will be informed in advance to the Player on the website and via
            e-mail. Any Sports Betting Bonus awarded will be automatically credited to game account as Bonus Money which is bound by the rules defined in these Sports Betting Bonus Terms and Conditions.<br></br>
            <br></br>
            <h3 id="Tc">2. GENERAL SPORTS BETTING BONUS TERMS AND CONDITIONS</h3>
            2.1. These General Sports Betting Bonus Terms and Conditions apply to any type of Sports Betting Bonus granted by Nubet. Some Bonus types have separately defined Terms and Conditions which may differ from the
            General Sports Betting Bonus Terms and Conditions and are explained at their own paragraph in these Sports Betting Bonus Terms and Conditions with reference to the Bonus type e.g., Sports Betting Welcome
            Bonus, or Sports Betting Deposit Bonus. In the event of any discrepancy, the Terms and Conditions specified per Bonus type shall apply. Please note that, inter alia, minimum wagering requirements and minimum
            deposit amounts may vary for different Bonus types.<br></br>
            <br></br>
            2.2. Nubet wants to offer Sports Betting Bonus to all its Players. However, it is within the Company’s sole discretion to exclude individual Players from the offer. It is within the Company’s sole discretion
            to void any Bonus and related winnings if the Company sees that the Player does not respect the Bonus rules. The Company may also change the terms and conditions of the Bonus or stop offering it at any time
            without notice.<br></br>
            <br></br>
            2.3. Nubet Sports Betting Bonus is valid for and must be used during a certain promotional period. If it is not used, it will expire, and it cannot be used afterwards.<br></br>
            <br></br>
            2.4. The Nubet Sports Betting Bonus is only available once per Customer, Player Account, household, the owner of the money transfer method and/or account used to make the deposit, shared computer (university,
            workplace, internet café, etc.) and/or IP address.<br></br>
            <br></br>
            2.5. Nubet Sports Betting Bonus may not be combined with any other Bonuses unless expressly stated otherwise and may not be used in conjunction with any other promotions or campaigns.<br></br>
            <br></br>
            2.6. A Nubet Sports Betting Bonus Campaign offer sent by e-mail may only be redeemed by the original recipient of the Bonus e-mail. The e-mail address receiving and using the Bonus must be the same as when
            creating the Player Account. Nubet reserves the right to investigate whether the game account holder who used the Sports Betting Bonus offer received the Bonus code personally to the e-mail address used in
            the game account. Breach of this rule may lead to confiscation of the Bonus and any related winnings.<br></br>
            <br></br>
            2.7. A Nubet Sports Betting Bonus that is linked to making a deposit is only valid when using the payment methods offered by Nubet and not specifically excluded as payment method from the Sports Betting Bonus
            offer. The account holder and the payment method holder must be the same. The credit balance on game account which origin is deposit made by the Player without use of bonus, and thus not bound to Bonus Terms
            and Conditions, is called real money balance (hereinafter referred also as <b>“Real Money Balance”</b>).<br></br>
            <br></br>
            2.8. The Nubet Sports Betting Bonus will be credited to your game account balance. In case Sports Betting Bonus has Wagering the account balance cannot be withdrawn or converted to Real Money Balance until
            the Wagering is complete as specified in the Bonus Terms and Conditions. If a Sports Betting Bonus is granted on a deposit, this also applies to the amount deposited, as both are bound by the Sports Betting
            Bonus terms and conditions.<br></br>
            <br></br>
            2.9. If more is deposited than the maximum of the Sports Betting Bonus offer, the entire deposit amount is bound to the Sports Betting Bonus Terms and Conditions. Example: If you receive a 100% up to 100 EUR
            Sports Betting Bonus and deposit 150 EUR, the 150 EUR is subject to the Wagering requirements of the Sports Betting Bonus. In given example the Sports Betting Bonus amount would be 100 EUR.<br></br>
            <br></br>
            2.10. Only settled bets count towards the Wagering requirement. In a case where bet/stake is refunded due to an interrupted event, or event is declared canceled, or void, or there is any other reason why the
            match bet cannot be settled normally, the bet amount will not count towards the Wagering. Same applies to the bets that are cashed out.<br></br>
            <br></br>
            2.11. If two or more bets are placed on the same event for different outcomes, only the first stake will be counted towards the Wagering requirement.<br></br>
            <br></br>
            2.12. Once the player has placed a bet with an active Bonus, the redeemed Bonus can no longer be canceled without losing the Bonus.<br></br>
            <br></br>
            2.13. Only the first bet per event counts towards fulfilling the Wagering requirement. Double or multiple bets are not taken into account for fulfilling the Wagering requirement. System bets can only be
            partially counted towards fulfilling the Wagering requirement. <br></br>
            <br></br>
            2.14. If not otherwise stated, any Sports Betting Bonus received must be Wagered at least six (6) times before it can be paid out or attempted to be withdrawn. Any winnings made from Bonus Money are not
            payable until the Bonus Wagering conditions are met. Attempt to withdraw the Bonus before the Bonus Wagering requirements are met will result in forfeiture of any winnings from the Bonus and the Bonus itself.
            <br></br>
            <br></br>
            2.15. The Bonus Wagering requirements must be met within the specified time frame of receiving a Bonus. In case of failure of meeting the Bonus conditions in time Nubet reserves the right to cancel the Bonus
            amount and all winnings resulting from it.<br></br>
            <br></br>
            2.16. The use of any Bonus is subject to Nubet's monitoring for irregular playing patterns.<br></br>
            <br></br>
            2.17. Misuse of the Sports Betting Bonus constitutes a breach of the Bonus Terms and Conditions. Nubet will assume a breach of the Bonus conditions in the following cases:<br></br>
            <br></br>
            <ListItem>Multiple use of a Bonus by the same person or using the same IP or terminal;</ListItem>
            <ListItem>Suspected organised use of the Bonus offer;</ListItem>
            <ListItem>Placing of bets which, irrespective of their outcome, guarantee a Sports Betting Bonus win for an individual or for a group of players cooperating together, making so-called "Safe Bets".</ListItem>
            <br></br>
            2.18. If there is a suspicion that Sports Betting Bonus Terms and Conditions have been breached or customer has placed bets that guarantee a win due to a Sports Betting Deposit Bonus, free bets, or any other
            promotional offer, regardless of the outcome of the bet, Nubet reserves the right to reclaim the Nubet Sports Betting Bonus offer, correct or cancel placed bets, refuse requested withdrawals and close
            accounts.<br></br>
            <br></br>
            2.19. A Bonus will not be credited if you have been excluded or suspended from the Nubet offer.<br></br>
            <br></br>
            2.20. The Sports Betting Bonus is valid until Wagering is complete or the Sports Betting Bonus is cancelled.<br></br>
            <br></br>
            2.21. Nubet reserves the right to change, suspend or revoke the Bonus and duration of the promotion at any time.<br></br>
            <br></br>
            2.22. Nubet's terms and conditions apply.<br></br>
            <br></br>
            <h3 id="Welcome">3. SPORTS BETTING WELCOME BONUS TERMS AND CONDITIONS</h3>
            3.1. Sports Betting Welcome Bonus offer cannot be used in conjunction with other offers and cannot be used in combination with another Sports Betting Welcome Bonus offer.<br></br>
            <br></br>
            3.2. The Nubet Sports Betting Welcome Bonus is only valid within its promotional period and for the first or second deposit on to a new game account.<br></br>
            <br></br>
            3.3. The minimum deposit is 20 EUR.<br></br>
            <br></br>
            3.4. A player has 30 days from registration to make a deposit and use any Sports Betting Welcome Bonus offer.<br></br>
            <br></br>
            3.5. The Nubet Sports Betting Welcome Bonus will be credited to the customer upon deposit immediately to match 100% of the deposit amount within the defined maximum amount.<br></br>
            <br></br>
            3.6. The Sports Betting Welcome Bonus amount must be wagered at least six (6) times on Nubet Sports Betting before requesting a withdrawal. Calculation example: You deposit 20 EUR, receive 20 EUR from us as a
            Bonus credit. In order to request a withdrawal, a Sports Betting Bonus Wagering of 120 EUR is therefore required.<br></br>
            <br></br>
            3.7. The betting odds for each betting item in a betting slip must be at least 1.5 (1.5x) in order for the bets to count towards the Wagering requirement, unless otherwise stated in the offer. Bets that are
            played with a smaller odds than the specified minimum odds for the Wagering do not contribute to the fulfillment of the wagering requirement.<br></br>
            <br></br>
            3.8. The Wagering requirement specified in the Sports Betting Welcome Bonus Terms and Conditions must be met within 90 days (Sports Betting Welcome Bonus Period) of the initial deposit. At the end of the
            Sports Betting Welcome Bonus Period, the Sports Betting Welcome Bonus and all associated winnings are automatically forfeited and cannot be returned.<br></br>
            <br></br>
            3.9. The Sports Betting Welcome Bonus amount including related deposit and winnings will be displayed as an unavailable credit balance in the Nubet account until the specified Wagering requirements have been
            met. If a withdrawal is requested before the Sports Betting Welcome Bonus Wagering requirement is complete it will result in to void and confiscation of the Sports Betting Welcome Bonus and any related
            winnings without further notice.<br></br>
            <br></br>
            3.10. The maximum allowed bet value with an active Bonus is 100 EUR unless otherwise stated.<br></br>
            <br></br>
            3.11. Only the first bet per event counts towards fulfilling the Wagering requirement. Double or multiple bets are not taken into account for fulfilling the Wagering requirement. System bets can only be
            partially counted towards fulfilling the Wagering requirement.<br></br>
            <br></br>
            3.12. The Sports Betting Welcome Bonus Wagering requirements can only be met if you bet on Nubet Sports according to these Nubet.com Sports Betting Bonus Terms and Conditions and complete the Wagering by
            placing bets on odds that exceed the specified minimum odds requirement.<br></br>
            <br></br>
            <h3 id="Deposit">4. SPORTS BETTING DEPOSIT BONUS TERMS AND CONDITIONS</h3>
            4.1. The Sports Betting Deposit Bonus is optional and can be chosen by the player at the time of making deposit. Make sure to follow the instructions related to use of Sports Betting Deposit Bonus code. The
            various Sports Betting Deposit Bonus options available will be displayed to you when you make your deposit.<br></br>
            <br></br>
            4.2. The minimum deposit to claim the Bonus is EUR 20.<br></br>
            <br></br>
            4.3. The Sports Betting Deposit Bonus is only valid within its promotional period and cannot be claimed afterwards.<br></br>
            <br></br>
            4.4. A Nubet Sports Betting Deposit Bonus offer sent by e-mail, account message or other notification may only be redeemed by the original recipient of the Sports Betting Deposit Bonus offer. Nubet reserves
            the right to investigate whether the game account holder who used the Sports Betting Deposit Bonus offer received the bonus code personally and in case of breach of this rule to void and confiscate the bonus
            and any related winnings.<br></br>
            <br></br>
            4.5. You will be informed of the Sports Betting Deposit Bonus terms and conditions (such as promotion period, Sports Betting Deposit Bonus percentage of the bonus amount corresponding to the deposit amount,
            and maximum Bonus EUR amount) by means of a personal e-mail message, account message, SMS or push message.<br></br>
            <br></br>
            4.6. The Sports Betting Deposit Bonus amount must be wagered at least six (6) times on Nubet Sports Betting before requesting a withdrawal. Calculation example: You deposit 20 EUR, receive 20 EUR from us as a
            Bonus credit. In order to request a withdrawal, a Sports Betting Bonus Wagering of 120 EUR is therefore required.<br></br>
            <br></br>
            4.7. The betting odds for each betting item in a betting slip must be at least 1.5 (1.5x) in order for the bets to count towards the Wagering requirement, unless otherwise stated in the offer. Bets that are
            played with a smaller odds than the specified minimum odds for the Wagering do not necessarily contribute to the fulfillment of the wagering requirement.<br></br>
            <br></br>
            4.8. The Wagering requirement specified in the Sports Betting Deposit Bonus Terms and Conditions must be met within 30 days (Sports Betting Deposit Bonus Period) of the initial deposit. As soon as the Sports
            Betting Deposit Bonus Period has expired, the Sports Betting Deposit Bonus and all related winnings will be forfeited automatically and cannot be recovered.<br></br>
            <br></br>
            4.9. The maximum allowed bet value with an active Bonus is 100 EUR unless otherwise stated. Only the first bet per event counts towards fulfilling the Wagering requirement. Double or multiple bets are not
            taken into account for fulfilling the Wagering requirement. <br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(BonusTerms));
