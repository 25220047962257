// Limits confirmation global alert
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Stack from "@mui/material/Stack";
import { Translate } from "react-redux-i18n";
import { useTheme } from "@emotion/react";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import {
  setLimitsAlert,
  getLimits,
  updateLimitsStatus,
} from "../../../actions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const getTimeEquality = (effectDate) => {
  const currentDate = new Date();
  const effectNewDate = new Date(effectDate);
  return currentDate >= effectNewDate;
};

const ConfirmLimitsAlert = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const shouldConfirmLimits = useSelector(
    (state) => state.user.shouldConfirmLimits
  );
  const auth = useSelector((state) => state.user.auth);
  const limits = useSelector((state) => state.user.limits);

  const handleClose = () => {
    dispatch(setLimitsAlert(false, "ConfirmLimitsAlert"));
  };

  React.useEffect(() => {
    if (auth.isSignedIn) {
      dispatch(getLimits("ConfirmLimitsAlert"));
    }
  }, [auth.isSignedIn]);

  const dailyWager = limits?.extraData?.find((item) => item?.dailyWager);
  const weeklyWager = limits?.extraData?.find((item) => item?.weeklyWager);
  const monthlyWager = limits?.extraData?.find((item) => item?.monthlyWager);
  //Loss limits
  const dailyLoss = limits?.extraData?.find((item) => item?.dailyLoss);
  const weeklyLoss = limits?.extraData?.find((item) => item?.weeklyLoss);
  const monthlyLoss = limits?.extraData?.find((item) => item?.monthlyLoss);
  //Deposit limits
  const dailyDeposit = limits?.extraData?.find((item) => item?.dailyDeposit);
  const weeklyDeposit = limits?.extraData?.find((item) => item?.weeklyDeposit);
  const monthlyDeposit = limits?.extraData?.find((item) => item?.monthlyDeposit);

  const handleLimits = (status) => {
    let payload = {
      operation: "confirmLimit",
      limitType: "",
      isConfirmed: status,
      limitPeriod: "",
    };

    const callback = () => dispatch(getLimits("ConfirmLimitsAlert"));

    if (
      dailyLoss &&
      dailyLoss?.newAmount &&
      dailyLoss?.amountEffectiveTime &&
      getTimeEquality(dailyLoss.amountEffectiveTime)
    ) {
      payload["limitType"] = "loss";
      payload["limitPeriod"] = 1;
      dispatch(updateLimitsStatus(payload, callback, "ConfirmLimitsAlert"));
    }
    if (
      weeklyLoss &&
      weeklyLoss?.newAmount &&
      weeklyLoss?.amountEffectiveTime &&
      getTimeEquality(weeklyLoss.amountEffectiveTime)
    ) {
      payload["limitType"] = "loss";
      payload["limitPeriod"] = 7;
      dispatch(updateLimitsStatus(payload, callback, "ConfirmLimitsAlert"));
    }
    if (
      monthlyLoss &&
      monthlyLoss?.newAmount &&
      monthlyLoss?.amountEffectiveTime &&
      getTimeEquality(monthlyLoss.amountEffectiveTime)
    ) {
      payload["limitType"] = "loss";
      payload["limitPeriod"] = 30;
      dispatch(updateLimitsStatus(payload, callback, "ConfirmLimitsAlert"));
    }
    if (
      dailyWager &&
      dailyWager?.newAmount &&
      dailyWager?.amountEffectiveTime &&
      getTimeEquality(dailyWager.amountEffectiveTime)
    ) {
      payload["limitType"] = "stake";
      payload["limitPeriod"] = 1;
      dispatch(updateLimitsStatus(payload, callback, "ConfirmLimitsAlert"));
    }
    if (
      weeklyWager &&
      weeklyWager?.newAmount &&
      weeklyWager?.amountEffectiveTime &&
      getTimeEquality(weeklyWager.amountEffectiveTime)
    ) {
      payload["limitType"] = "stake";
      payload["limitPeriod"] = 7;
      dispatch(updateLimitsStatus(payload, callback, "ConfirmLimitsAlert"));
    }
    if (
      monthlyWager &&
      monthlyWager?.newAmount &&
      monthlyWager?.amountEffectiveTime &&
      getTimeEquality(monthlyWager.amountEffectiveTime)
    ) {
      payload["limitType"] = "stake";
      payload["limitPeriod"] = 30;
      dispatch(updateLimitsStatus(payload, callback, "ConfirmLimitsAlert"));
    }
    if (
      dailyDeposit &&
      dailyDeposit?.newAmount &&
      dailyDeposit?.amountEffectiveTime &&
      getTimeEquality(dailyDeposit.amountEffectiveTime)
    ) {
      payload["limitType"] = "deposit";
      payload["limitPeriod"] = 1;
      dispatch(updateLimitsStatus(payload, callback, "ConfirmLimitsAlert"));
    }
    if (
      weeklyDeposit &&
      weeklyDeposit?.newAmount &&
      weeklyDeposit?.amountEffectiveTime &&
      getTimeEquality(weeklyDeposit.amountEffectiveTime)
    ) {
      payload["limitType"] = "deposit";
      payload["limitPeriod"] = 7;
      dispatch(updateLimitsStatus(payload, callback, "ConfirmLimitsAlert"));
    }
    if (
      monthlyDeposit &&
      monthlyDeposit?.newAmount &&
      monthlyDeposit?.amountEffectiveTime &&
      getTimeEquality(monthlyDeposit.amountEffectiveTime)
    ) {
      payload["limitType"] = "deposit";
      payload["limitPeriod"] = 30;
      dispatch(updateLimitsStatus(payload, callback, "ConfirmLimitsAlert"));
    }
    handleClose();
  };

  return (
    <Dialog
      open={shouldConfirmLimits}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: {
          margin: 0,
          padding: 0,
          [theme.breakpoints.down("sm")]: {
            width: "95%",
          },
        },
      }}
    >
      <DialogTitle><Translate value="labels.confirm_alert_limits" /></DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Translate value="labels.limits_requested_changes" />:
        </DialogContentText>
        <TableContainer component={Paper}>
          <Table
            sx={{
              "& td, th": {
                borderBottom: 1,
                borderColor: theme.palette.divider,
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Translate value="labels.daily" />
                </TableCell>
                <TableCell>
                  <Translate value="labels.weekly" />
                </TableCell>
                <TableCell>
                  <Translate value="labels.monthly" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th">
                  <Translate value={`labels.limits_loss_text_1`} />
                </TableCell>
                <TableCell>
                  {dailyLoss &&
                  dailyLoss?.newAmount &&
                  dailyLoss.amountEffectiveTime &&
                  getTimeEquality(dailyLoss.amountEffectiveTime) ? (
                    <Stack direction="row" alignItems="center">
                      <Typography variant="body1">
                        {dailyLoss?.amount}
                      </Typography>
                      <KeyboardDoubleArrowRightIcon />
                      <Typography variant="body1">
                        {dailyLoss?.newAmount}
                      </Typography>
                    </Stack>
                  ) : (
                    dailyLoss?.amount
                  )}
                </TableCell>
                <TableCell>
                  {weeklyLoss &&
                  weeklyLoss?.newAmount &&
                  weeklyLoss.amountEffectiveTime &&
                  getTimeEquality(weeklyLoss.amountEffectiveTime) ? (
                    <Stack direction="row" alignItems="center">
                      <Typography variant="body1">
                        {weeklyLoss?.amount}
                      </Typography>
                      <KeyboardDoubleArrowRightIcon />
                      <Typography variant="body1">
                        {weeklyLoss?.newAmount}
                      </Typography>
                    </Stack>
                  ) : (
                    weeklyLoss?.amount
                  )}
                </TableCell>
                <TableCell>
                  {monthlyLoss &&
                  monthlyLoss?.newAmount &&
                  monthlyLoss.amountEffectiveTime &&
                  getTimeEquality(monthlyLoss.amountEffectiveTime) ? (
                    <Stack direction="row" alignItems="center">
                      <Typography variant="body1">
                        {monthlyLoss?.amount}
                      </Typography>
                      <KeyboardDoubleArrowRightIcon />
                      <Typography variant="body1">
                        {monthlyLoss?.newAmount}
                      </Typography>
                    </Stack>
                  ) : (
                    monthlyLoss?.amount
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">
                  <Translate value={`labels.limits_wager_text_1`} />
                </TableCell>
                <TableCell>
                  {dailyWager &&
                  dailyWager?.newAmount &&
                  dailyWager.amountEffectiveTime &&
                  getTimeEquality(dailyWager.amountEffectiveTime) ? (
                    <Stack direction="row" alignItems="center">
                      <Typography variant="body1">
                        {dailyWager?.amount}
                      </Typography>
                      <KeyboardDoubleArrowRightIcon />
                      <Typography variant="body1">
                        {dailyWager?.newAmount}
                      </Typography>
                    </Stack>
                  ) : (
                    dailyWager?.amount
                  )}
                </TableCell>
                <TableCell>
                  {weeklyWager &&
                  weeklyWager?.newAmount &&
                  weeklyWager.amountEffectiveTime &&
                  getTimeEquality(weeklyWager.amountEffectiveTime) ? (
                    <Stack direction="row" alignItems="center">
                      <Typography variant="body1">
                        {weeklyWager?.amount}
                      </Typography>
                      <KeyboardDoubleArrowRightIcon />
                      <Typography variant="body1">
                        {weeklyWager?.newAmount}
                      </Typography>
                    </Stack>
                  ) : (
                    weeklyWager?.amount
                  )}
                </TableCell>
                <TableCell>
                  {monthlyWager &&
                  monthlyWager?.newAmount &&
                  monthlyWager.amountEffectiveTime &&
                  getTimeEquality(monthlyWager.amountEffectiveTime) ? (
                    <Stack direction="row" alignItems="center">
                      <Typography variant="body1">
                        {monthlyWager?.amount}
                      </Typography>
                      <KeyboardDoubleArrowRightIcon />
                      <Typography variant="body1">
                        {monthlyWager?.newAmount}
                      </Typography>
                    </Stack>
                  ) : (
                    monthlyWager?.amount
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">
                  <Translate value={`labels.limits_deposit_text_1`} />
                </TableCell>
                <TableCell>
                  {dailyDeposit &&
                  dailyDeposit?.newAmount &&
                  dailyDeposit.amountEffectiveTime &&
                  getTimeEquality(dailyDeposit.amountEffectiveTime) ? (
                    <Stack direction="row" alignItems="center">
                      <Typography variant="body1">
                        {dailyDeposit?.amount}
                      </Typography>
                      <KeyboardDoubleArrowRightIcon />
                      <Typography variant="body1">
                        {dailyDeposit?.newAmount}
                      </Typography>
                    </Stack>
                  ) : (
                    dailyDeposit?.amount
                  )}
                </TableCell>
                <TableCell>
                  {weeklyDeposit &&
                  weeklyDeposit?.newAmount &&
                  weeklyDeposit.amountEffectiveTime &&
                  getTimeEquality(weeklyDeposit.amountEffectiveTime) ? (
                    <Stack direction="row" alignItems="center">
                      <Typography variant="body1">
                        {weeklyDeposit?.amount}
                      </Typography>
                      <KeyboardDoubleArrowRightIcon />
                      <Typography variant="body1">
                        {weeklyDeposit?.newAmount}
                      </Typography>
                    </Stack>
                  ) : (
                    weeklyDeposit?.amount
                  )}
                </TableCell>
                <TableCell>
                  {monthlyDeposit &&
                  monthlyDeposit?.newAmount &&
                  monthlyDeposit.amountEffectiveTime &&
                  getTimeEquality(monthlyDeposit.amountEffectiveTime) ? (
                    <Stack direction="row" alignItems="center">
                      <Typography variant="body1">
                        {monthlyDeposit?.amount}
                      </Typography>
                      <KeyboardDoubleArrowRightIcon />
                      <Typography variant="body1">
                        {monthlyDeposit?.newAmount}
                      </Typography>
                    </Stack>
                  ) : (
                    monthlyDeposit?.amount
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleLimits(false)}
          size="medium"
          variant="outlined"
          color="error"
        >
          <Translate value={`labels.button_decline`} />
        </Button>
        <Button
          sx={{ ml: 2 }}
          onClick={() => handleLimits(true)}
          variant="contained"
          color="primary"
        >
          <Translate value="labels.limits_confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmLimitsAlert;
