import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const CategoryFavouriteIcon = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 200 200"
      {...props}
    >
      <path
        d="M165.6,45.6c-9.7-10.8-21.1-16.5-33.1-16.5c-13.4,0-25.4,8.1-32.5,21.6c-7-13.5-19-21.6-32.2-21.6c-12,0-23.1,5.5-33.1,16.4
	C23,58.4,16.4,87.5,39.4,109.2c10.6,10,58.5,60.4,58.9,60.9c0.5,0.5,1.1,0.8,1.8,0.8h0c0.7,0,1.4-0.3,1.8-0.8
	c0.5-0.5,48.5-50.7,59-60.9C183.5,87.3,177,58.3,165.6,45.6z"
      />
    </SvgIcon>
  );
};

export default CategoryFavouriteIcon;
