/**
 * Market set config FH
 */

const marketSetConfig = {
    FB: {
        prematch: {
            favorites: [],
            mainbets: [1, 16, 18],
            ou: [18, 18, 18],
            handicap: [14, 14],
            bothscore: [29, 31, 32],
            firsthalf: [60, 66, 68],
            asianhc: [16, 16, 16],
        },
        live: {
            favorites: [],
            mainbets: [1, 16, 18],
            rest: [7, 8, 18],
            ou: [18, 18, 18],
            handicap: [14, 14],
            firsthalf: [60, 66, 68],
            asianhc: [16, 16, 16],
        },
        tv: {
            mainbets: [1, 16, 18, 8],
            rest: [7, 8, 18],
            firsthalf: [60, 66, 68],
            handicap_ou: [18, 14],
            asian: [18, 16],
        },
        sidebets: {
            favorites: [],
            mainbets: [1, 2, 3, 4, 16, 18, 14, 10, 11, 19, 20, 29, 31, 32, 33, 34, 47, 45, 8, 7],
            configbets: [37, 35, 36, 854, 855, 856, 857, 858, 859, 860, 862, 861, 931, 932, 933, 934, 1173, 1174, 959, 1234, 1235],
            firsthalf: [60, 66, 68, 65, 63, 64, 69, 70, 75, 77, 76, 81, 62],
            goalscorer: [38, 39, 40],
            player: [770, 775, 776, 777, 778, 780, 1183, 1185, 1187, 1189, 1191],
            corners: [162, 165, 166],
            bookings: [136, 139, 147, 148],
        }
    },
    TE: {
        prematch: {
            favorites: [],
            mainbets: [186, 187, 189],
            firstset: [202, 203, 204]
        },
        live: {
            favorites: [],
            mainbets: [186, 187, 189],
            firstset: [202, 203, 204]
        },
        sidebets: {
            favorites: [],
            mainbets: [186, 187, 189, 188],
            firstset: [202, 203, 204],
        }
    },
    BA: {
        prematch: {
            favorites: [],
            mainbets: [219, 223, 225],
            firsthalf: [60, 66, 68]
        },
        live: {
            favorites: [],
            mainbets: [219, 223, 225],
            firsthalf: [60, 66, 68]
        },
        sidebets: {
            favorites: [],
            mainbets: [1, 219, 223, 225, 86],
            firsthalf: [60, 66, 68],
            player: [768, 770, 771, 772, 773, 774, 921, 922, 923, 924, 1236, 1237, 1238],
        }
    },
    IH: {
        prematch: {
            favorites: [],
            mainbets: [1, 406, 18],
            firstperiod: [443, 460, 446]
        },
        live: {
            favorites: [],
            mainbets: [1, 16, 18],
        },
        sidebets: {
            favorites: [],
            mainbets: [1, 406, 2, 16, 18, 14, 10, 11, 19, 20, 29, 199, 8, 7],
            firstperiod: [443, 460, 446, 445, 459, 529, 452, 444],
            player: [775, 770, 768, 777, 719, 921, 1185, 929],
        }
    },
    HB: {
        prematch: {
            favorites: [],
            mainbets: [1, 16, 18],
            firsthalf: [60, 66, 68]
        },
        live: {
            favorites: [],
            mainbets: [1, 16, 18],
            firsthalf: [60, 66, 68]
        },
        sidebets: {
            favorites: [],
            mainbets: [1, 16, 18, 47],
            firsthalf: [60, 66, 68]

        }
    },
    DA: {
        prematch: {
            favorites: [],
            mainbets: [186, 188, 384],
        },
        live: {
            favorites: [],
            mainbets: [186, 188, 384]
        },
        sidebets: {
            favorites: [],
            mainbets: [186, 188, 384, 381],
        }
    },
    BX: {
        prematch: {
            favorites: [],
            mainbets: [186, 18],
        },
        live: {
            favorites: [],
            mainbets: [186, 18]
        },
        sidebets: {
            favorites: [],
            mainbets: [186, 18, 910],
        }
    },
    AF: {
        prematch: {
            favorites: [],
            mainbets: [219, 223, 225],
            firsthalf: [60, 66, 68]
        },
        live: {
            favorites: [],
            mainbets: [219, 223, 225],
            firsthalf: [60, 66, 68]
        },
        sidebets: {
            favorites: [],
            mainbets: [219, 223, 225, 47, 931, 932, 933, 934, 1173, 1174, 959],
            firsthalf: [60, 66, 68],
            player: [759, 760, 761, 762, 764, 765, 767, 914, 915, 916, 917, 918, 919, 920, 1234, 1235],
        }
    },
    RG: {
        prematch: {
            favorites: [],
            mainbets: [1, 16, 18],
        },
        live: {
            favorites: [],
            mainbets: [1, 16, 18],
        },
        sidebets: {
            favorites: [],
            mainbets: [1, 16, 18],
        }
    },
    BB: {
        prematch: {
            favorites: [],
            mainbets: [251, 256, 258],
        },
        live: {
            favorites: [],
            mainbets: [251, 256, 258],
        },
        sidebets: {
            favorites: [],
            mainbets: [251, 256, 258],
            player: [768, 770, 771, 772, 773, 774, 921, 922, 923, 924, 1236, 1237, 1238],
        }
    },
    SN: {
        prematch: {
            favorites: [],
            mainbets: [186, 493, 494],
        },
        live: {
            favorites: [],
            mainbets: [186, 493, 494]
        },
        sidebets: {
            favorites: [],
            mainbets: [186, 493, 494],
        }
    },
    TT: {
        prematch: {
            favorites: [],
            mainbets: [186, 237, 238],
        },
        live: {
            favorites: [],
            mainbets: [186, 237, 238]
        },
        sidebets: {
            favorites: [],
            mainbets: [186, 237, 238],
        }
    },
    CR: {
        prematch: {
            favorites: [],
            mainbets: [186],
        },
        live: {
            favorites: [],
            mainbets: [186]
        },
        sidebets: {
            favorites: [],
            mainbets: [186],
            player: [999, 1147]
        }
    },
    CS: {
        prematch: {
            favorites: [],
            mainbets: [186, 327, 328],
        },
        live: {
            favorites: [],
            mainbets: [186, 328, 327]
        },
        sidebets: {
            favorites: [],
            mainbets: [186, 327, 328],
        }
    },
    DT: {
        prematch: {
            favorites: [],
            mainbets: [186, 327, 328],
        },
        live: {
            favorites: [],
            mainbets: [186, 328, 327]
        },
        sidebets: {
            favorites: [],
            mainbets: [186, 327, 328],
        }
    },
    LL: {
        prematch: {
            favorites: [],
            mainbets: [186, 327, 328],
        },
        live: {
            favorites: [],
            mainbets: [186, 328, 327]
        },
        sidebets: {
            favorites: [],
            mainbets: [186, 327, 328],
        }
    },
    VB: {
        prematch: {
            favorites: [],
            mainbets: [186, 237, 238],
        },
        live: {
            favorites: [],
            mainbets: [186, 237, 238],
        },
        sidebets: {
            favorites: [],
            mainbets: [186, 237, 238],
        }
    },
    BV: {
        prematch: {
            favorites: [],
            mainbets: [186, 237, 238],
        },
        live: {
            favorites: [],
            mainbets: [186, 237, 238]
        },
        sidebets: {
            favorites: [],
            mainbets: [186, 237, 238],
        }
    },
    BM: {
        prematch: {
            favorites: [],
            mainbets: [186, 237, 238],
        },
        live: {
            favorites: [],
            mainbets: [186, 237, 238]
        },
        sidebets: {
            favorites: [],
            mainbets: [186, 237, 238],
        }
    },
    MM: {
        prematch: {
            favorites: [],
            mainbets: [186, 18],
        },
        live: {
            favorites: [],
            mainbets: [186, 18]
        },
        sidebets: {
            favorites: [],
            mainbets: [186, 18, 910],
        }
    },
    SQ: {
        prematch: {
            favorites: [],
            mainbets: [186],
        },
        live: {
            favorites: [],
            mainbets: []
        },
        sidebets: {
            favorites: [],
            mainbets: [186],
        }
    },
    WP: {
        prematch: {
            favorites: [],
            mainbets: [1, 18],
        },
        live: {
            favorites: [],
            mainbets: []
        },
        sidebets: {
            favorites: [],
            mainbets: [1, 18],
        }
    },
    FL: {
        prematch: {
            favorites: [],
            mainbets: [1, 18],
        },
        live: {
            favorites: [],
            mainbets: []
        },
        sidebets: {
            favorites: [],
            mainbets: [1, 18],
        }
    },
    AR: {
        prematch: {
            favorites: [],
            mainbets: [186, 16, 18],
        },
        live: {
            favorites: [],
            mainbets: [186, 16, 18],
        },
        sidebets: {
            favorites: [],
            mainbets: [186, 16, 18],
        }
    },
    BD: {
        prematch: {
            favorites: [],
            mainbets: [1, 18],
        },
        live: {
            favorites: [],
            mainbets: [1, 18],
        },
        sidebets: {
            favorites: [],
            mainbets: [1, 18],
        }
    },
    FS: {
        prematch: {
            favorites: [],
            mainbets: [1, 18],
        },
        live: {
            favorites: [],
            mainbets: [1, 18],
        },
        sidebets: {
            favorites: [],
            mainbets: [1, 18],
        }
    },
    CU: {
        prematch: {
            favorites: [],
            mainbets: [186],
        },
        live: {
            favorites: [],
            mainbets: []
        },
        sidebets: {
            favorites: [],
            mainbets: [186],
        }
    },
    PS: {
        prematch: {
            mainbets: [9001, 9002, 9018],
            favorites: [],
        },
        live: {
            mainbets: [],
            favorites: [],
        },
        sidebets: {
            mainbets: [9001, 9002, 9016, 9018],
            favorites: [],
        }
    },
    CH: {
        prematch: {
            favorites: [],
            mainbets: [1],
        },
        live: {
            favorites: [],
            mainbets: []
        },
        sidebets: {
            favorites: [],
            mainbets: [1],
        }
    },
    CR: {
        prematch: {
            favorites: [],
            mainbets: [1],
        },
        live: {
            favorites: [],
            mainbets: []
        },
        sidebets: {
            favorites: [],
            mainbets: [1],
        }
    },
    B3: {
        prematch: {
            favorites: [],
            mainbets: [219],
        },
        live: {
            favorites: [],
            mainbets: []
        },
        sidebets: {
            favorites: [],
            mainbets: [219],
        }
    },
    BV: {
        prematch: {
            favorites: [],
            mainbets: [186, 237, 238],
        },
        live: {
            favorites: [],
            mainbets: []
        },
        sidebets: {
            favorites: [],
            mainbets: [186, 237, 238],
        }
    },
    AT: {
        prematch: {
            favorites: [],
            mainbets: [],
        },
        live: {
            favorites: [],
            mainbets: []
        },
        sidebets: {
            favorites: [],
            mainbets: [],
        }
    },
    WR: {
        prematch: {
            favorites: [],
            mainbets: [],
        },
        live: {
            favorites: [],
            mainbets: []
        },
        sidebets: {
            favorites: [],
            mainbets: [],
        }
    },
    FH:{
        prematch: {
            favorites: [],
            mainbets: [1, 18],
        },
        live: {
            favorites: [],
            mainbets: [1, 18]
        },
        sidebets: {
            favorites: [],
            mainbets: [1, 18],
        }
    }
};

export default marketSetConfig;


