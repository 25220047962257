/**
 * Get current locale and return them for API request headers
 */

export default function acceptLanguage() {
  const lang = localStorage.getItem('lang');
  let locale;

  switch (lang) {
    default:
    case 'en':
      locale = 'en-US,en;'
      break;
    // case 'de':
    //   locale = 'de-DE,de;'
    //   break;
    case 'fi':
      locale = 'en-US,en;'
      break;
    case 'et':
      locale = 'en-US,en;'
      break;
  }

  if (lang) {
    return { "Accept-Language": locale };
  } else {
    return {};
  }
}

