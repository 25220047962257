/**
 * All country configurations
 */

// Full ISO Alpha-2 codes from https://www.nationsonline.org/oneworld/country_code_list.htm
const favourites = [ 'EE', 'SE', 'DE', 'AT', 'TR', 'GR', 'IT', 'HR', 'RS', 'BA'];
const excludeNationality = ['AFG', 'AGO', 'AUS', 'BEL', 'BGR', 'CZE', 'DNK', 'EST', 'FRA', 'GGY', 'HKG', 'HUN', 'IRN', 'ITA', 'LBN', 'NLD', 'PRK', 'PHL', 'ROU', 'RUS', 'PRT', 'SWE', 'SGP', 'ESP', 'SYR', 'TWN', 'USA', 'GBR', 'UKR', 'YEM', 'UMI', 'VIR'];
const excludeResidenceCountry = ['AFG', 'AGO', 'AUS', 'BEL', 'BGR', 'CZE', 'DNK', 'EST', 'FRA', 'GGY', 'HKG', 'HUN', 'IRN', 'ISR', 'ITA', 'LBN', 'NLD', 'PRK', 'PHL', 'ROU', 'RUS', 'PRT', 'SWE', 'SGP', 'ESP', 'SYR', 'TWN', 'USA', 'GBR', 'UKR', 'YEM', 'UMI', 'VIR'];

let i18nCountries = require('i18n-iso-countries');
i18nCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
const allCountries = i18nCountries.getNames('en');
const sortedCountries = {};
for (let key in favourites){
    sortedCountries[favourites[key]] = allCountries[favourites[key]];
}

sortedCountries['---'] = '──────────────────';

for (let key in allCountries){
    if(!sortedCountries[key]){
    sortedCountries[key] = allCountries[key];
    }
}

const allowedNationalities = Object.entries(sortedCountries).filter(function (i) {

    return !excludeNationality.includes(i[0])
});

const allowedResidenceCountries = Object.entries(sortedCountries).filter(function (i) {

    return !excludeResidenceCountry.includes(i[0])
});

const countryConfig = {
    all: Object.entries(sortedCountries),
    allowedNationalities: allowedNationalities,
    allowedResidenceCountries: allowedResidenceCountries,
    getTripple: (code) => i18nCountries.alpha2ToAlpha3(code),
}

export default countryConfig;