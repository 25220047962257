//Merge overrided components
import merge from "lodash/merge";

import { defaultProps } from "./default-props";
import { button } from "./components/button";
import { badge } from "./components/badge";
import { appBar } from "./components/appbar";
import { alert } from "./components/alert";
import { typography } from "./components/typography";
import { tabs } from "./components/tabs";
import { list } from "./components/list";
import { modal } from "./components/modal";
import { dialog } from "./components/dialog";
import { paper } from "./components/paper";
import { cssBaseline } from "./components/css-baseline";
import { drawer } from "./components/drawer";

export function componentsOverrides(theme) {
  const components = merge(
    defaultProps(theme),
    button(theme),
    badge(theme),
    appBar(theme),
    alert(theme),
    typography(theme),
    tabs(theme),
    list(theme),
    modal(theme),
    dialog(theme),
    paper(theme),
    cssBaseline(theme),
    drawer(theme)
  );
  return components;
}
