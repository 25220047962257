/**
 * BonusTermsSlots - Fin version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import { ListItem } from "@mui/material";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class BonusTermsSlots extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component={"span"}>
          <div id="legalcontent" className={classes.root}>
            <h1>NUBET.COM KASINO BONUS KÄYTTÖEHDOT</h1>
            Suomenkielinen versio 1.1 Viimeisin päivitys 27.1.2023.<br></br>
            <br></br>
            <h3 id="General">1. YLEISTÄ</h3>
            1.1. Nämä kasinobonusehdot koskevat Nubet-kasinoa. Kasinobonusta ei voi käyttää muihin tuotteisiin, kuten urheiluvedonlyönti- tai live-vedonlyöntituotteisiin. Jos sinulla on aktiivinen kasinobonus, voit lyödä
            vetoa Nubetin urheiluvedonlyönnissä vain, jos talletat lisää rahaa.<br></br>
            <br></br>
            1.2. Näissä kasinobonusehdoissa selitetyn kasinobonuksen myöntäjä on Nubet verkkosivustolla Nubet.com, jota ylläpitää Vana Lauri OÜ, Virossa rekisteröidyn yrityksen rekisteröintinumerolla 16427120
            rekisteröity yritys, jonka rekisteröity osoite on Roseni 10-135, 10111 Tallinna, Viro (jäljempänä "Nubet" tai "Yhtiö"). Vana Lauri OÜ:llä on Viron vero- ja tullihallituksen myöntämä toimilupa ja sen valvonta,
            ja sillä on seuraavat toimiluvat: HKT000066 (myöntämispäivä: 10.07.2022) ja HKT000067 (myöntämispäivä: 10.07.2022).<br></br>
            <br></br>
            1.3. Nubet voi myöntää sinulle minkä tahansa tyyppistä kasinobonusluottoa tai muunlaisia bonuksia, kuten ilmaiskierroksia (jäljempänä myös "bonusraha", "bonus" tai "kasinobonus"), jos tietyt ehdot täyttyvät.
            Pelaaja päättää itse, haluaako hän käyttää bonuksen, ja pelaaja voi halutessaan kieltäytyä bonuksesta jättämällä bonustarjouksen käyttämättä. Bonuksen käyttöön liittyviä vedonlyöntiehtoja (jäljempänä myös
            "vedonlyönti") sovelletaan kaikkiin myönnettyihin kasinobonuksiin, ja vedonlyöntisäännöistä ilmoitetaan pelaajalle etukäteen verkkosivustolla ja sähköpostitse. Myönnetty kasinobonus hyvitetään automaattisesti
            pelitilille bonusrahana, jota sitovat näissä kasinobonusehdoissa määritellyt säännöt.<br></br>
            <br></br>
            <h3 id="Generalbonus">2. YLEISET KASINO BONUS KÄYTTÖEHDOT</h3>
            2.1. Näitä yleisiä kasinobonusehtoja sovelletaan kaikkiin Nubetin myöntämiin kasinobonuksiin. Joillakin bonustyypeillä on erikseen määritellyt ehdot, jotka voivat poiketa yleisistä kasinobonusehdoista, ja ne
            selitetään omassa kappaleessaan näissä kasinobonusehdoissa viitaten bonustyyppiin, esim. kasinon tervetuliaisbonus tai kasinon talletusbonus. Jos eroavaisuuksia ilmenee, sovelletaan bonustyypeittäin
            määriteltyjä ehtoja ja edellytyksiä. Huomaa, että muun muassa vähimmäiskierrätysvaatimukset ja vähimmäistalletusmäärät voivat vaihdella eri bonustyypeissä.<br></br>
            <br></br>
            2.2. Nubet haluaa tarjota kasinobonuksen kaikille pelaajilleen. Yhtiö voi kuitenkin harkintansa mukaan sulkea yksittäisiä pelaajia tarjouksen ulkopuolelle. Yhtiöllä on yksinomainen harkintavalta mitätöidä
            bonukset ja niihin liittyvät voitot, jos yhtiö näkee, että pelaaja ei noudata bonussääntöjä. Yhtiö voi myös muuttaa Bonuksen ehtoja tai lopettaa sen tarjoamisen milloin tahansa ilman ennakkoilmoitusta.
            <br></br>
            <br></br>
            2.3. Nubet Kasino Bonus on voimassa ja se on käytettävä tietyn kampanjajakson aikana. Jos sitä ei käytetä, se vanhenee, eikä sitä voi käyttää sen jälkeen.<br></br>
            <br></br>
            2.4. Nubet Kasino Bonus on saatavilla vain kerran asiakasta, pelitiliä, kotitaloutta, talletuksen tekemiseen käytetyn rahansiirtomenetelmän ja/tai tilin omistajaa, jaettua tietokonetta (yliopisto, työpaikka,
            internetkahvila jne.) ja/tai IP-osoitetta kohden.<br></br>
            <br></br>
            2.5. Nubet Kasino Bonusta ei voi yhdistää muihin bonuksiin, ellei nimenomaisesti toisin mainita, eikä sitä voi käyttää yhdessä muiden kampanjoiden tai kampanjoiden kanssa.<br></br>
            <br></br>
            2.6. Sähköpostitse lähetetyn Nubet kasinon bonuskampanjatarjouksen voi lunastaa vain bonussähköpostin alkuperäinen vastaanottaja. Bonuksen vastaanottavan ja käyttävän sähköpostiosoitteen on oltava sama kuin
            pelitiliä luotaessa. Nubet pidättää oikeuden tutkia, onko kasinobonustarjouksen käyttänyt pelitilin haltija saanut bonuskoodin henkilökohtaisesti pelitilillä käytettyyn sähköpostiosoitteeseen. Tämän säännön
            rikkominen voi johtaa bonuksen ja siihen liittyvien voittojen takavarikointiin.<br></br>
            <br></br>
            2.7. Nubet-kasinobonus, joka on sidottu talletuksen tekemiseen, on voimassa vain silloin, kun käytetään Nubetin tarjoamia maksutapoja, joita ei ole nimenomaisesti suljettu pois kasinobonustarjouksen
            maksutapana. Tilinhaltijan ja maksutavan haltijan on oltava sama. Pelitilin luottosaldoa, joka on peräisin pelaajan tekemästä talletuksesta ilman bonuksen käyttöä, eikä sitä siten sido bonusehdot, kutsutaan
            oikean rahan saldoksi (jäljempänä <b>"Oikean rahan saldo"</b>).<br></br>
            <br></br>
            2.8. Nubet Kasino Bonus hyvitetään pelitilisi saldoon. Jos kasinobonukseen liittyy kierrätys, pelitilin saldoa ei voi nostaa tai muuntaa oikean rahan saldoksi ennen kuin kierrätys on suoritettu loppuun
            bonusehdoissa määritellyllä tavalla. Jos kasinobonus myönnetään talletukseen, tämä koskee myös talletettua summaa, sillä molemmat ovat sidottuja kasinobonuksen ehtoihin.<br></br>
            <br></br>
            2.9. Jos talletetaan enemmän kuin kasinobonustarjouksessa määritelty euromääräinen enimmäismäärä, koko talletussumma on sidottu kasinobonuksen ehtoihin. Esimerkki: Jos saat 100 %:n 100 euroon asti ulottuvan
            kasinobonuksen ja talletat 150 euroa, 150 euroa on sidottu kasinobonuksen kierrätysvaatimuksiin. Tässä esimerkissä kasinobonuksen määrä olisi 100 euroa.<br></br>
            <br></br>
            2.10. Jos toisin ei ole mainittu, kaikki saadut kasinobonukset on kierrätettävä vähintään 40 kertaa (40x) ennen kuin ne voidaan maksaa tai niitä voidaan yrittää nostaa. bonusrahalla tehtyjä voittoja ei
            makseta ennen kuin bonuksen kierrätysehdot täyttyvät. Jos bonusta yritetään nostaa ennen kuin bonuksen kierrätysvaatimukset on täytetty, kaikki bonuksesta saadut voitot ja itse bonus menetetään.<br></br>
            <br></br>
            2.11. Ellei toisin mainita, bonuksen kierrätysvaatimukset on täytettävä 90 päivän kuluessa bonuksen saamisesta. Jos bonusehtoja ei täytetä ajoissa, Nubet pidättää oikeuden peruuttaa bonuksen määrän ja kaikki
            siitä saadut voitot.<br></br>
            <br></br>
            2.12. Jos ilmaiskierroksia myönnetään, niiden vähimmäisarvo on 0,10 euroa per kierros, ellei toisin mainita. Kierros, jota ei käytetä määritellyn voimassaoloajan aikana, menetetään. Kaikki ilmaiskierroksista
            saadut voitot katsotaan kasinobonushyvityksiksi, ja niiden on täytettävä kierrätysvaatimukset.<br></br>
            <br></br>
            2.13. Nubet valvoo kaikkien bonusten käyttöä epäsäännöllisten pelitapojen varalta.<br></br>
            <br></br>
            2.14. Kasinobonuksen väärinkäyttö on bonusehtojen rikkominen. Nubet olettaa bonusehtojen rikkomisen seuraavissa tapauksissa:<br></br>
            <ListItem>bonuksen moninkertainen käyttö saman henkilön toimesta tai samaa IP:tä tai päätelaitetta käyttäen;</ListItem>
            <br></br>
            <ListItem>bonustarjouksen epäilty järjestäytynyt käyttö.</ListItem>
            <br></br>
            <br></br>
            2.15. Nubet pidättää oikeuden takavarikoida kaikki käyttämättömät bonushyvitysvoitot, lukuun ottamatta talletettua oikean rahan saldoa, jos epäillään epäsäännöllistä pelaamista tai riippuvuutta aiheuttavaa
            käytöstä. Tällaisessa tilanteessa oikean rahan luotto palautetaan.<br></br>
            <br></br>
            2.16. Bonusta ei hyvitetä, jos sinut on suljettu Nubet-tarjouksen ulkopuolelle tai poistettu tarjouksen piiristä.<br></br>
            <br></br>
            2.17. Kasinobonus on voimassa, kunnes kierrätys on suoritettu tai kasinobonus peruutetaan.<br></br>
            <br></br>
            2.18. Nubet pidättää oikeuden muuttaa, keskeyttää tai peruuttaa bonuksen ja kampanjan keston milloin tahansa.<br></br>
            <br></br>
            2.19. Seuraavien pelien panostusta ei lasketa bonuksiin sisältyvään panostusvaatimukseen: Absolootly Mad Mega Moolah, Agent Royale, Age of Conquest, Astro Legends Lyra & Erion, Blazing Bull 2, Bakers Treat,
            Book Of 99, Castle Builder II, Cauldron, Double Dragons,Cazino Cosmos, Dwarf Mine, EggOmatic, Eye Of The Kraken, Gemix, Goblins and Gemstones, Magic of Sahara, Mad Monsters, Reel Rush II, Dynamite Riches, 11
            Champions, Victoria Wild, Ducks Till Dawn, The Wish Master, Viking Runecraft, White Rabbit, Sea Hunter, Mystery Joker 6000, Extra Chilli, Wild Swarm, Dark Vortex, Jingle Spin, Zombie Hoard, Mahjong 88, Le
            Kaffee Bar, Narcos, Village People Macho Moves, Solar Queen, Razor Shark, Tiki Mania, Jewel Scarabs, Crystal Cavern, Blazing Bull, Solar Temple, Wilderland, Bronco Spirit, Hellcatraz, Football glory, All Star
            Knockout Ultra Gamble, Marching Legions, Banana Drop, Maui Millions, Lil Devil, Bombuster, Jungle Gorilla, 5 Families, Rage of the Seas, Lucky Fridays, Solar King, Emerald King, Hoard of Poseidon, Golden
            Tsar, Leprechauns Magic Megaways, Augustus, Jingle Bells Power Reels, Sylvan Spirits, Golden Stallion, Frost Queen Jackpots, Rome The Golden Age, Rome The Golden Age, Pirates Plenty MegaWays, Pearls Of India,
            The Hot Offer, Tower Quest, Rage To Riches, Robin Hood Shifting Riches, Scudamores Super Stakes, Scrooge, Serengeti Kings, Spina Colada, Sweet Alchemy, Tuts Twister, Vikings Go Berzerk, Wolf Hunters. 2.20.
            Sovelletaan Nubetin ehtoja.<br></br>
            <br></br>
            <h3 id="Welcome">3. KASINO TERVETULIAISBONUS KÄYTTÖEHDOT</h3>
            3.1. Kasinon tervetuliaisbonustarjousta ei voi käyttää yhdessä muiden tarjousten kanssa, eikä sitä voi käyttää yhdessä toisen kasinon tervetuliaisbonustarjouksen kanssa.<br></br>
            <br></br>
            3.2. Nubet-kasinon tervetuliaisbonus on voimassa vain kampanja-aikana ensimmäiselle tai toiselle vähintään 20 euron suuruiselle talletukselle uudelle pelitilille.<br></br>
            <br></br>
            3.3. Pelaajalla on 30 päivää aikaa rekisteröinnistä tehdä talletus ja käyttää mikä tahansa kasinon tervetuliaisbonustarjous.<br></br>
            <br></br>
            3.4. Nubet-kasinon tervetuliaisbonus hyvitetään asiakkaalle heti talletuksen yhteydessä vastaamaan 100 % talletussummasta määritellyn enimmäismäärän puitteissa.<br></br>
            <br></br>
            3.5. Kasinon tervetuliaisbonuksen summa on kierrätettävä vähintään 40 kertaa (40x) Nubet-kasinolla ennen kotiutuspyyntöä. Laskentaesimerkki: Talletat 20 euroa, saat meiltä 20 euroa bonushyvityksenä. Jotta
            voit pyytää kotiutusta, kasinobonuksen kierrättäminen edellyttää siis 800 euron kierrätystä.<br></br>
            <br></br>
            3.6. Kasinon tervetuliaisbonuksen ehdoissa määritelty kierrätysvaatimus on täytettävä 90 päivän kuluessa (Kasinon tervetuliaisbonuskausi) ensimmäisestä talletuksesta. Kasinon tervetuliaisbonuskauden päätyttyä
            Kasinon tervetuliaisbonus ja kaikki siihen liittyvät voitot menetetään automaattisesti, eikä niitä voi palauttaa.<br></br>
            <br></br>
            3.7. Kasinon tervetuliaisbonuksen määrä, mukaan lukien siihen liittyvä talletus ja voitot, näkyy Nubet-tilillä käyttämättömänä hyvityssaldona, kunnes määritetyt kierrätysvaatimukset on täytetty. Jos
            kotiutusta pyydetään ennen kuin kasinon tervetuliaisbonuksen kierrätysvaatimus on täyttynyt, se johtaa kasinon tervetuliaisbonuksen ja siihen liittyvien voittojen mitätöintiin ja menetykseen ilman erillistä
            ilmoitusta.<br></br>
            <br></br>
            3.8. Kasinon tervetuliaisbonuksen kierrätysvaatimukset voidaan täyttää vain, jos pelaat Nubet-kasinon pelejä, joita ei ole erikseen suljettu kierrätysvaatimusten ulkopuolelle. Pelit, jotka on suljettu
            kierrätysehtojen ulkopuolelle, on eritelty käyttöehdoissa.<br></br>
            <br></br>
            <h3 id="Deposit">4. KASINO TALLETUSBONUS KÄYTTÖEHDOT</h3>
            4.1. Kasinon talletusbonus on valinnainen, ja pelaaja voi valita sen talletuksen tekemisen yhteydessä. Varmista, että noudatat kasinotalletusbonuskoodin käyttöön liittyviä ohjeita. Käytettävissäsi olevat eri
            kasinotalletusbonusvaihtoehdot näytetään sinulle talletuksen tekemisen yhteydessä.<br></br>
            <br></br>
            4.2. Minimitalletus bonuksen lunastamiseksi on 20 euroa.<br></br>
            <br></br>
            4.3. Kasinotalletusbonus on voimassa vain sen kampanja-aikana, eikä sitä voi lunastaa sen jälkeen.<br></br>
            <br></br>
            4.4. Nubet-kasinon talletusbonustarjouksen, joka on lähetetty sähköpostitse, tiliviestillä tai muulla ilmoituksella, voi lunastaa vain kasinon talletusbonustarjouksen alkuperäinen vastaanottaja. Nubet
            pidättää oikeuden tutkia, onko kasinon talletusbonustarjouksen käyttänyt pelitilin haltija saanut bonuskoodin henkilökohtaisesti, ja jos tätä sääntöä rikotaan, mitätöidä ja takavarikoida bonus ja siihen
            liittyvät voitot.<br></br>
            <br></br>
            4.5. Sinulle ilmoitetaan kasinotalletus-bonuksen ehdoista (kuten kampanja-aika, kasinotalletus-bonuksen prosenttiosuus talletussummaa vastaavasta bonussummasta ja bonuksen euromääräinen enimmäismäärä)
            henkilökohtaisella sähköpostiviestillä, tiliviestillä, tekstiviestillä tai push-viestillä.<br></br>
            <br></br>
            4.6. Kasinotalletusbonuksen summa on kierrätettävä vähintään 40 kertaa (40x) Nubet-kasinolla ennen kotiutuspyyntöä. Laskentaesimerkki: Talletat 20 euroa, saat meiltä 20 euroa bonushyvityksenä. Jotta voit
            pyytää kotiutusta, sinun on siis kierrätettävä kasinobonus 800 euroa.<br></br>
            <br></br>
            4.7. Kasinotalletus-bonuksen ehdoissa määritelty kierrätysvaatimus on täytettävä 30 päivän kuluessa (kasinotalletus-bonuskausi) ensimmäisestä talletuksesta. Heti kun kasinotalletus-bonuskausi on päättynyt,
            kasinotalletus-bonus ja kaikki siihen liittyvät voitot menetetään automaattisesti, eikä niitä voi saada takaisin.<br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(BonusTermsSlots));
