import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const CategoryJackpotsIcon = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 200 200"
      {...props}
    >
      <path d="M89.4,62.6c-44.2,8.3-68.8,78.2-53.6,120.9h128.4c15.2-42.7-9.5-112.5-53.7-120.9H89.4z M112.8,57.6H87.1c-3.3,0-6-2.7-6-6
	l0,0c0-3.3,2.8-6,6-6h25.7c3.3,0,6,2.7,6,6l0,0C118.8,54.9,116.1,57.6,112.8,57.6L112.8,57.6z M78.2,25c0,5.5,4.6,11.8,11.2,15.4
	h21.1c6.7-3.6,11.2-10,11.2-15.4s-0.7-5.9-1.8-8.5H80C78.9,19.1,78.2,19.6,78.2,25L78.2,25L78.2,25z M107.8,73.1v6
	c13.3,1.1,23.6,12.4,23.6,25.8h-15.5c0-4.9-3.4-9.1-8.1-10.1v20.7c31.6,2.8,30.6,48.9,0,51.6v6H92.3v-6
	C79,166,68.7,154.7,68.7,141.3h15.5c0,4.9,3.3,9.2,8.1,10.1v-20.7c-31.7-2.7-30.6-48.9,0-51.6v-6L107.8,73.1L107.8,73.1z
	 M107.8,131.1v20.3C118.3,149.1,118.7,133.6,107.8,131.1L107.8,131.1z M92.3,115.1V94.8C81.7,97.2,81.3,112.6,92.3,115.1
	C92.3,115.1,92.3,115.1,92.3,115.1z"/>
    </SvgIcon>
  );
};

export default CategoryJackpotsIcon;
