import { Box, Typography } from "@mui/material";

const EmptyScreen = ({ message, displayBackground }) => {
  return (
    <Box
      sx={{
        width: "94%",
        height: "50vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        margin: "0 1em",
        padding: "1em",
        borderRadius: "0.5em",
        ...(displayBackground && {
          backgroundImage: "url('https://storage.googleapis.com/nubet-prod-content-news/bg_norace_2.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }),
        "@media (hover:hover)": {
          width: "98.5%",
        },
      }}
    >
      <Typography mb={3} variant="norace" sx={{ textAlign: "center" }}>
        {message}
      </Typography>
    </Box>
  );
};

export default EmptyScreen;
