/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconPS = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g>
					<path d="M1371.3,599.6c5.9-18.3,8.2-31.1,11.1-47.7c24.4-145.5-41.7-290.7-147.8-324.3c-106.1-33.6-212,57.1-236.5,202.6
		c-4.4,26.2-5.9,52.3-4.7,77.8l-4.1,1038L1371.3,599.6z"/>
					<path d="M1696.5,984.9c71.5-108.3,64.5-281.8-15.9-387.7c-80.3-105.8-203.3-103.8-274.9,4.5c-12.9,19.5-23.2,41.1-31,64
		l-365.2,902.2l660.9-549.7C1682,1006.6,1688.4,997.2,1696.5,984.9z"/>
					<path d="M1930.2,1241c-33-149.7-140.2-236.9-239.6-194.8c-17.9,7.5-34.2,18.8-49,33l-626.9,517l761.1,2c13.9-1.8,22.7-5.2,34.1-10
		C1909.2,1546.2,1963.1,1390.7,1930.2,1241z"/>
					<path d="M264.2,1046.2c-99.4-42.1-206.7,45.2-239.6,194.8c-33,149.6,20.9,305.1,120.3,347.2c11.4,4.7,22.8,7.8,34.1,10l762.4-1.8
		l-628.3-517.3C298.5,1065,282.1,1053.7,264.2,1046.2z"/>
					<path d="M959.2,430.2c-24.5-145.5-130.4-236.1-236.5-202.6c-106.1,33.6-172.3,178.8-147.8,324.3c2.8,16.6,5.2,29.3,11.1,47.7
		L967,1546l-3.1-1038C965,482.6,963.6,456.4,959.2,430.2z"/>
					<path d="M551.3,601.7c-71.5-108.3-194.6-110.2-274.9-4.5c-80.3,105.9-87.4,279.4-15.9,387.7c8.2,12.3,14.6,21.7,26.1,33.3
		l660.9,549.7L582.3,665.6C574.5,642.7,564.2,621.2,551.3,601.7z"/>
				</g>
			</svg>
		</div>
	);
};

export default IconPS;
