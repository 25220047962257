import { useEffect } from "react";
import { Grid2, Paper, Stack, styled, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { getRacingResultsAsync } from "../../../../redux-store/sports-reducer";
import Iconify from "../../../iconify";
import { Translate } from "react-redux-i18n";

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.results1,
  padding: theme.spacing(1),
}));

const RacingResults = ({ leagueId, results }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!Object.keys(results).length) {
      dispatch(getRacingResultsAsync(leagueId));
    }
  }, [leagueId]);

  const resultsKeys = Object.keys(results).reverse();

  return (
    <Stack>
      {resultsKeys.map((resultDate) => {
        const races = results[resultDate];
        return (
          <Stack key={resultDate} mb={2} spacing={2}>
            <Typography variant="h4" color="primary.featured2">
              {resultDate}
            </Typography>
            <Grid2 container spacing={2}>
              {races.map((race) => (
                <Grid2 item size={{ xs: 12, md: 6, lg: 4 }} key={race.gameid}>
                  <StyledPaper>
                    <Typography variant="h4" component="p" mb={1}>
                      {race?.game_title}
                    </Typography>
                    <Stack direction="row" alignItems="center" gap={1} justifyContent="space-between">
                      <Typography variant="subtitle1" component="p" color="primary.featured2">
                        <Translate value="markets.label_1" />
                      </Typography>
                      <Iconify icon="material-symbols:keyboard-double-arrow-right" sx={{ width: 40, color: "primary.featured2" }} />
                      <Typography variant="subtitle1" component="p" color="primary.featured2">
                        {race.winner}
                      </Typography>
                    </Stack>
                  </StyledPaper>
                </Grid2>
              ))}
            </Grid2>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default RacingResults;
