import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const CategoryHomeIcon = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 200 200"
      {...props}
    >
      <path d="M178.1,81.5l-20.5-16.7V38c0-3-2.5-5.5-5.5-5.5h-12.9c-3,0-5.5,2.5-5.5,5.5v7.3L106.3,23c-2-1.6-4.6-2.5-7.4-2.5
	c-2.8,0-5.4,0.9-7.3,2.5L19.9,81.5c-3.3,2.7-3.1,5-2.7,6.1c0.4,1.1,1.7,3.1,5.9,3.1H33v76.9c0,5.5,4.5,10,9.9,10h31
	c5.4,0,9.7-4.4,9.7-10V136c0-2.7,2.5-5.2,5.2-5.2h20.9c2.6,0,4.7,2.3,4.7,5.2v31.6c0,5.4,4.7,10,10.2,10h30.5c5.5,0,9.9-4.5,9.9-10
	V90.6h9.8c4.2,0,5.5-1.9,5.9-3.1C181.2,86.4,181.4,84.1,178.1,81.5z"/>
    </SvgIcon>
  );
};

export default CategoryHomeIcon;
