/**
 * Settings -> Limit page component
 * Set / Remove Stake, Loss, Deposit limits
 */

import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import { Translate } from "react-redux-i18n";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LimitsForm from "./LimitsForm";
import LimitsBetsForm from "./LimitsBetsForm";
import { getLimits, saveLimits, deleteLimit, updateLimit } from "../../actions";

import "./Limits.css";
import withRouter from "../../helpers/withRouter";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    "& .MuiContainer-root": {
      padding: 2,
    },
    "& .MuiGrid-container": {
      margin: "1em 0 0 0",
    },
    "& .MuiGrid-item": {
      margin: "0.6em 0",
    },
    "& .MuiTypography-body2": {
      fontSize: "0.7rem",
    },
  },
  subMenu: {
    flexGrow: 1,
    width: "100%",
    // position: 'fixed',
    // bottom: '66px',
    zIndex: 1,

    "& .MuiAppBar-root": {
      background: "transparent",
      color: "var(--color)",
    },
  },
  actionButton: {
    textDecoration: "underline",
    cursor: "pointer",
  },
});

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

class Limits extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
    };
  }

  componentDidMount() {
    const { auth, locale, navigate } = this.props;
    auth.isSignedIn
      ? this.props.getLimits(this.constructor.name)
      : navigate(`/${locale}/sign-in`);
  }

  componentDidUpdate() {
    const { auth, locale, navigate } = this.props;
    if (!auth.isSignedIn) navigate(`/${locale}/sign-in`);
  }

  menuItemChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  menuItemChangeIndex = (newValue) => {
    this.setState({ value: newValue });
  };

  numberWithCommas = (x) => {
    /**
     * Format number with comma -> e.g : 1000 -> 1,000
     */
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return x;
  };

  deleteLimit = (type, period) => {
    /**
     * Delete existing limit with type and period
     */
    const data = {
      limitType: type,
      limitPeriod: period,
    };

    this.props.deleteLimit(data, this.constructor.name);
  };

  updateLimit = (type, period, operation) => {
    /**
     * Update existing limit with type and period
     */
    const data = {
      operation: operation,
      limitType: type,
      limitPeriod: period,
    };

    this.props.updateLimit(data, this.constructor.name);
  };

  renderLimitTypeOverview(type) {
    const { limits, classes, host } = this.props;

    if (!limits) return <div>Loading...</div>;

    let currentLimit = limits.settings[type];
    return (
      <div className={classes.root}>
        <Container>
          <Grid container>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                style={{ textAlign: "center", color: "var(--color-contrast)" }}
              >
                <Translate value="labels.daily" />
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                style={{ textAlign: "center", color: "var(--color-contrast)" }}
              >
                <Translate value="labels.weekly" />
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography
                variant="body2"
                style={{ textAlign: "center", color: "var(--color-contrast)" }}
              >
                <Translate value="labels.monthly" />
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">
                <Translate value="labels.active_limits" />
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                style={{ textAlign: "center" }}
                component="span"
              >
                {currentLimit.daily.active ? (
                  <div>
                    <div>
                      {host.brand === "nubet_se" ? "SEK" : "EUR"}{" "}
                      {this.numberWithCommas(
                        currentLimit.daily.active.limitValue
                      )}
                    </div>
                    {currentLimit.daily.pending ? null : (
                      <div
                        className={classes.actionButton}
                        onClick={this.deleteLimit.bind(this, type, 1)}
                      >
                        Delete
                      </div>
                    )}
                  </div>
                ) : (
                  "-"
                )}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                style={{ textAlign: "center" }}
                component="span"
              >
                {currentLimit.weekly.active ? (
                  <div>
                    <div>
                      {host.brand === "nubet_se" ? "SEK" : "EUR"}{" "}
                      {this.numberWithCommas(
                        currentLimit.weekly.active.limitValue
                      )}
                    </div>
                    {currentLimit.weekly.pending ? null : (
                      <div
                        className={classes.actionButton}
                        onClick={this.deleteLimit.bind(this, type, 7)}
                      >
                        Delete
                      </div>
                    )}
                  </div>
                ) : (
                  "-"
                )}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                style={{ textAlign: "center" }}
                component="span"
              >
                {currentLimit.monthly.active ? (
                  <div>
                    <div>
                      {host.brand === "nubet_se" ? "SEK" : "EUR"}{" "}
                      {this.numberWithCommas(
                        currentLimit.monthly.active.limitValue
                      )}
                    </div>
                    {currentLimit.monthly.pending ? null : (
                      <div
                        className={classes.actionButton}
                        onClick={this.deleteLimit.bind(this, type, 30)}
                      >
                        Delete
                      </div>
                    )}
                  </div>
                ) : (
                  "-"
                )}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">
                <Translate value="labels.pending_limits" />
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2" style={{ textAlign: "center" }}>
                {currentLimit.daily.pending ? (
                  currentLimit.daily.pending ? (
                    currentLimit.daily.pending.remainingTime !== 0 ? (
                      <div>
                        <div>
                          {host.brand === "nubet_se" ? "SEK" : "EUR"}{" "}
                          {this.numberWithCommas(
                            currentLimit.daily.pending.limitValue
                          )}
                        </div>
                        <div>
                          Remaining time:{" "}
                          {currentLimit.daily.pending.remainingTime}m{" "}
                          <div
                            className={classes.actionButton}
                            onClick={this.updateLimit.bind(
                              this,
                              type,
                              1,
                              "cancelLimit"
                            )}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div>
                          {host.brand === "nubet_se" ? "SEK" : "EUR"}{" "}
                          {this.numberWithCommas(
                            currentLimit.daily.pending.limitValue
                          )}
                        </div>
                        <div
                          className={classes.actionButton}
                          onClick={this.updateLimit.bind(
                            this,
                            type,
                            1,
                            "confirmLimit"
                          )}
                        >
                          Confirm
                        </div>
                        <div
                          className={classes.actionButton}
                          onClick={this.updateLimit.bind(
                            this,
                            type,
                            1,
                            "cancelLimit"
                          )}
                        >
                          Cancel
                        </div>
                      </div>
                    )
                  ) : (
                    "-"
                  )
                ) : (
                  "-"
                )}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2" style={{ textAlign: "center" }}>
                {currentLimit.weekly.pending ? (
                  currentLimit.weekly.pending ? (
                    currentLimit.weekly.pending.remainingTime !== 0 ? (
                      <div>
                        <div>
                          {host.brand === "nubet_se" ? "SEK" : "EUR"}{" "}
                          {this.numberWithCommas(
                            currentLimit.weekly.pending.limitValue
                          )}
                        </div>
                        <div>
                          Remaining time:{" "}
                          {currentLimit.weekly.pending.remainingTime}m{" "}
                          <div
                            className={classes.actionButton}
                            onClick={this.updateLimit.bind(
                              this,
                              type,
                              7,
                              "cancelLimit"
                            )}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div>
                          {host.brand === "nubet_se" ? "SEK" : "EUR"}{" "}
                          {this.numberWithCommas(
                            currentLimit.weekly.pending.limitValue
                          )}
                        </div>
                        <div
                          className={classes.actionButton}
                          onClick={this.updateLimit.bind(
                            this,
                            type,
                            7,
                            "confirmLimit"
                          )}
                        >
                          Confirm
                        </div>
                        <div
                          className={classes.actionButton}
                          onClick={this.updateLimit.bind(
                            this,
                            type,
                            7,
                            "cancelLimit"
                          )}
                        >
                          Cancel
                        </div>
                      </div>
                    )
                  ) : (
                    "-"
                  )
                ) : (
                  "-"
                )}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2" style={{ textAlign: "center" }}>
                {currentLimit.monthly.pending ? (
                  currentLimit.monthly.pending ? (
                    currentLimit.monthly.pending.remainingTime !== 0 ? (
                      <div>
                        <div>
                          {host.brand === "nubet_se" ? "SEK" : "EUR"}{" "}
                          {this.numberWithCommas(
                            currentLimit.monthly.pending.limitValue
                          )}
                        </div>
                        <div>
                          Remaining time:{" "}
                          {currentLimit.monthly.pending.remainingTime}m{" "}
                          <div
                            className={classes.actionButton}
                            onClick={this.updateLimit.bind(
                              this,
                              type,
                              30,
                              "cancelLimit"
                            )}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div>
                          {host.brand === "nubet_se" ? "SEK" : "EUR"}{" "}
                          {this.numberWithCommas(
                            currentLimit.monthly.pending.limitValue
                          )}
                        </div>
                        <div
                          className={classes.actionButton}
                          onClick={this.updateLimit.bind(
                            this,
                            type,
                            30,
                            "confirmLimit"
                          )}
                        >
                          Confirm
                        </div>
                        <div
                          className={classes.actionButton}
                          onClick={this.updateLimit.bind(
                            this,
                            type,
                            30,
                            "cancelLimit"
                          )}
                        >
                          Cancel
                        </div>
                      </div>
                    )
                  ) : (
                    "-"
                  )
                ) : (
                  "-"
                )}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }

  renderLimitForm(type) {
    const { limits } = this.props;

    let currentLimit = limits?.settings[type] ? limits.settings[type] : {};
    let stakeLimit = limits?.settings["stake"] ? limits.settings["stake"] : {};

    let initialValues = {
      value:
        stakeLimit["daily"] &&
        stakeLimit["daily"].active &&
        stakeLimit["daily"].active.limitValue
          ? stakeLimit["daily"].active.limitValue
          : "",
      period: "1",
      dailyAmount:
        currentLimit["daily"] &&
        currentLimit["daily"].active &&
        currentLimit["daily"].active.limitValue
          ? currentLimit["daily"].active.limitValue
          : "",
      weeklyAmount:
        currentLimit["weekly"] &&
        currentLimit["weekly"].active &&
        currentLimit["weekly"].active.limitValue
          ? currentLimit["weekly"].active.limitValue
          : "",
      monthlyAmount:
        currentLimit["monthly"] &&
        currentLimit["monthly"].active &&
        currentLimit["monthly"].active.limitValue
          ? currentLimit["monthly"].active.limitValue
          : "",
    };
    if (!limits) return <div>loading...</div>;
    return <LimitsForm type={type} initialValues={initialValues} />;
  }

  renderBetsLimitsForm(type) {
    const { limits } = this.props;
    const initialValues = {
      limitValue: limits.extraData[type === "max_bet" ? 6 : 5].amount,
    };
    return <LimitsBetsForm type={type} initialValues={initialValues} />;
  }

  render() {
    log(this.constructor.name, "render");
    const { classes, limitTypes } = this.props;

    return (
      <div>
        <div className={classes.subMenu}>
          <AppBar position="static" elevation={0}>
            <Tabs
              value={this.state.value}
              onChange={this.menuItemChange.bind(this)}
              variant="fullWidth"
              indicatorColor="primary"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {limitTypes.map((item, key) => (
                <Tab
                  key={key}
                  value={key}
                  label={<Translate value={`labels.limits_${item}`} />}
                  {...a11yProps(key)}
                />
              ))}
              {/* <Tab value={3} label={<Translate value={`labels.limits_max_bet`} />} {...a11yProps(3)} />
              <Tab value={4} label={<Translate value={`labels.limits_max_promo_bet`} />} {...a11yProps(4)} /> */}
            </Tabs>
          </AppBar>
        </div>
        <SwipeableViews
          axis={"x"}
          index={this.state.value}
          onChangeIndex={this.menuItemChangeIndex.bind(this)}
        >
          {this.state.value === 0 ? (
            <div key="limit-loss">
              {/* {this.renderLimitTypeOverview('loss')} */}
              {this.renderLimitForm("loss")}
            </div>
          ) : (
            <div />
          )}
          {this.state.value === 1 ? (
            <div key="limit-stake">
              {/* {this.renderLimitTypeOverview('stake')} */}
              {this.renderLimitForm("stake")}
            </div>
          ) : (
            <div />
          )}
          {this.state.value === 2 ? (
            <div key="limit-deposit">
              {/* {this.renderLimitTypeOverview('deposit')} */}
              {this.renderLimitForm("deposit")}
            </div>
          ) : (
            <div />
          )}
          {this.state.value === 3 ? (
            <div key="limit-deposit">
              {/* {this.renderLimitTypeOverview('deposit')} */}
              {this.renderBetsLimitsForm("max_bet")}
            </div>
          ) : (
            <div />
          )}
        </SwipeableViews>
      </div>
    );
  }
}

// export default (withStyles(styles)(Limits));

const mapStateToProps = (state) => ({
  auth: state.user.auth,
  limits: state.user.limits ? state.user.limits : null,
  limitTypes:
    state.user.limits && state.user.limits.types
      ? ["loss", ...state.user.limits.types?.filter((item) => item !== "loss")]
      : [],
  translate: state.i18n.translations[state.i18n.locale],
  host: state.settings.host,
  locale: state.i18n.locale,
});

export default withRouter(connect(mapStateToProps, {
  getLimits,
  saveLimits,
  deleteLimit,
  updateLimit,
})(withStyles(styles)(Limits)));
