/**
 * Reducer for favorite casino games
 */

import { SAVE_FAVORITE_CASINO_GAMES } from "../actions/types";

const INITIAL_STATE = localStorage.getItem("favoriteCasinoGames")
  ? JSON.parse(localStorage.getItem("favoriteCasinoGames"))
  : [];

let stateCopy;
const fn = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVE_FAVORITE_CASINO_GAMES:
      const game = action.payload;
      stateCopy = [...state];

      const find = stateCopy.filter((obj) => obj.gameId === game.gameId);
      if (find?.length) {
        stateCopy = stateCopy.filter((obj) => obj.gameId !== game.gameId);
      } else {
        stateCopy.push(game);
      }

      stateCopy.sort(function (a, b) {
        var keyA = a.gameId,
          keyB = b.gameId;
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });

      localStorage.setItem("favoriteCasinoGames", JSON.stringify(stateCopy));

      return stateCopy;

    default:
      return state;
  }
};

export default fn;
