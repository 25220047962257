/**
 * AboutUs - English version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class AboutUs extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)", height: "100%" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>ABOUT US</h1>
            <h3>Who are we? </h3>
            Nubet is a unique Nordic bookmaker, established by some of the pioneers of the sports betting and gambling industry. Nubet is licensed by The Estonian Tax and Customs Board (EMTA).<br></br>
            <br></br>
            <b>Registration number:</b> 16427120<br></br>
            <br></br>
            <b>Registered address:</b> Roseni 10-135, 10111 Tallinn, Estonia<br></br>
            <br></br>
            <b>Licence number:</b> HKT000066 and HKT000067<br></br>
            <br></br>
            <h3>Find out more:</h3>
            <a href="#Footer">NUBET</a>
            <br></br>
            <br></br>
            <a href="https://www.emta.ee/" target="_blank">
              EMTA
            </a>
            <br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(AboutUs));
