/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconESP = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g><circle cx="749.8" cy="1255.2" r="98.8" />
					<path d="M1808.2,1082.4l-169.9-419c-30.6-75.6-103.1-124.4-184.6-124.4c-69.2,0-132.5,35.1-169.1,93.9
		c-8.2,13.2-23.4,21.4-39.7,21.4H755.1c-16.3,0-31.5-8.2-39.7-21.4c-36.6-58.8-99.8-93.9-169.1-93.9c-81.5,0-154,48.8-184.6,124.4
		l-169.9,419c-23,56.8-34.7,116.7-34.7,178c0,68.5,34.4,131.6,92.1,168.8c32.9,21.2,70,31.8,108.1,31.8c30.5,0,61.6-6.8,91.7-20.5
		c1.3-0.6,2.6-1.3,3.8-2.1l125.1-86c-16.3-28.7-25.7-61.9-25.7-97.2c0-108.9,88.6-197.6,197.6-197.6s197.6,88.6,197.6,197.6
		c0,8.4-0.6,16.6-1.6,24.7h108.6c-1-8.1-1.6-16.3-1.6-24.7c0-108.9,88.6-197.6,197.6-197.6c108.9,0,197.6,88.6,197.6,197.6
		c0,35.3-9.4,68.5-25.7,97.2l104.4,71.8c33.9,23.3,72.5,36,111.8,36.7c54.3,1,105.6-19.3,144.4-57.3c38.8-38,60.1-88.9,60.1-143.2
		C1842.9,1199.1,1831.2,1139.2,1808.2,1082.4z M716.8,929.2h-49.4v49.4c0,27.3-22.1,49.4-49.4,49.4s-49.4-22.1-49.4-49.4v-49.4
		h-49.4c-27.3,0-49.4-22.1-49.4-49.4c0-27.3,22.1-49.4,49.4-49.4h49.4V781c0-27.3,22.1-49.4,49.4-49.4s49.4,22.1,49.4,49.4v49.4
		h49.4c27.3,0,49.4,22.1,49.4,49.4C766.2,907.1,744.1,929.2,716.8,929.2z M1283.2,929.2c-27.3,0-49.4-22.1-49.4-49.4
		c0-27.3,22.1-49.4,49.4-49.4s49.4,22.1,49.4,49.4C1332.5,907.1,1310.4,929.2,1283.2,929.2z M1480.7,929.2
		c-27.3,0-49.4-22.1-49.4-49.4c0-27.3,22.1-49.4,49.4-49.4s49.4,22.1,49.4,49.4C1530.1,907.1,1508,929.2,1480.7,929.2z"/>
					<circle cx="1250.2" cy="1255.2" r="98.8" /></g>
			</svg>
		</div>
	);
};

export default IconESP;
