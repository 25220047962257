import { lazy, Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import TicketsList from "../components/account/TicketsList";
import SignIn from "../components/account/SignIn";
import EmailConfirm from "../components/account/EmailConfirm";
import ResendConfirm from "../components/account/ResendConfirm";
import Statement from "../components/account/Statement";
import Settings from "../components/account/Settings";
import Payments from "../components/account/Payments";
import RegisterSwitch from "../components/account/RegisterSwitch";
import PasswordForgotten from "../components/account/PasswordForgotten";
import PasswordResetForm from "../components/account/PasswordResetForm";
import TrustlyRegister from "../components/account/TrustlyRegister";
import Help_DE from "../components/info/de/Help";
import AboutUs_EN from "../components/info/en/AboutUs";
import BettingRules_EN from "../components/info/en/BettingRules";
import BonusTerms_EN from "../components/info/en/BonusTerms";
import BonusTermsSlots_EN from "../components/info/en/BonusTermsSlots";
import Help_EN from "../components/info/en/Help";
import OutdatedBrowser_EN from "../components/info/en/OutdatedBrowser";
import PrivacyPolicy_EN from "../components/info/en/PrivacyPolicy";
import ResponsibleGame_EN from "../components/info/en/ResponsibleGame";
import SlotsRules_EN from "../components/info/en/SlotsRules";
import TermsConditions_EN from "../components/info/en/TermsConditions";
import Faq_EN from "../components/info/en/Faq";
import AboutUs_ET from "../components/info/et/AboutUs";
import BettingRules_ET from "../components/info/et/BettingRules";
import BonusTerms_ET from "../components/info/et/BonusTerms";
import BonusTermsSlots_ET from "../components/info/et/BonusTermsSlots";
import Help_ET from "../components/info/et/Help";
import PrivacyPolicy_ET from "../components/info/et/PrivacyPolicy";
import ResponsibleGame_ET from "../components/info/et/ResponsibleGame";
import SlotsRules_ET from "../components/info/et/SlotsRules";
import TermsConditions_ET from "../components/info/et/TermsConditions";
import OutdatedBrowser_ET from "../components/info/et/OutdatedBrowser";
import Faq_ET from "../components/info/et/Faq";
import AboutUs_FI from "../components/info/fi/AboutUs";
import BettingRules_FI from "../components/info/fi/BettingRules";
import BonusTerms_FI from "../components/info/fi/BonusTerms";
import BonusTermsSlots_FI from "../components/info/fi/BonusTermsSlots";
import Help_FI from "../components/info/fi/Help";
import PrivacyPolicy_FI from "../components/info/fi/PrivacyPolicy";
import ResponsibleGame_FI from "../components/info/fi/ResponsibleGame";
import SlotsRules_FI from "../components/info/fi/SlotsRules";
import TermsConditions_FI from "../components/info/fi/TermsConditions";
import OutdatedBrowser_FI from "../components/info/fi/OutdatedBrowser";
import Faq_FI from "../components/info/fi/Faq";
import TrustlyCases from "../components/TrustlyCases";
import TrustlyDepositWaiting from "../components/account/TrustlyDepositWaiting";
import SportsRegisterSwitch from "../components/account/SportsRegistration";
import CasinoRegisterSwitch from "../components/account/CasinoRegistration";
import CustomNotFoundComponent from "../components/404";
import WPCampaigns from "../components/wp-content/Campaigns";
import WPNews from "../components/wp-content/News";
import LeaderboardShanhai from "../components/wp-content/LeaderboardShanhai";
// Main promotions
import Promotions_FI from "../components/info/fi/Promotions";
import Promotions_ET from "../components/info/et/Promotions";
import Promotions_EN from "../components/info/en/Promotions";
// free speens promottions
import PromotionsFreespins_FI from "../components/info/fi/PromotionsFreespins";
import PromotionsFreespins_ET from "../components/info/et/PromotionsFreespins";
import PromotionsFreespins_EN from "../components/info/en/PromotionsFreespins";
// betbuilder promotions
import PromotionsBetbuilder_FI from "../components/info/fi/PromotionsBetbuilder";
import PromotionsBetbuilder_ET from "../components/info/et/PromotionsBetbuilder";
import PromotionsBetbuilder_EN from "../components/info/en/PromotionsBetbuilder";
// lazy loaded screens
import Slip from "../components/sports/slip";
import BetbuilderPage from "../components/sports/betbuilder";
import CasinoMainPage from "../components/casino";
import CasinoMainView from "../components/casino/views/CasinoMainView";
import CasinoFilterView from "../components/casino/views/TabFilterView";
import CasinoProviderFilterView from "../components/casino/views/ProviderFilterView";
import FreeSpinsView from "../components/casino/views/FreeSpinsView";
import PlayerPropsCatalog from "../components/sports/player-props";
import RacingPage from "../components/sports/racing";
import BetBuilderLoaderPage from "../components/sports/betbuilder/BetbuilderView";
// import DataBetPage from "../components/databet";
import FavoriteGamesView from "../components/casino/views/FavoriteGamesView";
import PageLoader from "../components/PageLoader";
// const Slip = lazy(() => import("../components/sports/slip"));
// const BetbuilderPage = lazy(() => import("../components/sports/betbuilder"));
// const CasinoMainPage = lazy(() => import("../components/casino"));
// const CasinoMainView = lazy(() => import("../components/casino/views/CasinoMainView"));
// const CasinoFilterView = lazy(() => import("../components/casino/views/TabFilterView"));
// const CasinoProviderFilterView = lazy(() => import("../components/casino/views/ProviderFilterView"));
// const FreeSpinsView = lazy(() => import("../components/casino/views/FreeSpinsView"));
// const PlayerPropsCatalog = lazy(() => import("../components/sports/player-props"));
// const RacingPage = lazy(() => import("../components/sports/racing"));
// const BetBuilderLoaderPage = lazy(() => import("../components/sports/betbuilder/BetbuilderView"));
const DataBetPage = lazy(() => import("../components/databet"));
// const FavoriteGamesView = lazy(() => import("../components/casino/views/FavoriteGamesView"));

const AppRoutes = () => {
  const allLocales = ["fi", "en", "et"];
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const host = useSelector((state) => state.settings.host);
  const lang = useSelector((state) => state.i18n.locale);

  const routeListener = () => {
    const pathname = location.pathname;
    const routeLocale = pathname.split("/")[1];
    if (!allLocales.includes(routeLocale)) {
      navigate(`/${lang}${pathname}?${searchParams.toString()}`);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    routeListener();
  }, [location.pathname]);

  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route path="/" element={<Navigate to={`/${lang}/sports?sportEventStatusSlug=all`} />} />
        <Route path={`/${lang}`} element={<Navigate to={`/${lang}/sports?sportEventStatusSlug=all`} />} />
        <Route path="/:lang" exact element={<DataBetPage />} />
        <Route path="/:lang/sports" exact element={<DataBetPage />} />
        <Route path="/:lang/freebets" exact element={<DataBetPage />} />
        <Route path="/:lang/sports/:sportsSlug" exact element={<DataBetPage />} />
        <Route path="/:lang/sports/:sportsSlug/match/:matchSlug" exact element={<DataBetPage />} />
        <Route path="/:lang/sports/:sportsSlug/tournament/:tournamentSlug" exact element={<DataBetPage />} />
        <Route path="/:lang/favorites" exact element={<DataBetPage />} />
        <Route path="/:lang/esports" exact element={<DataBetPage />} />
        <Route path="/:lang/esports/:sportsSlug" exact element={<DataBetPage />} />
        <Route path="/:lang/esports/:sportsSlug/match/:matchSlug" exact element={<DataBetPage />} />
        <Route path="/:lang/esports/:sportsSlug/tournament/:tournamentSlug" exact element={<DataBetPage />} />
        <Route path="/:lang/slip" exact element={<Slip />} />
        {host.promoPage && <Route path="/:lang/promotions" exact element={lang === "en" ? <Promotions_EN /> : lang === "et" ? <Promotions_ET /> : <Promotions_FI />} />}
        {host.promoPage && <Route path="/:lang/promotions-betbuilder" exact element={lang === "en" ? <Promotions_EN /> : lang === "et" ? <Promotions_ET /> : <Promotions_FI />} />}
        {host.promoPage && <Route path="/:lang/promotions-riskfree" exact element={lang === "en" ? <Promotions_EN /> : lang === "et" ? <Promotions_ET /> : <Promotions_FI />} />}
        {/* {host.promoPage && <Route path="/:lang/promotions-freespins" exact element={lang === "en" ? <Promotions_EN /> : lang === "et" ? <Promotions_ET /> : <Promotions_FI />} />} */}
        {host.promoPage && <Route path="/:lang/promotions-freespins" exact element={lang === "en" ? <PromotionsFreespins_EN /> : lang === "et" ? <PromotionsFreespins_ET /> : <PromotionsFreespins_FI />} />}
        {/* Betbuilder promotions start */}
        {/* {host.promoPage && <Route path="/:lang/promotions-betbuilder" exact element={lang === "en" ? <PromotionsBetbuilder_EN /> : lang === "et" ? <PromotionsBetbuilder_ET /> : <PromotionsBetbuilder_FI />} />} */}
        {/* Betbuilder promotions end */}
        <Route path="/:lang/horseracing" exact element={<RacingPage />} />
        {/* <Route path="/:lang/platform/news" exact element={<WPNews />} /> */}
        <Route path="/:lang/leaderboard/shanghai2024" exact element={<LeaderboardShanhai />} />
        <Route path="/:lang/platform/campaigns" exact element={<WPCampaigns />} />
        {/* <Route path="/:lang/casino" exact element={<CasinoMainPage />}> */}
        <Route path="/:lang/casino" exact element={<CasinoMainPage />}>
          <Route index element={<Navigate to="dashboard" replace />} />
          <Route path="dashboard" element={<CasinoMainView />} />
          <Route path="featured-games" element={<CasinoFilterView />} />
          <Route path="provider-games" element={<CasinoProviderFilterView />} />
          <Route path="free-spins" element={<FreeSpinsView />} />
          <Route path="my-games" element={<FavoriteGamesView />} />
        </Route>
        <Route path="/:lang/account/tickets/" exact element={<TicketsList />} />
        <Route path="/:lang/sign-in" exact element={<SignIn />} />
        <Route path="/:lang/betbuilder" exact element={<BetbuilderPage />} />
        <Route path="/:lang/betbuilder/:gameId" exact element={<BetBuilderLoaderPage />} />
        <Route path="/:lang/player-props" exact element={<PlayerPropsCatalog />} />
        <Route path="/:lang/confirm" exact element={<EmailConfirm />} />
        <Route path="/:lang/resend" exact element={<ResendConfirm />} />
        <Route path="/:lang/register/trustly" exact element={<TrustlyRegister />} />
        <Route path="/:lang/register-switch" exact element={<RegisterSwitch />} />
        <Route path="/:lang/all/casino/register-switch" exact element={<CasinoRegisterSwitch />} />
        <Route path="/:lang/sports/register-switch" exact element={<SportsRegisterSwitch />} />
        <Route path="/:lang/forgot-password" exact element={<PasswordForgotten />} />
        <Route path="/:lang/statement" exact element={<Statement />} />
        <Route path="/:lang/payments" exact element={<Payments />} />
        <Route path="/:lang/settings" exact element={<Settings />} />
        <Route path="/:lang/login/reset" exact element={<PasswordResetForm />} />
        <Route path="/:lang/interfaces/message" exact element={<TrustlyCases />} />
        <Route path="/:lang/trustly/deposit/waiting" exact element={<TrustlyDepositWaiting />} />
        <Route path="/:lang/trustly/deposit/status/waiting" exact element={<TrustlyDepositWaiting />} />
        <Route path="/:lang/info/terms-conditions" exact element={lang === "en" ? <TermsConditions_EN /> : lang === "et" ? <TermsConditions_ET /> : lang === "fi" ? <TermsConditions_FI /> : <TermsConditions_EN />} />
        <Route path="/:lang/info/outdated_browser" exact element={lang === "en" ? <OutdatedBrowser_EN /> : lang === "et" ? <OutdatedBrowser_ET /> : lang === "fi" ? <OutdatedBrowser_FI /> : <OutdatedBrowser_EN />} />
        {host.bet ? <Route path="/:lang/info/bonus-terms" exact element={lang === "en" ? <BonusTerms_EN /> : lang === "et" ? <BonusTerms_ET /> : lang === "fi" ? <BonusTerms_FI /> : <BonusTerms_EN />} /> : null}
        {host.bet ? <Route path="/:lang/info/betting-rules" exact element={lang === "en" ? <BettingRules_EN /> : lang === "et" ? <BettingRules_ET /> : lang === "fi" ? <BettingRules_FI /> : <BettingRules_EN />} /> : null}
        {host.gaming ? (
          <Route path="/:lang/info/bonus-terms-slots" exact element={lang === "en" ? <BonusTermsSlots_EN /> : lang === "et" ? <BonusTermsSlots_ET /> : lang === "fi" ? <BonusTermsSlots_FI /> : <BonusTermsSlots_EN />} />
        ) : null}
        {host.gaming ? <Route path="/:lang/info/slots-rules" exact element={lang === "en" ? <SlotsRules_EN /> : lang === "et" ? <SlotsRules_ET /> : lang === "fi" ? <SlotsRules_FI /> : <SlotsRules_EN />} /> : null}
        <Route path="/:lang/info/privacy-policy" exact element={lang === "en" ? <PrivacyPolicy_EN /> : lang === "et" ? <PrivacyPolicy_ET /> : lang === "fi" ? <PrivacyPolicy_FI /> : <PrivacyPolicy_EN />} />
        <Route path="/:lang/info/help" exact element={lang === "en" ? <Help_EN /> : lang === "et" ? <Help_ET /> : lang === "fi" ? <Help_FI /> : <Help_DE />} />
        <Route path="/:lang/info/responsible-game" exact element={lang === "en" ? <ResponsibleGame_EN /> : lang === "et" ? <ResponsibleGame_ET /> : lang === "fi" ? <ResponsibleGame_FI /> : <ResponsibleGame_EN />} />
        <Route path="/:lang/info/about-us" exact element={lang === "en" ? <AboutUs_EN /> : lang === "et" ? <AboutUs_ET /> : lang === "fi" ? <AboutUs_FI /> : <AboutUs_EN />} />
        <Route path="/:lang/info/faq" exact element={lang === "en" ? <Faq_EN /> : lang === "et" ? <Faq_ET /> : lang === "fi" ? <Faq_FI /> : <Faq_EN />} />
        <Route path="*" exact element={<CustomNotFoundComponent />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
