// List of markets displayed in accardion
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { getPlayerPropsOdds } from "../../../helpers/odds-factory";
import CatalogSingleOdds from "./CatalogSingleOdds";
import PropTypes from "prop-types";
import CatalogMultiOdds from "./CatalogMultiOdds";
import GoalScorerMarket from "./GoalScorerMarket";

const CatalogMarketsBox = ({ game }) => {
  // get market sets
  const marketSetConfig = useSelector((state) => state.settings.marketSets);
  // rescheme the odds object
  const generatedOdds = getPlayerPropsOdds(marketSetConfig, game);

  return (
    <Stack px={0}>
      {game?.hasGoalscorers && <GoalScorerMarket isInCatalog game={game} currentMarketSetKey="goalscorer" />}
      {Object.keys(generatedOdds.odds).map((mKey, indx) => {
        return <CatalogSingleOdds marketKey={mKey} key={mKey} odds={generatedOdds.odds[mKey]} game={game} autoOpenFirst={!game?.hasGoalscorers && indx === 0} />;
      })}
      {Object.keys(generatedOdds.multiOdds).map((mKey, indx) => {
        return <CatalogMultiOdds marketKey={mKey} key={mKey} odds={generatedOdds.multiOdds[mKey]} game={game} autoOpenFirst={Object.keys(generatedOdds.odds).length === 0 && indx === 0} />;
      })}
    </Stack>
  );
};

CatalogMarketsBox.propTypes = {
  game: PropTypes.object.isRequired,
};

CatalogMarketsBox.defaultProps = {
  game: {},
};

export default CatalogMarketsBox;
