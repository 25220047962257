/**
 * SlotsRules - Fin version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import Obfuscate from "react-obfuscate";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class SlotsRules extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>NUBET.COM KASINO SÄÄNNÖT</h1>
            Suomenkielinen versio 1.1 Viimeisin päivitys 27.1.2023.<br></br>
            <br></br>
            <h3 id="General">1. YLEISTÄ</h3>
            1.1. Kasinonjärjestäjä Nubet ja verkkosivusto Nubet.com ovat Vana Lauri OÜ:n ylläpitämiä, joka on Virossa rekisteröity yritys numerolla 16427120 ja jonka rekisteröity osoite on Roseni 10-135, 10111 Tallinna,
            Viro (jäljempänä <b>"Nubet"</b> tai <b>"Yhtiö"</b>). Vana Lauri OÜ:llä on Viron vero- ja tullihallituksen myöntämä toimilupa ja sen valvonta, ja sillä on seuraavat toimiluvat: HKT000066 (myöntämispäivä:
            10.07.2022) ja HKT000067 (myöntämispäivä: 10.07.2022).<br></br>
            <br></br>
            1.2. Näitä Nubet-kasinon sääntöjä sovelletaan Nubet-kasinoon verkkosivustolla Nubet.com ja ne koskevat Nubetin verkkosivustolla Nubet.com (jäljempänä myös <b>"Nubet-kasino"</b>) oikealla rahalla pelattavien
            kasinopelien tarjoamista. Jos pelaaja ei hyväksy näitä kasinon sääntöjä ja Nubetin käyttöehtoja, hänen tulee välittömästi lopettaa Nubet-kasinon käyttö.<br></br>
            <br></br>
            1.3. Pelatessaan peliä Nubet-kasinolla pelaajan on tunnustettava, että tuotteet kulutetaan välittömästi, eikä yhtiö voi palauttaa, peruuttaa tai hyvittää tuotteita pelin aikana tai sen jälkeen. Nubet-kasino
            tarjoaa uhkapelejä, joissa tappioriski on kokonaan pelaajalla, kun hän päättää pelata Nubet-kasinolla tarjottavia uhkapelejä.<br></br>
            <br></br>
            1.4. Nubet-kasino pidättää oikeuden rajoittaa tai estää pelaajan pääsyn Nubet-kasinon peleihin, tarjouksiin tai palveluihin ilman selitystä yhtiön oman harkinnan mukaan.<br></br>
            <br></br>
            1.5. Jos sinulla on kysyttävää Nubet-kasinosta, ota yhteyttä osoitteeseen <Obfuscate email={"support@nubet.com"}>support@nubet.com</Obfuscate>.<br></br>
            <br></br>
            <h3 id="Rules">2. NUBET-KASINON SÄÄNNÖT</h3>
            Nubet-kasinon pelaajalle tarjoamiin Nubet-kasinon peleihin ja muihin palveluihin liittyvät tai niiden käytön mahdollistavat ohjelmistot voidaan tarjota osittain tai kokonaan kolmannen osapuolen tarjoajan
            kautta, jolla on sopimussuhde Nubetin kanssa. Ohjelmisto on suojattu tekijänoikeuslainsäädännöllä ja immateriaalioikeuksien suojalla. Pelaaja saa käyttää ohjelmistoa vain henkilökohtaisiin ja vapaa-ajan
            tarkoituksiin kaikkien sovellettavien lakien, sääntöjen ja määräysten mukaisesti.<br></br>
            <br></br>
            2.1. Kun pelaa ja pelaa jackpot-pelejä Nubet-kasinolla, hän on tietoinen siitä, että jackpot-näyttö ei välttämättä joka kerta vastaa todellista tarjottua jackpotin määrää esimerkiksi määrän päivittämisen
            viiveen vuoksi. Siksi näytetty arvo ei välttämättä ole 100-prosenttisen tarkka. Kun jättipotti on voitettu, tarkka summa lasketaan jättipotin voittamiseen asti pelattujen kierrosten määrän mukaan.<br></br>
            <br></br>
            2.2. Pelaaja ei saa missään tapauksessa käyttää minkäänlaista virhettä, toimintahäiriötä, virusta, bugia, vikaa tai epäjohdonmukaisuutta Nubet-kasinon ohjelmistossa omaksi hyödykseen tai muiden pelaajien
            vahingoksi. Pelaajan on ilmoitettava tällaisesta tapauksesta asiakaspalveluun välittömästi sen havaitsemisen jälkeen.<br></br>
            <br></br>
            2.3. Nubet-kasinon sääntöjen tai Nubet-kasinoon sovellettavien Nubetin sääntöjen ja ehtojen rikkominen voi johtaa pelaajan hylkäämiseen osallistumasta peleihin, kampanjoihin, bonustarjouksiin tai
            erikoistarjouksiin ilman erillistä ilmoitusta. Jos Nubet toteaa, että pelaajan voittamat varat ovat peräisin tililtä, joka on saanut nämä voitot vilpillisesti ja/tai näitä Nubet-kasinon sääntöjä tai Nubetin
            käyttöehtoja rikkoen, Nubet pidättää itsellään oikeuden pidättää tai takavarikoida kyseiset varat/voitot. Kaikista pelaajista, jotka ovat osallisina epäillyssä petollisessa toiminnassa, voidaan ilmoittaa
            asianomaisille viranomaisille.<br></br>
            <br></br>
            2.4. Nubet Casino tarjoaa pelaajilleen mahdollisuuden pelata pelejä niin sanotussa pelaa ilmaiseksi -tilassa ilman oikean rahan panosta ja ilman talletusvaatimusta tai oikean rahan tilassa, jota varten
            pelaajalla on oltava talletus ja jonka pelitilillä on oltava riittävästi varoja pelikierroksen pelaamiseen valitulla panoksella.<br></br>
            <br></br>
            2.5. Varoja voi tallettaa Nubet-kasinolle käyttämällä Nubet-kasinon tarjoamia maksutapoja, jotka löytyvät valikosta kohdasta "Talletus".<br></br>
            <br></br>
            2.6. Varoja voi nostaa Nubet-kasinolla pyytämällä maksua ja valitsemalla käytettävissä olevan nostomenetelmän, joka löytyy valikosta kohdasta "Nostot". Käytettävissä oleva nostotapa voi riippua käytetystä
            talletustavasta.<br></br>
            <br></br>
            2.7. Yksityiskohtaiset peliohjeet löytyvät pelin kuvauksesta, joka on saatavilla pelin avaamisen jälkeen Nubet-kasinolla.<br></br>
            <br></br>
            <h3 id="Disclaimer">3. VASTUUVAPAUTUKSET</h3>
            3.1. Nubet-kasinolla digitaalisten apuvälineiden, kuten robottien tai mekaanisten, elektronisten tai muiden laitteiden käyttö automaattisten pelipäätösten tekemiseen on kielletty. Yhtiöllä on oikeus sulkea
            pelitili, keskeyttää talletukset ja kotiutukset sekä takavarikoida kaikki niihin liittyvät voitot, jos tällaisen apuvälineen tai laitteen käyttöä epäillään. Kuvattu tilanne voi johtaa myös oikeustoimiin.
            <br></br>
            <br></br>
            <h3 id="Severability">4. ERIYTETTÄVYYS</h3>
            4.1. Jokainen näiden Nubet-kasinon sääntöjen lauseke on erillinen ja erotettavissa kaikista muista. Jos jokin lauseke todetaan mitättömäksi, pätemättömäksi tai täytäntöönpanokelvottomaksi jostain syystä, muut
            lausekkeet pysyvät täysin voimassa.<br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(SlotsRules));
