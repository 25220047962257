export const checkSelectedOdd = (odd, game, bets, index) => {
  let payload = {
    gameId: game.gameid,
  };

  const marketId = odd.m;
  const selectionIds = String(odd.s)?.split(",");
  const prices = odd.p?.split(",");
  const hc = odd.hc;
  const shc = odd.shc;
  const isActives = String(odd.iA)?.split(",");

  if (selectionIds?.length < 3 && selectionIds?.length > 1 && odd.m !== 910) {
    selectionIds.unshift(null);
    prices.unshift(null);
    isActives.unshift(null);
  } else {
    selectionIds.push(null);
    prices.push(null);
    isActives.push(null);
    selectionIds.push(null);
    prices.push(null);
    isActives.push(null);
  }

  if (Number(prices[index]) < 1) return false;
  payload.marketId = Number(marketId);
  payload.selectionId = Number(selectionIds[index]);
  payload.price = Number(prices[index]);
  payload.hc = hc;
  if (shc) {
    payload.shc = shc;
  }

  let flag = false;

  if (!bets) return flag;

  for (let i = 0; i < bets?.length; i++) {
    let bet = bets[i];
    if (bet.gameId === payload.gameId && bet.marketId === payload.marketId && bet.selectionId === payload.selectionId && bet.hc === payload.hc && bet.shc === payload.shc) {
      flag = true;
      break;
    }
  }
  return flag;
};
