/**
 * Payment providers configurations
 */
 
const paymentProviderConfig = {
    deposit: {
        trustly: {
            isActive: true,
            min: 20,
            max: 'no_limit',
            sliderSteps: [{ value: 20, label: '20' }, { value: 50, label: '50' }, { value: 75, label: '75' }, { value: 100, label: '100' }],
            allowCustomValue: true,
            validForBonus: true,
            icon: 'trustly.svg',
            iconGrey: 'trustly.svg'
        }
    },
    withdraw: {
        trustly: {
            isActive: true,
            min: 1,
            max: 'no_limit',
            sliderSteps: [{ value: 10, label: '10' }, { value: 20, label: '20' }, { value: 50, label: '50' }],
            allowCustomValue: true,
            validForBonus: false,
            icon: 'trustly.svg',
            iconGrey: 'trustly.svg'
        },
    }
};

export default paymentProviderConfig;