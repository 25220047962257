/**
 * Help - English version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import ListItem from "@mui/material/ListItem";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class Help extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>HELP</h1>
            <h3>MY ACCOUNT</h3>
            Nubet.com is aimed at customers that consider gambling and sports betting as fun and entertainment and can assess their betting behaviour responsibly.<br></br>
            You can access your account from every device by using a browser. All you need is your username and password.<br></br>
            When you are logged in, just click on “My Account” in the top right corner.<br></br>
            In “My Account” you can make deposits, withdrawals, check your transactions as well as change your limits and edit your personal data.<br></br>
            <br></br>
            <h4>1. Deposit Methods</h4>
            The following options can be used you deposit funds into your account: <br></br>
            <br></br>
            <ListItem>Trustly</ListItem>
            <br></br>
            <h4>2. Withdrawal Methods</h4>
            Your withdrawal request will be processed within 24h.<br></br>
            Only options that are used to fund the account can be used as a withdrawal method.<br></br>
            <br></br>
            <ListItem>Trustly</ListItem>
            <br></br>
            <h4>3. My Wager History</h4>
            In this area you can see your wager history and filter for a specific timeframe.<br></br>
            <br></br>
            <h4>4. My Transactions</h4>
            In this section you can see an overview of all transactions in your player account. You may choose the timeframe and filter for deposits and withdrawals.<br></br>
            <br></br>
            <h4>5. Profile</h4>
            In this section you can edit your personal data. Here you can change your address or phone number. Name, birthday, and nationality cannot be changed. Please note, that some changes require a proof of
            identification.<br></br>
            <br></br>
            If you need changes in these sections, please contact our support team: support@nubet.com.<br></br>
            <br></br>
            <h4>6. Documents</h4>
            Under the tab “Documents” you can upload the documents that Nubet requires to fully verify your account. We are by law required to make identity checks on each registered customer.<br></br>
            If transactions reach a threshold of 2000 EUR we are by law required to make identity checks on the customer. No withdrawals are processed if the necessary documentation of the customer is not submitted and
            verified by Nubet. <br></br>
            <br></br>
            <h3>SETTINGS</h3>
            <h4>1. Change your password</h4>
            You can change your password anytime. Just follow the instructions after clicking on the button “Change Password”.<br></br>
            If you have forgotten your Password, please click on “Forgot Password?” and follow the instructions.<br></br>
            If you have forgotten your Username, please click on “Forgot Username?” and your Username will be sent to the E-Mail address you have registered with. <br></br>
            <br></br>
            <h4>2. Limits</h4>
            Control your budget! We offer several options to limit yourselves.<br></br>
            <br></br>
            <h4>3. Wager Limits</h4>
            Here you can determine the maximum amount you want to wager in a particular period.<br></br>
            <br></br>
            <h4>4. Loss Limits</h4>
            Here you can determine the maximum amount you will be able to lose in a certain period.<br></br>
            <br></br>
            <h4>5. Auto Log-Out</h4>
            With this function you can adjust the time the system will log you out automatically. After 1, 2, 5, 12 or 24 hours.<br></br>
            <br></br>
            <h4>6. Deposit Limits</h4>
            In this field you can set deposit limits for a certain period of time. We offer daily, weekly, or monthly deposit limits.<br></br>
            <br></br>
            <h4>7. Block Account</h4>
            You can block your player account for a certain period. Your player account will reopen automatically after the period expires.<br></br>
            If you confirm an unlimited block, your player account will be blocked for all further activities. It will then be impossible to sign in afterwards. In case you have money left on your player account this
            amount will be refunded to the last method you deposited with.<br></br>
            <br></br>
            <h4>8. Changes of existing Limits</h4>
            All limits you set become active with immediate effect. If you wish to eliminate or decrease an existing limit, it will come into effect after 7 days.<br></br>
            <br></br>
            <h4>9. User Profile</h4>
            In this field you can manage your communication preferences such as: E-Mail notifications, additional SMS notifications, individual information, or the receipt of offers and newsletters.<br></br>
            <br></br>
            <h3>BET SLIP</h3>
            <h4>Types of Bets</h4>
            <h4>1. Single Bet</h4>
            In a single bet, you bet on one outcome in one match. <br></br>
            <br></br>
            <h4>2. Combi Bet</h4>A combination bet consists of several single bets, that all need to be correct for the combination bet to win. With a combination bet you can bet on two, three, four or more bets at the
            same time.<br></br>
            <br></br>
            <h4>3. System Bet</h4>A system bet is a combination of a set amount of combi bets on one bet slip. You can choose several systems for example: 2 out of 3, 5 out of 7 and so on.<br></br>
            <br></br>
            <h4>4. Banker</h4>
            The banker can only be used only system bets. The bank choice is in every combination. For a banker set to be successful, all bets on the slip must win. You indicate a bet as banker by clicking the B on the
            bet slip.<br></br>
            The amount of system bets will only be calculated on the matches that are not chosen as a bank.<br></br>
            <br></br>
            <h4>5. Maximum Stake</h4>
            By clicking “Max” next to the stake, the system will display the maximum possible stake for the matches you added to the bet slip. This number may vary depending on the sport, league, bet type or other
            settings in the system.<br></br>
            <br></br>
            <h4>6. Win</h4>
            This will display the maximum winning on the bet slip.<br></br>
            <br></br>
            <h4>7. Maximum Win</h4>
            The max winning amount on every bet slip is EUR 100,000.00. The max aggregate amount you can win from your betting account is EUR 200.000 per week.<br></br>
            <br></br>
            <h4>8. Minimum Stake</h4>
            The minimum stake for every bet slip is EUR 1. For system and combi bets the minimum stake for every combination is EUR 0.25.<br></br>
            <br></br>
            <h4>9. Number of Bets</h4>
            The maximum amounts of matches on a bet slip is 30.<br></br>
            <br></br>
            <h4>10. Accept Odds Changes</h4>
            While you are adding events to you bet slip, it can happen that there is a change in the odds. This happens mostly in live events. A message will be displayed that the odds changed, and you will have to
            accept the change. To avoid the message, you can click “Accept Odds Changes”.<br></br>
            <br></br>
            <h3>Bet Settlement</h3>
            The evaluation and settling of the bet slips will happen after the official results for all events on the bet slip are checked and added to the system.<br></br>
            <br></br>
            <br></br>
            <h3>IMAGE RIGHTS</h3>
            Nubet acquires images and graphics for use on www.nubet.com from various sources, including:<br></br>
            <br></br>
            <ListItem>Shutterstock under a license agreement that allows us to use images and graphics for commercial purposes on our website. </ListItem>
            <ListItem>Freepik under a license agreement that allows us to use images and graphics for commercial purposes on our website. </ListItem>
            <ListItem>Our own photographers, and the images and graphics created by them are owned by Nubet and may only be used for commercial purposes on our website. </ListItem>
            <br></br>
            <h4>Copyright</h4>
            Nubet respects the copyrights of others. If you believe that your work has been used on our website without your permission, please contact us.<br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(Help));
