import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import { checkTrustlyDepositStatus } from "../../actions";
import { Translate } from "react-redux-i18n";
import TrustlyIcon from "../../media/img/trustly_register.png";
import withRouter from "../../helpers/withRouter";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
  text: {
    textAlign: "center",
    paddingTop: "3em",
    fontSize: "var(--xlarge)",
  },
});

class TrustlyDepositWaiting extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { seconds: 60 };
    this.t;
  }

  onStart = async () => {
    const { trustlyPayload, trustlyDepositStatus, locale, navigate } = this.props;
    if (this.state.seconds > 1) {
      this.setState({ seconds: this.state.seconds - 1 });
    }
    if (trustlyPayload && trustlyPayload?.token) {
      this.props.checkTrustlyDepositStatus(this.constructor.name, trustlyPayload, navigate);
    } else {
      clearInterval(this.t);
      navigate(`/${locale}/interfaces/message?type=deposit&status=notInitialized`);
    }
    if (trustlyDepositStatus?.payload?.status && trustlyDepositStatus?.payload?.status === "ok") {
      clearInterval(this.t);
      navigate(`/${locale}/register/trustly`);
    } else if (trustlyDepositStatus?.payload?.status && trustlyDepositStatus?.payload?.status !== "ok" && trustlyDepositStatus?.payload?.status !== "pending") {
      clearInterval(this.t);
      navigate(`/${locale}/interfaces/message?type=deposit&status=${trustlyDepositStatus.payload.status}`);
    }
  };

  timer = () => {
    this.t = setInterval(this.onStart, 1000);
  };

  componentDidMount() {
    this.timer();
  }

  componentWillUnmount() {
    clearInterval(this.t);
  }

  goTop = () => {};

  render() {
    // console.log();
    log(this.constructor.name, "render");
    const { classes } = this.props;
    return (
      <Container style={{ backgroundColor: "var(--color-main)", minHeight: "100%", textAlign: "center" }} maxWidth={false}>
        <Typography component={"span"}>
          <div className={classes.text}>
            <Translate value="trustly.deposit_waiting1" /> <span style={{ color: "var(--color-contrast)" }}>{this.state.seconds}</span> <Translate value="trustly.deposit_waiting2" />
          </div>
        </Typography>
        <img style={{ color: "var(--btn-text)", marginTop: "2.5em", height: "6em" }} src={TrustlyIcon} alt="trustly" />
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
    trustlyPayload: state.user.trustlyPayload,
    trustlyDepositStatus: state.user.trustlyDepositStatus,
    locale: state.i18n.locale,
  };
};

export default withRouter(connect(mapStateToProps, { checkTrustlyDepositStatus })(withStyles(styles)(TrustlyDepositWaiting)));
