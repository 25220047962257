/**
 * utilities library
 */

/**
 * Format value with commas and dots
 ** Format value with comma if current locale is not DE
 ** Format value with dots if current locale is DE
 */

export const formatCommasAndDots = (x, currentLocale) => {
  if (x) {
    x = parseFloat(x).toFixed(2);
    if (currentLocale === "de") {
      x = x.toString().replace(/\./g, ",");
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    } else {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  } else {
    return 0;
  }
};

export function createUuid() {
  var chars = "0123456789abcdefghijklmnopqrstuvwxyz".split("");

  var uuid = [],
    rnd = Math.random,
    r;
  uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
  uuid[14] = "4"; // version 4

  for (var i = 0; i < 36; i++) {
    if (!uuid[i]) {
      r = 0 | (rnd() * 16);

      uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r & 0xf];
    }
  }

  return uuid.join("");
}

export const sortAlphabetically = (arr) => {
  const sortedArr = arr.sort(function (a, b) {
    if (a[0].sn < b[0].sn) {
      return -1;
    }
    if (a[0].sn > b[0].sn) {
      return 1;
    }
    return 0;
  });
  return sortedArr;
};
