/**
 * Forgot password component
 */
import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withStyles } from "@mui/styles";
import { Translate } from "react-redux-i18n";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Alert from "../Alert";
import CustomDatePicker from "../Inputs/DatePicker";
import CustomTextField from "../Inputs/TextField";

import { log } from "../../config/log";
import { getForgottenPassword } from "../../actions";
import { format } from "date-fns";

const styles = (theme) => ({
  root: {
    "& .MuiContainer-root": {
      padding: "2.6em 2.6em 5.6em",
      width: "100%",
    },
    "& .MuiFormControl-root": {
      margin: "1em 0",
      width: "100%",
    },
    "& .MuiButton-root": {
      margin: "1em 1em 0 0",
    },
    backgroundColor: "var(--color-main)",
  },
});

class PasswordForgotten extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showAlert: false,
    };
  }

  onSubmit = (formValues) => {
    const { translate } = this.props;
    const data = {
      email: formValues.email,
      birthDate: format(new Date(formValues.birthDate), "yyyy-MM-dd"),
    };
    /**
     * Submit password reset request
     */
    this.props
      .getForgottenPassword(
        data,
        this.constructor.name,
        translate.labels.forgot_email_sent
      )
      .then(() => {
        this.setState({ showAlert: true });
      });
  };

  render() {
    log(this.constructor.name, "render");
    const { translate, user, classes } = this.props;
    return (
      <div className={classes.root}>
        <form
          onSubmit={this.props.handleSubmit(this.onSubmit)}
          autoComplete="off"
        >
          <div>
            <Container maxWidth="md">
              <Typography variant="h6" component="h4" color="primary" mb={1}>
                <Translate value="labels.forget_pass" />
              </Typography>
              <Typography
                variant="body2"
                paragraph
                sx={{ whiteSpace: "pre-line" }}
              >
                <Translate value="labels.forget_text" />
              </Typography>
              <Field
                name="email"
                variant="standard"
                component={CustomTextField}
                label={translate.labels.email}
              />
              <Field
                name="birthDate"
                variant="standard"
                component={CustomDatePicker}
                label={translate.labels.form_}
              />
              <Button
                type="submit"
                variant="contained"
                size="small"
                color="primary"
              >
                <Translate value="labels.forget_send" />
              </Button>
            </Container>
          </div>
        </form>
        {this.state.showAlert && user.forgottenPassword ? (
          <Alert
            success={user.forgottenPassword.success}
            message={user.forgottenPassword.msg}
            forwardRef={(c) => {
              this.openAlert = c;
            }}
          />
        ) : null}
      </div>
    );
  }
}

const validate = (values, props) => {
  const errors = {};

  const requiredFields = ["email", "birthDate"];

  requiredFields?.forEach((field) => {
    if (!values[field]) {
      errors[field] = props.translate.labels.field_required;
    }
  });

  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)
  ) {
    errors.email = "Invalid email address";
  }

  return errors;
};

const mapStateToProps = (state) => ({
  user: state.user,
  translate: state.i18n.translations[state.i18n.locale],
});

export default connect(mapStateToProps, { getForgottenPassword })(
  reduxForm({
    form: "PasswordForgotten",
    validate,
    enableReinitialize: true,
  })(withStyles(styles)(PasswordForgotten))
);
