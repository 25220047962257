/**
 * Promotions Page - English version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, Paper, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

const styles = (theme) => ({
  root: {
    padding: "1.8em",
    fontSize: "var(--content)",
    color: "var(--color-main-featured)",
  },
  listItems: {
    color: "var(--color-contrast)",
    fontSize: "1em !important",
    fontWeight: "bold",
  },
  promo: {
    height: "auto",
    width: "100%",
    borderRadius: "0.7em 0.7em 0 0",
  },
  promoGrid: {
    "& .MuiGrid-item": {
      padding: "0 0.5em 0.5em 0.5em !important",
      "@media (hover:hover)": {
        padding: "0 0.5em !important",
      },
    },
  },
});

class Promotions extends React.PureComponent {
  goTop = () => { };

  render() {
    log(this.constructor.name, "render");
    const { host, classes, locale, auth, url, siteTheme } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)", height: "100%" }} maxWidth={false}>
        <Grid className={classes.promoGrid} container spacing={2} style={{ display: "flex", alignItems: "stretch" }}>

          {/* <Grid item xs={12} sm={3} style={{ display: "flex", alignItems: "stretch" }}>
            <Paper style={{ margin: "0.5em", backgroundColor: "var(--bg-promo)" }}>
              <img className={classes.promo} src={`https://storage.googleapis.com/nubet-prod-content-news/banner_casino_sm_gemix.jpg`} alt="banner-casino-promo" />
              <Typography component="span">
                <div id="promotioncontent" className={classes.root}>
                  <h1>Are you the Emperor of Gemix? 20€ mega spins are up for grabs!</h1>
                  <br></br>
                  Now let's find out who is the Emperor of Gemix!<br></br><br></br>
                  Your goal is to achieve the highest multiplier in the original Gemix, Gemix 2, or the new Gemix 100.<br></br><br></br>
                  The winner will take home 150 mega spins worth 20 euros each!
                  <br></br>
                  <div style={{ textAlignLast: "center" }}>
                    <Link to={`/${locale}/casino/featured-games?filter=tournament`}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          margin: "2em 0",
                          color: "var(--btn-text)",
                          padding: "0.5em 0.5em",
                          fontSize: "1em",
                          padding: "0.5em 0.5em",
                        }}
                      >
                        Play now
                      </Button>
                    </Link>
                  </div>
                </div>
              </Typography>
            </Paper>
          </Grid> */}

          <Grid item xs={12} sm={6} lg={3} style={{ display: "flex", alignItems: "stretch" }}>
            <Paper style={{ margin: "0.5em", backgroundColor: "var(--bg-promo)" }}>
              <img className={classes.promo} src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_freebet_promo_${locale}_${siteTheme}.jpg`} alt="banner-casino-promo" />
              <Typography component="span">
                <div id="promotioncontent" className={classes.root}>
                  <h1>Start betting with Nubet 25 EUR free bet!</h1>
                  <br></br>
                  May your first bet at Nubet be a winner - and even if it's not, it doesn't matter. Nubet offers it to you for free!
                  <br></br>
                  <br></br>
                  <h3>How to use the free bet</h3>
                  <span className={classes.listItems}>1. </span>Deposit and open a Nubet account at the same time.
                  <br></br>
                  <br></br>
                  <span className={classes.listItems}>2. </span>Free bet worth 25 EUR will be added to your gaming account, which you can use within its validity period.<br></br>
                  <br></br>
                  <span className={classes.listItems}>3. </span>Before placing your bet, you must select the free bet at the bottom of the game slip.<br></br><br></br>
                  <span className={classes.listItems}>4. </span>If your bet is wrong it will cost you nothing. If your bet wins, you will be credited with the net winnings of the bet (winnings-stake).<br></br>
                  <div style={{ textAlignLast: "center" }}>
                    {!auth.isSignedIn ? (
                      <Link to={`/${locale}/register-switch`}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            margin: "2em 0",
                            color: "var(--btn-text)",
                            padding: "0.5em 0.5em",
                            fontSize: "1em",
                            padding: "0.5em 0.5em",
                          }}
                        >
                          DEPOSIT AND OPT-IN FOR FREE BET
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled
                        style={{
                          margin: "2em 0",
                          color: "var(--btn-text)",
                          padding: "0.5em 0.5em",
                          fontSize: "1em",
                        }}
                      >
                        FOR NEW CUSTOMERS
                      </Button>
                    )}
                  </div>
                </div>
              </Typography>
            </Paper>
          </Grid>

          {/* <Grid item xs={12} sm={3}>
            <Paper style={{ margin: "0.5em", backgroundColor: "var(--bg-promo)" }}>
              <img className={classes.promo} src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_euro2024_promo_welcome_${locale}_${siteTheme}.jpg`} alt="banner-euro-promo" />
              <Typography component="span">
                <div id="promotioncontent" className={classes.root}>

                  <h1>
                    Welcome bonus<br></br>
                    (NEW CUSTOMERS ONLY) 
                  </h1>
                  <br></br>
                  Time to set up your bankroll for Euro 2024!<br></br><br></br>
                  Make your FIRST Deposit and get 100% bonus up to 100 eur.<br></br><br></br>
                  You will also get 100 free spins to Book of Dead game.<br></br>
                  <br></br>
                  <h3>Follow these easy steps to claim the welcome bonus:</h3>
                  <span className={classes.listItems}>1. </span>Make a deposit and register as a customer during the process<br></br>
                  <br></br>
                  <span className={classes.listItems}>2. </span>Bonus and free spins are added to your account<br></br>
                  <br></br>
                  <span className={classes.listItems}>3. </span> Bonus money and winnings of free spins are subject to 4x rollover requirement.<br></br>
                  <br></br>
                  <span className={classes.listItems}>4. </span> Reload bonus cannot be combined with any other bonus.<br></br>
                  <br></br>
                  <span className={classes.listItems}>5. </span> General Bonus Terms and Conditions apply.<br></br>
                  <div style={{ textAlignLast: "center" }}>
                    {!auth.isSignedIn ? (
                      <Link to={`/${locale}/register-switch`}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            margin: "2em 0",
                            color: "var(--btn-text)",
                            padding: "0.5em 0.5em",
                            fontSize: "1em",
                            background: "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)"
                          }}
                        >
                          REGISTER AND GET BONUS
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled
                        style={{
                          margin: "2em 0",
                          color: "var(--btn-text)",
                          padding: "0.5em 0.5em",
                          fontSize: "1em",
                        }}
                      >
                        FOR NEW CUSTOMERS
                      </Button>
                    )}
                  </div>
                </div>
              </Typography>
            </Paper>
          </Grid> */}

          <Grid item xs={12} sm={6} lg={3} style={{ display: "flex", alignItems: "stretch" }}>
            <Paper style={{ margin: "0.5em", backgroundColor: "var(--bg-promo)" }}>
              <img className={classes.promo} src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_freespins_bookofpower_${siteTheme}.jpg`} alt="banner-casino-promo" />
              <Typography component="span">
                <div id="promotioncontent" className={classes.root}>
                  <h1>Start playing at Nubet Casino with 100 free spins!</h1>
                  <br></br>
                  When you make your FIRST deposit at Nubet, you will instantly receive 100 free spins on Book of Power.<br></br>
                  <br></br>
                  The game offers a whopping 15.000x maximum payout!
                  <br></br>
                  <br></br>
                  <h3>How to get your free spins</h3>
                  <span className={classes.listItems}>1. </span>Deposit and open a Nubet account at the same time<br></br>
                  <br></br>
                  <span className={classes.listItems}>2. </span>Open the Book of Power game and the free spins will be waiting for you in the game<br></br>
                  <br></br>
                  <span className={classes.listItems}>3. </span>The winnings from free spins are subject to 1x wagering requirement.<br></br>
                  <br></br>
                  <div style={{ textAlignLast: "center" }}>
                    {!auth.isSignedIn ? (
                      <Link to={`/${locale}/register-switch`}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            margin: "2em 0",
                            color: "var(--btn-text)",
                            padding: "0.5em 0.5em",
                            fontSize: "1em",
                            padding: "0.5em 0.5em",
                          }}
                        >
                          DEPOSIT AND PLAY FREE SPINS
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled
                        style={{
                          margin: "2em 0",
                          color: "var(--btn-text)",
                          padding: "0.5em 0.5em",
                          fontSize: "1em",
                        }}
                      >
                        FOR NEW CUSTOMERS
                      </Button>
                    )}
                  </div>
                </div>
              </Typography>
            </Paper>
          </Grid>

          {/* <Grid item xs={12} sm={3} style={{ display: "flex", alignItems: "stretch" }}>
            <Paper style={{ margin: "0.5em", backgroundColor: "var(--bg-promo)" }}>
              <img className={classes.promo} src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_comboboost_${locale}_${siteTheme}.gif`} alt="banner-casino-promo" />
              <Typography component="span">
                <div id="promotioncontent" className={classes.root}>
                  <h1>Combo Boost</h1>
                  Why settle for lower odds in combos? With combo boost, you get a significant boost in wager. The longer the combo, the bigger the bonus. Bonus is added to the net win according to the table below.
                  <br></br>
                  <br></br>
                  <Grid container style={{ fontSize: "0.8em", textAlignLast: "center" }}>
                    <Grid item xs={3} sm={3} style={{ color: "var(--color-contrast)", fontWeight: "bold" }}>
                      Selections
                    </Grid>
                    <Grid item xs={3} sm={3} style={{ color: "var(--color-contrast)", fontWeight: "bold" }}>
                      Bonus
                    </Grid>
                    <Grid item xs={3} sm={3} style={{ color: "var(--color-contrast)", fontWeight: "bold" }}>
                      Selections
                    </Grid>
                    <Grid item xs={3} sm={3} style={{ color: "var(--color-contrast)", fontWeight: "bold" }}>
                      Bonus
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      3
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      5%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      10
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      27%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      4
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      7%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      11
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      30%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      5
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      10%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      12
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      35%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      6
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      12%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      13
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      40%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      7
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      15%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      14
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      45%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      8
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      20%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      15
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      50%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      9
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      25%
                    </Grid>
                    <Grid item xs={3} sm={3}></Grid>
                    <Grid item xs={3} sm={3}></Grid>
                  </Grid>
                  <br></br>
                  <h3>Campaign rules</h3>
                  <span className={classes.listItems}>1. </span>The campaign is valid until further notice<br></br>
                  <span className={classes.listItems}>2. </span>Valid only for 1X2 and winner (moneyline) markets<br></br>
                  <span className={classes.listItems}>3. </span>Minimum selection odds at least 1.2<br></br>
                  <span className={classes.listItems}>4. </span>Bonus money cannot be used for the offer<br></br>
                  <span className={classes.listItems}>5. </span>If one or more selections in the combination are void, the bonus will be redefined based on the remaining selections<br></br>
                  <span className={classes.listItems}>6. </span>If the bet is cancelled, no bonus will be paid.<br></br>
                  <br></br>
                </div>
              </Typography>
            </Paper>
          </Grid> */}

          <Grid item xs={12} sm={6} lg={3} style={{ display: "flex", alignItems: "stretch" }}>
            <Paper style={{ margin: "0.5em", backgroundColor: "var(--bg-promo)" }}>
              <img className={classes.promo} src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_racingboost_${locale}_${siteTheme}.jpg`} alt="banner-casino-promo" />
              <Typography component="span">
                <div id="promotioncontent" className={classes.root}>
                  <h1>Trotting Boost</h1>
                  <br></br>
                  With Trotting Boost, you can create not only combinations but also systems with boosted wins. You can select multiple horses to multiple races within your bet. You can even mix Swedish and Finnish races. The longer the combo the bigger bonus. The bonus is added to the net winnings according to the table below.<br></br>
                  <br></br>
                  <Grid container style={{ fontSize: "0.8em", textAlignLast: "center" }}>
                    <Grid item xs={6} sm={6} style={{ color: "var(--color-contrast)", fontWeight: "bold" }}>
                      Selections
                    </Grid>
                    <Grid item xs={6} sm={6} style={{ color: "var(--color-contrast)", fontWeight: "bold" }}>
                      Bonus
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      3
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      10%
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      4
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      17%
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      5
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      25%
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      6
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      34%
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      7
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      42%
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      8
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      50%
                    </Grid>
                  </Grid>
                  <br></br>
                  <h3>Campaign rules</h3>
                  <span className={classes.listItems}>1. </span>The campaign is valid until further notice<br></br>
                  <span className={classes.listItems}>2. </span>Valid only for winner outright markets in trotting<br></br>
                  <span className={classes.listItems}>3. </span>Minimum selection odds at least 1.2<br></br>
                  <span className={classes.listItems}>4. </span>Bonus money cannot be used for the offer<br></br>
                  <span className={classes.listItems}>5. </span>If one or more selections in the combination are void, the bonus will be redefined based on the remaining selections<br></br>
                  <span className={classes.listItems}>6. </span>If the bet is cancelled, no bonus will be paid.<br></br>
                  <br></br>
                </div>
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} lg={3} style={{ display: "flex", alignItems: "stretch" }}>
            <Paper style={{ margin: "0.5em", backgroundColor: "var(--bg-promo)" }}>
              <img className={classes.promo} src={`https://storage.googleapis.com/nubet-prod-content-news/banner_casino_sm_dropswins_${siteTheme}.jpg`} alt="banner-casino-promo" />
              <Typography component="span">
                <div id="promotioncontent" className={classes.root}>
                  <h1>Drops & Wins Games: Two Million Euro Prizepot!</h1>
                  <br></br>
                  Join Pragmatic Play's highly popular Drops & Wins campaign, where two million euros are given away every month.<br></br>
                  <br></br>

                  <h3>Daily Drops:</h3>
                  <span className={classes.listItems}>– </span>€30,000 in cash drops every day<br></br>
                  <span className={classes.listItems}>– </span>6,500 cash prizes daily<br></br>
                  <span className={classes.listItems}>– </span>Win up to 2,500x your bet<br></br>
                  <br></br>

                  <h3>Weekly Tournaments:</h3>
                  <span className={classes.listItems}>– </span>€40,000 daily prize pool<br></br>
                  <span className={classes.listItems}>– </span>€5,000 daily top prize<br></br>
                  <span className={classes.listItems}>– </span>3,500 prizes up for grabs<br></br>
                  <span className={classes.listItems}>– </span>Every week from Wednesday to Wednesday<br></br>
                  <br></br>

                  <div style={{ textAlignLast: "center" }}>

                    <Link to={`/${locale}/casino/featured-games?filter=dropsandwins`}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          margin: "2em 0",
                          color: "var(--btn-text)",
                          padding: "0.5em 0.5em",
                          fontSize: "1em",
                          padding: "0.5em 0.5em",
                        }}
                      >
                        Play now
                      </Button>
                    </Link>
                  </div>
                </div>
              </Typography>
            </Paper>
          </Grid>


        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
    locale: state.i18n.locale,
    auth: state.user.auth,
    url: state.settings.url,
    siteTheme: state.settings.theme,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(Promotions));
