// Betbuilder Main Page
import { Box } from "@mui/material";
import { useEffect } from "react";
// redux utils
import { useDispatch, useSelector } from "react-redux";
import { getBetbuilderLeaguesAsync } from "../../../redux-store/sports-reducer";
import SportsTabs from "../@components/SportsTabs";
import LeagueRow from "../@components/LeagueRow";
import { useLocation, useSearchParams } from "react-router-dom";
import BetbuilderGamesList from "./games-list";
import PrecannedBetsCarousel from "../../banners/precanned-bets";
import EmptyScreen from "../@components/EmptyScreen";
import { Translate } from "react-redux-i18n";
import PageLoader from "../../PageLoader";
import SportsLayout from "../@components/SportsLayout";

const BetbuilderPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  // Selectors
  const isPageLoading = useSelector((state) => state.sports.betbuilder.isLoading);
  const sportCode = searchParams.get("sport-code");
  const lang = useSelector((state) => state.i18n.locale);
  const betbuilderLeagues = useSelector((state) => state.sports.betbuilder.leagues);
  const allSportsCodes = Object.keys(betbuilderLeagues);
  const initialSportCode = sportCode || allSportsCodes[0];

  useEffect(() => {
    if (!betbuilderLeagues[initialSportCode]?.length) {
      dispatch(getBetbuilderLeaguesAsync(lang));
    }
  }, [location.pathname]);

  return (
    <SportsLayout>
      <Box>
        <Box>
          <PrecannedBetsCarousel topLeagueIds={[]} isInBBModal />
        </Box>
        {!betbuilderLeagues[initialSportCode]?.length && isPageLoading ? (
          <PageLoader />
        ) : !isPageLoading && !betbuilderLeagues[initialSportCode]?.length ? (
          <EmptyScreen message={<Translate value="labels.empty_screen_message" />} />
        ) : (
          <>
            <SportsTabs sportCodes={allSportsCodes} initialCode={initialSportCode} />
            <Box>
              {betbuilderLeagues[initialSportCode]?.map((league, indx) => (
                <LeagueRow key={league.leagueId} rowIndex={indx} {...league} content={<BetbuilderGamesList leagueId={league.leagueId} gamesList={league?.games || []} />} />
              ))}
            </Box>
          </>
        )}
      </Box>
    </SportsLayout>
  );
};

export default BetbuilderPage;
