const casinoProviderSliderSettings = {
  slidesPerView: 5.5,
  spaceBetween: 10,
  loop: true,
  freeMode: true,
  breakpoints: {
    0: {
      slidesPerView: 2.5,
      spaceBetween: 10,
    },
    900: {
      slidesPerView: 5,
      spaceBetween: 10,
      slidesPerGroup: 5,
    },
    1200: {
      slidesPerView: 6,
      spaceBetween: 10,
      slidesPerGroup: 6,
    },
  },
};

export default casinoProviderSliderSettings;