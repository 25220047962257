/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconLL = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g>
					<path d="M1811.1,1093.9c0-449.1-364-813.1-813.1-813.1l3,1209.3l704.4,4.9C1772.7,1376.6,1811.1,1239.7,1811.1,1093.9z" />
					<path d="M349,594.3c-105.6,137.8-164.1,311.2-164.1,499.6c0,192.5,61,369.5,171.2,508.7C356.1,1602.6,349,594.3,349,594.3z" />
					<path d="M861.2,1581.1V145H361.7l107,123.8c4.5,5.2,7,11.8,7,18.7v1425c0.2,6.8-2.1,13.4-6.4,18.7L361.7,1855h1195.9l191.9-244.2
		H889.7C873.9,1610.8,861.2,1596.8,861.2,1581.1z"/>

				</g>
			</svg>
		</div>
	);
};

export default IconLL;
