import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    let [searchParams] = useSearchParams();

    return (
      <Component
        {...props}
        location={location}
        params={params}
        navigate={navigate}
        searchParams={searchParams}
      />
    );
  }

  return ComponentWithRouterProp;
}

export default withRouter;
