/**
 * Footer - English version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import AdultIcon18 from "../../../media/img/18+.svg";
import AdultIcon21 from "../../../media/img/21+.svg";
import FooterExpand from "./FooterExpand";
import { setLiveChatWidget } from "../../../redux-store/settings-reducer";
import "../de/Footer.css";

const styles = (theme) => ({
  root: {
    "& .MuiTypography-body1": {
      fontSize: "1em",
    },
    "& .MuiDivider-root": {
      margin: "2em 0 2em 0",
    },
    "& .MuiButton-root": {
      // opacity: '0.6',
      padding: "0",
      fontSize: "1.2em",
    },
    backgroundColor: "var(--color-main)",
    "& .MuiButton-root:hover": {
      // opacity: '1',
      backgroundColor: "#ffffff00",
    },
    "& .MuiLink-root": {
      margin: "100em",
    },
    padding: "1em 3em 1em 3em",
    a: {
      margin: "100em",
    },
    backgroundColor: "var(--bg-footer)",
  },
  logoBar: {
    display: "flex",
    alignItems: "center",
  },
});

class Footer extends React.PureComponent {
  goTop = () => { };

  render() {
    log(this.constructor.name, "render");
    const { host, classes, locale, selectedTheme } = this.props;

    if (!host) return null;

    return (
      <Container id="Footer" className={classes.root} style={{ marginTop: "0.2em" }} maxWidth={false}>
        <Typography component="span">
          <Grid container style={{ textAlign: "center" }} className={classes.logoBar}>
            <Grid item xs={12} style={{ margin: "0.5em 0 2em 0" }}>
              <FooterExpand />
            </Grid>
            <Grid item xs={12}>
              <img style={{ height: "4em", margin: "0.5em 0 1.5em 0" }} src={`https://api.nubet.com/media/logos/${host.logo}_${selectedTheme}.svg`} alt="logo" />
              <br></br>
              {host.promoPage ? (
                <p>
                  <Link to={`/${this.props.locale}/promotions`} onClick={this.goTop.bind(this)}>
                    Promotions
                  </Link>
                </p>
              ) : null}
              <p>
                <Link to={`/${locale}/info/terms-conditions`} onClick={this.goTop.bind(this)}>
                  Terms and Conditions
                </Link>
              </p>
              <p>
                <Link to={`/${locale}/info/privacy-policy`} onClick={this.goTop.bind(this)}>
                  Privacy Policy
                </Link>
              </p>
              <p>
                {" "}
                <Link to={`/${locale}/info/betting-rules`} onClick={this.goTop.bind(this)} key={4}>
                  Betting Rules
                </Link>
              </p>
              <p>
                <Link to={`/${locale}/info/slots-rules`} onClick={this.goTop.bind(this)} key={6}>
                  Casino Rules
                </Link>
              </p>
              <p>
                <Link to={`/${locale}/info/bonus-terms`} onClick={this.goTop.bind(this)} key={3}>
                  Bonus Terms and Conditions Betting
                </Link>
              </p>
              <p>
                <Link to={`/${locale}/info/bonus-terms-slots`} onClick={this.goTop.bind(this)} key={5}>
                  Bonus Terms and Conditions Casino
                </Link>
              </p>
              <p>
                <Link to={`/${locale}/info/about-us`} onClick={this.goTop.bind(this)}>
                  About Us
                </Link>
              </p>
              <p>
                <Link to={`/${locale}/info/responsible-game`} onClick={this.goTop.bind(this)}>
                  Responsible Gaming
                </Link>
              </p>
              <p>
                <Link to={`/${locale}/info/help`} onClick={this.goTop.bind(this)}>
                  Help
                </Link>
              </p>
              {/* <p>
                <Link
                  to={`/${this.props.locale}/platform/news`}
                  onClick={this.goTop.bind(this)}
                >
                  News
                </Link>
              </p> */}
              <p>
                <Link to={`/${locale}/info/faq`} onClick={this.goTop.bind(this)}>
                  FAQ
                </Link>
              </p>
              <p style={{ cursor: "pointer", width: "max-content", margin: "auto" }} onClick={() => this.props.setLiveChatWidget(true)}>
                {/* <Obfuscate
                  email={"support@nubet.com"}
                  headers={{ subject: "Report a bug" }}
                > */}
                Customer Support
                {/* </Obfuscate> */}
              </p>
            </Grid>

            <Grid item xs={12}>
              {host.brand === "nubet_et" ? (
                <img style={{ height: "5.5em", margin: "2em 0 0 0" }} src={"https://api.nubet.com/media/icons/maksujatolliameti.png"} alt="licence-ee" />
              ) : (
                <img style={{ height: "4em", margin: "1em 0" }} src={"https://api.nubet.com/media/icons/spelinspektionen.png"} alt="licence-se" />
              )}
              <br></br>
              <br></br>
              <Link to={`/${locale}/info/responsible-game`} onClick={this.goTop.bind(this)}>
                <img style={{ height: "3em", margin: "0.5em 2em" }} src={AdultIcon18} alt="18+" />
              </Link>
              <Link to={`/${locale}/info/responsible-game`} onClick={this.goTop.bind(this)}>
                <img style={{ height: "3em", margin: "0.5em 2em" }} src={AdultIcon21} alt="21+" />
              </Link>
              <br></br>
              <br></br>
              <Link to={`/${locale}/payments`} onClick={this.goTop.bind(this)}>
                <img style={{ height: "2em", margin: "1em 0 0 0" }} src={`https://api.nubet.com/media/icons/trustly.svg`} alt="Trustly icon"></img>
              </Link>
              <br></br>
              <br></br>
            </Grid>
          </Grid>
          <br></br>

          <Divider />
          <Grid item xs={12} key={12} style={{ textAlign: "center" }}>
          Attention! This is a gambling site. Gambling is not a suitable way to solve financial problems. Familiarize yourself with the rules and act responsibly! Participating in gambling can be addictive. If you have an addiction or problems, you can find help{" "}
            <Link
              to={`/${locale}/info/responsible-game`}
              style={{
                color: "var(--color-contrast",
                textDecoration: "underline",
              }}
              onClick={this.goTop.bind(this)}
            >
              {" "}
              here
            </Link>
            !
          </Grid>
          <Divider />
          <Grid item xs={12} key={12} style={{ textAlign: "center" }}>
            Vana Lauri OÜ (registry code 16427120), address Harju maakond, Tallinn, Kesklinna linnaosa, Roseni tn 10-135, 10111, is licensed in Estonia and is subject to the supervision of the Estonian Tax and Customs Board and offers services on the basis of an open-ended Licenses to organize games of chance and sports betting, Licenses numbers HKT000066 and HKT000067 (valid from 10.07.2022), and on the basis of the Operating Permits, games of chance Operating Permit number HKL000390 (valid from 14.10.2022) and sports betting Operating Permit number HKL000409 (valid from 10.05.2023)
          </Grid>
          <Divider />
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <span style={{ fontSize: "1em" }}>
              {" "}
              © Copyright 2023 Vana Lauri OÜ (All rights reserved)<br></br>
              <br></br>
            </span>
          </Grid>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
    locale: state.i18n.locale,
    selectedTheme: state.settings.theme,
  };
};

export default connect(mapStateToProps, { setLiveChatWidget })(withStyles(styles)(Footer));
