// Precanned banner card
import "./style.css";
import icons from "../../sports/@components/sportsIcons";
import locales from "../../../helpers/datefns-locales";
import format from "date-fns/format";
import { useSelector, useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { calcGame } from "../../../helpers/gameFunctions";
import CommitIcon from "@mui/icons-material/Commit";
import OddsFormat from "../../OddsFormat";
import { useNavigate } from "react-router-dom";
import { emptySlipTemplate, saveBet } from "../../../actions";
import createJersey from "../../sports/@components/@utils/create-jersey";

const PrecannedCard = ({ game, bet }) => {
  //  action dispatcher
  const dispatch = useDispatch();
  // navigate
  const navigate = useNavigate();
  // select data
  const selectedSlipTemplate = useSelector((state) => state.selected.slipTemplate);
  const lang = useSelector((state) => state.i18n.locale);
  const layout = useSelector((state) => state.settings.layout);

  const handleAddToBetslip = () => {
    let gameCopy = calcGame(game);
    let payload = {
      game: gameCopy,
      gameId: game?.gameid,
      consumerFixtureId: `sr:match:${game?.gameid}`,
      isLive: false,
      isBetBuilder: true,
      isPrecanned: true,
      marketId: undefined,
      betslipUid: bet.betslipUid,
      price: bet.price,
      isActive: true,
      isPrecannedBet: true,
      hc: "",
      selectionId: bet.betslipUid,
      description: bet.description,
      translations: [],
      identifier: bet.identifier,
      marketDescription: bet.marketDescription,
      isMultiBet: 0,
      sn: bet.description?.toString(),
    };
    dispatch(emptySlipTemplate(selectedSlipTemplate));
    dispatch(saveBet(payload, selectedSlipTemplate, "PrecannedCard"));
    if (layout === "touch") {
      navigate(`/${lang}/slip`);
    }
  };

  const getOldPrice = () => {
    const currentPrice = parseFloat(bet.price);
    let currentFloor = currentPrice * 0.95;
    if (currentFloor) {
      currentFloor = currentFloor?.toFixed(1);
      // currentFloor = currentFloor?.toFixed(2);
    }

    return currentFloor;
  };

  let selectionsArray = bet && bet?.description && typeof bet?.description === "object" && bet?.description?.length ? bet.description : [];

  let selections = [];

  selectionsArray?.forEach((element) => {
    const elements = element?.split(",");
    elements?.forEach((el) => {
      selections.push(el);
    });
  });

  return (
    <div className="featured-game-container">
      <div className="featured-game-header">
        <div style={{ margin: "0 1em 0 0", justifySelf: "right" }}>{icons[game?.sport_code]}</div>
        <div className="featured-outright-logo">
          <span className="featured-banner-title">{game?.leagueName ? game?.leagueName : "League name not available"}</span>
          <p className="text-align-center font-bold font-xs" style={{ margin: "0", color: "var(--color-main-featured" }}>
            {game?.startTime
              ? format(new Date(game?.startTime), "dd.MM. HH:mm", {
                  locale: locales[lang],
                })
              : null}
          </p>
        </div>
        <div id="league-country-flag" style={{ margin: "0 0 0 1em" }}>
          <img src={`https://api.nubet.com/media/flags/${game?.countryCode}.png`} alt="flag" style={{ height: "1.6em" }} />
        </div>
      </div>
      <div className="precanned-info-container">
        <div className="precanned-game-teams-container">
          {createJersey(
            game,
            game?.team1_color,
            game?.team1_horizontal_stripes,
            game?.team1_number,
            game?.team1_sleeve,
            game?.team1_split,
            game?.team1_squares,
            game?.team1_stripes,
            game?.team1_sleeve_detail,
            game?.sport_code,
            true
          )}
          <div className="precanned-game-team-separator">vs</div>
          {createJersey(
            game,
            game?.team2_color,
            game?.team2_horizontal_stripes,
            game?.team2_number,
            game?.team2_sleeve,
            game?.team2_split,
            game?.team2_squares,
            game?.team2_stripes,
            game?.team2_sleeve_detail,
            game?.sport_code,
            false
          )}
          <div className="precanned-game-team">
            <span className="featured-game-team-name color-odds">{game?.team1}</span>
          </div>
          <div></div>
          <div className="precanned-game-team">
            <span className="featured-game-team-name color-odds">{game?.team2}</span>
          </div>
        </div>
        <div className="precanned-timeline-container">
          {selections?.map((des, indx) => (
            <Stack key={indx} direction="row" alignItems="center" gap={1} sx={{ margin: "0 0 -0.3em 0" }}>
              <CommitIcon sx={{ fontSize: "1.5em" }} className="rotated" />
              <span className="font-xm">{des}</span>
            </Stack>
          ))}
        </div>
      </div>
      <div className="precanned-card-footer ">
        <div className="precanned-total-odds-container cursor-pointer" onClick={handleAddToBetslip}>
          <Stack direction="row" alignItems="center" gap={1}>
            {/* <del className="color-odds font-xl font-bold-2 prev-odd"> */}
            <del className="color-odds font-xl prev-odd">
              <OddsFormat isActive={true} price={getOldPrice()} />
            </del>
            <DoubleArrowIcon fontSize="small" style={{ fontSize: "1.4em", color: "var(--color-main-featured)" }} />
            {/* <span className="color-odds font-xl font-bold-2"> */}
            <span className="color-odds font-xl">
              <OddsFormat isActive={true} price={bet.price} />
            </span>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default PrecannedCard;
