import api from "../../apis/nubet";
import apiCatalog from "../../apis/nubetCatalog";

// GET BETBUILDER BANNERS DATA
export const getBetbuilderBanners = async (lang) => {
  try {
    const res = await api.get("featured/precanned", { params: { lang } });
    return res.data?.payload;
  } catch (error) {
    console.log(error);
  }
};

// GET OUTRIGHTS BANNERS
export const getOutrightsBanners = async (lang) => {
  try {
    const res = await api.get("featured/outrights", { params: { lang } });
    const payload = res.data?.payload;
    const result = typeof payload === "object" && res?.data?.type !== "error" ? payload : {};
    return result;
  } catch (error) {
    console.log(error);
  }
};

// GET FEATURED GAMES BANNERS

export const getFeaturedGamesBanners = async (lang) => {
  try {
    const res = await api.get("featured/games", { params: { lang } });
    return res.data?.payload;
  } catch (error) {
    console.log(error);
  }
};
