/**
 * Set auth header ( jwt ) for API request
 */

export default function authHeader() {
    const jwt = localStorage.getItem('jwt');
  
    if (jwt) {
      return { Authorization: jwt };
    } else {
      return {};
    }
  }