const casinoCategorySliderSettings = {
  slidesPerView: 4,
  spaceBetween: 10,
  loop: true,
  freeMode: true,
  breakpoints: {
    0: {
      slidesPerView: 2.2,
      spaceBetween: 2,
    },
    900: {
      slidesPerView: 4,
      spaceBetween: 10,
      slidesPerGroup: 4,
    },
  },
};

export default casinoCategorySliderSettings;
