/**
 * ResponsibleGame - Estish version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import ListItem from "@mui/material/ListItem";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
    "& .MuiListItem-root": {
      padding: "0.2em",
    },
  },
});

class ResponsibleGame extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          {host.gaming && host.bet
            ? [
                <div id="legalcontent" className={classes.root} key={0}>
                  <h1>VASTUTUSTUNDLIK MÄNGIMINE</h1>
                  Nubet.com toetab vastutustundlikku hasartmängimist ja spordikihlvedude tegemist <br></br>
                  <br></br>
                  Nubet.com kasiino ja spordiennustused on suunatud klientidele, kes peavad hasartmänge ja kihlvedusid lõbusaks ja meelelahutuseks ning oskavad oma panustamiskäitumist vastutustundlikult hinnata.{" "}
                  <br></br>
                  <br></br>
                  Kui võidusoov viib enesekontrolli kaotamiseni, peate võib-olla abi otsima. Palun järgige meie veebisaiti külastades järgmisi põhimõtteid.<br></br>
                  <br></br>
                  <ListItem>Kõigepealt määrake oma sissemakse maksimaalne kogupanus</ListItem>
                  <ListItem>Ärge deponeerige oma mängijakontole rohkem raha, nagu täpsustasite enne alustamist</ListItem>
                  <ListItem>Otsustage eelnevalt, millise kasumiga soovite veebisaidilt lahkuda</ListItem>
                  <ListItem>Määrake eelnevalt kindel summa, kui suured võivad teie kaotused olla</ListItem>
                  <ListItem>Püüdke alati olla keskendunud ja puhanud, kui mängite hasartmänge</ListItem>
                  <ListItem>Tehke hasartmängude või kihlvedude ajal sagedasi pause, kus teete midagi muud</ListItem>
                  <ListItem>Ärge kunagi mängige ega panustage narkootikumide, tugevate ravimite mõju all ega pärast liigset alkoholitarbimist</ListItem>
                  <ListItem>Ärge võtke krediiti et hasartmänge mängida või panustada</ListItem>
                  <ListItem>Hoidke oma panuste limiiti, et kaotus ei mõjutaks teie igapäevaelu</ListItem>
                  <ListItem>Ärge ignoreerige ega unustage oma sotsiaalset keskkonda.</ListItem>
                  <br></br>
                  Pakume kõigile kasutajatele võimalust määrata oma limiidid või blokeerida oma konto teatud ajaks. Pidage meeles, et kui olete piiri seadnud või seda langetanud, jõustub see kohe. Nubet.com ei võta seega
                  panuseid, mis neid limiite ületavad. Mängimise blokeerimiseks võite ühendust võtta ka support@nubet.com. Teil on võimalus lasta oma konto piiratud ajaks või jäädavalt blokeerida. On palju sõltumatuid
                  organisatsioone, kes pakuvad abi. Esimeste teadmiste saamiseks vaadake palun järgmist: <br></br>
                  <br></br>
                  <h4>Professionaalne abi:</h4>
                  Kui sul on hasartmänguprobleem või kui sa oled probleemse mänguri sõber või pereliige, saad tasuta abi ja nõustamist hasartmängusõltuvuse keskusest:<br></br>
                  <br></br>
                  tel: 15410<br></br>
                  email: info@15410.ee<br></br>
                  www: www.15410.ee<br></br>
                  <br></br>
                  <b>Nubet.com strictly keelab alaealistel kasutada meie hasartmänguautomaate ja kihlveopakkumisi</b>
                  <br></br>
                  Me ei võta panuseid klientidelt, kes ei ole oma riigi seaduste kohaselt täisealised. Alaealise registreerimine on meie tingimuste rikkumine ja sellest võib teatada vastavatele ametiasutustele. Nubet.com
                  küsib kliendi seadusliku vanuse kontrollimiseks isikut tõendavat dokumenti. Nubet.com on seadusega kohustatud tühistama kõik alaealiste panused ja kandma kasumi vastavalt vajadusele reguleerivatele
                  asutustele.
                  <br></br>
                  <br></br>
                </div>,
              ]
            : null}
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(ResponsibleGame));
