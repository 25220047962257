/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconCY = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g>
					<path d="M627.6,1590.7c-41.1,31.6-91.9,51.2-147.7,51.2c-134.4,0-243.8-109.4-243.8-243.8c0-134.4,109.4-243.8,243.8-243.8
		c101.4,0,188.4,62.4,225.1,150.7l76.3-79.2c-60.1-104.9-171.9-176.4-301.4-176.4c-192.6,0-348.7,156.1-348.7,348.7
		c0,192.6,156.1,348.7,348.7,348.7c90.6,0,172.4-35.4,234.4-92.1c-23.8-8-46.4-20.7-65.7-39.2
		C640.6,1607.8,633.8,1599.4,627.6,1590.7z"/>
					<path d="M1157,1052.1L972.4,944.9c82.3-51.5,51.8-32.4,134.1-84l-2.6,26.2c-4.4,44.6,28.5,85.5,69.9,93.9
		c1,0.2,324.1,55.1,335.9,55.1c42,0,79-30.3,86.1-73.1c7.9-47.6-24.3-92.7-71.9-100.5L1286,822.9l11.3-113.5
		c11.4-72.5,16.4-95.3-10.7-138.6l-34.1-54.4c-44-70.3-136.7-91.7-207.2-47.6L672.7,702.1c-91.5,57.3-136.2,178.1-61.4,267
		c29.2,34.7,313,209.2,313,209.2l-229.9,238.5c-40.2,41.7-39,108.1,2.7,148.3c20.4,19.6,46.6,29.4,72.8,29.4
		c27.5,0,55-10.7,75.5-32.1l334.4-346.8C1227,1166.8,1215.9,1086.3,1157,1052.1z"/>
					<path d="M1548.7,575.8c81.7-51.2,106.5-158.9,55.3-240.6c-51.2-81.8-158.9-106.5-240.6-55.4c-81.7,51.2-106.5,158.8-55.3,240.5
		C1359.2,602.1,1467,626.9,1548.7,575.8z"/>
					<path d="M1520.1,1049.4c-194.6,0-348.7,160.6-348.7,348.7c0,192.6,156.1,348.7,348.7,348.7c192.6,0,348.7-156.1,348.7-348.7
		C1868.8,1205.5,1712.7,1049.4,1520.1,1049.4z M1520.1,1641.9c-134.4,0-243.8-109.4-243.8-243.8c0-134.4,109.4-243.8,243.8-243.8
		c134.4,0,243.8,109.4,243.8,243.8C1763.9,1532.5,1654.5,1641.9,1520.1,1641.9z"/>
				</g>
			</svg>
		</div>
	);
};

export default IconCY;
