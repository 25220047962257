// src/slices/settingsSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostConfig from "../../config/hostConfig";
import playerPropsConfig from "../../config/playerPropsConfig";
import { getMaintananceStatus } from "./_requests";

// Determine default layout based on device capabilities
const canHover = window.matchMedia("(hover: hover)").matches;
const defaultLayout = canHover ? "mouse" : "touch";

// Define the initial state
const initialState = {
  theme: "dark",
  layout: defaultLayout,
  mode: "compact",
  oddsFormat: "decimal",
  ticketListStatus: "pending",
  marketSets: {},
  marketsLayout: "horizontal",
  leagueGrouping: false,
  showMyTicketsPanel: false,
  showCompetitorAvatar: false,
  tvMode: false,
  sse: false,
  maintenanceMode: false,
  notificationEnabled: false,
  notificationContent: "",
  shouldConfirmTerms: false,
  playerProps: playerPropsConfig,
  isLiveChatOpen: false,
  host: hostConfig.defaultHost, // Assuming there's a default host
  url: "",
  navMode: "",
  isMobile: false,
};

export const getMaintananceStatusAsync = createAsyncThunk("getMaintananceStatus/thunk", getMaintananceStatus);

// Create the settings slice
const settingsSlice = createSlice({
  name: "slice/settings",
  initialState,
  reducers: {
    setTermsStatus: (state, { payload }) => {
      state.shouldConfirmTerms = payload;
    },
    setTheme: (state, { payload }) => {
      state.theme = payload;
    },
    setLayout: (state, { payload }) => {
      state.layout = payload;
    },
    setMode: (state, { payload }) => {
      state.mode = payload;
    },
    setNavMode: (state, { payload }) => {
      state.navMode = payload;
    },
    setMarketsLayout: (state, { payload }) => {
      state.marketsLayout = payload;
    },
    setUrl: (state, { payload }) => {
      state.url = payload;
    },
    setHost: (state, { payload }) => {
      let host = localStorage.getItem("host") ? hostConfig[localStorage.getItem("host").replace(/\./g, "_")] : payload;
      state.host = host;
    },
    setIsMobile: (state, { payload }) => {
      state.isMobile = payload;
    },
    setSSE: (state, { payload }) => {
      state.sse = payload;
    },
    setOddsFormat: (state, { payload }) => {
      state.oddsFormat = payload;
    },
    saveMarketSetConfig: (state, { payload }) => {
      state.marketSets = payload;
    },
    saveTicketFilter: (state, { payload }) => {
      state.ticketListStatus = payload;
    },
    setLiveChatWidget: (state, { payload }) => {
      state.isLiveChatOpen = Boolean(payload);
    },
    toggleMyTicketsPanel: (state, { payload }) => {
      state.showMyTicketsPanel = Boolean(payload);
    },
    updateMaintenanceMode: (state, { payload }) => {
      state.maintenanceMode = Boolean(payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMaintananceStatusAsync.fulfilled, (state, { payload }) => {
      const hasNotification = Boolean(payload?.notification);
      const isMaintananceEnabled = Boolean(payload?.maintenance);
      state.maintenanceMode = isMaintananceEnabled;
      state.hasNotification = hasNotification;
      state.notificationContent = payload?.notification;
    });
  },
});

// Export actions and reducer
export const {
  setTermsStatus,
  setTheme,
  setLayout,
  setMode,
  setNavMode,
  setMarketsLayout,
  setUrl,
  setHost,
  setIsMobile,
  setSSE,
  setOddsFormat,
  saveMarketSetConfig,
  saveTicketFilter,
  setLiveChatWidget,
  toggleMyTicketsPanel,
  updateMaintenanceMode,
} = settingsSlice.actions;

export default settingsSlice.reducer;
