import "./style.css";
import React from "react";
import { connect } from "react-redux";
import { format } from "date-fns";
import locales from "../../../helpers/datefns-locales";
import icons from "../../sports/@components/sportsIcons";
import { checkSelectedOdd } from "../../sports/@components/@utils/odds-utils";
import { saveBet } from "../../../actions";
import { toggleMyTicketsPanel } from "../../../redux-store/settings-reducer";

class FeaturedOutrightCard extends React.PureComponent {
  addBet(odd) {
    const { game, selectedSlipTemplate } = this.props;
    let payload = {
      gameId: game.gameid,
      isLive: game.isLive,
    };

    const marketId = odd.m;
    const selectionId = odd.s;
    const price = odd.p;
    const hc = odd.hc;
    const isActive = odd.iA;

    payload.marketId = marketId;
    payload.selectionId = selectionId;
    payload.price = price;
    payload.hc = hc;
    payload.hn = odd.hn;
    payload.sn = odd.sn;
    payload.nr = odd?.nr;
    payload.isActive = isActive;
    payload.game = game;
    payload.isOutright = true;
    this.props.toggleMyTicketsPanel(false);
    this.props.saveBet(payload, selectedSlipTemplate, "OutrightCard");
    return Object.keys(payload)?.length === 0 ? false : true;
  }

  render() {
    const { game, lang, odds, bets } = this.props;
    return (
      <div className="featured-outright-container">
        <div className="featured-outright-header">
          <div style={{ margin: "0 1em 0 0", justifySelf: "right" }}>{icons[game?.sport_code]}</div>
          <div className="featured-outright-logo">
            <span className="featured-banner-title">
              {game?.sport_code === "TR" ? game?.leagueName : null} {game?.game_title}
            </span>
            <p className="text-align-center font-bold font-xs" style={{ margin: "0", color: "var(--color-main-featured" }}>
              {game?.sport_code === "TR"
                ? format(new Date(game?.nextBetStop), "dd.MM. HH:mm yyyy", {
                    locale: locales[lang],
                  })
                : !game.odds.find((item) => item.m === 534)
                ? format(new Date(game?.nextBetStop), "dd.MM. HH:mm yyyy", {
                    locale: locales[lang],
                  })
                : format(new Date(game?.endTime), "dd.MM. HH:mm yyyy", {
                    locale: locales[lang],
                  })}
            </p>
          </div>
          <div id="league-country-flag" style={{ margin: "0 0 0 1em" }}>
            <img src={`https://api.nubet.com/media/flags/${game?.countryCode}.png`} alt="flag" style={{ height: "1.6em" }} />
          </div>
        </div>
        <div className="featured-outright-game-details">
          <div className="featured-outright-game-logo"></div>
          <div className="featured-outright-game-odds-container">
            {odds
              ?.sort((a, b) => parseFloat(a.p) > parseFloat(b.p))
              .map((odd, indx) => {
                return (
                  <div key={indx} className="featured-outright-game-odds-row">
                    <p
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {odd?.sn}
                    </p>
                    <div
                      onClick={() => this.addBet(odd)}
                      style={{}}
                      className={`${odd?.priceChange === ">" ? "odds-raise" : odd?.priceChange === "<" ? "odds-drop" : odd?.priceChange === "=" ? "" : "color-odds"}   ${
                        checkSelectedOdd(odd, game, bets, 0) && !odd.nr ? "selected-odds" : "odds-featured"
                      } font-xl featured-outright-odd`}
                    >
                      {odd?.p}
                    </div>
                    {/* </a> */}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const oddsCopy = [...ownProps.game?.odds];
  const oddsList = oddsCopy.sort((a, b) => a?.ord - b?.ord);

  return {
    lang: state.i18n.locale,
    game: ownProps.game,
    odds: oddsList,
    selectionPrintLabels: state.labels.selection ? state.labels.selection.print : null,
    selectedSlipTemplate: state.selected.slipTemplate,
    bets: state.slips && state.slips[state.selected.slipTemplate].settings?.bets ? Object.values(state.slips[state.selected.slipTemplate].settings.bets) : null,
  };
};

export default connect(mapStateToProps, {
  saveBet,
  toggleMyTicketsPanel,
})(FeaturedOutrightCard);
