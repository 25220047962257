/**
 * Log service
 */

export const log = (text, type) => {
  if ("log-react-redux" in localStorage) {
    if (localStorage.getItem("log-react-redux-ignore")?.includes(text))
      return null;

    if (!type) {
      // console.log(text);
    } else {
      switch (type) {
        case "render":
          if ("-render" in localStorage) return null;
          // console.log('%c' + text, 'color:#42C09F');
          break;

        default:
          break;
      }
    }
  }

  return null;
};

export const logSSEScores = (content) => {
  let expression = localStorage.getItem("log-sse-scores");
  let conditions = expression?.split(",");
  let conditionsMet = 0;
  let output = {};

  if (Object.keys(content)?.length) {
    for (let record in content) {
      if (expression) {
        conditionsMet = 0;
        for (let key in content[record]) {
          for (let condition in conditions) {
            if (
              conditions[condition].includes(key + ": " + content[record][key])
            )
              conditionsMet++;
          }
        }

        if (conditionsMet === conditions?.length) {
          if (!output[content[record].sportCode])
            output[content[record].sportCode] = {};
          output[content[record].sportCode][content[record].eventID] =
            content[record];
        }
      } else {
        if (!output[content[record].sportCode])
          output[content[record].sportCode] = {};
        output[content[record].sportCode][content[record].eventID] =
          content[record];
      }
    }
  }

  if (Object.keys(output)?.length) {
    let logText = expression ? expression : "all";
    console.group(
      "%c...receiving SCORES [" + logText + "] ->",
      "color:#2BAEF8"
    );
    console.dir(output);
    console.groupEnd("sse-scores");
  }
};

export const logSSEOdds = (content) => {
  let expression = localStorage.getItem("log-sse-odds");
  let conditions = expression?.split(",");
  let conditionsMet = 0;
  let output = {};

  if (Object.keys(content)?.length) {
    for (let record in content) {
      if (expression) {
        conditionsMet = 0;
        for (let key in content[record]) {
          for (let condition in conditions) {
            if (
              conditions[condition].includes(key + ": " + content[record][key])
            )
              conditionsMet++;
          }
        }

        if (conditionsMet === conditions?.length) {
          if (!output[content[record].marketID])
            output[content[record].marketID] = {};
          output[content[record].marketID][content[record].selectionID] =
            content[record];
        }
      } else {
        if (!output[content[record].marketID])
          output[content[record].marketID] = {};
        output[content[record].marketID][content[record].selectionID] =
          content[record];
      }
    }
  }

  if (Object.keys(output)?.length) {
    let logText = expression ? expression : "all";
    console.group("%c...receiving ODDS [" + logText + "] ->", "color:#2BAEF8");
    console.dir(output);
    console.groupEnd("sse-odds");
  }
};
