/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconTR = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g>
					<path d="M1357.3,218.8c5.6,22.2,22.4,40.5,45.3,47.3c6.3,1.9,12.7,2.8,19,2.8c1.4,0,2.9,0,4.3-0.1c2.7,66.6,13.6,126,17.3,144.4
		c2.6,12.8,8.9,24.6,18.1,33.9c78.5,78.6,178.4,352.6,210.3,451.5c2,6.1,4.9,12,8.5,17.3l45.7,66.3c19.1,27.4,26.7,68,19.7,105.9
		c-5.1,27.5-15.2,39.2-17.5,40.8c-41,22.1-67.1,26.8-81.8,26.8c-13.9,0-28.6-3.2-41.6-26.7c-10.7-19.3-13.7-40.6-14.1-43.2
		c-2.7-31.4-27-55.4-58.3-59.1c-72.6-8.4-138.5-57.6-181.1-97.4c-49.6-46.4-80.2-91.6-80.5-92c-12.7-19-33.7-29.5-55.2-29.5
		c-10.3,0-20.7,2.4-30.4,7.4c-84.5,43.7-140,115.8-160.6,208.6c-40.5,182.5,59,397.1,135.9,496.8c78.9,102.2,57,258.9,38.8,337.8
		c-140.2-16.1-527.4-84.3-711.6-356.5c-93.7-138.2-134.4-279.1-120.9-418.7c10.1-104.6,48.4-170,48.6-170.3
		c10.8-17.6,12.7-39.1,5.3-58.4c-7.4-19.2-23.3-33.9-43.1-39.8c-8.8-2.6-18.2-3.9-28.1-3.9c-4.8,0-9.7,0.4-14.9,1
		c133.9-171.3,362.8-302.6,365.7-304.3c22.5-12.7,35.5-37.4,33.4-63.1c-1.7-20.6-12.9-39-29.8-50c127-68,252.4-85.4,347.1-85.4
		c98.9,0,166.1,18.9,166.6,19.1c6.1,1.8,12.4,2.7,18.6,2.7c12.2,0,24.4-3.4,35-10c15.5-9.6,26.3-25.2,29.9-43
		C1306.4,265.4,1327.6,243.7,1357.3,218.8 M1450.6,70.8c0,0-202,123.5-214.3,193.6c0,0-74.2-21.7-185.2-21.7
		c-149.3,0-365,39.3-556.4,223.5c0,0,81.5-21,137.4-21c14.1,0,26.5,1.3,35.6,4.7c0,0-407.9,230.9-482,502.6c0,0,115-75.4,163.8-75.4
		c3.4,0,6.6,0.4,9.3,1.2c0,0-177.4,289.8,74,660.8c248.3,366.7,815.7,390.2,815.7,390.2s97-277.6-35.5-449.2
		c-103.1-133.9-236.2-482.5,2.7-606c0,0,131.9,197.8,309,218.4c0,0,12.7,129.4,121.6,129.4c29.9,0,67.1-9.8,113.3-34.7
		c57.1-30.8,78.4-160.5,20.7-243l-45.4-65.9c0,0-119.4-370.8-226.5-478c0,0-37-185.4,0-271.8c0,0-66,37.1-86.6,74.2
		C1421.6,202.6,1429.9,91.2,1450.6,70.8L1450.6,70.8z"/>
				</g>
			</svg>
		</div>
	);
};

export default IconTR;
