/**
 * Reducer for WP content
 */

import { GET_WP_CONTENT, GET_WP_NEWS_CONTENT } from "../actions/types";

const INTIAL_STATE = {
  news: [],
  campaigns: [],
};

const fn = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case GET_WP_CONTENT:
      let stateCopy = { ...state };
      stateCopy.campaigns = action.payload;

      return stateCopy;
    case GET_WP_NEWS_CONTENT:
     let stateCopy1 = { ...state };
      stateCopy1.news = action.payload;
      return stateCopy1;
    default:
      return state;
  }
};

export default fn;
