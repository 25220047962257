/**
 * BonusTerms - Fin version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import { ListItem } from "@mui/material";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class BonusTerms extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>NUBET.COM VEDONLYÖNTI BONUS KÄYTTÖEHDOT </h1>
            Suomenkielinen versio 1.1 Viimeisin päivitys 27.1.2023.<br></br>
            <br></br>
            <h3 id="General">1. YLEISTÄ</h3>
            1.1. Näitä urheiluvedonlyönnin ehtoja sovelletaan Nubetin urheiluvedonlyöntiin. Urheiluvedonlyöntibonusta ei voi käyttää muihin tuotteisiin, kuten Nubet-kasino -tuotteisiin. Jos sinulla on aktiivinen
            urheiluvedonlyöntibonus, voit pelata Nubet-kasinolla tai muissa tuotteissa vain, jos talletat lisää rahaa.<br></br>
            <br></br>
            1.2. Näissä urheiluvedonlyöntibonusehdoissa selitetyn urheiluvedonlyöntibonuksen myöntäjä on Nubet verkkosivustolla Nubet.com, jota ylläpitää Vana Lauri OÜ, Virossa rekisteröidyn yrityksen
            rekisteröintinumerolla 16427120 rekisteröity yritys, jonka rekisteröity osoite on Roseni 10-135, 10111 Tallinna, Viro (jäljempänä "Nubet" tai "yritys"). Vana Lauri OÜ:llä on Viron vero- ja tullihallituksen
            myöntämä toimilupa ja sen valvonta, ja sillä on seuraavat toimiluvat: HKT000066 (myöntämispäivä: 10.07.2022) ja HKT000067 (myöntämispäivä: 10.07.2022).<br></br>
            <br></br>
            1.3. Nubet voi myöntää sinulle minkä tahansa tyyppistä urheiluvedonlyöntibonusluottoa tai muunlaisia bonuksia, kuten ilmaisvedon (jäljempänä myös "bonusraha", "bonus" tai "urheiluvedonlyöntibonus"), jos
            tietyt ehdot täyttyvät. Pelaaja päättää itse, haluaako hän käyttää bonuksen, ja pelaaja voi halutessaan kieltäytyä bonuksesta jättämällä bonustarjouksen käyttämättä. Bonuksen käyttöön liittyviä
            vedonlyöntiehtoja (jäljempänä myös "vedonlyönti") sovelletaan kaikkiin myönnettyihin urheiluvedonlyöntibonuksiin, ja vedonlyöntisäännöt ilmoitetaan pelaajalle etukäteen verkkosivustolla ja sähköpostitse.
            Myönnetty urheiluvedonlyöntibonus hyvitetään automaattisesti pelitilille bonusrahana, jota sitovat näissä urheiluvedonlyöntibonusehdoissa määritellyt säännöt.<br></br>
            <br></br>
            <h3 id="Tc">2. YLEISET VEDONLYÖNTI BONUS KÄYTTÖEHDOT</h3>
            2.1. Näitä yleisiä urheiluvedonlyöntibonusehtoja sovelletaan kaikkiin Nubetin myöntämiin urheiluvedonlyöntibonuksiin. Joillakin bonustyypeillä on erikseen määritellyt ehdot, jotka voivat poiketa yleisistä
            urheiluvedonlyöntibonusehdoista, ja ne selitetään omassa kappaleessaan näissä urheiluvedonlyöntibonusehdoissa viittaamalla bonustyyppiin, esimerkiksi urheiluvedonlyönnin tervetuliaisbonus tai
            urheiluvedonlyönnin talletusbonus. Jos eroavaisuuksia ilmenee, sovelletaan bonustyypeittäin määriteltyjä ehtoja ja edellytyksiä. Huomaa, että muun muassa vähimmäiskierrätysvaatimukset ja
            vähimmäistalletusmäärät voivat vaihdella eri bonustyypeissä.<br></br>
            <br></br>
            2.2. Nubet haluaa tarjota urheiluvedonlyöntibonuksen kaikille pelaajilleen. Yhtiö voi kuitenkin harkintansa mukaan sulkea yksittäisiä pelaajia tarjouksen ulkopuolelle. Yhtiöllä on yksinomainen harkintavalta
            mitätöidä bonukset ja niihin liittyvät voitot, jos yhtiö näkee, että pelaaja ei noudata bonussääntöjä. Yhtiö voi myös muuttaa bonuksen ehtoja tai lopettaa sen tarjoamisen milloin tahansa ilman
            ennakkoilmoitusta.<br></br>
            <br></br>
            2.3. Nubet urheiluvedonlyönti-bonus on voimassa ja se on käytettävä tietyn kampanjajakson aikana. Jos sitä ei käytetä, se vanhenee, eikä sitä voi käyttää sen jälkeen.<br></br>
            <br></br>
            2.4. Nubeturheiluvedonlyönti-bonus on käytettävissä vain kerran asiakasta, pelitiliä, kotitaloutta, talletuksen tekemiseen käytetyn rahansiirtomenetelmän ja/tai tilin omistajaa, yhteistä tietokonetta
            (yliopisto, työpaikka, internetkahvila jne.) ja/tai IP-osoitetta kohden.<br></br>
            <br></br>
            2.5. Nubet urheiluvedonlyönti-bonusta ei voi yhdistää muihin bonuksiin, ellei nimenomaisesti toisin mainita, eikä sitä voi käyttää yhdessä muiden kampanjoiden kanssa.<br></br>
            <br></br>
            2.6. Nubetin urheiluvedonlyönti-bonuskampanjatarjouksen, joka on lähetetty sähköpostitse, voi lunastaa vain alkuperäinen bonussähköpostin vastaanottaja. Bonuksen vastaanottavan ja käyttävän
            sähköpostiosoitteen on oltava sama kuin pelitiliä luotaessa. Nubet pidättää oikeuden tutkia, onko urheiluvedonlyönti-bonustarjouksen käyttänyt pelitilin haltija saanut bonuskoodin henkilökohtaisesti
            pelitilillä käytettyyn sähköpostiosoitteeseen. Tämän säännön rikkominen voi johtaa bonuksen ja siihen liittyvien voittojen takavarikointiin.<br></br>
            <br></br>
            2.7. Nubetin urheiluvedonlyönti-bonus, joka on sidottu talletuksen tekemiseen, on voimassa vain silloin, kun käytetään Nubetin tarjoamia maksutapoja, joita ei ole nimenomaisesti suljettu pois
            urheiluvedonlyönti-bonustarjouksen maksutapana. Tilinhaltijan ja maksutavan haltijan on oltava sama. Pelitilin luottosaldoa, joka on peräisin pelaajan tekemästä talletuksesta ilman bonuksen käyttöä ja jota
            eivät siten sido bonusehdot, kutsutaan oikean rahan saldoksi (jäljempänä ”oikean rahan saldo").<br></br>
            <br></br>
            2.8. Nubet urheiluvedonlyönti-bonus hyvitetään pelitilisi saldoon. Jos urheiluvedonlyönti-bonukseen liittyy panostus, pelitilin saldoa ei voi nostaa tai muuntaa oikean rahan saldoksi ennen kuin panostus on
            suoritettu loppuun bonusehdoissa määritellyllä tavalla. Jos urheiluvedonlyönti-bonus myönnetään talletuksen yhteydessä, tämä koskee myös talletettua summaa, sillä molempia sitovat urheiluvedonlyönti-bonuksen
            ehdot.<br></br>
            <br></br>
            2.9. Jos talletetaan enemmän kuin urheiluvedonlyönti-bonustarjouksen enimmäismäärä, koko talletussumma on sidottu urheiluvedonlyönti-bonusehtoihin. Esimerkki: Jos saat 100 % 100 euroon asti
            urheiluvedonlyönti-bonuksen ja talletat 150 euroa, 150 euroa on urheiluvedonlyönti-bonuksen kierrätysvaatimusten alainen. Tässä esimerkissä urheiluvedonlyönti-bonuksen määrä olisi 100 euroa.<br></br>
            <br></br>
            2.10. Vain maksetut vedot lasketaan mukaan panostusvaatimukseen. Jos veto/panos palautetaan keskeytyneen tapahtuman vuoksi tai tapahtuma julistetaan peruuntuneeksi tai mitättömäksi tai jos otteluvetoa ei
            voida jostain muusta syystä ratkaista normaalisti, vetosummaa ei lasketa mukaan panosvaatimukseen. Sama pätee myös vedonlyöntiin, joka lunastetaan cash outina.<br></br>
            <br></br>
            2.11. Jos kaksi tai useampia vetoja asetetaan samasta tapahtumasta eri lopputuloksilla, vain ensimmäinen panos lasketaan mukaan panostusvaatimukseen.<br></br>
            <br></br>
            2.12. Kun pelaaja on lyönyt vetoa aktiivisella bonuksella, lunastettua bonusta ei voi enää peruuttaa menettämättä bonusta.<br></br>
            <br></br>
            2.13. Vain ensimmäinen panos tapahtumaa kohti lasketaan panostusvaatimuksen täyttymiseen. Kaksois- tai moninkertaisia vetoja ei oteta huomioon panostusvaatimuksen täyttämisessä. Järjestelmävedot voidaan
            laskea vain osittain mukaan panostusvaatimuksen täyttämiseen.<br></br>
            <br></br>
            2.14. Ellei toisin mainita, kaikki saadut urheiluvedonlyöntibonukset on kierrätettävä vähintään kuusi (6) kertaa, ennen kuin ne voidaan maksaa tai niitä voidaan yrittää nostaa. Bonusrahalla tehtyjä voittoja
            ei makseta ennen kuin bonuksen kierrätysehdot täyttyvät. Yritys nostaa bonus ennen kuin bonuksen kierrätysvaatimukset on täytetty johtaa siihen, että kaikki bonuksesta saadut voitot ja itse bonus menetetään.
            <br></br>
            <br></br>
            2.15. Bonuksen kierrätysvaatimukset on täytettävä tietyn ajan kuluessa bonuksen saamisesta. Jos bonusehtoja ei täytetä ajoissa, Nubet pidättää oikeuden peruuttaa bonuksen määrän ja kaikki siitä saadut voitot.
            <br></br>
            <br></br>
            2.16. Nubet valvoo kaikkien bonusten käyttöä epäsäännöllisten pelitapojen varalta.<br></br>
            <br></br>
            2.17. Urheiluvedonlyönti-bonuksen väärinkäyttö on bonusehtojen rikkominen. Nubet olettaa bonusehtojen rikkomisen seuraavissa tapauksissa:<br></br>
            <br></br>
            <ListItem>bonuksen moninkertainen käyttö saman henkilön toimesta tai samaa IP:tä tai päätelaitetta käyttäen;</ListItem>
            <ListItem>bonustarjouksen epäilty järjestäytynyt käyttö;</ListItem>
            <ListItem>
              sellaisten vetojen asettaminen, jotka niiden tuloksesta riippumatta takaavat urheiluvedonlyönti-bonuksen voiton yksittäiselle henkilölle tai yhdessä yhteistyötä tekevälle pelaajaryhmälle, joka tekee niin
              sanottuja "varmoja vetoja"
            </ListItem>
            <br></br>
            2.18. Jos epäillään, että urheiluvedonlyönti-bonusehtoja on rikottu tai asiakas on asettanut vedon, joka takaa voiton urheiluvedonlyönnin talletusbonuksen, ilmaisvedon tai muun kampanjatarjouksen ansiosta
            vedon lopputuloksesta riippumatta, Nubet pidättää itsellään oikeuden vaatia Nubetin urheiluvedonlyöntibonus-tarjouksen takaisin, oikaista tai peruuttaa asetetut vedot, kieltäytyä pyydetyistä kotiutuksista ja
            sulkea tilit.<br></br>
            <br></br>
            2.19. Bonusta ei hyvitetä, jos sinut on suljettu Nubet-tarjouksen ulkopuolelle tai poistettu sen piiristä.<br></br>
            <br></br>
            2.20. Urheiluvedonlyönti-bonus on voimassa, kunnes panostus on suoritettu tai urheiluvedonlyönti-bonus peruutetaan.<br></br>
            <br></br>
            2.21. Nubet pidättää oikeuden muuttaa, keskeyttää tai peruuttaa bonuksen ja kampanjan keston milloin tahansa.<br></br>
            <br></br>
            2.22. Sovelletaan Nubetin ehtoja.<br></br>
            <br></br>
            <h3 id="Welcome">3. VEDONLYÖNNIN TERVETULIAISBONUS KÄYTTÖEHDOT</h3>
            3.1. Urheiluvedonlyönnin tervetuliaisbonustarjousta ei voi käyttää yhdessä muiden tarjousten kanssa, eikä sitä voi käyttää yhdessä toisen urheiluvedonlyönnin tervetuliais-bonustarjouksen kanssa.<br></br>
            <br></br>
            3.2. Nubetin urheiluvedonlyönnin tervetuliaisbonus on voimassa vain kampanja-aikana ensimmäiselle tai toiselle talletukselle uudelle pelitilille.<br></br>
            <br></br>
            3.3. Minimitalletus on 20 euroa.<br></br>
            <br></br>
            3.4. Pelaajalla on 30 päivää aikaa tehdä talletus ja käyttää mikä tahansa urheiluvedonlyönnin tervetuliaisbonustarjous rekisteröitymisestä.<br></br>
            <br></br>
            3.5. Nubetin urheiluvedonlyönnin tervetuliaisbonus hyvitetään asiakkaalle heti talletuksen yhteydessä vastaamaan 100 % talletussummasta määritellyn enimmäismäärän puitteissa.<br></br>
            <br></br>
            3.6. Urheiluvedonlyönnin tervetuliaisbonuksen summa on kierrätettävä vähintään kuusi (6) kertaa Nubet urheiluvedonlyönnissä ennen kotiutuspyyntöä. Laskentaesimerkki: Talletat 20 euroa, saat meiltä 20 euroa
            bonushyvityksenä. Jotta voit pyytää kotiutusta, sinun on siis kierrätettävä urheiluvedonlyönti-bonusta 120 euroa.<br></br>
            <br></br>
            3.7. Vedonlyöntikertoimen on oltava vähintään 1,5 (1,5x), jotta vedot voidaan laskea mukaan vedonlyöntivaatimukseen, ellei tarjouksessa toisin mainita. Vedot, jotka on pelattu pienemmällä kertoimella kuin
            vedonlyönnille määritetty vähimmäiskerroin, eivät vaikuta vedonlyöntivaatimuksen täyttymiseen.<br></br>
            <br></br>
            3.8. Urheiluvedonlyönnin tervetuliaisbonusehdoissa määritelty panostusvaatimus on täytettävä 90 päivän kuluessa (urheiluvedonlyönnin tervetuliaisbonuskausi) ensitalletuksesta. Urheiluvedonlyönnin
            tervetuliaisbonusjakson päätyttyä urheiluvedonlyönnin tervetuliaisbonus ja kaikki siihen liittyvät voitot menetetään automaattisesti, eikä niitä voi palauttaa.<br></br>
            <br></br>
            3.9. Urheiluvedonlyönnin tervetuliais-bonuksen määrä, mukaan lukien siihen liittyvä talletus ja voitot, näkyy Nubet-tilillä käyttämättömänä hyvityssaldona, kunnes määritetyt kierrätysvaatimukset on täytetty.
            Jos kotiutusta pyydetään ennen kuin urheiluvedonlyönnin tervetuliaisbonuksen kierrätysvaatimus on täyttynyt, se johtaa urheiluvedonlyönnin tervetuliaisbonuksen ja siihen liittyvien voittojen mitätöintiin ja
            takavarikointiin ilman erillistä ilmoitusta.<br></br>
            <br></br>
            3.10. Vedon suurin sallittu panos aktiivisella bonuksella on 100 euroa, ellei toisin mainita.<br></br>
            <br></br>
            3.11. Vain ensimmäinen veto tapahtumaa kohti lasketaan mukaan kierrätysvaatimuksen täyttämiseen. Kaksois- tai moninkertaisia vetoja ei oteta huomioon kierrätysvaatimuksen täyttämisessä. Järjestelmävedot
            voidaan laskea vain osittain mukaan kierrätysvaatimuksen täyttämiseen.<br></br>
            <br></br>
            3.12. Urheiluvedonlyönnin tervetuliaisbonuksen vedonlyöntivaatimukset voidaan täyttää vain, jos lyöt vetoa palvelussa näiden Nubet.com-urheiluvedonlyönti-bonusehtojen mukaisesti ja suoritat vedonlyönnin
            asettamalla vedonlyönnin kertoimilla, jotka ylittävät määritellyn vähimmäiskerroinvaatimuksen.<br></br>
            <br></br>
            <h3 id="Deposit">4. VEDONLYÖNNIN TALLETUSBONUS KÄYTTÖEHDOT</h3>
            4.1. Urheiluvedonlyönnin talletusbonus on valinnainen, ja pelaaja voi valita sen talletuksen tekemisen yhteydessä. Varmista, että noudatat urheiluvedonlyönti talletusbonuskoodin käyttöön liittyviä ohjeita.
            Saatavilla olevat eri urheiluvedonlyönnin talletusbonus-vaihtoehdot näytetään sinulle, kun teet talletuksen.<br></br>
            <br></br>
            4.2. Minimitalletus bonuksen lunastamiseksi on 20 euroa.<br></br>
            <br></br>
            4.3. Urheiluvedonlyönnin talletusbonus on voimassa vain kampanja-aikana, eikä sitä voi lunastaa sen jälkeen.<br></br>
            <br></br>
            4.4. Nubetin urheiluvedonlyönnin talletusbonustarjouksen, joka on lähetetty sähköpostitse, tiliviestillä tai muulla ilmoituksella, voi lunastaa vain urheiluvedonlyönnin talletusbonustarjouksen alkuperäinen
            vastaanottaja. Nubet pidättää oikeuden tutkia, onko urheiluvedonlyönnin talletusbonustarjouksen käyttänyt pelitilin haltija saanut bonuskoodin henkilökohtaisesti, ja jos tätä sääntöä rikotaan, mitätöidä ja
            takavarikoida bonus ja siihen liittyvät voitot.<br></br>
            <br></br>
            4.5. Sinulle ilmoitetaan urheiluvedonlyönnin talletusbonuksen ehdoista (kuten kampanja-aika, urheiluvedonlyönnin talletusbonuksen prosenttiosuus talletussummaa vastaavasta bonussummasta ja bonuksen
            euromääräinen enimmäismäärä) henkilökohtaisella sähköpostiviestillä, tiliviestillä, tekstiviestillä tai push-viestillä.<br></br>
            <br></br>
            4.6. Urheiluvedonlyönti-talletusbonuksen määrä on kierrätettävä vähintään kuusi (6) kertaa Nubet-urheiluvedonlyönnissä ennen nostopyyntöä. Laskentaesimerkki: Talletat 20 euroa, saat meiltä 20 euroa
            bonushyvityksenä. Jotta voit pyytää kotiutusta, sinun on siis kierrätettävä urheiluvedonlyöntibonusta 120 euroa.<br></br>
            <br></br>
            4.7. Vedonlyöntikertoimen on oltava vähintään 1,5 (1,5x), jotta vedot voidaan laskea mukaan vedonlyöntivaatimukseen, ellei tarjouksessa toisin mainita. Vedot, jotka on pelattu pienemmällä kertoimella kuin
            vedonlyönnin vähimmäiskertoimet, eivät välttämättä vaikuta vedonlyöntivaatimuksen täyttymiseen.<br></br>
            <br></br>
            4.8. Urheiluvedonlyönnin talletusbonusehdoissa määritelty panostusvaatimus on täytettävä 30 päivän kuluessa (urheiluvedonlyönnin talletusbonuskausi) ensitalletuksesta. Heti kun urheiluvedonlyönnin
            talletusbonuskausi on päättynyt, urheiluvedonlyönnin talletusbonus ja kaikki siihen liittyvät voitot menetetään automaattisesti, eikä niitä voi saada takaisin<br></br>
            <br></br>
            4.9. Vedon suurin sallittu panos aktiivisella bonuksella on 100 euroa, ellei toisin mainita. Vain ensimmäinen veto tapahtumaa kohden lasketaan mukaan kierrätysvaatimuksen täyttämiseen. Kaksois- tai
            moninkertaisia vetoja ei oteta huomioon kierrätysvaatimuksen täyttämisessä. <br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(BonusTerms));
