import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { Translate } from "react-redux-i18n";

const ConfirmRemoveLimit = ({ onConfirm, isDisabled }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirm();
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton
        disabled={isDisabled}
        sx={{ width: 35, height: 35 }}
        onClick={handleClickOpen}
      >
        <DeleteTwoToneIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth="xl"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="subtitle1">
            <Translate value={`labels.remove_limit`} />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Translate value={`labels.confirm_remove_limit`} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="error">
            <Translate value={`labels.keep_my_limit`} />
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            autoFocus
            sx={{ ml: 2 }}
          >
            <Translate value={`labels.remove_the_limit`} />
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

ConfirmRemoveLimit.propTypes = {
  onConfirm: PropTypes.func,
  isDisabled: PropTypes.bool,
};

ConfirmRemoveLimit.defaultProps = {
  onConfirm: () => {},
  isDisabled: false,
};

export default ConfirmRemoveLimit;
