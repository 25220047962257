export const calculateComboBoost = (slipTemplate, comboBoost) => {
  let boostedPayload = {
    isBoosted: false,
    price: 0,
    maxWin: 0,
    isBetBuilder: false,
    bonus_amount: 0,
  };
  let isAllowedCombi = true;
  let isTRSytemCombi = true;
  const settings = slipTemplate?.settings ? slipTemplate.settings : {};
  let selectedBoost = comboBoost?.bonuses?.find((item) => item.bonus_type === settings?.combiType);

  Object.values(settings.bets).forEach((bet) => {
    if (!comboBoost.includedMarkets.includes(String(bet.marketId)) || Number(bet.price) < Number(comboBoost?.minOdds)) {
      isAllowedCombi = false;
    }
    if (bet.marketId !== 534) {
      isTRSytemCombi = false;
    }
  });

  if (isTRSytemCombi) {
    selectedBoost = comboBoost?.systemBonuses?.find((item) => item.bonus_type === settings?.combiType);
  }

  if (slipTemplate?.betType === "combi" && selectedBoost && Number(selectedBoost?.percent_bonus) > 0 && isAllowedCombi) {
    const netWin = (settings.winMax - slipTemplate?.stake) * Number(selectedBoost.percent_bonus) + settings.winMax;

    boostedPayload["isBoosted"] = true;
    boostedPayload["price"] = netWin / slipTemplate?.stake;
    boostedPayload["maxWin"] = netWin;
    boostedPayload["bonus_amount"] = Number(selectedBoost.percent_bonus);
  }

  if (Object.keys(settings.bets)?.length === 1) {
    const currentBet = Object.values(settings.bets)[0];
    if (currentBet?.isBetBuilder && currentBet?.isPrecanned) {
      boostedPayload["isBoosted"] = true;
      boostedPayload["isBetBuilder"] = true;
      boostedPayload["price"] = settings.maxOdds;
      boostedPayload["maxWin"] = settings.winMax;
    }
  }
  // calculate system bonuses
  if (comboBoost?.systemBonuses?.length > 0 && isTRSytemCombi && slipTemplate.settings.winMax > 0 && slipTemplate.betType === "system") {
    boostedPayload = {
      payload: {},
      winMax: 0,
      price: 0,
      isSystemBoosted: false,
    };
    const combiTypes = slipTemplate.settings.combiTypes;
    const availableCombiTypes = slipTemplate.settings.availableCombiTypes;
    const bonuses = comboBoost?.systemBonuses ? comboBoost.systemBonuses : [];
    const minWins = slipTemplate.settings.availableCombiWinMin;
    const maxWins = slipTemplate.settings.availableCombiWinMax;
    const combiStakes = slipTemplate.settings.combiStakes;
    let totalMaxWin = 0;
    let totalOdds = 0;

    combiTypes.forEach((combiType, indx) => {
      const bonus = bonuses.find((bns) => bns.bonus_type === combiType);
      const winIndex = availableCombiTypes.findIndex((type) => type === combiType);
      const combiWinMin = minWins[winIndex];
      const combiWinMax = maxWins[winIndex];
      const currentStake = combiStakes[indx];
      if (bonus) {
        const boostAmount = Number(bonus?.percent_bonus);
        const boostedMin = (combiWinMin - Number(currentStake)) * Number(boostAmount) + Number(combiWinMin);
        const boostedMax = (combiWinMax - Number(currentStake)) * Number(boostAmount) + Number(combiWinMax);
        boostedPayload.payload[combiType] = {
          winMin: boostedMin.toFixed(2),
          winMax: boostedMax.toFixed(2),
        };
        totalMaxWin = totalMaxWin + boostedMax;
        totalOdds += boostedMax / Number(currentStake);
        boostedPayload.isSystemBoosted = true;
      } else {
        totalMaxWin = totalMaxWin + combiWinMax;
        totalOdds += Number(combiWinMax) / Number(currentStake);
      }
    });
    boostedPayload.winMax = Number(totalMaxWin).toFixed(2);
    boostedPayload.price = totalOdds;
  }
  return boostedPayload;
};
