/**
 * SSE service
 * Get live data from service games
 */

import { log, logSSEScores, logSSEOdds } from "../config/log";
import React from "react";
import { connect } from "react-redux";
import hostConfig from "../config/hostConfig";
import { updateBetbuilderGames, updateSelectedLeauge, updateOddsInSlip } from "../actions";
// ACTION DISPATCHERS
import { updateFeaturedGames, updateFeaturedOutrights } from "../redux-store/banners-reducer";
import { updateRacingOdds, updateScoresInLeague } from "../redux-store/sports-reducer";
import { setSSE, updateMaintenanceMode } from "../redux-store/settings-reducer";
import { updateScoresInTicket, updateOddsInTicket } from "../redux-store/tickets-reducer";

/**
 * Check if object is an empty one or not
 */

function isEmpty(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

class SSE extends React.PureComponent {
  constructor(props) {
    super(props);

    this.eventSource = null;
    this.betBuilderSource = null;

    this.odds = [];
    this.scores = [];

    this.leagues = [];
    this.games = [];
    this.maintenanceData = false;
    this.outrightOdds = [];
  }

  componentDidMount() {
    const { sse } = this.props;

    // if (sse) return false;

    let self = this;

    let host = window.location.hostname ? window.location.hostname.replace(/\./g, "_").replace(/\-/g, "_") : "betv3_frontend_herokuapp_com";
    let source;
    // console.log(hostConfig[host]);
    switch (hostConfig[host]?.api) {
      default:
      case "nubet_test":
        source = "https://test-sse.nubet.com";
        break;

      case "nubet_prod":
        source = "https://sse.nubet.com";
        break;
    }

    /**
     * Get SSE data and proceed them
     ** For score updating, new live league and games updating, checking maintenance mode or not
     */

    self.eventSource = new EventSource(source);

    self.eventSource.addEventListener(
      "marketUpdates",
      (e) => {
        localStorage.setItem("updateTimeStart", new Date());
        self.processMarketUpdatesMessage(JSON.parse(e.data));
      },
      false
    );

    // self.eventSource.addEventListener('outrightUpdates', e => {
    //   self.processOutrightsUpdatesMessage(JSON.parse(e.data));
    // }, false);

    self.eventSource.addEventListener(
      "recentlyStarted",
      (e) => {
        self.processNewLiveGamesMessage(JSON.parse(e.data));
      },
      false
    );

    self.eventSource.addEventListener(
      "Maintenance",
      (e) => {
        self.processMaintenanceMode(JSON.parse(e.data));
      },
      false
    );

    self.props.setSSE(true);

    setInterval(() => {
      // if ("-sse" in localStorage) return null;

      if (self.scores?.length || self.odds?.length || self.outrightOdds?.length) {
        // log('SSE');
        let activeGames = self.props.activeGames;
        let featuredGames = self.props.featuredGames;
        let featuredOutrights = self.props.featuredOutrights;
        let leagueAllGames = self.props.activeGames.games;
        let leagueOutrightGames = self.props.activeGames.outrights;
        let betBuilderGames = self.props.betBuilderGames;
        let featuredSlipGames = self.props.featuredSlip.games;
        let featuredSlipOutrights = self.props.featuredSlip.outrights;
        //Get updated games list from scores event
        if (betBuilderGames?.length && self.scores?.length) {
          let filteredGames = Object.values(self.scores).filter((score) => {
            const isExist = betBuilderGames.find((gmId) => gmId === parseInt(score.gId));
            return Boolean(isExist);
          });
          if (filteredGames?.length) {
            self.props.updateBetbuilderGames(filteredGames, this.constructor.name);
          }
        }
        // Betbuilder update ends here

        if ((featuredSlipGames?.length && self.odds?.length) || (featuredSlipGames?.length && self.scores?.length)) {
          let filteredSlipgames = Object.values(self.odds).filter((score) => {
            const isExist = featuredSlipGames.find((fg) => fg.gameid === parseInt(score.gId));
            return Boolean(isExist);
          });
          let filteredScores = Object.values(self.scores).filter((score) => {
            const isExist = featuredSlipGames.find((fg) => fg.gameid === parseInt(score.gId));
            return Boolean(isExist);
          });
          filteredScores = filteredScores.map((item) => {
            const isExist = featuredSlipGames.find((gmId) => gmId.gameid === parseInt(item.gId));
            if (isExist) {
              return { ...item, ...isExist };
            }
            return item;
          });
          filteredSlipgames = filteredSlipgames.map((item) => {
            const isExist = featuredSlipGames.find((gmId) => gmId.gameid === parseInt(item.gId));
            if (isExist) {
              return { ...item, ...isExist };
            }
            return item;
          });
        }

        if (featuredSlipOutrights?.length && self.outrightOdds?.length) {
          let filteredOutrightGames = Object.values(self.outrightOdds).filter((score) => {
            const isExist = featuredSlipOutrights.find((gmId) => gmId.gameid === parseInt(score.gId));
            return Boolean(isExist);
          });
          filteredOutrightGames = filteredOutrightGames.map((item) => {
            const isExist = featuredSlipOutrights.find((gmId) => gmId.gameid === parseInt(item.gId));
            if (isExist) {
              return { ...item, ...isExist };
            }
            return item;
          });
        }

        if (featuredGames?.length) {
          const filteredFeaturedGameUpdates = Object.values(self.odds).filter((odd) => {
            const existGame = featuredGames.find((game) => parseInt(game?.gameid) === parseInt(odd.gId));
            if (existGame) {
              const existOdd = existGame.odds[0];
              if (existOdd?.s?.includes(odd.s) && odd.m == existOdd.m) {
                return true;
              }
              return false;
            }
            return false;
          });
          if (filteredFeaturedGameUpdates?.length) {
            self.props.updateFeaturedGames(filteredFeaturedGameUpdates);
          }
        }

        if (featuredOutrights?.length) {
          const filteredFeaturedOutrightUpdates = Object.values(self.outrightOdds).filter((odd) => {
            const existGame = featuredOutrights.find((game) => parseInt(game?.gameid) === parseInt(odd.gId));
            if (existGame) {
              const existOdd = existGame.odds[0];
              if (existOdd.s == odd.s && odd.m == existOdd.m) {
                return true;
              }
              return false;
            }
            return false;
          });
          if (filteredFeaturedOutrightUpdates?.length) {
            self.props.updateFeaturedOutrights(filteredFeaturedOutrightUpdates);
          }
        }

        if (activeGames.slips?.length) {
          const filtOutrightUpdates = Object.values(self.outrightOdds).filter((odd) => {
            return activeGames.slips.includes(odd.gId);
          });
          const filtMarketUpdates = Object.values(self.odds).filter((odd) => {
            return activeGames.slips.includes(odd.gId);
          });
          if (activeGames.slips?.length > 0) self.props.updateOddsInSlip([...filtOutrightUpdates, ...filtMarketUpdates]);
        }

        if (self.outrightOdds?.length) {
          let filteredOutrightOdds = Object.values(self.outrightOdds);

          if (filteredOutrightOdds?.length) {
            self.props.updateRacingOdds(filteredOutrightOdds);
            self.props.updateOddsInSlip(filteredOutrightOdds);
            self.props.updateOddsInTicket(filteredOutrightOdds);
          }
          self.outrightOdds = [];
        }

        if (activeGames.all?.length) {
          if (self.scores?.length) {
            let filteredScores = Object.values(self.scores).filter((score) => {
              return activeGames.all.includes(score.gId);
            });

            if (filteredScores?.length) {
              self.props.updateScoresInLeague(filteredScores, self.props.leagueFilter);
              if (activeGames.tickets?.length > 0) self.props.updateScoresInTicket(filteredScores);
            }
          }

          if (self.odds?.length) {
            let filteredOdds = Object.values(self.odds).filter((odd) => {
              return activeGames.all.includes(odd.gId);
            });
            if (filteredOdds?.length) {
              if (activeGames.slips?.length > 0) self.props.updateOddsInSlip(filteredOdds);
              if (activeGames.tickets?.length > 0) self.props.updateOddsInTicket(filteredOdds);
            }
          }
          // log('/SSE');
        }
      }

      if (self.leagues?.length) {
        // there are games coming from recentlyStarted
        // I need to filter from new games;
        let { currentLiveGames, leagueFilter, sportCode } = self.props;

        if (leagueFilter === "live") {
          let currentLeaguesIds = currentLiveGames ? Object.keys(currentLiveGames) : [];

          let newLeagueIds = [];
          self.leagues?.forEach((league) => {
            const leagueID = league.leagueID;
            if (!currentLeaguesIds.includes(leagueID.toString()) && league.sportCode === sportCode) {
              newLeagueIds.push(leagueID);
            }
          });

          let uniqueNewLeagueIds = [...new Set(newLeagueIds)];
          let object = {};

          if (uniqueNewLeagueIds?.length) {
            // this means there are new leagues which need to be added

            uniqueNewLeagueIds?.forEach((id) => {
              let target = self.leagues.filter((league) => league.leagueID === id)[0];
              let targetGames = self.games.filter((game) => game.leagueID === id);
              let gamesObject = {};

              targetGames?.forEach((game) => {
                gamesObject[game.gameid] = game;
              });

              target.games = gamesObject;
              target.gameCount = targetGames?.length;
              target.gameLiveCount = targetGames?.length;
              target.isOpen = true;
              target.isTopLeague = 0;
              target.leagueId = id;
              target.label = target.leagueName.split(" - ")[1];
              target.labelCategory = target.leagueName.split(" - ")[0];

              object[id] = target;
            });

            if (!isEmpty(object)) {
              self.props.updateSelectedLeauge(object);
            }
          }

          // need to check if there are new games for existing leagues
          let games = self.games.filter((game) => game.sport_code === sportCode);
          let newObject = {};

          if (games?.length) {
            games?.forEach((game) => {
              if (currentLeaguesIds.includes(game.leagueID.toString())) {
                // this is a game for an existing league
                // need to check if it's a new game or not
                const leagueID = game.leagueID;
                let existingGames = currentLiveGames[leagueID] && currentLiveGames[leagueID].games ? Object.keys(currentLiveGames[leagueID].games) : [];

                if (!existingGames.includes(game.gameid.toString())) {
                  // this is a new game for an existing game.
                  let object = currentLiveGames[leagueID];
                  object.games[game.gameid] = game;

                  newObject[leagueID] = object;
                }
              }
            });

            if (!isEmpty(newObject)) {
              self.props.updateSelectedLeauge(newObject);
            }
          }
        }
      }

      if (self.maintenanceData) {
        self.props.updateMaintenanceMode(true);
      } else {
        if (self.props.maintenanceMode) {
          self.props.updateMaintenanceMode(false);
        }
      }

      self.odds = [];
      self.scores = [];
      self.leagues = [];
      self.games = [];
    }, 3000);
  }

  /**
   * Proceed game score and market updating from SSE service
   */

  processMarketUpdatesMessage(data) {
    const odds = data.odds;
    const scores = data.scores;
    const outrightOdds = data.outright;
    if ("log-sse-odds" in localStorage) logSSEOdds(data.odds);
    if ("log-sse-scores" in localStorage) logSSEScores(data.scores);
    this.outrightOdds = outrightOdds;
    Array.prototype.push.apply(this.odds, odds);
    Array.prototype.push.apply(this.scores, scores);
    // Array.prototype.push.apply(this.outrightOdds, outrightOdds);
  }

  /**
   * Proceed new live leagues and games data from SSE service
   */

  processNewLiveGamesMessage(data) {
    if (data) {
      const games = data?.games;
      const leagues = data?.leagues;
      Array.prototype.push.apply(this.leagues, leagues);
      Array.prototype.push.apply(this.games, games);
    }
  }

  /**
   * Proceed outrights updating from SSE service
   */

  // processOutrightsUpdatesMessage(data) {
  //   const outrightOdds = data.outright;

  //   if ("log-sse-odds" in localStorage) logSSEOdds(data.outright);
  //   this.outrightOdds = outrightOdds;
  // }

  /**
   * Proceed maintenance status from SSE service
   */

  processMaintenanceMode(data) {
    this.maintenanceData = { ...data };
  }

  render() {
    log(this.constructor.name, "render");
    return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  const selectedLeagues = state.selected.leagues;
  const slips = state.slips;
  const selectedTickets = state.tickets?.selected ? state.tickets.selected : state.tickets?.calcCashout ? state.tickets.calcCashout : {};

  let selectedLeagueGames = [],
    slipGames = [],
    outrightGames = [],
    leagueGames = [],
    ticketGames = [],
    bets;

  for (let league in selectedLeagues) {
    if (selectedLeagues[league].games) {
      for (let gameid in selectedLeagues[league].games) {
        selectedLeagueGames.push(Number(gameid));
        leagueGames.push(Number(gameid));
      }
    }
    if (selectedLeagues[league]?.outrightGames) {
      for (let gameid in selectedLeagues[league].outrightGames) {
        selectedLeagueGames.push(Number(gameid));
        outrightGames.push(Number(gameid));
      }
    }
  }

  for (let slip in slips) {
    bets = slips[slip].settings?.bets;
    if (bets) {
      for (let bet in bets) {
        slipGames.push(Number(bets[bet].gameId));
      }
    }
  }

  if (selectedTickets) {
    for (let ticket in selectedTickets) {
      bets = selectedTickets[ticket].bets;
      if (bets) {
        for (let bet in bets) {
          ticketGames.push(Number(bets[bet].gameId));
        }
      }
    }
  }

  const allGames = [].concat.apply([], [selectedLeagueGames, slipGames, ticketGames]);

  const activeGames = {
    all: allGames,
    leagues: selectedLeagueGames,
    slips: slipGames,
    tickets: ticketGames,
    games: leagueGames,
    outrights: outrightGames,
  };

  const selectedFeaturedGames = [];
  const bannerFetauredGamesList = state.banner.games && Object.values(state.banner.games)?.length ? Object.values(state.banner.games) : [];
  bannerFetauredGamesList?.forEach((bannerGameObj) => {
    Object.values(bannerGameObj)?.forEach((game) => {
      selectedFeaturedGames.push(game);
    });
  });
  const selectedFeaturedOutrights = [];
  const bannerFetauredOutrightsList = state.banner.outrights && Object.values(state.banner.outrights)?.length ? Object.values(state.banner.outrights) : [];
  bannerFetauredOutrightsList?.forEach((bannerGameObj) => {
    Object.values(bannerGameObj)?.forEach((game) => {
      selectedFeaturedOutrights.push(game);
    });
  });

  const featuredSlipOutrights = [];
  const featuredSlipGames = [];
  const featuredSlipKeys = state.banner.slip && Object.keys(state.banner.slip)?.length ? Object.keys(state.banner.slip) : [];
  featuredSlipKeys?.forEach((slipKey) => {
    const slip = state.banner.slip[slipKey] ? state.banner.slip[slipKey] : {};
    Object.keys(slip)?.forEach((collectionKey) => {
      const slipItem = slip[collectionKey];
      const slipItemGames = slipItem?.games ? slipItem.games : {};
      const slipItemOutrights = slipItem?.outrights ? slipItem.outrights : {};
      Object.values(slipItemGames)?.forEach((gamesCollection) => {
        Object.values(gamesCollection)?.forEach((game) => {
          featuredSlipGames.push({ ...game, slipKey, collectionKey });
        });
      });
      Object.values(slipItemOutrights)?.forEach((outrightsCollection) => {
        Object.values(outrightsCollection)?.forEach((outright) => {
          featuredSlipOutrights.push({ ...outright, slipKey, collectionKey });
        });
      });
    });
  });

  const featuredSlip = {
    games: featuredSlipGames,
    outrights: featuredSlipOutrights,
  };

  const favoriteLeagues = state.leagues?.favorites ? state.leagues?.favorites : {};

  let leagueFilter = state.leagues._leagueFilter ? state.leagues._leagueFilter : "All";
  let sportCode = state.leagues._sportCode ? state.leagues._sportCode : "FB";
  let leagues;

  switch (leagueFilter) {
    case "search":
      leagues = state.leagues?.all ? Object.values(state.leagues.all) : [];

      break;
    case "live":
      leagues = state.leagues[leagueFilter] && state.leagues[leagueFilter][sportCode] ? state.leagues[leagueFilter][sportCode] : [];
      leagues = leagues.filter((league) => {
        if (league?.games && Object.values(league?.games)?.length === 1 && Object.values(league?.games)[0]?.time === "End") {
          return false;
        }
        return true;
      });
      break;
    case "favorites":
      const selectedLeaguesAll = {};
      for (let sportCode in favoriteLeagues) {
        for (let leagueId in favoriteLeagues[sportCode]) {
          selectedLeaguesAll[leagueId] = favoriteLeagues[sportCode][leagueId];
        }
      }
      leagues = selectedLeaguesAll;
      break;

    default:
      leagues = state.leagues[leagueFilter] && state.leagues[leagueFilter][sportCode] ? state.leagues[leagueFilter][sportCode] : null;
      break;
  }
  //Collect all active betbuilder games
  let betBuilderGames = [];
  const betBuilderSportsList = state.leagues?.betbuilder && Object.values(state.leagues?.betbuilder)?.length ? Object.values(state.leagues.betbuilder) : [];
  betBuilderSportsList?.forEach((leagues) => {
    leagues?.forEach((league) => {
      if (league && league?.games) {
        league.games?.forEach((game) => {
          betBuilderGames.push(game.gameid);
        });
      }
    });
  });

  return {
    leagues: leagues,
    activeGames: activeGames,
    sse: state.settings.sse ? state.settings.sse : false,
    leagueFilter: leagueFilter,
    currentLiveGames: state.selected.leagues,
    sportCode: sportCode,
    maintenanceMode: state.settings.maintenanceMode,
    featuredGames: selectedFeaturedGames,
    featuredOutrights: selectedFeaturedOutrights,
    featuredSlip: featuredSlip,
    betBuilderGames: betBuilderGames,
  };
};

export default connect(mapStateToProps, {
  setSSE,
  updateBetbuilderGames,
  updateRacingOdds,
  updateScoresInLeague,
  updateOddsInSlip,
  updateOddsInTicket,
  updateScoresInTicket,
  updateSelectedLeauge,
  updateMaintenanceMode,
  updateFeaturedOutrights,
  updateFeaturedGames,
})(SSE);
