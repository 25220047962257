/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconSLIP = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g><path d="M1631.3,131.9L1631.3,131.9l-1262.7,0c-126.3,0-236.7,102.6-236.7,236.7v394.6c0,134.2,110.5,236.7,236.7,236.7h78.9v789.2
		c0,23.7,15.8,55.2,39.5,63.1c23.7,15.8,55.2,15.8,78.9,0l118.4-55.2l118.4,63.1c23.7,7.9,47.3,7.9,71,0l118.4-63.1l118.4,63.1
		c23.7,7.9,47.3,7.9,71,0l118.4-63.1l118.4,63.1c31.6,7.9,47.3,7.9,55.2,7.9c15.8,0,31.6,0,39.5-15.8c23.7-15.8,39.5-39.5,39.5-63.1
		V1000h78.9c134.2,0,236.7-102.6,236.7-236.7V368.7C1868.1,234.5,1765.5,131.9,1631.3,131.9z M1284.1,1639.2l-126.3,63.1
		l-118.4-63.1c-15.8-7.9-23.7-7.9-39.5-7.9c-7.9,0-23.7,0-39.5,7.9l-118.4,63.1l-118.4-63.1c-23.7-7.9-47.3-7.9-71,0l-47.3,23.7
		V684.3h789.2v978.6l-39.5-23.7C1331.4,1631.3,1307.8,1631.3,1284.1,1639.2z M1710.2,763.3c0,39.5-39.5,78.9-78.9,78.9h-78.9V605.4
		c0-39.5-39.5-78.9-78.9-78.9h-947c-39.5,0-78.9,39.5-78.9,78.9v236.7h-78.9c-39.5,0-78.9-39.5-78.9-78.9V368.7
		c0-39.5,39.5-78.9,78.9-78.9h1262.7c39.5,0,78.9,39.5,78.9,78.9V763.3z"/>
					<path d="M802.7,1142.1L929,1221c7.9,15.8,23.7,15.8,39.5,15.8l0,0c23.7,0,47.3-7.9,63.1-39.5L1221,960.5
		c23.7-31.6,15.8-86.8-15.8-110.5c-31.6-23.7-86.8-15.8-110.5,15.8l-142,181.5l-63.1-39.5c-39.5-23.7-86.8-15.8-110.5,23.7
		C755.4,1071,763.2,1118.4,802.7,1142.1z"/>
					<path d="M1157.8,1315.7H842.2c-47.3,0-78.9,31.6-78.9,78.9s31.6,78.9,78.9,78.9h315.7c47.3,0,78.9-31.6,78.9-78.9
		S1205.2,1315.7,1157.8,1315.7z"/></g>
			</svg>
		</div>
	);
};

export default IconSLIP;
