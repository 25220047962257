/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconCS = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g>
					<path d="M426.7,387.2c14.4-56.6,65.6-98.6,126.6-98.6h101.9c4.6-15.1,7.1-31.1,7.1-47.7c0-90.2-73.1-163.3-163.3-163.3
		s-163.2,73.1-163.2,163.3C335.8,305,372.9,360.5,426.7,387.2z"/>
					<path d="M1054.8,218.7c-32-29.5-81.8-27.4-111.3,4.6l-60,65.2c81.3,0,125,0,195.9,0C1083.1,263.5,1074.8,237.2,1054.8,218.7z" />
					<path d="M498.2,419.3v86.4l248.1,7.7l177.9-149.2H553.3C522.9,364.2,498.2,388.9,498.2,419.3z" />
					<path d="M1084.6,416.5L1084.6,416.5c-29.4-26.3-73.4-26.4-103-1.7c0,0,0,0,0,0L772.8,589.9c-65.8-19.4-350.2-99.6-350.2-99.6V453
		h-22.7c-60.9,0-110.3,49.4-110.3,110.3v564.6l-11.8,281.7l-157.5,382.2c-19.9,48.3,3.1,103.5,51.4,123.4
		c48.2,19.9,103.5-3.1,123.4-51.4l163.8-397.6c4.2-10.2,6.6-21,7-32.1c7.9-189.8,10.9-260.2,12-286.8c0.4-10.2,8.8-18.3,19-18.3h0
		c8.1,0,15.4,5.1,18.2,12.7l93.5,254.9l-87.2,411.6c-10.8,51.1,21.8,101.2,72.9,112.1c51.1,10.8,101.2-21.8,112.1-72.9l92.8-438.2
		c3.7-17.4,2.4-35.4-3.7-52.1l-88.7-242V804.7c-52.4-29.8-204-116-255.4-145.2l317.3,93.4c25.4,7.5,53,1.5,72.9-15.2l241.1-202.2
		C1119,505.2,1121,449.1,1084.6,416.5z"/>
					<path d="M1369.3,396c0,0-13.1-35-42.6-35h-62.1v-28.4c0-20.9,2-37.8-18.9-37.8c-20.9,0-18.9,16.9-18.9,37.8V361h-90.6v0.2
		c5,4.6,9.8,9.5,14.2,14.8c26.5,31.6,38.6,71.7,35.6,111.1h140.6c32.5,0,42.3-37.3,42.3-37.3h512V396H1369.3z"/>
				</g>
			</svg>
		</div>
	);
};

export default IconCS;
