/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconVB = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				{/* <linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient> */}
				<g>
					<path d="M1910.7,939.8c-1.4-21.9-3.8-43.7-6.8-65.5c-13.3-96.7-42-190.7-85.2-278.2c-8.8-17.8-18.2-35.4-28.2-52.6
	c-12.9-22.5-26.8-44.2-42.6-66.4c-87.5-125.2-205-226.4-341.7-294.5c-162.9-81-346.4-111.5-526.8-87.3
	c-280.5,37.8-527.7,203.2-669.8,447.9c-2.4,4.1-4.4,8.4-6.8,12.6c-3.9,7-7.9,14-11.6,21.1c-0.5,0.8-1,1.6-1.5,2.5
	c-1.3,2.6-2.5,5.3-3.8,7.9c-4,8-7.8,16-11.6,24.1c-4.4,9.4-8.7,18.8-12.8,28.3c-3.4,8-6.7,16-9.9,24.1c-3.8,9.6-7.4,19.3-10.9,29
	c-3,8.4-5.9,16.9-8.6,25.4c-3,9.5-5.9,19-8.6,28.5c-2.6,8.9-5.1,17.9-7.4,26.9c-2.3,9-4.5,18.7-6.6,28.1c-2.1,9.4-4.1,18.5-5.9,27.8
	c-1.8,9.3-3.3,19.3-4.8,29c-1.4,9.1-3,18.3-4.1,27.4c-1.4,10.9-2.2,21.8-3.2,32.7c-0.7,8-1.7,16-2.2,24c-1.2,18-1.9,35.9-2,53.8v3.3
	v1.4c0,17.5,0.7,34.9,1.7,52.2c0.3,3.8,0.6,7.5,0.9,11.3c1.1,14.9,2.5,29.7,4.2,44.4c0.2,1.5,0.3,3,0.4,4.5c0.3,2.4,0.7,4.7,1.1,7.1
	c1.3,9.6,2.6,19.3,4.2,28.8c1,6.1,2.1,12.2,3.3,18.2c1.4,7.5,2.8,15,4.4,22.5c1.9,8.8,4,17.5,6.1,26.2c1.2,4.7,2.2,9.4,3.4,14
	c3.3,12.8,7,25.6,10.8,38.2l0.5,1.7c84.2,270.2,288.7,486.1,553.9,584.9l4.4,1.6c12.2,4.4,24.3,8.6,36.5,12.5
	c2.2,0.7,4.5,1.4,6.7,2.1c11.7,3.6,23.6,7.1,35.5,10.3l7.3,1.9c12.2,3.1,24.3,6,36.5,8.6l6.4,1.3c13,2.7,26.2,5.1,39.5,7.2l3.7,0.6
	c36.6,5.7,73.6,9.3,110.7,10.5v0.7h11.5v-0.5h1.4v0.5v-0.4v0.4v-0.4v0.4v-0.4h0.2v0.4v-0.4c5.1,0,10.2,0.4,15.2,0.4h2.1
	c1.1,0.2,2.2,0.2,3.3,0h1.1c10.4,0,20.6-0.4,30.2-1c490.1-18.6,878-421.2,878.1-911.7C1912.7,979.8,1912,959.8,1910.7,939.8z
	 M1455.4,1719.3c-121.2,77.1-260.1,121.9-403.4,130.2c133.8-268.5,127.5-585.6-16.9-848.6c65.6-108.5,154.5-201,260.4-270.8
	c161,160,251.7,377.4,252.1,604.4C1548.1,1468.3,1516.5,1600.3,1455.4,1719.3z M184.4,1245.3c-2.1-7-4-14.1-5.9-21.3
	c230.1,249.8,580.3,350.4,907.9,260.7c-6.5,128.4-42,253.6-103.8,366.4c-366.9-8.1-687.6-249.7-796.5-600.1
	C185.5,1249.1,185,1247.2,184.4,1245.3z M148.2,996.5c0-16.7,0.5-33.4,1.6-50c0.5-7.4,1.3-14.7,2-22.1c0.9-10.3,1.8-20.5,3-30.8
	c1.1-8.5,2.5-16.9,3.8-25.3c1.4-9.1,2.8-18.3,4.5-27.2c1.6-8.6,3.5-17,5.4-25.5c1.9-8.5,4-17.8,6.1-26.6c2.1-8.2,4.4-16.4,6.7-24.6
	c2.6-9.1,5.3-18.3,8.2-27.2c2.5-7.7,5.1-15.2,7.8-23c3.3-9.4,6.8-18.7,10.5-27.9c2.8-7.1,5.7-14.3,8.7-21.3
	c4-9.3,8.2-18.6,12.6-27.7c2.9-6.1,5.7-12.2,8.6-18c80.1,121.7,188.1,222.6,315,294c131.1,73.8,278.5,113.8,429,116.5
	c66.2,120.2,102.3,254.6,105.3,391.8c-348,102.6-722.9-27.2-932.8-323.2c-1.5-12.9-2.7-25.8-3.7-38.7c-0.2-3.3-0.5-6.7-0.8-10.1
	c-1-16.3-1.6-32.5-1.6-48.6v-1.1C148.2,998.7,148.2,997.6,148.2,996.5z M1698.2,512.1c4.3,6.1,8.1,12,12.2,18
	c-300.9,17.3-574.1,181.5-730.6,439.1c-128.4-3-254.5-35.2-368.7-94c108.7-359.2,439.5-605,814.8-605.4c4.4,0,8.6,0.2,12.9,0.3
	C1541.3,331.8,1629.7,414.2,1698.2,512.1L1698.2,512.1z M1330.1,214.6C970.2,252.9,667,500.1,556.9,844.8
	c-116.6-71-214.5-169-285.5-285.7c3.5-5.7,7.1-11.5,10.7-17.1c4.9-7.6,9.7-15.2,14.8-22.7c5.5-7.9,11.2-15.7,16.9-23.4
	c5.3-7.2,10.6-14.4,16.1-21.5c6.1-7.5,12.2-14.9,18.3-22.2c5.7-6.8,11.3-13.6,17.2-20.1c6.5-7.2,13.1-14.1,19.8-21
	c6.1-6.3,12.2-12.7,18.2-18.8c6.9-6.8,14.1-13.3,21.3-19.8c6.4-5.8,12.7-11.7,19.2-17.4c7.4-6.3,15-12.3,22.6-18.4
	c6.7-5.3,13.3-10.8,20.1-15.9c7.8-5.9,15.8-11.4,23.8-17c7-4.9,13.8-9.9,21-14.6c8.2-5.4,16.6-10.4,24.9-15.5
	c7.2-4.4,14.3-8.9,21.7-13.1c8.5-4.9,17.3-9.4,26.1-14c7.4-3.9,14.7-7.9,22.3-11.6c9.1-4.4,18.3-8.3,27.4-12.4
	c7.5-3.4,15-6.9,22.6-10.1c9.4-3.9,19-7.2,28.5-10.8c7.6-2.9,15.2-5.9,22.9-8.5c9.7-3.3,19.7-6.1,29.5-9.1
	c7.7-2.3,15.2-4.8,23.1-6.9c10.2-2.7,20.5-4.9,30.9-7.3c7.6-1.7,15.2-3.7,22.8-5.3c10.9-2.2,21.9-3.8,32.9-5.5
	c7.2-1.2,14.4-2.6,21.7-3.6c12.4-1.6,24.9-2.7,37.4-3.8c6.1-0.5,12-1.4,18-1.8c18.5-1.2,37.2-1.8,56-1.8
	C1113.4,147.8,1225.6,170.4,1330.1,214.6L1330.1,214.6z M1553,1647.5c120.5-329.5,41.3-698.9-203.8-950
	c115.8-64.5,245-101.2,377.5-107.2c6.5-0.2,13-0.5,19.6-0.7C1941.1,943.4,1860.3,1385.4,1553,1647.5L1553,1647.5z"/>
				</g>
			</svg>
		</div>
	);
};

export default IconVB;
