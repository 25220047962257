import { Collapse, Divider, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { format } from "date-fns";
import Iconify from "../iconify";
import { useState } from "react";
import { Translate } from "react-redux-i18n";

const BettingStatementBox = ({ item }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Stack spacing={0.5}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body1">{item?.productTranslation || item?.productName}</Typography>
        <Typography variant="string" color="text.secondary">
          {format(new Date(item.createdTime), "dd MMMM, yyyy HH:mm")}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body1" color="text.secondary">
          <Translate value="labels.slip_stake" />
        </Typography>
        <Typography variant="body1">
          <Translate value="labels.bett_possiwin" />
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h1" component="p">
          € {item?.extraInfo?.stake || 0}
        </Typography>
        <Typography variant="h1" component="div">
          € {item?.winAmount || 0}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body1" color="primary">
          <Translate value="labels.bet_id" />: {item?.roundId}
        </Typography>
        <Stack direction="row" alignItems="center">
          <IconButton size="small" onClick={handleExpand}>
            <Iconify icon={expanded ? "mynaui:chevron-up-solid" : "mynaui:chevron-down-solid"} />
          </IconButton>
        </Stack>
      </Stack>
      <Stack>
        <Collapse in={expanded}>
          <Stack>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>
                      <Translate value="labels.date" />
                    </TableCell>
                    <TableCell>
                      <Translate value="labels.stmt_tickets" />
                    </TableCell>
                    <TableCell>
                      <Translate value="labels.bet_type" />
                    </TableCell>
                    <TableCell>
                      <Translate value="labels.amount" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{item?.roundId}</TableCell>
                    <TableCell>
                      <Typography variant="body1" color="text.secondary">
                        {format(new Date(item.createdTime), "dd MMMM, yyyy HH:mm")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">{item?.productTranslation || item?.productName}</Typography>
                    </TableCell>
                    <TableCell>{item?.gameType}</TableCell>
                    <TableCell>
                      <Typography variant="h1" component="p">
                        € {item?.extraInfo?.stake}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Collapse>
      </Stack>
      <Stack>
        <Divider />
      </Stack>
    </Stack>
  );
};

export default BettingStatementBox;
