import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const CategoryMegawaysIcon = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 200 200"
      {...props}
    >
      <polygon points="35.5,30.6 25,73.1 26.6,76.5 33.4,77.1 34,167.9 38.6,169.7 78.6,173.1 84.1,170.3 85.1,161.7 84.8,101.4 94,112.2 
	102.3,121.4 106.9,124.5 108.5,123.5 114.6,118.6 122.6,109.1 128.5,102.6 127.9,134.3 127.2,163.6 128.5,169.4 131.6,171.6 
	138.3,171.6 160.5,169.7 174,167.6 175,163.9 174.6,32.4 170.3,30 130,27.2 124.5,31.5 106.6,54 81.4,26.9 "/>
    </SvgIcon>
  );
};

export default CategoryMegawaysIcon;
