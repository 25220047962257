import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { generateDatabetToken } from "./_requests";

const initialState = {
  token: null,
  isAuthorized: false,
};

export const generateDatabetTokenAsync = createAsyncThunk("thunk/generateDatabetToken", generateDatabetToken);

const databetSlice = createSlice({
  name: "slice/databet",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(generateDatabetTokenAsync.fulfilled, (state, { payload }) => {
      state.token = payload?.token;
      state.isAuthorized = payload?.isAuthenticated;
    });
  },
});

export default databetSlice.reducer;
