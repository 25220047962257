import { useEffect, useState } from "react";
// @MUI components
import { Box, Stack, styled, Tab, Typography } from "@mui/material";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import MuiAccordion from "@mui/material/Accordion";
import Iconify from "../../iconify";
// components
import CountryAvatar from "./CountryAvatar";
import { Translate } from "react-redux-i18n";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `none`,
  borderRadius: `6px`,
  "&::before": {
    display: "none",
  },
  "& .MuiAccordionSummary-root.Mui-expanded": {
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.background.cartoffertitleopen : theme.palette.background.cartoffertitleopen,
    borderColor: theme.palette.mode === "dark" ? theme.palette.background.cartoffertitleopen : theme.palette.background.cartoffertitleopen,
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary expandIcon={<Iconify icon="heroicons:chevron-down-20-solid" />} {...props} />)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  "& .MuiAccordionSummary-content.Mui-expanded": {
    "& .MuiTypography-body2": {
      color: theme.palette.text.primary,
    },
    "& .MuiTypography-price": {
      color: theme.palette.primary,
    },
    "& .MuiTypography-oddscart": {
      color: theme.palette.text.primary,
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingTop: 0,
  paddingBottom: 0,
}));

const MuiTabPanel = styled(TabPanel)(({ theme }) => ({
  padding: 0,
  paddingTop: theme.spacing(2),
}));

const LeagueRow = ({ outrights, results, systemRacing, labelCategory, label, countryCode, gameCount, gameTabEnabled, content, rowIndex }) => {
  const [expanded, setExpanded] = useState(false);
  const [tabValue, setTabValue] = useState("outrights");

  // functions
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChange = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (rowIndex === 0) {
      setExpanded(true);
    }
  }, [rowIndex]);

  return (
    <Accordion expanded={expanded} onChange={handleChange} area-label="leagues-row-accordion">
      <AccordionSummary>
        <Stack direction="row" alignItems="center" gap={1}>
          <CountryAvatar countryCode={countryCode} />
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="subtitle1" component="p">
              {labelCategory}
            </Typography>
            <Typography variant="body2" color="text.secondary" component="p" noWrap>
              {label}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" width="100%" pr={1}>
          <Typography align="end" variant="subtitle1" color="primary">
            {gameCount}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
      {/* <AccordionDetails sx={{ backgroundColor: 'var(--bg-slip)', margin: "0.5em", borderRadius: "0.5em" }} > */}

        {expanded &&
          (gameTabEnabled ? (
            <TabContext value={tabValue}>
              <TabList onChange={handleTabChange} aria-label="racing-layout-tab">
                <Tab
                  label={<Translate value="marketSets.outrights" />}
                  value="outrights"
                  sx={{
                    fontWeight: '400',
                    '&.Mui-selected': {
                      color: 'primary.menuTopActive',
                      fontWeight: '400',

                    }
                  }}
                />
                <Tab
                  label={<Translate value="slip.system" />}
                  value="system"
                  sx={{
                    fontWeight: '400',
                    '&.Mui-selected': {
                      color: 'primary.menuTopActive',
                      fontWeight: '400',

                    }
                  }}
                />
                <Tab
                  label={<Translate value="menu.results" />}
                  value="results"
                  sx={{
                    fontWeight: '400',
                    '&.Mui-selected': {
                      color: 'primary.menuTopActive',
                      fontWeight: '400',

                    }
                  }}
                />
              </TabList>
              <MuiTabPanel value="outrights">{tabValue === "outrights" ? outrights : null}</MuiTabPanel>
              <MuiTabPanel value="system">{tabValue === "system" ? systemRacing : null}</MuiTabPanel>
              <MuiTabPanel value="results">{tabValue === "results" ? results : null}</MuiTabPanel>
            </TabContext>
          ) : (
            content
          ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default LeagueRow;
