import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { i18nReducer } from "react-redux-i18n";
import { reducer as formReducer } from "redux-form";
//list of reducers
import userReducer from "../reducers/userReducer";
import alertReducer from "../reducers/alertReducer";
import selectReducer from "../reducers/selectReducer";
import labelsReducer from "../reducers/labelsReducer";
import leaguesReducer from "../reducers/leaguesReducer";
import favoriteCasinoGamesReducer from "../reducers/favoriteCasinoGamesReducer";
import slipAutoUpdateStatusReducer from "../reducers/slipAutoUpdateStatusReducer";
import registeredUserReducer from "../reducers/registeredUserReducer";

import confirmReducer from "../reducers/confirmReducer";
import wpReducer from "../reducers/wpReducer";
import updateAlertReducer from "../reducers/oddsUpdateAlertReducer";
import comboboostReducer from "../reducers/comboboostReducer";
import slipsReducer from "../reducers/slipsReducer";

// Updated reducers
import sportsReducer from "./sports-reducer";
import bannersReducer from "./banners-reducer";
// import slipsReducer from "./slips-reducer";
import settingsReducer from "./settings-reducer";
import casinoReducer from "./casino-reducer";
import ticketsReducer from "./tickets-reducer";
import databetReducer from "./databet-reducer";

const persistConfig = {
  key: "nubet",
  storage,
  whitelist: ["user", "settings", "slips"],
};

const rootReducer = combineReducers({
  i18n: i18nReducer,
  form: formReducer,
  user: userReducer,
  settings: settingsReducer,
  selected: selectReducer,
  alert: alertReducer,
  labels: labelsReducer,
  leagues: leaguesReducer,
  tickets: ticketsReducer,
  slips: slipsReducer,
  casino: casinoReducer,
  favoriteCasinoGames: favoriteCasinoGamesReducer,
  slipAutoUpdateStatus: slipAutoUpdateStatusReducer,
  registeredUser: registeredUserReducer,
  confirm: confirmReducer,
  wpContent: wpReducer,
  banner: bannersReducer,
  update: updateAlertReducer,
  comboboost: comboboostReducer,
  databet: databetReducer,
  sports: sportsReducer,
});

export default persistReducer(persistConfig, rootReducer);
