import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import { Translate } from "react-redux-i18n";

const CustomNotFoundComponent = () => {
  return (
    <Container
      sx={{
        backgroundColor: "var(--color-main)",
        minHeight: "100%",
        textAlign: "center",
        padding: "3em",
      }}
      maxWidth={false}
    >
      <Box>
        <Typography component={"span"}>
          <h3>
            <Translate value={`labels.page_not_found`} />
          </h3>
        </Typography>
        <br></br>
        <Button component="a" href="/" variant="contained" color="primary" size="small" style={{ color: "var(--btn-text)", background: "var(--bg-btn)" }}>
          <Translate value="labels.back_to_home" />
        </Button>
      </Box>
    </Container>
  );
};

export default CustomNotFoundComponent;
